import BasicItemInformation from './BasicItemInformation';
import BasicItemSizeInformation from './BasicItemSizeInformation';
import { CaretDownOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import CorporateAssuranceRequirements from './CorporateAssuranceRequirements';
import PhysicalAttributes from './PhysicalAttributes';
import PropTypes from 'prop-types';
import React from 'react';
import SpeciesInformation from './SpeciesInformation';
import UploadDocuments from './UploadDocuments';
import { isEmpty } from 'lodash';

const { Panel } = Collapse;

class SpecificationPanels extends React.Component {
  static propTypes = {
    basicItemData: PropTypes.object.isRequired,
    speciesData: PropTypes.object.isRequired,
    basicItemSizeData: PropTypes.object,
    physicalAttributesData: PropTypes.object.isRequired,
    corporateAssuranceRequirementsData: PropTypes.object,
    uploadDocumentsData: PropTypes.object.isRequired,
    specId: PropTypes.string.isRequired,
    isEdit: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      physicalAttributesData: this.props.physicalAttributesData,
      corporateAssuranceRequirementsData:
        this.props.corporateAssuranceRequirementsData,
    };
  }

  updatePhysicalAttributesData = (data) => {
    let { physicalAttributesData } = this.state;
    physicalAttributesData = { ...physicalAttributesData, ...data };
    let updatedFormFields = {};
    if ('selectedProductForm' in data)
      updatedFormFields.productForm = data.selectedProductForm;
    if ('minSizeCount' in data)
      updatedFormFields.minSizeCount = data.minSizeCount;
    if ('minSizeCountUom' in data)
      updatedFormFields.minSizeCountUom = data.minSizeCountUom;
    if ('maxSizeCount' in data)
      updatedFormFields.maxSizeCount = data.maxSizeCount;
    if ('maxSizeCountUom' in data)
      updatedFormFields.maxSizeCountUom = data.maxSizeCountUom;
    if ('variance' in data) updatedFormFields.variance = data.variance;

    if (!isEmpty(updatedFormFields))
      this.props.updateFormFields(updatedFormFields);
    this.setState({ physicalAttributesData });
  };

  updateCorporateAssuranceRequirementsData = (data) => {
    let { corporateAssuranceRequirementsData } = this.state;
    corporateAssuranceRequirementsData = {
      ...corporateAssuranceRequirementsData,
      ...data,
    };
    this.setState({ corporateAssuranceRequirementsData });
  };

  getData = () => {
    return {
      ...this.basicItemInfoPanel.getData(),
      ...this.corporateAssuranceReqPanel.getData(),
      ...this.physicalAttributesPanel.getData(),
      ...this.speciesInfoPanel.getData(),
      ...this.basicItemSizePanel.getData(),
      ...this.uploadDocumentsPanel.getData(),
    };
  };

  render() {
    const {
      basicItemData,
      speciesData,
      basicItemSizeData,
      uploadDocumentsData,
      specId,
      isEdit,
    } = this.props;
    const { physicalAttributesData, corporateAssuranceRequirementsData } =
      this.state;

    return (
      <Collapse
        expandIconPosition='right'
        bordered={false}
        defaultActiveKey={[
          'basicItemInformation',
          'speciesInformation',
          'basicItemSizeInformation',
          'physicalAttributes',
          'corporateAssuranceRequirements',
          'uploadDocuments',
        ]}
        expandIcon={({ isActive }) => (
          <CaretDownOutlined rotate={isActive ? 180 : 0} />
        )}
        className='site-collapse-custom-collapse specification-collapse'
      >
        <Panel
          header='Basic Item Information'
          key='basicItemInformation'
          className='site-collapse-custom-panel'
        >
          <BasicItemInformation
            ref={(ref) => (this.basicItemInfoPanel = ref)}
            {...basicItemData}
            isEdit={isEdit}
          ></BasicItemInformation>
        </Panel>
        <Panel
          header='Species Information'
          key='speciesInformation'
          className='site-collapse-custom-panel'
        >
          <SpeciesInformation
            ref={(ref) => (this.speciesInfoPanel = ref)}
            {...speciesData}
            updatePhysicalAttributesData={(data) =>
              this.updatePhysicalAttributesData(data)
            }
            updateCorporateAssuranceRequirementsData={(data) =>
              this.updateCorporateAssuranceRequirementsData(data)
            }
          ></SpeciesInformation>
        </Panel>
        <Panel
          header='Basic Item Size Information'
          key='basicItemSizeInformation'
          className='site-collapse-custom-panel'
        >
          <BasicItemSizeInformation
            ref={(ref) => (this.basicItemSizePanel = ref)}
            {...basicItemSizeData}
          ></BasicItemSizeInformation>
        </Panel>
        <Panel
          header='Physical Attributes'
          key='physicalAttributes'
          className='site-collapse-custom-panel'
        >
          <PhysicalAttributes
            ref={(ref) => (this.physicalAttributesPanel = ref)}
            {...physicalAttributesData}
          />
        </Panel>
        <Panel
          header='Corporate Assurance Requirements'
          key='corporateAssuranceRequirements'
          className='site-collapse-custom-panel'
        >
          <CorporateAssuranceRequirements
            ref={(ref) => (this.corporateAssuranceReqPanel = ref)}
            {...corporateAssuranceRequirementsData}
          />
        </Panel>
        <Panel
          header='Related files'
          key='uploadDocuments'
          className='site-collapse-custom-panel'
        >
          <UploadDocuments
            ref={(ref) => (this.uploadDocumentsPanel = ref)}
            {...uploadDocumentsData}
            specId={specId}
          />
        </Panel>
      </Collapse>
    );
  }
}

export default SpecificationPanels;
