import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import React from 'react';
import SellerBidHistoryData from './SellerBidHistoryData';

class SellerBidHistory extends React.Component {
  render() {
    return (
      <>
        <CardWithTitle title='Seller Quote History'>
          <SellerBidHistoryData
            bidDetails={this.props.bidDetails}
            incoterms={this.props.bidDetails.auctionIncoterms}
          />
        </CardWithTitle>
      </>
    );
  }
}
export default SellerBidHistory;
