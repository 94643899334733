import '../Modals.css';

import { Form, Modal, Select } from 'antd';
import React, { Component } from 'react';

import ApiUtils from 'src/utils/ApiUtils';
import { CaretDownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import StringConstants from 'src/constants/StringConstants';
import TeamService from 'src/services/TeamService';
import Title from 'src/components/common/typography/Title';
import { toast } from 'react-toastify';

const { Option } = Select;

class UpdateUserRoleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isError: false,
      errorMsg: '',
    };
  }

  onSubmit = (values) => {
    this.setState({ loading: true });
    if (values['role'] === this.props.role) {
      toast.warning('No changes found');
      this.props.refreshPage();
      return;
    }
    values[':teamId'] = this.props.teamId;
    values[':userId'] = this.props.userId;
    TeamService.updateUserRole(values)
      .then((response) => {
        toast.success('Updated user role Successfully');
        this.setState({ loading: false }, () => this.props.refreshPage());
      })
      .catch((err) => {
        let errorMessage = ApiUtils.getErrorMessage(err);
        toast.error('Failed to update user role - ' + errorMessage);
        this.setState({ loading: false, isError: true, errorMsg: err });
      });
  };

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    teamId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    role: PropTypes.string,
  };

  render() {
    const { visible, role, userName } = this.props;
    const { loading } = this.state;

    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };

    return (
      <Modal
        className='confirmation-modal'
        centered
        visible={visible}
        okText='Update Role'
        onOk={() => this.updateForm.submit()}
        onCancel={this.props.onCancel}
        closable={false}
        maskClosable={false}
        cancelButtonProps={{
          disabled: loading,
          className: 'cancel-outlined-button',
        }}
        width={900}
      >
        <Title title='Update User Role' />
        <p className='modal-description mb-0'>
          {'Update role for ' + userName}
        </p>

        <Form
          {...layout}
          onFinish={this.onSubmit}
          preserve={false}
          ref={(ref) => (this.updateForm = ref)}
          className='mt-30'
          initialValues={{ role: role }}
        >
          <Form.Item
            label='Role'
            name='role'
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please select a role',
              },
            ]}
          >
            <Select
              defaultValue={role}
              className='planned-vi-sel-btn'
              suffixIcon={<CaretDownOutlined />}
            >
              {Object.keys(StringConstants.TEAM_ROLES).map((role) => {
                return <Option value={role}>{role}</Option>;
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default UpdateUserRoleModal;
