import './NavProfile.css';

import { Avatar, Button, Col, Dropdown, Menu, Row } from 'antd';
import { getProfilePicture, getUsername } from 'src/utils/DisplayUtils';
import {
  isUserImporter,
  isUserPartOfTeam,
  isUserPlant,
} from 'src/helpers/user';

// import { EditFilled } from '@ant-design/icons';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import SecureComponent from '../../SecureComponent';
import StringConstants from 'src/constants/StringConstants';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { logout } from 'src/utils/auth/auth0';
import { resetState } from 'src/actions';
import { withRouter } from 'react-router';

export const NavProfile = ({ user, signOut, history, resetState }) => {
  const profileMenu = (
    <div className='nav-profile nav-dropdown nav-profile-pop'>
      <div className='profile-pop-img'>{getProfilePicture(user)}</div>
      <p className='user-name-pop font-blue'>{getUsername(user)}</p>
      {/* <p className='user-designation-pop'>Sustainability Manager</p>
      <div className='prof-underline'></div> */}
      <p className='user-mail-pop '>{user && user.email}</p>
      <div
        className='propop-company-name '
        onClick={() => {
          if (
            (isUserImporter() || isUserPlant()) &&
            isUserPartOfTeam(StringConstants.TEAMS.EXECUTIVE)
          )
            history.push(RouteConstants.COMPANY_PROFILE);
        }}
      >
        {user && user.company && user.company.name}
      </div>
      <div className='prof-underline' />
      <div className='mt-10'>
        <Row style={{ justifyContent: 'space-between' }}>
          <Col>
            <Button
              className='primary-button'
              type='primary'
              // icon={<EditFilled />}
              onClick={() => history.push(RouteConstants.USER_PROFILE)}
            >
              My Profile
            </Button>
          </Col>
          <Col>
            <Button
              className='cancel-outlined-button'
              onClick={() => {
                resetState();
                signOut();
              }}
            >
              Logout
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );

  return (
    <Dropdown
      placement='bottomRight'
      overlay={isEmpty(user) ? null : profileMenu}
      trigger={['click']}
    >
      <Menu
        className='notification-menu d-flex align-item-center'
        mode='horizontal'
      >
        <Menu.Item>
          <Avatar
            src={
              user.profilePicUrl
                ? user.profilePicUrl + '?t=' + Date.now()
                : require('../../../../assets/images/user.svg').default
            }
          />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    signOut: logout,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetState: () => dispatch(resetState()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  SecureComponent(
    withRouter(NavProfile),
    StringConstants.DEFAULT_COMPANY_PAGE_ACCESS,
    StringConstants.ACCESS_TO_ALL_TEAMS_AND_ROLES,
    false
  )
);
