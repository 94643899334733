import '../Modals.css';

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import Title from 'src/components/common/typography/Title';
import { find } from 'lodash';

class RejectBidModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    auctionWinnerId: PropTypes.number.isRequired,
    actionLoading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    visible: false,
  };

  render() {
    const { visible, auctionWinnerId, data, actionLoading } = this.props;

    const auctionWinner = find(data, function (o) {
      return o.id === auctionWinnerId;
    });
    return (
      <Modal
        className='reject-bid-modal'
        centered
        visible={visible}
        onOk={this.props.onSubmit}
        onCancel={this.props.onCancel}
        okText='Yes, Reject It'
        width={900}
        confirmLoading={actionLoading}
        closable={false}
        maskClosable={false}
      >
        <Title title='Reject Quote' />

        <p className='modal-title mt-15'>
          Are you sure you want to reject{' '}
          <strong>
            {' '}
            {auctionWinner &&
              auctionWinner.company &&
              auctionWinner.company.name}
          </strong>
          {"'s "}
          bid?
        </p>
        <p className='modal-description'>
          This vendor will be notified that they have not been awarded any
          business from this RFQ.
        </p>
      </Modal>
    );
  }
}
export default RejectBidModal;
