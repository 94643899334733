import '../Modals.css';

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

class BidAwardSuccessfulModal extends React.Component {
  static propTypes = {
    bid: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    refreshPageFn: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
  };

  static defaultProps = {
    visible: false,
    bid: {},
  };

  render() {
    const { visible, data } = this.props;
    return (
      <Modal
        className='single-btn-center-footer'
        centered
        visible={visible}
        onOk={this.props.refreshPageFn}
        onCancel={this.props.refreshPageFn}
        cancelText='Close'
        width={700}
        closable={false}
        maskClosable={false}
      >
        <div className='bid-accepted-content'>
          <div className='bid-cont-img'>
            <img
              alt='success icon'
              style={{ width: '130px' }}
              className=''
              src={require('../../assets/images/accepted.png').default}
            />
          </div>
          <p className='bid-modal-content'>
            You have successfully awarded{' '}
            <strong>{data && data.totalFCL}</strong>
            {' FCL '}
            to <strong>{data && data.company && data.company.name} </strong>
            Based on the RFQ and award details, Carava has automatically
            generated a proforma contract for{' '}
            <strong>{data && data.company && data.company.name}</strong> to
            review and approve.”
          </p>
        </div>
      </Modal>
    );
  }
}
export default BidAwardSuccessfulModal;
