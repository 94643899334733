import ApiManager from 'src/api/ApiManager';
import HttpConstants from 'src/constants/HttpConstants';
import UrlConstants from 'src/constants/UrlConstants';

const PlannedOrderService = {
  getAllPlannedOrders: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PLANNED_ORDERS.USECASE,
      HttpConstants.GET_METHOD,
      data
    );
  },

  getPlannedOrderDetail: (plannedOrderId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PLANNED_ORDER_DETAIL.USECASE,
      HttpConstants.GET_METHOD,
      { ':pon': plannedOrderId }
    );
  },
};

export default PlannedOrderService;
