import { Button, Table } from 'antd';
import { isUserImporter, isUserManagerOfTeam } from 'src/helpers/user';

import ApiUtils from 'src/utils/ApiUtils';
import CardWithTitle from '../../components/common/cards/CardWithTitle';
import ConfirmationModal from 'src/modals/common/ConfirmationModal';
import CreateTermsAndConditionModal from 'src/modals/termsAndConditions/CreateTermsAndConditionModal';
import EditTermsAndConditionModal from 'src/modals/termsAndConditions/EditTermsAndConditionModal';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import LoadingComponent from 'src/components/common/LoadingComponent';
import React from 'react';
import SecureComponent from 'src/components/common/SecureComponent';
import StringConstants from 'src/constants/StringConstants';
import TermsAndConditionsService from 'src/services/TermsAndConditionsService';
import TermsAndConditionsTableManager from 'src/utils/tables/TermsAndConditionsTableManager';
import { isUndefined } from 'lodash';
import { toast } from 'react-toastify';

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      termsAndConditions: [],
      createTandCModalVisible: false,
      editTandCModalVisible: false,
      removeTandCModalVisible: false,
      actionLoading: false,
      tAndCId: undefined,
      category: undefined,
      text: undefined,
    };
  }

  async componentDidMount() {
    this.fetchTermsAndConditions();
  }

  refreshPage = () => {
    this.fetchTermsAndConditions();
  };

  fetchTermsAndConditions = async () => {
    try {
      this.setState({ loading: true });
      let termsAndConditionsRes =
        await TermsAndConditionsService.getAllTermsAndConditions();

      this.setState({
        loading: false,
        termsAndConditions: termsAndConditionsRes.data,
      });
    } catch (error) {
      let errorMessage = ApiUtils.getErrorMessage(error);
      toast.error(errorMessage);
      this.setState({ loading: false, termsAndConditions: [] });
    }
  };

  getColumns = () => {
    const { termsAndConditions } = this.state;
    return TermsAndConditionsTableManager.getBasicColumnsWithActionsForTable.call(
      this,
      termsAndConditions
    );
  };

  openCreateTandCModal = () => {
    this.setState({ createTandCModalVisible: true });
  };

  closeCreateTandCModal = () => {
    this.setState({ createTandCModalVisible: false });
  };

  openEditTandCModal = (tAndC) => {
    this.setState({
      tAndCId: tAndC.id,
      category: tAndC.category,
      text: tAndC.text,
      editTandCModalVisible: true,
    });
  };

  closeEditTandCModal = () => {
    this.setState({ editTandCModalVisible: false });
  };

  openRemoveTandCModal = (id) => {
    this.setState({ tAndCId: id, removeTandCModalVisible: true });
  };

  closeRemoveTandCModal = () => {
    this.setState({ removeTandCModalVisible: false });
  };

  isUserAuthorized = () => {
    return (
      isUserImporter() &&
      (isUserManagerOfTeam(StringConstants.TEAMS.EXECUTIVE) ||
        isUserManagerOfTeam(StringConstants.TEAMS.SOURCING))
    );
  };

  removeTermsAndCondition = async () => {
    const { tAndCId } = this.state;
    this.setState({ actionLoading: true });
    try {
      await TermsAndConditionsService.deleteTermsAndCondition(tAndCId);
      this.setState(
        { removeTandCModalVisible: false, actionLoading: false },
        () => this.refreshPage()
      );
    } catch (error) {
      console.log(error);
      if (!isUndefined(error)) {
        let errorMessage = ApiUtils.getErrorMessage(error);
        toast.error(errorMessage);
      } else toast.error('Something went wrong');
      this.setState({ actionLoading: false });
    }
  };

  render() {
    const {
      loading,
      termsAndConditions,
      createTandCModalVisible,
      editTandCModalVisible,
      removeTandCModalVisible,
      actionLoading,
      tAndCId,
      category,
      text,
    } = this.state;

    if (loading) return <LoadingComponent />;

    return (
      <>
        <HeaderMetadata
          title={`Terms and Conditions`}
          description='Configuration page for Terms and Conditions'
        />
        <CardWithTitle
          title='Terms and Conditions'
          action={
            isUserImporter() &&
            (isUserManagerOfTeam(StringConstants.TEAMS.EXECUTIVE) ||
              isUserManagerOfTeam(StringConstants.TEAMS.SOURCING)) && (
              <Button
                className='heading-cta'
                type='primary'
                onClick={this.openCreateTandCModal}
              >
                Add T&C
              </Button>
            )
          }
        >
          <Table
            columns={this.getColumns()}
            dataSource={termsAndConditions}
            rowKey='id'
            scroll={{ x: 900 }}
            pagination={termsAndConditions && termsAndConditions.length >= 10}
          />
        </CardWithTitle>
        {createTandCModalVisible && (
          <CreateTermsAndConditionModal
            visible={createTandCModalVisible}
            onCancel={this.closeCreateTandCModal}
            refreshPage={() =>
              this.setState({ createTandCModalVisible: false }, () =>
                this.refreshPage()
              )
            }
          />
        )}
        {editTandCModalVisible && (
          <EditTermsAndConditionModal
            visible={editTandCModalVisible}
            onCancel={this.closeEditTandCModal}
            refreshPage={() =>
              this.setState({ editTandCModalVisible: false }, () =>
                this.refreshPage()
              )
            }
            id={tAndCId}
            category={category}
            text={text}
          />
        )}
        <ConfirmationModal
          visible={removeTandCModalVisible}
          onSubmit={() => this.removeTermsAndCondition()}
          onCancel={this.closeRemoveTandCModal}
          title='Confirm removal of T&C'
          description={'Are you sure you want to remove this t&c ?'}
          negativeAction={true}
          okText='Yes, Remove'
          actionLoading={actionLoading}
        />
      </>
    );
  }
}

export default SecureComponent(TermsAndConditions, [
  StringConstants.COMPANY_TYPES.IMPORTER,
]);
