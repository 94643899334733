import './ProformaReview.css';

import { Button, Col, Row, Space, Tabs, message } from 'antd';
import {
  camelCase,
  cloneDeep,
  find,
  findIndex,
  isEmpty,
  isNull,
  isUndefined,
} from 'lodash';
import {
  getFormattedFullDate,
  getReadableDisplayName,
} from 'src/utils/DisplayUtils';
import {
  getUserDataFromCookie,
  isUserSubCompany,
} from 'src/utils/auth/AuthUtils';
import {
  isUserBuyerOrSeller,
  isUserImporter,
  isUserManagerOfTeam,
  isUserPartOfApprovalTeam,
  isUserPartOfTeam,
  isUserPlant,
} from 'src/helpers/user';

import AgreementTerm from 'src/components/agreementTerm/AgreementTerm';
import ApiUtils from 'src/utils/ApiUtils';
import ApproverCommentModal from 'src/modals/proforma/ApproverCommentModal';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import ChooseTermsAndConditions from 'src/components/termsAndConditions/ChooseTermsAndConditions';
import Comments from 'src/components/comments/Comments';
import CompanyService from 'src/services/CompanyService';
import { DownloadOutlined } from '@ant-design/icons';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import HorizontalTimeline from 'src/components/common/horizontalTimeline/HorizontalTimeline';
import LoadingComponent from 'src/components/common/LoadingComponent';
import MetadataService from 'src/services/MetadataService';
import NoDataComponent from 'src/components/common/NoDataComponent';
import PlannedOrderDataTable from 'src/components/plannedOrder/auction/PlannedOrderDataTable';
import ProformaActionCard from 'src/components/proforma/ProformaActionCard';
import ProformaApprovalStatus from 'src/components/proforma/ProformaApprovalStatus';
import ProformaService from 'src/services/ProformaService';
import ProformaUtils from 'src/utils/ProformaUtils';
import React from 'react';
import ReviewMultiplePlannedOrders from 'src/pages/auction/buyer/create/reviewAuctionPlannedOrders/ReviewMultiplePlannedOrders';
import ReviewSinglePlannedOrder from 'src/pages/auction/buyer/create/reviewAuctionPlannedOrders/ReviewSinglePlannedOrder';
import RouteConstants from 'src/constants/RouteConstants';
import SecureComponent from 'src/components/common/SecureComponent';
import Specification from 'src/components/specification/Specification';
import SplitProformaModal from 'src/modals/proforma/SplitProformaModal';
import StringConstants from 'src/constants/StringConstants';
import TermsAndConditionsService from 'src/services/TermsAndConditionsService';
import UrlGenerator from 'src/api/UrlGenerator';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

const { TabPane } = Tabs;

class ProformaReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auctionId: undefined,
      proformaId: undefined,
      loading: true,
      originalProformaDetails: {},
      proformaDetails: {},
      currentProforma: undefined,
      prevProforma: undefined,
      downloadLoading: false,
      toEdit: false,
      updateLoading: false,
      proformaStatus: '',
      approverCommentModalVisible: false,
      readOnly: props.readOnly,
      versionNotFound: false,
      currentActioningTeam: undefined,
      switchingTab: false,
      termsAndConditions: [],
      splitProformaModalVisible: false,
      companyDetails: {},
      splitProformaModalLoading: false,
    };
  }

  fetchTermsAndConditions = async () => {
    try {
      let termsAndConditionsRes =
        await TermsAndConditionsService.getAllTermsAndConditions();

      this.setState({
        termsAndConditions: termsAndConditionsRes.data,
      });
    } catch (error) {
      if (!isUndefined(error)) {
        let errorMessage = ApiUtils.getErrorMessage(error);
        toast.error(errorMessage);
      } else toast.error('Failed to fetch T&Cs');
      this.setState({ termsAndConditions: [] });
    }
  };

  setTermsAndConditions = (proforma) => {
    this.setState({ termsAndConditions: proforma.termsAndConditions });
  };

  async componentDidMount() {
    this.fetchPageData();
  }

  fetchPageData = async () => {
    this.setState({ splitProformaModalVisible: false });
    const { fromAuction, showVersionInHeading } = this.props;

    let fetchAllTandCs = false;

    await this.getCompanyDetails();

    if (
      isUserImporter() &&
      isUserPartOfTeam(StringConstants.TEAMS.SOURCING) &&
      !showVersionInHeading
    ) {
      fetchAllTandCs = true;
      await this.fetchTermsAndConditions();
    }

    if (!isUndefined(this.props.data)) {
      if (isNull(this.props.data))
        this.setState({
          loading: false,
          proformaDetails: [],
          versionNotFound: true,
        });
      else {
        let proformaVersionData = this.props.data;
        proformaVersionData.proformaPlannedOrders.forEach((pl) => {
          pl.specification = proformaVersionData.specification;
        });

        if (!fetchAllTandCs) this.setTermsAndConditions(proformaVersionData);

        this.setState({
          originalProformaDetails: [proformaVersionData],
          proformaDetails: [proformaVersionData],
          finalBidValue: proformaVersionData.bidValue,
          finalBidIncoterm: proformaVersionData.bidIncoterm.code,
          currentProforma: proformaVersionData,
          loading: false,
        });
      }
    } else {
      if (fromAuction) {
        try {
          const auctionId = this.props.match.params.id;
          this.setState({ auctionId });
          let response = await ProformaService.getAuctionProforma(auctionId);
          let proformaDetails = response.data;

          proformaDetails.forEach((proforma) => {
            proforma.proformaPlannedOrders.forEach((pl) => {
              pl.specification = proforma.specification;
            });
          });

          this.setState(
            {
              originalProformaDetails: proformaDetails,
              proformaDetails,
              finalBidValue: proformaDetails[0].bidValue,
              finalBidIncoterm: proformaDetails[0].bidIncoterm.code,
              currentProforma: proformaDetails[0],
              proformaStatus: this.getApprovalStatus(proformaDetails),
            },
            async () => {
              if (isUserImporter()) {
                MetadataService.getAllWarehouses()
                  .then((response) => {
                    this.setState({ warehouses: response.data }, async () => {
                      try {
                        if (
                          proformaDetails[0].version !== 0 &&
                          proformaDetails[0].status !==
                            StringConstants.PROFORMA_STATUS.CONTRACT_GENERATED
                        ) {
                          let diff = await ProformaService.getDiff(
                            proformaDetails[0].id
                          );
                          if (!fetchAllTandCs)
                            this.setTermsAndConditions(diff.data.current);
                          this.updateState(
                            diff.data.current,
                            diff.data.previous,
                            false
                          );
                          this.setProformaDetails(proformaDetails);
                        } else this.setState({ loading: false });
                      } catch (error) {
                        this.setState({ loading: false });
                      }
                    });
                  })
                  .catch((error) => {
                    this.setState({ loading: false });
                  });
              } else {
                try {
                  if (
                    proformaDetails[0].version !== 0 &&
                    proformaDetails[0].status !==
                      StringConstants.PROFORMA_STATUS.CONTRACT_GENERATED
                  ) {
                    let diff = await ProformaService.getDiff(
                      proformaDetails[0].id
                    );
                    if (!fetchAllTandCs)
                      this.setTermsAndConditions(diff.data.current);
                    this.updateState(
                      diff.data.current,
                      diff.data.previous,
                      false
                    );
                    this.setProformaDetails(proformaDetails);
                  } else this.setState({ loading: false });
                } catch (error) {
                  this.setState({ loading: false });
                }
              }
            }
          );
        } catch (error) {
          this.setState({
            auctionId: this.props.match.params.id,
            loading: false,
          });
        }
      } else {
        try {
          const proformaId = this.props.match.params.id;
          let response = await ProformaService.getProformaDetails(proformaId);
          let proformaDetails = response.data;

          proformaDetails.proformaPlannedOrders.forEach((pl) => {
            pl.specification = proformaDetails.specification;
          });

          this.setState(
            {
              proformaId: proformaId,
              originalProformaDetails: [proformaDetails],
              proformaDetails: [proformaDetails],
              currentProforma: proformaDetails,
              finalBidValue: proformaDetails.bidValue,
              finalBidIncoterm: proformaDetails.bidIncoterm.code,
              proformaStatus: this.getApprovalStatus([proformaDetails]),
            },
            async () => {
              if (isUserImporter()) {
                MetadataService.getAllWarehouses()
                  .then((response) => {
                    this.setState({ warehouses: response.data }, async () => {
                      try {
                        if (
                          proformaDetails.version !== 0 &&
                          proformaDetails.status !==
                            StringConstants.PROFORMA_STATUS.CONTRACT_GENERATED
                        ) {
                          let diff = await ProformaService.getDiff(
                            proformaDetails.id
                          );
                          if (!fetchAllTandCs)
                            this.setTermsAndConditions(diff.data.current);
                          this.updateState(
                            diff.data.current,
                            diff.data.previous,
                            false
                          );
                          this.setProformaDetails([proformaDetails]);
                        } else this.setState({ loading: false });
                      } catch (error) {
                        this.setState({ loading: false });
                      }
                    });
                  })
                  .catch((error) => {
                    this.setState({ loading: false });
                  });
              } else {
                try {
                  if (
                    proformaDetails.version !== 0 &&
                    proformaDetails.status !==
                      StringConstants.PROFORMA_STATUS.CONTRACT_GENERATED
                  ) {
                    let diff = await ProformaService.getDiff(
                      proformaDetails.id
                    );
                    if (!fetchAllTandCs)
                      this.setTermsAndConditions(diff.data.current);
                    this.updateState(
                      diff.data.current,
                      diff.data.previous,
                      false
                    );
                    this.setProformaDetails([proformaDetails]);
                  } else this.setState({ loading: false });
                } catch (error) {
                  this.setState({ loading: false });
                }
              }
            }
          );
        } catch (error) {
          this.setState({
            proformaId: this.props.match.params.id,
            loading: false,
          });
        }
      }
    }
  };

  downloadDocument = () => {
    const { currentProforma } = this.state;
    const { showVersionInHeading } = this.props;

    if (
      currentProforma.status === StringConstants.PROFORMA_STATUS.OPEN ||
      currentProforma.status === StringConstants.PROFORMA_STATUS.REVIEW_APPROVED
    ) {
      this.setState({ downloadLoading: true });
      if (showVersionInHeading) {
        ProformaService.downloadProformaPDFForVersion(
          currentProforma.id,
          currentProforma.version
        )
          .then((blob) => {
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(new Blob([blob.data]));
            link.href = url;
            link.setAttribute(
              'download',
              `${currentProforma.id}_v${currentProforma.version}.zip`
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            this.setState({ downloadLoading: false });
          })
          .catch((err) => {
            message.error('Cannot download proforma at the moment');
            this.setState({ downloadLoading: false });
          });
      } else {
        ProformaService.downloadProforma(currentProforma.id)
          .then((blob) => {
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(new Blob([blob.data]));
            link.href = url;
            link.setAttribute('download', currentProforma.id + '.zip');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            this.setState({ downloadLoading: false });
          })
          .catch((err) => {
            message.error('Cannot download proforma at the moment');
            this.setState({ downloadLoading: false });
          });
      }
    } else if (
      currentProforma.status ===
      StringConstants.PROFORMA_STATUS.CONTRACT_GENERATED
    ) {
      this.setState({ downloadLoading: true });
      ProformaService.downloadContract(currentProforma.id)
        .then((blob) => {
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(new Blob([blob.data]));
          link.href = url;
          link.setAttribute(
            'download',
            'contract- ' + currentProforma.id + '.zip'
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          this.setState({ downloadLoading: false });
        })
        .catch((err) => {
          message.error('Cannot download contract at the moment');
          this.setState({ downloadLoading: false });
        });
    }
  };

  findProformaById = (proformaId) => {
    const proformaDetails = this.state.proformaDetails;

    return find(proformaDetails, function (o) {
      return o.id === proformaId;
    });
  };

  findPrevProformaById = (proformaId) => {
    const prevProformaDetails = this.state.prevProformaDetails;

    return find(prevProformaDetails, function (o) {
      return o.id === proformaId;
    });
  };

  saveDetails = () => {
    let data = {};
    const { currentProforma } = this.state;
    if (currentProforma.proformaPlannedOrders.length === 1) {
      data = this.reviewSinglePlannedOrder.getUpdatedData();
      this.reviewSinglePlannedOrder.resetEditedFields();
    } else {
      data = this.plannedOrderDataTable.getUpdatedData();
      this.plannedOrderDataTable.resetEditedFields();
    }

    if (isEmpty(data))
      data = {
        proformaPlannedOrders: currentProforma.proformaPlannedOrders.map(
          (elt) => {
            return {
              id: elt.id,
              requestedEtd: elt.requestedEtd,
              totalWeight: elt.totalWeight,
              warehouseId: elt.warehouse.id,
            };
          }
        ),
      };

    data = {
      proformaPlannedOrders: data.proformaPlannedOrders.map((elt) => {
        return {
          id: elt.id,
          requestedEtd: elt.requestedEtd,
          totalWeight: elt.totalWeight,
          warehouseId: elt.warehouseId,
        };
      }),
      bidIncoterm: this.agreementTerm.getUpdatedData(),
      paymentTerms: this.agreementTerm.getUpdatedData().paymentTerms,
    };

    data[':proformaId'] = currentProforma.id;
    let termsAndConditions = this.chooseTermsAndConditions
      ? this.chooseTermsAndConditions.getUpdatedData()
      : [];
    data['termsAndConditions'] = termsAndConditions;

    ProformaService.updateProforma(data)
      .then(async (response) => {
        let diff = await ProformaService.getDiff(currentProforma.id);
        this.updateState(diff.data.current, diff.data.previous, false);
        if (this.commentsSection) this.commentsSection.fetchComments();
        toast.success('Proforma updated successfully');
      })
      .catch((err) => {
        let errorMessage = ApiUtils.getErrorMessage(err);
        this.setState({ updateLoading: false });
        toast.error('Error - ' + errorMessage);
      });
  };

  updateState = (data, prevData, updateApprovalStatus) => {
    this.setState({ loading: true });
    let currentProforma = cloneDeep(data);
    currentProforma.proformaPlannedOrders.forEach((pl) => {
      pl.specification = currentProforma.specification;
    });
    let proformaDetails = [...this.state.proformaDetails];
    let currentProformaIndex = findIndex(proformaDetails, function (o) {
      return o.id === currentProforma.id;
    });
    let proforma = { ...proformaDetails[currentProformaIndex] };
    proforma = currentProforma;
    proformaDetails[currentProformaIndex] = proforma;

    let prevProforma = undefined;

    if (prevData) {
      prevProforma = prevData;
      prevProforma.proformaPlannedOrders.forEach((pl) => {
        pl.caravaItem = prevProforma.caravaItem;
      });
    }

    this.setState({
      currentProforma,
      proformaDetails,
      prevProforma,
      updateLoading: false,
      loading: false,
      proformaStatus: updateApprovalStatus
        ? this.getApprovalStatus([data])
        : this.state.proformaStatus,
      approverCommentModalVisible: false,
    });
  };

  setProformaDetails = (data) => {
    this.setState({
      proformaDetails: data,
      currentProforma: data[0],
    });
  };

  isAlreadyActioned = (proformaInternalReview) => {
    if (isUserPartOfTeam(StringConstants.TEAMS.ACCOUNT_MANAGERS))
      return (
        proformaInternalReview && proformaInternalReview.accountManagerApproved
      );
    else if (isUserPartOfTeam(StringConstants.TEAMS.COMPLIANCE))
      return (
        proformaInternalReview && proformaInternalReview.complianceApproved
      );
    else
      return (
        proformaInternalReview && proformaInternalReview.sustainabilityApproved
      );
  };

  approveProforma = async (currentTeam, comment) => {
    const { currentProforma } = this.state;
    this.setState({ loading: true });
    if (isUserBuyerOrSeller(currentTeam)) {
      try {
        let response = await ProformaService.approveProforma(
          currentProforma.id
        );
        if (this.commentsSection) this.commentsSection.fetchComments();
        this.updateState(response.data, undefined, true);
        toast.success('Approved proforma successfully');
        setTimeout(() => {
          this.setState({ loading: false });
        }, 1000);
      } catch (err) {
        let errorMessage = ApiUtils.getErrorMessage(err);
        toast.error('Failed to approve proforma - ' + errorMessage);
        setTimeout(() => {
          this.setState({ loading: false });
        }, 1000);
      }
    } else if (isUserPartOfApprovalTeam(currentTeam)) {
      try {
        let response = await ProformaService.internalReviewAction(
          currentProforma.id,
          {
            action: StringConstants.INTERNAL_REVIEW_ACTIONS.APPROVE,
            comment: comment,
          }
        );
        if (this.commentsSection) this.commentsSection.fetchComments();
        this.updateState(response.data, undefined, true);
        toast.success('Approved proforma successfully');
        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 1000);
      } catch (err) {
        let errorMessage = ApiUtils.getErrorMessage(err);
        toast.error('Failed to approve proforma - ' + errorMessage);
        setTimeout(() => {
          this.setState({ loading: false });
        }, 1000);
      }
    }
  };

  rejectProforma = async (currentTeam, comment) => {
    const { currentProforma } = this.state;
    this.setState({ loading: true });
    if (isUserPartOfApprovalTeam(currentTeam)) {
      try {
        let response = await ProformaService.internalReviewAction(
          currentProforma.id,
          {
            action: StringConstants.INTERNAL_REVIEW_ACTIONS.REJECT,
            comment: comment,
          }
        );
        if (this.commentsSection) this.commentsSection.fetchComments();
        this.updateState(response.data, undefined, true);
        toast.success('Rejected proforma successfully');
        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 1000);
      } catch (err) {
        toast.error('Failed to reject proforma');
        setTimeout(() => {
          this.setState({ loading: false });
        }, 1000);
      }
    }
  };

  getApprovalStatus = (data, currentTeam) => {
    let proformaReview = data[0].proformaReview;
    let status = data[0].status;
    let proformaInternalReview = data[0].proformaInternalReview;

    let proformaStatus = {};
    if (status === StringConstants.PROFORMA_STATUS.REVIEW_APPROVED) {
      if (
        isUserBuyerOrSeller(currentTeam) ||
        currentTeam === StringConstants.TEAMS.EXECUTIVE
      ) {
        proformaStatus.mainText = ' Approved by buyer and seller';
        proformaStatus.subText = ' Contract in review';
      } else if (isUserImporter() && isUserPartOfApprovalTeam(currentTeam)) {
        if (!this.isAlreadyActioned(proformaInternalReview))
          proformaStatus = {};
        else {
          if (currentTeam === StringConstants.TEAMS.ACCOUNT_MANAGERS) {
            if (proformaInternalReview.accountManagerApproved) {
              proformaStatus.mainText =
                'Approved by ' +
                (proformaInternalReview.accountManagerApprover &&
                  proformaInternalReview.accountManagerApprover.email) +
                ' on ' +
                getFormattedFullDate(
                  proformaInternalReview.accountManagerActionedTime
                );

              proformaStatus.subText =
                'Comments: ' + proformaInternalReview.accountManagerComments;
            }
          } else if (currentTeam === StringConstants.TEAMS.COMPLIANCE) {
            if (proformaInternalReview.complianceApproved) {
              proformaStatus.mainText =
                'Approved by ' +
                (proformaInternalReview.complianceApprover &&
                  proformaInternalReview.complianceApprover.email) +
                ' on ' +
                getFormattedFullDate(
                  proformaInternalReview.complianceActionedTime
                );

              proformaStatus.subText =
                'Comments: ' + proformaInternalReview.complianceComments;
            }
          } else {
            if (proformaInternalReview.sustainabilityApproved) {
              proformaStatus.mainText =
                'Approved by ' +
                (proformaInternalReview.sustainabilityApprover &&
                  proformaInternalReview.sustainabilityApprover.email) +
                ' on ' +
                getFormattedFullDate(
                  proformaInternalReview.sustainabilityActionedTime
                );

              proformaStatus.subText =
                'Comments: ' + proformaInternalReview.sustainabilityComments;
            }
          }
        }
      }
    } else if (status === StringConstants.PROFORMA_STATUS.CONTRACT_GENERATED) {
      proformaStatus.mainText = ' Contract Generated';
    } else if (status === StringConstants.PROFORMA_STATUS.REJECTED) {
      if (isUserImporter() && currentTeam === StringConstants.TEAMS.SOURCING) {
        proformaStatus = {};
      } else if (isUserPlant()) {
        proformaStatus.mainText = ' Waiting for buyer approval';
      } else if (isUserPartOfApprovalTeam(currentTeam)) {
        proformaStatus.mainText = ' Proforma has been rejected';
        proformaStatus.subText = ' Waiting for buyer action';
      }
    } else {
      let companyId = this.props.user.company.id;
      if (isUserImporter()) {
        if (status === StringConstants.PROFORMA_STATUS.OPEN) {
          if (currentTeam === StringConstants.TEAMS.SOURCING) {
            if (proformaReview.nextApprovalCompanyId === companyId)
              proformaStatus = {};
            else if (proformaReview.buyerApproved) {
              proformaStatus.mainText =
                'Approved by ' +
                (proformaReview.buyerApprover &&
                  proformaReview.buyerApprover.email) +
                ' on ' +
                getFormattedFullDate(proformaReview.buyerApprovedTime);

              proformaStatus.subText = ' Waiting for seller action';
            } else {
              proformaStatus.mainText = 'Waiting for seller action';
            }
          } else if (isUserPartOfApprovalTeam(currentTeam)) {
            proformaStatus.mainText = ' Proforma is open';
            proformaStatus.subText = ' Waiting for buyer and seller approval';
          }
        }
      } else if (isUserPlant()) {
        if (status === StringConstants.PROFORMA_STATUS.OPEN) {
          if (proformaReview.nextApprovalCompanyId === companyId) {
            proformaStatus = {};
          } else {
            proformaStatus.mainText = ' Waiting for buyer approval';
          }
        }
      }
    }

    if (currentTeam && !isEmpty(proformaStatus))
      proformaStatus.mainText =
        '[' + currentTeam + '] ' + proformaStatus.mainText;

    return proformaStatus;
  };

  toggleEdit = (currentTeam) => {
    this.setState({
      toEdit: !this.state.toEdit,
      currentActioningTeam: currentTeam,
    });
  };

  showApproverCommentBox = (action, currentTeam) => {
    this.setState({
      approverCommentModalVisible: true,
      approverAction: action,
      currentActioningTeam: currentTeam,
    });
  };

  getCompanyDetails = async () => {
    this.setState({ splitProformaModalLoading: true });
    try {
      let companyDetailsRes = await CompanyService.getCompanyProfile();
      this.setState({
        companyDetails: companyDetailsRes.data,
        splitProformaModalLoading: false,
      });
    } catch (error) {
      this.setState({ splitProformaModalLoading: false });
    }
  };

  formApprovalStatusTableData = (data) => {
    const { proformaInternalReview } = data;
    let tableData = [];
    StringConstants.APPROVAL_TEAMS.forEach((approvalTeam) => {
      let teamKey = camelCase(approvalTeam);
      tableData.push({
        name: getReadableDisplayName(approvalTeam),
        status: proformaInternalReview[teamKey + 'Approved'],
        approver: proformaInternalReview[teamKey + 'Approver'],
        comments: proformaInternalReview[teamKey + 'Comments'],
        actionedTime: proformaInternalReview[teamKey + 'ActionedTime'],
      });
    });
    return tableData;
  };

  areSubPlantsPartOfAuction = () => {
    const { companyDetails, currentProforma } = this.state;
    return companyDetails.subCompanies.some((subCompany) => {
      let subCompanyPartOfAuction = find(
        currentProforma.biddingCompanies,
        function (o) {
          return o.id === subCompany.id;
        }
      );
      return !isUndefined(subCompanyPartOfAuction);
    });
  };

  render() {
    const {
      auctionId,
      proformaId,
      loading,
      proformaDetails,
      warehouses,
      currentProforma,
      prevProforma,
      downloadLoading,
      toEdit,
      updateLoading,
      approverCommentModalVisible,
      approverAction,
      readOnly,
      versionNotFound,
      currentActioningTeam,
      termsAndConditions,
      splitProformaModalVisible,
      splitProformaModalLoading,
      companyDetails,
    } = this.state;

    const { fromAuction, showVersionInHeading } = this.props;

    if (loading) return <LoadingComponent />;

    if (versionNotFound)
      return (
        <NoDataComponent
          title='No proforma  found'
          tip={`No proforma with given version found (${this.props.match.params.version})`}
        />
      );

    if (isEmpty(proformaDetails))
      return fromAuction ? (
        <NoDataComponent
          title='No proforma found'
          tip={`No proforma found for RFQ (${auctionId})`}
        />
      ) : (
        <NoDataComponent
          title='No proforma found'
          tip={`No proforma found (${proformaId})`}
        />
      );

    return (
      <>
        <HeaderMetadata
          title={
            (showVersionInHeading ? `(v${currentProforma.version}) ` : ``) +
            `${currentProforma.id} (Proforma)`
          }
          description=''
        />
        {isUserImporter() && !this.state.switchingTab && (
          <HorizontalTimeline data={currentProforma} currentEntity='Proforma' />
        )}
        <Row type='flex' gutter={15}>
          <Col className='' span={24} xs={24} lg={12} xl={12}>
            <p className='pagetitle'>
              {'Pro-Forma Details ' +
                (showVersionInHeading
                  ? '(v' + currentProforma.version + ')'
                  : '')}
            </p>
          </Col>
          <Col className='proforma-actions' span={24} xs={24} lg={12} xl={12}>
            <Space>
              {isUserPlant() &&
                isUserManagerOfTeam(StringConstants.TEAMS.SOURCING) &&
                currentProforma.payTo.id ===
                  getUserDataFromCookie().company_id &&
                currentProforma.proformaReview.nextApprovalCompanyId ===
                  getUserDataFromCookie().company_id &&
                ProformaUtils.isOpenProforma(currentProforma) &&
                currentProforma.proformaPlannedOrders.length > 1 &&
                currentProforma.type === 'AUCTION' &&
                this.areSubPlantsPartOfAuction() && (
                  <Button
                    className='create-proforma-cta mr-15'
                    type='primary'
                    onClick={() =>
                      this.setState({ splitProformaModalVisible: true })
                    }
                  >
                    Split Proforma
                  </Button>
                )}
              <Button
                type='link'
                className='head-box-download'
                onClick={this.downloadDocument}
                loading={downloadLoading}
              >
                <DownloadOutlined />
                Download as PDF
              </Button>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24} xs={24} lg={24} xl={24}>
            {proformaDetails.length === 1 ? (
              <>
                {!readOnly &&
                  !isUserSubCompany() &&
                  this.props.user.teams.map((team) => {
                    let showApprovalStatus = true;
                    if (
                      team.name === StringConstants.TEAMS.EXECUTIVE &&
                      this.props.user.teams.length > 1
                    )
                      showApprovalStatus = false;

                    return (
                      showApprovalStatus && (
                        <ProformaActionCard
                          saveDetails={this.saveDetails}
                          toggleEdit={this.toggleEdit}
                          proformaStatus={this.getApprovalStatus(
                            proformaDetails,
                            team.name
                          )}
                          updateLoading={updateLoading}
                          toEdit={toEdit}
                          hideEdit={(currentTeam) =>
                            this.setState(
                              {
                                toEdit: false,
                                currentActioningTeam: currentTeam,
                              },
                              () =>
                                this.chooseTermsAndConditions.resetSelection()
                            )
                          }
                          showApproverCommentBox={this.showApproverCommentBox}
                          approveProforma={(currentTeam) =>
                            this.approveProforma(currentTeam)
                          }
                          currentTeam={team.name}
                          key={team.name}
                          isBuyerOrSeller={isUserBuyerOrSeller(team.name)}
                          isPartOfApprovalTeam={isUserPartOfApprovalTeam(
                            team.name
                          )}
                          currentActioningTeam={currentActioningTeam}
                        />
                      )
                    );
                  })}
                {(currentProforma.status ===
                  StringConstants.PROFORMA_STATUS.REVIEW_APPROVED ||
                  currentProforma.status ===
                    StringConstants.PROFORMA_STATUS.CONTRACT_GENERATED) &&
                  currentProforma.proformaInternalReview && (
                    <ProformaApprovalStatus
                      data={this.formApprovalStatusTableData(currentProforma)}
                    />
                  )}
                <AgreementTerm
                  data={currentProforma}
                  prevData={prevProforma}
                  toEdit={toEdit}
                  ref={(ref) => (this.agreementTerm = ref)}
                  readOnly={readOnly}
                />
              </>
            ) : (
              <>
                <div className='agreementtabstyle'>
                  <Tabs
                    className='notification-alerts auction-tabs proforma-tabs'
                    onChange={(value) => {
                      this.setState(
                        {
                          switchingTab: true,
                          currentProforma: this.findProformaById(
                            value.split('_')[0]
                          ),
                          prevProforma: this.findPrevProformaById(
                            value.split('_')[0]
                          ),
                        },
                        () => {
                          this.setState(
                            {
                              proformaStatus: this.getApprovalStatus([
                                this.state.currentProforma,
                              ]),
                              switchingTab: false,
                            },
                            () => this.commentsSection.fetchComments()
                          );
                        }
                      );
                    }}
                    activeKey={
                      currentProforma.id.toString() +
                      '_' +
                      currentProforma.auctionWinnerId.toString() +
                      '-' +
                      currentProforma.buyFrom.id.toString()
                    }
                  >
                    {proformaDetails.map((proforma) => {
                      return (
                        <TabPane
                          className='participant-tab'
                          tab={<span>{proforma.buyFrom.name}</span>}
                          key={
                            proforma.id +
                            '_' +
                            proforma.auctionWinnerId +
                            '-' +
                            proforma.buyFrom.id
                          }
                        >
                          {!readOnly &&
                            !isUserSubCompany() &&
                            this.props.user.teams.map((team) => {
                              let showApprovalStatus = true;
                              if (
                                team.name === StringConstants.TEAMS.EXECUTIVE &&
                                this.props.user.teams.length > 1
                              )
                                showApprovalStatus = false;

                              return (
                                showApprovalStatus && (
                                  <ProformaActionCard
                                    saveDetails={this.saveDetails}
                                    toggleEdit={this.toggleEdit}
                                    proformaStatus={this.getApprovalStatus(
                                      [proforma],
                                      team.name
                                    )}
                                    updateLoading={updateLoading}
                                    toEdit={toEdit}
                                    hideEdit={() =>
                                      this.setState({ toEdit: false }, () =>
                                        this.chooseTermsAndConditions.resetSelection()
                                      )
                                    }
                                    showApproverCommentBox={
                                      this.showApproverCommentBox
                                    }
                                    approveProforma={(currentTeam) =>
                                      this.approveProforma(currentTeam)
                                    }
                                    currentTeam={team.name}
                                    key={team.name}
                                    isBuyerOrSeller={isUserBuyerOrSeller(
                                      team.name
                                    )}
                                    isPartOfApprovalTeam={isUserPartOfApprovalTeam(
                                      team.name
                                    )}
                                    currentActioningTeam={currentActioningTeam}
                                  />
                                )
                              );
                            })}

                          {!this.state.switchingTab && (
                            <>
                              {(currentProforma.status ===
                                StringConstants.PROFORMA_STATUS
                                  .REVIEW_APPROVED ||
                                currentProforma.status ===
                                  StringConstants.PROFORMA_STATUS
                                    .CONTRACT_GENERATED) &&
                                currentProforma.proformaInternalReview && (
                                  <ProformaApprovalStatus
                                    data={this.formApprovalStatusTableData(
                                      currentProforma
                                    )}
                                  />
                                )}
                              <AgreementTerm
                                data={currentProforma}
                                prevData={prevProforma}
                                toEdit={toEdit}
                                ref={(ref) => (this.agreementTerm = ref)}
                                readOnly={readOnly}
                              />
                            </>
                          )}
                        </TabPane>
                      );
                    })}
                  </Tabs>
                </div>
              </>
            )}
          </Col>
          <Col span={24} xs={24} lg={24} xl={24}>
            {!this.state.switchingTab && (
              <ChooseTermsAndConditions
                data={termsAndConditions}
                originalSelection={
                  currentProforma && currentProforma.termsAndConditions
                    ? currentProforma.termsAndConditions
                    : []
                }
                toEdit={toEdit}
                ref={(ref) => (this.chooseTermsAndConditions = ref)}
                allowSelection={
                  isUserImporter() &&
                  isUserPartOfTeam(StringConstants.TEAMS.SOURCING) &&
                  !showVersionInHeading &&
                  toEdit
                }
              />
            )}
          </Col>
          {currentProforma &&
            currentProforma.proformaPlannedOrders &&
            currentProforma.specification && (
              <>
                <Col span={24} xs={24} lg={24} xl={24}>
                  {currentProforma.proformaPlannedOrders.length === 1 ? (
                    <CardWithTitle title='Planned Order Summary'>
                      <ReviewSinglePlannedOrder
                        data={{
                          details: currentProforma.proformaPlannedOrders[0],
                          warehouses: warehouses,
                        }}
                        prevData={{
                          details:
                            prevProforma &&
                            prevProforma.proformaPlannedOrders &&
                            prevProforma.proformaPlannedOrders[0],
                          warehouses: warehouses,
                        }}
                        entity='Proforma'
                        proformaId={currentProforma.id}
                        hideTitle={true}
                        ref={(ref) => (this.reviewSinglePlannedOrder = ref)}
                        toEdit={toEdit}
                      />
                    </CardWithTitle>
                  ) : (
                    <>
                      <CardWithTitle title='Planned Order Details'>
                        <PlannedOrderDataTable
                          data={{
                            proformaPlannedOrders:
                              currentProforma.proformaPlannedOrders,
                            warehouses: warehouses,
                          }}
                          prevData={{
                            proformaPlannedOrders:
                              prevProforma &&
                              prevProforma.proformaPlannedOrders,
                            warehouses: warehouses,
                          }}
                          proformaId={currentProforma.id}
                          hideTitle={true}
                          updatePlannedView={(data) =>
                            this.updatePlannedView(data)
                          }
                          toEdit={toEdit}
                          entity='Proforma'
                          ref={(ref) => (this.plannedOrderDataTable = ref)}
                        />
                      </CardWithTitle>
                      <CardWithTitle title='Planned Order Summary'>
                        <ReviewMultiplePlannedOrders
                          data={currentProforma}
                          entity='Proforma'
                          readOnly={true}
                        />
                      </CardWithTitle>
                    </>
                  )}
                </Col>
                <Col span={24} xs={24} lg={24} xl={24}>
                  <Specification
                    data={currentProforma.specification}
                    entity={{
                      type: 'PROFORMA',
                      id: currentProforma.id,
                    }}
                  />
                </Col>
              </>
            )}

          <Col span={24} xs={24} lg={24} xl={24}>
            <Comments
              ref={(ref) => (this.commentsSection = ref)}
              allowAdd={
                currentProforma.status ===
                  StringConstants.PROFORMA_STATUS.OPEN &&
                !showVersionInHeading &&
                !isUserSubCompany()
              }
              entity={StringConstants.COMMNENT_ENTITY.PROFORMA}
              id={currentProforma.id}
            />
          </Col>
          <ApproverCommentModal
            visible={approverCommentModalVisible}
            onSubmit={(comment) => {
              if (approverAction === StringConstants.APPROVER_ACTIONS.APPROVE)
                this.approveProforma(currentActioningTeam, comment);
              else this.rejectProforma(currentActioningTeam, comment);
            }}
            onCancel={() =>
              this.setState({ approverCommentModalVisible: false })
            }
          />
          <SplitProformaModal
            visible={splitProformaModalVisible}
            assignedPlannedOrders={proformaDetails[0].proformaPlannedOrders}
            onCancel={() => this.setState({ splitProformaModalVisible: false })}
            loading={splitProformaModalLoading}
            currentProforma={currentProforma}
            subCompanies={companyDetails.subCompanies}
            refreshPage={() =>
              this.props.history.push(
                UrlGenerator.getUiUrlWithPathParams(RouteConstants.AUCTION, {
                  id: currentProforma.auctionId,
                })
              )
            }
          />
        </Row>
      </>
    );
  }
}

export default SecureComponent(
  withRouter(ProformaReview),
  StringConstants.DEFAULT_COMPANY_PAGE_ACCESS,
  StringConstants.ACCESS_TO_ALL_TEAMS_AND_ROLES
);
