const { default: ApiManager } = require('src/api/ApiManager');
const { default: HttpConstants } = require('src/constants/HttpConstants');
const { default: UrlConstants } = require('src/constants/UrlConstants');

const DocumentService = {
  getDocumentTemplate: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_DOCUMENT_TEMPLATE.USECASE,
      HttpConstants.GET_METHOD,
      data
    );
  },
  uploadDocument: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPLOAD_DOCUMENT.USECASE,
      HttpConstants.POST_METHOD,
      data,
      true
    );
  },
  getUploadedDocuments: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_DOCUMENTS.USECASE,
      HttpConstants.GET_METHOD,
      data
    );
  },
  downloadDocument: (id) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.DOWNLOAD_DOCUMENT.USECASE,
      HttpConstants.GET_BLOB_METHOD,
      { ':id': id }
    );
  },
  getProcessingPlantDocumentTemplatesForImporter: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PROCESSING_PLANT_DOCUMENT_TEMPLATES_FOR_IMPORTER.USECASE,
      HttpConstants.GET_METHOD,
      data
    );
  },
  getProcessingPlantDocumentsForImporter: (id) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PROCESSING_PLANT_DOCUMENTS_FOR_IMPORTER.USECASE,
      HttpConstants.GET_METHOD,
      { ':id': id }
    );
  },
};

export default DocumentService;
