const { default: RouteConstants } = require('./RouteConstants');

const BreadcrumbConstants = {
  [[RouteConstants.PLANNED_ORDERS]]: [
    {
      text: 'Planned Orders',
    },
  ],
  [[RouteConstants.PLANNED_ORDER_DETAIL]]: [
    {
      text: 'Planned Orders',
      route: RouteConstants.PLANNED_ORDERS,
    },
    {
      text: ':id',
    },
  ],

  [[RouteConstants.SPECIFICATIONS]]: [
    {
      text: 'Specifications',
    },
  ],
  [[RouteConstants.SPECIFICATION_DETAIL]]: [
    {
      text: 'Specifications',
      route: RouteConstants.SPECIFICATIONS,
    },
    {
      text: ':id',
    },
  ],
  [[RouteConstants.SPECIFICATION_VERSION_DETAIL]]: [
    {
      text: 'Specifications',
      route: RouteConstants.SPECIFICATIONS,
    },
    {
      text: ':id',
      route: RouteConstants.SPECIFICATION_DETAIL,
    },
    {
      text: 'Version :version',
    },
  ],
  [[RouteConstants.SPECIFICATION_HOMEPAGE]]: [
    {
      text: 'Specifications',
      route: RouteConstants.SPECIFICATIONS,
    },
    {
      text: ':id',
      route: RouteConstants.SPECIFICATION_DETAIL,
    },
    {
      text: 'Overview',
    },
  ],
  [[RouteConstants.CREATE_SPECIFICATION]]: [
    {
      text: 'Specifications',
      route: RouteConstants.SPECIFICATIONS,
    },
    {
      text: 'Create',
    },
  ],
  [[RouteConstants.EDIT_SPECIFICATION]]: [
    {
      text: 'Specifications',
      route: RouteConstants.SPECIFICATIONS,
    },
    {
      text: ':id',
      route: RouteConstants.SPECIFICATION_DETAIL,
    },
    {
      text: 'Edit',
    },
  ],
  [[RouteConstants.CLONE_SPECIFICATION]]: [
    {
      text: 'Specifications',
      route: RouteConstants.SPECIFICATIONS,
    },
    {
      text: ':id',
      route: RouteConstants.SPECIFICATION_DETAIL,
    },
    {
      text: 'Clone',
    },
  ],

  [[RouteConstants.AUCTIONS]]: [
    {
      text: 'RFQs',
    },
  ],
  [[RouteConstants.AUCTION]]: [
    {
      text: 'RFQs',
      route: RouteConstants.AUCTIONS,
    },
    {
      text: ':id',
    },
  ],
  [[RouteConstants.AUCTION_DETAILS]]: [
    {
      text: 'RFQs',
      route: RouteConstants.AUCTIONS,
    },
    {
      text: ':id',
      route: RouteConstants.AUCTION,
    },
    {
      text: 'Details',
    },
  ],
  [[RouteConstants.BID_DETAILS]]: [
    {
      text: 'RFQs',
      route: RouteConstants.AUCTIONS,
    },
    {
      text: ':id',
      route: RouteConstants.AUCTION,
    },
    {
      text: ':plantId',
    },
  ],

  [[RouteConstants.PROFORMAS]]: [
    {
      text: 'Pro-formas',
    },
  ],

  [[RouteConstants.PROFORMA_REVIEW]]: [
    {
      text: 'RFQs',
      route: RouteConstants.AUCTIONS,
    },
    {
      text: ':id',
      route: RouteConstants.AUCTION,
    },
    {
      text: 'Proforma',
    },
  ],

  [[RouteConstants.PROFORMA_DETAIL]]: [
    {
      text: 'Proformas',
      route: RouteConstants.PROFORMAS,
    },
    {
      text: ':id',
    },
  ],

  [[RouteConstants.PROFORMA_VERSION_DETAIL]]: [
    {
      text: 'Proformas',
      route: RouteConstants.PROFORMAS,
    },
    {
      text: ':id',
      route: RouteConstants.PROFORMA_DETAIL,
    },
    {
      text: 'Version :version',
    },
  ],

  [[RouteConstants.PROCESSING_PLANTS]]: [
    {
      text: 'Processing Plants',
    },
  ],

  [[RouteConstants.PLACE_BID]]: [
    {
      text: 'RFQs',
      route: RouteConstants.AUCTIONS,
    },
    {
      text: ':id',
      route: RouteConstants.AUCTION,
    },
    {
      text: 'Quote',
    },
  ],

  [[RouteConstants.USER_PROFILE]]: [
    {
      text: 'My Profile',
    },
  ],
  [[RouteConstants.USER_CHANGE_PASSWORD]]: [
    {
      text: 'My Profile',
      route: RouteConstants.USER_PROFILE,
    },
    {
      text: 'Change Password',
    },
  ],
  [[RouteConstants.USER_PREFERENCE]]: [
    {
      text: 'My Profile',
      route: RouteConstants.USER_PROFILE,
    },
    {
      text: 'Preference',
    },
  ],
  [[RouteConstants.PURCHASE_ORDERS]]: [
    {
      text: 'Purchase Orders',
    },
  ],
  [[RouteConstants.PURCHASE_ORDER_DETAIL]]: [
    {
      text: 'Purchase Orders',
      route: RouteConstants.PURCHASE_ORDERS,
    },
    {
      text: ':id',
    },
  ],
  [[RouteConstants.SIMULATE_BID]]: [
    {
      text: 'RFQs',
      route: RouteConstants.AUCTIONS,
    },
    {
      text: ':id',
      route: RouteConstants.AUCTION,
    },
    {
      text: 'Sourcing Quote',
    },
  ],
};

export default BreadcrumbConstants;
