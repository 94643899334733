import { Checkbox, Col, Input, Modal, Row, Select, Table } from 'antd';

import { CaretDownOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import PaymentTerms from 'src/constants/PaymentTermConstants';
import PlannedOrderTableManager from 'src/utils/tables/PlannedOrderTableManager';
import React from 'react';
import StringConstants from 'src/constants/StringConstants';
import Title from 'src/components/common/typography/Title';
import { findBidIncotermByCode } from 'src/utils/BidUtils';
import { isEmpty } from 'lodash';

class AcceptOrEditBidModal extends React.Component {
  getColumnsForTable = () => {
    return PlannedOrderTableManager.getPlannedOrdersAssignmentColumns.call(
      this
    );
  };

  render() {
    const { visible, data, tableData, actionLoading, bidEditable, bidAction } =
      this.props;
    const rowSelection = {
      selectedRowKeys: data.selectedPlannedOrders,
      onChange: (selectedRowKeys) =>
        this.props.onSelectPlannedOrder(selectedRowKeys),
    };
    return (
      <Modal
        centered
        visible={visible}
        onOk={() => this.props.onOk()}
        onCancel={() => this.props.onCancel()}
        cancelButtonProps={{ className: 'cancel-outlined-button' }}
        okText='Accept'
        width={900}
        confirmLoading={actionLoading}
        closable={false}
        maskClosable={false}
      >
        <Title
          title={
            bidAction === StringConstants.BID_ACTIONS.ACCEPT
              ? 'Accept Quote For RFQ'
              : 'Edit Quote For RFQ'
          }
        />
        <p className='modal-title'>{data.companyName}</p>
        <span className='color-blue a3'> FCL Required: </span>
        <span className='field-value'>{data.totalFCL}</span>
        <br />
        <span className='color-blue a3'> FCL Promised: </span>
        <span className='field-value'>{data.fclPromised}</span>

        <p className='modal-title mt-10'>Item Details</p>
        <Row justify='space-between' align='middle'>
          <Col xs={24} lg={12}>
            <span className='color-blue a3'>Item: </span>
            <span className='field-value'>{data.item}</span>
          </Col>
          <Col xs={24} lg={12}>
            <span className='color-blue a3'>Addtional Item Number: </span>
            <span className='field-value'>{data.additionalItemNumber}</span>
          </Col>
        </Row>
        <Row justify='space-between' align='middle'>
          <Col xs={24} lg={12}>
            <span className='color-blue a3'>Product Form: </span>
            <span className='field-value'>{data.productForm}</span>
          </Col>
          <Col xs={24} lg={12}>
            <span className='color-blue a3'>Customer: </span>
            <span className='field-value'>{data.customer}</span>
          </Col>
        </Row>
        <div className='heading-divider'></div>
        <p className='review-selection-detail color-red'>
          {data.totalFCL > data.fclPromised &&
            'You want ' + data.totalFCL + ' FCL but '}
          <strong>{' ' + data.companyName + ' '}</strong>
          can provide {data.fclPromised} FCL.
        </p>
        <div className='mt-15 select-quantity'>
          <Table
            className='plan-order-over-tble'
            pagination={false}
            locale={{ filterConfirm: 'Apply' }}
            columns={this.getColumnsForTable()}
            dataSource={tableData}
            rowSelection={rowSelection}
            rowKey={(row) => row.id}
            scroll={{ x: 1350, y: 220 }}
          />
        </div>
        <div
          className='rscb-details  mt-15 select-quantity-input'
          span={24}
          xs={24}
          lg={24}
          xl={24}
        >
          <ul className='rscb-items'>
            <li className='details-section'>
              <span className='left-item'>Planned Orders Selected:</span>
              <span className='right-item'>
                {data.selectedPlannedOrders.length}
              </span>
            </li>
            <li className='details-section'>
              <span className='left-item'>Lbs Selected:</span>
              <span className='right-item'>
                <NumberFormat
                  value={data.selectedWeight}
                  suffix={' lb'}
                  thousandSeparator={true}
                  displayType='text'
                />
              </span>
            </li>
            <li className='details-section'>
              <span className='left-item'>Payment Terms:</span>
              <span className='right-item'>
                <Select
                  suffixIcon={<CaretDownOutlined />}
                  className='planned-vi-sel-btn'
                  defaultValue={data.paymentTerms}
                  style={{ width: 200 }}
                  value={data.paymentTerms}
                  onChange={(value) => this.props.onPaymentTermChange(value)}
                  disabled={!bidEditable}
                >
                  {PaymentTerms.map((value, index) => {
                    return (
                      <Select.Option value={value} key={index}>
                        {value}
                      </Select.Option>
                    );
                  })}
                </Select>
              </span>
            </li>
          </ul>
          <div className='heading-divider'></div>
          <p className='modal-title color-blue mt-15'>Award Price</p>
          <ul className='rscb-items'>
            <li className='details-section'>
              <span className='left-item'>Value:</span>
              <span className='right-item'>
                <Input
                  className='input-field'
                  style={{ width: 200 }}
                  value={data.finalBidValue}
                  onChange={(event) =>
                    this.props.onBidValueChange(event.target.value)
                  }
                  disabled={!bidEditable}
                />
              </span>
            </li>
            <li className='details-section'>
              <span className='left-item'>Incoterm:</span>
              <span className='right-item'>
                {!isEmpty(
                  findBidIncotermByCode(data.auctionIncoterms, 'CNF')
                ) && (
                  <Checkbox
                    className={
                      data.finalBidIncoterm === 'CNF'
                        ? 'incoterms incoterms-dark incoterm-cnf-dark'
                        : 'incoterms incoterm-cnf-light'
                    }
                    onChange={(e) => {
                      this.props.onIncotermChange('CNF');
                    }}
                    checked={data.finalBidIncoterm === 'CNF'}
                    disabled={!bidEditable}
                  >
                    Cost and
                    <br /> Freight
                  </Checkbox>
                )}

                {!isEmpty(
                  findBidIncotermByCode(data.auctionIncoterms, 'DDPCY')
                ) && (
                  <Checkbox
                    className={
                      data.finalBidIncoterm === 'DDPCY'
                        ? 'incoterms incoterms-dark incoterm-cy-dark'
                        : 'incoterms incoterm-cy-light'
                    }
                    onChange={(event) => {
                      this.props.onIncotermChange('DDPCY');
                    }}
                    checked={data.finalBidIncoterm === 'DDPCY'}
                    disabled={!bidEditable}
                  >
                    Delivered Duty Paid Container Yard
                  </Checkbox>
                )}
              </span>
            </li>
          </ul>
        </div>
      </Modal>
    );
  }
}

export default AcceptOrEditBidModal;
