const getErrorMessage = (
  errorResponse,
  defaultErrorMsg = 'Failed to process request'
) => {
  let errorMessage = defaultErrorMsg;
  if (errorResponse.data && errorResponse.data.message) {
    errorMessage = errorResponse.data.message;
  }
  return errorMessage;
};

const ApiUtils = {
  getErrorMessage,
};

export default ApiUtils;
