import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

class HeaderMetadata extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
  };

  render() {
    const { title, description } = this.props;
    return (
      <Helmet>
        <meta charSet='utf-8' />
        <title>{title} | Carava</title>
        {description && <meta name='description' content={description} />}
      </Helmet>
    );
  }
}

export default HeaderMetadata;
