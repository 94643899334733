import '../common/Auth.css';

import { Button, Col, Form, Input, Row } from 'antd';
import { Link, withRouter } from 'react-router-dom';

import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import UserService from 'src/services/UserService';
import { saveToken } from 'src/utils/auth/auth';
import { toast } from 'react-toastify';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isError: false,
    };
  }

  signIn = (values) => {
    this.setState({ loading: true });
    UserService.login(values)
      .then((response) => {
        saveToken(response.data.token);
        toast.success('Login Successful');
        this.props.history.push(RouteConstants.REDIRECT);
      })
      .catch((err) => {
        let errorMessage = 'Invalid Username/Password';
        // if (err && err.data && err.data.message)
        //   errorMessage = err.data.message;
        toast.error(errorMessage);
        this.setState({ loading: false, isError: true });
      });
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        <div className='login-left-details'>
          <img
            className='log-logo'
            src={require('../../../assets/images/logo.png').default}
            alt='Carava Logo'
          />
          <h2 className='all-form-heading'>Welcome Back!</h2>
          {/* Disabling Signup For Time being */}
          {/* <p className='log-sign-form-desc'>
            Don't have an account?{' '}
            <Link to={RouteConstants.SIGNUP}> Sign Up</Link>
          </p> */}
          {/* login form */}
          <Form
            className='all-form login-form'
            layout='vertical'
            onFinish={this.signIn}
          >
            <Form.Item
              label='Email Address'
              name='email'
              rules={[
                {
                  type: 'email',
                  message: 'Please enter a valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please enter your E-mail!',
                },
              ]}
              hasFeedback
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Password'
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button
                className='submit'
                type='primary'
                htmlType='submit'
                loading={loading}
              >
                Sign In
              </Button>
            </Form.Item>
          </Form>

          {/* /lofin form end */}
          <Row>
            <Col flex='auto' className='text-left'>
              <Link
                className='all-form-links'
                to={RouteConstants.FORGOT_PASSWORD}
              >
                Forget Password?
              </Link>
            </Col>
            <Col flex='auto' className='text-right'>
              <Link
                className='all-form-links-secondary'
                to={RouteConstants.AUTH0_LOGIN}
              >
                Login with SSO
              </Link>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(LoginForm);
