import { Input, Radio } from 'antd';

import StringConstants from 'src/constants/StringConstants';

export const getFactoryContactInformationFields = () => {
  return {
    ...StringConstants.FACTORY_CONTACT_INFORMATION_TYPES,
    contactTypes:
      StringConstants.FACTORY_CONTACT_INFORMATION_TYPES.contactTypes.map(
        (contactType) => {
          return {
            ...contactType,
            fields: [
              ...contactType.fields.map((field, index) => {
                return {
                  ...field,
                  name: 'contactName',
                  inputType: 'STRING',
                  validatesOtherFields: true,
                };
              }),
              ...StringConstants.COMMON_CONTACT_INFORMATION_FIELDS,
            ],
          };
        }
      ),
  };
};

export const getSubcontractorFactoryContactInformationFields = () => {
  return {
    ...StringConstants.SUBCONTRACTOR_FACTORY_CONTACT_INFORMATION_TYPES,
    contactTypes:
      StringConstants.SUBCONTRACTOR_FACTORY_CONTACT_INFORMATION_TYPES.contactTypes.map(
        (contactType) => {
          return {
            ...contactType,
            fields:
              StringConstants.COMMON_SUBCONTRACTOR_FACTORY_CONTACT_INFORMATION_FIELDS,
          };
        }
      ),
  };
};

export const getMandatoryFieldRule = () => {
  return {
    required: true,
    message: 'This field is mandatory !',
  };
};

export const getValidationRules = (field) => {
  let rules = [];
  if (field.isRequired) rules.push(getMandatoryFieldRule);

  if (field.inputType === 'INTEGER' || field.inputType === 'EMAIL') {
    let rule = {
      type: field.inputType === 'INTEGER' ? 'number' : 'email',
      message: 'The input is not valid !',
    };
    if (field.inputType === 'INTEGER') {
      rule.type = 'regexp';
      rule.pattern = new RegExp(/\d+/g);
    }

    rules.push(rule);
  }

  return rules;
};

const handleChange = (value, group, field, cb) => {
  if (field.validatesOtherFields) cb(value, group, field.name);
};

export const getInputElement = (group, field, cb) => {
  return field.inputType === 'STRING' ? (
    <Input
      className='input-field'
      onChange={(event) => handleChange(event.target.value, group, field, cb)}
    />
  ) : field.inputType === 'BOOLEAN' ? (
    <Radio.Group>
      <Radio value={field.hasBooleanValue ? true : 'Yes'}>Yes</Radio>
      <Radio value={field.hasBooleanValue ? false : 'No'}>No</Radio>
    </Radio.Group>
  ) : field.inputType === 'INTEGER' ? (
    <Input className='input-field number-field' type='number' />
  ) : field.inputType === 'DECIMAL' ? (
    <Input className='input-field' type='number' step='0.5' />
  ) : field.inputType === 'EMAIL' ? (
    <Input className='input-field' type='email' />
  ) : field.inputType === 'BIG_TEXT' ? (
    <Input.TextArea />
  ) : null;
};
