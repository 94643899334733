import StringConstants from 'src/constants/StringConstants';

const { isEmpty, sum } = require('lodash');

const ProformaUtils = {
  getProformaCountForCard: (status, proformaCountData, proformaData) => {
    let proformaCount = proformaCountData[status],
      totalProformas = !isEmpty(proformaCountData)
        ? sum(Object.values(proformaCountData))
        : 0;

    if (proformaCount > 0) {
      let percentage = ((proformaCount / totalProformas) * 100).toFixed(2);
      proformaData[status] = {
        count: proformaCount,
        percentage,
      };
    } else {
      proformaData[status] = {
        count: 0,
        percentage: 0,
      };
    }
  },
  isOpenProforma: (proforma) => {
    return proforma.status === StringConstants.PROFORMA_STATUS.OPEN;
  },
};

export default ProformaUtils;
