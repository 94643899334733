import './Reports.css';

import { Col, Row } from 'antd';

import AuctionOverview from 'src/components/auction/buyer/AuctionOverview';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import PipelineOverviewReport from './reports/PipelineOverviewReport';
import React from 'react';
import SecureComponent from 'src/components/common/SecureComponent';
import SourcingTrends from './reports/SourcingTrends';

class Reports extends React.Component {
  render() {
    return (
      <>
        <HeaderMetadata
          title={`Reports and Trends`}
          description='Detailed Analysis of Sourcing Trends, Specification-wise inventory, auctions'
        />
        <Row type='flex' gutter={15}>
          <Col className='' span={24} xs={24} lg={12} xl={12}>
            <p className='pagetitle'>Reports</p>
          </Col>
        </Row>
        <Row>
          <Col span={24} xs={24} lg={24} xl={24}>
            <SourcingTrends />
            <PipelineOverviewReport />
            <AuctionOverview />
          </Col>
        </Row>
      </>
    );
  }
}
export default SecureComponent(Reports);
