const MapConstants = {
  markerIcons: {
    greenIcon: {
      iconUrl:
        'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
      shadowUrl:
        'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    },
    shipIcon: {
      iconUrl: require('../assets/images/shipment/ship.png').default,
      shadowUrl:
        'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [30, 40],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [0, 0],
    },
    truckIcon: {
      iconUrl: require('../assets/images/shipment/truck.png').default,
      shadowUrl:
        'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [30, 45],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [0, 0],
    },
    sourceIcon: {
      iconUrl: require('../assets/images/shipment/source.png').default,
      shadowUrl:
        'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [45, 50],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [0, 0],
    },
    destinationIcon: {
      iconUrl: require('../assets/images/shipment/destination.png').default,
      shadowUrl:
        'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [40, 40],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [0, 0],
    },
    currentLocationIcon: {
      iconUrl: require('../assets/images/shipment/jumping_pin.gif').default,
      shadowUrl:
        'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [40, 40],
      iconAnchor: [20, 20],
      popupAnchor: [0, -40],
      shadowSize: [0, 0],
    },
  },
  tileLayer: {
    attribution: 'Tiles &copy; Esri &mdash; Source: Esri',
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Shaded_Relief/MapServer/tile/{z}/{y}/{x}',
  },
  overlayLayer: {
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
    overlayUrl:
      'https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png',
  },
  pathColors: {
    landColor: '#d8b400',
    seaColor: '#4a89f3',
  },
};

export default MapConstants;
