import SecureComponent from './SecureComponent';

const withSecureComponent =
  (accessToCompany, accessToTeamsAndRoles) => (WrappedComponent) => {
    return SecureComponent(
      WrappedComponent,
      accessToCompany,
      accessToTeamsAndRoles
    );
  };

export default withSecureComponent;
