import { Col, Row } from 'antd';
import React, { Component } from 'react';

import LoadingComponent from 'src/components/common/LoadingComponent';
import SecureComponent from 'src/components/common/SecureComponent';
import Specification from '../../components/specification/Specification';
import SpecificationService from 'src/services/SpecificationService';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';

class SpecificationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      specification: {},
      cin: null,
      version: null,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const { id, version } = this.props.match.params;

    SpecificationService.getSpecificationDetailForVersion(id, version)
      .then((response) => {
        this.setState({
          loading: false,
          specification: response.data,
          cin: id,
          version,
        });
      })
      .catch((err) => {
        this.setState({ loading: false, specification: {}, cin: id, version });
      });
  }

  render() {
    const { specification, loading } = this.state;
    const { id, version } = this.props.match.params;
    return loading ? (
      <LoadingComponent />
    ) : (
      <>
        <HeaderMetadata
          title={`v${version} ${id} ${specification.itemDescription} | Specification`}
          description={specification.itemDescription}
        />
        <Row>
          <Col span={24} xs={24} lg={24} xl={24}>
            <Specification
              data={specification}
              openAllPanels={true}
              showHeading={true}
              showVersionHistory={false}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default SecureComponent(SpecificationDetail);
