import { DatePicker, InputNumber, Modal, Select } from 'antd';
import React, { Component } from 'react';
import { isUndefined, range } from 'lodash';

import { CaretDownOutlined } from '@ant-design/icons';
import PaymentTerms from 'src/constants/PaymentTermConstants';
import PropTypes from 'prop-types';
import Title from 'src/components/common/typography/Title';
import { findCustomsCharge } from 'src/utils/BidUtils';
import moment from 'moment';

class EditAuctionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newTargetPrice: undefined,
      newAuctionEndAt: undefined,
      newPaymentTerms: this.props.data && this.props.data.paymentTerms,
      newIncotermCharge:
        this.props.data && findCustomsCharge(this.props.data.auctionIncoterms),
    };
  }

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
  };

  static defaultProps = {
    heading: 'Confirm your action',
    okText: 'Submit',
    cancelText: 'Cancel',
  };

  render() {
    const { visible, data } = this.props;
    const {
      newTargetPrice,
      newAuctionEndAt,
      newPaymentTerms,
      newIncotermCharge,
    } = this.state;
    return (
      <Modal
        className='confirmation-modal'
        centered
        visible={visible}
        okText='Submit'
        onOk={() =>
          this.props.onSubmit({
            newTargetPrice,
            newAuctionEndAt,
            newPaymentTerms,
            newIncotermCharge,
          })
        }
        onCancel={() => this.props.onCancel()}
        cancelButtonProps={{ className: 'cancel-outlined-button' }}
        okButtonProps={{
          disabled:
            (isUndefined(newTargetPrice) ||
              newTargetPrice === data.targetPrice) &&
            (isUndefined(newAuctionEndAt) ||
              moment(newAuctionEndAt).isSame(moment(data.endAt))) &&
            (isUndefined(newPaymentTerms) ||
              newPaymentTerms === data.paymentTerms) &&
            (isUndefined(newIncotermCharge) ||
              newIncotermCharge === findCustomsCharge(data.auctionIncoterms))
              ? true
              : false,
        }}
        closable={false}
        maskClosable={false}
      >
        <Title title='Edit RFQ' />

        <ul className='auction-edit-items'>
          <li className='auction-edit-row'>
            <span className='left-item'>Target Price (per LB): </span>
            <span className='right-item'>
              <InputNumber
                className='auction-edit-field'
                step={0.01}
                min={0}
                defaultValue={data.targetPrice}
                onChange={(value) => this.setState({ newTargetPrice: value })}
                formatter={(value) => `$ ${value}`}
              />
            </span>
          </li>
          <li className='auction-edit-row'>
            <span className='left-item'>End RFQ By:</span>
            <span className='right-item'>
              <DatePicker
                suffixIcon={
                  <img
                    className=''
                    src={
                      require('../../assets/images/Icon-Calendar-Black.svg')
                        .default
                    }
                    alt='species'
                  />
                }
                className='auction-edit-field'
                showTime={{ format: 'hh:mm A' }}
                format='DD MMM, YYYY hh:mm A'
                defaultValue={moment(data.endAt)}
                value={newAuctionEndAt}
                onChange={(value) => {
                  let m = moment(value);
                  if (
                    moment(value).date() === moment().date() &&
                    moment(value).hour() < moment().hour()
                  ) {
                    m.set({
                      hour: moment().hour(),
                      minute: moment().minute() + 1,
                    });
                  }
                  this.setState({ newAuctionEndAt: m });
                }}
                disabledDate={(date) =>
                  moment().diff(moment(date).startOf('day'), 'days') >= 1
                }
                disabledTime={(date) => {
                  if (moment(date).isSame(moment(), 'day'))
                    return {
                      disabledHours: () =>
                        range(0, 24).filter((hour) => moment().hour() > hour),
                      disabledMinutes: () =>
                        range(0, 60).filter(
                          (minute) => moment().minute() >= minute
                        ),
                    };
                  else return false;
                }}
                allowClear={false}
              />
            </span>
          </li>
          <li className='auction-edit-row'>
            <span className='left-item'>Payment Terms: </span>
            <span className='right-item'>
              <Select
                suffixIcon={<CaretDownOutlined />}
                style={{ width: '100%' }}
                className='planned-vi-sel-btn'
                onChange={(value) => this.setState({ newPaymentTerms: value })}
                defaultValue={newPaymentTerms}
                value={newPaymentTerms}
              >
                {PaymentTerms.map((value, index) => {
                  return (
                    <Select.Option value={value} key={index}>
                      {value}
                    </Select.Option>
                  );
                })}
              </Select>
            </span>
          </li>
          {data && data.auctionIncoterms && data.auctionIncoterms.length > 1 && (
            <li className='auction-edit-row'>
              <span className='left-item'>
                Estimated customs brokerage costs per lb
              </span>
              <span className='right-item'>
                <InputNumber
                  className='custom-input-field'
                  step={0.01}
                  min={0}
                  defaultValue={newIncotermCharge}
                  onChange={(value) =>
                    this.setState({ newIncotermCharge: value })
                  }
                  formatter={(value) => `$ ${value}`}
                />
              </span>
            </li>
          )}
        </ul>
      </Modal>
    );
  }
}

export default EditAuctionModal;
