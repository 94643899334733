import { Button, Col, Row, Table, Tabs } from 'antd';
import React, { Component } from 'react';

import ApiUtils from 'src/utils/ApiUtils';
import Card from 'src/components/common/cards/Card';
import CompanyService from 'src/services/CompanyService';
import ConfirmationModal from 'src/modals/common/ConfirmationModal';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import InvitePlantsModal from 'src/modals/processingPlants/InvitePlantsModal';
import ProcessingPlantDetailModal from 'src/modals/processingPlants/ProcessingPlantDetailModal';
import ProcessingPlantTableManager from 'src/utils/tables/ProcessingPlantTableManager';
import RouteConstants from 'src/constants/RouteConstants';
import SecureComponent from 'src/components/common/SecureComponent';
import StatusValidator from 'src/validators/StatusValidator';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { toast } from 'react-toastify';

class ProcessingPlants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      plants: {
        ALL: [],
        INVITED: [],
      },
      activeKey: undefined,
      inviteModalVisible: false,
      selectedPlant: {},
      plantDetailModalVisible: false,
      resendInviteModalVisible: false,
      revokeInviteModalVisible: false,
      modalLoading: false,
    };
  }

  componentDidMount() {
    let type = StatusValidator.getValidStatusForParam(
      StringConstants.PROCESSING_PLANT_TYPES,
      this.props.location.search,
      'type'
    );
    if (!type) {
      type = StringConstants.PROCESSING_PLANT_TYPES.ALL;
    }
    this.setState({ activeKey: type.toUpperCase() }, () =>
      this.fetchProcessingPlants()
    );
  }

  fetchProcessingPlants() {
    const { activeKey, plants } = this.state;
    if (plants[activeKey].length > 0) return;
    else if (activeKey === StringConstants.PROCESSING_PLANT_TYPES.ALL) {
      this.fetchAllProcessingPlants();
    } else {
      this.fetchInvitedProcessingPlants();
    }
  }

  fetchAllProcessingPlants = () => {
    this.setState({ loading: true });
    CompanyService.getAllProcessingPlants()
      .then((response) => {
        this.setState((prev) => ({
          plants: {
            ...prev.plants,
            [[StringConstants.PROCESSING_PLANT_TYPES.ALL]]: response.data,
          },
          loading: false,
        }));
      })
      .catch((err) => {
        let errorMsg = ApiUtils.getErrorMessage(
          err,
          'Failed to fetch processing plants at the moment'
        );
        toast.error(errorMsg);
        this.setState({ loading: false });
      });
  };

  fetchInvitedProcessingPlants = () => {
    this.setState({ loading: true });
    CompanyService.getInvitedProcessingPlants()
      .then((response) => {
        this.setState((prev) => ({
          plants: {
            ...prev.plants,
            [[StringConstants.PROCESSING_PLANT_TYPES.INVITED]]: response.data,
          },
          loading: false,
        }));
      })
      .catch((err) => {
        let errorMsg = ApiUtils.getErrorMessage(
          err,
          'Failed to get invited plants at the moment'
        );
        toast.error(errorMsg);
        this.setState({ loading: false });
      });
  };

  getColumnsForAllPlants = () => {
    const { plants } = this.state;
    const data = plants[StringConstants.PROCESSING_PLANT_TYPES.ALL];
    return ProcessingPlantTableManager.getColumnsForAllPlants.call(this, data);
  };

  getColumnsForInvitedPlants = () => {
    const { plants } = this.state;
    const data = plants[StringConstants.PROCESSING_PLANT_TYPES.INVITED];
    return ProcessingPlantTableManager.getColumnsForInvitedPlants.call(
      this,
      data
    );
  };

  onTabChange = (value) => {
    const url = UrlGenerator.getUiUrlWithQueryParams(
      RouteConstants.PROCESSING_PLANTS,
      {
        type: value,
      }
    );
    window.history.replaceState(null, null, url);
    this.setState({ activeKey: value }, () => this.fetchProcessingPlants());
  };

  closeInviteModal = (refresh = false) => {
    this.setState({ inviteModalVisible: false });
    this.fetchInvitedProcessingPlants();
  };

  openInviteModal = () => this.setState({ inviteModalVisible: true });

  handleModalSubmit = () => {
    const {
      selectedPlant,
      resendInviteModalVisible,
      revokeInviteModalVisible,
    } = this.state;
    this.setState({ modalLoading: true });
    if (resendInviteModalVisible) {
      CompanyService.resendInvite(selectedPlant.id)
        .then(() => {
          toast.success('Invite Resent Successfully');
          this.handleModalCancel();
          this.fetchInvitedProcessingPlants();
        })
        .catch((err) => {
          let errorMessage = ApiUtils.getErrorMessage(
            err,
            'Failed to resend invite'
          );
          toast.error(errorMessage);
          this.setState({ modalLoading: false });
        });
    } else if (revokeInviteModalVisible) {
      CompanyService.revokeInvite(selectedPlant.id)
        .then(() => {
          toast.success('Invite revoked successfully');
          this.handleModalCancel();
          this.fetchInvitedProcessingPlants();
        })
        .catch((err) => {
          let errorMessage = ApiUtils.getErrorMessage(
            err,
            'Failed to revoke invite'
          );
          toast.error(errorMessage);
          this.setState({ modalLoading: false });
        });
    }
  };

  handleModalCancel = () => {
    this.setState({
      modalLoading: false,
      selectedPlant: {},
      resendInviteModalVisible: false,
      revokeInviteModalVisible: false,
      plantDetailModalVisible: false,
    });
  };

  navigateToPlantHomepage = (plantId) => {
    this.props.history.push(
      UrlGenerator.getUiUrlWithPathParams(RouteConstants.PLANT_HOMEPAGE, {
        id: plantId,
      })
    );
  };

  render() {
    const {
      loading,
      plants,
      activeKey,
      inviteModalVisible,
      modalLoading,
      selectedPlant,
      revokeInviteModalVisible,
      resendInviteModalVisible,
      plantDetailModalVisible,
    } = this.state;
    return (
      <>
        <HeaderMetadata
          title={`Processing Plants`}
          description='View, Manage and Invite Processing Plants'
        />
        <Card fullHeight={true}>
          <Row>
            <Col xs={24} lg={24} xl={24}>
              <Tabs
                activeKey={activeKey}
                onChange={this.onTabChange}
                tabBarExtraContent={{
                  right: (
                    <Button
                      type='primary'
                      className='btn-sm btn-primary mb-10'
                      onClick={this.openInviteModal}
                    >
                      Invite Plant
                    </Button>
                  ),
                }}
              >
                <Tabs.TabPane
                  tab='All Plants'
                  key={StringConstants.PROCESSING_PLANT_TYPES.ALL}
                >
                  <Table
                    className='mt-10'
                    columns={this.getColumnsForAllPlants()}
                    loading={loading}
                    dataSource={
                      plants[StringConstants.PROCESSING_PLANT_TYPES.ALL]
                    }
                    rowKey={(row) => row.company.id}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab='Open Invites'
                  key={StringConstants.PROCESSING_PLANT_TYPES.INVITED}
                >
                  <Table
                    className='mt-10'
                    columns={this.getColumnsForInvitedPlants()}
                    loading={loading}
                    dataSource={
                      plants[StringConstants.PROCESSING_PLANT_TYPES.INVITED]
                    }
                    rowKey={(row) => row.id}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
          <InvitePlantsModal
            visible={inviteModalVisible}
            onClose={this.closeInviteModal}
          />

          <ProcessingPlantDetailModal
            visible={plantDetailModalVisible}
            onClose={this.handleModalCancel}
            data={selectedPlant}
          />

          <ConfirmationModal
            visible={revokeInviteModalVisible || resendInviteModalVisible}
            loading={modalLoading}
            negativeAction={revokeInviteModalVisible}
            heading={
              resendInviteModalVisible ? 'Resend Invite' : 'Revoke Invite'
            }
            title={
              resendInviteModalVisible
                ? 'Are you sure you want to resend the invite?'
                : 'Are you sure you want to revoke the invite?'
            }
            description={
              resendInviteModalVisible
                ? `An invite will be resent to ${selectedPlant.email}`
                : `${selectedPlant.email} will not longer have access to the invite link sent earlier`
            }
            okText={resendInviteModalVisible ? 'Yes, Resend' : 'Yes, Revoke'}
            onSubmit={this.handleModalSubmit}
            onCancel={this.handleModalCancel}
          />
        </Card>
      </>
    );
  }
}

export default SecureComponent(ProcessingPlants);
