import { Space, Table, Tag } from 'antd';
import { map, sumBy, uniq, upperCase } from 'lodash';

import NumberFormat from 'react-number-format';
import PlannedOrder from '../plannedOrder/PlannedOrder';
import React from 'react';
import TableFilterUtils from 'src/utils/TableFilterUtils';
import { getFormattedDate } from 'src/utils/DisplayUtils';

const rows = [
  {
    title: 'Company Planned Order',
    property: 'companyPlannedOrderNumber',
  },
  {
    title: 'Requested ETD',
    property: 'requestedEtd',
    customRender: ({ requestedEtd }) => getFormattedDate(requestedEtd),
  },
  {
    title: 'Requested ETA',
    property: 'requestedEta',
    customRender: ({ requestedEta }) => getFormattedDate(requestedEta),
  },
  {
    title: 'Location Code',
    property: 'warehouse.locationCode',
  },
  {
    title: 'Destination Port',
    property: 'destinationPort',
    customRender: ({ destinationPort }) =>
      destinationPort && destinationPort.name,
  },
  {
    title: 'Estimated Weight',
    property: 'totalWeight',
    customRender: ({ totalWeight, weightUom }) => (
      <NumberFormat
        value={totalWeight}
        suffix={` ${weightUom}`}
        thousandSeparator={true}
        displayType='text'
      />
    ),
  },
  {
    title: 'Planned Cases',
    property: 'plannedCases',
    customRender: ({ plannedCases }) => (
      <NumberFormat
        value={plannedCases}
        thousandSeparator={true}
        displayType='text'
      />
    ),
  },
];

class PlannedOrderReview extends React.Component {
  getColumnsForTable = () => {
    const { data } = this.props;
    const columns = [{ dataIndex: 'title', title: '' }];
    data.forEach((element) => {
      let obj = {};
      obj.dataIndex = element['id'];
      obj.title = element['id'];
      obj.ellipsis = { showTitle: true };
      columns.push(obj);
    });
    return columns;
  };

  getDataForTable = () => {
    const { data } = this.props;
    const organizedData = [];
    rows.forEach((row) => {
      let obj = {
        title: row.title,
      };
      data.forEach((element) => {
        if (row.customRender) {
          obj[element['id']] = row.customRender(element);
        } else
          obj[element['id']] = TableFilterUtils.getProperty(
            element,
            row.property
          );
      });
      organizedData.push(obj);
    });
    return organizedData;
  };

  render() {
    const { data } = this.props;
    return data.length > 1 ? (
      <>
        <div className='mt-20 mb-20 rscb-details'>
          <p className='modal-title'>
            <span>{upperCase(data[0].specification.customer)}</span> |{' '}
            <span>{data[0].specification.itemDescription}</span>
          </p>
          <ul className='rscb-items'>
            <li>
              <span className='left-item'>Item Number</span>
              <span className='right-item'>
                {data[0].specification.companyItemNumber}
              </span>
            </li>
            <li>
              <span className='left-item'>Additional Item Number</span>
              <span className='right-item'>
                {data[0].specification.additionalItemNumber}
              </span>
            </li>
            <li>
              <span className='left-item'>Product Form</span>
              <span className='right-item'>
                {data[0].specification.productForm}
              </span>
            </li>
            <li>
              <span className='left-item'>Customer</span>
              <span className='right-item'>
                {data[0].specification.customer}
              </span>
            </li>
            <li>
              <span className='left-item'>Brand</span>
              <span className='right-item'>{data[0].specification.brand}</span>
            </li>
            <li>
              <span className='left-item'>Total FCL</span>
              <span className='right-item'>{data.length}</span>
            </li>
            <li>
              <span className='left-item'>Total Weight</span>
              <span className='right-item'>
                <NumberFormat
                  value={sumBy(data, 'totalWeight')}
                  thousandSeparator={true}
                  displayType='text'
                  suffix=' lb'
                />
              </span>
            </li>
            <li>
              <span className='left-item'>Planned Cases</span>
              <span className='right-item'>
                <NumberFormat
                  value={sumBy(data, 'plannedCases')}
                  thousandSeparator={true}
                  displayType='text'
                />
              </span>
            </li>
            <li>
              <span className='left-item'>Species</span>
              <span className='right-item species-details-item'>
                <Space>
                  {uniq(
                    map(data[0].specification.seaFoodList, 'commonName')
                  ).map((seafoodItem, index) => {
                    return <Tag key={index}>{seafoodItem}</Tag>;
                  })}
                </Space>
              </span>
            </li>
          </ul>
        </div>
        <div className='mt-20'>
          <Table
            className='review-selection-tble'
            pagination={false}
            columns={this.getColumnsForTable()}
            dataSource={this.getDataForTable()}
            scroll={{ x: true }}
            rowKey={(row) => row.id}
          />
        </div>
      </>
    ) : (
      <PlannedOrder data={data[0]} showImage={false} />
    );
  }
}

export default PlannedOrderReview;
