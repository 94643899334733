import {
  isUserImporter,
  isUserPartOfApprovalTeam,
  isUserPartOfTeam,
  isUserPlant,
} from 'src/helpers/user';

import ApproversDashboard from './buyer/ApproversDashboard';
import ErrorComponent from 'src/components/common/ErrorComponent';
import ProcessingPlantDashboard from './plants/ProcessingPlantDashboard';
import React from 'react';
import SecureComponent from 'src/components/common/SecureComponent';
import SourcingDashboard from './buyer/SourcingDashboard';
import StringConstants from 'src/constants/StringConstants';
import SuperUserDashboard from './buyer/SuperUserDashboard';

class Dashboard extends React.Component {
  render() {
    if (isUserImporter()) {
      if (isUserPartOfTeam(StringConstants.TEAMS.EXECUTIVE))
        return <SuperUserDashboard />;
      else if (isUserPartOfTeam(StringConstants.TEAMS.SOURCING))
        return <SourcingDashboard />;
      else if (isUserPartOfApprovalTeam()) return <ApproversDashboard />;
      else
        return (
          <ErrorComponent
            title='No Dashboard exist for user role'
            tip='Please contact support for details'
          />
        );
    } else if (isUserPlant()) return <ProcessingPlantDashboard />;
    else
      return (
        <ErrorComponent
          title='No Dashboard exist for user role'
          tip='Please contact support for details'
        />
      );
  }
}

export default SecureComponent(
  Dashboard,
  StringConstants.DEFAULT_COMPANY_PAGE_ACCESS,
  StringConstants.ACCESS_TO_ALL_TEAMS_AND_ROLES
);
