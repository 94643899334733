const DisplayConstants = {
  DATE_FORMAT: 'll',
  DATE_TIME_FORMAT: 'lll',

  PREFERENCE: {
    'notification.email.auction_created': {
      DISPLAY_NAME: 'RFQ Created Email',
      NOTIFICATION: 'email',
      TYPE: 'boolean',
    },
    'notification.in_app.auction_created': {
      DISPLAY_NAME: 'RFQ Created Notification',
      NOTIFICATION: 'in-app',
      TYPE: 'boolean',
    },
    'notification.email.auction_status_changed': {
      DISPLAY_NAME: 'RFQ Update Email',
      NOTIFICATION: 'email',
      TYPE: 'boolean',
    },
    'notification.in_app.auction_status_changed': {
      DISPLAY_NAME: 'RFQ Update Notification',
      NOTIFICATION: 'in-app',
      TYPE: 'boolean',
    },
  },
};

export default DisplayConstants;
