import './SellerBidHistoryData.css';

import { Table, Tooltip } from 'antd';
import {
  displayDecimal,
  getBidDisplayText,
  getFormattedFullDate,
  getPercentageDisplayText,
} from 'src/utils/DisplayUtils';
import {
  findActualBiddedIncoterm,
  findPreviousBidForCompany,
  getDisplayIncoterm,
  getDisplayIncotermForLegend,
} from 'src/utils/BidUtils';

import BidHistoryChart from './BidHistoryChart';
import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { isUndefined } from 'lodash';
import moment from 'moment';

function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}

const columns = (props) => {
  const { bids } = props.bidDetails;
  return [
    {
      title: 'Date/Time',
      sorter: (a, b) => {
        return moment(a.createdAt).unix() - moment(b.createdAt).unix();
      },
      render: ({ createdAt }) => getFormattedFullDate(createdAt),
    },
    {
      title: 'New Value',
      sorter: (a, b) =>
        findActualBiddedIncoterm(a.bidIncoterms).amount -
        findActualBiddedIncoterm(b.bidIncoterms).amount,
      render: ({ bidIncoterms }) => {
        const incotermBidObj = getDisplayIncoterm(bidIncoterms);
        return (
          <>
            {bids.length > 0
              ? getBidDisplayText(incotermBidObj.amount, incotermBidObj.code)
              : ''}
            {incotermBidObj.isCalculated && (
              <Tooltip
                className=''
                title={
                  bids.length > 0
                    ? 'Estimated ' + incotermBidObj.code + ' value'
                    : ''
                }
                placement='bottomLeft'
              >
                <span className='estimate-pop'>
                  <InfoCircleOutlined />
                </span>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: 'Previous Value',
      sorter: (a, b) =>
        !isUndefined(findPreviousBidForCompany(bids, a)) &&
        !isUndefined(findPreviousBidForCompany(bids, b))
          ? findActualBiddedIncoterm(
              findPreviousBidForCompany(bids, a).bidIncoterms
            ).amount -
            findActualBiddedIncoterm(
              findPreviousBidForCompany(bids, b).bidIncoterms
            ).amount
          : 1,
      render: (bid) => {
        const previousBid = findPreviousBidForCompany(bids, bid);
        if (isUndefined(previousBid)) {
          return '-';
        } else {
          const incotermBidObj = getDisplayIncoterm(previousBid.bidIncoterms);
          return (
            <>
              {bids.length > 0
                ? getBidDisplayText(incotermBidObj.amount, incotermBidObj.code)
                : ''}
              {incotermBidObj.isCalculated && (
                <Tooltip
                  className=''
                  title={
                    bids.length > 0
                      ? 'Estimated ' + incotermBidObj.code + ' value'
                      : ''
                  }
                  placement='bottomLeft'
                >
                  <span className='estimate-pop'>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              )}
            </>
          );
        }
      },
    },
    {
      title: 'Change (in $)',
      sorter: (a, b) =>
        !isUndefined(findPreviousBidForCompany(bids, a)) &&
        !isUndefined(findPreviousBidForCompany(bids, b))
          ? getDisplayIncoterm(a.bidIncoterms).amount -
            getDisplayIncoterm(findPreviousBidForCompany(bids, a).bidIncoterms)
              .amount -
            (getDisplayIncoterm(b.bidIncoterms).amount -
              getDisplayIncoterm(
                findPreviousBidForCompany(bids, b).bidIncoterms
              ).amount)
          : 1,
      render: (bid) => {
        const previousBid = findPreviousBidForCompany(bids, bid);
        if (isUndefined(previousBid)) return '-';
        else {
          return displayDecimal(
            getDisplayIncoterm(bid.bidIncoterms).amount -
              getDisplayIncoterm(previousBid.bidIncoterms).amount
          );
        }
      },
    },
    {
      title: 'Change (in %)',
      sorter: (a, b) =>
        !isUndefined(findPreviousBidForCompany(bids, a)) &&
        !isUndefined(findPreviousBidForCompany(bids, b))
          ? (getDisplayIncoterm(a.bidIncoterms).amount -
              getDisplayIncoterm(
                findPreviousBidForCompany(bids, a).bidIncoterms
              ).amount) /
              getDisplayIncoterm(
                findPreviousBidForCompany(bids, a).bidIncoterms
              ).amount -
            (getDisplayIncoterm(b.bidIncoterms).amount -
              getDisplayIncoterm(
                findPreviousBidForCompany(bids, b).bidIncoterms
              ).amount) /
              getDisplayIncoterm(
                findPreviousBidForCompany(bids, b).bidIncoterms
              ).amount
          : 1,
      render: (bid) => {
        const previousBid = findPreviousBidForCompany(bids, bid);
        if (isUndefined(previousBid)) return '-';
        else {
          const previousDisplayIncoterm = getDisplayIncoterm(
            previousBid.bidIncoterms
          );
          return getPercentageDisplayText(
            ((getDisplayIncoterm(bid.bidIncoterms).amount -
              previousDisplayIncoterm.amount) /
              previousDisplayIncoterm.amount) *
              100
          );
        }
      },
    },
  ];
};

class SellerBidHistoryData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIncoterm:
        props.incoterms && props.incoterms.length > 1
          ? 'DDPCY'
          : props.bidDetails &&
            props.bidDetails.bids &&
            props.bidDetails.bids.length > 0
          ? getDisplayIncotermForLegend(this.props.bidDetails.bids)
          : props.incoterms && props.incoterms.length > 0
          ? props.incoterms[0].incoterm.code
          : undefined,
    };
  }

  handleChange = (value) => {
    this.setState({ selectedIncoterm: value });
  };
  render() {
    const { selectedIncoterm } = this.state;

    return (
      <>
        <div className='mb-15'>
          <Table
            className='auction-tab-table bid-history-table'
            locale={{ filterConfirm: 'Apply' }}
            pagination={false}
            columns={columns(this.props)}
            dataSource={this.props.bidDetails.bids}
            onChange={onChange}
            rowKey={(row) => row.id}
          />
        </div>

        <div className='chart-area'>
          <BidHistoryChart
            allBids={{
              ...this.props.bidDetails,
            }}
            auctionTargetPrice={this.props.bidDetails.auctionTargetPrice}
            selectedIncoterm={selectedIncoterm}
            incoterms={this.props.bidDetails.auctionIncoterms}
            lowestBid={this.props.bidDetails.lowestBid}
            chartId='quote-stats-chart-sider'
            handleChange={this.handleChange}
            showForSingleSeller={true}
          />
        </div>
      </>
    );
  }
}
export default SellerBidHistoryData;
