const ContainerTrackingConstants = {
  Vendors: 'vendor_name',
  VendorName: 'vendor_name',
  VendorID: 'vendor_id',
  PONumber: 'orderNo',
  ContainerNumber: 'containerNumber',
  BOLNumber: 'bolNo',
  Source: 'sourcePort',
  Destination: 'destinationPort',
  TrackingStatus: 'trackingStatus',
  ArrivedDate: 'arrivedDate',
  InitialArrivalDate: 'initialArrivalDate',
  Sealine: 'sealine.name',
  DashboardVendorName: 'vendor.vendor_name',
  ContentsField: 'contents',
  CreatedAt: 'createdAt',
  UpdatedAt: 'updatedAt',
  Contents: 'name',
  DepartureDate: 'departureDate',
  StartDate: 'startDate',
  EndDate: 'endDate',
  LatestETA: 'latestEta',
  Count: 'count',
  ID: 'id',
  Point: {
    Name: 'name',
  },
  Event: {
    Description: 'description',
  },
  LocationCode: 'location_code',

  FetchStatusTypes: {
    NotStarted: 'Not Started',
    InProgress: 'In Progress',
    Completed: 'Completed',
  },

  Tracking_status: 'trackingStatus',
  ContainerAtSea: 'AT_SEA',

  TrackingStatusTypes: {
    Arrived: 'ARRIVED',
    Tracking: 'TRACKING',
  },

  JSC_TRACKING: 'JSC_TRACKING',

  ContainerStatusTypes: {
    NEW: {
      label: 'New',
      color: 'purple',
      index: 0,
    },
    TRACKING: {
      label: 'Tracking',
      color: 'blue',
      index: 1,
    },
    JSC_TRACKING: {
      label: 'JSC Tracking',
      color: 'blue',
      index: 2,
    },
    REJECTED: {
      label: 'No Tracking Data',
      color: 'red',
      index: 3,
    },
    ARCHIVED: {
      label: 'Archived',
      color: 'geekblue',
      index: 4,
    },
    ARRIVED: {
      label: 'Arrived',
      color: 'green',
      index: 5,
    },
  },

  DisplayDate: 'Arrived/Expected Arrival Date',

  ETA: 'eta',
  ActualETA: 'actualETA',
  ArrivalStatusField: 'arrivalStatus',
  ArrivalStatus: {
    EARLY: 'Early',
    ON_TIME: 'On time',
    DELAYED: 'Late',
  },
  StatusColors: {
    ON_TIME: 'processing',
    EARLY: 'warning',
    DELAYED: 'error',
  },
  TagColors: {
    ON_TIME: 'processing',
    EARLY: 'warning',
    DELAYED: 'error',
    NEW: 'purple',
    ARCHIVED: 'geekblue',
    TRACKING: 'blue',
    JSC_TRACKING: 'blue',
    REJECTED: 'red',
    ARRIVED: 'green',
    AT_SEA: '#005385',
  },
  ProgressColors: {
    ON_TIME: '#13c2c2',
    EARLY: '#fa8c16',
    DELAYED: '#f5222d',
    TRACKING: 'blue',
    ARRIVED: '#52c41a',
  },
  NotificationTags: {
    ARRIVING_IN_WEEK: 'ARRIVING_IN_WEEK',
    ARRIVED_IN_TWO_DAYS: 'ARRIVED_IN_TWO_DAYS',
    ARRIVING_TODAY: 'ARRIVING_TODAY',
  },
  ChartColors: {
    TRACKING: '#13c2c2',
    REJECTED: '#f5222d',
    ARRIVED: '#52c41a',
    ON_TIME: '#13c2c2',
    EARLY: '#52c41a',
    LATE: '#f5222d',
  },

  TrackingStatusOrder: ['TRACKING', 'ARRIVED', 'REJECTED'],

  NotificationTagsOrder: [
    'ARRIVED_IN_TWO_DAYS',
    'ARRIVING_TODAY',
    'ARRIVING_IN_WEEK',
  ],

  ContainerInfoFields: {
    containerNo: {
      label: 'Container Number: ',
      position: 0,
    },
    orderNo: {
      label: 'PO No: ',
      position: 2,
    },
    bol: {
      label: 'Bill of Lading No: ',
      position: 4,
    },
    carrier: {
      label: 'Carrier: ',
      rowPosition: 'left',
    },
    latestETA: {
      label: 'ETA: ',
      position: 6,
    },
    arrivedDate: {
      label: 'Arrived Date: ',
      position: 6,
    },
    contents: {
      label: 'Contents',
      position: 1,
    },
    locationCode: {
      label: 'Warehouse',
      position: 3,
    },
    vendor: {
      label: 'Vendor Name: ',
      position: 5,
    },
    sealine: {
      label: 'Sealine: ',
      position: 7,
    },
  },
};

export default ContainerTrackingConstants;
