import { Col, Row, Table, Tabs } from 'antd';
import React, { Component } from 'react';
import {
  isUserBuyerOrSeller,
  isUserImporter,
  isUserPlant,
} from 'src/helpers/user';

import ApiUtils from 'src/utils/ApiUtils';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import HelpText from 'src/components/common/typography/HelpText';
import LoadingComponent from 'src/components/common/LoadingComponent';
import ProformaService from 'src/services/ProformaService';
import ProformaTableManager from 'src/utils/tables/ProformaTableManager';
import RouteConstants from 'src/constants/RouteConstants';
import SecureComponent from 'src/components/common/SecureComponent';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { getReadableDisplayName } from 'src/utils/DisplayUtils';
import { isUndefined } from 'lodash';
import { toast } from 'react-toastify';

const helpTextByStatus = {
  [[StringConstants.PROFORMA_STATUS.OPEN]]:
    'Proformas that are submitted for review from buyer/seller',
  [[StringConstants.PROFORMA_STATUS.REVIEW_APPROVED]]:
    'Proformas approved by buyer and seller with contract under review',
  [[StringConstants.PROFORMA_STATUS.CONTRACT_GENERATED]]:
    'Past Proformas with contracts in place',
};

class Proformas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      proformas: {
        OPEN: [],
        REVIEW_APPROVED: [],
        CONTRACT_GENERATED: [],
      },
      activeKey: undefined,
    };
  }

  componentDidMount() {
    let status = UrlGenerator.getParamsFromUrl(
      this.props.location.search,
      'status'
    );
    if (!status) {
      status = isUserBuyerOrSeller()
        ? StringConstants.PROFORMA_STATUS.OPEN
        : StringConstants.PROFORMA_STATUS.REVIEW_APPROVED;
    }
    this.setState({ activeKey: status.toUpperCase() }, () =>
      this.fetchProformas()
    );
  }

  fetchProformas() {
    const { activeKey } = this.state;
    this.setState({ loading: true });
    ProformaService.getAllProformas({ status: activeKey })
      .then((response) => {
        this.setState((prev) => ({
          proformas: {
            ...prev.proformas,
            [[activeKey]]: response.data,
          },
          loading: false,
        }));
      })
      .catch((err) => {
        let errorMsg = ApiUtils.getErrorMessage(
          err,
          'Failed to fetch proformas at the moment'
        );
        toast.error(errorMsg);
        this.setState({ loading: false });
      });
  }

  getColumnsForTable = () => {
    const { proformas, activeKey } = this.state;
    const data = proformas[activeKey];
    return isUserImporter()
      ? ProformaTableManager.getColumnsForImporter(data)
      : isUserPlant()
      ? ProformaTableManager.getColumnsForPlant(data)
      : [];
  };

  onTabChange = (value) => {
    const url = UrlGenerator.getUiUrlWithQueryParams(RouteConstants.PROFORMAS, {
      status: value,
    });
    window.history.replaceState(null, null, url);
    this.setState({ activeKey: value }, () => this.fetchProformas());
  };

  render() {
    const { loading, proformas, activeKey } = this.state;
    if (isUndefined(activeKey))
      return <LoadingComponent title='Loading Pro-formas' />;
    return (
      <>
        <HeaderMetadata
          title={`${getReadableDisplayName(activeKey)} Pro-forma`}
          description={helpTextByStatus[activeKey]}
        />
        <div className='ca-card-light h-100'>
          <Row>
            <Col xs={24} lg={24} xl={24}>
              <Tabs activeKey={activeKey} onChange={this.onTabChange}>
                <Tabs.TabPane
                  tab='Active Pro-formas'
                  key={StringConstants.PROFORMA_STATUS.OPEN}
                ></Tabs.TabPane>
                <Tabs.TabPane
                  tab='Pending Pro-formas'
                  key={StringConstants.PROFORMA_STATUS.REVIEW_APPROVED}
                ></Tabs.TabPane>
                <Tabs.TabPane
                  tab='Pro-formas History'
                  key={StringConstants.PROFORMA_STATUS.CONTRACT_GENERATED}
                ></Tabs.TabPane>
              </Tabs>
              {activeKey && helpTextByStatus[activeKey] && (
                <HelpText
                  message={helpTextByStatus[activeKey]}
                  showIcon={true}
                />
              )}
              <Table
                className='mt-10'
                columns={this.getColumnsForTable()}
                loading={loading}
                dataSource={proformas[activeKey]}
                rowKey={(row) => row.id}
                pagination={
                  proformas[activeKey] && proformas[activeKey].length >= 10
                }
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default SecureComponent(Proformas);
