import './ChoosePlantsTable.css';

import { Button, Input, Rate, Space, Table, Tag } from 'antd';
import { capitalize, find, isEmpty, map, uniq } from 'lodash';

import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import NumberFormat from 'react-number-format';
import ProcessingPlantDetailModal from 'src/modals/processingPlants/ProcessingPlantDetailModal';
import PropTypes from 'prop-types';
import React from 'react';
import TableFilterUtils from 'src/utils/TableFilterUtils';
import { toast } from 'react-toastify';

const { Search } = Input;

// function onChange(pagination, filters, sorter, extra) {
//   console.log('params', pagination, filters, sorter, extra);
// }

class ChoosePlantsTable extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      plantDetailModalVisible: false,
      selectedRowKeys: this.props.selectedData
        ? this.props.selectedData.map((b) => b.id)
        : [],
      loading: false,
      selectedPlant: {},
    };
  }

  hideModal = () => {
    this.setState({ plantDetailModalVisible: false, selectedPlant: {} });
  };

  showModal = (plant) => {
    this.setState({ selectedPlant: plant, plantDetailModalVisible: true });
  };

  onSelectChange = (selectedRowKeys) => {
    if (this.props.onSelectChange) this.props.onSelectChange(selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  onSelectPlant = (plant) => {
    const { selectedRowKeys } = this.state;
    let newSelectedRowKeys = [...selectedRowKeys];
    newSelectedRowKeys.push(plant.company.id);
    this.setState({ selectedRowKeys: newSelectedRowKeys });
  };

  getColumnsForTable = () => {
    const { data } = this.props;
    const columns = [
      {
        title: 'Processing Plant',
        dataIndex: 'company',
        filters: TableFilterUtils.getFilterDropdownValues(data, 'company.name'),
        filterMultiple: true,
        onFilter: (value, record) => record.company.name.indexOf(value) >= 0,
        render: (company) => company.name,
      },
      {
        title: 'Country',
        dataIndex: 'company',
        filters: TableFilterUtils.getFilterDropdownValues(
          data,
          'company.country'
        ),
        filterMultiple: true,
        onFilter: (value, record) => record.company.country.indexOf(value) >= 0,
        render: (company) => capitalize(company.country),
      },
      {
        title: 'Species Capabilities',
        dataIndex: 'company',
        ellipsis: {
          showTitle: false,
        },
        width: 200,
        render: ({ seaFoodList }) =>
          uniq(map(seaFoodList, 'commonName')).map((seafoodItem, index) => {
            return <Tag key={index}>{seafoodItem}</Tag>;
          }),
      },
      {
        title: 'User Notes',
        dataIndex: 'companyNote',
        render: (companyNote) => (companyNote ? companyNote : '-'),
      },
      {
        title: 'Total LBS Sold',
        dataIndex: 'company',
        render: (company) => {
          const size = Math.floor(Math.random() * (95000 - 35000 + 1)) + 35000;
          return (
            <NumberFormat
              value={size}
              suffix='lbs'
              thousandSeparator={true}
              displayType='text'
            />
          );
        },
      },
      {
        title: 'Total $ Sold',
        dataIndex: 'company',
        render: (company) => {
          const size = Math.floor(Math.random() * (95000 - 35000 + 1)) + 35000;
          return (
            <NumberFormat
              value={size * 4}
              prefix='$'
              thousandSeparator={true}
              displayType='text'
            />
          );
        },
      },
      {
        title: 'Delivery Score',
        dataIndex: 'deliveryscore',

        sorter: (a, b) => a.caravaid - b.caravaid,
        render: (text, rating) => (
          <Space
            className='deliver-star'
            direction='vertical'
            align='start'
            size='small'
          >
            <Rate
              disabled
              allowHalf
              defaultValue={Math.floor(Math.random() * 5) + 1}
            />
          </Space>
        ),
      },
      {
        title: '',
        key: 'action',
        render: (companyObj) => (
          <div className='text-center'>
            <Button
              className='primary-button'
              type='primary'
              onClick={() => this.showModal(companyObj)}
            >
              View
            </Button>
          </div>
        ),
      },
    ];
    return columns;
  };

  getSelectedPlants = () => {
    const { selectedRowKeys } = this.state;
    if (isEmpty(selectedRowKeys)) toast.warning('No Plants Selected');
    return selectedRowKeys;
  };

  isPlantSelected = (plant) => {
    const { selectedData } = this.props;

    return find(selectedData, function (selectedPlant) {
      return plant.company.id === selectedPlant.id;
    });
  };

  render() {
    const { selectedRowKeys, selectedPlant, plantDetailModalVisible } =
      this.state;
    const { data, readOnly, hideCard, selectMultiple, addOnly } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      type: selectMultiple ? 'checkbox' : 'radio',
      getCheckboxProps: (record) => {
        if (addOnly) return { disabled: this.isPlantSelected(record) };
        else return { disabled: false };
      },
    };

    return !hideCard ? (
      <CardWithTitle
        title='Choose Compliant Plants'
        action={
          <Search
            className='plordfull-search'
            placeholder='Search anything here...'
            style={{ width: 350 }}
            enterButton='Search'
          />
        }
      >
        <Table
          className='plan-order-over-tble'
          locale={{ filterConfirm: 'Apply' }}
          rowSelection={readOnly ? false : rowSelection}
          rowKey={(row) => row.company.id}
          columns={this.getColumnsForTable()}
          dataSource={data}
        />

        <ProcessingPlantDetailModal
          visible={plantDetailModalVisible}
          onClose={this.hideModal}
          data={selectedPlant}
        />
      </CardWithTitle>
    ) : (
      <>
        <Table
          className='plan-order-over-tble'
          locale={{ filterConfirm: 'Apply' }}
          rowSelection={readOnly ? false : rowSelection}
          rowKey={(row) => row.company.id}
          columns={this.getColumnsForTable()}
          dataSource={data}
        />
        <ProcessingPlantDetailModal
          visible={plantDetailModalVisible}
          onClose={this.hideModal}
          data={selectedPlant}
        />
      </>
    );
  }
}
export default ChoosePlantsTable;
