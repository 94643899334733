import { Col, Row } from 'antd';
import React, { Component } from 'react';

import LoadingComponent from 'src/components/common/LoadingComponent';
import ProformaReview from './view/ProformaReview';
import ProformaService from 'src/services/ProformaService';
import SecureComponent from 'src/components/common/SecureComponent';

class ProformaVersionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      proforma: {},
      cin: null,
      version: null,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const { id, version } = this.props.match.params;

    ProformaService.getProformaDetailForVersion(id, version)
      .then((response) => {
        if (response.data === '')
          this.setState({ loading: false, proforma: null, cin: id, version });
        else
          this.setState({
            loading: false,
            proforma: response.data,
            cin: id,
            version,
          });
      })
      .catch((err) => {
        this.setState({ loading: false, proforma: null, cin: id, version });
      });
  }

  render() {
    const { proforma, loading } = this.state;
    return loading ? (
      <LoadingComponent />
    ) : (
      <Row>
        <Col span={24} xs={24} lg={24} xl={24}>
          <ProformaReview
            data={proforma}
            readOnly
            hideBreadCrumbs
            showVersionInHeading
          />
        </Col>
      </Row>
    );
  }
}

export default SecureComponent(ProformaVersionDetail);
