import '../Auction.css';

import { Link, withRouter } from 'react-router-dom';
import { Table, Tooltip } from 'antd';
import {
  getBidDisplayText,
  getFormattedFullDate,
} from 'src/utils/DisplayUtils';

import AuctionService from 'src/services/AuctionService';
import EmptySection from 'src/components/common/emptySection/EmptySection';
import { InfoCircleOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import StringConstants from 'src/constants/StringConstants';
import Title from '../../common/typography/Title';
import UrlGenerator from 'src/api/UrlGenerator';
import { isEmpty } from 'lodash';

class AuctionOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      auctions: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    AuctionService.getAllAuctions({
      limit: 5,
      status: StringConstants.AUCTION_STATUS.ACTIVE,
    })
      .then((response) => {
        this.setState({ loading: false, auctions: response.data });
      })
      .catch((err) => {
        this.setState({ loading: false, auctions: [] });
      });
  }

  getColumnsForTable = () => {
    const columns = [
      {
        title: 'Item',
        key: 'id',
        render: ({ customerItemNumber, itemDescription }) => (
          <>
            <strong>{itemDescription}</strong>
            <br />
            <span className='spantext'>{customerItemNumber}</span>
          </>
        ),
      },
      {
        title: 'Total FCLs',
        dataIndex: 'totalFCL',
        key: 'totalFCL',
        render: (totalFCL) => <div className='ml-15'>{totalFCL}</div>,
      },
      {
        title: 'Estimated Weight',
        sorter: (a, b) => a.totalWeight - b.totalWeight,
        render: ({ totalWeight, weightUom }) => (
          <div className='ml-15'>
            <NumberFormat
              value={totalWeight}
              thousandSeparator={true}
              displayType='text'
              suffix={' ' + weightUom}
            />
          </div>
        ),
      },
      // {
      //   title: 'Invited Plants',
      //   dataIndex: 'totalInvitedParticipants',
      //   key: 'totalInvitedParticipants',
      //   render: (invitedCompanies) =>
      //   <div className='ml-15'>{isNull(invitedCompanies) ? '-' : invitedCompanies}</div>,
      // },
      // {
      //   title: 'Bidders',
      //   dataIndex: 'totalParticipants',
      //   key: 'totalParticipants',
      //   render: (totalParticipants) => (
      //     <div className='ml-15'>
      //       {isNull(totalParticipants) ? '-' : totalParticipants}
      //     </div>
      //   ),
      // },
      {
        title: 'Lowest Quote',
        key: 'lowestBid',
        dataIndex: 'lowestBid',
        render: (lowestBid) => {
          if (isEmpty(lowestBid)) return '-';
          else if (lowestBid.isCalculated)
            return (
              <>
                <strong>
                  {getBidDisplayText(lowestBid.amount, lowestBid.incoterm.code)}
                  <Tooltip
                    className=''
                    title='This quote value is calculated'
                    placement='bottomLeft'
                  >
                    <InfoCircleOutlined style={{ marginLeft: 5 }} />
                  </Tooltip>
                </strong>
              </>
            );
          else
            return (
              <strong>
                {getBidDisplayText(lowestBid.amount, lowestBid.incoterm.code)}
              </strong>
            );
        },
      },
      {
        title: 'Ends by',
        dataIndex: 'endAt',
        key: 'endAt',
        render: (endAt) => getFormattedFullDate(endAt),
      },
      {
        title: '',
        key: 'action',
        render: (record) => (
          <div className='text-center'>
            <Link
              className='auctionoverview-cta ant-btn-primary'
              to={UrlGenerator.getUiUrlWithPathParams(RouteConstants.AUCTION, {
                id: record.id,
              })}
            >
              View
            </Link>
          </div>
        ),
      },
    ];
    return columns;
  };

  render() {
    const { loading, auctions } = this.state;
    const { fullHeight } = this.props;
    return (
      <div
        className='auctionoverview ca-card-light'
        style={fullHeight ? { height: '100%' } : {}}
      >
        <Title
          title='Active RFQs'
          action={
            auctions.length > 0 ? (
              <Link
                className='head-box-va'
                to={UrlGenerator.getUiUrlWithQueryParams(
                  RouteConstants.AUCTIONS,
                  {
                    status: StringConstants.AUCTION_STATUS.ACTIVE,
                  }
                )}
              >
                View All
              </Link>
            ) : null
          }
        />
        {loading || auctions.length > 0 ? (
          <Table
            className='auctionoverview'
            columns={this.getColumnsForTable()}
            loading={loading}
            dataSource={auctions}
            pagination={false}
            scroll={{ y: 200 }}
            rowKey={(row) => row.id}
          />
        ) : (
          <EmptySection
            imageSrc={
              require('../../../assets/images/no-data-images/nd-bid.png')
                .default
            }
            title='There are no active RFQs at the moment'
            showButton={true}
            buttonText='Create RFQ'
            action={() =>
              this.props.history.push(RouteConstants.PLANNED_ORDERS)
            }
          />
        )}
      </div>
    );
  }
}
export default withRouter(AuctionOverview);
