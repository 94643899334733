import { actionTypes } from 'src/actions/types';
import chat from './chat';
import { combineReducers } from 'redux';
import route from './route';
import user from './user';

const appReducer = combineReducers({ user, route, chat });

export const rootReducer = (state = {}, action) => {
  if (action.type === actionTypes.RESET_STATE) state = undefined;

  return appReducer(state, action);
};
