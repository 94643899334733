import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router';
import {
  deleteToken,
  getAuth0RawToken,
  saveAuth0Token,
  saveToken,
  verifyToken,
} from '../../utils/auth/auth';
import { fetchUser, fetchUserFailed } from 'src/actions/user';

import ApiUtils from 'src/utils/ApiUtils';
import LoadingComponent from 'src/components/common/LoadingComponent';
import RouteConstants from '../../constants/RouteConstants';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import UserService from 'src/services/UserService';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { getUserDataFromCookie } from 'src/utils/auth/AuthUtils';
import { isEmpty } from 'lodash';
import { parseHash } from '../../utils/auth/auth0';
import { toast } from 'react-toastify';

class RedirectComponent extends Component {
  setRoute = async (path, token, tokenPayload) => {
    try {
      let payload = {};
      if (token) {
        if (path !== RouteConstants.AUTH0_LOGIN) {
          try {
            const response = await UserService.verifyUserAccount(token);

            if (response.status === 201) {
              saveToken(response.data.token);
              this.props.fetchUser(payload, path);
              return;
            } else {
              toast.error('Cannot find user, please try later');
            }
          } catch (err) {
            let errorMessage = ApiUtils.getErrorMessage(err);
            toast.error(errorMessage);
            cookie.remove('token');
            sessionStorage.removeItem('state');
            deleteToken();
            this.props.history.push(RouteConstants.LOGIN);
          }
        }
        this.props.fetchUser(payload, RouteConstants.LOGIN);
      } else this.props.history.push(path);
    } catch (error) {
      console.error(error);
      this.props.fetchUserFailed(error, path);
    }
  };

  componentDidMount = () => {
    const loggedInUserData = getUserDataFromCookie();
    if (
      !isEmpty(loggedInUserData) &&
      loggedInUserData.auth_type === StringConstants.AUTH_TYPES.PASSWORD
    ) {
      let redirectionRoute = this.getRedirectionRoute(RouteConstants.DASHBOARD);
      this.props.fetchUser({}, redirectionRoute);
      return;
    } else {
      parseHash(async (err, result) => {
        if (result == null) {
          const token = await getAuth0RawToken();
          if (!token) {
            this.setRoute(RouteConstants.AUTH0_LOGIN);
            return;
          } else {
            let redirectionRoute = this.getRedirectionRoute();
            this.setRoute(redirectionRoute, token);
            return;
          }
        }
        if (err) {
          console.error('Error signing in', err);
          this.setRoute(RouteConstants.AUTH0_LOGIN);
          return;
        }

        verifyToken(result.idToken).then(async (valid) => {
          if (valid) {
            saveAuth0Token(result.idToken, result.accessToken);
            let redirectionRoute = this.getRedirectionRoute();
            this.setRoute(
              redirectionRoute,
              result.idToken,
              result.idTokenPayload
            );
            return;
          } else {
            this.setRoute(RouteConstants.AUTH0_LOGIN);
            return;
          }
        });
      });
    }
  };

  getRedirectionRoute = (defaultRoute = RouteConstants.DASHBOARD) => {
    let redirectionRoute = defaultRoute;
    if (this.props.location.search) {
      const route = UrlGenerator.getParamsFromUrl(
        this.props.location.search,
        'redirect'
      );
      if (route) redirectionRoute = route;
    }
    return redirectionRoute;
  };

  render() {
    if (this.props.route && this.props.route.redirectPath) {
      return <Redirect to={this.props.route.redirectPath} />;
    }

    return (
      <div style={{ height: '90vh' }}>
        <LoadingComponent title={'Logging you in...'} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    route: state.route,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (payload, redirectPath) =>
      dispatch(fetchUser(payload, redirectPath)),
    fetchUserFailed: (payload, redirectPath) =>
      dispatch(fetchUserFailed(payload, redirectPath)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RedirectComponent));
