import { Route, Switch } from 'react-router-dom';

import ApproverProformas from '../proforma/ApproverProformas';
import Auction from '../auction/Auction';
import AuctionDetails from '../auction/AuctionDetails';
import Auctions from '../auction/Auctions';
import BidDetails from '../auction/bid/BidDetails';
import ChatScreen from '../chat/ChatScreen';
import ChooseCompliantPlants from '../auction/buyer/create/ChooseCompliantPlants';
import CloneSpecification from '../specification/CloneSpecification';
import CompanyProfile from '../profile/company/CompanyProfile';
import CreateDirectProforma from '../proforma/CreateDirectProforma';
import CreateSpecification from '../specification/CreateSpecification';
import Dashboard from '../dashboard/Dashboard';
import EditSpecification from '../specification/EditSpecification';
import ErrorPage from '../ErrorPage';
import MainDashboard from '../../components/Dashboard/MainDashboard';
import PlaceBid from '../auction/plants/PlaceBid';
import PlannedOrderDetail from '../plannedOrder/PlannedOrderDetail';
import PlannedOrders from '../plannedOrder/PlannedOrders';
import PlantHomepage from '../PlantHomepage';
import ProcessingPlants from '../processingPlants/ProcessingPlants';
import ProformaReview from '../proforma/view/ProformaReview';
import ProformaVersionDetail from '../proforma/ProformaVersionDetail';
import Proformas from '../proforma/Proformas';
import PurchaseOrderAnalysis from '../purchaseOrder/poAnalysis/PurchaseOrderAnalysis';
import PurchaseOrderDetails from '../purchaseOrder/PurchaseOrderDetails';
import PurchaseOrders from '../purchaseOrder/PurchaseOrders';
import React from 'react';
import Reports from '../reports/Reports';
import ReviewAndCreateBid from '../auction/buyer/create/ReviewAuctionPlannedOrders';
import ReviewAndOpenAuction from '../auction/buyer/create/ReviewAndOpenAuction';
import RouteConstants from '../../constants/RouteConstants';
import SimulateBid from '../auction/bid/SimulateBid';
import SpecificationDetail from '../specification/SpecificationDetail';
import SpecificationOverview from '../specification/SpecificationOverview';
import SpecificationVersionDetail from '../specification/SpecificationVersionDetail';
import Specifications from '../specification/Specifications';
import StringConstants from '../../constants/StringConstants';
import TeamDetails from '../team/TeamDetails';
import TermsAndConditions from '../termsAndConditions/TermsAndConditions';
import UserChangePassword from '../profile/user/UserChangePassword';
import UserPreference from '../profile/user/UserPreference';
import UserProfile from '../profile/user/UserProfile';
import { isUserPartOfTeam } from '../../helpers/user';

const InternalRouter = () => {
  return (
    <Switch>
      <Route path={RouteConstants.DASHBOARD} exact component={Dashboard} />
      {/* Planned Orders */}
      <Route
        path={RouteConstants.PLANNED_ORDERS}
        exact
        component={PlannedOrders}
      />
      <Route
        path={RouteConstants.PLANNED_ORDER_DETAIL}
        exact
        component={PlannedOrderDetail}
      />

      {/* Auction */}
      <Route path={RouteConstants.AUCTIONS} exact component={Auctions} />
      <Route
        path={RouteConstants.AUCTION_STEP_1}
        exact
        component={ReviewAndCreateBid}
      />
      <Route
        path={RouteConstants.AUCTION_STEP_2}
        exact
        component={ChooseCompliantPlants}
      />
      <Route
        path={RouteConstants.AUCTION_STEP_3}
        exact
        component={ReviewAndOpenAuction}
      />
      <Route path={RouteConstants.AUCTION} exact component={Auction} />
      <Route
        path={RouteConstants.AUCTION_DETAILS}
        exact
        component={AuctionDetails}
      />
      <Route path={RouteConstants.BID_DETAILS} exact component={BidDetails} />

      {/* Specificaitons */}
      <Route
        path={RouteConstants.SPECIFICATIONS}
        exact
        component={Specifications}
      />
      <Route
        path={RouteConstants.CREATE_SPECIFICATION}
        exact
        component={CreateSpecification}
      />
      <Route
        path={RouteConstants.SPECIFICATION_DETAIL}
        exact
        component={SpecificationDetail}
      />
      <Route
        path={RouteConstants.SPECIFICATION_VERSION_DETAIL}
        exact
        component={SpecificationVersionDetail}
      />
      <Route
        path={RouteConstants.SPECIFICATION_HOMEPAGE}
        exact
        component={SpecificationOverview}
      />
      <Route
        path={RouteConstants.EDIT_SPECIFICATION}
        exact
        component={EditSpecification}
      />
      <Route
        path={RouteConstants.CLONE_SPECIFICATION}
        exact
        component={CloneSpecification}
      />

      {/* Proformas */}
      <Route
        path={RouteConstants.PROFORMA_REVIEW}
        exact
        render={() => <ProformaReview fromAuction={true} />}
      />
      <Route
        path={RouteConstants.PROFORMA_DETAIL}
        exact
        component={ProformaReview}
      />
      <Route
        path={RouteConstants.PROFORMAS}
        exact
        component={
          isUserPartOfTeam(StringConstants.TEAMS.EXECUTIVE) ||
          isUserPartOfTeam(StringConstants.TEAMS.SOURCING)
            ? Proformas
            : ApproverProformas
        }
      />

      <Route
        path={RouteConstants.PROFORMA_VERSION_DETAIL}
        exact
        component={ProformaVersionDetail}
      />
      <Route
        path={RouteConstants.CREATE_DIRECT_PROFORMA}
        exact
        component={CreateDirectProforma}
      />

      {/* Purchase Orders */}
      <Route
        path={RouteConstants.PURCHASE_ORDERS}
        exact
        component={PurchaseOrders}
      />
      <Route
        path={RouteConstants.PURCHASE_ORDER_DETAIL}
        exact
        component={PurchaseOrderDetails}
      />
      <Route
        path={RouteConstants.NAV_PURCHASE_ORDER_DETAIL}
        exact
        render={() => <PurchaseOrderDetails fromNav={true} />}
      />
      <Route
        path={RouteConstants.PURCHASE_ORDER_ANALYSIS}
        exact
        component={PurchaseOrderAnalysis}
      />

      {/* Processing Plants */}
      <Route
        path={RouteConstants.PROCESSING_PLANTS}
        exact
        component={ProcessingPlants}
      />
      <Route path={RouteConstants.PLACE_BID} exact component={PlaceBid} />
      <Route path={RouteConstants.SIMULATE_BID} exact component={SimulateBid} />
      <Route
        path={RouteConstants.PLANT_HOMEPAGE}
        exact
        component={PlantHomepage}
      />

      {/* Profiles */}
      <Route path={RouteConstants.USER_PROFILE} exact component={UserProfile} />
      <Route
        path={RouteConstants.USER_PREFERENCE}
        exact
        component={UserPreference}
      />
      <Route
        path={RouteConstants.USER_CHANGE_PASSWORD}
        exact
        component={UserChangePassword}
      />

      {/* Team */}
      <Route path={RouteConstants.TEAM_MEMBERS} exact component={TeamDetails} />

      {/* Report */}
      <Route path={RouteConstants.REPORT} exact component={Reports} />

      <Route path={RouteConstants.CHAT} exact component={ChatScreen} />

      <Route path={RouteConstants.CHAT_DETAIL} exact component={ChatScreen} />

      <Route
        path={RouteConstants.TERMS_AND_CONDITIONS}
        exact
        component={TermsAndConditions}
      />

      <Route
        path={RouteConstants.COMPANY_PROFILE}
        exact
        component={CompanyProfile}
      />

      <Route path='/components' component={MainDashboard} />

      {/* Error Page */}
      <Route path='*' component={ErrorPage} />
    </Switch>
  );
};

export default InternalRouter;
