import { Col, Row } from 'antd';
import React, { Component } from 'react';

import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import LoadingComponent from 'src/components/common/LoadingComponent';
import NoDataComponent from 'src/components/common/NoDataComponent';
import PlannedOrder from '../../components/plannedOrder/PlannedOrder';
import PlannedOrderService from 'src/services/PlannedOrderService';
import SecureComponent from 'src/components/common/SecureComponent';
import Specification from '../../components/specification/Specification';
import StringConstants from 'src/constants/StringConstants';
import { isEmpty } from 'lodash';

class PlannedOrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      plannedOrder: {},
      pon: null,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const pon = this.props.match.params.id;
    PlannedOrderService.getPlannedOrderDetail(pon)
      .then((response) => {
        this.setState({ loading: false, plannedOrder: response.data, pon });
      })
      .catch((err) => {
        console.log('Error', err);
        this.setState({ loading: false, plannedOrder: {}, pon });
      });
  }

  render() {
    const { plannedOrder, pon, loading } = this.state;
    if (loading) return <LoadingComponent />;
    return isEmpty(plannedOrder) ? (
      <NoDataComponent
        title='Planned Order not found'
        tip={`Planned Order (${pon}) does not exist`}
        fullScreen={true}
        headerMetadata={true}
      />
    ) : (
      <>
        <HeaderMetadata
          title={`${pon} (Planned Order)`}
          description={plannedOrder.specification.itemDescription}
        />
        <Row>
          <Col span={24} xs={24} lg={24} xl={24}>
            <CardWithTitle title='Planned Order'>
              <PlannedOrder
                data={plannedOrder}
                isEdit={false}
                showImage={false}
              />
            </CardWithTitle>
            <Specification data={plannedOrder.specification} />
          </Col>
        </Row>
      </>
    );
  }
}

export default SecureComponent(
  PlannedOrderDetail,
  [StringConstants.COMPANY_TYPES.IMPORTER],
  [
    ...StringConstants.DEFAULT_TEAM_PAGE_ACCESS,
    {
      name: StringConstants.TEAMS.ACCOUNT_MANAGERS,
    },
  ]
);
