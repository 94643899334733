import './AcceptInviteUser.css';

import { Button, Col, Form, Input, Row } from 'antd';

import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from 'lodash';

class AcceptInviteUser extends React.Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    submit: PropTypes.func.isRequired,
    values: PropTypes.object,
    buttonText: PropTypes.string,
  };

  static defaultProps = {
    buttonText: 'Proceed',
  };

  submitUserDetail = (values) => {
    this.props.submit(values.user);
  };

  render() {
    const { email, loading, values } = this.props;
    return (
      <Form
        ref={(ref) => (this.userForm = ref)}
        className='all-form signup-form'
        layout='vertical'
        initialValues={
          !isEmpty(values) ? { user: values } : { user: { email } }
        }
        onFinish={this.submitUserDetail}
      >
        {/* TODO Enable when we want users to upload images during signup */}
        {/* <div className='accept-invite-img-upload'>
            <div className='ai-userinfo-img'>
              <img
                className=''
                src={
                  require('../../../../assets/images/thumbnail-img.png').default
                }
                alt='Thumbnail'
              />
            </div>
            <div className='ai-userinfo-img-upload'>
              <Form.Item
                name='upload'
                label='Your Photo (Optional)'
                valuePropName='fileList'
              >
                <Upload>Upload</Upload>
              </Form.Item>
            </div>
          </div> */}
        <Row gutter={[20, 0]}>
          <Col xs={24} md={12}>
            <Form.Item
              name={['user', 'firstName']}
              label='First Name'
              rules={[
                { required: true, message: 'Please input your first name!' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name={['user', 'lastName']}
              label='Last Name'
              rules={[
                { required: true, message: 'Please input your last name!' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label='Email Address'
          name={['user', 'email']}
          rules={[
            {
              type: 'email',
              message: 'Please enter a valid e-mail!',
            },
            {
              required: true,
              message: 'Please input your e-mail!',
            },
          ]}
        >
          <Input disabled style={{ background: '#e6e6e6', color: '#231f20' }} />
        </Form.Item>
        <Form.Item
          name={['user', 'mobileNumber']}
          label='Phone (Optional)'
          rules={[
            {
              message: 'Please input your phone number!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={['user', 'password']}
          label='Password'
          rules={[
            {
              required: true,
              message: 'Please enter a valid password',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item className='steps-action text-right-impt'>
          <Button
            className='btn-primary mt-20'
            type='primary'
            htmlType='submit'
            loading={loading}
          >
            {this.props.buttonText}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
export default AcceptInviteUser;
