import Modal from 'antd/lib/modal/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import Title from 'src/components/common/typography/Title';

class DocumentPreviewModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    blobData: PropTypes.object.isRequired,
    additionalData: PropTypes.object,
    handleClose: PropTypes.func,
  };

  static defaultProps = {
    visible: false,
  };

  render() {
    const { visible, blobData, additionalData, handleClose } = this.props;

    return (
      <Modal
        className='single-btn-center-footer full-height-modal'
        centered
        visible={visible}
        width={1200}
        onCancel={handleClose}
        cancelText='Close'
        closable={false}
        maskClosable={false}
      >
        <Title
          title={
            blobData.docType +
            (additionalData
              ? ' for ' +
                additionalData.entity +
                ' - ' +
                additionalData.entityId
              : ' ')
          }
        />
        <iframe
          src={URL.createObjectURL(blobData.data)}
          title={blobData.docType}
          width='100%'
          height='100%'
        ></iframe>
      </Modal>
    );
  }
}

export default DocumentPreviewModal;
