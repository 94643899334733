import { Col, Row } from 'antd';
import React from 'react';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import AuctionOverview from '../../../components/auction/buyer/AuctionOverview';
import TotalAuctionCard from '../../../components/auction/buyer/sourcing/TotalAuctionsCard';
import PlannedOrderOverview from '../../../components/plannedOrder/PlannedOrderOverview';

class SourcingDashboard extends React.Component {
  render() {
    return (
      <>
        <HeaderMetadata
          title='Sourcing Dashboard | Carava'
          description='Manage Auctions, Planned Orders easily from this dashbaord'
        />
        <Row type='flex' gutter={15}>
          <Col className='' span={24} xs={24} lg={24} xl={24}>
            <p className='pagetitle'>Sourcing Dashboard</p>
          </Col>
          <Col className='' span={10} xs={10} lg={4} xl={4}>
            <TotalAuctionCard />
          </Col>
          <Col style={{ minHeight: 330 }} span={20} xs={20} lg={20} xl={20}>
            <AuctionOverview fullHeight={true} />
          </Col>
          {/* <Col className='' span={10} xs={10} lg={8} xl={8}>
            <ReminderOverview />
          </Col> */}
        </Row>
        <Row gutter={15}>
          <Col className='mt-15' span={10} xs={10} lg={24} xl={24}>
            <PlannedOrderOverview />
          </Col>
        </Row>
      </>
    );
  }
}
export default SourcingDashboard;
