const { default: ApiManager } = require('src/api/ApiManager');
const { default: HttpConstants } = require('src/constants/HttpConstants');
const { default: UrlConstants } = require('src/constants/UrlConstants');

const ChatService = {
  getChatToken: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_CHAT_TOKEN.USECASE,
      HttpConstants.GET_METHOD
    );
  },
};
export default ChatService;
