import RouteConstants from 'src/constants/RouteConstants';
import UrlGenerator from 'src/api/UrlGenerator';

const NotificationUtils = {
  getRedirectionUrl: (entityId, type) => {
    let url = '';
    switch (type) {
      case 'AUCTION_CREATED':
        url = UrlGenerator.getUiUrlWithPathParams(
          RouteConstants.AUCTION_DETAILS,
          {
            id: entityId,
          }
        );
        break;
      case 'AUCTION_STATUS_CHANGED':
        url = UrlGenerator.getUiUrlWithPathParams(RouteConstants.AUCTION, {
          id: entityId,
        });
        break;
      case 'PROFORMA_AWAITING_APPROVAL':
      case 'PROFORMA_APPROVED':
        url = UrlGenerator.getUiUrlWithPathParams(
          RouteConstants.PROFORMA_DETAIL,
          {
            id: entityId,
          }
        );
        break;
      default:
        url = '';
        break;
    }
    return url;
  },
};

export default NotificationUtils;
