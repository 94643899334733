import React, { Component } from 'react';
import { cloneDeep, filter, findIndex, forEach, isEmpty } from 'lodash';

import { CaretDownOutlined } from '@ant-design/icons';
import Chart from 'react-apexcharts';
import EmptySection from 'src/components/common/emptySection/EmptySection';
import LoadingComponent from 'src/components/common/LoadingComponent';
import PropTypes from 'prop-types';
import ReportConstants from 'src/constants/ReportConstants';
import { Select } from 'antd';
import StringConstants from 'src/constants/StringConstants';

const tagsExpected = [
  ReportConstants.PIPELINE_STATUS.US_COLD_STORAGE,
  ReportConstants.PIPELINE_STATUS.ON_WATER,
  ReportConstants.PIPELINE_STATUS.IN_PRODUCTION,
];
const options_ = {
  chart: {
    type: 'bar',
    height: 350,
    stacked: true,
    // stackType: '100%',
    toolbar: {
      show: false,
    },
    fontFamily: 'Roboto Condensed, sans-serif',
  },

  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: 24,
      borderRadius: 6,
    },
  },
  dataLabels: {
    enabled: false,
  },

  xaxis: {
    type: 'category',
    categories: [],
    axisTicks: {
      show: false,
    },
    title: {
      text: 'Quantity',
      style: {
        fontSize: '12px',
      },
    },
  },

  yaxis: {
    labels: {
      show: true,
      style: {
        fontSize: '14px',
      },
      maxWidth: 300,
    },
  },

  tooltip: {
    marker: {
      show: false,
    },
    y: {
      formatter: function (val) {
        return new Intl.NumberFormat().format(val) + ' cases';
      },
    },
  },
  fill: {
    opacity: 1,
    colors: ['#231F20', '#2170B8', '#0EBAB3'],
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'right',
    offsetX: 40,
    markers: {
      width: 12,
      height: 12,
      strokeWidth: 0,
      strokeColor: '#fff',
      fillColors: ['#231F20', '#2170B8', '#0EBAB3'],
      radius: 12,
      customHTML: undefined,
      onClick: undefined,
      offsetX: 0,
      offsetY: 0,
    },
  },
  grid: {
    show: true,
    borderColor: '#E0E2E3',
    strokeDashArray: 0,
    position: 'back',
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
};

class PipelineReportGraph extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    pipelineData: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      options: options_,
      series: [],
      height: 350,
      selectedProductType: StringConstants.PRODUCT_TYPES.SHRIMP,
    };
  }

  componentDidMount() {
    this.fetchReport(StringConstants.PRODUCT_TYPES.SHRIMP);
  }

  fetchReport = (value) => {
    const { pipelineData } = this.props;
    if (!isEmpty(value)) {
      let filteredData = filter(pipelineData, [
        'specification.productType',
        value,
      ]);
      const { options, series, height } =
        this.transformDataForGraph(filteredData);
      this.setState({ options, series, height, selectedProductType: value });
    } else {
      this.setState({
        options: options_,
        series: [],
        height: 350,
        selectedProductType: value,
      });
    }
  };

  transformDataForGraph = (pipelineData) => {
    const data = {},
      categories = [];
    pipelineData.sort((a, b) =>
      b.specification.id.localeCompare(a.specification.id)
    );
    forEach(tagsExpected, (tag) => (data[tag] = []));

    forEach(pipelineData, (row) => {
      if (!isEmpty(row.pipelineItems)) {
        categories.push(row.specification.itemDescription);
        forEach(tagsExpected, (tag) => {
          let index = findIndex(row.pipelineItems, { tag });
          if (index >= 0) {
            data[tag].push(row.pipelineItems[index].units);
          } else {
            data[tag].push(null);
          }
        });
      }
    });
    const options = cloneDeep(options_);
    options.xaxis.categories = categories;
    let height = categories.length * 50;
    if (categories.length < 10) height = 450;
    const series = [
      {
        name: 'In USA Cold Storage',
        data: data[ReportConstants.PIPELINE_STATUS.US_COLD_STORAGE],
      },
      {
        name: 'On The Water',
        data: data[ReportConstants.PIPELINE_STATUS.ON_WATER],
      },
      {
        name: 'On Order or In Production',
        data: data[ReportConstants.PIPELINE_STATUS.IN_PRODUCTION],
      },
    ];
    return { options, series, height };
  };

  render() {
    const { loading, pipelineData } = this.props;
    const { series, selectedProductType, options, height } = this.state;

    return loading ? (
      <LoadingComponent />
    ) : isEmpty(pipelineData) ? (
      <div>
        <EmptySection
          title='No data to analyze'
          imageSrc={
            require('../../../../assets/images/no-data-images/nd-planned-order.png')
              .default
          }
        />
      </div>
    ) : (
      <div>
        <div className='historicaltrend-header '>
          <div className='select-drop ht-header-select'>
            Product Type:
            <Select
              suffixIcon={<CaretDownOutlined />}
              className='planned-vi-sel-btn'
              value={selectedProductType}
              style={{ width: 300 }}
              onChange={(value) => this.fetchReport(value)}
            >
              <Select.Option value={StringConstants.PRODUCT_TYPES.SHRIMP}>
                Shrimp
              </Select.Option>
              <Select.Option value={StringConstants.PRODUCT_TYPES.FINFISH}>
                Fin-Fish (Tilapia)
              </Select.Option>
            </Select>
          </div>
        </div>
        <Chart
          options={options}
          series={series}
          type='bar'
          width='98%'
          height={height}
        />
      </div>
    );
  }
}

export default PipelineReportGraph;
