import React, { Component } from 'react';
import { Space, Typography } from 'antd';
import { getFormattedDate, getPortDisplayText } from 'src/utils/DisplayUtils';
import { isEmpty, isEqual, isUndefined } from 'lodash';
import moment, { isMoment } from 'moment';

import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import TableFilterUtils from 'src/utils/TableFilterUtils';

class RevisedDataView extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    type: PropTypes.string,
    attribute: PropTypes.string.isRequired,
    displayAttribute: PropTypes.string,
    updatedValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
    ]),
  };

  getDisplayText = (data) => {
    const { type } = this.props;
    if (type === 'Number') {
      return (
        <NumberFormat
          value={data}
          thousandSeparator={true}
          displayType='text'
        />
      );
    } else if (type === 'Weight') {
      return (
        <NumberFormat
          value={data}
          suffix={' lb'}
          thousandSeparator={true}
          displayType='text'
        />
      );
    } else if (type === 'Date') {
      return getFormattedDate(data);
    } else if (type === 'Port') {
      return getPortDisplayText(data);
    } else {
      return data;
    }
  };

  isSameData = (data1, data2) => {
    if (isMoment(data1) && isMoment(data2)) {
      return moment(data1).isSame(moment(data2), 'd');
    } else if (typeof data1 === 'object' && typeof data2 === 'object') {
      return isEqual(data1, data2);
    } else {
      return data1 === data2;
    }
  };

  render() {
    const { attribute, updatedValue, data } = this.props;
    let displayAttribute = attribute;
    if (this.props.displayAttribute) {
      displayAttribute = this.props.displayAttribute;
    }

    if (!isEmpty(data.plannedOrder)) {
      if (isUndefined(updatedValue)) {
        if (
          this.isSameData(
            TableFilterUtils.getProperty(data.plannedOrder, attribute),
            TableFilterUtils.getProperty(data, attribute)
          )
        )
          return this.getDisplayText(
            TableFilterUtils.getProperty(data, displayAttribute)
          );
        else
          return (
            <Space>
              <Typography.Text delete>
                {this.getDisplayText(
                  TableFilterUtils.getProperty(
                    data.plannedOrder,
                    displayAttribute
                  )
                )}
              </Typography.Text>
              {this.getDisplayText(
                TableFilterUtils.getProperty(data, displayAttribute)
              )}
            </Space>
          );
      } else {
        if (
          this.isSameData(
            updatedValue,
            TableFilterUtils.getProperty(data.plannedOrder, attribute)
          )
        )
          return this.getDisplayText(
            TableFilterUtils.getProperty(data.plannedOrder, displayAttribute)
          );
        else
          return (
            <Space>
              <Typography.Text delete>
                {this.getDisplayText(
                  TableFilterUtils.getProperty(
                    data.plannedOrder,
                    displayAttribute
                  )
                )}
              </Typography.Text>
              {this.getDisplayText(updatedValue)}
            </Space>
          );
      }
    } else {
      if (
        isUndefined(updatedValue) ||
        this.isSameData(
          updatedValue,
          TableFilterUtils.getProperty(data, attribute)
        )
      ) {
        return this.getDisplayText(
          TableFilterUtils.getProperty(data, displayAttribute)
        );
      } else {
        return (
          <Space>
            <Typography.Text delete>
              {this.getDisplayText(
                TableFilterUtils.getProperty(data, displayAttribute)
              )}
            </Typography.Text>
            {this.getDisplayText(updatedValue)}
          </Space>
        );
      }
    }
  }
}

export default RevisedDataView;
