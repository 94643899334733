import POTrendChart from 'src/components/purchaseOrder/poAnalysis/POTrendChart';
import PendingPOChart from 'src/components/purchaseOrder/poAnalysis/PendingPOChart';
import React from 'react';

class PurchaseOrderAnalysis extends React.Component {
  render() {
    return (
      <>
        <PendingPOChart />
        <POTrendChart />
      </>
    );
  }
}

export default PurchaseOrderAnalysis;
