import { Button, Form, Space, Tooltip, Upload } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import SpecificationUtils from '../SpecificationUtils';
import { getFormattedDate } from '../DisplayUtils';

function getUploadItem(template, uploadCb, removeCb) {
  return (
    <div className='specification-upload-area'>
      <Upload
        customRequest={(options) => {
          uploadCb(options);
          options.onSuccess({});
        }}
        onRemove={() => removeCb()}
        accept={template.acceptedFormats}
        maxCount={1}
      >
        <img
          className='specification-upload-icon'
          src={require('../../assets/images/upload-icon.png').default}
          alt='Upload'
        />
      </Upload>
    </div>
  );
}

function getColumns() {
  let uploadAction = {
    title: 'Upload',
    render: (template) => {
      return this.props.isPartOfForm ? (
        <Form.Item
          className='specification-input-item'
          name={template.displayName}
          rules={
            template.isMandatory
              ? [
                  SpecificationUtils.getValidationRule(
                    SpecificationUtils.ruleTypes.REQUIRED
                  ),
                ]
              : []
          }
        >
          {getUploadItem(
            template,
            (options) =>
              this.setState(
                {
                  [template.type + '_file']: options.file,
                  expiryDateModalVisible: template.expiryRequired,
                  currentTemplate: template.type,
                },
                () => {
                  if (this.props.setUploadButtonEnabled)
                    this.props.setUploadButtonEnabled(true);
                }
              ),
            () =>
              this.setState(
                {
                  [template.type + '_file']: undefined,
                },
                () => {
                  if (this.props.setUploadButtonEnabled)
                    this.props.setUploadButtonEnabled(this.anyFileUploaded());
                }
              )
          )}
        </Form.Item>
      ) : (
        getUploadItem(
          template,
          (options) =>
            this.setState(
              {
                [template.type + '_file']: options.file,
                expiryDateModalVisible: template.expiryRequired,
                currentTemplate: template.type,
              },
              () => {
                if (this.props.setUploadButtonEnabled)
                  this.props.setUploadButtonEnabled(true);
              }
            ),
          () =>
            this.setState(
              {
                [template.type + '_file']: undefined,
              },
              () => {
                if (this.props.setUploadButtonEnabled)
                  this.props.setUploadButtonEnabled(this.anyFileUploaded());
              }
            )
        )
      );
    },
    width: 200,
    align: 'center',
  };
  let columns = [
    {
      title: 'Document Type',
      render: (template) => {
        return (
          <span className='specification-input-item'>
            {template.displayName}
          </span>
        );
      },
      width: 150,
    },
    {
      title: 'Upload Status',
      render: (template) => {
        return template.uploadedFiles &&
          template.uploadedFiles.some((file) => !file.isExpired) ? (
          <Space>
            <CheckOutlined
              style={{
                marginRight: 5,
                color: '#36b44c',
              }}
            />
            {template.uploadedFiles &&
            template.uploadedFiles.some((file) => file.isExpired) ? (
              <Tooltip title='Some uploaded documents of this type have expired'>
                <InfoCircleOutlined />
              </Tooltip>
            ) : null}
          </Space>
        ) : (
          <Space>
            <CloseOutlined
              style={{
                marginRight: 5,
                color: '#ea1f37',
              }}
            />
            {template.uploadedFiles &&
            template.uploadedFiles.every((file) => file.isExpired) ? (
              <Tooltip title='All uploaded documents of this type have expired'>
                <InfoCircleOutlined />
              </Tooltip>
            ) : null}
          </Space>
        );
      },
      width: 150,
      align: 'center',
    },
    {
      title: 'Expiry Date',
      render: (template) => {
        if (template.uploadedFiles && template.uploadedFiles.length === 1)
          return (
            <span className='specification-input-item'>
              {template.uploadedFiles[0].expiryDate
                ? getFormattedDate(template.uploadedFiles[0].expiryDate)
                : '-'}
            </span>
          );
        else return '-';
      },
      width: 150,
      align: 'center',
    },
    {
      title: 'View/Download',
      render: (template) => {
        if (template.uploadedFiles && template.uploadedFiles.length === 1)
          return (
            <>
              <Button type='link' className='uploaded-document-action'>
                <EyeOutlined
                  className='mr-0'
                  onClick={() => this.viewDocument(template.uploadedFiles[0])}
                />
              </Button>
              <Button
                type='link'
                className='uploaded-document-action'
                onClick={() => this.downloadDocument(template.uploadedFiles[0])}
              >
                <DownloadOutlined className='mr-15' />
              </Button>
            </>
          );
        else return '-';
      },
      align: 'center',
      width: 150,
    },
  ];

  if (!this.props.readOnly) columns.push(uploadAction);

  return columns;
}

const UploadFilesTableManager = {
  getColumns,
};

export default UploadFilesTableManager;
