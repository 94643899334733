import './CreateSpecifications.css';

import { Button, Col, Row } from 'antd';
import { camelCase, uniq } from 'lodash';

import ApiUtils from 'src/utils/ApiUtils';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import { Form } from 'antd';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import LoadingComponent from 'src/components/common/LoadingComponent';
import MetadataService from 'src/services/MetadataService';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import SecureComponent from 'src/components/common/SecureComponent';
import SpecificationPanels from 'src/components/specification/specificationPanels/SpecificationPanels';
import SpecificationService from 'src/services/SpecificationService';
import StringConstants from 'src/constants/StringConstants';
import { getReadableDisplayName } from 'src/utils/DisplayUtils';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

class CreateSpecification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      customers: [],
      brands: [],
      seafoodList: [],
      acceptableMarketNames: [],
      countryNames: [],
      productForms: [],
      otherDropdowns: {},
      creatingSpec: false,
    };
    this.createSpecForm = React.createRef();
  }

  async componentDidMount() {
    this.setState({ loading: true });
    let { customers } = await this.fetchSpecificationCustomers();
    let { brands } = await this.fetchSpecificationBrands();
    let { seafoodList, acceptableMarketNames } = await this.fetchSeafoodList();
    let { countryNames } = await this.fetchCountries();

    this.setState({
      customers,
      selectedCustomer: customers.length > 0 ? customers[0] : undefined,
      brands,
      selectedBrand: brands.length > 0 ? brands[0] : undefined,
      seafoodList,
      acceptableMarketNames,
      countryNames,
      loading: false,
    });
  }

  fetchSpecificationCustomers = async () => {
    try {
      let customersRes = await MetadataService.getSpecificationCustomers();
      return {
        customers: customersRes.data,
      };
    } catch (err) {
      return { customers: [] };
    }
  };

  fetchSpecificationBrands = async () => {
    try {
      let brandsRes = await MetadataService.getSpecificationBrands();
      return {
        brands: brandsRes.data,
      };
    } catch (err) {
      return { brands: [] };
    }
  };

  fetchSeafoodList = async () => {
    try {
      let seafoodListRes = await MetadataService.getSeaFoodList();
      return {
        seafoodList: seafoodListRes.data,
        acceptableMarketNames: uniq(
          seafoodListRes.data.map((item) => item.acceptableMarketName)
        ).sort(),
      };
    } catch (err) {
      return { seaFoodList: [], acceptableMarketNames: [] };
    }
  };

  fetchCountries = async () => {
    try {
      let countriesRes = await MetadataService.getCountries();
      return {
        countryNames: countriesRes.data.map((country) => country.name).sort(),
      };
    } catch (err) {
      return { countryNames: [] };
    }
  };

  getOtherPanelData = () => {
    return this.specificationPanels.getData();
  };

  getAdditionalComments = (formValues) => {
    let additionalComments = [];
    StringConstants.SPECIFICATION_FIELDS.COMMENT_TYPES.forEach(
      (commentType) => {
        let updatedComment =
          formValues[
            camelCase(getReadableDisplayName(commentType)) + 'Comments'
          ];

        additionalComments.push({
          commentType,
          comments: updatedComment,
        });
      }
    );
    return additionalComments;
  };

  getCorporateAssurances = (formValues, panelData) => {
    let corporateAssurances = [];
    StringConstants.SPECIFICATION_FIELDS.ASSURANCE_TYPES.forEach(
      (assuranceType) => {
        let updatedCorporateAssuranceValue =
          panelData[camelCase(getReadableDisplayName(assuranceType))];
        let updatedCorporateAssuranceComments =
          formValues[
            camelCase(getReadableDisplayName(assuranceType)) + 'Comments'
          ];

        if (typeof updatedCorporateAssuranceValue === 'object')
          updatedCorporateAssuranceValue.forEach((value) =>
            corporateAssurances.push({
              assuranceType,
              comments: updatedCorporateAssuranceComments,
              value: value,
            })
          );
        else
          corporateAssurances.push({
            assuranceType,
            comments: updatedCorporateAssuranceComments,
            value: updatedCorporateAssuranceValue,
          });
      }
    );
    return corporateAssurances;
  };

  getCountriesAssociated = (formValues) => {
    let countriesAssociated = [];
    StringConstants.SPECIFICATION_FIELDS.ASSOCIATED_COUNTRY_TYPES.forEach(
      (countryType) => {
        let updatedValue =
          formValues[
            camelCase(getReadableDisplayName(countryType)) + 'CountryOfOrigin'
          ];

        updatedValue.forEach((value) =>
          countriesAssociated.push({
            category: countryType,
            country: value,
          })
        );
      }
    );
    return countriesAssociated;
  };

  getSeaFoodList = (selectedAcceptedMarketNames) => {
    const { seafoodList } = this.state;
    let updatedSeafoodList = seafoodList
      .filter((seafoodItem) => {
        return selectedAcceptedMarketNames.includes(
          seafoodItem.acceptableMarketName
        );
      })
      .map((item) => item.id);

    return updatedSeafoodList;
  };

  getStringFields = (formValues, otherFormData) => {
    let updatedStringFields = {};
    StringConstants.SPECIFICATION_FIELDS.UPDATABLE_STRING_FIELDS.forEach(
      (field) => {
        let updatedValue;
        if (field.referFrom === 'form') updatedValue = formValues[field.name];
        else updatedValue = otherFormData[field.name];

        if (field.name === 'moisturePercent' || field.name === 'netUnitSize')
          updatedValue = parseFloat(updatedValue);
        if (field.name === 'noOfUnitsInCase')
          updatedValue = parseInt(updatedValue);

        updatedStringFields[field.name] = updatedValue;
      }
    );

    return updatedStringFields;
  };

  onFormSubmit = async (formValues) => {
    this.setState({ creatingSpec: true });
    const { acceptableMarketName } = formValues;

    let otherFormData = this.getOtherPanelData();

    let additionalComments = this.getAdditionalComments(formValues);

    let corporateAssurances = this.getCorporateAssurances(
      formValues,
      otherFormData
    );

    let countriesAssociated = this.getCountriesAssociated(formValues);

    let seaFoodList = this.getSeaFoodList(acceptableMarketName);

    let createDataRequest = this.getStringFields(formValues, otherFormData);

    createDataRequest.additionalComments = additionalComments;

    createDataRequest.corporateAssurances = corporateAssurances;

    createDataRequest.countriesAssociated = countriesAssociated;
    createDataRequest.seaFoodList = seaFoodList;

    createDataRequest.purchaseBlocked = false;
    createDataRequest.salesBlocked = false;

    try {
      SpecificationService.createSpecification(createDataRequest)
        .then((response) => {
          this.specificationPanels.uploadDocumentsPanel
            .getUploadComponentRef()
            .uploadDocuments(response.data.id);
        })
        .catch((error) => {
          let errorMessage = ApiUtils.getErrorMessage(error);
          toast.error('Failed to create spec - ' + errorMessage);
          this.setState({ creatingSpec: false });
        });
    } catch (error) {
      console.log(error);
      let errorMessage = ApiUtils.getErrorMessage(error);
      toast.error('Failed to create spec - ' + errorMessage);
      this.setState({ creatingSpec: false });
    }
  };

  onFormFinishFailed = () => {
    toast.warning('Some fields have errors, please verify and re-submit');
  };

  updateFormFields = (updatedFormFields) => {
    this.specForm.setFieldsValue(updatedFormFields);
  };

  navigateToSpecDetail = () => {
    this.props.history.push(RouteConstants.SPECIFICATIONS);
  };

  render() {
    const {
      loading,
      customers,
      brands,
      seafoodList,
      acceptableMarketNames,
      countryNames,
      productForms,
      creatingSpec,
    } = this.state;

    if (loading) return <LoadingComponent />;

    return (
      <>
        <HeaderMetadata
          title={'Create Specification'}
          description='Create a new specification'
        />
        <Form
          layout='vertical'
          className='spec-form'
          onFinish={this.onFormSubmit}
          onFinishFailed={this.onFormFinishFailed}
          scrollToFirstError={true}
          ref={(ref) => (this.specForm = ref)}
        >
          <CardWithTitle title='Create Specification'>
            <SpecificationPanels
              ref={(ref) => (this.specificationPanels = ref)}
              basicItemData={{
                customers,
                brands,
              }}
              speciesData={{
                acceptableMarketNames,
                countryNames,
                seafoodList,
              }}
              physicalAttributesData={{ productForms }}
              uploadDocumentsData={{
                resetLoadingButton: () =>
                  this.setState({ creatingSpec: false }),
                fetchPageData: () =>
                  this.props.history.push(RouteConstants.SPECIFICATIONS),
                successMessage:
                  'Created specification and uploaded templates successfully',
                doNotFetchUploadedDocuments: true,
              }}
              updateFormFields={this.updateFormFields}
            />
          </CardWithTitle>
          <Row>
            <Col span={24} xs={24} lg={24} xl={24}>
              <Form.Item className='specification-input-item'>
                <div className='editprofile-btn-area mt-15 mb-15'>
                  <Button
                    className='primary-full mr-15'
                    type='primary'
                    onClick={() => this.specForm.submit()}
                    loading={creatingSpec}
                  >
                    Create Specification
                  </Button>
                  <Button
                    className='cancel-full'
                    onClick={() => this.navigateToSpecDetail()}
                  >
                    Cancel
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}
export default withRouter(
  SecureComponent(
    CreateSpecification,
    [StringConstants.COMPANY_TYPES.IMPORTER],
    [
      {
        name: StringConstants.TEAMS.ACCOUNT_MANAGERS,
        role: StringConstants.TEAM_ROLES.MANAGER,
      },
    ]
  )
);
