import { Typography } from 'antd';
import moment from 'moment';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class TimeTicker extends PureComponent {
  static propTypes = {
    time: PropTypes.any,
    prefix: PropTypes.string,
  };

  static defaultProps = {
    time: moment(),
    prefix: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      time: props.time,
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.updateTime.bind(this), 15000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateTime() {
    this.setState({
      time: moment(this.props.time),
    });
  }

  render() {
    const { className, prefix, time } = this.props;

    return (
      <Typography.Text className={className}>
        {prefix} {moment(time).fromNow()}
      </Typography.Text>
    );
  }
}

export default TimeTicker;
