import './ParticipationTabs.css';

import { Space, Table, Tabs, Tooltip } from 'antd';
import { filter, find } from 'lodash';
import { findChange, getDisplayIncoterm } from 'src/utils/BidUtils';
import {
  getBidDisplayText,
  getFormattedFullDate,
  getPercentageDisplayText,
} from 'src/utils/DisplayUtils';

import Card from 'src/components/common/cards/Card';
import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';
import SellerBidHistoryData from '../bid/SellerBidHistoryData';

const { TabPane } = Tabs;

class ParticipationTabs extends React.Component {
  getParticipantColumns = () => {
    return [
      // {
      //   title: 'Rank No.',
      //   // sorter: (a, b) => {
      //   //   return a.bids.length > 0 && b.bids.length > 0
      //   //     ? a.bids[0].rank - b.bids[0].rank
      //   //     : 0;
      //   // },
      //   render: ({ bids }) => {
      //     return bids.length > 0 ? (
      //       <span className='ml-15'>{bids[0].rank}</span>
      //     ) : (
      //       '-'
      //     );
      //   },
      // },
      {
        title: 'Current Quote',
        // sorter: (a, b) => {
        //   return a.bids.length > 0 && b.bids.length > 0
        //     ? getDisplayIncoterm(a.bids[0].bidIncoterms).amount -
        //         getDisplayIncoterm(b.bids[0].bidIncoterms).amount
        //     : 0;
        // },
        render: ({ bids }) => {
          const bid =
            bids.length > 0
              ? getDisplayIncoterm(bids[0].bidIncoterms)
              : undefined;
          return (
            <Space size='middle' align='start'>
              {bid ? getBidDisplayText(bid.amount, bid.code) : 'N/A'}{' '}
              {bid.isCalculated && (
                <Tooltip
                  className=''
                  title={
                    bids.length > 0 ? 'Estimated ' + bid.code + ' value' : ''
                  }
                  placement='bottomLeft'
                >
                  <span className='estimate-pop'>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              )}
              {/* {bids[0].rank === 1 ? (
                <div className='seller-bid-sub'>{'LB'}</div>
              ) : null}
              {find(this.props.plantBids.bids, function (o) {
                return o.id === bids[0].id;
              }) && <div className='current-plant-bid-sub'>{'ME'}</div>} */}
            </Space>
          );
        },
      },
      {
        title: 'Total FCL in quote',
        render: () =>
          this.props.auctionDetails && this.props.auctionDetails.totalFCL,
      },
      {
        title: 'FCL offered',
        // sorter: (a, b) => {
        //   return a.bids.length > 0 && b.bids.length > 0
        //     ? a.bids[0].fclAvailable - b.bids[0].fclAvailable
        //     : 0;
        // },
        render: ({ bids }) => (bids.length > 0 ? bids[0].fclAvailable : ''),
      },
      {
        title: 'Change (in $)',
        // sorter: (a, b) => {
        //   return a.bids.length > 0 && b.bids.length > 0
        //     ? findChange(a.bids) - findChange(b.bids)
        //     : 0;
        // },
        render: ({ bids }) => findChange(bids),
      },
      {
        title: 'Change (in %)',
        // sorter: (a, b) => {
        //   return findChange(a.bids) !== '-' &&
        //     a.bids.length > 1 &&
        //     findChange(b.bids) !== '-' &&
        //     b.bids.length > 1
        //     ? findChange(a.bids) /
        //         getDisplayIncoterm(a.bids[1].bidIncoterms).amount -
        //         findChange(b.bids) /
        //           getDisplayIncoterm(b.bids[1].bidIncoterms).amount
        //     : 1;
        // },
        render: ({ bids }) =>
          findChange(bids) !== '-' && bids.length > 1
            ? getPercentageDisplayText(
                (findChange(bids) /
                  getDisplayIncoterm(bids[1].bidIncoterms).amount) *
                  100
              )
            : '-',
      },
      {
        title: 'Date/Time',
        // sorter: (a, b) => {
        //   return a.bids.length > 0 && b.bids.length > 0
        //     ? moment(a.bids[0].createdAt).unix() -
        //         moment(b.bids[0].createdAt).unix()
        //     : 0;
        // },
        render: ({ bids }) =>
          bids.length > 0 ? getFormattedFullDate(bids[0].createdAt) : '-',
      },
      {
        title: 'Win Score',
        render: () => (
          <span
            className={
              this.props.auctionDetails.winScore > 80
                ? 'win-score-80-100'
                : this.props.auctionDetails.winScore > 50
                ? 'win-score-50-80'
                : 'win-score-0-50'
            }
          >
            {this.props.auctionDetails.winScore.toFixed(2)}{' '}
          </span>
        ),
      },
    ];
  };

  render() {
    const { data, plantBids } = this.props;
    const myBid = filter(data, (d) =>
      find(this.props.plantBids.bids, function (o) {
        return o.id === d.bids[0].id;
      })
    );
    return (
      <Card className='pai-section'>
        <Tabs
          className='notification-alerts auction-tabs vw-part-mb-hi-tab'
          defaultActiveKey='1'
        >
          {/*View Participation tab */}
          <TabPane
            className='participant-tab'
            tab={<span>Leaderboard</span>}
            key='1'
          >
            <Table
              className='auction-tab-table table-striped-rows mt-10'
              locale={{ filterConfirm: 'Apply' }}
              columns={this.getParticipantColumns()}
              dataSource={myBid}
              // rowClassName={() => 'current-plant-bid'}
              // rowClassName={(record) =>
              //   find(plantBids.bids, function (o) {
              //     return o.id === record.bids[0].id;
              //   })
              //     ? 'current-plant-bid'
              //     : ''
              // }
              rowKey={(record) =>
                record.bids && record.bids.length > 0 && record.bids[0].id
              }
              pagination={false}
            />
            {/* <div className='lb-denote-div'>
              <span className='seller-bid-sub'>LB</span> Lowest Quote
            </div> */}
          </TabPane>

          {/* My Bid History tab */}
          <TabPane tab={<span>My Quote History</span>} key='2'>
            <SellerBidHistoryData
              bidDetails={{
                ...plantBids,
                auctionIncoterms: this.props.auctionDetails.auctionIncoterms,
                lowestBid: this.props.auctionDetails.lowestBid,
              }}
            />
          </TabPane>
          {/* My Bid History tab end */}
        </Tabs>
      </Card>
    );
  }
}

export default ParticipationTabs;
