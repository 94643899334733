import '../Auction.css';

import { isEmpty, sum } from 'lodash';

import AuctionService from 'src/services/AuctionService';
import AuctionUtils from 'src/utils/AuctionUtils';
import Chart from 'react-apexcharts';
import LoadingComponent from 'src/components/common/LoadingComponent';
import React from 'react';
import StringConstants from 'src/constants/StringConstants';

const chartOptions = {
  chart: {
    height: 350,
    type: 'radialBar',
  },

  fill: {
    colors: ['#0EBAB3'],
  },
  stroke: {
    lineCap: 'round',
  },
  plotOptions: {
    radialBar: {
      hollow: {
        size: '60%',
      },
      dataLabels: {
        name: {
          show: true,
          fontSize: '20px',
          color: '#231F20',
          offsetY: 10,
        },
        value: {
          show: false,
        },
      },
      track: {
        show: true,
        background: '#DEDEDE',
      },
    },
  },
  labels: ['RFQ Invites'],
};

class AuctionInvites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      series: [0],
      recievedInvites: 0,
      activeAuctions: 0,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    AuctionService.getAuctionCountForDashboard()
      .then((response) => {
        const auctionCounts = response.data,
          totalAuctions = !isEmpty(auctionCounts)
            ? sum(Object.values(auctionCounts))
            : 0,
          auctionData = {};

        AuctionUtils.getAuctionCountForCard(
          StringConstants.AUCTION_STATUS.ACTIVE,
          auctionCounts,
          auctionData
        );

        this.setState({
          loading: false,
          series: [
            [auctionData[StringConstants.AUCTION_STATUS.ACTIVE].percentage],
          ],
          recievedInvites: totalAuctions,
          activeAuctions:
            auctionData[StringConstants.AUCTION_STATUS.ACTIVE].count,
        });
      })
      .catch((err) => {
        this.setState({ loading: false, auctionCount: {}, totalAuctions: 0 });
      });
  }

  render() {
    const { loading, series, recievedInvites, activeAuctions } = this.state;

    return (
      <div className='ca-card-light auction-invite-graph'>
        {loading ? (
          <div style={{ height: 250 }}>
            <LoadingComponent />
          </div>
        ) : (
          <>
            <Chart
              options={chartOptions}
              series={series}
              type='radialBar'
              height={250}
            />
            <div className='auction-invite-legand'>
              <ul className='auction-invite-legand-list'>
                <li>
                  <div className='recvd-invite-legand'></div>Received invites:{' '}
                  <span>
                    <strong>{recievedInvites}</strong>
                  </span>
                </li>
                <li>
                  <div className='active-auction-legand'></div>Active Auctions:{' '}
                  <span>
                    <strong>{activeAuctions}</strong>
                  </span>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    );
  }
}
export default AuctionInvites;
