import React, { Component } from 'react';
import { isUserImporter, isUserPlant } from 'src/helpers/user';

import BuyerAuctionDetails from './buyer/BuyerAuctionDetails';
import NoAccessComponent from 'src/components/common/NoAccessComponent';
import ProcessingPlantAuctionDetails from './plants/ProcessingPlantAuctionDetails';
import SecureComponent from 'src/components/common/SecureComponent';

class AuctionDetails extends Component {
  render() {
    if (isUserImporter()) return <BuyerAuctionDetails />;
    else if (isUserPlant()) return <ProcessingPlantAuctionDetails />;
    else return <NoAccessComponent />;
  }
}

export default SecureComponent(AuctionDetails);
