import ApiManager from 'src/api/ApiManager';
import HttpConstants from 'src/constants/HttpConstants';
import UrlConstants from 'src/constants/UrlConstants';

const TermsAndConditionsService = {
  getAllTermsAndConditions: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_TERMS_AND_CONDITIONS.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  createTermsAndCondition: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.CREATE_TERMS_AND_CONDITION.USECASE,
      HttpConstants.POST_METHOD,
      data
    );
  },
  editTermsAndCondition: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.EDIT_TERMS_AND_CONDITION.USECASE,
      HttpConstants.PATCH_METHOD,
      data
    );
  },
  deleteTermsAndCondition: (id) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.DELETE_TERMS_AND_CONDITION.USECASE,
      HttpConstants.DELETE_METHOD,
      { ':id': id }
    );
  },
};
export default TermsAndConditionsService;
