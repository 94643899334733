import { Button, Tag } from 'antd';
import {
  getFormattedFullDate,
  getProfilePicture,
  getUsername,
} from '../DisplayUtils';
import { hashCode, intToRGB } from '../misc/ColorUtils';

function getPendingInvitesColumns() {
  return [
    {
      title: 'Email Address',
      width: '100',
      key: 'email',
      render: (record) => {
        return (
          <>
            <strong>{record.email}</strong>
            <br />
            <span className='pendinginvitetime'>
              {getFormattedFullDate(record.createdAt)}
            </span>
          </>
        );
      },
    },
    {
      title: '',
      key: 'action',
      render: (record) => (
        <div size='middle' className='pending-invites-cta'>
          <Button
            className='primary-button mr-15'
            onClick={() =>
              this.setState({
                resendInviteConfirmationModalVisible: true,
                inviteId: record.id,
              })
            }
            loading={this.props.resendActionLoading}
          >
            Resend
          </Button>
          <Button
            className='cancel-button'
            onClick={() =>
              this.setState({
                revokeInviteConfirmationModalVisible: true,
                inviteId: record.id,
              })
            }
            loading={this.props.revokeActionLoading}
          >
            Revoke
          </Button>
        </div>
      ),
    },
  ];
}

function getUserRoleColumns() {
  let userRoleColumns = [
    {
      title: 'memberpic',
      key: 'memberpic',
      render: (text, record) => (
        <div className='member-pic'>{getProfilePicture(record)}</div>
      ),
    },
    {
      title: 'Name',
      key: 'name',
      render: (record) => (
        <>
          <strong>{getUsername(record)}</strong>
          <br />
          <span className=''>{record.email}</span>
        </>
      ),
    },

    {
      title: 'Tags',
      key: 'tags',
      render: (record) => (
        <Tag color={intToRGB(hashCode(record.role))} key={record.role}>
          {record.role}
        </Tag>
      ),
    },
  ];
  if (this.props.isUserAuthorized)
    userRoleColumns.push({
      title: '',
      key: 'action',
      render: (record) =>
        this.props.user.id !== record.userId && (
          <div size='middle' className='pending-invites-cta'>
            <Button
              className='primary-button mr-15'
              onClick={() =>
                this.setState({
                  updateUserRoleModalVisible: true,
                  teamId: record.teamId,
                  userId: record.userId,
                  role: record.role,
                  userName: getUsername(record),
                })
              }
            >
              Update Role
            </Button>
            <Button
              className='cancel-outlined-button'
              onClick={() =>
                this.setState({
                  removeUserModalVisible: true,
                  teamId: record.teamId,
                  userId: record.userId,
                  userName: getUsername(record),
                })
              }
            >
              Remove
            </Button>
          </div>
        ),
    });
  return userRoleColumns;
}

const TeamTableManager = {
  getPendingInvitesColumns,
  getUserRoleColumns,
};

export default TeamTableManager;
