import { actionTypes } from 'src/actions/types';

export default function route(state = {}, action) {
  switch (action.type) {
    case actionTypes.SET_UNSAVED_CHANGES:
      return {
        ...state,
        hasUnsavedChanges: action.payload,
      };
    case actionTypes.SET_INCOMPLETE_AUCTION_DRAFT:
      return {
        ...state,
        isDraftAuctionIncomplete: action.payload,
      };
    case actionTypes.SET_REDIRECT_PATH:
      return {
        ...state,
        redirectPath: action.redirectPath,
      };
    default:
      return state;
  }
}
