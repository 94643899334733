import ApiManager from 'src/api/ApiManager';
import HttpConstants from 'src/constants/HttpConstants';
import UrlConstants from 'src/constants/UrlConstants';

const UserService = {
  login: (data) => {
    return ApiManager.makeApiCall(
      UrlConstants.LOGIN.USECASE,
      HttpConstants.POST_METHOD,
      data
    );
  },
  forgotPassword: (email) => {
    return ApiManager.makeApiCall(
      UrlConstants.FORGOT_PASSWORD.USECASE,
      HttpConstants.POST_METHOD,
      { email }
    );
  },
  resetPassword: (token, newPassword, confirmPassword) => {
    return ApiManager.makeApiCall(
      UrlConstants.RESET_PASSWORD.USECASE,
      HttpConstants.POST_METHOD,
      { newPassword, confirmPassword, ':token': token }
    );
  },
  changePassword: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.CHANGE_PASSWORD.USECASE,
      HttpConstants.PATCH_METHOD,
      data
    );
  },
  getUserProfile: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_USER_PROFILE.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  verifyUserAccount: (token) => {
    return ApiManager.makeApiCall(
      UrlConstants.AUTH0_LOGIN.USECASE,
      HttpConstants.POST_METHOD,
      { token }
    );
  },
  updateProfile: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_PROFILE.USECASE,
      HttpConstants.PATCH_METHOD,
      data
    );
  },
  getUserPreference: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_USER_PREFERENCE.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  updateUserPreference: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_USER_PREFERENCE.USECASE,
      HttpConstants.PATCH_METHOD,
      data
    );
  },
};

export default UserService;
