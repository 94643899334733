import { Link, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { Table, Tooltip } from 'antd';

import PropTypes from 'prop-types';
import RouteConstants from '../../constants/RouteConstants';
import TableFilterUtils from 'src/utils/TableFilterUtils';
import { getDisplayString } from 'src/utils/DisplayUtils';

class SpecificationTable extends Component {
  static propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  getColumnsForTable = () => {
    const { data } = this.props;
    const columns = [
      // {
      //   title: 'Carava Item Number',
      //   width: 130,
      //   dataIndex: 'id',
      // },
      {
        title: 'Item Number',
        width: 130,
        dataIndex: 'companyItemNumber',
        filters: TableFilterUtils.getFilterDropdownValues(
          data,
          'companyItemNumber'
        ),
        filterMultiple: true,
        onFilter: (value, record) =>
          record.companyItemNumber.indexOf(value) >= 0,
      },
      {
        title: 'Item Description',
        key: 'itemDescription',
        dataIndex: 'itemDescription',
        filters: TableFilterUtils.getFilterDropdownValues(
          data,
          'itemDescription'
        ),
        filterMultiple: true,
        onFilter: (value, record) => record.itemDescription.indexOf(value) >= 0,
        ellipsis: {
          showTitle: false,
        },
        render: (itemDescription) => (
          <Tooltip placement='topLeft' title={itemDescription}>
            {itemDescription}
          </Tooltip>
        ),
      },
      {
        title: 'Product Type',
        key: 'productType',
        dataIndex: 'productType',
        width: 110,
        render: (productType) => getDisplayString(productType),
        filters: TableFilterUtils.getFilterDropdownValues(data, 'productType'),
        filterMultiple: true,
        onFilter: (value, record) => record.productType.indexOf(value) >= 0,
      },
      {
        title: 'Product Form',
        key: 'productForm',
        dataIndex: 'productForm',
        filters: TableFilterUtils.getFilterDropdownValues(data, 'productForm'),
        filterMultiple: true,
        onFilter: (value, record) => record.productForm.indexOf(value) >= 0,
      },
      {
        title: 'Pack Combined',
        key: 'packCombined',
        dataIndex: 'packCombined',
        width: 130,
        filters: TableFilterUtils.getFilterDropdownValues(data, 'packCombined'),
        filterMultiple: true,
        onFilter: (value, record) => record.packCombined.indexOf(value) >= 0,
      },
      // {
      //   title: 'Customer',
      //   dataIndex: 'customer',
      //   filters: TableFilterUtils.getFilterDropdownValues(data, 'customer'),
      //   filterMultiple: true,
      //   onFilter: (value, record) => record.customer.indexOf(value) >= 0,
      // },
      {
        title: 'Brand',
        width: 110,
        dataIndex: 'brand',
        key: 'brand',
        filters: TableFilterUtils.getFilterDropdownValues(data, 'brand'),
        filterMultiple: true,
        onFilter: (value, record) => record.brand.indexOf(value) >= 0,
      },
      {
        title: '',
        key: 'action',
        width: 110,
        render: (record) => (
          <div className='text-center'>
            <Link
              className='primary-button ant-btn'
              to={RouteConstants.SPECIFICATION_DETAIL.replace(':id', record.id)}
            >
              View
            </Link>
          </div>
        ),
      },
    ];

    return columns;
  };

  render() {
    const { loading, data } = this.props;
    return (
      <Table
        locale={{ filterConfirm: 'Apply' }}
        loading={loading}
        columns={this.getColumnsForTable()}
        dataSource={data}
        rowKey={(row) => row.id}
      />
    );
  }
}

export default withRouter(SpecificationTable);
