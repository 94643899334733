import { Col, Form, Input, Row, Select } from 'antd';
import { find, isEmpty, isUndefined, uniq } from 'lodash';

import { CaretDownOutlined } from '@ant-design/icons';
import MetadataService from 'src/services/MetadataService';
import PropTypes from 'prop-types';
import React from 'react';
import SpecificationUtils from 'src/utils/SpecificationUtils';
import StringConstants from 'src/constants/StringConstants';
import { getReadableDisplayName } from 'src/utils/DisplayUtils';

const { Option } = Select;
const { TextArea } = Input;

class SpeciesInformation extends React.Component {
  static propTypes = {
    acceptableMarketNames: PropTypes.array.isRequired,
    countryNames: PropTypes.array.isRequired,
    seafoodList: PropTypes.array,
    selectedSeafoodItems: PropTypes.array,
    selectedProductType: PropTypes.string,
    selectedProductForm: PropTypes.string,
    wildOrFarm: PropTypes.string,
    minSizeCount: PropTypes.number,
    minSizeCountUom: PropTypes.string,
    maxSizeCount: PropTypes.number,
    maxSizeCountUom: PropTypes.string,
    variance: PropTypes.number,
    varianceSymbol: PropTypes.string,
    varianceUom: PropTypes.string,
  };

  static defaultProps = {
    wildOrFarm: StringConstants.SPECIFICATION_FIELDS.FARM_RAISED,
  };

  constructor(props) {
    super(props);
    this.state = {
      acceptableMarketNames: this.props.acceptableMarketNames,
      countryNames: this.props.countryNames,
      seafoodList: this.props.seafoodList,
      selectedSeafoodItems: this.props.selectedSeafoodItems,
      selectedProductType: this.props.selectedProductType,
      wildOrFarm: this.props.wildOrFarm,
      selectedMarketNames:
        this.props.selectedSeafoodItems &&
        this.props.selectedSeafoodItems.map(
          (item) => item.acceptableMarketName
        ),
      selectedProductForm: this.props.selectedProductForm,
      minSizeCount: this.props.minSizeCount,
      minSizeCountUom: this.props.minSizeCountUom,
      maxSizeCount: this.props.maxSizeCount,
      maxSizeCountUom: this.props.maxSizeCountUom,
      variance: this.props.variance,
      varianceSymbol: this.props.varianceSymbol,
      varianceUom: this.props.varianceUom,
    };
  }

  selectMarketNames = (value) => {
    this.setState({ selectedMarketNames: value }, () => {
      let selectedSeafoodItems = this.findSelectedSeafoodItems();
      let selectedProductType =
        selectedSeafoodItems.length > 0
          ? selectedSeafoodItems[0].productType
          : undefined;

      this.setState(
        (prevState) => {
          if (
            prevState.selectedProductType !== selectedProductType &&
            !isUndefined(selectedProductType)
          )
            this.fetchProductForms(selectedProductType);
          return {
            selectedProductType,
            selectedSeafoodItems,
            selectedProductForm:
              prevState.selectedProductType !== selectedProductType
                ? undefined
                : prevState.selectedProductForm,
            productForms:
              prevState.selectedProductType !== selectedProductType
                ? []
                : prevState.productForms,
            otherDropdowns:
              prevState.selectedProductType !== selectedProductType
                ? undefined
                : prevState.otherDropdowns,
            minSizeCountUom:
              selectedProductType === 'SHRIMP'
                ? StringConstants.SPECIFICATION_FIELDS.COUNT_PER_LB
                : selectedProductType === undefined
                ? undefined
                : prevState.minSizeCountUom,
            maxSizeCountUom:
              selectedProductType === 'SHRIMP'
                ? StringConstants.SPECIFICATION_FIELDS.COUNT_PER_LB
                : selectedProductType === undefined
                ? undefined
                : prevState.maxSizeCountUom,
            minSizeCount:
              selectedProductType === 'SHRIMP'
                ? undefined
                : prevState.minSizeCount,
            maxSizeCount:
              selectedProductType === 'SHRIMP'
                ? undefined
                : prevState.maxSizeCount,
            variance:
              selectedProductType === 'SHRIMP' ? undefined : prevState.variance,
          };
        },
        () => {
          const {
            selectedProductType,
            selectedProductForm,
            otherDropdowns,
            minSizeCount,
            minSizeCountUom,
            maxSizeCount,
            maxSizeCountUom,
            productForms,
            variance,
          } = this.state;
          let updatePhysicalAttributesData = {
            selectedProductType,
            selectedProductForm,
            otherDropdowns,
            minSizeCount,
            minSizeCountUom,
            maxSizeCount,
            maxSizeCountUom,
            productForms,
            variance,
          };
          this.props.updatePhysicalAttributesData(updatePhysicalAttributesData);
        }
      );
    });
  };

  findSelectedSeafoodItems = () => {
    const { seafoodList, selectedMarketNames } = this.state;
    if (!isEmpty(selectedMarketNames)) {
      let selectedSeafoodItems = seafoodList.filter((item) => {
        return selectedMarketNames.includes(item.acceptableMarketName);
      });

      selectedSeafoodItems.sort(
        (a, b) =>
          selectedMarketNames.indexOf(a.acceptableMarketName) -
          selectedMarketNames.indexOf(b.acceptableMarketName)
      );

      return selectedSeafoodItems;
    }

    return [];
  };

  checkMarketNameSelectable = (value) => {
    const { seafoodList } = this.state;

    let seafoodItem = find(seafoodList, function (o) {
      return o.acceptableMarketName === value;
    });

    let selectedSeafooditems = this.findSelectedSeafoodItems();

    if (isEmpty(selectedSeafooditems)) return true;

    return selectedSeafooditems.every(
      (item) => item.productType === seafoodItem.productType
    );
  };

  fetchProductForms = async (selectedProductType) => {
    let actualSelectedProductType = selectedProductType;
    if (isUndefined(actualSelectedProductType))
      actualSelectedProductType = this.state.selectedProductType;
    let request = {
      productType: actualSelectedProductType,
    };
    let dictionaryDropdownsRes = await MetadataService.getDictionaryDropdowns(
      request
    );
    this.setState(
      { productForms: dictionaryDropdownsRes.data.product_form },
      () =>
        this.props.updatePhysicalAttributesData({
          productForms: this.state.productForms,
        })
    );
  };

  setWildOrFarm = (value) => {
    this.setState(
      {
        wildOrFarm: value,
        selectedSustainabilityRequirements: [],
      },
      () =>
        this.props.updateCorporateAssuranceRequirementsData({
          wildOrFarm: this.state.wildOrFarm,
          selectedSustainabilityRequirements:
            this.state.selectedSustainabilityRequirements,
        })
    );
  };

  getData = () => {
    const { wildOrFarm } = this.state;
    return {
      wildOrFarm,
    };
  };

  render() {
    const {
      acceptableMarketNames,
      countryNames,
      selectedSeafoodItems,
      selectedProductType,
      wildOrFarm,
    } = this.state;

    return (
      <Row gutter={30}>
        <Col span={24} xs={24} lg={16} xl={16}>
          <Form.Item
            className='specification-input-item'
            label='Acceptable Market Name'
            name='acceptableMarketName'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Select
              className='specification-tag-input'
              placeholder='Please choose the item market name'
              onChange={this.selectMarketNames}
              suffixIcon={<CaretDownOutlined />}
              showSearch
              mode='multiple'
            >
              {!isEmpty(acceptableMarketNames) &&
                acceptableMarketNames.map((name) => (
                  <Option
                    key={name}
                    value={name}
                    disabled={!this.checkMarketNameSelectable(name)}
                  >
                    {name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item className='specification-input-item' label='Common Name'>
            <Input
              className='specification-input'
              value={
                selectedSeafoodItems &&
                uniq(selectedSeafoodItems.map((item) => item.commonName))
              }
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item className='specification-input-item' label='Latin Name(s)'>
            <Input
              className='specification-input'
              value={
                selectedSeafoodItems &&
                uniq(selectedSeafoodItems.map((item) => item.scientificName))
              }
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item className='specification-input-item' label='Product Type'>
            <Input
              className='specification-input'
              defaultValue={
                selectedSeafoodItems &&
                uniq(selectedSeafoodItems.map((item) => item.productType))
              }
              value={selectedProductType}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            label='Wild Caught or Farm Raised'
          >
            <Select
              suffixIcon={<CaretDownOutlined />}
              className='planned-vi-sel-btn'
              value={wildOrFarm}
              onChange={this.setWildOrFarm}
            >
              <Option value={StringConstants.SPECIFICATION_FIELDS.FARM_RAISED}>
                {getReadableDisplayName(
                  StringConstants.SPECIFICATION_FIELDS.FARM_RAISED
                )}
              </Option>
              <Option value={StringConstants.SPECIFICATION_FIELDS.WILD_CAUGHT}>
                {getReadableDisplayName(
                  StringConstants.SPECIFICATION_FIELDS.WILD_CAUGHT
                )}
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={8} xl={8}>
          <Form.Item
            className='specification-input-item'
            label='Farm or Catch Country of Origin'
            name='farmOrCatchCountryOfOrigin'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Select
              className='specification-tag-input'
              mode='multiple'
              placeholder='Please choose farm or catch country(s) of origin'
            >
              {!isEmpty(countryNames) &&
                countryNames.map((name) => (
                  <Option key={name} value={name}>
                    {name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={8} xl={8}>
          <Form.Item
            className='specification-input-item'
            label='Processing Country of Origin'
            name='processingCountryOfOrigin'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Select
              className='specification-tag-input'
              mode='multiple'
              placeholder='Please choose processing country(s) of origin'
            >
              {!isEmpty(countryNames) &&
                countryNames.map((name) => (
                  <Option key={name} value={name}>
                    {name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={8} xl={8}>
          <Form.Item
            className='specification-input-item'
            label='Labeled Country of Origin'
            name='labelledCountryOfOrigin'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Select
              className='specification-tag-input'
              mode='multiple'
              placeholder='Please choose labelled country(s) of origin'
            >
              {!isEmpty(countryNames) &&
                countryNames.map((name) => (
                  <Option key={name} value={name}>
                    {name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            label='Country of Origin Comments'
            name='countryOfOriginComments'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <TextArea className='specification-textarea-input' rows={4} />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            label='Additional Species Information Comments'
            name='additionalSpeciesInfoComments'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <TextArea className='specification-textarea-input' rows={4} />
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

export default SpeciesInformation;
