import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import { CaretDownOutlined } from '@ant-design/icons';
import Chart from 'react-apexcharts';
import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const options = {
  chart: {
    id: 'po-trend-chart',
    height: 350,
    type: 'line',
    toolbar: {
      show: true,
      position: 'topRight',
      tools: {
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true | '<img src="/static/icons/reset.png" width="20">',
      },
    },
  },
  stroke: {
    curve: 'straight',
    width: 2,
  },
  legend: {
    show: false,
    position: 'top',
    horizontalAlign: 'right',
    fontSize: '10px',
    fontFamily: 'Roboto Condensed, sans-serif',
    fontWeight: 700,
    labels: {
      useSeriesColors: true,
    },
  },
  colors: ['#0EBAB3', '#2170B8'],
  markers: {
    size: 6,
    strokeColors: ['#0EBAB3', '#2170B8'],
    hover: {
      size: 6,
    },
  },
  fill: {
    opacity: 1,
  },
  dataLabels: {
    enabled: false,
    enabledOnSeries: [0, 1, 3, 4],
  },
  grid: {
    show: true,
    borderColor: '#E0E2E3',
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },
  xaxis: {
    type: 'datetime',
    axisBorder: {
      show: false,
      color: 'red',
    },
    labels: {
      showDuplicates: false,
      style: {
        colors: '#231F20',
        fontSize: '12px',
        fontFamily: 'Roboto Condensed, sans-serif',
      },
    },
    tickPlacement: 'between',
  },
  yaxis: [
    {
      forceNiceScale: true,
      axisBorder: {
        show: true,
        color: '#E0E2E3',
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#2170B8',
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        formatter: function (val, index) {
          if (val) {
            let number = '$ ' + new Intl.NumberFormat().format(val);
            return number;
          } else return '-';
        },
        style: {
          colors: '#2170B8',
          fontSize: '12px',
          fontFamily: 'Roboto Condensed, sans-serif',
        },
      },
      title: {
        text: 'Value (per case)',
        style: {
          color: '#231F20',
          fontSize: '12px',
          fontFamily: 'Roboto Condensed, sans-serif',
          fontWeight: 700,
        },
      },
    },
  ],
  annotations: {
    position: 'front',
    xaxis: [
      {
        x: new Date().getTime(),
        strokeDashArray: 0,
        borderColor: '#ED474A',
        width: '100%',
        height: '100%',
        label: {
          show: true,
          text: '',
          position: 'right',
          borderWidth: 0,
          style: {
            fontSize: 12,
            color: '#ED474A',
          },
        },
      },
    ],
  },
};

const series = [
  {
    name: 'Delivered Purchase Orders',
    data: [
      {
        x: new Date('2021-09-29'),
        y: 2.9,
      },
      {
        x: new Date('2021-09-30'),
        y: 2.8,
      },
      {
        x: new Date('2021-10-01'),
        y: 3.6,
      },
      {
        x: new Date('2021-10-02'),
        y: 3.4,
      },
      {
        x: new Date('2021-10-03'),
        y: 3.9,
      },
      {
        x: new Date('2021-10-11'),
        y: 2.4,
      },
    ],
  },
  {
    name: 'Pending Purchase Orders',
    data: [
      {
        x: new Date('2021-10-11'),
        y: 2.4,
      },
      {
        x: new Date('2021-10-12'),
        y: 2.5,
      },
      {
        x: new Date('2021-10-13'),
        y: 3.9,
      },
      {
        x: new Date('2021-11-09'),
        y: 3.4,
      },
      {
        x: new Date('2021-11-14'),
        y: 2.5,
      },
      {
        x: new Date('2021-11-24'),
        y: 3.0,
      },
      {
        x: new Date('2021-12-02'),
        y: 3.2,
      },
      {
        x: new Date('2021-12-05'),
        y: 3.5,
      },
    ],
  },
];

class POTrendChart extends React.Component {
  render() {
    return (
      <CardWithTitle title='PO Trend Analysis'>
        <div className='header'>
          <div className='select-drop ht-header-select'>
            Species:
            <Select
              suffixIcon={<CaretDownOutlined />}
              className='planned-vi-sel-btn'
              defaultValue={'All'}
              style={{ width: 300 }}
            >
              <Option value={'All'}>All</Option>
              <Option value={'Finfish'}>Finfish</Option>
              <Option value={'Shrimp'}>Shrimp</Option>
            </Select>
          </div>
          <div className='select-drop ht-header-select'>
            Item:
            <Select
              suffixIcon={<CaretDownOutlined />}
              className='planned-vi-sel-btn'
              defaultValue={'All'}
              style={{ width: 300 }}
            >
              <Option value={'All'}>All</Option>
              <Option value={'GV Whole Tilapia 8/3LB Bag'}>
                GV Whole Tilapia 8/3LB Bag
              </Option>
              <Option value={'GV Tilapia Fillets 8/4LB Bag'}>
                GV Tilapia Fillets 8/4LB Bag
              </Option>
              <Option value={'GV Tilapia Fillets 10/1LB Bag'}>
                GV Tilapia Fillets 10/1LB Bag
              </Option>
              <Option value={'GV Tilapia Fillets 12/4LB Bag'}>
                GV Tilapia Fillets 12/4LB Bag
              </Option>
            </Select>
          </div>
          <div className='select-drop ht-header-select'>
            Supplier:
            <Select
              suffixIcon={<CaretDownOutlined />}
              className='planned-vi-sel-btn'
              defaultValue={'All'}
              style={{ width: 300 }}
            >
              <Option value={'All'}>All</Option>
              <Option value={'PT. Surya Alam Tunggal'}>
                PT. Surya Alam Tunggal
              </Option>
              <Option value={'Marine Gold Products Ltd'}>
                Marine Gold Products Ltd
              </Option>
              <Option value={'Sandhya Aqua Exports Pvt. Ltd'}>
                Sandhya Aqua Exports Pvt. Ltd
              </Option>
            </Select>
          </div>
        </div>
        <Chart
          options={options}
          series={series}
          type='line'
          height={350}
          width={'95%'}
        />
      </CardWithTitle>
    );
  }
}

export default POTrendChart;
