import 'antd/dist/antd.css';

import { Layout } from 'antd';
import PoDetailsTrackingTab from '../PoDetailsTrackingTab/PoDetailsTrackingTab';
import React from 'react';

const { Content } = Layout;

class MainDashboard extends React.Component {
  state = {
    collapsed: true,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (
      <Content
        className='site-layout-background'
        style={{
          // margin: '24px 16px',
          padding: 24,
          minHeight: 280,
        }}
      >
        <PoDetailsTrackingTab />
      </Content>
    );
  }
}

export default MainDashboard;
