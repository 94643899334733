import './VersionHistoryDrawer.css';

import { Button, Drawer, Space } from 'antd';
import React, { Component } from 'react';
import { getFormattedFullDate, getUsername } from 'src/utils/DisplayUtils';

import ApiUtils from 'src/utils/ApiUtils';
import { DownloadOutlined } from '@ant-design/icons';
import EmptySection from 'src/components/common/emptySection/EmptySection';
import LoadingComponent from 'src/components/common/LoadingComponent';
import NoDataComponent from 'src/components/common/NoDataComponent';
import ProformaService from 'src/services/ProformaService';
import PropTypes from 'prop-types';
import RouteConstants from 'src/constants/RouteConstants';
import SpecificationService from 'src/services/SpecificationService';
import UrlGenerator from 'src/api/UrlGenerator';
import { orderBy } from 'lodash';
import withRouterAndRef from 'src/components/common/WithRouterAndRef';

class VersionHistoryDrawer extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    entity: PropTypes.oneOf(['SPECIFICATION', 'PROFORMA']),
    id: PropTypes.string.isRequired,
    currentVersion: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      downloadLoading: false,
      versions: [],
      error: undefined,
    };
  }

  componentDidMount() {
    const { id, entity } = this.props;
    this.setState({ loading: true });
    if (entity === 'SPECIFICATION') {
      SpecificationService.getSpecificationVersions(id)
        .then((response) => {
          this.setState({
            versions: orderBy(response.data, 'updatedAt', 'desc'),
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({ loading: false, versions: [] });
        });
    } else if (entity === 'PROFORMA') {
      ProformaService.getProformaVersions(id)
        .then((response) => {
          this.setState({
            versions: orderBy(response.data, 'updatedAt', 'desc'),
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
            versions: [],
            error: ApiUtils.getErrorMessage(err),
          });
        });
    }
  }

  downloadVersion = (version) => {
    const { id, entity } = this.props;
    this.setState({ downloadLoading: true });
    if (entity === 'SPECIFICATION') {
      SpecificationService.downloadSpecificationPDFForVersion(id, version)
        .then((blob) => {
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(new Blob([blob.data]));
          link.href = url;
          link.setAttribute('download', `${id}_v${version}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          this.setState({ downloadLoading: false });
        })
        .catch((err) => {
          this.setState({ downloadLoading: false });
        });
    } else if (entity === 'PROFORMA') {
      ProformaService.downloadProformaPDFForVersion(id, version)
        .then((blob) => {
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(new Blob([blob.data]));
          link.href = url;
          link.setAttribute('download', `${id}_v${version}.zip`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          this.setState({ downloadLoading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    }
  };

  navigateToSpecVersion = (version) => {
    const { id } = this.props;
    const url = UrlGenerator.getUiUrlWithPathParams(
      RouteConstants.SPECIFICATION_VERSION_DETAIL,
      { id, version }
    );
    this.props.history.push(url);
  };

  navigateToProformaVersion = (version) => {
    const { id } = this.props;
    const url = UrlGenerator.getUiUrlWithPathParams(
      RouteConstants.PROFORMA_VERSION_DETAIL,
      { id, version }
    );
    this.props.history.push(url);
  };

  getUserDisplayInfo = (user) => {
    return user.updatedBy.firstName ? (
      getUsername(user.updatedBy)
    ) : (
      <a href={`mailto:${user.updatedBy.email}`}>{user.updatedBy.email}</a>
    );
  };

  render() {
    const { visible, entity } = this.props;
    const { versions, loading, downloadLoading, error } = this.state;

    return (
      <Drawer
        width={620}
        onClose={this.props.onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {loading ? (
          <LoadingComponent />
        ) : versions.length > 0 ? (
          <>
            <div className='right-side-drawer mb-15'>
              <p className='pagetitle'>Version History</p>
              <p className='sec-heading-subtitle currentvrs'>
                <Space>
                  Current Version
                  <span className='newversion'>v{versions[0].version}</span>
                </Space>
              </p>
              <p className='sec-heading person_name'>
                Changed by {this.getUserDisplayInfo(versions[0])}
              </p>
              <p className='headingdate'>
                {getFormattedFullDate(versions[0].createdAt)}
              </p>
            </div>

            <div>
              {versions.slice(1, versions.length).map((version) => (
                <div
                  className='agreement-reminder-list-card heading-divider'
                  key={version.version}
                >
                  <div className='agreement-reminder-list-desc'>
                    <p className='desc'>
                      <strong>
                        Last Updated by {this.getUserDisplayInfo(version)}
                      </strong>
                    </p>
                    <p className='sec-heading-subtitle'>
                      {getFormattedFullDate(version.updatedAt)}
                    </p>
                  </div>

                  <div className='v-history-btns'>
                    <Button
                      type='link'
                      className='pdflink mr-15'
                      onClick={() => this.downloadVersion(version.version)}
                      loading={downloadLoading}
                    >
                      <DownloadOutlined /> Download as PDF
                    </Button>
                    <Button
                      className='primary-button mr-15'
                      onClick={() => {
                        if (entity === 'SPECIFICATION')
                          this.navigateToSpecVersion(version.version);
                        else if (entity === 'PROFORMA')
                          this.navigateToProformaVersion(version.version);
                      }}
                    >
                      View
                    </Button>
                    <span className='title-heading version-num'>
                      v {version.version}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div>
              {versions.length === 1 && (
                <EmptySection
                  imageSrc={
                    require('../../assets/images/no-data-images/nd-old-versions.png')
                      .default
                  }
                  title='No Older Versions'
                  showButton={false}
                />
              )}
            </div>
          </>
        ) : (
          <NoDataComponent
            title='No versions found'
            tip={error ? error : ''}
            showButton={false}
          />
        )}
      </Drawer>
    );
  }
}

export default withRouterAndRef(VersionHistoryDrawer);
