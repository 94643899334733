import { Select, Tooltip } from 'antd';
import { find, isUndefined } from 'lodash';
import { getFormattedDate, getFormattedFullDate } from '../DisplayUtils';

import { CaretDownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import RouteConstants from 'src/constants/RouteConstants';
import TableFilterUtils from '../TableFilterUtils';
import moment from 'moment';

const { Option } = Select;

function getColumnsForImporter(data) {
  const columns = [
    {
      title: 'Planned Order',
      dataIndex: 'companyPlannedOrderNumber',
      width: 100,
    },
    // {
    //   title: 'Customer',
    //   dataIndex: 'journalBatchName',
    //   filters: TableFilterUtils.getFilterDropdownValues(
    //     data,
    //     'journalBatchName'
    //   ),
    //   filterMultiple: true,
    //   onFilter: (value, record) =>
    //     record.journalBatchName.indexOf(value) >= 0,
    // },
    {
      title: 'Brand',
      width: 100,
      filters: TableFilterUtils.getFilterDropdownValues(
        data,
        'specification.brand'
      ),
      filterMultiple: true,
      onFilter: (value, record) =>
        record.specification.brand.indexOf(value) >= 0,
      render: ({ specification }) => specification.brand,
    },
    {
      title: 'Item Number',
      width: 100,
      filters: TableFilterUtils.getFilterDropdownValues(
        data,
        'specification.companyItemNumber'
      ),
      filterMultiple: true,
      onFilter: (value, record) =>
        record.specification.companyItemNumber.indexOf(value) >= 0,
      render: ({ specification }) => specification.companyItemNumber,
    },
    {
      title: 'Item Description',
      filters: TableFilterUtils.getFilterDropdownValues(
        data,
        'specification.itemDescription'
      ),
      ellipsis: {
        showTitle: false,
      },
      filterMultiple: true,
      onFilter: (value, record) =>
        record.specification.itemDescription.indexOf(value) >= 0,
      render: ({ specification }) => (
        <Tooltip placement='topLeft' title={specification.itemDescription}>
          {specification.itemDescription}
        </Tooltip>
      ),
    },
    {
      title: 'Product Form',
      filters: TableFilterUtils.getFilterDropdownValues(
        data,
        'specification.productForm'
      ),
      filterMultiple: true,
      onFilter: (value, record) =>
        record.specification.productForm.indexOf(value) >= 0,
      render: ({ specification }) => {
        return <span>{specification.productForm}</span>;
      },
    },
    {
      title: 'Species',
      ellipsis: {
        showTitle: false,
      },
      filters: TableFilterUtils.getFilterDropdownValuesForArray(
        data,
        'specification.seaFoodList',
        'commonName'
      ),
      filterMultiple: true,
      onFilter: (value, record) =>
        record.specification.seaFoodList
          .map(function (e) {
            return e.commonName;
          })
          .indexOf(value) >= 0,
      render: ({ specification }) => {
        const species = [];
        specification.seaFoodList.forEach((seafood) => {
          species.push(seafood.commonName);
        });

        return (
          <Tooltip placement='topLeft' title={species.toString()}>
            {species.toString()}
          </Tooltip>
        );
      },
    },
    {
      title: 'Estimated Weight',
      width: 100,
      sorter: (a, b) => a.totalWeight - b.totalWeight,
      render: ({ totalWeight, weightUom }) => (
        <NumberFormat
          value={totalWeight}
          suffix={` ${weightUom}`}
          thousandSeparator={true}
          displayType='text'
        />
      ),
    },
    {
      title: 'Location',
      width: 100,
      render: ({ warehouse }) => warehouse.locationCode,
      filters: TableFilterUtils.getFilterDropdownValues(
        data,
        'warehouse.locationCode'
      ),
      filterMultiple: true,
      onFilter: (value, record) =>
        record.warehouse.locationCode.indexOf(value) >= 0,
    },
    {
      title: 'Requested ETA',
      width: 100,
      dataIndex: 'requestedEta',
      sorter: (a, b) => moment(a.requestedEta).diff(b.requestedEta),
      render: (requestedEta) => getFormattedDate(requestedEta),
      defaultSortOrder: 'ascend',
    },
    {
      title: '',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <div className='text-center'>
          <Link
            className='primary-button ant-btn'
            to={RouteConstants.PLANNED_ORDER_DETAIL.replace(':id', record.id)}
          >
            View
          </Link>
        </div>
      ),
    },
  ];

  return columns;
}

function getPlannedOrdersAssignmentColumns() {
  return [
    {
      title: 'Planned Order',
      dataIndex: 'companyPlannedOrderNumber',
    },
    {
      title: 'Requested ETA',
      render: ({ requestedEta }) => getFormattedFullDate(requestedEta),
      sorter: (a, b) => moment(a.requestedEta).diff(b.requestedEta),
    },
    {
      title: 'Requested ETD',
      render: ({ requestedEtd }) => getFormattedFullDate(requestedEtd),
      sorter: (a, b) => moment(a.requestedEtd).diff(b.requestedEtd),
    },
    {
      title: 'Location Code',
      render: ({ warehouse }) => warehouse.locationCode,
      filters: TableFilterUtils.getFilterDropdownValues(
        this.props.data && this.props.data.auctionPlannedOrders
          ? this.props.data.auctionPlannedOrders
          : [],
        'warehouse.locationCode'
      ),
      filterMultiple: true,
      onFilter: (value, record) =>
        record.warehouse.locationCode.indexOf(value) >= 0,
    },
    {
      title: 'Planned Cases',
      dataIndex: 'plannedCases',
      render: (value) => (
        <NumberFormat
          value={value}
          thousandSeparator={true}
          displayType='text'
        />
      ),
    },
    {
      title: 'Estimated Weight',
      dataIndex: 'totalWeight',
      render: (value) => (
        <NumberFormat
          value={value}
          suffix={' lb'}
          thousandSeparator={true}
          displayType='text'
        />
      ),
    },
  ];
}

function getPlannedOrdersSplitColumns() {
  return [
    {
      title: 'Planned Order',
      dataIndex: 'companyPlannedOrderNumber',
      width: 100,
    },
    {
      title: 'Requested ETA',
      render: ({ requestedEta }) => getFormattedFullDate(requestedEta),
      sorter: (a, b) => moment(a.requestedEta).diff(b.requestedEta),
      width: 150,
    },
    {
      title: 'Requested ETD',
      render: ({ requestedEtd }) => getFormattedFullDate(requestedEtd),
      sorter: (a, b) => moment(a.requestedEtd).diff(b.requestedEtd),
      width: 150,
    },
    {
      title: 'Location Code',
      render: ({ warehouse }) => warehouse.locationCode,
      filters: TableFilterUtils.getFilterDropdownValues(
        this.props.data && this.props.data.auctionPlannedOrders
          ? this.props.data.auctionPlannedOrders
          : [],
        'warehouse.locationCode'
      ),
      filterMultiple: true,
      onFilter: (value, record) =>
        record.warehouse.locationCode.indexOf(value) >= 0,
      width: 100,
    },
    {
      title: 'Planned Cases',
      dataIndex: 'plannedCases',
      render: (value) => (
        <NumberFormat
          value={value}
          thousandSeparator={true}
          displayType='text'
        />
      ),
      width: 100,
    },
    {
      title: 'Estimated Weight',
      dataIndex: 'totalWeight',
      render: (value) => (
        <NumberFormat
          value={value}
          suffix={' lb'}
          thousandSeparator={true}
          displayType='text'
        />
      ),
      width: 100,
    },
    {
      title: 'Buy From',
      render: (record) => {
        let subCompanies = this.props.subCompanies.filter((subCompany) => {
          let subCompanyPartOfAuction = find(
            this.props.currentProforma.biddingCompanies,
            function (o) {
              return o.id === subCompany.id;
            }
          );
          return !isUndefined(subCompanyPartOfAuction);
        });
        return (
          <Select
            suffixIcon={<CaretDownOutlined />}
            className='planned-vi-sel-btn'
            defaultValue={this.props.currentProforma.payTo.id}
            style={{ width: 200 }}
            onChange={(value) => this.handleChange(value, record)}
            value={
              this.state['buyFromForPL_' + record.id]
                ? this.state['buyFromForPL_' + record.id]
                : this.props.currentProforma.payTo.id
            }
          >
            <Option
              key={this.props.currentProforma.payTo.id}
              value={this.props.currentProforma.payTo.id}
            >
              {this.props.currentProforma.payTo.name}
            </Option>
            {subCompanies.map((subCompany) => {
              return (
                <Option key={subCompany.id} value={subCompany.id}>
                  {subCompany.name}
                </Option>
              );
            })}
          </Select>
        );
      },
      width: 200,
    },
  ];
}

const PlannedOrderTableManager = {
  getColumnsForImporter,
  getPlannedOrdersAssignmentColumns,
  getPlannedOrdersSplitColumns,
};

export default PlannedOrderTableManager;
