import './auctionAnalysis/AuctionAnalysis.css';

import { Button, Col, Dropdown, Menu, Row, Space, Tag } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { find, isUndefined } from 'lodash';
import { isUserImporter, isUserManagerOfTeam } from 'src/helpers/user';

import AddPlantsToAuctionModal from 'src/modals/auction/AddPlantsToAuctionModal';
import ApiUtils from 'src/utils/ApiUtils';
import AuctionService from 'src/services/AuctionService';
import AuctionStats from 'src/components/auction/bid/AuctionStats';
import AuctionSummaryTopCard from 'src/components/auction/bid/AuctionSummaryTopCard';
import ConfirmationModal from 'src/modals/common/ConfirmationModal';
import EditAuctionModal from '../../../../../modals/auction/EditAuctionModal';
import { EllipsisOutlined } from '@ant-design/icons';
import HorizontalTimeline from 'src/components/common/horizontalTimeline/HorizontalTimeline';
import LoadingComponent from 'src/components/common/LoadingComponent';
import ParticipantsAuctionIncoterms from 'src/components/auction/bid/ParticipantsAuctionIncoterms';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import StringConstants from 'src/constants/StringConstants';
import TimeTicker from 'src/components/common/time/TimeTicker';
import UrlGenerator from 'src/api/UrlGenerator';
import { getReadableDisplayName } from 'src/utils/DisplayUtils';
import moment from 'moment';
import { toast } from 'react-toastify';

class AuctionAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auctionId: this.props.auctionId,
      auctionDetails: this.props.auctionDetails,
      auctionParticipants: this.props.auctionParticipants,
      auctionBids: this.props.auctionBids,
      addPlantsToAuctionModalVisible: false,
      endAuctionModalVisible: false,
      cancelAuctionModalVisible: false,
      editAuctionModalVisible: false,
      loading: false,
      actionLoading: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  moveAuctionToHold = async () => {
    const { auctionId } = this.state;
    AuctionService.moveAuctionToHold(auctionId)
      .then((response) => {
        let auctionDetails = response.data;
        auctionDetails.auctionPlannedOrders.forEach(
          (pl) => (pl.specification = auctionDetails.specification)
        );
        toast.success('RFQ moved to hold successfully');
        this.setState({ endAuctionModalVisible: false }, () => {
          this.props.refreshPageData();
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error('Failed to move RFQ to hold');
        this.setState({ endAuctionModalVisible: false });
      });
  };

  cancelAuction = async () => {
    const { auctionId } = this.state;
    AuctionService.cancelAuction(auctionId)
      .then((response) => {
        toast.success('RFQ cancelled successfully');
        this.setState({
          cancelAuctionModalVisible: false,
          actionLoading: false,
        });
        this.props.history.push(
          UrlGenerator.addQueryParamsToUrl(RouteConstants.AUCTIONS, {
            status: StringConstants.AUCTION_STATUS.HISTORY,
          })
        );
      })
      .catch((error) => {
        console.log(error);
        toast.error('Failed to cancel RFQ');
        this.setState({
          cancelAuctionModalVisible: false,
          actionLoading: false,
        });
      });
  };

  editAuction = async (actionData) => {
    const { auctionId, auctionDetails } = this.state;
    const {
      newAuctionEndAt,
      newTargetPrice,
      newPaymentTerms,
      newIncotermCharge,
    } = actionData;
    const data = { ':auctionId': auctionId };
    this.setState({ loading: true });
    try {
      data.targetPrice = isUndefined(newTargetPrice)
        ? auctionDetails.targetPrice
        : newTargetPrice;
      data.endAt = isUndefined(newAuctionEndAt)
        ? auctionDetails.endAt
        : newAuctionEndAt;
      data.paymentTerms = isUndefined(newPaymentTerms)
        ? auctionDetails.paymentTerms
        : newPaymentTerms;

      if (
        auctionDetails.auctionIncoterms.length > 1 &&
        !isUndefined(newIncotermCharge)
      ) {
        let incotermWithCharge = find(
          auctionDetails.auctionIncoterms,
          function (o) {
            return o.incoterm.code === 'CNF';
          }
        );
        let updatedAuctionIncoterm = {
          ...incotermWithCharge,
          charges: newIncotermCharge,
        };

        data.auctionIncoterms = auctionDetails.auctionIncoterms.filter(
          (incoterm) => incoterm.id !== updatedAuctionIncoterm.id
        );

        data.auctionIncoterms.push(updatedAuctionIncoterm);
      }

      AuctionService.editAuction(data)
        .then((response) => {
          let newAuctionDetails = response.data;
          newAuctionDetails.auctionPlannedOrders.forEach(
            (pl) => (pl.specification = newAuctionDetails.specification)
          );
          toast.success('RFQ edited successfully');
          this.setState(
            {
              editAuctionModalVisible: false,
              auctionDetails: newAuctionDetails,
              loading: false,
            },
            () => this.props.refreshPageData()
          );
        })
        .catch((error) => {
          toast.error('Failed to edit RFQ');
          this.setState({ editAuctionModalVisible: false, loading: false });
        });
    } catch (error) {
      toast.error('Failed to edit RFQ');
      this.setState({ editAuctionModalVisible: false, loading: false });
    }
  };

  addPlantsToAuction = async (newPlants) => {
    const { auctionId } = this.state;
    this.setState({ loading: true });
    const data = { plants: newPlants };
    AuctionService.addPlantsToAuction(auctionId, data)
      .then((response) => {
        toast.success('Plants added to RFQ successfully');
        this.setState(
          {
            addPlantstoAuctionModalVisible: false,
            loading: false,
          },
          () => this.props.refreshPageData()
        );
      })
      .catch((error) => {
        let errorMessage = ApiUtils.getErrorMessage(error);
        toast.error('Failed to add plants to RFQ - ' + errorMessage);
        this.setState({
          addPlantstoAuctionModalVisible: false,
          loading: false,
        });
      });
  };

  cancelAction = (action) => {
    this.setState({ [action + 'ModalVisible']: false });
  };

  render() {
    const {
      auctionDetails,
      auctionParticipants,
      auctionBids,
      auctionId,
      addPlantsToAuctionModalVisible,
      cancelAuctionModalVisible,
      endAuctionModalVisible,
      editAuctionModalVisible,
      loading,
      actionLoading,
    } = this.state;

    const { specification } = auctionDetails;
    const { refreshedTime } = this.props;

    const actionsMenu = isUserImporter() &&
      isUserManagerOfTeam(StringConstants.TEAMS.SOURCING) && (
        <Menu>
          {Object.keys(StringConstants.AUCTION_ACTIONS).map((action) => {
            return (
              <Menu.Item
                key={action}
                onClick={() => {
                  if (action === 'SIMULATE_BID')
                    this.props.history.push({
                      pathname: UrlGenerator.getUiUrlWithPathParams(
                        RouteConstants.SIMULATE_BID,
                        { id: auctionId }
                      ),
                      auctionDetails,
                      auctionBids,
                    });
                  else
                    this.setState({
                      [StringConstants.AUCTION_ACTIONS[action].key +
                      'ModalVisible']: true,
                    });
                }}
              >
                {StringConstants.AUCTION_ACTIONS[action].title}
              </Menu.Item>
            );
          })}
        </Menu>
      );

    if (loading) return <LoadingComponent />;

    return (
      <>
        {isUserImporter() && (
          <HorizontalTimeline data={auctionDetails} currentEntity={'RFQ'} />
        )}
        <Row type='flex' gutter={15}>
          <Col className='' span={24} xs={24} lg={12} xl={12}>
            <Space>
              <span className='pagetitle'>Request for Quotes</span>
              <Tag
                color={
                  StringConstants.AUCTION_STATUS_COLORS[auctionDetails.status]
                }
              >
                {getReadableDisplayName(auctionDetails.status).toUpperCase()}
              </Tag>
            </Space>
          </Col>
        </Row>
        <Row className='mb-15'>
          <Col className='auction-info' span={24} xs={24} lg={12} xl={12}>
            <p className='rscb-desc-cin'>
              Customer Item : <span>{specification.companyItemNumber}</span>
            </p>
            <p className='rscb-desc-company'>
              <span>{specification.customer}</span> |{' '}
              <span>{specification.itemDescription}</span>
            </p>
          </Col>
          <Col className='auction-info-cta' span={24} xs={24} lg={12} xl={12}>
            <TimeTicker
              prefix='Last refreshed'
              time={moment(refreshedTime)}
              className='mr-15'
            />
            <Link
              to={UrlGenerator.getUiUrlWithPathParams(
                RouteConstants.AUCTION_DETAILS,
                { id: auctionId }
              )}
            >
              <Button className='auction-detail-cta' type='primary'>
                View Details
              </Button>
            </Link>
            {actionsMenu && (
              <Dropdown overlay={actionsMenu} trigger={['click']}>
                <Button className='action-menu-cta'>
                  <EllipsisOutlined className='more-actions-icon' />
                </Button>
              </Dropdown>
            )}
            <AddPlantsToAuctionModal
              existingPlants={auctionDetails.biddingCompanies}
              visible={addPlantsToAuctionModalVisible}
              onSubmit={(newPlants) => this.addPlantsToAuction(newPlants)}
              onCancel={() =>
                this.cancelAction(
                  StringConstants.AUCTION_ACTIONS.ADD_PLANTS_TO_AUCTION.key
                )
              }
            />
            <ConfirmationModal
              visible={endAuctionModalVisible}
              heading='End RFQ'
              title={StringConstants.AUCTION_ACTIONS.END_AUCTION.title}
              description={
                StringConstants.AUCTION_ACTIONS.END_AUCTION.description
              }
              okText='Move RFQ to Pending'
              onSubmit={this.moveAuctionToHold}
              onCancel={() =>
                this.cancelAction(
                  StringConstants.AUCTION_ACTIONS.END_AUCTION.key
                )
              }
            />
            <ConfirmationModal
              visible={cancelAuctionModalVisible}
              negativeAction={true}
              heading='Cancel RFQ'
              title={StringConstants.AUCTION_ACTIONS.CANCEL_AUCTION.title}
              description={
                StringConstants.AUCTION_ACTIONS.CANCEL_AUCTION.description
              }
              okText='Yes, Cancel the RFQ'
              cancelText='No'
              onSubmit={() =>
                this.setState({ actionLoading: true }, () =>
                  this.cancelAuction()
                )
              }
              onCancel={() =>
                this.cancelAction(
                  StringConstants.AUCTION_ACTIONS.CANCEL_AUCTION.key
                )
              }
              actionLoading={actionLoading}
            />
            <EditAuctionModal
              data={auctionDetails}
              visible={editAuctionModalVisible}
              onSubmit={(data) => this.editAuction(data)}
              onCancel={() =>
                this.cancelAction(
                  StringConstants.AUCTION_ACTIONS.EDIT_AUCTION.key
                )
              }
            />
          </Col>
        </Row>
        <Row className='mb-15'>
          <Col
            className=''
            span={24}
            xs={24}
            lg={24}
            xl={24}
            style={{ textAlign: 'center' }}
          >
            <AuctionSummaryTopCard
              auctionDetails={auctionDetails}
              auctionParticipants={auctionParticipants}
              auctionBids={auctionBids}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} xs={24} lg={24} xl={24}>
            <ParticipantsAuctionIncoterms
              participants={auctionParticipants}
              auctionDetails={auctionDetails}
              allBids={auctionBids}
            />
            <AuctionStats
              allBids={auctionBids}
              auctionTargetPrice={auctionDetails.targetPrice}
              incoterms={auctionDetails.auctionIncoterms}
              lowestBid={auctionDetails.lowestBid}
              specification={auctionDetails.specification}
            />
          </Col>
        </Row>
      </>
    );
  }
}
export default withRouter(AuctionAnalysis);
