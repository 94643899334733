import CardWithTitle from '../common/cards/CardWithTitle';
import ProformaTableManager from 'src/utils/tables/ProformaTableManager';
import React from 'react';
import { Table } from 'antd';

class ProformaApprovalStatus extends React.Component {
  getColumns = () => {
    return ProformaTableManager.getColumnsForApprovalStatus();
  };

  render() {
    const { data } = this.props;

    return (
      <div className='mt-15'>
        <CardWithTitle title='Proforma Approval Status '>
          <Table
            dataSource={data}
            columns={this.getColumns()}
            pagination={false}
            rowKey='name'
          ></Table>
        </CardWithTitle>
      </div>
    );
  }
}

export default ProformaApprovalStatus;
