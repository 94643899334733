import React, { Component } from 'react';
import { forEach, isEmpty } from 'lodash';

import { Input } from 'antd';
import SearchResultList from 'src/components/search/SearchResultList';
import SearchService from 'src/services/SearchService';

const { Search } = Input;
class NavSearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      text: undefined,
      results: {},
    };
  }

  onSearch = (text) => {
    if (text.length >= 3) {
      this.setState({ loading: true, text });
      SearchService.getSearchResults(text)
        .then((response) => {
          const results_ = Object.entries(response.data).sort(
            (a, b) => a[1].displayIndex - b[1].displayIndex
          );
          let results = {};
          forEach(results_, (r) => (results[r[0]] = r[1]));
          this.setState({ loading: false, results });
        })
        .catch((err) => {
          this.setState({ loading: false, results: [] });
        });
    } else {
      this.setState({ text });
    }
  };

  render() {
    const { text, results } = this.state;
    return (
      <div className='nav-search-area'>
        <Search
          className='carava-searchbar'
          placeholder='Search anything here...'
          style={{ width: 400 }}
          value={text}
          onChange={(e) => this.onSearch(e.target.value)}
        />
        {text && (
          <div className='nav-search-result-area'>
            {Object.keys(results).map((resultKey, index) => {
              if (!isEmpty(results[resultKey].results))
                return (
                  <div className='nav-result-box' key={index}>
                    <SearchResultList
                      searchText={text}
                      title={resultKey}
                      results={results[resultKey].results}
                      onClose={() =>
                        this.setState({ text: undefined, results: [] })
                      }
                    />
                  </div>
                );
              return null;
            })}
          </div>
        )}
      </div>
    );
  }
}

export default NavSearchBar;
