import './ChoosePlantsTable.css';

import { Button, Rate, Space, Table } from 'antd';

import NumberFormat from 'react-number-format';
import ProcessingPlantDetailModal from 'src/modals/processingPlants/ProcessingPlantDetailModal';
import PropTypes from 'prop-types';
import React from 'react';
import Title from 'src/components/common/typography/Title';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';

class SelectedPlantsTable extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    readOnly: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      plantDetailModalVisible: false,
      selectedRowKeys: this.props.selectedData
        ? this.props.selectedData.map((b) => b.id)
        : [],
      loading: false,
      selectedPlant: {},
    };
  }

  hideModal = () => {
    this.setState({ plantDetailModalVisible: false, selectedPlant: {} });
  };

  showModal = (plant) => {
    this.setState({
      selectedPlant: { company: plant },
      plantDetailModalVisible: true,
    });
  };

  getColumnsForTable = () => {
    const columns = [
      {
        title: 'Processing Plant',
        dataIndex: 'name',
      },
      {
        title: 'Country',
        dataIndex: 'country',
      },
      {
        title: 'Total LBS Sold',
        dataIndex: 'company',
        render: (company) => {
          const size = Math.floor(Math.random() * (95000 - 35000 + 1)) + 35000;
          return (
            <NumberFormat
              value={size}
              suffix='lbs'
              thousandSeparator={true}
              displayType='text'
            />
          );
        },
      },
      {
        title: 'Total $ Sold',
        dataIndex: 'company',
        render: (company) => {
          const size = Math.floor(Math.random() * (95000 - 35000 + 1)) + 35000;
          return (
            <NumberFormat
              value={size * 4}
              prefix='$'
              thousandSeparator={true}
              displayType='text'
            />
          );
        },
      },
      {
        title: 'Delivery Score',
        dataIndex: 'deliveryscore',
        render: (text, rating) => (
          <Space
            className='deliver-star'
            direction='vertical'
            align='start'
            size='small'
          >
            <Rate
              disabled
              allowHalf
              defaultValue={Math.floor(Math.random() * 5) + 1}
            />
          </Space>
        ),
      },
      {
        title: '',
        key: 'action',
        render: (companyObj) => (
          <div className='text-center'>
            <Button
              className='heading-cta'
              type='primary'
              onClick={() => this.showModal(companyObj)}
            >
              View
            </Button>
          </div>
        ),
      },
    ];
    return columns;
  };

  getSelectedPlants = () => {
    const { selectedRowKeys } = this.state;
    if (isEmpty(selectedRowKeys)) toast.warning('No Plants Selected');
    return selectedRowKeys;
  };

  render() {
    const { selectedRowKeys, selectedPlant, plantDetailModalVisible } =
      this.state;
    const { data, readOnly } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <div className={readOnly ? '' : 'ca-card-light mt-15 mb-15'}>
        {!readOnly && <Title title='Selected Compliant Plants' />}

        <Table
          locale={{ filterConfirm: 'Apply' }}
          rowSelection={readOnly ? false : rowSelection}
          rowKey={(row) => row.id}
          columns={this.getColumnsForTable()}
          dataSource={data}
          pagination={data && data.length >= 10}
        />

        <ProcessingPlantDetailModal
          visible={plantDetailModalVisible}
          onClose={this.hideModal}
          data={selectedPlant}
        />
      </div>
    );
  }
}
export default SelectedPlantsTable;
