import { actionTypes } from './types';

/**
 * @param {boolean} payload
 */
export const setUnsavedChanges = (payload) => {
  return {
    type: actionTypes.SET_UNSAVED_CHANGES,
    payload: payload,
  };
};

/**
 * @param {boolean} payload
 */
export const setIncompleteAuctionDraft = (payload) => {
  return {
    type: actionTypes.SET_INCOMPLETE_AUCTION_DRAFT,
    payload: payload,
  };
};
