const PaymentTerms = [
  'TT Scan of Shipping Documents',
  'TT 3 Days Before ETA',
  'TT Net 7 Days After ETA',
  'TT Net 15 Days After ETA',
  'TT Net 30 Days After ETA',
  'TT Upon FDA Release',
];

export default PaymentTerms;
