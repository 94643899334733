import '../Modals.css';

import { Button, Col, Input, Modal, Row, Select } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { find, isEmpty, isEqual, isUndefined } from 'lodash';

import LoadingComponent from 'src/components/common/LoadingComponent';
import MetadataService from 'src/services/MetadataService';
import PropTypes from 'prop-types';
import Title from 'src/components/common/typography/Title';

const { Option } = Select;

class AddOrEditSeafoodItemModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      certifications: props.isEdit
        ? props.seafoodItem && props.seafoodItem.certifications
          ? props.seafoodItem.certifications.split(',')
          : []
        : [],
      seafoodList: [],
      newSeafoodItem: undefined,
    };
  }

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    seafoodItem: PropTypes.object,
    existingSeafoodItems: PropTypes.array,
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    heading: 'Confirm your action',
    okText: 'Proceed',
    cancelText: 'Cancel',
  };

  componentDidMount = async () => {
    if (!this.props.isEdit) {
      this.setState({ loading: true });
      try {
        let seafoodListRes = await MetadataService.getSeaFoodList();
        this.setState({ loading: false, seafoodList: seafoodListRes.data });
      } catch (err) {
        this.setState({ loading: false });
      }
    }
  };

  addCertification = () => {
    let certifications = [...this.state.certifications];
    certifications.push('');
    this.setState({ certifications });
  };

  editCertification = (certificationIndex, value) => {
    let certifications = [...this.state.certifications];
    certifications[certificationIndex] = value;
    this.setState({ certifications });
  };

  removeCertification = (certificationIndex) => {
    let certifications = [...this.state.certifications];
    certifications.splice(certificationIndex, 1);
    this.setState({ certifications });
  };

  isAnyUpdate = () => {
    const { seafoodItem } = this.props;
    const { certifications } = this.state;

    if (seafoodItem.certifications)
      return !isEqual(
        seafoodItem.certifications
          .split(',')
          .sort((a, b) => a.localeCompare(b)),
        certifications.sort((a, b) => a.localeCompare(b))
      );
    else return true;
  };

  isAnyCertificationEmpty = () => {
    const { certifications } = this.state;
    return (
      isEmpty(certifications) ||
      certifications.some((certification) => isEmpty(certification))
    );
  };

  si;

  render() {
    const { visible, seafoodItem, isEdit, existingSeafoodItems } = this.props;
    const { certifications, seafoodList, newSeafoodItem, loading } = this.state;

    return (
      <Modal
        className='confirmation-modal'
        centered
        visible={visible}
        okText='Submit'
        onOk={() => {
          if (isEdit) this.props.onSubmit(certifications);
          else this.props.onSubmit({ ...newSeafoodItem, certifications });
        }}
        onCancel={() => this.props.onCancel()}
        okButtonProps={{
          disabled:
            loading ||
            this.isAnyCertificationEmpty() ||
            (isEdit && !this.isAnyUpdate()) ||
            (!isEdit && isUndefined(newSeafoodItem)),
        }}
        cancelButtonProps={{ disabled: loading }}
        closable={false}
        maskClosable={false}
        width={750}
      >
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            <Title title='Edit Seafood Item' />

            <ul className='auction-edit-items'>
              {isEdit ? (
                <li className='auction-edit-row'>
                  <span className='left-item'>Item: </span>
                  <span className='right-item'>
                    {seafoodItem.commonName
                      ? seafoodItem.commonName
                      : seafoodItem.acceptableMarketName}
                  </span>
                </li>
              ) : (
                <li className='auction-edit-row'>
                  <span className='left-item'>Item: </span>
                  <span className='right-item' style={{ width: '75%' }}>
                    <Select
                      onChange={(value) =>
                        this.setState({
                          newSeafoodItem: find(seafoodList, function (o) {
                            return o.id === value;
                          }),
                        })
                      }
                      style={{ width: '100%' }}
                    >
                      {seafoodList &&
                        seafoodList.map((item) => {
                          return (
                            <Option
                              key={item.id}
                              value={item.id}
                              disabled={existingSeafoodItems.some(
                                (existingItem) => existingItem.id === item.id
                              )}
                            >
                              {item.commonName
                                ? item.commonName
                                : item.acceptableMarketName}
                            </Option>
                          );
                        })}
                    </Select>
                  </span>
                </li>
              )}
              <li className='auction-edit-row'>
                <span className='left-item'>Certifications: </span>
                <Row style={{ width: '75%' }}>
                  {certifications &&
                    certifications.map((certification, index) => {
                      return (
                        <Col
                          span={24}
                          className='mb-10'
                          key={certification + '-' + index}
                        >
                          <span className='right-item'>
                            <Input
                              className='auction-edit-field'
                              defaultValue={certification}
                              style={{ width: '85%' }}
                              onChange={(event) =>
                                this.editCertification(
                                  index,
                                  event.target.value
                                )
                              }
                              placeholder='Enter certification'
                              autoFocus
                            />
                          </span>
                          <Button
                            type='link'
                            className='remove-cert-btn'
                            onClick={() => this.removeCertification(index)}
                          >
                            <MinusCircleOutlined />
                            Remove
                          </Button>
                        </Col>
                      );
                    })}
                </Row>
              </li>
              <li className='auction-edit-row'>
                <span className='left-item'>
                  <Button
                    type='link'
                    className='add-cert-btn'
                    onClick={() => this.addCertification()}
                  >
                    <PlusCircleOutlined />
                    Add Certification
                  </Button>
                </span>
              </li>
            </ul>
          </>
        )}
      </Modal>
    );
  }
}

export default AddOrEditSeafoodItemModal;
