import './EmptySection.css';

import { Button, Col, Row } from 'antd';

import PropTypes from 'prop-types';
import React from 'react';

class EmptySection extends React.Component {
  static propTypes = {
    imageSrc: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    showButton: PropTypes.bool,
    buttonText: PropTypes.string,
    action: PropTypes.func,
    size: PropTypes.oneOf(['small', 'large']),
  };

  static defaultProps = {
    actionable: false,
    size: 'large',
  };

  render() {
    const { imageSrc, title, showButton, buttonText, action, size } =
      this.props;
    return (
      <Row>
        <Col span={24} xs={24} lg={24} xl={24}>
          <div
            className={
              size === 'small' ? 'empty-section-small' : 'empty-section'
            }
          >
            <div className='no-data-elements'>
              <div className='nd-img'>
                <img src={imageSrc} alt={title} />
              </div>
              <p className='nd-text mb-0'>{title}</p>
              {showButton && (
                <Button
                  className='primary-button mt-10'
                  type='primary'
                  onClick={() => action()}
                >
                  {buttonText}
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
export default EmptySection;
