import './PurchaseOrderTimeline.css';

import { Col, Row } from 'antd';
import React, { Component } from 'react';
import { capitalize, isEmpty, isNull, isUndefined } from 'lodash';

import ApiUtils from 'src/utils/ApiUtils';
import LoadingComponent from 'src/components/common/LoadingComponent';
import NoDataComponent from 'src/components/common/NoDataComponent';
import PurchaseOrderService from 'src/services/PurchaseOrderService';
import RouteMap from './locationTracking/routeMap/RouteMap';
import Timeline from 'src/components/timeline/Timeline';
import { getContainerEventsAndRoute } from 'src/utils/PurchaseOrderUtils';
import { getReadableDisplayName } from 'src/utils/DisplayUtils';
import moment from 'moment';
import { toast } from 'react-toastify';

class PurchaseOrderTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      poId: undefined,
      purchaseOrder: {},
      containerDetails: {},
    };
  }

  componentDidMount() {
    this.fetchTimelineData();
  }

  fetchTimelineData = async () => {
    const poId = this.props.purchaseOrderId;
    this.setState({ loading: true });
    const { fromNav } = this.props;
    if (fromNav)
      PurchaseOrderService.getNavPOTimeline(poId)
        .then((response) => {
          let containerDetails = undefined;
          if (!isNull(response.data.oceansTrackingDetails))
            containerDetails = getContainerEventsAndRoute(
              response.data.oceansTrackingDetails
            );
          this.setState({
            loading: false,
            purchaseOrder: response.data,
            containerDetails,
            poId,
          });
        })
        .catch((err) => {
          const errorMessage = ApiUtils.getErrorMessage(
            err,
            'Unable to fetch PO timeline at the moment'
          );
          toast.error(errorMessage);
          this.setState({ loading: false, poId });
        });
    else
      PurchaseOrderService.getPOTimeline(poId)
        .then((response) => {
          let containerDetails = undefined;
          if (!isNull(response.data.oceansTrackingDetails))
            containerDetails = getContainerEventsAndRoute(
              response.data.oceansTrackingDetails
            );
          this.setState({
            loading: false,
            purchaseOrder: response.data,
            containerDetails,
            poId,
          });
        })
        .catch((err) => {
          const errorMessage = ApiUtils.getErrorMessage(
            err,
            'Unable to fetch PO timeline at the moment'
          );
          toast.error(errorMessage);
          this.setState({ loading: false, poId });
        });
  };

  render() {
    const { loading, poId, purchaseOrder, containerDetails } = this.state;

    let pathData = undefined;
    let events = undefined;
    if (!isUndefined(containerDetails)) {
      pathData = containerDetails.pathData;
      events = containerDetails.events;
    }

    if (loading) return <LoadingComponent />;
    else if (isEmpty(purchaseOrder))
      return (
        <NoDataComponent
          title='Purchase order not found'
          tip={`Purchase order (${poId}) does not exist`}
          fullScreen={true}
          headerMetadata={true}
        />
      );
    return (
      <Row className='timeline-row'>
        <Col className='mb-15 mt-15' span={24} xs={24} lg={24} xl={24}>
          <div className='rscb-info'>
            <div className='rscb-desc'>
              <p className='rscb-desc-cin'>
                Purchase Order : <span>{poId}</span>
              </p>
              <p className='rscb-desc-company'>
                <span>{purchaseOrder.customer} | </span>
                <span>{purchaseOrder.itemDescription}</span>
              </p>
            </div>
          </div>
        </Col>
        <Col className='mb-15 mt-15' span={24} xs={24} lg={24} xl={24}>
          <ul className='po-track-del-status'>
            <li>
              <span className='left-item'>
                <span className='delivery-status-title'>
                  Estimated Delivery:
                  <br />
                  <span className='delivery-status'>
                    {purchaseOrder.eta
                      ? moment(purchaseOrder.eta).format('dddd, MMMM Do YYYY')
                      : '-'}
                  </span>
                </span>
              </span>
              <span className='right-item'>
                <span className='delivery-status-title'>
                  Status:
                  <br />
                  <span className='delivery-status'>
                    {capitalize(getReadableDisplayName(purchaseOrder.status))}
                  </span>
                </span>
              </span>
            </li>
          </ul>
        </Col>
        <Col
          className='rscb-details mb-15 mt-15'
          span={24}
          xs={24}
          lg={24}
          xl={24}
        >
          <p style={{ fontWeight: 700 }} className='text-normal mb-0'>
            Shipment Information:
          </p>
          <ul className='rscb-items bid-detail-list-1 mb-15'>
            <li>
              <span className='left-item'>Shipping To:</span>
              <span className='right-item'>
                {purchaseOrder.warehouse?.locationCode}
                {purchaseOrder.warehouse && <br />}
                {!isNull(purchaseOrder.destination) && (
                  <span>
                    {purchaseOrder.destination?.name},{' '}
                    {purchaseOrder.destination?.country}
                  </span>
                )}
              </span>
            </li>
          </ul>
        </Col>
        <Col className=' ' span={24} xs={24} lg={24} xl={24}>
          <p
            style={{ color: '#918F8F', fontWeight: 700 }}
            className='text-normal'
          >
            Tracking Status:
          </p>
        </Col>
        <Col span={24}>
          <Timeline events={purchaseOrder.events} />
        </Col>
        <Col span={24} id='detail-col' className='route-map-col'>
          <RouteMap pathData={pathData} events={events} />
        </Col>
      </Row>
    );
  }
}

export default PurchaseOrderTimeline;
