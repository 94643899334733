import './PoDetailsTrackingTab.css';

import { Button, Col, Drawer, Row, Tabs } from 'antd';

import Comment from './Comment';
import { DownloadOutlined } from '@ant-design/icons';
import PoTracking from './PoTracking';
import React from 'react';

const { TabPane } = Tabs;

class PoDetailsTrackingTab extends React.Component {
  state = {
    isActive: false,
    isActive1: true,
  };
  handleShow = () => {
    this.setState({
      isActive: true,
      isActive1: false,
    });
  };
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  handleHide = () => {
    this.setState({
      isActive: false,
      isActive1: true,
    });
  };
  render() {
    return (
      <>
        <div className='ca-card-light mb-15 pai-section'>
          <Tabs
            className='notification-alerts auction-tabs '
            defaultActiveKey='1'
          >
            {/*PO Details tab */}
            <TabPane
              className='participant-tab'
              tab={<span>Purchase Order Details</span>}
              key='1'
            >
              <div className=' rscb'>
                <Row>
                  <Col
                    className='rscb-details mb-15 mt-15'
                    span={24}
                    xs={24}
                    lg={20}
                    xl={20}
                  >
                    <p className='bid-sum-title'>Agreement Terms</p>

                    <ul className='rscb-items mb-15'>
                      <li>
                        <span className='left-item'>Seller Name :</span>
                        <span className='right-item'>
                          Diana Seafoods Corporate Ltd.
                        </span>
                      </li>
                      <li>
                        <span className='left-item'>Version :</span>
                        <span className='right-item'>
                          v 1.5{' '}
                          <span
                            className='color-blue-link vh-cta'
                            onClick={this.showDrawer}
                          >
                            View Changes
                          </span>
                        </span>
                      </li>
                      <li>
                        <span className='left-item'>Final Bid Value :</span>
                        <span className='right-item'>$ 3.99</span>
                      </li>
                    </ul>
                    <ul className='rscb-items mt-15'>
                      <li>
                        <span className='left-item '>Quantity :</span>
                        <span className='right-item '>
                          <span className='list-sub-items'>
                            Total Est Cases:
                            <br />
                            <span className='color-blue'>5,000</span>
                          </span>
                          <span className='list-sub-items'>
                            Total Est LBS:
                            <br />
                            <span className='color-blue'>648,000</span>
                          </span>
                          <span className='list-sub-items'>
                            Total Est Containers:
                            <br />
                            <span className='color-blue'>20</span>
                          </span>
                        </span>
                      </li>
                      <li>
                        <span className='left-item'>Bid Placed On :</span>
                        <span className='right-item'>05 Aug 2020</span>
                      </li>
                      <li>
                        <span className='left-item '>Incoterm Values :</span>
                        <span className='right-item '>
                          <span className='list-sub-items-detail'>
                            CNF/CFR (Cost & Freight)
                            <br />
                            <span className='color-blue'>$ 4.35</span>
                          </span>
                          <span className='list-sub-items-detail'>
                            DDP CY (Delivered Duty Paid Container Yard)
                            <br />
                            <span className='color-blue'>$ 4.40</span>
                          </span>
                        </span>
                      </li>
                      <li>
                        <span className='left-item'>Requested Date :</span>
                        <span className='right-item'>10 Aug 2020</span>
                      </li>
                      <li>
                        <span className='left-item'>
                          Promised Receipt Date :
                        </span>
                        <span className='right-item'>10 Aug 2020</span>
                      </li>
                      <li>
                        <span className='left-item'>Actual Receipt Date :</span>
                        <span className='right-item'>10 Aug 2020</span>
                      </li>
                      <li>
                        <span className='left-item'>Additional Remarks :</span>
                        <span className='right-item'>
                          This is example of additional remark.
                        </span>
                      </li>
                    </ul>
                  </Col>
                  <Col
                    className='rscb-details mb-15 mt-15 align-right'
                    span={24}
                    xs={24}
                    lg={4}
                    xl={4}
                  >
                    <Button
                      className='success-button'
                      type='primary'
                      icon={
                        <img
                          className='btn-cutom-icon'
                          src={
                            require('../../assets/images/negotiate.png').default
                          }
                          alt='Negotiate'
                        />
                      }
                    >
                      View Negotiation
                    </Button>
                  </Col>
                  <Col
                    className='rscb-details mb-15 mt-15 '
                    span={24}
                    xs={24}
                    lg={24}
                    xl={24}
                  >
                    <div className='heading-divider'></div>
                    <p className='bid-sum-title'>PO History</p>
                    <Button className='primary-button mr-30' type='primary'>
                      View Auction
                    </Button>
                    <Button className='primary-button mr-30' type='primary'>
                      View Pro-forma
                    </Button>
                    <Button className='primary-button mr-30' type='primary'>
                      View Negotiation
                    </Button>
                    <Button className='primary-button mr-30' type='primary'>
                      View Contract
                    </Button>
                  </Col>
                  <Drawer
                    width={620}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{ paddingBottom: 80 }}
                  >
                    <div className='bid-hist-drawer mb-15'>
                      <p className='pagetitle'>Version History</p>
                      <div className='version-info'>
                        <p className='sec-heading-subtitle currentvrs'>
                          Current Version
                        </p>
                        <p className='sec-heading person_name'>
                          Changed by M.Ferren
                        </p>
                        <p className='headingdate'>
                          Today, 05 Aug 2020 / 02:05:03 PM
                        </p>
                      </div>
                      <div className='current-version'>
                        <p className='title-heading version-num'>v 1.0</p>
                      </div>
                    </div>
                    <p className='title-heading'>Past Versions</p>
                    {/*reminder list card  */}
                    <div className='agreement-reminder-list-card'>
                      <div className='agreement-reminder-list-desc'>
                        <p className='desc'>
                          <strong>Created by John Doe</strong>
                        </p>
                        <p className='sec-heading-subtitle'>
                          Today, 05 Aug 2020 / 02:05:03 PM
                        </p>
                      </div>
                      <div className='v-history-btns'>
                        <span className='pdflink mr-15'>
                          <DownloadOutlined /> Download as PDF
                        </span>
                        <Button className='primary-button mr-15'>View</Button>
                        <span className='title-heading version-num'>v 1.0</span>
                      </div>
                    </div>
                    {/*end reminder list card  */}
                    {/*reminder list card  */}
                    <div className='agreement-reminder-list-card'>
                      <div className='agreement-reminder-list-desc'>
                        <p className='desc'>
                          <strong>Created by John Doe</strong>
                        </p>
                        <p className='sec-heading-subtitle'>
                          Today, 05 Aug 2020 / 02:05:03 PM
                        </p>
                      </div>
                      <div className='v-history-btns'>
                        <span className='pdflink mr-15'>
                          <DownloadOutlined /> Download as PDF
                        </span>
                        <Button className='primary-button mr-15'>View</Button>
                        <span className='title-heading version-num'>v 1.0</span>
                      </div>
                    </div>
                    {/*end reminder list card  */}
                    {/*reminder list card  */}
                    <div className='agreement-reminder-list-card'>
                      <div className='agreement-reminder-list-desc'>
                        <p className='desc'>
                          <strong>Created by John Doe</strong>
                        </p>
                        <p className='sec-heading-subtitle'>
                          Today, 05 Aug 2020 / 02:05:03 PM
                        </p>
                      </div>
                      <div className='v-history-btns'>
                        <span className='pdflink mr-15'>
                          <DownloadOutlined /> Download as PDF
                        </span>
                        <Button className='primary-button mr-15'>View</Button>
                        <span className='title-heading version-num'>v 1.0</span>
                      </div>
                    </div>
                    {/*end reminder list card  */}
                    {/*reminder list card  */}
                    <div className='agreement-reminder-list-card'>
                      <div className='agreement-reminder-list-desc'>
                        <p className='desc'>
                          <strong>Created by John Doe</strong>
                        </p>
                        <p className='sec-heading-subtitle'>
                          Today, 05 Aug 2020 / 02:05:03 PM
                        </p>
                      </div>
                      <div className='v-history-btns'>
                        <span className='pdflink mr-15'>
                          <DownloadOutlined /> Download as PDF
                        </span>
                        <Button className='primary-button mr-15'>View</Button>
                        <span className='title-heading version-num'>v 1.0</span>
                      </div>
                    </div>
                    {/*end reminder list card  */}
                    {/*reminder list card  */}
                    <div className='agreement-reminder-list-card'>
                      <div className='agreement-reminder-list-desc'>
                        <p className='desc'>
                          <strong>Created by John Doe</strong>
                        </p>
                        <p className='sec-heading-subtitle'>
                          Today, 05 Aug 2020 / 02:05:03 PM
                        </p>
                      </div>
                      <div className='v-history-btns'>
                        <span className='pdflink mr-15'>
                          <DownloadOutlined /> Download as PDF
                        </span>
                        <Button className='primary-button mr-15'>View</Button>
                        <span className='title-heading version-num'>v 1.0</span>
                      </div>
                    </div>
                    {/*end reminder list card  */}
                    {/*reminder list card  */}
                    <div className='agreement-reminder-list-card'>
                      <div className='agreement-reminder-list-desc'>
                        <p className='desc'>
                          <strong>Created by John Doe</strong>
                        </p>
                        <p className='sec-heading-subtitle'>
                          Today, 05 Aug 2020 / 02:05:03 PM
                        </p>
                      </div>
                      <div className='v-history-btns'>
                        <span className='pdflink mr-15'>
                          <DownloadOutlined /> Download as PDF
                        </span>
                        <Button className='primary-button mr-15'>View</Button>
                        <span className='title-heading version-num'>v 1.0</span>
                      </div>
                    </div>
                    {/*end reminder list card  */} {/*reminder list card  */}
                    <div className='agreement-reminder-list-card'>
                      <div className='agreement-reminder-list-desc'>
                        <p className='desc'>
                          <strong>Created by John Doe</strong>
                        </p>
                        <p className='sec-heading-subtitle'>
                          Today, 05 Aug 2020 / 02:05:03 PM
                        </p>
                      </div>
                      <div className='v-history-btns'>
                        <span className='pdflink mr-15'>
                          <DownloadOutlined /> Download as PDF
                        </span>
                        <Button className='primary-button mr-15'>View</Button>
                        <span className='title-heading version-num'>v 1.0</span>
                      </div>
                    </div>
                    {/*end reminder list card  */} {/*reminder list card  */}
                    <div className='agreement-reminder-list-card'>
                      <div className='agreement-reminder-list-desc'>
                        <p className='desc'>
                          <strong>Created by John Doe</strong>
                        </p>
                        <p className='sec-heading-subtitle'>
                          Today, 05 Aug 2020 / 02:05:03 PM
                        </p>
                      </div>
                      <div className='v-history-btns'>
                        <span className='pdflink mr-15'>
                          <DownloadOutlined /> Download as PDF
                        </span>
                        <Button className='primary-button mr-15'>View</Button>
                        <span className='title-heading version-num'>v 1.0</span>
                      </div>
                    </div>
                    {/*end reminder list card  */}
                  </Drawer>
                </Row>
              </div>
            </TabPane>
            {/* PO Details tab ends */}
            {/* PO Tracking tab */}
            <TabPane tab={<span>PO Tracking</span>} key='2'>
              <PoTracking />
            </TabPane>
            {/* PO Tracking tab end */}
          </Tabs>
        </div>
        <Comment />
      </>
    );
  }
}
export default PoDetailsTrackingTab;
