import './MemberRole.css';

import CardWithTitle from '../common/cards/CardWithTitle';
import ConfirmationModal from 'src/modals/common/ConfirmationModal';
import React from 'react';
import { Table } from 'antd';
import TeamTableManager from 'src/utils/tables/TeamTableManager';
import UpdateUserRoleModal from 'src/modals/team/UpdateUserRoleModal';
import { connect } from 'react-redux';

class MemberRole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUserRoleModalVisible: false,
      removeUserModalVisible: false,
      teamId: undefined,
      userId: undefined,
      role: undefined,
      userName: undefined,
    };
  }

  getUserRoleColumns = () => {
    return TeamTableManager.getUserRoleColumns.call(this);
  };

  onCancel = () => {
    this.setState({ updateUserRoleModalVisible: false });
  };

  render() {
    const { users } = this.props;
    const {
      updateUserRoleModalVisible,
      teamId,
      userId,
      role,
      removeUserModalVisible,
      userName,
    } = this.state;
    return (
      <>
        <CardWithTitle title='Team Members'>
          <Table
            locale={{ filterConfirm: 'Apply' }}
            columns={this.getUserRoleColumns()}
            dataSource={users}
            showHeader={false}
            rowKey='userId'
            pagination={users && users.length >= 10}
          />
        </CardWithTitle>
        {updateUserRoleModalVisible && (
          <UpdateUserRoleModal
            visible={updateUserRoleModalVisible}
            teamId={teamId}
            userId={userId}
            userName={userName}
            role={role}
            onCancel={this.onCancel}
            refreshPage={() => {
              this.setState({ updateUserRoleModalVisible: false }, () =>
                this.props.refreshPage()
              );
            }}
          />
        )}
        <ConfirmationModal
          visible={removeUserModalVisible}
          onSubmit={() => this.props.removeUser(teamId, userId)}
          onCancel={() => this.setState({ removeUserModalVisible: false })}
          title='Confirm removal of user'
          description={
            'Are you sure you want to remove the user ' + userName + '?'
          }
          negativeAction={true}
          okText='Yes, Remove'
          actionLoading={this.props.removeActionLoading}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(MemberRole);
