import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import { Link } from 'react-router-dom';
import ProformaService from 'src/services/ProformaService';
import ProformaTableManager from 'src/utils/tables/ProformaTableManager';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import StringConstants from 'src/constants/StringConstants';
import { Table } from 'antd';
import UrlGenerator from 'src/api/UrlGenerator';

function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}

class PendingProformaOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalProformas: 0,
      loading: false,
      proformasData: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    ProformaService.getPendingProformas({
      limit: 10,
      status: StringConstants.PROFORMA_STATUS.REVIEW_APPROVED,
    })
      .then((response) => {
        this.setState({
          loading: false,
          proformasData: response.data,
          totalProformas: response.headers['x-total-count'],
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          proformasData: [],
          totalProformas: 0,
        });
      });
  }

  getColumnsForTable = () => {
    const { proformasData } = this.state;
    return ProformaTableManager.getColumnsForImporter(proformasData);
  };

  render() {
    const { proformasData, totalProformas, loading } = this.state;
    return (
      <CardWithTitle
        title='Proforma Review'
        badge={totalProformas}
        action={
          <Link
            className='head-box-va'
            to={UrlGenerator.getUiUrlWithQueryParams(RouteConstants.PROFORMAS, {
              status: StringConstants.PROFORMA_STATUS.REVIEW_APPROVED,
            })}
          >
            View All
          </Link>
        }
      >
        <Table
          loading={loading}
          locale={{ filterConfirm: 'Apply' }}
          columns={this.getColumnsForTable()}
          dataSource={proformasData}
          onChange={onChange}
          pagination={false}
          rowKey={(row) => row.id}
        />
      </CardWithTitle>
    );
  }
}
export default PendingProformaOverview;
