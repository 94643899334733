import './AcceptInviteCompany.css';

import { Button, Col, Form, Input, Row, Select } from 'antd';

import LoadingComponent from 'src/components/common/LoadingComponent';
import MetadataService from 'src/services/MetadataService';
import React from 'react';
import { sortBy } from 'lodash';

class AcceptInviteCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentLoading: false,
      seaFoodList: [],
    };
  }

  componentDidMount() {
    this.setState({ componentLoading: true });
    MetadataService.getSeaFoodList()
      .then((response) =>
        this.setState({ seaFoodList: response.data, componentLoading: false })
      )
      .catch(() => this.setState({ componentLoading: false }));
  }

  submitCompanyDetails = (values) => {
    this.props.submit(values.company);
  };

  render() {
    const { loading } = this.props;
    const { seaFoodList, componentLoading } = this.state;
    if (componentLoading) return <LoadingComponent />;
    return (
      <>
        <Form
          className='all-form signup-form acceptInviteCompany'
          layout='vertical'
          ref={(ref) => (this.companyForm = ref)}
          onFinish={this.submitCompanyDetails}
        >
          {/* TODO Enable when we want users to upload images during signup */}
          {/* <div className='accept-invite-img-upload'>
            <div className='ai-userinfo-img'>
              <img
                src={
                  require('../../../../assets/images/thumbnail-img.png').default
                }
                alt='Thumbnai'
              />
            </div>
            <div className='ai-userinfo-img-upload'>
              <Form.Item
                name='upload'
                label='Company Logo'
                valuePropName='fileList'
              >
                <Upload>Upload</Upload>
              </Form.Item>
            </div>
          </div> */}

          <Form.Item
            name={['company', 'name']}
            label='Company Name'
            rules={[{ required: true, message: 'Please enter a valid name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['company', 'address', 'line1']}
            label='Address Line 1'
            rules={[{ required: true, message: 'Please enter your address' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['company', 'address', 'line2']}
            label='Address Line 2'
          >
            <Input />
          </Form.Item>
          <Row gutter={[20, 0]}>
            <Col xs={24} md={12}>
              <Form.Item
                name={['company', 'address', 'city']}
                label='City'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your city',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={['company', 'address', 'state']}
                label='State'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your state',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 0]}>
            <Col xs={24} md={12}>
              <Form.Item
                name={['company', 'address', 'zipcode']}
                label='Zip Code'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Zip Code',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={['company', 'address', 'country']}
                label='Country'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your country',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name={['company', 'registrationNumber']}
            label='Legal / Tax Number'
            rules={[
              {
                required: true,
                message: 'Please enter your Legal / Tax Number',
              },
            ]}
          >
            <Input />
          </Form.Item>
          {/* Species Capabilities */}
          <Form.Item
            name={['company', 'seaFoodList']}
            label='Species Capabilities'
            rules={[
              {
                required: true,
                message: 'Please select your Species Capabilities*',
              },
            ]}
          >
            <Select
              className='specification-tag-input'
              mode='multiple'
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {sortBy(seaFoodList, 'id').map((item) => (
                <Select.Option value={item.id} key={item.id}>
                  {item.commonName
                    ? item.commonName
                    : item.acceptableMarketName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Row>
            <Col flex='auto'>
              <Form.Item className='steps-action text-left-impt'>
                <Button
                  className='btn-secondary mt-20'
                  disabled={loading}
                  onClick={this.props.goBack}
                >
                  Go Back
                </Button>
              </Form.Item>
            </Col>
            <Col flex='auto'>
              <Form.Item className='steps-action text-right-impt'>
                <Button
                  className='btn-primary mt-20'
                  type='primary'
                  htmlType='submit'
                  loading={loading}
                >
                  Proceed
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}
export default AcceptInviteCompany;
