import './MapMarker.css';

import React from 'react';

class MapMarker extends React.Component {
  render() {
    return (
      <>
        <div className='map-marker'>
          <img
            className=''
            src={require('../../assets/images/Icon-Ship-Map.png').default}
            alt='marker'
          />
          <p>WALMART | GV Shrimp 30/12oz Bag 41-60 Ckd</p>
        </div>
      </>
    );
  }
}
export default MapMarker;
