import './Incoterm.css';

import { Checkbox, InputNumber, Space, Tooltip } from 'antd';
import React, { Component } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

class SelectIncoterm extends Component {
  static propTypes = {
    selectedData: PropTypes.array,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    readOnly: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      cnf: this.props.selectedData ? this.getIncotermValue('CNF') : false,
      ddpcy: this.props.selectedData ? this.getIncotermValue('DDPCY') : false,
      incotermCharge: this.props.selectedData
        ? this.getIncotermCharge('CNF')
        : 0,
    };
  }

  getIncotermValue = (incotermCode) => {
    let selected = false;
    this.props.selectedData.forEach((d) => {
      if (d.incoterm.code === incotermCode) selected = true;
    });
    return selected;
  };

  getEnteredIncotermCharge = () => {
    return this.state.incotermCharge;
  };

  getIncotermCharge = (incotermCode) => {
    let incotermCharge = 0;
    this.props.selectedData.forEach((d) => {
      if (d.incoterm.code === incotermCode) incotermCharge = d.charges;
    });
    return incotermCharge;
  };

  getSelectedIncoterms = () => {
    const { cnf, ddpcy, incotermCharge } = this.state;
    const obj = {
      charges: 0,
      incoterm: {
        code: 0,
      },
    };
    const data = [];
    if (cnf) {
      let cnf_ = cloneDeep(obj);
      cnf_.charges = incotermCharge;
      cnf_.incoterm.code = 'CNF';
      data.push(cnf_);
    }
    if (ddpcy) {
      let ddpcy_ = cloneDeep(obj);
      ddpcy_.charges = 0;
      ddpcy_.incoterm.code = 'DDPCY';
      data.push(ddpcy_);
    }
    return data;
  };

  render() {
    const { cnf, ddpcy, incotermCharge } = this.state;
    const { readOnly } = this.props;

    return (
      <div>
        <div>
          <Checkbox
            className={
              cnf
                ? 'incoterms incoterms-dark incoterm-cnf-dark'
                : 'incoterms incoterm-cnf-light'
            }
            onChange={(e) => {
              this.setState({ cnf: e.target.checked });
            }}
            checked={cnf}
            disabled={readOnly}
          >
            Cost and
            <br /> Freight
          </Checkbox>

          <Checkbox
            className={
              ddpcy
                ? 'incoterms incoterms-dark incoterm-cy-dark'
                : 'incoterms incoterm-cy-light'
            }
            onChange={(event) => {
              this.setState({ ddpcy: event.target.checked });
            }}
            checked={ddpcy}
            disabled={readOnly}
          >
            Delivered Duty Paid Container Yard
          </Checkbox>
        </div>
        {cnf && ddpcy && (
          <div className='mt-15 est-tool'>
            <Space>
              <p className='estimate-tooltip mb0'>
                Estimated customs brokerage costs per lb
              </p>
              <Tooltip
                className=''
                title='This information will be used in the RFQ to accurately compare CNF quotes with DDP CY quotes'
                placement='bottomLeft'
              >
                <span className='estimate-pop'>
                  <InfoCircleOutlined />
                </span>
              </Tooltip>
              <InputNumber
                className='custom-input-field'
                step={0.01}
                min={0}
                disabled={readOnly}
                defaultValue={incotermCharge}
                onChange={(value) => this.setState({ incotermCharge: value })}
                formatter={(value) => `$ ${value}`}
              />
            </Space>
          </div>
        )}
      </div>
    );
  }
}

export default SelectIncoterm;
