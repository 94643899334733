import {
  find,
  findIndex,
  groupBy,
  isEmpty,
  isUndefined,
  orderBy,
  uniq,
} from 'lodash';
import { hashCode, intToRGB } from './misc/ColorUtils';

import StringConstants from 'src/constants/StringConstants';
import { displayDecimal } from './DisplayUtils';
import moment from 'moment';

export const findChange = (bids) => {
  if (bids.length === 0 || bids.length === 1) return '-';
  else {
    let currentBidIncoterm = getDisplayIncoterm(bids[0].bidIncoterms);
    let prevBidIncoterm = getDisplayIncoterm(bids[1].bidIncoterms);

    if (isEmpty(currentBidIncoterm) || isEmpty(prevBidIncoterm)) return '-';
    else
      return displayDecimal(currentBidIncoterm.amount - prevBidIncoterm.amount);
  }
};

export const getDisplayIncoterm = (incoterms) => {
  return incoterms.length === 0
    ? {}
    : incoterms.length === 1
    ? {
        code: incoterms[0].incoterm.code,
        amount: incoterms[0].amount,
        isCalculated: incoterms[0].isCalculated,
      }
    : findHighestIncoterm(incoterms);
};

export const getDisplayIncotermForLegend = (bids) => {
  let displayIncoterm = 'DDPCY';

  let incotermsBiddedOn =
    bids &&
    uniq(bids.map((bid) => findActualBiddedIncoterm(bid.bidIncoterms).code));

  if (incotermsBiddedOn.length === 1) displayIncoterm = incotermsBiddedOn[0];

  return displayIncoterm;
};

export const findHighestIncoterm = (incoterms) => {
  let highestIncoterm = find(incoterms, function (o) {
    return o.incoterm.code === 'DDPCY';
  });

  if (!isUndefined(highestIncoterm))
    return {
      code: highestIncoterm.incoterm.code,
      amount: highestIncoterm.amount,
      isCalculated: highestIncoterm.isCalculated,
    };
  else return {};
};

export const findBidIncotermByCode = (incoterms, code) => {
  let incoterm = find(incoterms, function (o) {
    return o.incoterm.code === code;
  });

  if (!isUndefined(incoterm))
    return {
      code: incoterm.incoterm.code,
      amount: incoterm.amount,
      isCalculated: incoterm.isCalculated,
    };
  else return {};
};

export const findActualBiddedIncoterm = (incoterms) => {
  let incoterm = find(incoterms, function (o) {
    return !o.incoterm.isCalculated;
  });

  if (!isUndefined(incoterm))
    return {
      code: incoterm.incoterm.code,
      amount: incoterm.amount,
    };
  else return {};
};

export const findPreviousBidForCompany = (bids, currentBid) => {
  return find(bids, function (o) {
    return (
      moment(o.createdAt).isBefore(moment(currentBid.createdAt)) &&
      o.companyId === currentBid.companyId
    );
  });
};

export const getDateRangeOfAllBids = (chartData) => {
  let allDates = [];

  chartData.forEach((chartElt) => {
    allDates.push(...chartElt.data.map((elt) => elt.x));
  });

  allDates.sort();

  if (allDates.length === 0) return [];

  return [
    moment(allDates[0]), // start date
    moment(allDates[allDates.length - 1]), // end date
  ];
};

export const formBidChartData = (bidsObj, auctionIncoterms) => {
  let bids = bidsObj.bids;
  let biddingCompanies = bidsObj.biddingCompanies;

  let bidsByCompany = groupBy(bids, function (o) {
    return o.companyId;
  });

  let displayIncoterm = 'DDPCY';

  if (auctionIncoterms.length === 1) {
    let incotermsBiddedOn = uniq(
      bids.map((bid) => findActualBiddedIncoterm(bid.bidIncoterms).code)
    );

    if (incotermsBiddedOn.length === 1) displayIncoterm = incotermsBiddedOn[0];
  }

  let chartObj = [];
  Object.keys(bidsByCompany).forEach((company) => {
    let companyName = find(biddingCompanies, function (o) {
      return o.id === company;
    })
      ? find(biddingCompanies, function (o) {
          return o.id === company;
        }).name
      : '';

    let actualIncoterm = uniq(
      bidsByCompany[company].map(
        (bid) => findActualBiddedIncoterm(bid.bidIncoterms).code
      )
    );
    if (actualIncoterm.length > 0) actualIncoterm = actualIncoterm[0];
    chartObj.push({
      name: companyName,
      data: bidsByCompany[company].map((bid) => {
        return {
          x: new Date(bid.createdAt),
          y: find(bid.bidIncoterms, function (o) {
            return o.incoterm.code === displayIncoterm;
          }).amount,
          marker: find(bid.bidIncoterms, function (o) {
            return o.incoterm.code === displayIncoterm;
          }).isCalculated
            ? 'square'
            : 'circle',
        };
      }),
      finalIncoterm: displayIncoterm,
      actualIncoterm,
      color: intToRGB(hashCode(companyName)),
    });
  });

  // sort by date
  chartObj = chartObj.map((chartElt) => {
    return {
      ...chartElt,
      data: orderBy(chartElt.data, ['x'], ['asc']),
    };
  });

  return chartObj;
};

export const getRank = (bidId, bids) => {
  return (
    findIndex(
      orderBy(bids, function (o) {
        return getDisplayIncoterm(o.bidIncoterms).amount;
      }),
      function (orderedBid) {
        return bidId === orderedBid.id;
      }
    ) + 1
  );
};

export const filterBidsByIncoterm = (bids, incoterm) => {
  return (
    bids &&
    bids.filter((bid) => {
      let bidIncoterm = find(bid.bidIncoterms, function (o) {
        return o.incoterm.code === incoterm && !o.isCalculated;
      });
      return bidIncoterm;
    })
  );
};

export const formHistoricalPriceChartData = (data) => {
  let chartData =
    data &&
    data.map((elt) => {
      return [new Date(elt.postedAt).getTime(), elt.finalPrice];
    });

  chartData.sort(function (a, b) {
    if (a[0] === b[0]) return 0;
    return a[0] < b[0] ? -1 : 1;
  });

  return chartData;
};

export const getSubmittedOrAcceptedBid = (bids) => {
  let bidObj = find(bids, function (o) {
    return (
      o.status === StringConstants.BID_STATUS.SUBMITTED ||
      o.status === StringConstants.BID_STATUS.ACCEPTED
    );
  });

  return (
    bidObj && {
      ...findActualBiddedIncoterm(bidObj.bidIncoterms),
      id: bidObj.id,
      fclAvailable: bidObj.fclAvailable,
    }
  );
};

export const getLatestBidByParticipant = (bids) => {
  let bidsByParticipant = groupBy(bids, 'companyId');
  let latestBidObj = {};
  let latestBidByParticipant = [];
  Object.keys(bidsByParticipant).forEach((participant) => {
    let latestBid = find(bidsByParticipant[participant], function (o) {
      return (
        o.status === StringConstants.BID_STATUS.SUBMITTED ||
        o.status === StringConstants.BID_STATUS.ACCEPTED
      );
    });
    latestBidByParticipant.push(latestBid);
  });
  latestBidObj.bids = latestBidByParticipant;

  return latestBidObj;
};

export const getCurrentParticipantBids = (participants, plantBids) => {
  let currentParticipantBids = [];
  participants.forEach((participant) => {
    currentParticipantBids.push(
      ...participant.bids.filter((bid) => {
        return (
          plantBids && plantBids.map((plantBid) => plantBid.id).includes(bid.id)
        );
      })
    );
  });
  return currentParticipantBids;
};

export const findCustomsCharge = (incoterms) => {
  let incoterm = find(incoterms, function (o) {
    return o.incoterm.code === 'CNF';
  });

  if (incoterm) return incoterm.charges;

  return '-';
};
