import {
  camelCase,
  capitalize,
  isEmpty,
  isNull,
  isUndefined,
  lowerCase,
  startCase,
  upperCase,
} from 'lodash';

import DisplayConstants from 'src/constants/DisplayConstants';
import { Link } from 'react-router-dom';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import moment from 'moment';

export const getUsername = (user) => {
  if (isNull(user)) return 'Carava';
  let name = user.firstName;
  if (user.lastName) name += ' ' + user.lastName;

  return name;
};

export const getFormattedCurrentDate = () => {
  return moment().format(DisplayConstants.DATE_FORMAT);
};

export const getFormattedDate = (date) => {
  if (date) return moment(date).format(DisplayConstants.DATE_FORMAT);
  return '-';
};

export const getFormattedFullDate = (date) => {
  if (date) return moment(date).format(DisplayConstants.DATE_TIME_FORMAT);
  return '-';
};

export const getPortDisplayText = (data) => {
  return `${startCase(camelCase(data.name))}, ${data.country}`;
};

export const getBidDisplayText = (amount, code) => {
  if (isUndefined(code)) return getAmountDisplayText(amount);
  return getAmountDisplayText(amount) + ' / ' + code;
};

export const getAmountDisplayText = (amount) => {
  return amount ? '$ ' + displayDecimal(amount) : '';
};

export const getBidDisplayWithHelperText = (amount, code) => {
  return (
    <>
      {getAmountDisplayText(amount)}
      <span className='helper-text'>{' / ' + code}</span>
    </>
  );
};

export const getPercentageDisplayText = (value) => {
  return displayDecimal(value) + ' %';
};

export const getSavingsDisplayText = (savings) => {
  return (
    <span className={savings < 0 ? 'negative-savings' : 'positive-savings'}>
      {getPercentageDisplayText(savings)}
    </span>
  );
};

export const getChatItemDisplayText = (itemType, value, linkTo) => {
  if (itemType === 'link')
    return (
      <Link
        to={UrlGenerator.getUiUrlWithPathParams(linkTo, {
          id: value,
        })}
      >
        {value}
      </Link>
    );
  else if (itemType === 'date')
    return (
      getFormattedFullDate(moment(value)) +
      ' ( In ' +
      getTimeRemaining(moment(value)) +
      ' )'
    );
  else return value;
};

export const getPackCombinedDisplayText = (noOfUnits, netUnitSize, uom) => {
  if (noOfUnits && netUnitSize && uom)
    return noOfUnits + '/' + netUnitSize + uom;

  return '';
};

export const getSizeCombinedDisplayText = (
  minSizeCount,
  minSizeCountUom,
  maxSizeCount,
  maxSizeCountUom,
  varianceSymbol,
  variance,
  varianceUom
) => {
  if (minSizeCount && minSizeCountUom && maxSizeCount && maxSizeCountUom)
    return (
      minSizeCount +
      (variance ? '-' : minSizeCountUom + '- ') +
      maxSizeCount +
      (variance ? ' ' : maxSizeCountUom) +
      (variance ? varianceSymbol + ' ' + variance + ' ' + varianceUom : '')
    );
};

export const convertToCode = (value) => {
  return value.split(' ').length > 1
    ? value
        .split(' ')
        .map((word) => word.toUpperCase())
        .join('_')
    : value.toUpperCase();
};

export const getReadableDisplayName = (name) => {
  const displayName =
    name.split('_').length > 1
      ? name
          .split('_')
          .map((word) => word)
          .join(' ')
      : name;
  return capitalize(displayName);
};

export const getPoundConversion = (weight, weightUom) => {
  if (weightUom === StringConstants.UNITS.POUNDS) return weight;
  else if (weightUom === StringConstants.UNITS.OUNCES)
    return weight * StringConstants.CONVERSIONS.OUNCES_TO_POUNDS;
  else if (weightUom === StringConstants.UNITS.KILOGRAMS)
    return weight * StringConstants.CONVERSIONS.KG_TO_POUNDS;
  else if (weightUom === StringConstants.UNITS.GRAMS)
    return weight * StringConstants.CONVERSIONS.G_TO_POUNDS;
  else return undefined;
};

const calculateTimeRemaining = (date) => {
  var now = moment();
  var end = moment(date);
  var duration = moment.duration(end.diff(now));

  var days = Math.floor(duration.asDays());
  duration.subtract(moment.duration(days, 'days'));

  var hours = Math.floor(duration.hours());
  duration.subtract(moment.duration(hours, 'hours'));

  var minutes = Math.floor(duration.minutes());
  duration.subtract(moment.duration(minutes, 'minutes'));

  return { days, hours, minutes };
};

export const getTimeRemaining = (date) => {
  return calculateTimeRemaining(date).days
    ? calculateTimeRemaining(date).days +
        ' days, ' +
        calculateTimeRemaining(date).hours +
        ' hours and ' +
        calculateTimeRemaining(date).minutes +
        ' minutes'
    : calculateTimeRemaining(date).hours +
        ' hours and ' +
        calculateTimeRemaining(date).minutes +
        ' minutes';
};

export const getProfilePicture = (user) => (
  <img
    src={
      user && user.profilePicUrl
        ? user.profilePicUrl + '?t=' + Date.now()
        : require('../assets/images/user.svg').default
    }
    alt={getUsername(user)}
  />
);

export const getCompanyLogo = (company) => (
  <img
    src={
      company && company.logoUrl
        ? company.logoUrl + '?t=' + Date.now()
        : require('../assets/images/user.svg').default
    }
    alt={company.name}
  />
);

export const displayDecimal = (amount) => {
  if (amount) {
    return parseFloat(amount).toFixed(2);
  } else {
    return '-';
  }
};

export const getDisplayString = (value, isStartCase = false) => {
  if (isEmpty(value)) return '-';
  if (value.includes('_') || value === upperCase(value)) {
    if (isStartCase) return startCase(lowerCase(value));
    else return capitalize(lowerCase(value));
  }
  return value;
};
