import { Col, Row } from 'antd';

import ActiveAuctionsOverview from 'src/components/auction/plants/ActiveAuctionsOverview';
import AuctionInvites from 'src/components/auction/plants/AuctionInvites';
import OpenProformaOverview from 'src/components/proforma/plants/OpenProformaOverview';
import React from 'react';
import TotalAuctionCard from '../../../components/auction/plants/TotalAuctionsCard';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';

class ProcessingPlantDashboard extends React.Component {
  render() {
    return (
      <>
        <HeaderMetadata title={`Dashboard | Carava`} description='' />
        <Row type='flex' gutter={15}>
          <Col span={24} xs={24} lg={24} xl={24}>
            <p className='pagetitle'>Dashboard</p>
          </Col>
          <Col span={10} xs={24} lg={6} xl={4}>
            <TotalAuctionCard />
          </Col>
          <Col span={10} xs={24} lg={12} xl={15}>
            {/*  Proforma Overview for Plants */}
            <OpenProformaOverview />
          </Col>
          <Col span={10} xs={24} lg={6} xl={5}>
            <AuctionInvites />
          </Col>
        </Row>
        <Row gutter={15} className='h-50'>
          <Col className='mt-15' span={10} xs={10} lg={24} xl={24}>
            {/* Auction Overview for Plants */}
            <ActiveAuctionsOverview />
          </Col>
        </Row>
      </>
    );
  }
}
export default ProcessingPlantDashboard;
