import { Button, Col, Row } from 'antd';
import {
  getBidDisplayText,
  getFormattedDate,
  getReadableDisplayName,
} from 'src/utils/DisplayUtils';

import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import UploadFilesComponent from '../common/UploadFilesComponent';
import UrlGenerator from 'src/api/UrlGenerator';
import { capitalize } from 'lodash';

class PurchaseOrderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseOrderId: this.props.purchaseOrderId,
      purchaseOrderDetails: this.props.purchaseOrderDetails,
      uploadingDocuments: false,
      uploadDisabled: true,
    };
  }

  uploadDocuments = () => {
    this.setState({ uploadingDocuments: true });
    this.uploadFilesRef.uploadDocuments();
  };

  render() {
    const {
      purchaseOrderDetails,
      uploadingDocuments,
      purchaseOrderId,
      uploadDisabled,
    } = this.state;
    const { fromNav } = this.props;

    return (
      <>
        <Row>
          <Col
            className='rscb-details mb-15 mt-15'
            span={24}
            xs={24}
            lg={20}
            xl={20}
          >
            <ul className='rscb-items mb-15'>
              <li>
                <span className='left-item'>ID :</span>
                <span className='right-item'>
                  {purchaseOrderDetails && purchaseOrderDetails.id}
                </span>
              </li>
              <li>
                <span className='left-item'>Container Number :</span>
                <span className='right-item'>
                  {purchaseOrderDetails && purchaseOrderDetails.containerNumber
                    ? purchaseOrderDetails.containerNumber
                    : '-'}
                </span>
              </li>
              <li>
                <span className='left-item'>Company PO Number :</span>
                <span className='right-item'>
                  {purchaseOrderDetails && purchaseOrderDetails.companyPoNumber
                    ? purchaseOrderDetails.companyPoNumber
                    : '-'}
                </span>
              </li>
              <li>
                <span className='left-item'>Buy From :</span>
                <span className='right-item'>
                  {fromNav
                    ? purchaseOrderDetails && purchaseOrderDetails.buyFromVendor
                    : purchaseOrderDetails.buyFrom &&
                      purchaseOrderDetails.buyFrom.name}
                </span>
              </li>
              <li>
                <span className='left-item'>Pay To :</span>
                <span className='right-item'>
                  {fromNav
                    ? purchaseOrderDetails && purchaseOrderDetails.payToVendor
                    : purchaseOrderDetails.payTo &&
                      purchaseOrderDetails.payTo.name}
                </span>
              </li>
              {purchaseOrderDetails && purchaseOrderDetails.auctionId && (
                <li>
                  <span className='left-item'>RFQ :</span>
                  <Link
                    to={UrlGenerator.getUiUrlWithPathParams(
                      RouteConstants.AUCTION,
                      {
                        id: purchaseOrderDetails.auctionId,
                      }
                    )}
                  >
                    <span className='auction-link'>
                      {purchaseOrderDetails && purchaseOrderDetails.auctionId}
                    </span>
                  </Link>
                </li>
              )}
              {purchaseOrderDetails && purchaseOrderDetails.proformaId && (
                <li>
                  <span className='left-item'>Proforma :</span>
                  <Link
                    to={UrlGenerator.getUiUrlWithPathParams(
                      RouteConstants.PROFORMA_DETAIL,
                      {
                        id: purchaseOrderDetails.proformaId,
                      }
                    )}
                  >
                    <span className='auction-link'>
                      {purchaseOrderDetails && purchaseOrderDetails.proformaId}
                    </span>
                  </Link>
                </li>
              )}
              <li>
                <span className='left-item'>Order Status: </span>
                <span className='right-item'>
                  {purchaseOrderDetails &&
                    capitalize(
                      getReadableDisplayName(purchaseOrderDetails.status)
                    )}
                </span>
              </li>
              <li>
                <span className='left-item'>Payment Terms: </span>
                <span className='right-item'>
                  {purchaseOrderDetails && purchaseOrderDetails.paymentTerms}
                </span>
              </li>
              <li>
                <span className='left-item'>Requested ETA :</span>
                <span className='right-item'>
                  {purchaseOrderDetails &&
                    getFormattedDate(purchaseOrderDetails.requestedEta)}
                </span>
              </li>
              <li>
                <span className='left-item'>Origin country :</span>
                <span className='right-item'>
                  {purchaseOrderDetails && purchaseOrderDetails.fromCountry}
                </span>
              </li>
            </ul>
            <ul className='rscb-items mt-15'>
              <li>
                <span className='left-item'>Final Quote Value :</span>
                <span className='right-item'>
                  {purchaseOrderDetails
                    ? getBidDisplayText(
                        purchaseOrderDetails.finalBidValue,
                        purchaseOrderDetails.incoterm &&
                          purchaseOrderDetails.incoterm.code
                      )
                    : '-'}
                </span>
              </li>
              <li>
                <span className='left-item '>Quantity :</span>
                <span className='right-item '>
                  <span className='list-sub-items'>
                    Total Cases:
                    <br />
                    <span className='color-blue'>
                      <NumberFormat
                        value={
                          purchaseOrderDetails &&
                          purchaseOrderDetails.totalCases
                        }
                        thousandSeparator={true}
                        displayType='text'
                      />
                    </span>
                  </span>
                  <span className='list-sub-items'>
                    Total LBS:
                    <br />
                    <span className='color-blue'>
                      <NumberFormat
                        value={
                          purchaseOrderDetails &&
                          purchaseOrderDetails.totalWeight
                        }
                        thousandSeparator={true}
                        displayType='text'
                      />
                    </span>
                  </span>
                </span>
              </li>

              <li>
                <span className='left-item'>Additional Remarks :</span>
                <span className='right-item'>
                  {purchaseOrderDetails && purchaseOrderDetails.remark}
                </span>
              </li>

              {!fromNav && (
                <>
                  <hr className='upload-files-divider' />

                  <UploadFilesComponent
                    ref={(ref) => (this.uploadFilesRef = ref)}
                    entity='PURCHASE_ORDER'
                    entityId={purchaseOrderId}
                    setUploadButtonEnabled={(value) =>
                      this.setState({ uploadDisabled: !value })
                    }
                    refreshData={this.props.fetchPageData}
                    resetLoadingButton={() =>
                      this.setState({ uploadingDocuments: false })
                    }
                  />
                </>
              )}
            </ul>
          </Col>

          {!fromNav && (
            <Col span={24} xs={24} lg={24} xl={24}>
              <div className='editprofile-btn-area mt-15 mb-15'>
                <Button
                  className='primary-full mr-15'
                  type='primary'
                  loading={uploadingDocuments}
                  disabled={uploadDisabled}
                  onClick={this.uploadDocuments}
                >
                  Upload Documents
                </Button>
              </div>
            </Col>
          )}
        </Row>
      </>
    );
  }
}

export default PurchaseOrderDetail;
