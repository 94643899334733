import { Column, Row } from 'simple-flexbox';

import React from 'react';
import { Typography } from 'antd';

const LoadingComponent = (props) => {
  return (
    <Row vertical='center' horizontal='center' style={{ height: '100%' }}>
      <Column className='text-center'>
        <img
          src={require('../../assets/images/Loader.gif').default}
          alt='Loading...'
        />
        <Typography.Text type='secondary'>
          {props.title ? props.title : 'Loading...'}
        </Typography.Text>
      </Column>
    </Row>
  );
};

export default LoadingComponent;
