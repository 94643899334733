import { Button, Col, Row, Table } from 'antd';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { groupBy, isEmpty, isUndefined } from 'lodash';

import DocumentPreviewModal from 'src/modals/documentPreview/DocumentPreviewModal';
import DocumentService from 'src/services/DocumentService';
import ExpiryDateModal from 'src/modals/documentPreview/ExpiryDateModal';
import LoadingComponent from './LoadingComponent';
import PropTypes from 'prop-types';
import React from 'react';
import StringConstants from 'src/constants/StringConstants';
import UploadFilesTableManager from 'src/utils/tables/UploadFilesTableManager';
import { getFormattedDate } from 'src/utils/DisplayUtils';
import { toast } from 'react-toastify';

class UploadFilesComponent extends React.Component {
  static propTypes = {
    entity: PropTypes.string.isRequired,
    entityId: PropTypes.string.isRequired,
    refreshData: PropTypes.func,
    setUploadButtonEnabled: PropTypes.func,
    templateState: PropTypes.object,
    successMessage: PropTypes.string,
    doNotFetchUploadedDocuments: PropTypes.bool,
    readOnly: PropTypes.bool,
    forImporter: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      documentTemplates: [],
      groupedDocumentTemplates: [],
      uploadedDocuments: [],
      documentPreviewModalVisible: false,
      expiryDateModalVisible: false,
      currentTemplate: undefined,
      currentBlob: undefined,
    };
  }

  componentDidMount() {
    const { entity, doNotFetchUploadedDocuments, forImporter } = this.props;

    if (forImporter) {
      DocumentService.getProcessingPlantDocumentTemplatesForImporter({
        documentEntity: entity,
      })
        .then((templatesResponse) => {
          this.setState(
            {
              documentTemplates: templatesResponse.data,
              groupedDocumentTemplates: groupBy(
                templatesResponse.data,
                'groupName'
              ),
            },
            async () => {
              if (!doNotFetchUploadedDocuments) {
                let { uploadedDocuments } = await this.fetchUploadedDocuments();
                await this.downloadUploadedDocuments(uploadedDocuments);
              } else this.setState({ loading: false });
            }
          );
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    } else {
      DocumentService.getDocumentTemplate({
        documentEntity: entity,
      })
        .then((templatesResponse) => {
          this.setState(
            {
              documentTemplates: templatesResponse.data,
              groupedDocumentTemplates: groupBy(
                templatesResponse.data,
                'groupName'
              ),
            },
            async () => {
              if (!doNotFetchUploadedDocuments) {
                let { uploadedDocuments } = await this.fetchUploadedDocuments();
                await this.downloadUploadedDocuments(uploadedDocuments);
              } else this.setState({ loading: false });
            }
          );
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    }
  }

  getData = () => {
    return this.state;
  };

  fetchUploadedDocuments = async () => {
    const { entity, entityId, forImporter } = this.props;
    try {
      let data = {
        entityType: entity,
        entityId: entityId,
      };
      let uploadedDocumentsRes = [];

      if (forImporter)
        uploadedDocumentsRes =
          await DocumentService.getProcessingPlantDocumentsForImporter(
            entityId
          );
      else
        uploadedDocumentsRes = await DocumentService.getUploadedDocuments(data);
      return {
        uploadedDocuments: uploadedDocumentsRes.data,
      };
    } catch (err) {
      return { uploadedDocuments: [] };
    }
  };

  downloadUploadedDocuments = async (uploadedDocuments) => {
    let downloadedDocs = uploadedDocuments.map((doc) => {
      return DocumentService.downloadDocument(doc.id);
    });
    Promise.all(downloadedDocs)
      .then((blobResponses) => {
        let blobs = {};
        blobResponses.forEach((blobResponse, index) => {
          let data = blobResponse.data;
          let docType = uploadedDocuments[index].documentTemplateResponse.type;
          let fileNameSplit = uploadedDocuments[index].fileName.split('.');
          let fileType = fileNameSplit[fileNameSplit.length - 1];
          let displayName =
            uploadedDocuments[index].documentTemplateResponse.displayName;
          let expiryDate = uploadedDocuments[index].expiryDate;
          let isExpired = uploadedDocuments[index].isExpired;
          let docKey =
            'uploadedFile_' +
            uploadedDocuments[index].documentTemplateResponse.type;
          if (docKey in blobs)
            blobs[docKey].push({
              data,
              docType,
              fileType,
              displayName,
              expiryDate,
              isExpired,
            });
          else
            blobs[docKey] = [
              {
                data,
                docType,
                fileType,
                displayName,
                expiryDate,
                isExpired,
              },
            ];
        });
        this.setState({ uploadedDocuments: blobs, loading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  viewDocument = (blob) => {
    let mimeType = StringConstants.FILE_TYPE_TO_MIME_TYPE[blob.fileType];
    this.setState({
      currentBlob: {
        ...blob,
        data: blob.data.slice(0, blob.data.size, mimeType),
        docType: blob.displayName,
      },
      documentPreviewModalVisible: true,
    });
  };

  downloadDocument = (blob) => {
    const { entityId } = this.props;
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(new Blob([blob.data]));
    link.href = url;

    link.setAttribute(
      'download',
      entityId + '-' + blob.displayName + '.' + blob.fileType
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  anyFileUploaded = () => {
    const { documentTemplates } = this.state;
    return documentTemplates.some(
      (template) => !isUndefined(this.state[template.type + '_file'])
    );
  };

  resetState = () => {
    const { documentTemplates } = this.state;
    let uploadedTemplates = {};
    documentTemplates.forEach((template) => {
      uploadedTemplates[template.type + '_file'] = undefined;
    });
    this.setState({ ...uploadedTemplates });
  };

  uploadDocuments = (newEntityId) => {
    const { documentTemplates } = this.state;
    const { templateState, successMessage } = this.props;
    let uploadResponses = [];
    let uploadCount = 0;

    let templateData = isUndefined(templateState) ? this.state : templateState;

    let entityId = this.props.entityId;
    if (isUndefined(entityId)) entityId = newEntityId;

    let promises = documentTemplates.map((template) => {
      return new Promise(async (resolve, reject) => {
        let isTemplateFileUploaded = !isUndefined(
          templateData[template.type + '_file']
        );

        if (isTemplateFileUploaded) {
          uploadCount = uploadCount + 1;
          let formData = new FormData();
          formData.append('file', templateData[template.type + '_file']);
          formData.append('documentTemplateId', template.id);
          formData.append('entityId', entityId);
          if (
            template.expiryRequired &&
            !isUndefined(templateData[template.type + '_expiryDate'])
          )
            formData.append(
              'expiryDate',
              templateData[template.type + '_expiryDate'] + 'Z'
            );
          let uploadResponse = await DocumentService.uploadDocument(formData);
          if (uploadResponse.status === 201)
            uploadResponses.push(uploadResponse);
          else {
            toast.error('Failed to upload templates');
            this.props.resetLoadingButton();
          }
        }

        resolve();
      });
    });

    Promise.all(promises)
      .then(() => {
        if (uploadResponses.length !== uploadCount) {
          toast.error('Failed to upload templates');
          this.props.resetLoadingButton();
        } else {
          toast.success(successMessage);
          this.props.resetLoadingButton();
          this.props.refreshData();
        }
      })
      .catch((err) => this.props.resetLoadingButton());
  };

  render() {
    const {
      loading,
      groupedDocumentTemplates,
      uploadedDocuments,
      documentPreviewModalVisible,
      expiryDateModalVisible,
      currentTemplate,
      currentBlob,
    } = this.state;

    const { entity, entityId } = this.props;

    if (loading)
      return <LoadingComponent title='Fetching Uploaded Files ...' />;

    if (isEmpty(groupedDocumentTemplates))
      return <p className='nd-text mb-0'> No templates found</p>;

    return (
      <li>
        <Row gutter={30}>
          {Object.keys(groupedDocumentTemplates).map((templateGroup) => {
            let templatesInGroup = groupedDocumentTemplates[templateGroup];
            let uploadedTemplates = templatesInGroup.map((template) => {
              return Object.keys(uploadedDocuments).filter((docKey) => {
                return uploadedDocuments[docKey].every(
                  (doc) => doc.docType === template.type
                );
              });
            });

            let finalTemplatesInGroup = [];

            finalTemplatesInGroup = templatesInGroup.map(
              (templateInGroup, index) => {
                return {
                  ...templateInGroup,
                  uploadedFiles:
                    uploadedTemplates.length > 0
                      ? uploadedDocuments[uploadedTemplates[index]]
                      : [],
                };
              }
            );

            return (
              <Col span={24} key={templateGroup}>
                <p className='title-small-heading mb-0'>
                  {templateGroup === 'null' ? '' : templateGroup}
                </p>
                <Table
                  columns={UploadFilesTableManager.getColumns.call(this)}
                  dataSource={finalTemplatesInGroup}
                  rowKey='id'
                  expandable={{
                    expandedRowRender: (record) => {
                      return (
                        <Row>
                          {record.uploadedFiles.map((doc, index) => {
                            return (
                              <Col
                                span={24}
                                key={doc.displayName + '_' + index}
                              >
                                <span
                                  key={doc.displayName + '_' + index}
                                  className='uploaded-document-title'
                                  style={
                                    doc.isExpired
                                      ? {
                                          color: '#717070',
                                          fontWeight: 400,
                                          textDecoration: 'line-through',
                                        }
                                      : {}
                                  }
                                >
                                  {doc.displayName + ' ' + (index + 1)}
                                  {doc.expiryDate
                                    ? ' (' +
                                      (doc.isExpired ? 'Expired' : 'Expires') +
                                      ' on ' +
                                      getFormattedDate(doc.expiryDate) +
                                      ')'
                                    : ''}
                                </span>
                                <Button
                                  type='link'
                                  className='head-box-download'
                                  onClick={() => this.downloadDocument(doc)}
                                >
                                  <DownloadOutlined className='mr-0' />
                                </Button>
                                <Button
                                  type='link'
                                  className='head-box-download'
                                >
                                  <EyeOutlined
                                    className='mr-15'
                                    onClick={() => this.viewDocument(doc)}
                                  />
                                </Button>
                              </Col>
                            );
                          })}
                        </Row>
                      );
                    },
                    rowExpandable: (record) =>
                      !isUndefined(record.uploadedFiles) &&
                      record.uploadedFiles.length > 1,
                  }}
                  pagination={false}
                ></Table>
              </Col>
            );
          })}
        </Row>
        {documentPreviewModalVisible && (
          <DocumentPreviewModal
            visible={documentPreviewModalVisible}
            blobData={currentBlob}
            additionalData={{
              entity: entity,
              entityId: entityId,
            }}
            handleClose={() =>
              this.setState({ documentPreviewModalVisible: false })
            }
          />
        )}
        {expiryDateModalVisible && (
          <ExpiryDateModal
            visible={expiryDateModalVisible}
            documentType={currentTemplate}
            handleOk={(value) =>
              this.setState({
                [currentTemplate + '_expiryDate']: value,
                expiryDateModalVisible: false,
              })
            }
            handleClose={() => {
              this.setState({
                expiryDateModalVisible: false,
              });
              this.props.resetLoadingButton();
            }}
          />
        )}
      </li>
    );
  }
}

export default UploadFilesComponent;
