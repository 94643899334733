import {
  isUserImporter,
  isUserPartOfTeam,
  isUserPlant,
} from 'src/helpers/user';

import StringConstants from 'src/constants/StringConstants';

const menuOptions = {
  [[StringConstants.COMPANY_TYPES.IMPORTER]]: [
    {
      key: 'DASHBOARD',
      imgSrc: require('../../../assets/images/sidebar/Icon-Home-Filled.svg')
        .default,
      title: 'Dashboard',
      index: 0,
    },
    {
      key: 'PROFORMAS',
      imgSrc: require('../../../assets/images/sidebar/Icon-Proforma-Filled.svg')
        .default,
      title: 'Proformas',
      index: 4,
    },
    {
      key: 'PURCHASE_ORDERS',
      imgSrc:
        require('../../../assets/images/sidebar/Icon-Purchase-Orders-Filled.svg')
          .default,
      title: 'Purchase Orders',
      index: 5,
    },
  ],
  [[StringConstants.COMPANY_TYPES.PROCESSING_PLANT]]: [
    {
      key: 'DASHBOARD',
      imgSrc: require('../../../assets/images/sidebar/Icon-Home-Filled.svg')
        .default,
      title: 'Dashboard',
      index: 0,
    },
    {
      key: 'AUCTIONS',
      imgSrc: require('../../../assets/images/sidebar/Icon-Bid-Filled.svg')
        .default,
      title: 'Request for Quotes',
      index: 1,
    },
    {
      key: 'PROFORMAS',
      imgSrc: require('../../../assets/images/sidebar/Icon-Proforma-Filled.svg')
        .default,
      title: 'Proformas',
      index: 2,
    },
  ],
  OTHERS: [
    {
      key: 'DASHBOARD',
      imgSrc: require('../../../assets/images/sidebar/Icon-Home-Filled.svg')
        .default,
      title: 'Dashboard',
      index: 0,
    },
  ],
};

const getSidebarMenuItems = () => {
  if (isUserImporter()) {
    if (
      isUserPartOfTeam(StringConstants.TEAMS.EXECUTIVE) ||
      isUserPartOfTeam(StringConstants.TEAMS.SOURCING)
    )
      return [
        ...menuOptions[StringConstants.COMPANY_TYPES.IMPORTER],

        {
          key: 'SPECIFICATIONS',
          imgSrc:
            require('../../../assets/images/sidebar/Icon-Specification-Filled.svg')
              .default,
          title: 'Specifications',
          index: 1,
        },
        {
          key: 'PLANNED_ORDERS',
          imgSrc:
            require('../../../assets/images/sidebar/Icon-PlannedOrder-Filled.svg')
              .default,
          title: 'Planned Orders',
          index: 2,
        },
        {
          key: 'AUCTIONS',
          imgSrc: require('../../../assets/images/sidebar/Icon-Bid-Filled.svg')
            .default,
          title: 'Request for Quotes',
          index: 3,
        },
        {
          key: 'PROCESSING_PLANTS',
          imgSrc:
            require('../../../assets/images/sidebar/Icon-Suppliers-Filled.svg')
              .default,
          title: 'Processing Plants',
          index: 6,
        },
      ];
    else if (isUserPartOfTeam(StringConstants.TEAMS.ACCOUNT_MANAGERS))
      return [
        ...menuOptions[StringConstants.COMPANY_TYPES.IMPORTER],
        {
          key: 'SPECIFICATIONS',
          imgSrc:
            require('../../../assets/images/sidebar/Icon-Specification-Filled.svg')
              .default,
          title: 'Specifications',
          index: 1,
        },
        {
          key: 'PLANNED_ORDERS',
          imgSrc:
            require('../../../assets/images/sidebar/Icon-PlannedOrder-Filled.svg')
              .default,
          title: 'Planned Orders',
          index: 2,
        },
      ];
    else if (
      isUserPartOfTeam(StringConstants.TEAMS.SUSTAINABILITY) ||
      isUserPartOfTeam(StringConstants.TEAMS.COMPLIANCE)
    )
      return menuOptions[StringConstants.COMPANY_TYPES.IMPORTER];
  } else if (isUserPlant())
    return menuOptions[StringConstants.COMPANY_TYPES.PROCESSING_PLANT];
  else return menuOptions['OTHERS'];
};

export default getSidebarMenuItems;
