import { Col, Form, Input, Row, Select } from 'antd';

import { CaretDownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import SpecificationUtils from 'src/utils/SpecificationUtils';
import StringConstants from 'src/constants/StringConstants';
import { capitalize } from 'lodash';
import { getPackCombinedDisplayText } from 'src/utils/DisplayUtils';

const { Option } = Select;

class BasicItemSizeInformation extends React.Component {
  static propTypes = {
    noOfUnits: PropTypes.number,
    netUnitSize: PropTypes.number,
    baseUom: PropTypes.string,
  };

  static defaultProps = {
    baseUom: StringConstants.UNITS.POUNDS,
  };

  constructor(props) {
    super(props);
    this.state = {
      noOfUnits: this.props.noOfUnits,
      netUnitSize: this.props.netUnitSize,
      baseUom: this.props.baseUom,
    };
  }
  setNetUnitSize = (value) => {
    this.setState({ netUnitSize: value });
  };

  setNoOfUnits = (value) => {
    this.setState({ noOfUnits: value });
  };

  setBaseUom = (value) => {
    this.setState({ baseUom: value });
  };

  getData = () => {
    const { noOfUnits, netUnitSize, baseUom } = this.state;
    return {
      noOfUnitsInCase: noOfUnits,
      netUnitSize,
      baseUom,
    };
  };

  render() {
    const { noOfUnits, netUnitSize, baseUom } = this.state;
    return (
      <Row gutter={30}>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            label='Net Unit Size'
            name='netUnitSize'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Input
              className='specification-input'
              placeholder='Please enter net unit size'
              onChange={(event) => this.setNetUnitSize(event.target.value)}
              type='number'
              step={0.1}
              min={1}
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            label='Unit of Measure'
            name='baseUom'
          >
            <Select
              suffixIcon={<CaretDownOutlined />}
              className='planned-vi-sel-btn'
              defaultValue={baseUom}
              onChange={this.setBaseUom}
            >
              {Object.keys(StringConstants.UNITS).map((unit) => (
                <Option key={unit} value={StringConstants.UNITS[unit]}>
                  {capitalize(unit) + ' (' + StringConstants.UNITS[unit] + ')'}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            label='# of Units in Case'
            name='noOfUnitsInCase'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Input
              className='specification-input'
              placeholder='Please enter no of units'
              onChange={(event) => this.setNoOfUnits(event.target.value)}
              type='number'
              step={1}
              min={1}
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            label='Pack Combined'
            // name='packCombined'
          >
            <Input
              className='specification-input'
              value={getPackCombinedDisplayText(
                noOfUnits,
                netUnitSize,
                baseUom
              )}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            label='Shelf Life from Production (months)'
            name='shelfLifeFromProductionInMonths'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Input
              className='specification-input'
              placeholder='Please enter shelf life from production in months'
              type='number'
              step={1}
              min={1}
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            label='Shelf Life Requirements for Delivery to Customer (months)'
            name='shelfLifeReqForCustDeliveryInMonths'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Input
              className='specification-input'
              placeholder='Please enter shelf life requirements for delivery to customer in months'
              type='number'
              step={1}
              min={1}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

export default BasicItemSizeInformation;
