import { Form, Input, Modal } from 'antd';
import React, { Component } from 'react';

import CompanyService from 'src/services/CompanyService';
import PropTypes from 'prop-types';
import Title from 'src/components/common/typography/Title';
import { toast } from 'react-toastify';

class ChooseMainCompanyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  static defaultProps = {
    heading: 'Confirm your action',
    okText: 'Submit',
    cancelText: 'Cancel',
  };

  onSubmit = (values) => {
    this.setState({ loading: true });
    CompanyService.subPlantLinkingInvite(values)
      .then((response) => {
        toast.success('Sent representation invite Successfully');
        this.setState({ loading: false }, () => this.props.refreshPage());
      })
      .catch((err) => {
        toast.error('Failed to send representation invite');
        this.setState({ loading: false, isError: true, errorMsg: err });
      });
  };

  render() {
    const { visible } = this.props;

    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };

    return (
      <Modal
        className='confirmation-modal'
        centered
        visible={visible}
        okText='Submit'
        onOk={() => this.chooseMainCompanyForm.submit()}
        onCancel={() => this.props.onCancel()}
        cancelButtonProps={{ className: 'cancel-outlined-button' }}
        width={900}
        closable={false}
        maskClosable={false}
      >
        <Title title='Send representation invite' />
        <Form
          {...layout}
          onFinish={this.onSubmit}
          preserve={false}
          ref={(ref) => (this.chooseMainCompanyForm = ref)}
          className='mt-30'
        >
          <Form.Item
            label='Email Address'
            name='email'
            labelAlign='left'
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid E-mail!',
              },
              {
                required: true,
                message: 'Please enter an E-mail!',
              },
            ]}
            hasFeedback
          >
            <Input className='invite-member-field' />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default ChooseMainCompanyModal;
