import './Comments.css';

import { Button, Col, Form, Input, Row, Space, Spin, Tooltip } from 'antd';
// import { DeleteFilled, EditFilled } from '@ant-design/icons';
import React, { Component } from 'react';
import { concat, sortBy } from 'lodash';
import {
  getFormattedFullDate,
  getProfilePicture,
  getUsername,
} from 'src/utils/DisplayUtils';

import ApiUtils from 'src/utils/ApiUtils';
import CardWithTitle from '../common/cards/CardWithTitle';
import CommentService from 'src/services/CommentService';
import EmptySection from '../common/emptySection/EmptySection';
import PropTypes from 'prop-types';
import StringConstants from 'src/constants/StringConstants';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

class Comments extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    entity: PropTypes.oneOf([StringConstants.COMMNENT_ENTITY.PROFORMA])
      .isRequired,
    allowAdd: PropTypes.bool,
  };

  static defaultProps = {
    allowAdd: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isAddComment: false,
      comments: [],
      loading: false,
      olderCommentsLoading: false,
      submitLoading: false,
      page: 0,
      limit: 10,
      totalComments: 0,
    };
  }

  componentDidMount = () => this.fetchComments();

  fetchComments = () => {
    const { id, entity } = this.props;
    const { page, limit } = this.state;
    this.setState({ loading: true });
    CommentService.fetchComments(id, entity, page, limit)
      .then((response) => {
        this.setState({
          comments: response.data,
          loading: false,
          totalComments: parseInt(response.headers['x-total-count']),
        });
      })
      .catch((err) => {
        let errorMessage = ApiUtils.getErrorMessage(
          err,
          'Unable to fetch comments at the moment'
        );
        toast.warning(errorMessage);
        this.setState({ loading: false });
      });
  };

  fetchOlderComments = () => {
    const { id, entity } = this.props;
    const { page, limit } = this.state;
    this.setState({ olderCommentsLoading: true });
    CommentService.fetchComments(id, entity, page + 1, limit)
      .then((response) => {
        const oldComments = response.data;
        this.setState({
          comments: concat(this.state.comments, oldComments),
          olderCommentsLoading: false,
          page: page + 1,
          totalComments: parseInt(response.headers['x-total-count']),
        });
      })
      .catch((err) => {
        let errorMessage = ApiUtils.getErrorMessage(
          err,
          'Unable to fetch comments at the moment'
        );
        toast.warning(errorMessage);
        this.setState({ olderCommentsLoading: false });
      });
  };

  addComment = (values) => {
    const { entity, id } = this.props;
    this.setState({ submitLoading: true });
    CommentService.addComment(id, entity, values.comment)
      .then(() => {
        this.commentForm.resetFields();
        this.setState({ isAddComment: false, submitLoading: false });
        toast.success('Comment Added Successfully', { autoClose: 2000 });
        this.fetchComments();
      })
      .catch((err) => {
        console.log(err);
        let errorMessage = ApiUtils.getErrorMessage(
          err,
          'Cannot add comment at the moment'
        );
        toast.error(errorMessage, { autoClose: 2000 });
        this.setState({ submitLoading: false });
      });
  };

  getCommentOwnerMetadata = (owner) => {
    if (owner === 'USER')
      return (
        <p className='cmnt-src'>
          <Tooltip title='User Comment'>
            <img
              src={require('../../assets/images/user-gen.png').default}
              alt='User'
            />
          </Tooltip>
        </p>
      );
    else
      return (
        <p className='cmnt-src'>
          <Tooltip title='System Generated Comment'>
            <img
              src={require('../../assets/images/sytem-gen.png').default}
              alt='System Generated'
            />
          </Tooltip>
        </p>
      );
  };

  render() {
    const { allowAdd } = this.props;
    const {
      loading,
      olderCommentsLoading,
      isAddComment,
      submitLoading,
      comments,
      totalComments,
    } = this.state;

    const layout = {
      wrapperCol: { span: 22 },
    };

    return (
      <CardWithTitle
        className='ca-card-light mb-15 rscb h-20'
        title='Comments'
        badge={totalComments}
        action={
          allowAdd ? (
            !isAddComment ? (
              <Button
                type='primary'
                className='primary-button'
                onClick={() => this.setState({ isAddComment: true })}
                disabled={submitLoading || loading}
              >
                Add Comment
              </Button>
            ) : (
              <></>
            )
          ) : (
            <> </>
          )
        }
      >
        <div className='rscb-editable addtrackcomments'>
          {loading ? (
            <div className='mv-10 text-center'>
              <Spin />
            </div>
          ) : (
            <Row>
              <Col
                className='rscb-details mb-15 mt-15 '
                span={24}
                xs={24}
                lg={24}
                xl={24}
              >
                {isAddComment && (
                  <ul className='comment-sec'>
                    <li className='comment-box'>
                      <div className='comment-user-img'>
                        {getProfilePicture(this.props.user)}
                      </div>
                      <div className='add-comment-vline'></div>

                      <div className='comnt-details'>
                        <p className='cmnt-username'>
                          <strong>{getUsername(this.props.user)}</strong>
                          <span className='cmnt-dot'>&bull;</span>
                          <span className='cmnt-date'>Now</span>
                        </p>
                        {this.getCommentOwnerMetadata('USER')}
                        <p className='user-cmnt'>
                          <Form
                            {...layout}
                            onFinish={this.addComment}
                            preserve={false}
                            ref={(ref) => (this.commentForm = ref)}
                          >
                            <Form.Item
                              name='comment'
                              className='mb-10'
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter a comment',
                                },
                              ]}
                            >
                              <Input.TextArea
                                className='input-field'
                                placeholder='Any additonal remarks can be added here.'
                              />
                            </Form.Item>
                            <Form.Item>
                              <Space>
                                <Button
                                  type='primary'
                                  className='primary-button'
                                  htmlType='submit'
                                  loading={submitLoading}
                                >
                                  Submit Comment
                                </Button>
                                <Button
                                  className='cancel-button'
                                  disabled={submitLoading}
                                  onClick={() =>
                                    this.setState({ isAddComment: false })
                                  }
                                >
                                  Cancel
                                </Button>
                              </Space>
                            </Form.Item>
                          </Form>
                        </p>
                      </div>
                    </li>
                  </ul>
                )}

                {comments.length > 0 ? (
                  <>
                    <ul className='comment-sec'>
                      {sortBy(comments, 'createdAt')
                        .reverse()
                        .map((comment) => (
                          <li className='comment-box' key={comment.id}>
                            <div className='comment-user-img'>
                              {getProfilePicture(comment.commentedBy)}
                            </div>
                            <div className='comment-vline'></div>

                            <div className='comnt-details'>
                              <p className='cmnt-username'>
                                <strong>
                                  {getUsername(comment.commentedBy)}
                                </strong>
                                <span className='cmnt-dot'>&bull;</span>
                                <span className='cmnt-date'>
                                  {getFormattedFullDate(comment.createdAt)}
                                </span>
                              </p>
                              {this.getCommentOwnerMetadata(
                                comment.commentOwner
                              )}
                              <p className='user-cmnt'>
                                {comment.message}
                                {/* TODO Enable only when comment if from current user */}

                                {/* <div className='cmnt-btn-area mt-15'>
                                <Button
                                  onClick={this.handleShow}
                                  className='primary-button mr-15'
                                  type='primary'
                                  icon={<EditFilled />}
                                >
                                  Edit
                                </Button>
                                <Button
                                  className='cancel-button'
                                  type='primary'
                                  icon={<DeleteFilled />}
                                >
                                  Delete
                                </Button>
                              </div> */}
                              </p>
                            </div>
                          </li>
                        ))}
                    </ul>
                    {comments.length !== totalComments && (
                      <div className='text-center divider-wrapper mt-20'>
                        <Button
                          onClick={this.fetchOlderComments}
                          loading={olderCommentsLoading}
                        >
                          Show Older Comments
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <EmptySection
                    imageSrc={
                      require('../../assets/images/no-data-images/nd-old-versions.png')
                        .default
                    }
                    title='No Comments'
                    showButton={false}
                    size='small'
                  />
                )}
              </Col>
            </Row>
          )}
        </div>
      </CardWithTitle>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  Comments
);
