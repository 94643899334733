import 'stream-chat-react/dist/css/index.css';
import './ChatScreen.css';

import { Button, Col, Row } from 'antd';
import {
  Channel,
  ChannelList,
  Chat,
  InfiniteScrollPaginator,
  MessageInput,
  MessageInputFlat,
  MessageList,
  Thread,
  Window,
  withChannelStateContext,
} from 'stream-chat-react';
import { isEmpty, isUndefined } from 'lodash';

import ApiUtils from 'src/utils/ApiUtils';
import { ChannelPreviewMessenger } from 'src/components/chat/ChannelPreviewMessenger';
import { ChannelSearch } from 'src/components/chat/ChannelSearch';
import ChatInfoComponent from 'src/components/chat/ChatInfoComponent';
import ChatService from 'src/services/ChatService';
import HeaderComponent from 'src/components/chat/HeaderComponent';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import SecureComponent from 'src/components/common/SecureComponent';
import { StreamChat } from 'stream-chat';
import UrlGenerator from 'src/api/UrlGenerator';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setUnreadCount } from 'src/actions/chat';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

const ChannelWatcher = withChannelStateContext(
  class ChannelWatcher extends React.PureComponent {
    componentDidMount() {
      let enableChannelSearch = isEmpty(this.props.currentPathParams);
      this.props.updateChatUrl(this.props.channel.id, enableChannelSearch);
    }

    render() {
      return null;
    }
  }
);

class ChatScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = { infoDrawerVisible: false, enableChannelSearch: false };
    this.filters = {
      type: 'messaging',
      members: { $in: [props.user.id] },
    };
    if (!isEmpty(props.match.params)) this.filters.id = props.match.params.id;

    this.apiKey = process.env.REACT_APP_STREAM_KEY;
    this.sort = { last_message_at: -1 };
    this.theme = 'messaging light';
    this.Paginator = (props) => (
      <InfiniteScrollPaginator threshold={300} {...props} />
    );

    this.chatClient = StreamChat.getInstance(this.apiKey);
  }

  componentDidMount() {
    this.chatClient
      .connectUser(
        { id: this.props.user.id, name: this.props.user.name },
        async () => {
          try {
            const response = await ChatService.getChatToken();
            return response.data.token;
          } catch (error) {
            let errorMessage = ApiUtils.getErrorMessage(error);
            toast.error(errorMessage);
          }
        }
      )
      .then((response) => {
        this.props.setUnreadCount(response.me.unread_count);
      })
      .catch((error) => {
        this.props.setUnreadCount(0);
      });

    this.chatClient.on((event) => {
      if (event.total_unread_count !== undefined) {
        this.props.setUnreadCount(event.total_unread_count);
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.enableChannelSearch === false &&
      this.props.history.location.pathname === RouteConstants.CHAT
    ) {
      this.setState({ enableChannelSearch: true });
      return;
    }
    if (
      prevState.enableChannelSearch !== this.state.enableChannelSearch &&
      this.props.history.location.pathname !== RouteConstants.CHAT
    ) {
      if (this.props.history.location.fromChannelClick)
        this.setState({ enableChannelSearch: true });
      else this.setState({ enableChannelSearch: false });
    }
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (location.pathname === RouteConstants.CHAT)
        this.filters = {
          type: 'messaging',
          members: { $in: [this.props.user.id] },
        };
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  getChatUrl = (channelId) => {
    if (isUndefined(channelId)) return RouteConstants.CHAT;
    else
      return UrlGenerator.getUiUrlWithPathParams(RouteConstants.CHAT_DETAIL, {
        id: channelId,
      });
  };

  render() {
    const { infoDrawerVisible, enableChannelSearch } = this.state;
    return (
      <>
        <HeaderMetadata
          title={
            isUndefined(this.props.match.params.id)
              ? 'RFQ Chats'
              : `${this.props.match.params.id} Chat`
          }
          description={'View all RFQ Chats'}
        />
        <Chat client={this.chatClient} theme={this.theme}>
          <Row align='center' justify='space-around'>
            <Col xs={24}>
              {!enableChannelSearch && (
                <Button
                  type='link'
                  onClick={() => {
                    this.setState({ enableChannelSearch: true }, () =>
                      this.props.history.push(this.getChatUrl())
                    );
                  }}
                >
                  View all chats
                </Button>
              )}
            </Col>
            <Col xs={infoDrawerVisible ? 24 : 24}>
              <ChannelList
                filters={this.filters}
                sort={this.sort}
                Paginator={this.Paginator}
                showChannelSearch={enableChannelSearch}
                ChannelSearch={() => <ChannelSearch user={this.props.user} />}
                Preview={(props) => (
                  <ChannelPreviewMessenger {...props}></ChannelPreviewMessenger>
                )}
              />
              <Channel>
                <Window>
                  <HeaderComponent
                    showInfoIcon={true}
                    showInfoDrawer={() =>
                      this.setState({ infoDrawerVisible: true })
                    }
                  />
                  <MessageList />
                  <MessageInput Input={MessageInputFlat} />
                </Window>
                <Thread />
                {infoDrawerVisible && (
                  <ChatInfoComponent
                    closeInfoDrawer={() =>
                      this.setState({ infoDrawerVisible: false })
                    }
                  />
                )}
                <ChannelWatcher
                  currentPathParams={this.props.match.params}
                  updateChatUrl={(channelId, enableChannelSearch) => {
                    this.setState({ enableChannelSearch }, () => {
                      this.props.history.replace({
                        pathname: this.getChatUrl(channelId),
                        fromChannelClick: true,
                      });
                    });
                  }}
                />
              </Channel>
            </Col>
          </Row>
        </Chat>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    unreadCount: state.unreadCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUnreadCount: (unreadCount) => dispatch(setUnreadCount(unreadCount)),
  };
};

export default compose(
  SecureComponent,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ChatScreen);
