import { findIndex, orderBy } from 'lodash';
import { getDaysRemaining, getDuration } from './DateUtils';

import ContainerTrackingConstants from 'src/constants/ContainerTrackingConstants';
import MapConstants from 'src/constants/MapConstants';
import { getFormattedDate } from './DisplayUtils';
import moment from 'moment';

export const getContainerEventsAndRoute = (data) => {
  const L = require('leaflet');

  const { containerNumber, orderNo } = data;
  let seaPath = {};
  let landPath = {};
  let points = [];
  let events = [];
  let meanLat = 0;
  let meanLng = 0;
  /* Get the major points in the route and the path as array of points between two such major points */
  const points_ = data.containerDetails ? data.containerDetails.points : [];

  let lastEnteredZone = '';

  points_.forEach((point, pointIndex) => {
    let eventsForPoint = [];

    let pointEvents = point.events ? point.events : [];

    pointEvents.forEach((event) => {
      let eventObj = {};
      eventObj.pointName = point[ContainerTrackingConstants.Point.Name];
      eventObj.dateVal = new Date(event.date);
      eventObj.date = new Date(event.date).toString();
      eventObj.description =
        event[ContainerTrackingConstants.Event.Description];
      eventObj.mode = event.type;
      eventObj.vessel = event.vessel;
      eventObj.voyage = event.voyage;
      eventsForPoint.push(eventObj);
    });

    eventsForPoint = orderBy(eventsForPoint, function (o) {
      return o.dateVal;
    });

    if (eventsForPoint.length !== 0) events.push(eventsForPoint);

    let pathTos = point.pathTo ? point.pathTo : [];

    let enteredZone = '';
    let pathType = '';

    let invalidateExistingPath = false;

    pathTos.forEach((pathTo, index) => {
      let pathPoints = null;

      if (pathType === '')
        pathType =
          pointIndex +
          '_' +
          point[ContainerTrackingConstants.Point.Name] +
          '_' +
          index;

      if (
        index !== 0 &&
        parseFloat(pathTo.longitude) < 0 && // crossing from east to west hemisphere
        parseFloat(pathTos[index - 1].longitude) > 0
      ) {
        if (
          Math.abs(
            parseFloat(pathTo.longitude) -
              parseFloat(pathTos[index - 1].longitude) // cross direction : from right
          ) < 180
        ) {
          // console.log("ENTERING WEST FROM RIGHT")
          pathPoints = new L.LatLng(
            pathTo.latitude,
            parseFloat(pathTo.longitude)
          );
          enteredZone = 'no-wrap';
        } else {
          // cross direction : from left
          // console.log("ENTERING WEST FROM LEFT")
          pathPoints = new L.LatLng(
            pathTo.latitude,
            L.Util.wrapNum(parseFloat(pathTo.longitude), [0, 360], true)
          );
          enteredZone = 'wrap';
        }
      } else if (
        index !== 0 &&
        parseFloat(pathTo.longitude) > 0 && // crossing from west to east hemisphere
        parseFloat(pathTos[index - 1].longitude) < 0
      ) {
        if (
          Math.abs(
            parseFloat(pathTo.longitude) -
              parseFloat(pathTos[index - 1].longitude) // cross direction : from left
          ) < 180
        ) {
          // console.log("ENTERING EAST FROM LEFT")
          pathPoints = new L.LatLng(
            pathTo.latitude,
            parseFloat(pathTo.longitude)
          );
          enteredZone = 'no-wrap';
        } else {
          // cross direction : from right
          // console.log("ENTERING EAST FROM RIGHT")
          // Additional step: wrap all points from beginning of this path
          pathPoints = [];
          for (let pathIndex = 0; pathIndex < index; pathIndex++) {
            pathPoints.push(
              new L.LatLng(
                pathTos[pathIndex].latitude,
                L.Util.wrapNum(
                  parseFloat(pathTos[pathIndex].longitude),
                  [0, 360],
                  true
                )
              )
            );
          }
          invalidateExistingPath = true;

          pathPoints.push(
            new L.LatLng(
              pathTo.latitude,
              L.Util.wrapNum(parseFloat(pathTo.longitude), [0, 360], true)
            )
          );
          enteredZone = 'wrap';
        }
      } else {
        pathPoints = new L.LatLng(
          pathTo.latitude,
          enteredZone === 'wrap' ||
          (enteredZone === '' && lastEnteredZone === 'wrap')
            ? L.Util.wrapNum(parseFloat(pathTo.longitude), [0, 360], true)
            : parseFloat(pathTo.longitude)
        );
      }

      let typeOfPath = eventsForPoint ? eventsForPoint[0].mode : '3';

      if (typeOfPath === '3') {
        if (!seaPath[pathType] || invalidateExistingPath)
          seaPath[pathType] = [pathPoints];
        else seaPath[pathType].push(pathPoints);
      } else if (typeOfPath === '4') {
        if (!landPath[pathType] || invalidateExistingPath)
          landPath[pathType] = [pathPoints];
        else landPath[pathType].push(pathPoints);
      }

      if (enteredZone !== '') lastEnteredZone = enteredZone;
    });

    let pointObj = {};
    pointObj.lat = point.latitude;
    pointObj.lng =
      lastEnteredZone === 'wrap'
        ? L.Util.wrapNum(parseFloat(point.longitude), [0, 360], true)
        : parseFloat(point.longitude);

    pointObj.name = point[ContainerTrackingConstants.Point.Name];
    points.push(pointObj);

    meanLat = meanLat + point.latitude;
    meanLng = meanLng + point.longitude;
  });

  meanLat = points_.length > 0 ? meanLat / points_.length : 0;
  meanLng = points_.length > 0 ? meanLng / points_.length : 0;

  const { lastKnownLat, lastKnownLong } = data.containerDetails
    ? data.containerDetails.containerTracker
      ? data.containerDetails.containerTracker.length > 0
        ? data.containerDetails.containerTracker[0]
        : { lastKnownLat: 0, lastKnownLong: 0 }
      : { lastKnownLat: 0, lastKnownLong: 0 }
    : { lastKnownLat: 0, lastKnownLong: 0 };

  const lastContainerCoord = {
    lat: lastKnownLat,
    lng:
      lastEnteredZone === 'wrap'
        ? L.Util.wrapNum(lastKnownLong, [0, 360], true)
        : lastKnownLong,
  };

  const latestETA = data.jscEta !== null ? data.jscEta : data.latestEta;

  const initialETA = getFormattedDate(data.initialArrivalDate);

  const containerArrived =
    data[ContainerTrackingConstants.TrackingStatus] ===
    ContainerTrackingConstants.TrackingStatusTypes.Arrived;

  const source = data[ContainerTrackingConstants.Source]; //points_[0].name;
  const destination = data[ContainerTrackingConstants.Destination]; //points_[points_.length - 1].name;
  const startDate = points
    ? points.length > 0
      ? points_[0].events.length > 0
        ? getFormattedDate(points_[0].events[0].date)
        : ''
      : ''
    : '';

  let containerDelayDuration = null;
  let daysRemaining = -1;
  let totalExpectedDuration = getDuration(latestETA, startDate);
  let progress = 0;
  let arrivalStatus = data[ContainerTrackingConstants.ArrivalStatusField];

  if (containerArrived) {
    containerDelayDuration = getDuration(
      data[ContainerTrackingConstants.ArrivedDate],
      data.initialArrivalDate
    );
    daysRemaining = 0;
    progress = 100;
  } else {
    containerDelayDuration = getDuration(latestETA, data.initialArrivalDate);

    daysRemaining = getDaysRemaining(latestETA);

    progress =
      daysRemaining !== null && totalExpectedDuration !== null
        ? daysRemaining > 0
          ? ((totalExpectedDuration - daysRemaining) / totalExpectedDuration) *
            100
          : 100
        : 0;
  }
  const trackingStatus = data[ContainerTrackingConstants.TrackingStatus];

  const bol = data[ContainerTrackingConstants.BOLNumber];

  const vendor = data && data.sellerName;

  const contents = data && data.itemDescription;

  const locationCode = data && data.warehouse && data.warehouse.locationCode;

  const sealine = data.containerDetails
    ? data.containerDetails.sealine
      ? data.containerDetails.sealine.name
      : ''
    : '';

  const landColor = MapConstants.pathColors.landColor,
    seaColor = MapConstants.pathColors.seaColor;

  const { departureDate, initialArrivalDate, arrivedDate } = data;

  const { hashed_value } = data;

  return {
    pathData: {
      path: {
        seaPath: {
          path: seaPath,
          color: seaColor,
        },
        landPath: {
          path: landPath,
          color: landColor,
        },
      },
      points,
      mapCenter: lastContainerCoord,
      lastContainerCoord,
      mapZone: lastEnteredZone,
      trackingStatus,
      containerNo: containerNumber,
      orderNo,
    },
    containerData: {
      containerNo: containerNumber,
      orderNo,
      source,
      destination,
      startDate,
      contents,
      bol,
      vendor,
      sealine,
      initialETA,
      latestETA: getFormattedDate(latestETA),
      searatesETA: data.latestEta,
      jscETA: data.jscEta,
      containerArrived,
      containerDelayDuration,
      arrivalStatus,
      daysRemaining,
      progress,
      trackingStatus,
      departureDate,
      initialArrivalDate,
      arrivedDate,
      hash_value: hashed_value,
      locationCode,
    },
    events,
    jscEvents: data.jscEvents,
  };
};

const isEventCompleted = (event, date) => {
  let subEventDate = event ? event[event.length - 1].dateVal : '';
  return date.getTime() >= new Date(subEventDate).getTime();
};

export const findUpcomingEvent = (events, date) => {
  if (events.length === 0) return { upcomingEvent: null, upcomingStep: null };
  const upcomingEvent = events.filter((event, index) => {
    if (index === 0 || index % 2 !== 0) {
      return false;
    }
    const lastSubEventForEvent =
      events[index - 2][events[index - 2].length - 1];
    return (
      (moment(date)
        .startOf('day')
        .isSameOrAfter(moment(lastSubEventForEvent.date).startOf('day')) &&
        moment(date)
          .startOf('day')
          .isSameOrBefore(
            moment(event[event.length - 1].date).startOf('day')
          )) ||
      (index === events.length - 2
        ? moment(date)
            .startOf('day')
            .isSameOrAfter(moment(event[event.length - 1].date))
        : false)
    );
  })[0];

  let upcomingStep = 1;

  if (upcomingEvent) {
    upcomingStep = findIndex(events, function (o) {
      if (o === 'Transit Event') return false;
      return o[0].dateVal === upcomingEvent[0].dateVal;
    });

    if (isEventCompleted(upcomingEvent, date)) upcomingStep = upcomingStep + 1;
  }

  return { upcomingEvent, upcomingStep };
};
