import {
  getFactoryContactInformationFields,
  getSubcontractorFactoryContactInformationFields,
} from 'src/utils/CompanyProfileUtils';
import { groupBy, isEmpty, orderBy } from 'lodash';

import React from 'react';
import StringConstants from 'src/constants/StringConstants';
import UploadFilesComponent from '../common/UploadFilesComponent';

class ProcessingPlantAdditionalInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questionnaireData: [],
      plantContactsData: [],
      subContractorContactsData: [],
      plantId: props.plantId,
    };
  }

  componentDidMount() {
    const questionnaireData =
      this.props.data.company.plantAdditionalInformations;
    const plantContactsData = this.props.data.company.plantContacts;
    const subContractorContactsData = this.props.data.company.subContractors;
    let groupedData = groupBy(questionnaireData, 'question.groupName');
    let sortedData = {};
    Object.keys(groupedData).forEach((group) => {
      let sortedDataInGroup = orderBy(
        groupedData[group],
        'question.displayRank'
      );
      sortedData[group] = sortedDataInGroup;
    });
    let finalPlantContactsData = {};
    plantContactsData.forEach((info) => {
      Object.keys(info).forEach((infoKey) => {
        if (infoKey !== 'type')
          finalPlantContactsData[info.type + '-' + infoKey] = info[infoKey];
      });
    });
    let finalSubContractorContactsData = {};
    subContractorContactsData.forEach((info) => {
      Object.keys(info).forEach((infoKey) => {
        if (infoKey !== 'type')
          finalSubContractorContactsData[info.type + '-' + infoKey] =
            info[infoKey];
      });
    });
    this.setState({
      questionnaireData: sortedData,
      plantContactsData: finalPlantContactsData,
      subContractorContactsData: finalSubContractorContactsData,
    });
  }

  render() {
    const {
      questionnaireData,
      plantContactsData,
      subContractorContactsData,
      plantId,
    } = this.state;
    return (
      <div className='rscb-details'>
        <p className='title-heading'>{'Plant Additional Information'}</p>
        {isEmpty(questionnaireData) ? (
          <span className='empty-plant-info-text'>
            Plant yet to enter these details !
          </span>
        ) : (
          <ul className='rscb-items'>
            {StringConstants.QUESTIONNAIRE_GROUPS.map((questionGroup) => {
              return (
                <div key={questionGroup.name}>
                  <span
                    className='title-small-heading'
                    style={{ marginBottom: 15 }}
                  >
                    {questionGroup.title}
                  </span>
                  <ul className='rscb-items'>
                    {!isEmpty(questionnaireData) &&
                      questionnaireData[questionGroup.name].map(
                        (questionObj) => {
                          return (
                            <li
                              key={
                                questionObj.question.groupName +
                                '_' +
                                questionObj.question.id
                              }
                            >
                              <span className='left-item'>
                                {questionObj.question.questionText}
                              </span>
                              <span className='right-item'>
                                {questionObj.value}
                              </span>
                            </li>
                          );
                        }
                      )}
                  </ul>
                </div>
              );
            })}
          </ul>
        )}
        <p className='title-heading'>{'Factory Contact Information'}</p>

        {isEmpty(plantContactsData) ? (
          <span className='empty-plant-info-text'>
            Plant yet to enter these details !
          </span>
        ) : (
          <ul className='rscb-items'>
            {getFactoryContactInformationFields().contactTypes.map(
              (contactType) => {
                return (
                  <div key={contactType.name}>
                    <span
                      className='title-small-heading'
                      style={{ marginBottom: 15 }}
                    >
                      {contactType.title}
                    </span>

                    <ul className='rscb-items'>
                      {contactType.fields.map((field) => {
                        return (
                          <li key={field.title}>
                            <span className='left-item'>{field.title}</span>
                            <span className='right-item'>
                              {
                                plantContactsData[
                                  contactType.name + '-' + field.name
                                ]
                              }
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              }
            )}
          </ul>
        )}
        <p className='title-heading'>
          {'Subcontractor Factory Contact Information'}
        </p>
        {isEmpty(subContractorContactsData) ? (
          <span className='empty-plant-info-text'>
            Plant yet to enter these details !
          </span>
        ) : (
          <ul className='rscb-items'>
            {getSubcontractorFactoryContactInformationFields().contactTypes.map(
              (contactType) => {
                return (
                  <div key={contactType.name}>
                    <span
                      className='title-small-heading'
                      style={{ marginBottom: 15 }}
                    >
                      {contactType.title}
                    </span>

                    <ul className='rscb-items'>
                      {contactType.fields.map((field) => {
                        return (
                          <li key={field.title}>
                            <span className='left-item'>{field.title}</span>
                            <span className='right-item'>
                              {
                                subContractorContactsData[
                                  contactType.name + '-' + field.name
                                ]
                              }
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              }
            )}
          </ul>
        )}
        <p className='title-heading'>{'Uploaded Files'}</p>
        <ul className='rscb-items'>
          <UploadFilesComponent
            ref={(ref) => (this.uploadFilesRef = ref)}
            entity='COMPANY'
            entityId={plantId}
            readOnly={true}
            forImporter={true}
          />
        </ul>
      </div>
    );
  }
}

export default ProcessingPlantAdditionalInformation;
