import './AcceptInvite.css';

import { Col, Layout, Row, Steps } from 'antd';

import AcceptInviteCompanyInfo from './company/AcceptInviteCompany';
import AcceptInviteUser from './user/AcceptInviteUser';
import ApiUtils from 'src/utils/ApiUtils';
import AuthSideCarousel from '../common/AuthSideCarousel';
import { ContainerFilled } from '@ant-design/icons';
import InviteService from 'src/services/InviteService';
import LoadingComponent from 'src/components/common/LoadingComponent';
import NoAccessComponent from 'src/components/common/NoAccessComponent';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import StringConstants from 'src/constants/StringConstants';
import UnsecureComponent from 'src/components/common/UnsecureComponent';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';

const { Step } = Steps;

const steps = [
  {
    title: 'User Details',
    icon: (
      <img
        width='17px'
        src={require('../../../assets/images/usericon.png').default}
        alt='User'
      />
    ),
  },
  {
    title: 'Company Details',
    icon: <ContainerFilled />,
  },
];

class AcceptInvite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      submitLoading: false,
      inviteToken: null,
      inviteDetails: null,
      userDetails: {},
      current: 0,
      inviteAccepted: false,
    };
  }

  componentDidMount() {
    const inviteToken = this.props.match.params.token;
    this.setState({ loading: true, inviteToken });
    InviteService.getInviteDetails(inviteToken)
      .then((response) => {
        let inviteDetails = response.data;
        if (
          (inviteDetails.existingUser || !!inviteDetails.ssoProvider) &&
          inviteDetails.status === 'INVITED'
        ) {
          if (
            inviteDetails.invitationType ===
            StringConstants.INVITATION_TYPES.CARAVA_USER
          )
            this.submitCaravaUserDetail(!!inviteDetails.ssoProvider);
          else if (
            inviteDetails.invitationType ===
            StringConstants.INVITATION_TYPES.SUB_PROCESSING_PLANT
          )
            this.submitSubPlantUserDetail(!!inviteDetails.ssoProvider);
          else this.setState({ inviteDetails, loading: false });
        } else if (inviteDetails.status !== 'INVITED') {
          inviteDetails = {};
          this.setState({
            inviteDetails,
            inviteAccepted: true,
            loading: false,
          });
        } else {
          this.setState({ inviteDetails, loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  }

  goBack = () => {
    this.setState({ current: 0 });
  };

  submitCaravaUserDetail = (isSsoBasedAccount) => {
    const { inviteToken } = this.state;
    InviteService.submitUserInvite(inviteToken, { date: Date.now() })
      .then(() => {
        toast.success('Invite Accepted Successfully !');
        this.setState({ loading: false });
        if (isSsoBasedAccount)
          this.props.history.replace(RouteConstants.AUTH0_LOGIN);
        else this.props.history.replace(RouteConstants.LOGIN);
      })
      .catch((err) => {
        let errorMessage = ApiUtils.getErrorMessage(
          err,
          'Failed to accept invite, please contact support'
        );
        toast.error(errorMessage);
        this.setState({ inviteDetails: {}, loading: false });
      });
  };

  submitSubPlantUserDetail = (isSsoBasedAccount) => {
    const { inviteToken } = this.state;
    InviteService.submitSubPlantInvite(inviteToken, { date: Date.now() })
      .then(() => {
        toast.success('Invite Accepted Successfully !');
        this.setState({ loading: false });
        if (isSsoBasedAccount)
          this.props.history.replace(RouteConstants.AUTH0_LOGIN);
        else this.props.history.replace(RouteConstants.LOGIN);
      })
      .catch((err) => {
        let errorMessage = ApiUtils.getErrorMessage(
          err,
          'Failed to accept invite, please contact support'
        );
        toast.error(errorMessage);
        this.setState({ inviteDetails: {}, loading: false });
      });
  };

  submitUserDetail = (user) => {
    const { inviteDetails, inviteToken } = this.state;
    if (
      inviteDetails.invitationType ===
      StringConstants.INVITATION_TYPES.PROCESSING_PLANT
    ) {
      this.setState({ userDetails: user, current: 1 });
    } else {
      this.setState({ submitLoading: true });
      InviteService.submitUserInvite(inviteToken, user)
        .then(() => {
          toast.success('Invite Accepted Successfully !');
          this.setState({ submitLoading: false });
          this.props.history.replace(RouteConstants.LOGIN);
        })
        .catch((err) => {
          let errorMessage = ApiUtils.getErrorMessage(
            err,
            'Failed to accept invite, please contact support'
          );
          toast.error(errorMessage);
          this.setState({ submitLoading: false });
        });
    }
  };

  submitCompanyDetail = (company) => {
    const { inviteToken, userDetails } = this.state;
    this.setState({ submitLoading: true });
    const data = { user: userDetails, company };
    InviteService.submitPlantInvite(inviteToken, data)
      .then(() => {
        toast.success('Invite Accepted Successfully !');
        this.setState({ submitLoading: false });
        this.props.history.replace(RouteConstants.LOGIN);
      })
      .catch((err) => {
        let errorMessage = ApiUtils.getErrorMessage(
          err,
          'Failed to accept invite, please contact support'
        );
        toast.error(errorMessage);
        this.setState({ submitLoading: false });
      });
  };

  render() {
    const {
      current,
      inviteDetails,
      loading,
      submitLoading,
      userDetails,
      inviteAccepted,
    } = this.state;
    return (
      <>
        <Layout style={{ height: '100vh' }}>
          <Row>
            <Col
              style={{ height: '100vh' }}
              className='login-left carva-step-form acceptinvitesection'
              span={12}
              xs={24}
              lg={12}
              xl={12}
            >
              <div className='acceptinvite-container'>
                <img
                  style={{ marginBottom: '30px' }}
                  width='235px'
                  src={require('../../../assets/images/logo.png').default}
                  alt='Carava Logo'
                />
                <h2 className='all-form-heading mb-40'>Welcome !</h2>
                {loading ? (
                  <LoadingComponent />
                ) : inviteAccepted ? (
                  <NoAccessComponent
                    title='Invite already accepted !'
                    tip='This invite has already been accepted'
                    showButton={false}
                  />
                ) : isEmpty(inviteDetails) ? (
                  <NoAccessComponent
                    title='Invalid Invite'
                    tip='Looks like your invite was either revoked or expired. Contact support for help'
                    showButton={false}
                  />
                ) : inviteDetails.invitationType ===
                  StringConstants.INVITATION_TYPES.PROCESSING_PLANT ? (
                  <>
                    <Steps current={current}>
                      {steps.map((item) => (
                        <Step
                          key={item.title}
                          title={item.title}
                          icon={item.icon}
                        />
                      ))}
                    </Steps>
                    <div className='steps-content'>
                      {current === 0 ? (
                        <AcceptInviteUser
                          email={inviteDetails.email}
                          loading={submitLoading}
                          submit={this.submitUserDetail}
                          values={userDetails}
                        />
                      ) : (
                        <AcceptInviteCompanyInfo
                          loading={submitLoading}
                          goBack={this.goBack}
                          submit={this.submitCompanyDetail}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <div className='steps-content'>
                    <AcceptInviteUser
                      email={inviteDetails.email}
                      loading={submitLoading}
                      submit={this.submitUserDetail}
                      values={userDetails}
                      buttonText='Submit'
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col
              style={{ height: '100vh' }}
              className='login-right'
              span={12}
              xs={24}
              sm={24}
              lg={12}
              xl={12}
            >
              <AuthSideCarousel />
            </Col>
          </Row>
        </Layout>
      </>
    );
  }
}
export default UnsecureComponent(withRouter(AcceptInvite));
