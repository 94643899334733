import './PlannedOrderDataTable.css';

import { Button, DatePicker, InputNumber, Select, Table } from 'antd';
import { CalendarFilled, CaretDownOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { find, findIndex, isEmpty, isUndefined, sumBy } from 'lodash';

import AuctionService from 'src/services/AuctionService';
import ProformaService from 'src/services/ProformaService';
import RevisedDataView from '../revisedDataView/RevisedDataView';
import TableFilterUtils from 'src/utils/TableFilterUtils';
import Title from 'src/components/common/typography/Title';
import { connect } from 'react-redux';
import { isUserImporter } from 'src/helpers/user';
import moment from 'moment';
import { setUnsavedChanges } from 'src/actions/route';
import { toast } from 'react-toastify';

const { Option } = Select;

class PlannedOrderDataTable extends Component {
  state = {
    isEditData: false,
  };

  handleShow = () => {
    this.setState({
      isEditData: true,
    });
  };

  handleHide = () => {
    this.setState(
      {
        isEditData: false,
      },
      () => {
        this.props.setUnsavedChanges(!isEmpty(this.getUpdatedData()));
        this.getCalculatedData();
      }
    );
  };

  resetEditedFields = () => {
    const plannedOrders = this.getPlannedOrders();
    plannedOrders.forEach((entityPlannedOrder) => {
      this.setState({
        ['plannedCases_' + entityPlannedOrder.plannedOrderId]: undefined,
      });
      this.setState({
        ['weightUom_' + entityPlannedOrder.plannedOrderId]: undefined,
      });
      this.setState({
        ['requestedEta_' + entityPlannedOrder.plannedOrderId]: undefined,
      });
      this.setState({
        ['destinationPort_' + entityPlannedOrder.plannedOrderId]: undefined,
      });
    });
  };

  getPlannedOrders = () => {
    const { entity } = this.props;
    if (entity === 'Auction') {
      const { auctionPlannedOrders } = this.props.data;

      return auctionPlannedOrders ? auctionPlannedOrders : [];
    } else {
      const { proformaPlannedOrders } = this.props.data;

      return proformaPlannedOrders ? proformaPlannedOrders : [];
    }
  };

  getPrevDataPlannedOrders = () => {
    const { entity } = this.props;
    if (!this.props.prevData) return [];
    if (entity === 'Auction') {
      const { auctionPlannedOrders } = this.props.prevData;

      return auctionPlannedOrders ? auctionPlannedOrders : [];
    } else {
      const { proformaPlannedOrders } = this.props.prevData;

      return proformaPlannedOrders ? proformaPlannedOrders : [];
    }
  };

  getRows = () => {
    const { isEditData } = this.state;

    const { data, toEdit } = this.props;
    const { warehouses } = data;
    return [
      {
        title: 'Company Planned Order',
        property: 'companyPlannedOrderNumber',
      },
      {
        title: 'Requested ETD',
        property: 'requestedEtd',
        customRender: (plannedOrder, prevPlannedOrder) => {
          const { requestedEtd, plannedOrderId } = plannedOrder;
          return isEditData || toEdit ? (
            <DatePicker
              allowClear={false}
              suffixIcon={<CalendarFilled />}
              className='table-input date-pick'
              value={
                isUndefined(this.state['requestedEtd_' + plannedOrderId])
                  ? requestedEtd
                    ? moment(requestedEtd)
                    : null
                  : moment(this.state['requestedEtd_' + plannedOrderId])
              }
              onChange={(value) => {
                this.setState({
                  ['requestedEtd_' + plannedOrderId]: moment(value),
                });
              }}
            />
          ) : (
            <RevisedDataView
              data={prevPlannedOrder ? prevPlannedOrder : plannedOrder}
              updatedValue={
                prevPlannedOrder
                  ? requestedEtd
                  : this.state['requestedEtd_' + plannedOrderId]
              }
              attribute='requestedEtd'
              type='Date'
            />
          );
        },
      },
      {
        title: 'Requested ETA',
        property: 'requestedEta',
        customRender: (plannedOrder, prevPlannedOrder) => {
          const { plannedOrderId, requestedEta } = plannedOrder;
          return (
            <RevisedDataView
              data={prevPlannedOrder ? prevPlannedOrder : plannedOrder}
              updatedValue={
                prevPlannedOrder
                  ? requestedEta
                  : this.state['requestedEta_' + plannedOrderId]
              }
              attribute='requestedEta'
              type='Date'
            />
          );
        },
      },
      {
        title: 'Location Code',
        property: 'warehouse.locationCode',
        customRender: (plannedOrder, prevPlannedOrder) => {
          const { warehouse, plannedOrderId } = plannedOrder;
          const { locationCode } = warehouse;

          return isUserImporter() && (isEditData || toEdit) ? (
            <Select
              suffixIcon={<CaretDownOutlined />}
              className='planned-vi-sel-btn'
              defaultValue={locationCode}
              value={
                isUndefined(this.state['locationCode_' + plannedOrderId])
                  ? locationCode
                  : this.state['locationCode_' + plannedOrderId]
              }
              onChange={(value) =>
                this.setState({ ['locationCode_' + plannedOrderId]: value })
              }
            >
              {warehouses.map((warehouse) => {
                return (
                  <Option
                    key={warehouse.locationCode}
                    value={warehouse.locationCode}
                  >
                    {warehouse.locationCode}
                  </Option>
                );
              })}
            </Select>
          ) : (
            <RevisedDataView
              data={prevPlannedOrder ? prevPlannedOrder : plannedOrder}
              updatedValue={
                prevPlannedOrder
                  ? locationCode
                  : this.state['locationCode_' + plannedOrderId]
              }
              attribute='warehouse.locationCode'
            />
          );
        },
      },
      {
        title: 'Destination Port',
        property: 'destinationPort',
        customRender: (plannedOrder, prevPlannedOrder) => {
          const { destinationPort, plannedOrderId } = plannedOrder;
          return (
            <RevisedDataView
              data={prevPlannedOrder ? prevPlannedOrder : plannedOrder}
              updatedValue={
                prevPlannedOrder
                  ? destinationPort
                  : this.state['destinationPort_' + plannedOrderId]
              }
              attribute='destinationPort'
              type='Port'
            />
          );
        },
      },
      {
        title: 'Estimated Weight',
        property: 'totalWeight',
        customRender: (plannedOrder, prevPlannedOrder) => {
          const { totalWeight, plannedOrderId } = plannedOrder;
          return isEditData || toEdit ? (
            <InputNumber
              className='table-input'
              placeholder={totalWeight}
              value={
                isUndefined(this.state['totalWeight_' + plannedOrderId])
                  ? totalWeight
                  : this.state['totalWeight_' + plannedOrderId]
              }
              min={1}
              step={1}
              onChange={(value) => {
                this.setState({
                  ['totalWeight_' + plannedOrderId]: value,
                });
              }}
            />
          ) : (
            <RevisedDataView
              data={prevPlannedOrder ? prevPlannedOrder : plannedOrder}
              updatedValue={
                prevPlannedOrder
                  ? totalWeight
                  : this.state['totalWeight_' + plannedOrderId]
              }
              attribute='totalWeight'
              type='Weight'
            />
          );
        },
      },
      {
        title: 'Planned Cases',
        property: 'plannedCases',
        customRender: (plannedOrder, prevPlannedOrder) => {
          const { plannedCases, plannedOrderId } = plannedOrder;
          return (
            <RevisedDataView
              data={prevPlannedOrder ? prevPlannedOrder : plannedOrder}
              updatedValue={
                prevPlannedOrder
                  ? plannedCases
                  : this.state['plannedCases_' + plannedOrderId]
              }
              attribute='plannedCases'
              type='Number'
            />
          );
        },
      },
    ];
  };

  getColumns = () => {
    const plannedOrders = this.getPlannedOrders();

    let columns = [
      {
        title: '',
        dataIndex: 'title',
        width: 200,
      },
    ];

    plannedOrders.forEach((plannedOrder) => {
      columns.push({
        title: plannedOrder.plannedOrderId,
        dataIndex: plannedOrder.plannedOrderId,
        ellipsis: { showTitle: true },
        width: 150,
      });
    });

    return columns;
  };

  getTableData = () => {
    const plannedOrders = this.getPlannedOrders();
    const prevDataPlannedOrders = this.getPrevDataPlannedOrders();

    let tableData = [];
    this.getRows().forEach((row) => {
      let tableDataRow = {
        title: row.title,
      };

      plannedOrders.forEach((plannedOrder) => {
        if (row.customRender)
          tableDataRow[plannedOrder.plannedOrderId] = row.customRender(
            plannedOrder,
            find(prevDataPlannedOrders, function (o) {
              return o.id === plannedOrder.id;
            })
          );
        else
          tableDataRow[plannedOrder.plannedOrderId] =
            TableFilterUtils.getProperty(plannedOrder, row.property);
      });

      tableData.push(tableDataRow);
    });

    return tableData;
  };

  getUpdatedData = () => {
    const { data, entity } = this.props;
    const { warehouses } = data;
    const plannedOrders = this.getPlannedOrders();

    const updatedData = [];
    let actualUpdateCount = 0;

    plannedOrders.forEach((entityPlannedOrder, index) => {
      let newLocationCode =
        this.state['locationCode_' + entityPlannedOrder.plannedOrderId];
      let updatedPlannedOrderObj = {};
      updatedPlannedOrderObj.id = entityPlannedOrder.id;

      updatedPlannedOrderObj.totalWeight = !isUndefined(
        this.state['totalWeight_' + entityPlannedOrder.plannedOrderId]
      )
        ? this.state['totalWeight_' + entityPlannedOrder.plannedOrderId]
        : entityPlannedOrder.totalWeight;

      updatedPlannedOrderObj.requestedEtd = !isUndefined(
        this.state['requestedEtd_' + entityPlannedOrder.plannedOrderId]
      )
        ? this.state['requestedEtd_' + entityPlannedOrder.plannedOrderId]
        : entityPlannedOrder.requestedEtd;

      updatedPlannedOrderObj.warehouseId = !isUndefined(newLocationCode)
        ? find(warehouses, function (o) {
            return o.locationCode === newLocationCode;
          }).id
        : entityPlannedOrder.warehouse.id;

      // atleast one field updated
      if (
        updatedPlannedOrderObj.totalWeight !== entityPlannedOrder.totalWeight ||
        !moment(updatedPlannedOrderObj.requestedEtd).isSame(
          moment(entityPlannedOrder.requestedEtd),
          'd'
        ) ||
        updatedPlannedOrderObj.warehouseId !== entityPlannedOrder.warehouse.id
      )
        actualUpdateCount = actualUpdateCount + 1;
      else {
        // reset computed fields since no update
        this.setState({
          ['plannedCases_' + entityPlannedOrder.plannedOrderId]:
            entityPlannedOrder.plannedCases,
        });
        this.setState({
          ['weightUom_' + entityPlannedOrder.plannedOrderId]:
            entityPlannedOrder.weightUom,
        });
        this.setState({
          ['requestedEta_' + entityPlannedOrder.plannedOrderId]:
            entityPlannedOrder.requestedEta,
        });
        this.setState({
          ['destinationPort_' + entityPlannedOrder.plannedOrderId]:
            entityPlannedOrder.destinationPort,
        });
      }

      updatedData.push(updatedPlannedOrderObj);
    });

    if (actualUpdateCount === 0) return {};

    if (entity === 'Auction')
      return {
        auctionPlannedOrders: updatedData,
      };
    else
      return {
        proformaPlannedOrders: updatedData,
      };
  };

  getCalculatedPlannedOrder = (entityPlannedOrder, response) => {
    const { data } = this.props;
    const { warehouses } = data;
    let calculatedEntityPlannedOrder = {
      ...entityPlannedOrder,
      plannedCases: response.plannedCases,
      destinationPort: response.destinationPort,
      requestedEta: response.requestedEta,
      requestedEtd: this.state[
        'requestedEtd_' + entityPlannedOrder.plannedOrderId
      ]
        ? this.state['requestedEtd_' + entityPlannedOrder.plannedOrderId]
        : entityPlannedOrder.requestedEtd,
      totalWeight: this.state[
        'totalWeight_' + entityPlannedOrder.plannedOrderId
      ]
        ? this.state['totalWeight_' + entityPlannedOrder.plannedOrderId]
        : entityPlannedOrder.totalWeight,
    };

    let revisedLocationCode =
      this.state['locationCode_' + entityPlannedOrder.plannedOrderId];

    if (revisedLocationCode) {
      let warehouse = find(warehouses, function (o) {
        return o.locationCode === revisedLocationCode;
      });
      calculatedEntityPlannedOrder.warehouse = warehouse;
    }

    return calculatedEntityPlannedOrder;
  };

  getCalculatedData = () => {
    const { data, auctionId, proformaId, entity } = this.props;
    const plannedOrders = this.getPlannedOrders();

    if (entity === 'Auction') {
      let updatedAuctionDetails = { ...data };
      let calculatedAuctionPlannedOrders = [];

      if (isEmpty(this.getUpdatedData())) return;

      AuctionService.getCalculatedAuctionPlannedOrders(
        auctionId,
        this.getUpdatedData()
      )
        .then((response) => {
          plannedOrders.forEach((auctionPlannedOrder) => {
            let index = findIndex(response.data, function (o) {
              return o.id === auctionPlannedOrder.id;
            });
            this.setState({
              ['plannedCases_' + auctionPlannedOrder.plannedOrderId]:
                response.data[index].plannedCases,
              ['weightUom_' + auctionPlannedOrder.plannedOrderId]:
                response.data[index].weightUom,
              ['destinationPort_' + auctionPlannedOrder.plannedOrderId]:
                response.data[index].destinationPort,
              ['requestedEta_' + auctionPlannedOrder.plannedOrderId]:
                response.data[index].requestedEta,
            });

            calculatedAuctionPlannedOrders.push(
              this.getCalculatedPlannedOrder(
                auctionPlannedOrder,
                response.data[index]
              )
            );
          });
          updatedAuctionDetails.auctionPlannedOrders =
            calculatedAuctionPlannedOrders;
          updatedAuctionDetails.totalFCL =
            calculatedAuctionPlannedOrders.length;
          updatedAuctionDetails.totalCases = sumBy(
            calculatedAuctionPlannedOrders,
            'plannedCases'
          );
          updatedAuctionDetails.totalWeight = sumBy(
            calculatedAuctionPlannedOrders,
            'totalWeight'
          );
          this.props.updatePlannedView(updatedAuctionDetails);
        })
        .catch((error) => {
          console.log(error);
          toast.error('Computation failed, invalid values in edited field(s)');
          // reset state
          plannedOrders.forEach((auctionPlannedOrder) => {
            this.setState({
              ['totalWeight_' + auctionPlannedOrder.plannedOrderId]: undefined,
              ['weightUom_' + auctionPlannedOrder.plannedOrderId]: undefined,
              ['destinationPort_' + auctionPlannedOrder.plannedOrderId]:
                undefined,
              ['requestedEta_' + auctionPlannedOrder.plannedOrderId]: undefined,
              ['requestedEtd_' + auctionPlannedOrder.plannedOrderId]: undefined,
              ['plannedCases_' + auctionPlannedOrder.plannedOrderId]: undefined,
              ['locationCode_' + auctionPlannedOrder.plannedOrderId]: undefined,
            });
          });
        });
    } else {
      let updatedProformaDetails = { ...data };
      let calculatedProformaPlannedOrders = [];

      if (isEmpty(this.getUpdatedData())) return;

      ProformaService.getCalculatedProformaPlannedOrders(
        proformaId,
        this.getUpdatedData()
      )
        .then((response) => {
          plannedOrders.forEach((proformaPlannedOrder) => {
            let index = findIndex(response.data, function (o) {
              return o.id === proformaPlannedOrder.id;
            });
            this.setState({
              ['plannedCases_' + proformaPlannedOrder.plannedOrderId]:
                response.data[index].plannedCases,
              ['weightUom_' + proformaPlannedOrder.plannedOrderId]:
                response.data[index].weightUom,
              ['destinationPort_' + proformaPlannedOrder.plannedOrderId]:
                response.data[index].destinationPort,
              ['requestedEta_' + proformaPlannedOrder.plannedOrderId]:
                response.data[index].requestedEta,
            });

            calculatedProformaPlannedOrders.push(
              this.getCalculatedPlannedOrder(
                proformaPlannedOrder,
                response.data[index]
              )
            );
          });
          updatedProformaDetails.auctionPlannedOrders =
            calculatedProformaPlannedOrders;
          updatedProformaDetails.totalFCL =
            calculatedProformaPlannedOrders.length;
          updatedProformaDetails.totalCases = sumBy(
            calculatedProformaPlannedOrders,
            'plannedCases'
          );
          updatedProformaDetails.totalWeight = sumBy(
            calculatedProformaPlannedOrders,
            'totalWeight'
          );
          this.props.updatePlannedView(updatedProformaDetails);
        })
        .catch((error) => {
          console.log(error);
          toast.error('Computation failed, invalid values in edited field(s)');
          // reset state
          plannedOrders.forEach((proformaPlannedOrder) => {
            this.setState({
              ['totalWeight_' + proformaPlannedOrder.plannedOrderId]: undefined,
              ['weightUom_' + proformaPlannedOrder.plannedOrderId]: undefined,
              ['destinationPort_' + proformaPlannedOrder.plannedOrderId]:
                undefined,
              ['requestedEta_' + proformaPlannedOrder.plannedOrderId]:
                undefined,
              ['requestedEtd_' + proformaPlannedOrder.plannedOrderId]:
                undefined,
              ['plannedCases_' + proformaPlannedOrder.plannedOrderId]:
                undefined,
              ['locationCode_' + proformaPlannedOrder.plannedOrderId]:
                undefined,
            });
          });
        });
    }
  };

  render() {
    const { isEditData } = this.state;
    const { readOnly, hideTitle, toEdit } = this.props;

    return (
      <div
        className={
          'planned-order-data' +
          (readOnly || hideTitle ? '' : ' ca-card-light mb-15 rscb')
        }
      >
        {hideTitle ? null : !readOnly ? (
          <Title
            title='Review Planned Order'
            action={
              isEditData ? (
                <Button
                  className='heading-cta'
                  type='primary'
                  onClick={this.handleHide}
                >
                  Done
                </Button>
              ) : (
                <Button
                  className='heading-cta'
                  type='primary'
                  onClick={this.handleShow}
                >
                  Revise Planned Order
                </Button>
              )
            }
          />
        ) : (
          <p className='sub-heading'> Planned Orders </p>
        )}

        {!isEditData && !toEdit ? (
          <Table
            className='plan-order-data-table'
            columns={this.getColumns()}
            dataSource={this.getTableData()}
            pagination={false}
            scroll={{ x: this.getPlannedOrders().length * 300 }}
            rowKey='title'
          />
        ) : (
          <Table
            className='plan-order-data-edit'
            columns={this.getColumns()}
            dataSource={this.getTableData()}
            pagination={false}
            scroll={{ x: this.getPlannedOrders().length * 300 }}
            rowKey='title'
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUnsavedChanges: (payload) => dispatch(setUnsavedChanges(payload)),
  };
};

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(
  PlannedOrderDataTable
);
