import Cookie from 'js-cookie';
import jwt from 'jsonwebtoken';

// const clientID = process.env.REACT_APP_AUTH0_CLIENTID;
const domain = process.env.REACT_APP_AUTH0_DOMAIN;

async function getJWK() {
  const res = await fetch('https://' + domain + '/.well-known/jwks.json');
  const jwk = await res.json();
  return jwk;
}

function saveAuth0Token(jwtToken) {
  Cookie.set('auth0_token', jwtToken);
}

function saveToken(jwtToken) {
  Cookie.set('token', jwtToken);
}

function deleteToken() {
  Cookie.remove('auth0_token');
  Cookie.remove('token');
}

async function verifyToken(token) {
  if (token) {
    const decodedToken = jwt.decode(token, { complete: true });
    const jwk = await getJWK();
    let cert = jwk.keys[0].x5c[0];
    cert = cert.match(/.{1,64}/g).join('\n');
    cert = `-----BEGIN CERTIFICATE-----\n${cert}\n-----END CERTIFICATE-----\n`;
    if (jwk.keys[0].kid === decodedToken.header.kid) {
      try {
        jwt.verify(token, cert);
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    }
  }
}

async function getAuth0RawToken() {
  const token = Cookie.getJSON('auth0_token');
  const validToken = await verifyToken(token);
  if (validToken) {
    return token;
  } else {
    return undefined;
  }
}

async function getTokenForBrowser() {
  const token = Cookie.getJSON('auth0_token');
  const validToken = await verifyToken(token);
  if (validToken) {
    return jwt.decode(token);
  } else {
    return undefined;
  }
}

async function getTokenForServer(req) {
  if (req.headers.cookie) {
    const jwtFromCookie = req.headers.cookie
      .split(';')
      .find((c) => c.trim().startsWith('token='));
    if (!jwtFromCookie) {
      return undefined;
    }
    const token = jwtFromCookie.split('=')[1];
    const validToken = await verifyToken(token);
    if (validToken) {
      return jwt.decode(token);
    } else {
      return undefined;
    }
  }
}

export {
  saveToken,
  saveAuth0Token,
  deleteToken,
  getAuth0RawToken,
  getTokenForBrowser,
  getTokenForServer,
  verifyToken,
};
