import {
  getInputElement,
  getMandatoryFieldRule,
  getValidationRules,
} from 'src/utils/CompanyProfileUtils';
import { isEmpty, isUndefined } from 'lodash';

import { Form } from 'antd';
import React from 'react';

class FactoryContactInformationSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleFieldValueChange = (fieldValue, group, fieldName) => {
    if (isEmpty(fieldValue))
      this.setState({ [group + '-' + fieldName]: undefined });
    else this.setState({ [group + '-' + fieldName]: fieldValue });
  };

  render() {
    const { data, isEdit, filledData } = this.props;
    return (
      <>
        <p className='title-heading'>{data.title}</p>
        <ul className='rscb-items'>
          {data.contactTypes.map((contactType) => {
            return (
              <div key={contactType.name}>
                <span
                  className='title-small-heading'
                  style={{ marginBottom: 15 }}
                >
                  {contactType.title}
                </span>
                <div>
                  <ul className='rscb-items'>
                    {contactType.fields.map((field) => {
                      return (
                        <li key={field.title}>
                          <span className='left-item'>{field.title}</span>
                          <span className='right-item'>
                            {isEdit ? (
                              <Form.Item
                                name={contactType.name + '-' + field.name}
                                className='mb-0'
                                rules={
                                  field.validateDynamically &&
                                  !isUndefined(
                                    this.state[
                                      contactType.name +
                                        '-' +
                                        field.validateBasedOnField
                                    ]
                                  )
                                    ? [
                                        ...getValidationRules(field),
                                        getMandatoryFieldRule,
                                      ]
                                    : getValidationRules(field)
                                }
                              >
                                {getInputElement(
                                  contactType.name,
                                  field,
                                  this.handleFieldValueChange
                                )}
                              </Form.Item>
                            ) : typeof filledData[
                                contactType.name + '-' + field.name
                              ] === 'boolean' ? (
                              filledData[
                                contactType.name + '-' + field.name
                              ] ? (
                                'Yes'
                              ) : (
                                'No'
                              )
                            ) : (
                              filledData[contactType.name + '-' + field.name]
                            )}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </ul>
      </>
    );
  }
}

export default FactoryContactInformationSection;
