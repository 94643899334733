import { Form, Input, Modal } from 'antd';
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import RichTextEditor from './RichTextEditor';
import TermsAndConditionsService from 'src/services/TermsAndConditionsService';
import Title from 'src/components/common/typography/Title';
import { toast } from 'react-toastify';

class EditTermsAndConditionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isError: false,
      errorMsg: '',
    };
  }

  onSubmit = (values) => {
    this.setState({ loading: true });
    values[':id'] = this.props.id;
    if (
      values['category'] === this.props.category &&
      values['text'] === this.props.text
    ) {
      toast.warning('No changes found');
      this.props.refreshPage();
      return;
    }
    TermsAndConditionsService.editTermsAndCondition(values)
      .then((response) => {
        toast.success('Edited T&C Successfully');
        this.setState({ loading: false }, () => this.props.refreshPage());
      })
      .catch((err) => {
        toast.error('Failed to edit T&C');
        this.setState({ loading: false, isError: true, errorMsg: err });
      });
  };

  setTandCText = (value) => {
    if (!value.isEmpty)
      this.editTandCForm.setFieldsValue({
        text: value.content,
      });
    else
      this.editTandCForm.setFieldsValue({
        text: '',
      });
  };

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    category: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  };

  render() {
    const { visible, category, text } = this.props;
    const { loading } = this.state;

    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };

    return (
      <Modal
        className='confirmation-modal'
        centered
        visible={visible}
        okText='Edit T&C'
        onOk={() => this.editTandCForm.submit()}
        onCancel={this.props.onCancel}
        closable={false}
        maskClosable={false}
        okButtonProps={{
          loading: loading,
        }}
        cancelButtonProps={{
          disabled: loading,
          className: 'cancel-outlined-button',
        }}
        width={900}
      >
        <Title title='Edit T&C' />

        <Form
          {...layout}
          onFinish={this.onSubmit}
          preserve={false}
          ref={(ref) => (this.editTandCForm = ref)}
          className='mt-30'
          initialValues={{ category: category, text: text }}
        >
          <Form.Item
            label='Category'
            name='category'
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please enter a category !',
              },
            ]}
            hasFeedback
          >
            <Input defaultValue={category} className='invite-member-field' />
          </Form.Item>
          <Form.Item
            label='Text'
            name='text'
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please enter a text !',
              },
            ]}
            hasFeedback
          >
            <RichTextEditor
              htmlContent={text}
              isPrefilled
              setContent={this.setTandCText}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default EditTermsAndConditionModal;
