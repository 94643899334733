import { Button, Col, Row, Table, Tabs } from 'antd';
import React, { Component } from 'react';
import { isUserImporter, isUserPartOfTeam } from 'src/helpers/user';

import ApiUtils from 'src/utils/ApiUtils';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import HelpText from 'src/components/common/typography/HelpText';
import LoadingComponent from 'src/components/common/LoadingComponent';
import PurchaseOrderService from 'src/services/PurchaseOrderService';
import PurchaseOrderTableManager from 'src/utils/tables/PurchaseOrderTableManager';
import RouteConstants from 'src/constants/RouteConstants';
import SecureComponent from 'src/components/common/SecureComponent';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { getReadableDisplayName } from 'src/utils/DisplayUtils';
import { isUndefined } from 'lodash';
import { toast } from 'react-toastify';

const helpTextByStatus = {
  [[StringConstants.PURCHASE_ORDER_STATUS.PENDING]]:
    'Purchase orders that are ordered or in transit',
  [[StringConstants.PURCHASE_ORDER_STATUS.DELIVERED]]:
    'Purchase orders that are delivered',
};

class PurchaseOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      purchaseOrders: {
        PENDING: [],
        DELIVERED: [],
      },
      activeKey: undefined,
    };
  }

  componentDidMount() {
    let status = UrlGenerator.getParamsFromUrl(
      this.props.location.search,
      'status'
    );
    if (!status) {
      status = StringConstants.PURCHASE_ORDER_STATUS.PENDING;
    }
    this.setState({ activeKey: status.toUpperCase() }, () =>
      this.fetchPurchaseOrders()
    );
  }

  fetchPurchaseOrders() {
    const { activeKey } = this.state;
    this.setState({ loading: true });
    let status = activeKey;
    if (status === StringConstants.PURCHASE_ORDER_STATUS.PENDING) {
      status = [
        StringConstants.PURCHASE_ORDER_STATUS.ORDERED,
        StringConstants.PURCHASE_ORDER_STATUS.IN_TRANSIT,
      ];
    }
    PurchaseOrderService.getAllPOs({ status })
      .then((response) => {
        this.setState((prev) => ({
          purchaseOrders: {
            ...prev.purchaseOrders,
            [[activeKey]]: response.data,
          },
          loading: false,
        }));
      })
      .catch((err) => {
        let errorMsg = ApiUtils.getErrorMessage(
          err,
          'Failed to fetch purchase orders at the moment'
        );
        toast.error(errorMsg);
        this.setState({ loading: false });
      });
  }

  getColumnsForTable = () => {
    const { purchaseOrders, activeKey } = this.state;
    const data = purchaseOrders[activeKey];
    return PurchaseOrderTableManager.getColumns(data);
  };

  onTabChange = (value) => {
    const url = UrlGenerator.getUiUrlWithQueryParams(
      RouteConstants.PURCHASE_ORDERS,
      {
        status: value,
      }
    );
    window.history.replaceState(null, null, url);
    this.setState({ activeKey: value }, () => this.fetchPurchaseOrders());
  };

  render() {
    const { loading, purchaseOrders, activeKey } = this.state;

    if (isUndefined(activeKey))
      return <LoadingComponent title='Loading Purchase Orders' />;

    return (
      <>
        <HeaderMetadata
          title={`${getReadableDisplayName(activeKey)} Purchase Orders`}
          description={helpTextByStatus[activeKey]}
        />
        <div className='ca-card-light h-95'>
          <Row>
            <Col xs={24} lg={24} xl={24}>
              <Tabs
                activeKey={activeKey}
                onChange={this.onTabChange}
                tabBarExtraContent={
                  isUserImporter() &&
                  (isUserPartOfTeam(StringConstants.TEAMS.EXECUTIVE) ||
                    isUserPartOfTeam(StringConstants.TEAMS.SOURCING)) && (
                    <Button
                      className='heading-cta'
                      type='primary'
                      onClick={() =>
                        this.props.history.push(
                          RouteConstants.PURCHASE_ORDER_ANALYSIS
                        )
                      }
                    >
                      Analyze
                    </Button>
                  )
                }
              >
                <Tabs.TabPane
                  tab='Pending Purchase Orders'
                  key={StringConstants.PURCHASE_ORDER_STATUS.PENDING}
                ></Tabs.TabPane>
                <Tabs.TabPane
                  tab='Delivered Purchase Orders'
                  key={StringConstants.PURCHASE_ORDER_STATUS.DELIVERED}
                ></Tabs.TabPane>
              </Tabs>
              {activeKey && helpTextByStatus[activeKey] && (
                <HelpText
                  message={helpTextByStatus[activeKey]}
                  showIcon={true}
                />
              )}
              <Table
                className='mt-10'
                columns={this.getColumnsForTable()}
                loading={loading}
                dataSource={purchaseOrders[activeKey]}
                rowKey={(row) => row.id}
                pagination={
                  purchaseOrders[activeKey] &&
                  purchaseOrders[activeKey].length >= 10
                }
                scroll={{ x: 800 }}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default SecureComponent(PurchaseOrders);
