import '../Modals.css';

import React, { Component } from 'react';

import { Modal } from 'antd';
import ProcessingPlantDetail from 'src/components/plantDetails/ProcessingPlantDetail';
import PropTypes from 'prop-types';

class ProcessingPlantDetailModal extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
  };

  render() {
    const { visible, data } = this.props;

    return (
      <Modal
        className='single-btn-footer'
        centered
        visible={visible}
        destroyOnClose={true}
        cancelText='Close'
        onCancel={this.props.onClose}
        maskClosable={false}
        closable={false}
        width={900}
      >
        {visible && <ProcessingPlantDetail data={data} />}
      </Modal>
    );
  }
}

export default ProcessingPlantDetailModal;
