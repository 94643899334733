import './AwardedBids.css';

import AuctionTableManager from 'src/utils/tables/AuctionTableManager';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import ChatPopup from 'src/components/common/chatDrawer/ChatPopup';
import React from 'react';
import SellerBidHistoryDrawer from './SellerBidHistoryDrawer';
import { Table } from 'antd';
import { orderBy } from 'lodash';
import { withRouter } from 'react-router-dom';

class AwardedBids extends React.Component {
  state = {
    bidHistoryVisible: false,
    selectedSeller: undefined,
    chatPopupVisible: false,
    plantId: undefined,
    auctionId: undefined,
  };

  onClose = () => {
    this.setState({ bidHistoryVisible: false });
  };

  getAuctionWinnerColumns = () => {
    return AuctionTableManager.getAuctionWinnerColumns.call(this);
  };

  showChatPopup = (plantId, auctionId) => {
    this.setState({ chatPopupVisible: true, plantId, auctionId });
  };

  hideChatPopup = () => {
    this.setState({ chatPopupVisible: false });
  };

  render() {
    const {
      bidHistoryVisible,
      selectedSeller,
      chatPopupVisible,
      plantId,
      auctionId,
    } = this.state;
    return (
      <>
        <CardWithTitle title='Awarded Quotes'>
          <Table
            className='auction-tab-table awarded-bid-table '
            pagination={
              this.props.auctionWinners &&
              this.props.auctionWinners.length >= 10
            }
            locale={{ filterConfirm: 'Apply' }}
            columns={this.getAuctionWinnerColumns()}
            dataSource={orderBy(this.props.auctionWinners, 'bidValue')}
            rowKey='id'
          />
        </CardWithTitle>
        <SellerBidHistoryDrawer
          onClose={() => this.onClose()}
          visible={bidHistoryVisible}
          data={{
            ...this.props.allBids,
            bids: this.props.allBids.bids.filter((bid) => {
              return selectedSeller && bid.companyId === selectedSeller.id;
            }),
            auctionIncoterms: this.props.auctionDetails.auctionIncoterms,
            auctionTargetPrice: this.props.auctionDetails.targetPrice,
            lowestBid: this.props.auctionDetails.lowestBid,
          }}
          selectedSeller={selectedSeller}
        />
        {chatPopupVisible && (
          <ChatPopup
            visible={chatPopupVisible}
            plantId={plantId}
            auctionId={auctionId}
            hideChatPopup={() => this.hideChatPopup()}
          />
        )}
      </>
    );
  }
}
export default withRouter(AwardedBids);
