import { forEach, isEmpty } from 'lodash';

/* eslint-disable array-callback-return */
import UrlConstants from '../constants/UrlConstants';

const getParamsFromUrl = (urlSearchParams, param) => {
  return new URLSearchParams(urlSearchParams).get(param);
};

const generateUrl = (usecase) => {
  let baseUrl = process.env.REACT_APP_API_ENDPOINT;
  if (UrlConstants[usecase] && usecase === UrlConstants[usecase]['USECASE'])
    baseUrl += UrlConstants[usecase].URL;

  return baseUrl;
};

const updatePathParamsForApiUrl = (url, params) => {
  const updatedUrl = new URL(url);

  params &&
    Object.keys(params).map((key) => {
      if (key.charAt(0) === ':' && updatedUrl.pathname.includes(key)) {
        updatedUrl.pathname = updatedUrl.pathname.replace(key, params[key]);
        delete params[key];
      }
    });
  return { url: updatedUrl.toString(), params };
};

const addQueryParamsToApiUrl = (url, params) => {
  const updatedUrl = new URL(url);

  params &&
    Object.keys(params).map((key) => {
      if (!(key.charAt(0) === ':' && updatedUrl.pathname.includes(key))) {
        if (Array.isArray(params[key])) {
          forEach(params[key], (element, index) => {
            if (typeof element === 'object') {
              Object.keys(element).forEach((elementKey) => {
                updatedUrl.searchParams.append(
                  key + '[' + index + '].' + elementKey,
                  element[elementKey]
                );
              });
            } else updatedUrl.searchParams.append(key, element);
          });
        } else {
          updatedUrl.searchParams.set(key, params[key]);
        }
        delete params[key];
      }
    });
  return { url: updatedUrl.toString(), params };
};

/**
 * Repalce Path params in a UI Route.
 * Method returns complete url for the given route.
 * Ensure route has path param differentiated with colon (:) infront of the variable
 * Note : ":" automatically added to the keys in param
 * @param {RouteConstants} route
 * @param {object} params
 */
const getUiUrlWithPathParams = (route, params) => {
  Object.keys(params).map((key) => {
    if (route.includes(':' + key)) {
      route = route.replace(':' + key, params[key]);
    }
  });
  const url = route;
  return url;
};

/**
 * Add Query params to a UI Route
 * Method returns a complete url for the give UI route.
 * (Best used for window.history.replace)
 * @param {RouteConstants} route
 * @param {Object} params
 */
const getUiUrlWithQueryParams = (route, params) => {
  return addQueryParamsToUrl(route, params);
};

/**
 * Add Query params to any URI.
 * Does not convert the route param to URL
 * @param {*} route
 * @param {*} params
 */
const addQueryParamsToUrl = (route, params) => {
  if (isEmpty(params)) return route;
  let pathname = route;
  Object.keys(params).map((key, index) => {
    if (index === 0) pathname += '?';
    else pathname += '&';
    pathname += key + '=' + encodeURIComponent(params[key]);
  });
  return pathname;
};

const UrlGenerator = {
  getParamsFromUrl,
  generateUrl,
  updatePathParamsForApiUrl,
  addQueryParamsToApiUrl,
  getUiUrlWithPathParams,
  getUiUrlWithQueryParams,
  addQueryParamsToUrl,
};

export default UrlGenerator;
