import { Badge, Layout, Menu } from 'antd';
import React, { Component } from 'react';
import {
  isUserImporter,
  isUserPartOfTeam,
  isUserPlant,
} from 'src/helpers/user';

import { Link } from 'react-router-dom';
import NavNotification from 'src/components/common/navbar/navHeader/NavNotification';
import NavProfile from 'src/components/common/navbar/navHeader/NavProfile';
import NavSearchBar from './navHeader/NavSearchBar';
import RouteConstants from 'src/constants/RouteConstants';
import { StreamChat } from 'stream-chat';
import StringConstants from 'src/constants/StringConstants';
import { connect } from 'react-redux';
import { setUnreadCount } from 'src/actions/chat';

const { Header } = Layout;

class NavHeader extends Component {
  state = {
    searchboxstatus: 0,
    searchval: '',
  };

  componentDidMount() {
    this.apiKey = process.env.REACT_APP_STREAM_KEY;
    this.chatClient = StreamChat.getInstance(this.apiKey);

    this.chatClient.on((event) => {
      if (event.total_unread_count !== undefined) {
        this.props.setUnreadCount(event.total_unread_count);
      }
    });
  }

  render() {
    return (
      <Header className='carava-header' style={{ padding: 0 }}>
        <div className='nav-left'>
          <Link className='dash-logo' to={RouteConstants.DASHBOARD}>
            <img
              src={require('../../../assets/images/logo.png').default}
              alt='Carava Logo'
            />
          </Link>
        </div>
        <div className='nav-right'>
          {isUserImporter() && <NavSearchBar />}
          <Menu className='notification-menu' mode='horizontal'>
            {(isUserImporter() || isUserPlant()) &&
              (isUserPartOfTeam(StringConstants.TEAMS.EXECUTIVE) ||
                isUserPartOfTeam(StringConstants.TEAMS.SOURCING)) && (
                <Menu.Item>
                  <Link to={RouteConstants.CHAT}>
                    <Badge count={this.props.unreadCount}>
                      <img
                        src={
                          require('../../../assets/images/chat-icon.png')
                            .default
                        }
                        alt='Chat'
                        className='chat-icon'
                      />
                    </Badge>
                  </Link>
                </Menu.Item>
              )}
          </Menu>
          <NavNotification />
          <NavProfile />
        </div>
      </Header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    unreadCount: state.chat.unreadCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUnreadCount: (unreadCount) => dispatch(setUnreadCount(unreadCount)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavHeader);
