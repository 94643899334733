import { Col, Row } from 'antd';
import {
  getCurrentParticipantBids,
  getSubmittedOrAcceptedBid,
} from 'src/utils/BidUtils';

import ErrorComponent from 'src/components/common/ErrorComponent';
import ParticipationTabs from 'src/components/auction/plants/ParticipationTabs';
import PlaceBidCard from 'src/components/auction/plants/PlaceBidCard';
import ProcessingPlantAuctionDetails from '../../ProcessingPlantAuctionDetails';
import ProformaService from 'src/services/ProformaService';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { isUndefined } from 'lodash';
import { withRouter } from 'react-router-dom';

class PlantAuctionSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auctionId: this.props.auctionId,
      auctionDetails: this.props.auctionDetails,
      auctionParticipants: this.props.auctionParticipants,
      plantBids: this.props.plantBids,
      doesProformaExist: false,
    };
  }

  componentDidMount() {
    const { plantBids } = this.props;
    const auctionId = this.props.match.params.id;
    if (isUndefined(plantBids.bids))
      this.props.history.push(
        UrlGenerator.getUiUrlWithPathParams(RouteConstants.AUCTION_DETAILS, {
          id: auctionId,
        })
      );

    ProformaService.getAuctionProforma(auctionId).then((response) => {
      if (response && response.data.length > 0)
        this.setState({ doesProformaExist: true });
    });
  }

  render() {
    const {
      auctionId,
      auctionDetails,
      auctionParticipants,
      plantBids,
      doesProformaExist,
    } = this.state;

    if (isUndefined(auctionDetails))
      return (
        <ErrorComponent
          title='RFQ not found'
          tip={`RFQ (${auctionId}) does not exist`}
          fullScreen={true}
          headerMetadata={true}
        />
      );

    return (
      <>
        <Row>
          <Col className='mb-10' span={24} xs={24} lg={24} xl={24}>
            <PlaceBidCard
              auctionDetails={auctionDetails}
              updateBid={true}
              bid={getSubmittedOrAcceptedBid(plantBids.bids)}
              currentParticipantBids={getCurrentParticipantBids(
                auctionParticipants,
                plantBids.bids
              )}
              doesProformaExist={doesProformaExist}
            />
          </Col>
        </Row>
        <Row>
          <Col className='mb-10' span={24} xs={24} lg={24} xl={24}>
            <ParticipationTabs
              data={auctionParticipants}
              auctionDetails={auctionDetails}
              plantBids={plantBids}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} xs={24} lg={24} xl={24}>
            <ProcessingPlantAuctionDetails
              auctionDetails={auctionDetails}
              readOnly={true}
            />
          </Col>
        </Row>
      </>
    );
  }
}
export default withRouter(PlantAuctionSummary);
