const UrlConstants = {
  LOGIN: {
    USECASE: 'LOGIN',
    URL: '/auth/user',
  },
  AUTH0_LOGIN: {
    USECASE: 'AUTH0_LOGIN',
    URL: '/auth/verify',
  },
  FORGOT_PASSWORD: {
    USECASE: 'FORGOT_PASSWORD',
    URL: '/forgot-password',
  },
  RESET_PASSWORD: {
    USECASE: 'RESET_PASSWORD',
    URL: '/forgot-password/:token/reset',
  },
  //Accept Invite
  GET_INVITE_TOKEN_DETAILS: {
    USECASE: 'GET_INVITE_TOKEN_DETAILS',
    URL: '/invite-user/:token',
  },
  ACCEPT_PLANT_INVITES: {
    USECASE: 'ACCEPT_PLANT_INVITES',
    URL: '/invite-user/:token/plant-accept',
  },
  ACCEPT_USER_INVITES: {
    USECASE: 'ACCEPT_USER_INVITES',
    URL: '/invite-user/:token/team-accept',
  },
  ACCEPT_SUB_PLANT_INVITES: {
    USECASE: 'ACCEPT_SUB_PLANT_INVITES',
    URL: '/invite-user/:token/sub-plant-accept',
  },

  //User Detail
  GET_USER_PROFILE: {
    USECASE: 'GET_USER_PROFILE',
    URL: '/user',
  },
  UPDATE_PROFILE: {
    USECASE: 'UPDATE_PROFILE',
    URL: '/user',
  },
  UPLOAD_PROFILE_PICTURE: {
    USECASE: 'UPLOAD_PROFILE_PICTURE',
    URL: '/user/-/profile-pic',
  },
  CHANGE_PASSWORD: {
    USECASE: 'CHANGE_PASSWORD',
    URL: '/user/-/change-password',
  },
  GET_USER_PREFERENCE: {
    USECASE: 'GET_USER_PREFERENCE',
    URL: '/user-preference',
  },
  UPDATE_USER_PREFERENCE: {
    USECASE: 'UPDATE_USER_PREFERENCE',
    URL: '/user-preference',
  },
  //Dashboard
  DASHBOARD_GET_AUCTION_COUNT: {
    USECASE: 'DASHBOARD_GET_AUCTION_COUNT',
    URL: '/auction/-/count',
  },

  //Auction
  CREATE_AUCTION: {
    USECASE: 'CREATE_AUCTION',
    URL: '/auction',
  },
  OPEN_AUCTION: {
    USECASE: 'OPEN_AUCTION',
    URL: '/auction/:auctionId/open',
  },
  DELETE_AUCTION: {
    USECASE: 'DELETE_AUCTION',
    URL: '/auction/:auctionId',
  },
  GET_AUCTIONS: {
    USECASE: 'GET_AUCTIONS',
    URL: '/auction',
  },
  GET_AUCTION_DETAIL: {
    USECASE: 'GET_AUCTION_DETAIL',
    URL: '/auction/:auctionId',
  },
  GET_AUCTION_BIDS: {
    USECASE: 'GET_AUCTION_BIDS',
    URL: '/auction/:auctionId/bid',
  },
  GET_AUCTION_PARTICIPANTS: {
    USECASE: 'GET_AUCTION_PARTICIPANTS',
    URL: '/auction/:auctionId/participants',
  },
  MOVE_AUCTION_TO_HOLD: {
    USECASE: 'MOVE_AUCTION_TO_HOLD',
    URL: '/auction/:auctionId/hold',
  },
  CANCEL_AUCTION: {
    USECASE: 'CANCEL_AUCTION',
    URL: '/auction/:auctionId/cancel',
  },
  EDIT_AUCTION: {
    USECASE: 'EDIT_AUCTION',
    URL: '/auction/:auctionId',
  },
  ADD_PLANTS_TO_AUCTION: {
    USECASE: 'ADD_PLANTS_TO_AUCTION',
    URL: '/auction/:auctionId/add-plant',
  },
  GET_BID_DETAIL: {
    USECASE: 'GET_BID_DETAIL',
    URL: '/auction/:auctionId/plant/:plantId/bid',
  },
  ACCEPT_BID: {
    USECASE: 'ACCEPT_BID',
    URL: '/bid/:bidId/accept',
  },
  EDIT_AUCTION_WINNER: {
    USECASE: 'EDIT_AUCTION_WINNER',
    URL: '/auction-winner/:auctionWinnerId',
  },
  REJECT_BID: {
    USECASE: 'REJECT_BID',
    URL: '/auction-winner/:auctionWinnerId',
  },
  GET_AUCTION_WINNERS: {
    USECASE: 'GET_AUCTION_WINNERS',
    URL: '/auction/:auctionId/winners',
  },
  UPDATE_AUCTION_PLANNED_ORDERS: {
    USECASE: 'UPDATE_AUCTION_PLANNED_ORDERS',
    URL: '/auction/:auctionId/auction-planned-orders',
  },
  GET_CALCULATED_AUCTION_PLANNED_ORDERS: {
    USECASE: 'GET_CALCULATED_AUCTION_PLANNED_ORDERS',
    URL: '/auction/:auctionId/auction-planned-orders/calculate',
  },
  UPDATE_COMPLIANT_PLANT_INCOTERMS: {
    USECASE: 'UPDATE_COMPLIANT_PLANT_INCOTERMS',
    URL: '/auction/:auctionId/complaint-plants-incoterms',
  },
  GET_AUCTION_ANALYSIS: {
    USECASE: 'GET_AUCTION_ANALYSIS',
    URL: '/auction/-/analysis-overview',
  },

  GET_PLANNED_ORDERS: {
    USECASE: 'GET_PLANNED_ORDERS',
    URL: '/planned-order',
  },
  GET_PLANNED_ORDER_DETAIL: {
    USECASE: 'GET_PLANNED_ORDER_DETAIL',
    URL: '/planned-order/:pon',
  },
  GET_SPECIFICATIONS: {
    USECASE: 'GET_SPECIFICATIONS',
    URL: '/specification',
  },
  GET_SPECIFICATION_DETAIL: {
    USECASE: 'GET_SPECIFICATION_DETAIL',
    URL: '/specification/:specId',
  },
  GET_SPECIFICATION_DETAIL_FOR_VERSION: {
    USECASE: 'GET_SPECIFICATION_DETAIL_FOR_VERSION',
    URL: '/specification/:specId/version/:version',
  },
  GET_SPECIFICATION_VERSIONS: {
    USECASE: 'GET_SPECIFICATION_VERSIONS',
    URL: '/specification/:specId/version',
  },
  DOWNLOAD_SPECIFICATION: {
    USECASE: 'DOWNLOAD_SPECIFICATION',
    URL: '/specification/:specId/download',
  },
  DOWNLOAD_SPECIFICATION_FOR_VERSION: {
    USECASE: 'DOWNLOAD_SPECIFICATION_FOR_VERSION',
    URL: '/specification/:specId/version/:version/download',
  },
  CREATE_SPECIFICATION: {
    USECASE: 'CREATE_SPECIFICATION',
    URL: '/specification',
  },
  UPDATE_SPECIFICATION: {
    USECASE: 'UPDATE_SPECIFICATION',
    URL: '/specification/:specId',
  },
  DELETE_SPECIFICATION: {
    USECASE: 'DELETE_SPECIFICATION',
    URL: '/specification/:specId',
  },
  ARCHIVE_SPECIFICATION: {
    USECASE: 'ARCHIVE_SPECIFICATION',
    URL: '/specification/:specId/archive',
  },
  UNARCHIVE_SPECIFICATION: {
    USECASE: 'UNARCHIVE_SPECIFICATION',
    URL: '/specification/:specId/unarchive',
  },
  GET_SHIPPING_PORTS: {
    USECASE: 'GET_SHIPPING_PORTS',
    URL: '/shipping-port',
  },
  GET_COMPANY_PROFILE: {
    USECASE: 'GET_COMPANY_PROFILE',
    URL: '/company',
  },
  UPDATE_COMPANY_PROFILE: {
    USECASE: 'UPDATE_COMPANY_PROFILE',
    URL: '/company',
  },
  UPLOAD_COMPANY_LOGO: {
    USECASE: 'UPLOAD_COMPANY_LOGO',
    URL: '/company/-/logo',
  },
  SAVE_COMPANY_NOTE: {
    USECASE: 'SAVE_COMPANY_NOTE',
    URL: '/company-note',
  },
  EDIT_COMPANY_NOTE: {
    USECASE: 'EDIT_COMPANY_NOTE',
    URL: '/company-note/:id',
  },
  REMOVE_COMPANY_NOTE: {
    USECASE: 'REMOVE_COMPANY_NOTE',
    URL: '/company-note/:id',
  },
  GET_PLANT_QUESTIONNAIRE: {
    USECASE: 'GET_PLANT_QUESTIONNAIRE',
    URL: '/plant-questionnaire',
  },
  GET_PROCESSING_PLANTS: {
    USECASE: 'GET_PROCESSING_PLANTS',
    URL: '/company/-/processing-plants',
  },
  GET_INVITED_PROCESSING_PLANTS: {
    USECASE: 'GET_INVITED_PROCESSING_PLANTS',
    URL: '/invite-user/-/plant-pending-invites',
  },
  GET_PROCESSING_PLANT_OVERVIEW: {
    USECASE: 'GET_PROCESSING_PLANT_OVERVIEW',
    URL: '/company/-/processing-plants/:id/overview',
  },
  INVITE_PROCESSING_PLANT: {
    USECASE: 'INVITE_PROCESSING_PLANT',
    URL: '/invite-user/-/plant',
  },
  RESEND_INVITE: {
    USECASE: 'RESEND_INVITE',
    URL: '/invite-user/:id/resend',
  },
  REVOKE_INVITE: {
    USECASE: 'REVOKE_INVITE',
    URL: '/invite-user/:id',
  },
  SUBPLANT_LINKING_INVITE: {
    USECASE: 'SUBPLANT_LINKING_INVITE',
    URL: '/invite-user/-/sub-plant',
  },
  GET_SUBPLANT_PENDING_INVITES: {
    USECASE: 'GET_SUBPLANT_PENDING_INVITES',
    URL: '/invite-user/-/sub-plant-pending-invites',
  },
  SUBPLANT_DELINK: {
    USECASE: 'SUBPLANT_DELINK',
    URL: '/company/-/sub-plant-delink',
  },
  GET_ALL_WAREHOUSES: {
    USECASE: 'GET_ALL_WAREHOUSES',
    URL: '/warehouse',
  },
  COMPLETE_AUCTION: {
    USECASE: 'COMPLETE_AUCTION',
    URL: '/auction/:auctionId/complete',
  },
  REVIEW_PROFORMA: {
    USECASE: 'REVIEW_PROFORMA',
    URL: '/auction/:auctionId/review-proforma',
  },
  GET_AUCTION_PROFORMA: {
    USECASE: 'GET_AUCTION_PROFORMA',
    URL: '/auction/:auctionId/proforma',
  },
  GET_ALL_PROFORMAS: {
    USECASE: 'GET_ALL_PROFORMAS',
    URL: '/proforma',
  },
  DOWNLOAD_PROFORMA: {
    USECASE: 'DOWNLOAD_PROFORMA',
    URL: '/proforma/:proformaId/download',
  },
  DOWNLOAD_CONTRACT: {
    USECASE: 'DOWNLOAD_CONTRACT',
    URL: '/proforma/:proformaId/download-contract',
  },
  GET_CALCULATED_PROFORMA_PLANNED_ORDERS: {
    USECASE: 'GET_CALCULATED_PROFORMA_PLANNED_ORDERS',
    URL: '/proforma/:proformaId/proforma-planned-orders/calculate',
  },
  GET_PROFORMA_DETAILS: {
    USECASE: 'GET_PROFORMA_DETAILS',
    URL: '/proforma/:proformaId',
  },
  GET_PROFORMA_COUNT_FOR_DASHBOARD: {
    USECASE: 'GET_PROFORMA_COUNT_FOR_DASHBOARD',
    URL: '/proforma/-/internal-review/count',
  },
  GET_PENDING_PROFORMAS: {
    USECASE: 'GET_PENDING_PROFORMAS',
    URL: '/proforma/-/internal-review',
  },
  GET_HISTORICAL_PROFORMAS: {
    USECASE: 'GET_HISTORICAL_PROFORMAS',
    URL: '/proforma/-/internal-review/history',
  },
  INTERNAL_REVIEW_ACTION: {
    USECASE: 'INTERNAL_REVIEW_ACTION',
    URL: '/proforma/:proformaId/internal-review',
  },
  SPLIT_PROFORMA: {
    USECASE: 'SPLIT_PROFORMA',
    URL: '/proforma/:proformaId/split',
  },
  // Comments
  GET_COMMENTS: {
    USECASE: 'GET_COMMENTS',
    URL: '/comment',
  },
  ADD_COMMENT: {
    USECASE: 'ADD_COMMENT',
    URL: '/comment',
  },
  UPDATE_PROFORMA: {
    USECASE: 'UPDATE_PROFORMA',
    URL: '/proforma/:proformaId',
  },
  GET_AUDIT_DIFF: {
    USECASE: 'GET_AUDIT_DIFF',
    URL: '/proforma/:proformaId/audit-diff',
  },
  APPROVE_PROFORMA: {
    USECASE: 'APPROVE_PROFORMA',
    URL: '/proforma/:proformaId/approve',
  },
  CREATE_DIRECT_PROFORMA: {
    USECASE: 'CREATE_DIRECT_PROFORMA',
    URL: '/proforma/-/planned-order',
  },
  GET_PROFORMA_VERSIONS: {
    USECASE: 'GET_PROFORMA_VERSIONS',
    URL: '/proforma/:proformaId/version',
  },
  GET_PROFORMA_DETAIL_FOR_VERSION: {
    USECASE: 'GET_PROFORMA_DETAIL_FOR_VERSION',
    URL: '/proforma/:proformaId/version/:version',
  },
  DOWNLOAD_PROFORMA_FOR_VERSION: {
    USECASE: 'DOWNLOAD_PROFORMA_FOR_VERSION',
    URL: '/proforma/:proformaId/version/:version/download',
  },
  GET_ITEM_HISTORICAL_PRICE: {
    USECASE: 'GET_ITEM_HISTORICAL_PRICE',
    URL: '/specification/:specId/price-history',
  },

  // Processing Plants side APIs
  GET_AUCTIONS_FOR_PLANTS: {
    USECASE: 'GET_AUCTIONS_FOR_PLANTS',
    URL: '/auction/-/:status',
  },
  PLACE_BID: {
    USECASE: 'PLACE_BID',
    URL: '/auction/:auctionId/bid',
  },
  WITHDRAW_FROM_AUCTION: {
    USECASE: 'WITHDRAW_FROM_AUCTION',
    URL: '/auction/:auctionId/plant',
  },
  UPDATE_BID: {
    USECASE: 'UPDATE_BID',
    URL: '/bid/:bidId',
  },
  SIMULATE_BID: {
    USECASE: 'SIMULATE_BID',
    URL: '/auction/:auctionId/simulate-bid',
  },
  //SeaFoodList
  GET_SEAFOOD_LIST: {
    USECASE: 'GET_SEAFOOD_LIST',
    URL: '/sea-food-list',
  },

  // Teams
  GET_TEAMS: {
    USECASE: 'GET_TEAMS',
    URL: '/team',
  },
  GET_TEAM_USERS: {
    USECASE: 'GET_TEAM_USERS',
    URL: '/team/:teamId/users',
  },
  UPDATE_USER_ROLE: {
    USECASE: 'UPDATE_USER_ROLE',
    URL: '/team/:teamId/change-role/:userId',
  },
  REMOVE_USER: {
    USECASE: 'REMOVE_USER',
    URL: '/team/:teamId/remove-user/:userId',
  },
  INVITE_USER_TO_TEAM: {
    USECASE: 'INVITE_USER_TO_TEAM',
    URL: '/invite-user/-/team',
  },
  RESEND_INVITE_USER_TO_TEAM: {
    USECASE: 'RESEND_INVITE_USER_TO_TEAM',
    URL: '/invite-user/:id/resend',
  },
  REVOKE_USER_TEAM_INVITE: {
    USECASE: 'REVOKE_USER_TEAM_INVITE',
    URL: '/invite-user/:id',
  },
  GET_PENDING_INVITES: {
    USECASE: 'GET_PENDING_INVITES',
    URL: '/invite-user/:teamId/pending-invites',
  },

  // Reports
  GET_ALL_SPECIFICATIONS_DROPDOWN: {
    USECASE: 'GET_ALL_SPECIFICATIONS_DROPDOWN',
    URL: '/specification/-/dropdown',
  },
  GET_SOURCING_TREND_FOR_SPEC: {
    USECASE: 'GET_SOURCING_TREND_FOR_SPEC',
    URL: '/specification/:id/trends',
  },
  GET_INVENTORY_PIPELINE_FOR_SPECS: {
    USECASE: 'GET_INVENTORY_PIPELINE_FOR_SPECS',
    URL: '/report/-/inventory-pipeline',
  },
  GET_SPECIFICATION_OVERVIEW: {
    USECASE: 'GET_SPECIFICATION_OVERVIEW',
    URL: '/report/-/specification/:id',
  },

  //Chat
  GET_CHAT_TOKEN: {
    USECASE: 'GET_CHAT_TOKEN',
    URL: '/chat/-/token',
  },

  // Notifications
  GET_ALL_NOTIFICATIONS: {
    USECASE: 'GET_ALL_NOTIFICATIONS',
    URL: '/in-app-notification',
  },
  MARK_ALL_NOTIFICATIONS_READ: {
    USECASE: 'MARK_ALL_NOTIFICATIONS_READ',
    URL: '/in-app-notification/-/read',
  },
  MARK_NOTIFICATION_READ: {
    USECASE: 'MARK_NOTIFICATION_READ',
    URL: '/in-app-notification/:id/read',
  },

  GET_SPECIFICATION_CUSTOMERS: {
    USECASE: 'GET_SPECIFICATION_CUSTOMERS',
    URL: '/specification/-/customers',
  },
  GET_SPECIFICATION_BRANDS: {
    USECASE: 'GET_SPECIFICATION_BRANDS',
    URL: '/specification/-/brand',
  },
  GET_COUNTRIES: {
    USECASE: 'GET_COUNTRIES',
    URL: '/country',
  },
  GET_DICTIONARY_DROPDOWNS: {
    USECASE: 'GET_DICTIONARY_DROPDOWNS',
    URL: '/dictionary/drop-down',
  },

  GET_DOCUMENT_TEMPLATE: {
    USECASE: 'GET_DOCUMENT_TEMPLATE',
    URL: '/document-template',
  },
  UPLOAD_DOCUMENT: {
    USECASE: 'UPLOAD_DOCUMENT',
    URL: '/document',
  },
  GET_DOCUMENTS: {
    USECASE: 'GET_DOCUMENTS',
    URL: '/document',
  },
  DOWNLOAD_DOCUMENT: {
    USECASE: 'DOWNLOAD_DOCUMENT',
    URL: '/document/:id/download',
  },
  GET_PROCESSING_PLANT_DOCUMENT_TEMPLATES_FOR_IMPORTER: {
    USECASE: 'GET_PROCESSING_PLANT_DOCUMENT_TEMPLATES_FOR_IMPORTER',
    URL: '/document-template/-/plant-doc-templates',
  },
  GET_PROCESSING_PLANT_DOCUMENTS_FOR_IMPORTER: {
    USECASE: 'GET_PROCESSING_PLANT_DOCUMENTS_FOR_IMPORTER',
    URL: '/document/-/processing-plants/:id',
  },

  //Terms and Conditions
  GET_ALL_TERMS_AND_CONDITIONS: {
    USECASE: 'GET_ALL_TERMS_AND_CONDITIONS',
    URL: '/terms-and-conditions',
  },
  CREATE_TERMS_AND_CONDITION: {
    USECASE: 'CREATE_TERMS_AND_CONDITION',
    URL: '/terms-and-conditions',
  },
  EDIT_TERMS_AND_CONDITION: {
    USECASE: 'EDIT_TERMS_AND_CONDITION',
    URL: '/terms-and-conditions/:id',
  },
  DELETE_TERMS_AND_CONDITION: {
    USECASE: 'DELETE_TERMS_AND_CONDITION',
    URL: '/terms-and-conditions/:id',
  },

  // Search API
  GET_SEARCH_RESULTS: {
    USECASE: 'GET_SEARCH_RESULTS',
    URL: '/search',
  },

  //Purchase order
  GET_ALL_PURCHASE_ORDERS: {
    USECASE: 'GET_ALL_PURCHASE_ORDERS',
    URL: '/purchase-order',
  },
  GET_PURCHASE_ORDER_DETAIL: {
    USECASE: 'GET_PURCHASE_ORDER_DETAIL',
    URL: '/purchase-order/:id',
  },
  GET_PURCHASE_ORDER_TIMELINE: {
    USECASE: 'GET_PURCHASE_ORDER_TIMELINE',
    URL: '/purchase-order/:id/timeline',
  },
  GET_NAV_PURCHASE_ORDER_DETAIL: {
    USECASE: 'GET_NAV_PURCHASE_ORDER_DETAIL',
    URL: '/nav-purchase-order/:id',
  },
  GET_NAV_PURCHASE_ORDER_TIMELINE: {
    USECASE: 'GET_NAV_PURCHASE_ORDER_TIMELINE',
    URL: '/nav-purchase-order/:id/timeline',
  },
};

export default UrlConstants;
