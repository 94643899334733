import '../common/Auth.css';

import { Button, Form, Input } from 'antd';

import React from 'react';
import UserService from 'src/services/UserService';
import { toast } from 'react-toastify';
import ApiUtils from 'src/utils/ApiUtils';
import { withRouter } from 'react-router';
import RouteConstants from 'src/constants/RouteConstants';

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  onSubmit = (values) => {
    if (values.newPassword !== values.confirmPassword) {
      this.resetPasswordForm.setFields([
        {
          name: 'confirmPassword',
          value: values.confirmPassword,
          errors: ['Passwords do not match'],
        },
      ]);
      return;
    }

    this.setState({ loading: true });
    UserService.resetPassword(
      this.props.token,
      values.newPassword,
      values.confirmPassword
    )
      .then(() => {
        toast.success(
          'Password Reset Successful, Please login with the new password'
        );
        this.resetPasswordForm.resetFields();
        this.props.history.replace(RouteConstants.LOGIN);
      })
      .catch((err) => {
        const errorMessage = ApiUtils.getErrorMessage(
          err,
          'Invalid Link, Please verify if you have the correct password reset link'
        );
        toast.error(errorMessage);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { disabled } = this.props;
    const { loading } = this.state;
    return (
      <>
        <div className='login-left-details'>
          <img
            className='log-logo'
            src={require('../../../assets/images/logo.png').default}
            alt='Carava Logo'
          />
          <h2 className='all-form-heading'>Create New Password</h2>

          <Form
            className='all-form createpass-form'
            layout='vertical'
            ref={(ref) => (this.resetPasswordForm = ref)}
            onFinish={this.onSubmit}
          >
            <Form.Item
              label='New Password'
              name='newPassword'
              rules={[
                {
                  required: true,
                  message: 'Please enter a valid password',
                },
                {
                  len: 5,
                  message: 'Minimum password length is 5 characters',
                },
              ]}
            >
              <Input.Password disabled={disabled || loading} />
            </Form.Item>
            <Form.Item
              label='Confirm Password'
              name='confirmPassword'
              rules={[
                {
                  required: true,
                  message: 'Please enter a valid password',
                },
                {
                  len: 5,
                  message: 'Minimum password length is 5 characters',
                },
              ]}
            >
              <Input.Password disabled={disabled || loading} />
            </Form.Item>
            <Form.Item>
              <Button
                className='submit mt-10'
                type='primary'
                htmlType='submit'
                disabled={disabled}
                loading={loading}
              >
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </>
    );
  }
}
export default withRouter(ResetPasswordForm);
