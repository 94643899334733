import './Timeline.css';

import { Checkbox, Col, Row } from 'antd';
import React, { Component } from 'react';

import { isEmpty } from 'lodash';
import moment from 'moment';

class Timeline extends Component {
  render() {
    const { events } = this.props;
    return (
      <>
        <Row>
          <Col className=' mb-15 ' span={24} xs={24} lg={20} xl={20}>
            <ul className='ca-delivery-timeline'>
              {events.map((event, index) => {
                const date = moment(event.dateTime);
                return (
                  <li
                    className={`timeline-sections active ${
                      !isEmpty(event.subEvents) ? 'multiactivity' : ''
                    }`}
                    key={index}
                  >
                    <div className='left-content'>
                      <p className='date-time'>
                        <strong>{date.format('ll')}</strong>
                      </p>
                      <p className='date-time'>{date.format('HH:mm A')}</p>
                    </div>
                    <Checkbox className='tim-ch' checked={true}></Checkbox>
                    <div className='center-content-upline'></div>
                    <div className='center-content-downline'></div>
                    <div className='right-content'>
                      {isEmpty(event.subEvents) ? (
                        <>
                          <p className='tracking-desc '>
                            <strong>Activity : </strong>
                            {event.description}
                          </p>
                          <p className='tracking-desc'>
                            {event.location ? (
                              <>
                                <strong>Location : </strong> {event.location}
                              </>
                            ) : event.user ? (
                              <>
                                <strong>Actioned by : </strong>
                                {event.user.name}
                              </>
                            ) : null}
                          </p>
                        </>
                      ) : (
                        <>
                          <div className='multiactivity-divider' />
                          {event.subEvents.map((subEvent, index) => {
                            return (
                              <p className='tracking-desc' key={index}>
                                <Checkbox
                                  className='tim-multi-chk'
                                  checked={true}
                                ></Checkbox>
                                <strong>Activity : </strong>{' '}
                                {subEvent.description}
                              </p>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </Col>
        </Row>
      </>
    );
  }
}

export default Timeline;
