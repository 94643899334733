import {
  getBidDisplayText,
  getFormattedFullDate,
  getReadableDisplayName,
  getUsername,
} from '../DisplayUtils';

import { Link } from 'react-router-dom';
import RouteConstants from 'src/constants/RouteConstants';
import { Space } from 'antd';
import TableFilterUtils from '../TableFilterUtils';
import UrlGenerator from 'src/api/UrlGenerator';

const getColumnsForImporter = (data) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      filters: TableFilterUtils.getFilterDropdownValues(data, 'id'),
      filterMultiple: true,
      onFilter: (value, record) => record.id.indexOf(value) >= 0,
    },
    {
      title: 'RFQ ID',
      dataIndex: 'auctionId',
      filters: TableFilterUtils.getFilterDropdownValues(data, 'auctionId'),
      filterMultiple: true,
      onFilter: (value, record) =>
        record.auctionId && record.auctionId.indexOf(value) >= 0,
      render: (auctionId) => (auctionId ? auctionId : '-'),
    },
    {
      title: 'Pro-forma Description',
      dataIndex: 'specification',
      filters: TableFilterUtils.getFilterDropdownValues(
        data,
        'specification.itemDescription'
      ),
      filterMultiple: true,
      onFilter: (value, record) =>
        record.specification.itemDescription.indexOf(value) >= 0,
      render: (specification) => specification.itemDescription,
    },
    {
      title: 'FCLs',
      dataIndex: 'totalFCL',
      sorter: (a, b) => a.totalFCL - b.totalFCL,
    },
    {
      title: 'Final Value',
      render: ({ bidValue, bidIncoterm }) =>
        getBidDisplayText(bidValue, bidIncoterm.code),
      sorter: (a, b) => a.bidValue - b.bidValue,
    },

    {
      title: 'Notes',
      dataIndex: 'notes',
      render: (notes) => (notes ? notes : '-'),
    },
    {
      title: '',
      render: (proforma) => {
        return (
          <div className='text-center'>
            <Space>
              <Link
                className='primary-button ant-btn'
                to={UrlGenerator.getUiUrlWithPathParams(
                  RouteConstants.PROFORMA_DETAIL,
                  {
                    id: proforma.id,
                  }
                )}
              >
                View
              </Link>
            </Space>
          </div>
        );
      },
    },
  ];
  return columns;
};

const getColumnsForPlant = (data) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      filters: TableFilterUtils.getFilterDropdownValues(data, 'id'),
      filterMultiple: true,
      onFilter: (value, record) => record.id.indexOf(value) >= 0,
    },
    {
      title: 'Pro-forma Description',
      dataIndex: 'specification',
      filters: TableFilterUtils.getFilterDropdownValues(
        data,
        'specification.itemDescription'
      ),
      filterMultiple: true,
      onFilter: (value, record) =>
        record.specification.itemDescription.indexOf(value) >= 0,
      render: (specification) => specification.itemDescription,
    },
    {
      title: 'FCLs',
      dataIndex: 'totalFCL',
      sorter: (a, b) => a.totalFCL - b.totalFCL,
    },
    {
      title: 'Final Value',
      render: ({ bidValue, bidIncoterm }) =>
        getBidDisplayText(bidValue, bidIncoterm.code),
      sorter: (a, b) => a.bidValue - b.bidValue,
    },
    {
      title: 'Buyer',
      dataIndex: 'importer',
      filters: TableFilterUtils.getFilterDropdownValues(data, 'importer.name'),
      filterMultiple: true,
      onFilter: (value, record) => {
        return record.importer.name.indexOf(value) >= 0;
      },
      render: (importer) => (importer ? importer.name : ''),
    },
    {
      title: 'Pro-forma creation date',
      dataIndex: 'createdAt',
      render: (createdAt) => getFormattedFullDate(createdAt),
    },
    {
      title: '',
      render: (proforma) => {
        return (
          <div className='text-center'>
            <Space>
              <Link
                className='primary-button ant-btn'
                to={UrlGenerator.getUiUrlWithPathParams(
                  RouteConstants.PROFORMA_DETAIL,
                  {
                    id: proforma.id,
                  }
                )}
              >
                View
              </Link>
            </Space>
          </div>
        );
      },
    },
  ];
  return columns;
};

const getColumnsForSpecificationInventory = (data) => {
  const columns = getColumnsForImporter(data);
  const sellerColumn = {
    title: 'Seller',
    dataIndex: 'buyFrom',
    filters: TableFilterUtils.getFilterDropdownValues(data, 'buyFrom.name'),
    filterMultiple: true,
    onFilter: (value, record) => record.buyFrom.name.indexOf(value) >= 0,
    render: (buyFrom) => buyFrom.name,
  };
  const statusColumn = {
    title: 'Status',
    dataIndex: 'status',
    filters: TableFilterUtils.getFilterDropdownValues(data, 'status'),
    filterMultiple: true,
    onFilter: (value, record) => record.status.indexOf(value) >= 0,
    render: (status) => getReadableDisplayName(status, true),
  };
  columns.splice(columns.length - 2, 0, sellerColumn);
  columns.splice(columns.length - 1, 0, statusColumn);
  return columns;
};

const getColumnsForApprovalStatus = () => {
  const columns = [
    {
      title: 'Team',
      dataIndex: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value) =>
        value ? (
          <span className='proforma-approved-text'>Approved</span>
        ) : (
          <span className='proforma-pending-approval-text'>
            Pending Approval
          </span>
        ),
    },
    {
      title: 'Approver',
      dataIndex: 'approver',
      render: (value) => (value ? getUsername(value) : '-'),
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      render: (value) => (value ? value : '-'),
    },
    {
      title: 'Actioned Time',
      dataIndex: 'actionedTime',
      render: (value) => getFormattedFullDate(value),
    },
  ];
  return columns;
};

const ProformaTableManager = {
  getColumnsForImporter,
  getColumnsForPlant,
  getColumnsForSpecificationInventory,
  getColumnsForApprovalStatus,
};

export default ProformaTableManager;
