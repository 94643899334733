import './UserProfile.css';

import { Button, Col, Form, Input, Row, Space } from 'antd';

import ApiUtils from 'src/utils/ApiUtils';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import NoAccessComponent from 'src/components/common/NoAccessComponent';
import ProfileSection from './userProfile/ProfileSection';
import React from 'react';
import SecureComponent from 'src/components/common/SecureComponent';
import UserService from 'src/services/UserService';
import { getUserDataFromCookie } from 'src/utils/auth/AuthUtils';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';

class UserChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      changePasswordAllowed: false,
    };
  }

  componentDidMount() {
    const jwtData = getUserDataFromCookie();
    if (jwtData && jwtData.auth_type === 'PASSWORD')
      this.setState({ changePasswordAllowed: true });
  }

  onSubmit = () => {
    this.setState({ loading: true });
    this.userChangePasswordForm
      .validateFields()
      .then((values) => {
        if (values.newPassword !== values.confirmPassword) {
          this.userChangePasswordForm.setFields([
            { name: 'confirmPassword', errors: ["Passwords don't match"] },
          ]);
          this.setState({ loading: false });
          return;
        }
        UserService.changePassword(values)
          .then(() => {
            toast.success('Password Updated Successfully');
            this.setState({ loading: false, isEdit: false });
          })
          .catch((err) => {
            const errorMessage = ApiUtils.getErrorMessage(
              err,
              'Unable to update password at the moment'
            );
            toast.error(errorMessage);
            this.setState({ loading: false });
          });
      })
      .catch((error) => {
        if (!isEmpty(error.errorFields)) {
          this.userChangePasswordForm.scrollToField(
            error.errorFields[0].name[0]
          );
        } else {
          toast.warning('Unable to update password at the moment');
        }
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading, changePasswordAllowed } = this.state;

    if (!changePasswordAllowed)
      return (
        <NoAccessComponent
          title='Password Change Not Enabled'
          description='Your company settings do not allow for password change. Please contact your administrator'
        />
      );

    return (
      <>
        <HeaderMetadata title={`Change Password | Carava`} description='' />
        <CardWithTitle
          className={'userprofile userprofile-edit rscb-editable'}
          title='Change Password'
        >
          <Row>
            <ProfileSection />
            <Col className='rscb-details' span={24} xs={24} lg={24} xl={24}>
              <p className='title-heading'>Update Password</p>
              <ul className='rscb-items'>
                <Form ref={(ref) => (this.userChangePasswordForm = ref)}>
                  <li>
                    <span className='left-item'>Current Password:</span>
                    <span className='right-item'>
                      <Form.Item
                        name='currentPassword'
                        className='mb-0'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your current password',
                          },
                        ]}
                      >
                        <Input.Password className='input-field' />
                      </Form.Item>
                    </span>
                  </li>
                  <li>
                    <span className='left-item'>New Password:</span>
                    <span className='right-item'>
                      <Form.Item
                        name='newPassword'
                        className='mb-0'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your new password',
                          },
                          {
                            type: 'string',
                            min: 6,
                            message: 'Password should be min. 6 characters',
                          },
                        ]}
                      >
                        <Input.Password className='input-field' />
                      </Form.Item>
                    </span>
                  </li>
                  <li>
                    <span className='left-item'>Confirm New Password:</span>
                    <span className='right-item'>
                      <Form.Item
                        name='confirmPassword'
                        className='mb-0'
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm your new password',
                          },
                          {
                            type: 'string',
                            min: 6,
                            message: 'Password should be min. 6 characters',
                          },
                        ]}
                      >
                        <Input.Password className='input-field' />
                      </Form.Item>
                    </span>
                  </li>
                </Form>
              </ul>
            </Col>
          </Row>
        </CardWithTitle>
        <div className='editprofile-btn-area mt-15 mb-15'>
          <Space>
            <Button
              className='primary-full mr-15'
              type='primary'
              onClick={this.onSubmit}
              loading={loading}
            >
              Save Changes
            </Button>
            <Button
              onClick={this.handleCancel}
              className='cancel-outlined-full'
              type='primary'
              disabled={loading}
            >
              Cancel
            </Button>
          </Space>
        </div>
      </>
    );
  }
}

export default SecureComponent(UserChangePassword);
