import { Button, Checkbox, Col, Form, InputNumber, Row, Tooltip } from 'antd';
import {
  getBidDisplayWithHelperText,
  getFormattedFullDate,
  getTimeRemaining,
} from 'src/utils/DisplayUtils';
import { isUserManagerOfTeam, isUserPlant } from 'src/helpers/user';

import ApiUtils from 'src/utils/ApiUtils';
import AuctionService from 'src/services/AuctionService';
import AuctionUtils from 'src/utils/AuctionUtils';
import BidService from 'src/services/BidService';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { find } from 'lodash';
import { findActualBiddedIncoterm } from 'src/utils/BidUtils';
import { isUserSubCompany } from 'src/utils/auth/AuthUtils';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';

class PlaceBidCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auctionId: this.props.auctionId,
      fclAvailable:
        this.props.updateBid && this.props.bid && this.props.bid.fclAvailable
          ? this.props.bid.fclAvailable
          : 1,
      selectedIncoterm:
        this.props.updateBid && this.props.bid
          ? this.props.bid.code
          : undefined,
      selectedAmount:
        this.props.updateBid && this.props.bid ? this.props.bid.amount : 0.0,
      termsAccepted: false,
      placingBid: false,
      bidId:
        this.props.updateBid && this.props.bid ? this.props.bid.id : undefined,
    };
  }

  placeBid = () => {
    const {
      fclAvailable,
      termsAccepted,
      auctionId,
      bidId,
      selectedIncoterm,
      selectedAmount,
    } = this.state;

    if (fclAvailable === 0 || !termsAccepted || !selectedIncoterm) {
      toast.warning('Please fill all mandatory fields');
      return;
    }

    if (selectedIncoterm && selectedAmount === 0) {
      toast.warning('Please enter a quote amount');
      return;
    }

    this.setState({ placingBid: true });

    let bidIncoterms = [
      {
        incotermCode: selectedIncoterm,
        amount: selectedAmount,
      },
    ];

    const { updateBid } = this.props;

    if (updateBid) {
      try {
        BidService.updateBid(bidId, {
          fclAvailable,
          incoterms: bidIncoterms,
        })
          .then((response) => {
            toast.success('Updated quote successfully');
            this.setState({ placingBid: false }, () => {
              this.props.history.push(RouteConstants.AUCTIONS);
            });
          })
          .catch((error) => {
            let errorMessage = ApiUtils.getErrorMessage(
              error,
              'Failed to update quote'
            );
            toast.error(errorMessage);
            this.setState({ placingBid: false });
          });
      } catch (error) {
        let errorMessage = ApiUtils.getErrorMessage(
          error,
          'Failed to update quote'
        );
        toast.error(errorMessage);
        this.setState({ placingBid: false });
      }
    } else {
      try {
        AuctionService.placeBid(auctionId, {
          fclAvailable,
          incoterms: bidIncoterms,
        })
          .then((response) => {
            toast.success('Placed quote successfully');
            this.setState({ placingBid: false }, () => {
              this.props.history.push(RouteConstants.AUCTIONS);
            });
          })
          .catch((error) => {
            let errorMessage = ApiUtils.getErrorMessage(
              error,
              'Failed to place quote'
            );
            toast.error(errorMessage);
            this.setState({ placingBid: false });
          });
      } catch (error) {
        let errorMessage = ApiUtils.getErrorMessage(
          error,
          'Failed to place quote'
        );
        toast.error(errorMessage);
        this.setState({ placingBid: false });
      }
    }
  };

  isIncotermAvailable = (incotermCode) => {
    const { auctionDetails } = this.props;
    return (
      auctionDetails &&
      find(auctionDetails.auctionIncoterms, function (o) {
        return o.incoterm.code === incotermCode;
      })
    );
  };

  isIncotermEnabled = (participantCurrentBid, incoterm) => {
    if (participantCurrentBid)
      return participantCurrentBid && participantCurrentBid.code === incoterm;
    else return this.isIncotermAvailable(incoterm);
  };

  navigateToPage = (route, params) => {
    this.props.history.push(UrlGenerator.getUiUrlWithPathParams(route, params));
  };

  render() {
    const {
      auctionDetails,
      currentParticipantBids,
      updateBid,
      doesProformaExist,
    } = this.props;

    const {
      fclAvailable,
      termsAccepted,
      placingBid,
      selectedIncoterm,
      selectedAmount,
    } = this.state;

    let participantCurrentBid = undefined;
    if (currentParticipantBids && currentParticipantBids.length > 0) {
      let currentIncoterm = findActualBiddedIncoterm(
        currentParticipantBids[0].bidIncoterms
      );
      if (currentIncoterm) participantCurrentBid = currentIncoterm;
    }

    let participantPreviousBid = undefined;
    if (currentParticipantBids && currentParticipantBids.length > 1) {
      let currentIncoterm = findActualBiddedIncoterm(
        currentParticipantBids[1].bidIncoterms
      );
      if (currentIncoterm) participantPreviousBid = currentIncoterm;
    }

    return (
      <CardWithTitle
        title='My Quote'
        className='mybid'
        action={
          AuctionUtils.isPendingAuction(auctionDetails.status) ? (
            <Button
              className='heading-cta '
              type='primary'
              onClick={() =>
                this.navigateToPage(RouteConstants.PROFORMA_REVIEW, {
                  id: auctionDetails.id,
                })
              }
              disabled={!doesProformaExist}
            >
              View Proforma
            </Button>
          ) : null
        }
      >
        <Row>
          <Col className='' span={24} xs={24} lg={24} xl={24}></Col>
          <Col className='mb-10 mt-15' span={24} xs={24} lg={24} xl={24}>
            <div className='leftheading'>
              {/* {auctionDetails &&
                auctionDetails.specification &&
                ImageUtils.getSpecificationLogo(
                  auctionDetails.specification.productType
                )} */}
              <div className='rscb-desc' style={{ padding: 0 }}>
                <p className='rscb-desc-company'>
                  <span>
                    {auctionDetails &&
                      auctionDetails.specification &&
                      auctionDetails.specification.itemDescription}
                  </span>
                </p>
              </div>
            </div>
            <div className=' rightheading'>
              <div className='right-heading-desc'>
                <p className='auction-end-det text-normal'>
                  RFQ{' '}
                  {AuctionUtils.isActiveAuction(auctionDetails.status)
                    ? 'Ends on '
                    : 'Ended on '}{' '}
                  {getFormattedFullDate(auctionDetails.endAt)}
                </p>
                {AuctionUtils.isActiveAuction(auctionDetails.status) && (
                  <span className='count-down-det title-heading'>
                    {getTimeRemaining(auctionDetails.endAt)}
                  </span>
                )}
              </div>
            </div>
          </Col>
          <Col
            className='ogbid-form mb-15 mt-15'
            span={24}
            xs={24}
            lg={24}
            xl={24}
          >
            {updateBid && (
              <Row className='mb-15 fullwidth'>
                <Col className='' span={24} xs={24} lg={24} xl={24}>
                  <div className='auction-info-card ogbid-detail'>
                    <ul className='ogbid-detail-items-list'>
                      {/* <li className='ogbid-detail-items'>
                        {currentParticipantBids &&
                        currentParticipantBids.length > 0
                          ? currentParticipantBids[0].rank
                          : 'N/A'}
                        <span>My Quote Rank</span>
                      </li> */}

                      {/* Disabling this to hide details from plants */}
                      {/* <li className='ogbid-detail-items'>
                        {auctionDetails.lowestBid
                          ? getBidDisplayWithHelperText(
                              auctionDetails.lowestBid.amount,
                              auctionDetails.lowestBid.incoterm.code
                            )
                          : 'N/A'}
                        <span>Lowest Quote</span>
                      </li> */}

                      <li className='ogbid-detail-items'>
                        {currentParticipantBids &&
                        currentParticipantBids.length > 0
                          ? currentParticipantBids[0].fclAvailable
                          : '-'}
                        <span>FCLs Offered</span>
                      </li>

                      <li className='ogbid-detail-items'>
                        {participantCurrentBid
                          ? getBidDisplayWithHelperText(
                              participantCurrentBid.amount,
                              participantCurrentBid.code
                            )
                          : 'N/A'}
                        <span>My New Quote</span>
                      </li>

                      <li className='ogbid-detail-items'>
                        {participantPreviousBid
                          ? getBidDisplayWithHelperText(
                              participantPreviousBid.amount,
                              participantPreviousBid.code
                            )
                          : 'N/A'}
                        <span>My Previous Quote</span>
                      </li>

                      <li className='ogbid-detail-items'>
                        {auctionDetails.winScore.toFixed(2)}
                        <span>Win Score</span>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            )}
            <ul className='rscb-items mybid'>
              <li>
                <span className='left-item'>Total RFQ Volume</span>
                <span className='right-item'>
                  {auctionDetails.totalFCL} FCL(s)
                </span>
              </li>
              <li>
                <span className='left-item'>
                  Total Capacity
                  <br />
                  (Fulfillable Volume in FCL)
                  {/* RB: Commented as per feedback from David */}
                  {/* {updateBid
                    ? 'Total FCL included in bid:'
                    : 'Total FCL available from supplier:'} */}
                </span>
                <span className='right-item'>
                  <InputNumber
                    className='decimalval input-field'
                    step={1}
                    min={1}
                    placeholder='Enter your FCL to commit for quote'
                    value={fclAvailable}
                    onChange={(value) =>
                      this.setState({
                        fclAvailable: value,
                      })
                    }
                    disabled={
                      placingBid ||
                      !AuctionUtils.isActiveAuction(auctionDetails.status) ||
                      !isUserPlant() ||
                      isUserSubCompany() ||
                      !isUserManagerOfTeam(StringConstants.TEAMS.SOURCING)
                    }
                    precision={0}
                  />
                </span>
              </li>
              <li>
                <span className='left-item'>Select Preferred Incoterm:</span>

                <span className='right-item'>
                  {StringConstants.INCOTERMS.map((incoterm) => {
                    return (
                      this.isIncotermEnabled(
                        participantCurrentBid,
                        incoterm.code
                      ) && (
                        <div className='incoterm-checkbox' key={incoterm.code}>
                          <Checkbox
                            className={
                              selectedIncoterm === incoterm.code
                                ? 'incoterms incoterms-dark incoterm-cnf-dark'
                                : 'incoterms incoterm-cnf-light'
                            }
                            disabled={
                              placingBid ||
                              !AuctionUtils.isActiveAuction(
                                auctionDetails.status
                              ) ||
                              !isUserPlant() ||
                              isUserSubCompany() ||
                              !isUserManagerOfTeam(
                                StringConstants.TEAMS.SOURCING
                              )
                            }
                            onChange={() =>
                              this.setState({
                                selectedIncoterm:
                                  selectedIncoterm === incoterm.code
                                    ? undefined
                                    : incoterm.code,
                              })
                            }
                            checked={selectedIncoterm === incoterm.code}
                          >
                            {incoterm.text}
                            <br />
                            {incoterm.subText}
                          </Checkbox>
                          {selectedIncoterm === incoterm.code && (
                            <>
                              <p className='mt-15'>
                                Quote price per LB
                                <Tooltip
                                  title='This price includes all retail packaging, labels, and master cartons'
                                  className='ml-15'
                                >
                                  <InfoCircleOutlined />
                                </Tooltip>
                              </p>

                              <InputNumber
                                className='input-field decimalval'
                                step={0.01}
                                min={0}
                                value={selectedAmount}
                                onChange={(value) =>
                                  this.setState({ selectedAmount: value })
                                }
                                disabled={
                                  placingBid ||
                                  !AuctionUtils.isActiveAuction(
                                    auctionDetails.status
                                  ) ||
                                  !isUserPlant() ||
                                  isUserSubCompany() ||
                                  !isUserManagerOfTeam(
                                    StringConstants.TEAMS.SOURCING
                                  )
                                }
                                formatter={(value) => '$ ' + value}
                              />
                            </>
                          )}
                        </div>
                      )
                    );
                  })}
                </span>
              </li>
            </ul>
            {AuctionUtils.isActiveAuction(auctionDetails.status) &&
              isUserPlant() &&
              !isUserSubCompany() &&
              isUserManagerOfTeam(StringConstants.TEAMS.SOURCING) && (
                <Form.Item className='mb-0'>
                  <Checkbox
                    className='tnc-check text-normal'
                    checked={termsAccepted}
                    onChange={(event) =>
                      this.setState({ termsAccepted: event.target.checked })
                    }
                  >
                    I commit to this price for the RFQ submitted.
                  </Checkbox>
                </Form.Item>
              )}
          </Col>
          {AuctionUtils.isActiveAuction(auctionDetails.status) &&
            isUserPlant() &&
            !isUserSubCompany() &&
            isUserManagerOfTeam(StringConstants.TEAMS.SOURCING) && (
              <Col span={24} xs={24} lg={24} xl={24}>
                <Button
                  className='place-bid-cta'
                  type='primary'
                  onClick={() => this.placeBid()}
                  loading={placingBid}
                >
                  Place Quote
                </Button>
              </Col>
            )}
        </Row>
      </CardWithTitle>
    );
  }
}

export default withRouter(PlaceBidCard);
