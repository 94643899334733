import { Button, Rate, Space, Tooltip } from 'antd';
import {
  displayDecimal,
  getAmountDisplayText,
  getBidDisplayText,
  getFormattedFullDate,
  getPercentageDisplayText,
  getReadableDisplayName,
  getSavingsDisplayText,
} from '../DisplayUtils';
import { find, forEach, isEmpty, isNull, isUndefined } from 'lodash';
import {
  findActualBiddedIncoterm,
  findBidIncotermByCode,
  findChange,
  findPreviousBidForCompany,
  getDisplayIncoterm,
  getRank,
} from '../BidUtils';
import {
  isUserImporter,
  isUserManagerOfTeam,
  isUserPartOfTeam,
} from 'src/helpers/user';

import AuctionTableUtils from '../AuctionTableUtils';
import AuctionUtils from '../AuctionUtils';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import RouteConstants from 'src/constants/RouteConstants';
import StringConstants from 'src/constants/StringConstants';
import TableFilterUtils from '../TableFilterUtils';
import UrlGenerator from 'src/api/UrlGenerator';
import moment from 'moment';

const getAuctionBasicColumnsForImporters = (data) => {
  return [
    {
      title: 'RFQ',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'Item Number',
      dataIndex: 'customerItemNumber',
      key: 'customerItemNumber',
      filters: TableFilterUtils.getFilterDropdownValues(
        data,
        'customerItemNumber'
      ),
      filterMultiple: true,
      onFilter: (value, record) =>
        record.customerItemNumber.indexOf(value) >= 0,
      width: 100,
    },
    {
      title: 'Item Description',
      dataIndex: 'itemDescription',
      key: 'itemDescription',
      filters: TableFilterUtils.getFilterDropdownValues(
        data,
        'itemDescription'
      ),
      ellipsis: {
        showTitle: false,
      },
      filterMultiple: true,
      onFilter: (value, record) => record.itemDescription.indexOf(value) >= 0,
      render: (itemDescription) => (
        <Tooltip placement='topLeft' title={itemDescription}>
          {itemDescription}
        </Tooltip>
      ),
    },
    {
      title: 'Customer',
      dataIndex: 'journalBatchName',
      key: 'journalBatchName',
      filters: TableFilterUtils.getFilterDropdownValues(
        data,
        'journalBatchName'
      ),
      filterMultiple: true,
      onFilter: (value, record) => record.journalBatchName.indexOf(value) >= 0,
      ellipsis: {
        showTitle: false,
      },
      render: (journalBatchName) => (
        <Tooltip placement='topLeft' title={journalBatchName}>
          {journalBatchName}
        </Tooltip>
      ),
      width: 120,
    },
    {
      title: 'Total FCLs',
      dataIndex: 'totalFCL',
      key: 'totalFCL',
      sorter: (a, b) => a.totalFCL - b.totalFCL,
      render: (totalFCL) => (
        <NumberFormat
          value={totalFCL}
          thousandSeparator={true}
          displayType='text'
        />
      ),
      width: 100,
    },
    {
      title: 'Estimated Weight',
      sorter: (a, b) => a.totalWeight - b.totalWeight,
      render: ({ totalWeight, weightUom }) => (
        <NumberFormat
          value={totalWeight}
          thousandSeparator={true}
          displayType='text'
          suffix={' ' + weightUom}
        />
      ),
      width: 100,
    },
  ];
};

const getAuctionBasicColumnsForPlants = (data, status) => {
  return [
    {
      title: 'RFQ',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'Item Description',
      dataIndex: 'itemDescription',
      key: 'itemDescription',
      filters: TableFilterUtils.getFilterDropdownValues(
        data,
        'itemDescription'
      ),
      ellipsis: {
        showTitle: false,
      },
      filterMultiple: true,
      onFilter: (value, record) => record.itemDescription.indexOf(value) >= 0,
      render: (itemDescription) => (
        <Tooltip placement='topLeft' title={itemDescription}>
          {itemDescription}
        </Tooltip>
      ),
    },
    {
      title: 'Customer',
      dataIndex: 'journalBatchName',
      key: 'journalBatchName',
      filters: TableFilterUtils.getFilterDropdownValues(
        data,
        'journalBatchName'
      ),
      filterMultiple: true,
      onFilter: (value, record) => record.journalBatchName.indexOf(value) >= 0,
    },
    {
      title: 'Total FCLs',
      dataIndex: 'totalFCL',
      key: 'totalFCL',
      sorter: (a, b) => a.totalFCL - b.totalFCL,
      render: (totalFCL) => (
        <NumberFormat
          value={totalFCL}
          thousandSeparator={true}
          displayType='text'
        />
      ),
      width: 100,
    },
    {
      title: 'Estimated Weight',
      sorter: (a, b) => a.totalWeight - b.totalWeight,
      render: ({ totalWeight, weightUom }) => (
        <NumberFormat
          value={totalWeight}
          thousandSeparator={true}
          displayType='text'
          suffix={' ' + weightUom}
        />
      ),
    },
    {
      title: 'Invited By',
      dataIndex: 'invitedBy',
      key: 'invitedBy',
      filters: TableFilterUtils.getFilterDropdownValues(data, 'invitedBy'),
      ellipsis: {
        showTitle: false,
      },
      filterMultiple: true,
      onFilter: (value, record) => record.invitedBy.indexOf(value) >= 0,
      render: (invitedBy) => (
        <Tooltip placement='topLeft' title={invitedBy}>
          {invitedBy}
        </Tooltip>
      ),
    },
    {
      title:
        status === StringConstants.AUCTION_STATUS.HISTORY
          ? 'Ended At'
          : 'Ends by',
      dataIndex: 'endAt',
      key: 'endAt',
      sorter: (a, b) => moment(a.endAt).diff(moment(b.endAt), 'second'),
      render: (endAt) => getFormattedFullDate(endAt),
      width: 100,
    },
  ];
};

const getAuctionBidColumnForPlants = (data) => {
  return [
    {
      title: 'My Last Quote',
      dataIndex: 'currentBid',
      key: 'currentBid',
      render: (currentBid) => {
        if (isNull(currentBid) || isUndefined(currentBid)) return 'N/A';
        const bidIncoterm = findActualBiddedIncoterm(currentBid.bidIncoterms);
        return getBidDisplayText(bidIncoterm.amount, bidIncoterm.code);
      },
      width: 100,
    },
  ];
};

const getParticipantColumns = (status) => {
  return [
    {
      title: 'Participants',
      dataIndex: 'totalParticipants',
      key: 'totalParticipants',
      render: (totalParticipants) =>
        isNull(totalParticipants) ? '-' : totalParticipants,
      width: 100,
    },
    {
      title: 'Lowest Quote',
      key: 'lowestBid',
      dataIndex: 'lowestBid',
      render: (lowestBid) => {
        if (isEmpty(lowestBid)) return '-';
        else if (lowestBid.isCalculated)
          return (
            <>
              <strong>
                {getBidDisplayText(lowestBid.amount, lowestBid.incoterm.code)}
                <Tooltip
                  title='This Quote value is calculated'
                  placement='bottomLeft'
                >
                  <InfoCircleOutlined style={{ marginLeft: 5 }} />
                </Tooltip>
              </strong>
            </>
          );
        else
          return (
            <strong>
              {getBidDisplayText(lowestBid.amount, lowestBid.incoterm.code)}
            </strong>
          );
      },
      width: 120,
    },
    {
      title:
        status === StringConstants.AUCTION_STATUS.ACTIVE
          ? 'Ends by'
          : status === StringConstants.AUCTION_STATUS.HOLD
          ? 'Ended At'
          : 'Ends/Ended At',
      dataIndex: 'endAt',
      key: 'endAt',
      sorter: (a, b) => moment(a.endAt).diff(moment(b.endAt), 'second'),
      render: (endAt) => getFormattedFullDate(endAt),
      width: 150,
    },
  ];
};

const getInvitedPlantColumn = () => {
  return [
    {
      title: 'Invited Plants',
      dataIndex: 'totalInvitedParticipants',
      key: 'totalInvitedParticipants',
      sorter: (a, b) => a.totalInvitedParticipants - b.totalInvitedParticipants,
      render: (totalInvitedParticipants) => (
        <NumberFormat
          value={totalInvitedParticipants}
          thousandSeparator={true}
          displayType='text'
        />
      ),
      width: 100,
    },
  ];
};

const getParticipantStatusColumn = (data, title) => {
  return [
    {
      title: isUndefined(title) ? 'Status' : title,
      dataIndex: 'participantStatus',
      key: 'participantStatus',
      render: (participantStatus) => {
        return (
          <span
            className={
              participantStatus === 'LOST' || participantStatus === 'CANCELLED'
                ? 'auction-lost-text'
                : participantStatus === 'WON'
                ? 'auction-won-text'
                : 'auction-pending-proforma-approval-text'
            }
          >
            {participantStatus
              ? getReadableDisplayName(participantStatus)
              : '-'}
          </span>
        );
      },
      filters: TableFilterUtils.getFilterDropdownValues(
        data,
        'participantStatus'
      ),
      filterMultiple: true,
      onFilter: (value, record) => {
        return (
          record.participantStatus &&
          record.participantStatus.indexOf(value) >= 0
        );
      },
      width: 120,
    },
  ];
};

const getStatusColumn = (data, title) => {
  const filters = TableFilterUtils.getFilterDropdownValues(data, 'status');
  forEach(filters, (filter) => {
    if (filter.value === StringConstants.AUCTION_STATUS.HOLD) {
      filter.text = 'Pending Action';
    }
  });
  return [
    {
      title: isUndefined(title) ? 'Status' : title,
      dataIndex: 'status',
      key: 'status',
      filters: filters,
      filterMultiple: true,
      onFilter: (value, record) => record.status.indexOf(value) >= 0,
      width: 120,
      render: (status) => {
        switch (status) {
          case StringConstants.AUCTION_STATUS.PROFORMA_REVIEW:
            return 'Proforma Review';
          case StringConstants.AUCTION_STATUS.HOLD:
            return 'Pending Action';
          case StringConstants.AUCTION_STATUS.DRAFT:
            return 'Draft';
          default:
            return getReadableDisplayName(status);
        }
      },
    },
  ];
};

const getActionButton = (status) => {
  return [
    {
      title: '',
      key: 'action',
      width: 80,
      render: (auctionRecord) => {
        if (isEmpty(status)) status = auctionRecord.status;
        if (status === StringConstants.AUCTION_STATUS.DRAFT)
          return (
            <div className='text-center'>
              <Link
                className='primary-button ant-btn'
                to={UrlGenerator.getUiUrlWithPathParams(
                  RouteConstants.AUCTION_STEP_1,
                  { id: auctionRecord.id }
                )}
              >
                Continue
              </Link>
            </div>
          );
        else if (
          status === StringConstants.AUCTION_STATUS.PENDING ||
          status === StringConstants.AUCTION_STATUS.HOLD ||
          status === StringConstants.AUCTION_STATUS.ACTIVE ||
          status === StringConstants.AUCTION_STATUS.SCHEDULED_ACTIVE ||
          status === StringConstants.AUCTION_STATUS.PROFORMA_REVIEW ||
          status === StringConstants.AUCTION_STATUS.HISTORY
        ) {
          return (
            <div className='text-center'>
              <Link
                className='primary-button ant-btn'
                to={UrlGenerator.getUiUrlWithPathParams(
                  RouteConstants.AUCTION,
                  { id: auctionRecord.id }
                )}
              >
                View
              </Link>
            </div>
          );
        } else if (status === StringConstants.AUCTION_STATUS.INVITED) {
          return (
            <div className='text-center'>
              <Link
                className='primary-button ant-btn'
                to={UrlGenerator.getUiUrlWithPathParams(
                  RouteConstants.AUCTION_DETAILS,
                  { id: auctionRecord.id }
                )}
              >
                View
              </Link>
            </div>
          );
        }
      },
    },
  ];
};

const getDraftAuctionColumnsForImporters = (data) => {
  let columns = getAuctionBasicColumnsForImporters(data);
  columns = columns.concat(
    getInvitedPlantColumn(),
    getActionButton(StringConstants.AUCTION_STATUS.DRAFT)
  );
  return columns;
};

const getActiveAuctionColumnsForImporter = (data) => {
  let columns = getAuctionBasicColumnsForImporters(data);
  columns = columns.concat(
    getInvitedPlantColumn(),
    getParticipantColumns(StringConstants.AUCTION_STATUS.ACTIVE),
    getActionButton(StringConstants.AUCTION_STATUS.ACTIVE)
  );
  return columns;
};

const getPendingAuctionColumnsForImporters = (data) => {
  let columns = getAuctionBasicColumnsForImporters(data);
  columns = columns.concat(
    getParticipantColumns(StringConstants.AUCTION_STATUS.PENDING),
    getStatusColumn(data),
    getActionButton(StringConstants.AUCTION_STATUS.PENDING)
  );
  return columns;
};

const getHistoryAuctionColumnsForImporters = (data) => {
  let columns = getAuctionBasicColumnsForImporters(data);
  columns = columns.concat(
    {
      title: 'Ended At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: (a, b) => moment(a.updatedAt).diff(moment(b.updatedAt), 'second'),
      render: (updatedAt) => getFormattedFullDate(updatedAt),
      width: 100,
    },
    getStatusColumn(data),
    getActionButton(StringConstants.AUCTION_STATUS.HISTORY)
  );
  return columns;
};

const getInvitedAuctionColumnsForPlants = (data) => {
  let columns = getAuctionBasicColumnsForPlants(data);
  columns = columns.concat(
    getActionButton(StringConstants.AUCTION_STATUS.INVITED)
  );
  return columns;
};

const getActiveAuctionColumnsForPlants = (data) => {
  let columns = getAuctionBasicColumnsForPlants(
    data,
    StringConstants.AUCTION_STATUS.HISTORY
  );
  columns = columns.concat(
    getAuctionBidColumnForPlants(data, StringConstants.AUCTION_STATUS.ACTIVE),
    getActionButton(StringConstants.AUCTION_STATUS.ACTIVE)
  );
  return columns;
};

const getPendingAuctionColumnsForPlants = (data) => {
  return getActiveAuctionColumnsForPlants(data);
};

const getHistoryAuctionColumnsForPlants = (data) => {
  let columns = getAuctionBasicColumnsForPlants(
    data,
    StringConstants.AUCTION_STATUS.HISTORY
  );
  columns = columns.concat(
    getAuctionBidColumnForPlants(data, StringConstants.AUCTION_STATUS.HISTORY),
    getParticipantStatusColumn(data),
    getActionButton(StringConstants.AUCTION_STATUS.ACTIVE)
  );
  return columns;
};

const getAuctionRevenueColumns = () => {
  return [
    {
      title: 'RFQ Description',
      key: 'auctionDescription',
    },
    {
      title: 'RFQ Closed On',
      key: 'auctionClosedOn',
    },
    {
      title: 'FCL needed',
      key: 'totalFCL',
    },
    {
      title: 'Final Value',
      key: 'finalValue',
    },
  ];
};

const getColumnsForSpecificationInventory = (data) => {
  let columns = getAuctionBasicColumnsForImporters(data);
  columns = columns.concat(
    getInvitedPlantColumn(),
    getParticipantColumns(),
    getStatusColumn(data),
    getActionButton()
  );
  return columns;
};

const getColumnsForPlantOverview = (data) => {
  let columns = getAuctionBasicColumnsForImporters(data);
  columns = columns.concat(
    {
      title:
        data.status === StringConstants.AUCTION_STATUS.HISTORY
          ? 'Ended At'
          : 'Ends by',
      dataIndex: 'endAt',
      key: 'endAt',
      sorter: (a, b) => moment(a.endAt).diff(moment(b.endAt), 'second'),
      render: (endAt) => getFormattedFullDate(endAt),
      defaultSortOrder: 'descend',
      width: 100,
    },
    getParticipantStatusColumn(data, 'Participant Status'),
    getStatusColumn(data, 'RFQ Status'),
    getActionButton()
  );
  return columns;
};

function getAuctionSummaryActions() {
  if (!AuctionUtils.isPendingAuction(this.props.auctionDetails.status))
    return [];
  return [
    {
      title: '',
      key: 'action',
      render: (text, record) => {
        return (
          <Space size='middle' align='center'>
            {isUserImporter() &&
              (isUserPartOfTeam(StringConstants.TEAMS.EXECUTIVE) ||
                isUserPartOfTeam(StringConstants.TEAMS.SOURCING)) && (
                <Button
                  className='heading-cta negotiate-cta'
                  icon={
                    <img
                      className=''
                      src={require('../../assets/images/negotiate.png').default}
                      alt='negotiate'
                    />
                  }
                  onClick={() => {
                    this.showChatPopup(
                      record.company.id,
                      this.props.match.params.id
                    );
                  }}
                >
                  Negotiate
                </Button>
              )}

            {isUserImporter() &&
            isUserManagerOfTeam(StringConstants.TEAMS.SOURCING) ? (
              this.areAllPlannedOrdersAssigned() ? (
                <Tooltip title='All planned orders have been assigned'>
                  <Button
                    disabled={this.areAllPlannedOrdersAssigned()}
                    className='heading-cta accept-cta'
                    type='primary'
                    onClick={() =>
                      this.props.showPlannedOrdersAssignmentModal(
                        record.bids && record.bids.length > 0 && record.bids[0],
                        StringConstants.BID_ACTIONS.ACCEPT
                      )
                    }
                  >
                    Accept Quote
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  disabled={this.areAllPlannedOrdersAssigned()}
                  className='heading-cta accept-cta'
                  type='primary'
                  onClick={() =>
                    this.props.showPlannedOrdersAssignmentModal(
                      record.bids && record.bids.length > 0 && record.bids[0],
                      StringConstants.BID_ACTIONS.ACCEPT
                    )
                  }
                >
                  Accept Quote
                </Button>
              )
            ) : null}
          </Space>
        );
      },
    },
  ];
}

function getAuctionAnalysisActions() {
  return [
    {
      title: '',
      key: 'action',
      render: (text, record) => {
        return (
          <Link
            to={UrlGenerator.getUiUrlWithPathParams(
              RouteConstants.BID_DETAILS,
              {
                id: this.props.auctionDetails.id,
                plantId: record.company.id,
              }
            )}
            className='auctionoverview-cta'
          >
            View Quote
          </Link>
        );
      },
    },
  ];
}

function getAuctionParticipantColumnsForImporters() {
  let bidActions = this.props.isAuctionSummary
    ? getAuctionSummaryActions.call(this)
    : getAuctionAnalysisActions.call(this);

  return [
    {
      title: 'Rank No.',
      sorter: (a, b) => {
        return AuctionTableUtils.checkBidsLength(a, b)
          ? a.bids[0].rank - b.bids[0].rank
          : 0;
      },
      render: ({ bids }) => {
        return bids.length > 0 ? bids[0].rank : '-';
      },
    },
    {
      title: 'Seller Name',
      filters: TableFilterUtils.getFilterDropdownValues(
        this.props.allBids.biddingCompanies,
        'country'
      ),
      onFilter: (value, record) => record.company.country === value,
      render: ({ bids, company }) => {
        return (
          <>
            <Tooltip title='Click to view quote history chart'>
              <span
                className='seller-clickable'
                onClick={() => {
                  this.setState({
                    bidHistoryVisible: true,
                    selectedSeller: company,
                  });
                }}
              >
                {company.name}
              </span>
            </Tooltip>

            {bids[0].rank === 1 ? (
              <div className='seller-bid-sub'>{'LB'}</div>
            ) : null}
          </>
        );
      },
    },
    {
      title: 'Latest Quote',
      sorter: (a, b) => {
        return AuctionTableUtils.checkBidsLength(a, b)
          ? getDisplayIncoterm(a.bids[0].bidIncoterms).amount -
              getDisplayIncoterm(b.bids[0].bidIncoterms).amount
          : 0;
      },
      render: ({ bids }) => {
        const incotermBidObj = getDisplayIncoterm(bids[0].bidIncoterms);
        return (
          <>
            {bids.length > 0
              ? getBidDisplayText(incotermBidObj.amount, incotermBidObj.code)
              : ''}
            {this.props.auctionDetails &&
              this.props.auctionDetails.auctionIncoterms &&
              this.props.auctionDetails.auctionIncoterms.length > 1 &&
              incotermBidObj.isCalculated && (
                <Tooltip
                  className=''
                  title={
                    bids.length > 0
                      ? 'Estimated ' + incotermBidObj.code + ' value'
                      : ''
                  }
                  placement='bottomLeft'
                >
                  <span className='estimate-pop'>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              )}
          </>
        );
      },
    },
    {
      title: 'FCL Available',
      sorter: (a, b) => {
        return AuctionTableUtils.checkBidsLength(a, b)
          ? a.bids[0].fclAvailable - b.bids[0].fclAvailable
          : 0;
      },
      render: ({ bids }) => (bids.length > 0 ? bids[0].fclAvailable : ''),
    },
    {
      title: 'Change (in $)',
      sorter: (a, b) => {
        return AuctionTableUtils.checkBidsLength(a, b)
          ? findChange(a.bids) - findChange(b.bids)
          : 0;
      },
      render: ({ bids }) => findChange(bids),
    },
    {
      title: 'Change (in %)',
      sorter: (a, b) => {
        return AuctionTableUtils.checkChange(a, b)
          ? findChange(a.bids) /
              getDisplayIncoterm(a.bids[1].bidIncoterms).amount -
              findChange(b.bids) /
                getDisplayIncoterm(b.bids[1].bidIncoterms).amount
          : 1;
      },
      render: ({ bids }) => {
        let change = findChange(bids);

        return change !== '-' && bids.length > 1
          ? getPercentageDisplayText(
              (change / getDisplayIncoterm(bids[1].bidIncoterms).amount) * 100
            )
          : '-';
      },
    },
    {
      title: 'Date/Time',
      sorter: (a, b) => {
        return AuctionTableUtils.checkBidsLength(a, b)
          ? moment(a.bids[0].createdAt).unix() -
              moment(b.bids[0].createdAt).unix()
          : 0;
      },
      render: ({ bids }) =>
        bids.length > 0 ? getFormattedFullDate(bids[0].createdAt) : '-',
    },
    {
      title: 'Savings (in %)',
      width: 130,
      sorter: (a, b) => {
        return AuctionTableUtils.checkBidsLength(a, b)
          ? this.props.auctionDetails.targetPrice -
              getDisplayIncoterm(a.bids[0].bidIncoterms).amount -
              (this.props.auctionDetails.targetPrice -
                getDisplayIncoterm(b.bids[0].bidIncoterms).amount)
          : 0;
      },
      render: ({ bids }) => {
        if (bids.length === 0) return '-';
        else {
          return getSavingsDisplayText(
            AuctionTableUtils.getSavings(
              this.props.auctionDetails.targetPrice,
              getDisplayIncoterm(bids[0].bidIncoterms).amount
            )
          );
        }
      },
    },
    {
      title: 'Delivery Score',
      dataIndex: 'deliveryscore',
      sorter: (a, b) => a.caravaid - b.caravaid,
      render: (text, rating) => (
        <Space
          className='deliver-star'
          direction='vertical'
          align='start'
          size='small'
        >
          <Rate
            disabled
            allowHalf
            defaultValue={Math.floor(Math.random() * 5) + 1}
          />
        </Space>
      ),
    },
    ...bidActions,
  ];
}

function getAuctionHistoryColumnsForImporters() {
  return [
    {
      title: 'Seller Name',
      render: ({ companyId }) => {
        const company = this.findCompany(
          this.props.allBids.biddingCompanies,
          companyId
        );
        if (company)
          return (
            <Tooltip title='Click to view quote history chart'>
              <span
                className='seller-clickable'
                onClick={() => {
                  this.setState({
                    bidHistoryVisible: true,
                    selectedSeller: company,
                  });
                }}
              >
                {company.name}
              </span>
            </Tooltip>
          );
        else return '-';
      },
    },
    {
      title: 'New Value',
      sorter: (a, b) =>
        findActualBiddedIncoterm(a.bidIncoterms).amount -
        findActualBiddedIncoterm(b.bidIncoterms).amount,
      render: ({ bidIncoterms }) => {
        let actualBiddedIncoterm = findActualBiddedIncoterm(bidIncoterms);
        return getBidDisplayText(
          actualBiddedIncoterm.amount,
          actualBiddedIncoterm.code
        );
      },
    },
    {
      title: 'Previous Value',
      sorter: (a, b) => {
        return AuctionTableUtils.checkPreviousBid(a, b, this.props.allBids.bids)
          ? findActualBiddedIncoterm(
              findPreviousBidForCompany(this.props.allBids.bids, a).bidIncoterms
            ).amount -
              findActualBiddedIncoterm(
                findPreviousBidForCompany(this.props.allBids.bids, b)
                  .bidIncoterms
              ).amount
          : 1;
      },
      render: (bid) => {
        let previousBid = findPreviousBidForCompany(
          this.props.allBids.bids,
          bid
        );

        if (isUndefined(previousBid)) return '-';

        let actualBiddedIncoterm = findActualBiddedIncoterm(
          previousBid.bidIncoterms
        );
        return getBidDisplayText(
          actualBiddedIncoterm.amount,
          actualBiddedIncoterm.code
        );
      },
    },
    {
      title: 'Change (in $)',
      sorter: (a, b) =>
        AuctionTableUtils.checkPreviousBid(a, b, this.props.allBids.bids)
          ? AuctionTableUtils.getChange(this.props.allBids.bids, a) -
            AuctionTableUtils.getChange(this.props.allBids.bids, b)
          : 1,
      render: (bid) =>
        isUndefined(findPreviousBidForCompany(this.props.allBids.bids, bid))
          ? '-'
          : displayDecimal(
              AuctionTableUtils.getChange(this.props.allBids.bids, bid)
            ),
    },
    {
      title: 'Change (in %)',
      sorter: (a, b) =>
        AuctionTableUtils.checkPreviousBid(a, b, this.props.allBids.bids)
          ? AuctionTableUtils.getChange(this.props.allBids.bids, a) /
              AuctionTableUtils.getPreviousBidAmount(
                this.props.allBids.bids,
                a
              ) -
            AuctionTableUtils.getChange(this.props.allBids.bids, b) /
              AuctionTableUtils.getPreviousBidAmount(this.props.allBids.bids, b)
          : 1,
      render: (bid) =>
        isUndefined(findPreviousBidForCompany(this.props.allBids.bids, bid))
          ? '-'
          : getPercentageDisplayText(
              (AuctionTableUtils.getChange(this.props.allBids.bids, bid) /
                AuctionTableUtils.getPreviousBidAmount(
                  this.props.allBids.bids,
                  bid
                )) *
                100
            ),
    },
    {
      title: 'Date/Time',
      sorter: (a, b) => {
        return moment(a.createdAt).unix() - moment(b.createdAt).unix();
      },
      render: ({ createdAt }) => getFormattedFullDate(createdAt),
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => {
        return (
          <Space size='middle' align='center'>
            <Link
              to={UrlGenerator.getUiUrlWithPathParams(
                RouteConstants.BID_DETAILS,
                {
                  id: this.props.auctionDetails.id,
                  plantId: record.companyId,
                }
              )}
              className='auctionoverview-cta'
            >
              View Quote
            </Link>
          </Space>
        );
      },
    },
  ];
}

function getIncotermComparisonColumnsForImporters() {
  return [
    {
      title: 'Rank No.',
      sorter: (a, b) => {
        return a.bids.length > 0 && b.bids.length > 0
          ? a.bids[0].rank - b.bids[0].rank
          : 0;
      },
      render: ({ bids }) => (bids.length > 0 ? bids[0].rank : '-'),
    },
    {
      title: 'Seller Name',
      sorter: (a, b) => a.company.name.localeCompare(b.company.name),
      render: ({ company }) => {
        return (
          <Tooltip title='Click to view quote history chart'>
            <span
              className='seller-clickable'
              onClick={() => {
                this.setState({
                  bidHistoryVisible: true,
                  selectedSeller: company,
                });
              }}
            >
              {company.name}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'CNF/CFR (Cost & Freight)',
      sorter: (a, b) =>
        AuctionTableUtils.checkIncotermByCode(a, b, 'CNF')
          ? findBidIncotermByCode(a.bids[0].bidIncoterms, 'CNF').amount -
            findBidIncotermByCode(b.bids[0].bidIncoterms, 'CNF').amount
          : 0,
      render: ({ bids }) => {
        const bid = findBidIncotermByCode(bids[0].bidIncoterms, 'CNF');
        return !bid ? (
          '-'
        ) : (
          <>
            <>{getAmountDisplayText(bid.amount)}</>
            {bid.isCalculated && (
              <Tooltip
                title={'Estimated ' + bid.code + ' value'}
                placement='bottomLeft'
              >
                <span className='estimate-pop'>
                  <InfoCircleOutlined />
                </span>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: 'DDPCY (Delivered Duty Paid Container Yard)',
      sorter: (a, b) =>
        AuctionTableUtils.checkIncotermByCode(a, b, 'DDPCY')
          ? findBidIncotermByCode(a.bids[0].bidIncoterms, 'DDPCY').amount -
            findBidIncotermByCode(b.bids[0].bidIncoterms, 'DDPCY').amount
          : 0,
      render: ({ bids }) => {
        const bid = findBidIncotermByCode(bids[0].bidIncoterms, 'DDPCY');
        return !bid ? (
          '-'
        ) : (
          <>
            <>{getAmountDisplayText(bid.amount)}</>
            {bid.isCalculated && (
              <Tooltip
                title={'Estimated ' + bid.code + ' value'}
                placement='bottomLeft'
              >
                <span className='estimate-pop'>
                  <InfoCircleOutlined />
                </span>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];
}

function getAuctionWinnerColumns() {
  let acceptedBids = this.props.auctionWinners.map((auctionWinner) => {
    return {
      id: auctionWinner.bidId,
      bidIncoterms: [
        { incoterm: auctionWinner.incoterm, amount: auctionWinner.bidValue },
      ],
    };
  });

  return [
    {
      title: 'Rank No.',
      render: ({ bidId }) => getRank(bidId, acceptedBids),
      sorter: (a, b) =>
        getRank(a.bidId, acceptedBids) - getRank(b.bidId, acceptedBids),
    },
    {
      title: 'Seller Name',
      filterMultiple: false,
      filters: TableFilterUtils.getFilterDropdownValues(
        this.props.allBids.biddingCompanies,
        'country'
      ),
      onFilter: (value, record) => record.company.country === value,
      render: ({ company }) => (
        <Tooltip title='Click to view quote history chart'>
          <span
            className='seller-clickable'
            onClick={() => {
              this.setState({
                bidHistoryVisible: true,
                selectedSeller: company,
              });
            }}
          >
            {company.name}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Final Quote',
      sorter: (a, b) => a.bidValue - b.bidValue,
      render: ({ incoterm, bidValue }) =>
        getBidDisplayText(bidValue, incoterm.code),
    },
    {
      title: 'No. of FCL Awarded',
      dataIndex: 'totalFCL',
    },
    {
      title: 'Savings (in %)',
      render: ({ bidValue }) => {
        return getSavingsDisplayText(
          AuctionTableUtils.getSavings(this.props.targetPrice, bidValue)
        );
      },
      sorter: (a, b) => b.bidValue - a.bidValue,
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => {
        let winnerAuctionProforma = find(
          this.props.auctionProforma,
          function (o) {
            return o.payTo.id === record.company.id;
          }
        );
        let doesWinnerHaveMultipleProformas =
          this.props.auctionProforma &&
          this.props.auctionProforma.filter(
            (proforma) => proforma.auctionWinnerId === record.id
          ).length > 1;

        let editTermsButton = (
          <Button
            className='heading-cta accept-cta'
            type='primary'
            onClick={() =>
              this.props.showEditPlannedOrdersAssignmentModal(
                record,
                StringConstants.BID_ACTIONS.EDIT
              )
            }
            disabled={
              (winnerAuctionProforma &&
                winnerAuctionProforma.status ===
                  StringConstants.PROFORMA_STATUS.CONTRACT_GENERATED) ||
              doesWinnerHaveMultipleProformas
            }
          >
            Edit Terms
          </Button>
        );
        let rejectQuoteButton = (
          <Button
            className='heading-cta cancel-button'
            onClick={() => this.props.rejectBid(record.id)}
            disabled={
              winnerAuctionProforma &&
              winnerAuctionProforma.status ===
                StringConstants.PROFORMA_STATUS.CONTRACT_GENERATED
            }
          >
            Reject Quote
          </Button>
        );

        return (
          AuctionUtils.isPendingAuction(this.props.auctionDetails.status) && (
            <Space size='middle' align='center'>
              {isUserImporter() &&
                (isUserPartOfTeam(StringConstants.TEAMS.EXECUTIVE) ||
                  isUserPartOfTeam(StringConstants.TEAMS.SOURCING)) && (
                  <Button
                    className='heading-cta negotiate-cta'
                    icon={
                      <img
                        alt='negotiate  '
                        src={
                          require('../../assets/images/negotiate.png').default
                        }
                      />
                    }
                    onClick={() =>
                      this.showChatPopup(
                        record.company.id,
                        this.props.match.params.id
                      )
                    }
                  >
                    Negotiate
                  </Button>
                )}
              {isUserImporter() &&
                isUserManagerOfTeam(StringConstants.TEAMS.SOURCING) && (
                  <>
                    {winnerAuctionProforma &&
                    winnerAuctionProforma.status ===
                      StringConstants.PROFORMA_STATUS.CONTRACT_GENERATED ? (
                      <Tooltip title='Contract has been generated'>
                        {editTermsButton}
                      </Tooltip>
                    ) : doesWinnerHaveMultipleProformas ? (
                      <Tooltip title='Proforma has been split'>
                        {editTermsButton}
                      </Tooltip>
                    ) : (
                      editTermsButton
                    )}
                    {winnerAuctionProforma &&
                    winnerAuctionProforma.status ===
                      StringConstants.PROFORMA_STATUS.CONTRACT_GENERATED ? (
                      <Tooltip title='Contract has been generated'>
                        {rejectQuoteButton}
                      </Tooltip>
                    ) : (
                      rejectQuoteButton
                    )}
                  </>
                )}
            </Space>
          )
        );
      },
    },
  ];
}

const AuctionTableManager = {
  getActiveAuctionColumnsForImporter,
  getPendingAuctionColumnsForImporters,
  getDraftAuctionColumnsForImporters,
  getHistoryAuctionColumnsForImporters,
  getInvitedAuctionColumnsForPlants,
  getActiveAuctionColumnsForPlants,
  getPendingAuctionColumnsForPlants,
  getHistoryAuctionColumnsForPlants,
  getAuctionRevenueColumns,
  getColumnsForSpecificationInventory,
  getAuctionParticipantColumnsForImporters,
  getAuctionHistoryColumnsForImporters,
  getIncotermComparisonColumnsForImporters,
  getAuctionWinnerColumns,
  getColumnsForPlantOverview,
};

export default AuctionTableManager;
