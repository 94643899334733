const RouteConstants = {
  //Unsecured General Screens
  LOGIN: '/',
  AUTH0_LOGIN: '/sso',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:token',
  REDIRECT: '/redirect',

  //Accept Invite
  USER_ACCEPT_INVITE: '/invite/:token/accept',

  //Dashboard
  DASHBOARD: '/dashboard',

  // Planned Orders
  PLANNED_ORDERS: '/planned-orders',
  PLANNED_ORDER_DETAIL: '/planned-orders/:id',

  // Auctions
  AUCTIONS: '/rfqs',
  AUCTION_STEP_1: '/rfqs/create/:id/review',
  AUCTION_STEP_2: '/rfqs/create/:id/plants',
  AUCTION_STEP_3: '/rfqs/create/:id/verify',
  AUCTION: '/rfqs/:id',
  AUCTION_DETAILS: '/rfqs/:id/details',
  BID_DETAILS: '/rfqs/:id/plant/:plantId/quote',
  PLACE_BID: '/rfqs/:id/quote',
  SIMULATE_BID: '/rfq/:id/admin/quote',

  // Specifications
  SPECIFICATIONS: '/specifications',
  SPECIFICATION_DETAIL: '/specifications/:id',
  SPECIFICATION_VERSION_DETAIL: '/specifications/:id/version/:version',
  SPECIFICATION_HOMEPAGE: '/specifications/:id/overview',
  CREATE_SPECIFICATION: '/specifications/create',
  EDIT_SPECIFICATION: '/specifications/:id/edit',
  CLONE_SPECIFICATION: '/specifications/:id/clone',

  // Proformas
  PROFORMAS: '/proformas',
  PROFORMA_REVIEW: '/rfqs/:id/proforma',
  PROFORMA_DETAIL: '/proformas/:id',
  PROFORMA_VERSION_DETAIL: '/proforma/:id/version/:version',
  CREATE_DIRECT_PROFORMA: '/proforma/create',

  // Processing Plants
  PROCESSING_PLANTS: '/processing-plants',
  TEAM_MEMBERS: '/teams',
  PLANT_HOMEPAGE: '/processing-plants/:id',

  // Reports
  REPORT: '/reports',

  //Profile
  USER_PROFILE: '/profile',
  USER_PREFERENCE: '/profile/preference',
  USER_CHANGE_PASSWORD: '/profile/change-password',

  //Chat
  CHAT: '/chat',
  CHAT_DETAIL: '/chat/:id',

  //Terms & Conditions
  TERMS_AND_CONDITIONS: '/settings/terms-and-conditions',

  // Purchase Orders
  PURCHASE_ORDERS: '/purchase-orders',
  PURCHASE_ORDER_DETAIL: '/purchase-orders/:id',
  NAV_PURCHASE_ORDER_DETAIL: '/purchase-orders/nav/:id',
  PURCHASE_ORDER_ANALYSIS: '/purchase-order/analysis',

  //Company Profile
  COMPANY_PROFILE: '/company/profile',
};

export default RouteConstants;
