import RouteConstants from './RouteConstants';

const ChatInfoConstants = {
  RFQ: [
    {
      key: 'id',
      field: 'auctionId',
      type: 'link',
      title: 'RFQ ID: ',
      linkTo: RouteConstants.AUCTION,
    },
    {
      key: 'endsAt',
      field: 'auctionMeta.endAt',
      type: 'date',
      title: 'Ends At: ',
    },
    {
      key: 'totalFCL',
      field: 'auctionMeta.totalFCL',
      type: 'text',
      title: 'Total FCL: ',
    },
  ],
  Specification: [
    {
      key: 'id',
      field: 'specificationMeta.id',
      type: 'link',
      title: 'Specification ID: ',
      linkTo: RouteConstants.SPECIFICATION_DETAIL,
    },
    {
      key: 'description',
      field: 'specificationMeta.itemDescription',
      type: 'text',
      title: 'Description: ',
    },
  ],
};

export default ChatInfoConstants;
