import { Col, Row } from 'antd';
import React, { Component } from 'react';
import { isUserImporter, isUserPartOfTeam } from 'src/helpers/user';

import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import LoadingComponent from 'src/components/common/LoadingComponent';
import NoDataComponent from 'src/components/common/NoDataComponent';
import SecureComponent from 'src/components/common/SecureComponent';
import Specification from '../../components/specification/Specification';
import SpecificationService from 'src/services/SpecificationService';
import StringConstants from 'src/constants/StringConstants';
import { isEmpty } from 'lodash';

class SpecificationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      specification: {},
      specID: null,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.init();
  }

  init() {
    const specID = this.props.match.params.id;
    SpecificationService.getSpecificationDetail(specID)
      .then((response) => {
        this.setState({ loading: false, specification: response.data, specID });
      })
      .catch((err) => {
        this.setState({ loading: false, specification: {}, specID });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined) {
      return false;
    }
    if (this.state.specID !== this.props.match.params.id) {
      if (!prevState.loading) this.setState({ loading: true });
      this.init();
    }
  }

  render() {
    const { specification, specID, loading } = this.state;
    if (loading) return <LoadingComponent />;
    return isEmpty(specification) ? (
      <NoDataComponent
        title='Specification not found'
        tip={`Specification (${specID}) does not exist`}
        fullScreen={true}
        headerMetadata={true}
      />
    ) : (
      <>
        <HeaderMetadata
          title={`${specID} ${specification.itemDescription}| Specification`}
          description={specification.itemDescription}
        />
        <Row>
          <Col span={24} xs={24} lg={24} xl={24}>
            <Specification
              data={specification}
              openAllPanels={true}
              showHeading={true}
              showVersionHistory={true}
              showOverview={
                isUserImporter() &&
                (isUserPartOfTeam(StringConstants.TEAMS.EXECUTIVE) ||
                  isUserPartOfTeam(StringConstants.TEAMS.SOURCING))
              }
              showActions={
                specification.status ===
                StringConstants.SPECIFICATION_STATUS.ACTIVE
              }
              showUnarchiveAction={
                specification.status ===
                StringConstants.SPECIFICATION_STATUS.ARCHIVED
              }
              showUploadedFiles={true}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default SecureComponent(
  SpecificationDetail,
  [StringConstants.COMPANY_TYPES.IMPORTER],
  [
    ...StringConstants.DEFAULT_TEAM_PAGE_ACCESS,
    {
      name: StringConstants.TEAMS.ACCOUNT_MANAGERS,
    },
  ]
);
