import '../../Auction.css';

import { isEmpty, sum } from 'lodash';
import { isUserImporter, isUserPartOfTeam } from 'src/helpers/user';

import AuctionService from 'src/services/AuctionService';
import AuctionUtils from 'src/utils/AuctionUtils';
import { Link } from 'react-router-dom';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import StringConstants from '../../../../constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { getFormattedCurrentDate } from 'src/utils/DisplayUtils';

const { AUCTION_STATUS } = StringConstants;

class TotalAuctionOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      auctionCount: {},
      totalAuctions: 0,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    AuctionService.getAuctionCountForDashboard()
      .then((response) => {
        const auctionCounts = response.data,
          totalAuctions = !isEmpty(auctionCounts)
            ? sum(Object.values(auctionCounts))
            : 0,
          auctionData = {};

        AuctionUtils.getAuctionCountForCard(
          AUCTION_STATUS.DRAFT,
          auctionCounts,
          auctionData
        );
        AuctionUtils.getAuctionCountForCard(
          AUCTION_STATUS.PENDING,
          auctionCounts,
          auctionData
        );
        AuctionUtils.getAuctionCountForCard(
          AUCTION_STATUS.ACTIVE,
          auctionCounts,
          auctionData
        );

        this.setState({
          loading: false,
          auctionCount: auctionData,
          totalAuctions,
        });
      })
      .catch((err) => {
        this.setState({ loading: false, auctionCount: {}, totalAuctions: 0 });
      });
  }

  render() {
    const { auctionCount, totalAuctions, loading } = this.state;

    return (
      <div className='ca-card-dark h-100'>
        <div className='total-auction-count'>
          <p className='total-auction-count-no'>{totalAuctions}</p>
          <p className='total-auction-count-text'>Total RFQs</p>
        </div>
        <div className='auction-progress-bar'>
          {!loading &&
          isUserImporter() &&
          isUserPartOfTeam(StringConstants.TEAMS.SOURCING) ? (
            <>
              <div className='auction-progress-bar-title'>
                {auctionCount[AUCTION_STATUS.DRAFT].count > 0 && (
                  <Link
                    to={UrlGenerator.addQueryParamsToUrl(
                      RouteConstants.AUCTIONS,
                      { status: AUCTION_STATUS.DRAFT }
                    )}
                    style={{
                      width: `${
                        auctionCount[AUCTION_STATUS.DRAFT].percentage
                      }%`,
                    }}
                  >
                    <span>Draft</span>
                  </Link>
                )}

                {auctionCount[AUCTION_STATUS.ACTIVE].count > 0 && (
                  <Link
                    to={UrlGenerator.addQueryParamsToUrl(
                      RouteConstants.AUCTIONS,
                      { status: AUCTION_STATUS.ACTIVE }
                    )}
                    style={{
                      width: `${
                        auctionCount[AUCTION_STATUS.ACTIVE].percentage
                      }%`,
                      textAlign: 'center',
                    }}
                  >
                    <span>Active</span>
                  </Link>
                )}

                {auctionCount[AUCTION_STATUS.PENDING].count > 0 && (
                  <Link
                    to={UrlGenerator.addQueryParamsToUrl(
                      RouteConstants.AUCTIONS,
                      { status: AUCTION_STATUS.PENDING }
                    )}
                    style={{
                      width: `${
                        auctionCount[AUCTION_STATUS.PENDING].percentage
                      }%`,
                      textAlign: 'right',
                    }}
                  >
                    <span>Pending</span>
                  </Link>
                )}
              </div>
              <div className='total-auction-progress-bar'>
                {auctionCount[AUCTION_STATUS.DRAFT].count > 0 && (
                  <Link
                    to={UrlGenerator.addQueryParamsToUrl(
                      RouteConstants.AUCTIONS,
                      { status: AUCTION_STATUS.DRAFT }
                    )}
                    className='draft-auction-bar'
                    style={{
                      width: `${
                        auctionCount[AUCTION_STATUS.DRAFT].percentage
                      }%`,
                    }}
                  >
                    <div className='draft-auction-bar-h'>
                      <p className='bar-number'>
                        {auctionCount['DRAFT'].count}
                      </p>
                    </div>
                  </Link>
                )}
                {auctionCount[AUCTION_STATUS.ACTIVE].count > 0 && (
                  <Link
                    to={UrlGenerator.addQueryParamsToUrl(
                      RouteConstants.AUCTIONS,
                      { status: AUCTION_STATUS.ACTIVE }
                    )}
                    className='open-auction-bar'
                    style={{
                      width: `${
                        auctionCount[AUCTION_STATUS.ACTIVE].percentage
                      }%`,
                    }}
                  >
                    <div className='open-auction-bar-h'>
                      <p className='bar-number'>
                        {auctionCount[AUCTION_STATUS.ACTIVE].count}
                      </p>
                    </div>
                  </Link>
                )}
                {auctionCount[AUCTION_STATUS.PENDING].count > 0 && (
                  <Link
                    to={UrlGenerator.addQueryParamsToUrl(
                      RouteConstants.AUCTIONS,
                      { status: AUCTION_STATUS.PENDING }
                    )}
                    className='active-auction-bar'
                    style={{
                      width: `${
                        auctionCount[AUCTION_STATUS.PENDING].percentage
                      }%`,
                    }}
                  >
                    <div className='active-auction-bar-h'>
                      <p className='bar-number'>
                        {auctionCount[AUCTION_STATUS.PENDING].count}
                      </p>
                    </div>
                  </Link>
                )}
              </div>
            </>
          ) : null}
        </div>
        {isUserImporter() && isUserPartOfTeam(StringConstants.TEAMS.SOURCING) && (
          <div className='total-auction-date'>
            <p>{getFormattedCurrentDate()}</p>
          </div>
        )}
      </div>
    );
  }
}
export default TotalAuctionOverview;
