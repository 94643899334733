import React from 'react';

const Card = (props) => {
  if (props.fullHeight)
    return (
      <div
        className={`ca-card-light h-100 ${
          props.className ? props.className : ''
        }`}
      >
        {props.children}
      </div>
    );
  else
    return (
      <div
        className={`ca-card-light mb-15 rscb ${
          props.className ? props.className : ''
        }`}
      >
        {props.children}
      </div>
    );
};

export default Card;
