import './Reminder.css';

import React from 'react';
import ReminderRow from './reminderOverview/ReminderRow';
import Title from '../common/typography/Title';
import { fill } from 'lodash';

class ReminderOverview extends React.Component {
  render() {
    return (
      <>
        <div className='ca-card-light h-100'>
          <Title title='Reminders' badge='02' />

          <div className='reminder-o-list'>
            {/*reminder list card  */}
            {fill(Array(2), 0).map((value, index) => (
              <ReminderRow key={index} id={index} value={value} />
            ))}
            {/*end reminder list card  */}
          </div>
        </div>
      </>
    );
  }
}
export default ReminderOverview;
