import { Col, Row } from 'antd';

import AuctionOverview from 'src/components/auction/buyer/AuctionOverview';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import React from 'react';
import RevenueTabs from 'src/components/revenue/RevenueTabs';
import TotalAuctionCard from 'src/components/auction/buyer/superUser/TotalAuctionsCard';

class SuperUserDashboard extends React.Component {
  render() {
    return (
      <>
        <HeaderMetadata
          title='Super User Dashboard | Carava'
          description='Manage Auctions, Planned Orders easily from this dashbaord'
        />
        <Row type='flex' gutter={15}>
          <Col className='' span={24} xs={24} lg={24} xl={24}>
            <p className='pagetitle'>Super User Dashboard</p>
          </Col>
          <Col className='mb-15' span={10} xs={10} lg={4} xl={4}>
            <TotalAuctionCard />
          </Col>
          <Col className='mb-15' span={10} xs={10} lg={20} xl={20}>
            <RevenueTabs />
          </Col>
        </Row>
        <Row gutter={15}>
          <Col className='mt-15' span={10} xs={10} lg={24} xl={24}>
            <AuctionOverview fullHeight={true} />
          </Col>
        </Row>
      </>
    );
  }
}
export default SuperUserDashboard;
