import { Button, Space } from 'antd';
import { findIndex, isEqual } from 'lodash';

import AddOrEditSeafoodItemModal from 'src/modals/seafoodList/AddOrEditSeafoodItemModal';
import ConfirmationModal from 'src/modals/common/ConfirmationModal';
import { PlusCircleFilled } from '@ant-design/icons';
import React from 'react';

class SeafoodListSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSeafoodItem: undefined,
      addSeafoodItemModalVisible: false,
      editSeafoodItemModalVisible: false,
      removeSeafoodItemModalVisible: false,
      originalSeafoodItems: [],
      seafoodItems: [],
    };
  }

  componentDidMount() {
    const { data } = this.props;
    this.setState({ originalSeafoodItems: data, seafoodItems: data });
  }

  componentDidUpdate() {
    const { data } = this.props;
    const { originalSeafoodItems } = this.state;

    if (!isEqual(data, originalSeafoodItems))
      this.setState({ originalSeafoodItems: data, seafoodItems: data });
  }

  getSeafoodItemIndex = () => {
    const { seafoodItems, selectedSeafoodItem } = this.state;
    let selectedSeafoodItemIndex = findIndex(seafoodItems, function (o) {
      return o.id === selectedSeafoodItem.id;
    });
    return selectedSeafoodItemIndex;
  };

  addSeafoodItem = (seafoodItem) => {
    const { seafoodItems } = this.state;

    let newSeafoodItems = [...seafoodItems];
    seafoodItem.certifications = seafoodItem.certifications.join(', ');
    newSeafoodItems.push(seafoodItem);

    this.setState({
      seafoodItems: newSeafoodItems,
      addSeafoodItemModalVisible: false,
    });
  };

  editSeafoodItem = (certifications) => {
    const { seafoodItems } = this.state;
    let selectedSeafoodItemIndex = this.getSeafoodItemIndex();

    let newSeafoodItems = [...seafoodItems];
    newSeafoodItems[selectedSeafoodItemIndex] = {
      ...seafoodItems[selectedSeafoodItemIndex],
      certifications: certifications.join(', '),
    };

    this.setState({
      seafoodItems: newSeafoodItems,
      editSeafoodItemModalVisible: false,
    });
  };

  removeSeafoodItem = () => {
    const { seafoodItems } = this.state;
    let selectedSeafoodItemIndex = this.getSeafoodItemIndex();

    let newSeafoodItems = [...seafoodItems];
    newSeafoodItems.splice(selectedSeafoodItemIndex, 1);
    this.setState({
      seafoodItems: newSeafoodItems,
      removeSeafoodItemModalVisible: false,
    });
  };

  getData = () => {
    const { seafoodItems } = this.state;
    return {
      seaFoodList: seafoodItems.map((item) => {
        return {
          id: item.id,
          certifications: item.certifications,
        };
      }),
    };
  };

  render() {
    const { isEdit } = this.props;
    const {
      seafoodItems,
      selectedSeafoodItem,
      addSeafoodItemModalVisible,
      editSeafoodItemModalVisible,
      removeSeafoodItemModalVisible,
    } = this.state;

    return (
      <div>
        <span className='title-heading'> Seafood List</span>
        {isEdit ? (
          <Button
            type='link'
            className='add-seafood-list-button'
            onClick={() => this.setState({ addSeafoodItemModalVisible: true })}
          >
            <PlusCircleFilled />
          </Button>
        ) : null}
        <ul className='rscb-items'>
          {seafoodItems.map((item) => {
            return (
              <li key={item.id}>
                <span className='left-item'>
                  {item.commonName
                    ? item.commonName
                    : item.acceptableMarketName}{' '}
                </span>
                {isEdit ? (
                  <span className='right-item'>
                    {item.certifications}
                    <Space className='seafood-item-actions'>
                      <Button
                        className='primary-button'
                        type='primary'
                        onClick={() =>
                          this.setState({
                            editSeafoodItemModalVisible: true,
                            selectedSeafoodItem: item,
                          })
                        }
                      >
                        Edit
                      </Button>
                      <Button
                        className='cancel-button'
                        onClick={() =>
                          this.setState({
                            removeSeafoodItemModalVisible: true,
                            selectedSeafoodItem: item,
                          })
                        }
                      >
                        Remove
                      </Button>
                    </Space>
                  </span>
                ) : (
                  <span className='right-item'>{item.certifications}</span>
                )}
              </li>
            );
          })}
        </ul>
        {addSeafoodItemModalVisible && (
          <AddOrEditSeafoodItemModal
            visible={addSeafoodItemModalVisible}
            onSubmit={(seafoodItem) => this.addSeafoodItem(seafoodItem)}
            onCancel={() =>
              this.setState({ addSeafoodItemModalVisible: false })
            }
            existingSeafoodItems={seafoodItems}
          />
        )}
        {editSeafoodItemModalVisible && (
          <AddOrEditSeafoodItemModal
            visible={editSeafoodItemModalVisible}
            seafoodItem={{
              ...selectedSeafoodItem,
            }}
            onSubmit={(certifications) => this.editSeafoodItem(certifications)}
            onCancel={() =>
              this.setState({ editSeafoodItemModalVisible: false })
            }
            isEdit={true}
          />
        )}
        {removeSeafoodItemModalVisible && (
          <ConfirmationModal
            visible={removeSeafoodItemModalVisible}
            negativeAction={true}
            title={'Remove ' + selectedSeafoodItem.commonName}
            description={
              'This action will remove the item from your seafood list'
            }
            onSubmit={() => this.removeSeafoodItem()}
            onCancel={() =>
              this.setState({ removeSeafoodItemModalVisible: false })
            }
          />
        )}
      </div>
    );
  }
}

export default SeafoodListSection;
