import ApiManager from 'src/api/ApiManager';
import HttpConstants from 'src/constants/HttpConstants';
import UrlConstants from 'src/constants/UrlConstants';

const SpecificationService = {
  getAllSpecifications: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_SPECIFICATIONS.USECASE,
      HttpConstants.GET_METHOD,
      data
    );
  },
  getSpecificationDetail: (specificationId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_SPECIFICATION_DETAIL.USECASE,
      HttpConstants.GET_METHOD,
      { ':specId': specificationId }
    );
  },
  getSpecificationDetailForVersion: (specificationId, version) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_SPECIFICATION_DETAIL_FOR_VERSION.USECASE,
      HttpConstants.GET_METHOD,
      { ':specId': specificationId, ':version': version }
    );
  },
  getSpecificationVersions: (specificationId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_SPECIFICATION_VERSIONS.USECASE,
      HttpConstants.GET_METHOD,
      { ':specId': specificationId }
    );
  },
  downloadSpecificationPDF: (specificationId, data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.DOWNLOAD_SPECIFICATION.USECASE,
      HttpConstants.GET_BLOB_METHOD,
      { ':specId': specificationId, ...data }
    );
  },
  downloadSpecificationPDFForVersion: (specificationId, version) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.DOWNLOAD_SPECIFICATION_FOR_VERSION.USECASE,
      HttpConstants.GET_BLOB_METHOD,
      {
        ':version': version,
        ':specId': specificationId,
      }
    );
  },
  getItemHistoricalPrice: (specificationId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_ITEM_HISTORICAL_PRICE.USECASE,
      HttpConstants.GET_METHOD,
      {
        ':specId': specificationId,
      }
    );
  },
  getSpecificationOverviewDetails: (specificationId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_SPECIFICATION_OVERVIEW.USECASE,
      HttpConstants.GET_METHOD,
      { ':id': specificationId }
    );
  },
  createSpecification: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.CREATE_SPECIFICATION.USECASE,
      HttpConstants.POST_METHOD,
      data
    );
  },
  updateSpecification: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_SPECIFICATION.USECASE,
      HttpConstants.PATCH_METHOD,

      data
    );
  },
  deleteSpecification: (specificationId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.DELETE_SPECIFICATION.USECASE,
      HttpConstants.DELETE_METHOD,
      {
        ':specId': specificationId,
      }
    );
  },
  archiveSpecification: (specificationId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.ARCHIVE_SPECIFICATION.USECASE,
      HttpConstants.POST_METHOD,
      {
        ':specId': specificationId,
      }
    );
  },
  unarchiveSpecification: (specificationId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UNARCHIVE_SPECIFICATION.USECASE,
      HttpConstants.POST_METHOD,
      {
        ':specId': specificationId,
      }
    );
  },
};

export default SpecificationService;
