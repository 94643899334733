import { Button, Col, Input, Rate, Row, Space, Tag } from 'antd';
import { isEmpty, isNull, isUndefined, map, uniq } from 'lodash';
import { isUserImporter, isUserPartOfTeam } from 'src/helpers/user';

import ApiUtils from 'src/utils/ApiUtils';
import CompanyService from 'src/services/CompanyService';
import React from 'react';
import StringConstants from 'src/constants/StringConstants';
import Title from '../common/typography/Title';
import { toast } from 'react-toastify';

class ProcessingPlantDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notesEditable: false,
      companyNote:
        this.props.data &&
        this.props.data.companyNote &&
        this.props.data.companyNote.notes,
      savingNote: false,
    };
  }

  saveCompanyNote = async () => {
    const { companyNote } = this.state;
    const { data } = this.props;

    const request = {
      plantId: data.company.id,
      notes: companyNote,
    };

    if (isEmpty(companyNote)) toast.warning('Notes cannot be empty');

    this.setState({ savingNote: true });

    if (data.companyNote) {
      const request = {
        notes: companyNote,
      };
      CompanyService.editCompanyNote(data.companyNote.id, request)
        .then((response) => {
          toast.success('Edited note successfully');
          this.setState({ notesEditable: false, savingNote: false }, () =>
            this.props.refreshData()
          );
        })
        .catch((err) => {
          let errorMessage = ApiUtils.getErrorMessage(err);
          toast.error('Failed to edit note - ' + errorMessage);
          this.setState({
            notesEditable: false,
            savingNote: false,
            companyNote:
              this.props.data &&
              this.props.data.companyNote &&
              this.props.data.companyNote.notes,
          });
        });
    } else {
      CompanyService.saveCompanyNote(request)
        .then((response) => {
          toast.success('Saved note successfully');
          this.setState({ notesEditable: false, savingNote: false }, () =>
            this.props.refreshData()
          );
        })
        .catch((err) => {
          let errorMessage = ApiUtils.getErrorMessage(err);
          toast.error('Failed to save note - ' + errorMessage);
          this.setState({
            notesEditable: false,
            savingNote: false,
            companyNote:
              this.props.data &&
              this.props.data.companyNote &&
              this.props.data.companyNote.notes,
          });
        });
    }
  };

  removeCompanyNote = () => {
    const { data } = this.props;
    const { companyNote } = data;
    const id = companyNote.id;
    this.setState({ removingNote: true });

    CompanyService.removeCompanyNote(id)
      .then((response) => {
        toast.success('Removed note successfully');
        this.setState(
          { notesEditable: false, removingNote: false, companyNote: null },
          () => this.props.refreshData()
        );
      })
      .catch((err) => {
        let errorMessage = ApiUtils.getErrorMessage(err);
        toast.error('Failed to remove note - ' + errorMessage);
        this.setState({ removingNote: false });
      });
  };

  render() {
    const { notesEditable, companyNote, savingNote, removingNote } = this.state;
    const { data, showAdditionalDetails, noteActionsEnabled } = this.props;
    return (
      <>
        <Title title={data && data.company && data.company.name} />
        <div className='mb-15'>
          {/* <p className='modal-title'>{data.name}</p> */}
          <p className='review-selection-detail'>
            <span>Notes :</span>

            {isUserImporter() &&
            (isUserPartOfTeam(StringConstants.TEAMS.SOURCING) ||
              isUserPartOfTeam(StringConstants.TEAMS.EXECUTIVE)) ? (
              notesEditable ? (
                <>
                  <Input
                    style={{ width: 'auto', marginLeft: 15, marginRight: 15 }}
                    placeholder='Enter notes for this plant'
                    value={companyNote}
                    onChange={(event) =>
                      this.setState({ companyNote: event.target.value })
                    }
                  />
                  <Space>
                    <Button
                      className='primary-button'
                      onClick={() => this.saveCompanyNote()}
                      disabled={savingNote || isEmpty(companyNote)}
                    >
                      Save
                    </Button>
                    <Button
                      className='cancel-button'
                      type='primary'
                      onClick={() => {
                        this.setState({
                          notesEditable: false,
                          companyNote:
                            this.props.data &&
                            this.props.data.companyNote &&
                            this.props.data.companyNote.notes,
                        });
                      }}
                      disabled={savingNote}
                    >
                      Cancel
                    </Button>
                  </Space>
                </>
              ) : (
                <>
                  <span className='ml-15 mr-15'>
                    {companyNote ? companyNote : 'None'}
                  </span>
                  {noteActionsEnabled ? (
                    <Space>
                      <Button
                        className='primary-button'
                        type='primary'
                        onClick={() => {
                          this.setState({ notesEditable: true });
                        }}
                        disabled={removingNote}
                      >
                        Edit
                      </Button>
                      <Button
                        className='cancel-button'
                        onClick={() => this.removeCompanyNote()}
                        disabled={removingNote || !companyNote}
                      >
                        Remove
                      </Button>
                    </Space>
                  ) : null}
                </>
              )
            ) : (
              <span className='ml-15 mr-15'>
                {companyNote ? companyNote : 'None'}
              </span>
            )}
          </p>
          <p className='review-selection-detail'>
            Country :{' '}
            <span>{data && data.company && data.company.country}</span>
          </p>
          <p className='review-selection-detail species-details-item'>
            Species Capabilities :{' '}
            <Space>
              {data &&
                data.company &&
                uniq(map(data.company.seaFoodList, 'commonName')).map(
                  (seafoodItem, index) => {
                    return <Tag key={index}>{seafoodItem}</Tag>;
                  }
                )}
            </Space>
          </p>
        </div>
        <Row>
          <Col className='' span={24} xs={24} lg={8} xl={8}>
            <p className='text1'>
              Total LBS Sold to Date
              <br />
              through Carava
            </p>
            <p className='text1-desc'>1,849,301</p>
          </Col>
          <Col className='' span={24} xs={24} lg={8} xl={8}>
            <p className='text1'>
              Total $ Sold to Date
              <br />
              through Carava
            </p>
            <p className='text1-desc'>$8,321,855</p>
          </Col>
          <Col className='' span={24} xs={24} lg={8} xl={8}>
            <p className='text1'>
              Delivery
              <br />
              Score
            </p>
            <p className='modal-rate'>
              <Rate disabled allowHalf defaultValue={4.5} />
              <span className='modal-rate-text'>4.50/5</span>
            </p>
          </Col>
        </Row>
        {showAdditionalDetails && (
          <>
            <Row>
              <Col span={24} xs={24} lg={24} xl={24}>
                <div className='plain-divide'></div>
                <p className='sub-heading'> Address </p>
              </Col>
              <Col
                className='rscb-details mt-15 mb-15'
                span={24}
                xs={24}
                lg={24}
                xl={24}
              >
                <ul className='rscb-items'>
                  <li>
                    <span className='left-item'>Name:</span>
                    <span className='right-item'>
                      {data && data.company && data.company.address ? (
                        <>
                          {!isUndefined(data.company.address.line1) && (
                            <span> {data.company.address.line1} </span>
                          )}
                          {!isUndefined(data.company.address.line2) && (
                            <span> {', ' + data.company.address.line2} </span>
                          )}
                          {!isUndefined(data.company.address.line3) && (
                            <span> {', ' + data.company.address.line3} </span>
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </span>
                  </li>
                  <li>
                    <span className='left-item'>Zipcode:</span>
                    <span className='right-item'>
                      {data && data.company && data.company.address ? (
                        <>
                          <span> {data.company.address.zipcode} </span>
                        </>
                      ) : (
                        '-'
                      )}
                    </span>
                  </li>
                  <li>
                    <span className='left-item'>City:</span>
                    <span className='right-item'>
                      {data && data.company && data.company.address ? (
                        <>
                          <span> {data.company.address.city} </span>
                        </>
                      ) : (
                        '-'
                      )}
                    </span>
                  </li>
                  <li>
                    <span className='left-item'>State:</span>
                    <span className='right-item'>
                      {data && data.company && data.company.address ? (
                        <>
                          <span> {data.company.address.state} </span>
                        </>
                      ) : (
                        '-'
                      )}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>

            <Row>
              <Col span={24} xs={24} lg={24} xl={24}>
                <div className='plain-divide'></div>
                <p className='sub-heading'>
                  {!isNull(data.company.mainCompany) ||
                  !isEmpty(data.company.subCompanies)
                    ? !isNull(data.company.mainCompany)
                      ? 'Represented by: '
                      : 'Represents'
                    : 'No representee / representative set'}
                </p>
              </Col>

              {!isNull(data.company.mainCompany) ||
              !isEmpty(data.company.subCompanies) ? (
                <Col
                  className='rscb-details mt-15 mb-15'
                  span={24}
                  xs={24}
                  lg={24}
                  xl={24}
                >
                  <ul className='rscb-items'>
                    {!isNull(data.company.mainCompany) && (
                      <li>
                        <span className='left-item'>Plant: </span>
                        <span className='right-item'>
                          {data.company && data.company.mainCompany
                            ? data.company.mainCompany.name
                            : 'N/A'}
                        </span>
                      </li>
                    )}
                    {!isEmpty(data.company.subCompanies) && (
                      <li>
                        <span className='left-item'>Plants: </span>
                        <span className='right-item'>
                          {data.company &&
                          data.company.subCompanies &&
                          data.company.subCompanies.length > 0
                            ? data.company.subCompanies
                                .map((company) => company.name)
                                .join(', ')
                            : 'N/A'}
                        </span>
                      </li>
                    )}
                  </ul>
                </Col>
              ) : null}
            </Row>
          </>
        )}
      </>
    );
  }
}

export default ProcessingPlantDetail;
