import './HistoricalTrend.css';

import { find, isEqual, isUndefined, orderBy } from 'lodash';
import {
  findBidIncotermByCode,
  formHistoricalPriceChartData,
  getDisplayIncoterm,
  getLatestBidByParticipant,
} from 'src/utils/BidUtils';
import { hashCode, intToRGB } from 'src/utils/misc/ColorUtils';

import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';
import React from 'react';
import StringConstants from 'src/constants/StringConstants';
import { displayDecimal } from 'src/utils/DisplayUtils';
import moment from 'moment';

class HistoricalTrend extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: props.item,
          data: formHistoricalPriceChartData(props.historicalPrice),
        },
      ],
      options: {
        chart: {
          animations: {
            enabled: true,
            easing: 'linear',
            speed: 400,
          },
          id: 'area-datetime',
          type: 'area',
          height: 350,
          zoom: {
            show: false,
          },
          toolbar: {
            show: false,
          },
        },
        yaxis: [
          {
            show: true,
            labels: {
              style: {
                colors: '#838B99',
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-yaxis-label',
              },
            },
            axisTicks: {
              show: false,
            },

            min: this.getMinYAxisValue(),
            max: this.getMaxYAxisValue(),
          },
        ],
        stroke: {
          show: false,
          curve: 'straight',
        },
        annotations: {
          position: 'front',
          yaxis: [
            {
              y: this.props.auctionTargetPrice,
              strokeDashArray: 5,
              borderColor: '#ED474A',
              width: '100%',
              label: {
                show: true,
                text: displayDecimal(this.props.auctionTargetPrice),
                position: 'left',
                offsetX: 25,
                borderWidth: 0,
                style: {
                  fontSize: 12,
                  color: '#ED474A',
                },
              },
            },
            ...getLatestBidByParticipant(this.props.allBids.bids).bids.map(
              (bid, index) => {
                let biddingCompanies = this.props.allBids.biddingCompanies;
                let company = find(biddingCompanies, function (o) {
                  return o.id === bid.companyId;
                });
                let displayBid = getDisplayIncoterm(bid.bidIncoterms);
                return {
                  y: displayBid.amount,
                  strokeDashArray: 5,
                  borderColor: intToRGB(hashCode(company.name)),
                  width: '100%',
                  label: {
                    show: true,
                    text:
                      displayDecimal(displayBid.amount) +
                      ' / ' +
                      displayBid.code +
                      (' (' + company.name + ')'),
                    position: index % 2 === 0 ? 'right' : 'left',
                    offsetX: index % 2 === 0 ? -10 : 180,
                    borderWidth: 0,
                    style: {
                      fontSize: 12,
                      color: intToRGB(hashCode(company.name)),
                    },
                  },
                };
              }
            ),
          ],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: 'hollow',
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 6,
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy',
          },
          marker: {
            show: false,
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.1,
            opacityFrom: 0.1,
            opacityTo: 0.5,
            stops: [0, 100],
            gradientToColors: ['#fff'],
            colorStops: [
              [
                {
                  offset: 0,
                  color: '#eee',
                  opacity: 1,
                },
                {
                  offset: 0.6,
                  color: '#2170B866',
                  opacity: 50,
                },
                {
                  offset: 100,
                  color: '#fff',
                  opacity: 1,
                },
              ],
              [
                {
                  offset: 0,
                  color: '#fff',
                  opacity: 1,
                },
                {
                  offset: 50,
                  color: 'blue',
                  opacity: 0.75,
                },
                {
                  offset: 100,
                  color: '#ddd',
                  opacity: 1,
                },
              ],
            ],
          },
        },
        grid: {
          position: 'front',
          borderColor: '#e3e3e3',
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      selection: 'one_year',
    };
  }

  updateData(timeline) {
    this.setState({
      selection: timeline,
    });

    let sortedHistoricalPriceData = orderBy(
      this.props.historicalPrice,
      'postedAt'
    );

    switch (timeline) {
      case 'one_month':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date(moment().subtract(1, 'month')).getTime(),
          new Date().getTime()
        );
        break;
      case 'six_months':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date(moment().subtract(6, 'month')).getTime(),
          new Date().getTime()
        );
        break;
      case 'one_year':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date(moment().subtract(1, 'year')).getTime(),
          new Date().getTime()
        );
        break;
      case 'ytd':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date(moment().startOf('year')).getTime(),
          new Date().getTime()
        );
        break;
      case 'all':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          sortedHistoricalPriceData.length > 0
            ? new Date(moment(sortedHistoricalPriceData[0].postedAt)).getTime()
            : new Date(moment().startOf('year')).getTime(),
          sortedHistoricalPriceData.length > 0
            ? new Date(
                moment(
                  sortedHistoricalPriceData[
                    sortedHistoricalPriceData.length - 1
                  ].postedAt
                )
              ).getTime()
            : new Date().getTime()
        );
        break;
      default:
    }
  }

  getLowerReferenceValue = () => {
    let lowerReferenceValue = this.props.auctionTargetPrice;

    if (this.props.lowestBid) {
      lowerReferenceValue =
        lowerReferenceValue < this.props.lowestBid.amount
          ? lowerReferenceValue
          : this.props.lowestBid.amount;
    }

    return lowerReferenceValue;
  };

  getHigherReferenceValue = () => {
    let higherReferenceValue = this.props.auctionTargetPrice;

    let selectedIncoterm = this.props.selectedIncoterm;
    let acceptedBids = this.props.allBids.bids.filter(
      (bid) =>
        bid.status === StringConstants.BID_STATUS.SUBMITTED ||
        bid.status === StringConstants.BID_STATUS.ACCEPTED
    );
    let sortedBids = orderBy(
      acceptedBids,
      function (o) {
        return findBidIncotermByCode(o.bidIncoterms, selectedIncoterm)
          ? findBidIncotermByCode(o.bidIncoterms, selectedIncoterm).amount
          : 0;
      },
      'desc'
    );

    if (sortedBids.length > 0) {
      higherReferenceValue =
        higherReferenceValue >
        findBidIncotermByCode(sortedBids[0].bidIncoterms, selectedIncoterm)
          .amount
          ? higherReferenceValue
          : findBidIncotermByCode(sortedBids[0].bidIncoterms, selectedIncoterm)
              .amount;
    }

    return higherReferenceValue;
  };

  getMaxYAxisValue = () => {
    let sortedBids = orderBy(
      this.props.historicalPrice,
      function (o) {
        return o.finalPrice;
      },
      'desc'
    );

    let higherReferenceValue = this.getHigherReferenceValue();

    let anyBidAboveHigherReferenceValue = find(sortedBids, function (o) {
      return o.finalPrice > higherReferenceValue;
    });

    if (sortedBids.length === 0) return undefined;

    if (isUndefined(anyBidAboveHigherReferenceValue))
      return higherReferenceValue + 0.1;
    else
      return anyBidAboveHigherReferenceValue
        ? anyBidAboveHigherReferenceValue.finalPrice + 0.1
        : higherReferenceValue + 0.1;
  };

  getMinYAxisValue = () => {
    let sortedBids = orderBy(this.props.historicalPrice, function (o) {
      return o.finalPrice;
    });

    let lowerReferenceValue = this.getLowerReferenceValue();

    return sortedBids.length > 0
      ? sortedBids[0].finalPrice > lowerReferenceValue
        ? lowerReferenceValue - 0.1
        : sortedBids[0].finalPrice - 0.1
      : undefined;
  };

  componentDidUpdate() {
    if (
      !isEqual(
        formHistoricalPriceChartData(this.props.historicalPrice),
        this.state.series[0].data
      )
    ) {
      let series = [...this.state.series];
      let seriesobj = { ...series[0] };

      seriesobj.data = formHistoricalPriceChartData(this.props.historicalPrice);

      series[0] = seriesobj;

      let options = { ...this.state.options };

      let yaxis = [...options.yaxis];
      yaxis[0].min = this.getMinYAxisValue();
      yaxis[0].max = this.getMaxYAxisValue();

      this.setState({ series, options });
    }
  }

  render() {
    return (
      <>
        <div id='chart' className='historical-trend-chart'>
          <div className='historicaltrend-header'>
            <div className='toolbar'>
              Change View:
              <button
                id='one_month'
                onClick={() => this.updateData('one_month')}
                className={this.state.selection === 'one_month' ? 'active' : ''}
              >
                1M
              </button>
              &nbsp;
              <button
                id='six_months'
                onClick={() => this.updateData('six_months')}
                className={
                  this.state.selection === 'six_months' ? 'active' : ''
                }
              >
                6M
              </button>
              &nbsp;
              <button
                id='one_year'
                onClick={() => this.updateData('one_year')}
                className={this.state.selection === 'one_year' ? 'active' : ''}
              >
                1Y
              </button>
              &nbsp;
              <button
                id='ytd'
                onClick={() => this.updateData('ytd')}
                className={this.state.selection === 'ytd' ? 'active' : ''}
              >
                YTD
              </button>
              &nbsp;
              <button
                id='all'
                onClick={() => this.updateData('all')}
                className={this.state.selection === 'all' ? 'active' : ''}
              >
                ALL
              </button>
            </div>
            <div className='target-price-anno'>- - - Target Price</div>
          </div>

          <div id='chart-timeline'>
            <Chart
              options={this.state.options}
              series={this.state.series}
              type='area'
              height={450}
            />
          </div>
        </div>
      </>
    );
  }
}
export default HistoricalTrend;
