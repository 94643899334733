import { Col, Form, Input, Row, Select, Space } from 'antd';
import { isEmpty, isUndefined } from 'lodash';

import { CaretDownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import SpecificationUtils from 'src/utils/SpecificationUtils';

const { Option } = Select;

class BasicItemInformation extends React.Component {
  static propTypes = {
    customers: PropTypes.array.isRequired,
    brands: PropTypes.array.isRequired,
    selectedCustomer: PropTypes.string,
    selectedBrand: PropTypes.string,
    isEdit: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      customers: this.props.customers,
      brands: this.props.brands,
      selectedCustomer: this.props.selectedCustomer
        ? this.props.selectedCustomer
        : this.props.customers[0],
      selectedBrand: this.props.selectedBrand
        ? this.props.selectedBrand
        : this.props.brands[0],
      showCustomerInput: false,
      showBrandInput: false,
    };
  }

  selectCustomer = (value) => {
    if (value === 'Other') this.setState({ showCustomerInput: true });
    else this.setState({ selectedCustomer: value, showCustomerInput: false });
  };

  selectBrand = (value) => {
    if (value === 'Other') this.setState({ showBrandInput: true });
    else this.setState({ selectedBrand: value, showBrandInput: false });
  };

  setCustomer = (value) => {
    this.setState({ selectedCustomer: value });
  };

  setBrand = (value) => {
    this.setState({ selectedBrand: value });
  };

  getData = () => {
    const { selectedCustomer, selectedBrand } = this.state;
    return {
      customer: selectedCustomer,
      brand: selectedBrand,
    };
  };

  render() {
    const {
      customers,
      brands,
      selectedCustomer,
      selectedBrand,
      showCustomerInput,
      showBrandInput,
    } = this.state;
    const { isEdit } = this.props;
    return (
      <Row gutter={30}>
        <Col span={24} xs={24} lg={8} xl={8}>
          <Form.Item
            className='specification-input-item'
            name='companyItemNumber'
            label='User Item Number'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Input
              className='specification-input'
              placeholder='Please enter an item number'
              disabled={isEdit}
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={8} xl={8}>
          <Form.Item
            className='specification-input-item'
            name='itemDescription'
            label='User Preferred Item Description'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Input
              className='specification-input'
              placeholder='Please enter preferred item description'
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={8} xl={8}>
          <Form.Item
            className='specification-input-item'
            name='additionalItemNumber'
            label='Additional Item Number'
          >
            <Input
              className='specification-input'
              placeholder='Please enter additional item number if any'
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item className='specification-input-item' label='Customer'>
            <Space>
              <Select
                suffixIcon={<CaretDownOutlined />}
                className='specification-tag-input'
                onChange={this.selectCustomer}
                placeholder='Please choose a customer'
                defaultValue={
                  isUndefined(selectedCustomer)
                    ? !isEmpty(customers) && customers[0]
                    : selectedCustomer
                }
              >
                {!isEmpty(customers) &&
                  customers.map((customer) => (
                    <Option key={customer} value={customer}>
                      {customer}
                    </Option>
                  ))}
                {!customers.includes('None') && (
                  <Option key='No specific customer' value='None'>
                    No Specific Customer
                  </Option>
                )}
                <Option key='Other' value='Other'>
                  Other
                </Option>
              </Select>
              {showCustomerInput && (
                <Input
                  className='specification-input'
                  placeholder='Enter customer name'
                  onChange={(event) => this.setCustomer(event.target.value)}
                />
              )}
            </Space>
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item className='specification-input-item' label='Brand'>
            <Space>
              <Select
                suffixIcon={<CaretDownOutlined />}
                className='specification-tag-input'
                placeholder='Please choose a brand'
                onChange={this.selectBrand}
                defaultValue={
                  isUndefined(selectedBrand)
                    ? !isEmpty(brands) && brands[0]
                    : selectedBrand
                }
              >
                {!isEmpty(brands) &&
                  brands.map((brand) => (
                    <Option key={brand} value={brand}>
                      {brand}
                    </Option>
                  ))}
                {!brands.includes('None') && (
                  <Option key='No specific brand' value='None'>
                    No Specific Brand
                  </Option>
                )}
                <Option key='Other' value='Other'>
                  Other
                </Option>
              </Select>
              {showBrandInput && (
                <Input
                  className='specification-input'
                  placeholder='Enter brand name'
                  onChange={(event) => this.setBrand(event.target.value)}
                />
              )}
            </Space>
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

export default BasicItemInformation;
