import { Button, Tag } from 'antd';
import { getFormattedFullDate, getUsername } from '../DisplayUtils';
import { hashCode, intToRGB } from '../misc/ColorUtils';

import ReactHtmlParser from 'react-html-parser';
import TableFilterUtils from '../TableFilterUtils';
import { isUndefined } from 'lodash';
import moment from 'moment';

function getBasicColumnsForTable(data, showTextColumnOnly) {
  let columns = [
    {
      title: 'Description',
      key: 'text',
      render: (record) => ReactHtmlParser(record.text),
    },
    {
      title: 'Category',
      key: 'category',
      render: (record) => (
        <Tag color={intToRGB(hashCode(record.category))} key={record.category}>
          {record.category}
        </Tag>
      ),
      filters: TableFilterUtils.getFilterDropdownValues(data, 'category'),
      filterMultiple: true,
      onFilter: (value, record) => record.category.indexOf(value) >= 0,
    },
  ];

  if (!showTextColumnOnly) {
    columns.push(
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sorter: (a, b) =>
          moment(a.updatedAt).diff(moment(b.updatedAt), 'second'),
        render: (updatedAt) => getFormattedFullDate(updatedAt),
        defaultSortOrder: 'descend',
        width: 150,
      },
      {
        title: 'Updated By',
        dataIndex: 'updatedBy',
        key: 'updatedBy',
        render: (updatedBy) =>
          !isUndefined(updatedBy) ? getUsername(updatedBy) : '-',
        width: 150,
      }
    );
  }

  return columns;
}

function getBasicColumnsWithActionsForTable(data) {
  let columns = getBasicColumnsForTable(data);
  if (this.isUserAuthorized())
    columns.push({
      title: '',
      key: 'action',
      render: (record) => (
        <div size='middle' className='pending-invites-cta'>
          <Button
            className='primary-button mr-15'
            onClick={() => this.openEditTandCModal(record)}
          >
            Edit
          </Button>
          <Button
            className='cancel-outlined-button'
            onClick={() => this.openRemoveTandCModal(record.id)}
          >
            Remove
          </Button>
        </div>
      ),
      width: 200,
    });
  return columns;
}

const TermsAndConditionsTableManager = {
  getBasicColumnsForTable,
  getBasicColumnsWithActionsForTable,
};

export default TermsAndConditionsTableManager;
