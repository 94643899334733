import * as Sentry from '@sentry/react';

import RouteConstants from 'src/constants/RouteConstants';
import StringConstants from 'src/constants/StringConstants';
import auth0 from 'auth0-js';
import { deleteToken } from './auth';
import { getUserDataFromCookie } from './AuthUtils';
import { isEmpty } from 'lodash';

const clientID = process.env.REACT_APP_AUTH0_CLIENTID;
const domain = process.env.REACT_APP_AUTH0_DOMAIN;

function webAuth(clientID, domain) {
  return new auth0.WebAuth({
    clientID: clientID,
    domain: domain,
  });
}

function login(redirectTo) {
  let redirectUri = process.env.REACT_APP_REDIRECT_URL;
  if (redirectTo) redirectUri += '?redirect=' + redirectTo;
  const options = {
    responseType: 'token id_token',
    redirectUri: redirectUri,
  };

  return webAuth(clientID, domain).authorize(options);
}

function parseHash(cb) {
  return webAuth(clientID, domain).parseHash(cb);
}

function logout(redirectUrl) {
  const loggedInUserData = getUserDataFromCookie();
  Sentry.configureScope((scope) => scope.setUser(null));
  if (
    !isEmpty(loggedInUserData) &&
    loggedInUserData.company_type === StringConstants.COMPANY_TYPES.IMPORTER
  ) {
    if (!redirectUrl) redirectUrl = process.env.REACT_APP_LOGOUT_RETURN_URL;
    deleteToken();
    return webAuth(clientID, domain).logout({ returnTo: redirectUrl });
  } else {
    deleteToken();
    window.location = RouteConstants.LOGIN;
  }
}

export { login, parseHash, logout };
