import './BidDetails.css';

import { Col, Row, Tag, Tooltip } from 'antd';
import {
  findBidIncotermByCode,
  findChange,
  getDisplayIncoterm,
} from 'src/utils/BidUtils';
import {
  getAmountDisplayText,
  getFormattedFullDate,
  getPercentageDisplayText,
} from 'src/utils/DisplayUtils';
import { isEmpty, isUndefined, map, orderBy, uniq } from 'lodash';

import AuctionService from 'src/services/AuctionService';
import BidService from 'src/services/BidService';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import { InfoCircleOutlined } from '@ant-design/icons';
import LoadingComponent from 'src/components/common/LoadingComponent';
import NoDataComponent from 'src/components/common/NoDataComponent';
import NumberFormat from 'react-number-format';
import React from 'react';
import SecureComponent from 'src/components/common/SecureComponent';
import SellerBidHistory from 'src/components/auction/bid/SellerBidHistory';
import eventBus from 'src/utils/eventBus/EventBus';

class BidDetails extends React.Component {
  state = {
    auctionId: null,
    plantId: null,
    auctionDetails: {},
    bidDetails: {},
    loading: true,
  };

  async componentDidMount() {
    const auctionId = this.props.match.params.id;
    const plantId = this.props.match.params.plantId;
    this.setState({ loading: true, auctionId, plantId });

    try {
      let auctionResponse = await AuctionService.getAuctionDetails(auctionId);
      let auctionDetails = auctionResponse.data;

      this.setState({ auctionDetails }, async () => {
        let response = await BidService.getBidDetails(auctionId, plantId);
        let bidDetails = response.data;
        bidDetails.bids = orderBy(bidDetails.bids, ['createdAt'], ['desc']);
        bidDetails.biddingCompanies = [bidDetails.company];
        this.setState({ bidDetails: response.data, loading: false });
        if (!isEmpty(bidDetails.company))
          eventBus.dispatch('breadcrumb_update', {
            [[plantId]]: bidDetails.company.name,
          });
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading, auctionDetails, bidDetails, auctionId, plantId } =
      this.state;
    const { bids, company } = bidDetails;
    const { specification } = auctionDetails;

    return loading ? (
      <LoadingComponent />
    ) : isEmpty(bidDetails) ? (
      <NoDataComponent
        title='Quote not found'
        tip={`Quote by plant (${plantId}) for RFQ (${auctionId}) does not exist`}
        fullScreen={true}
        headerMetadata={true}
      />
    ) : (
      <>
        <HeaderMetadata
          title={`Quotes from ${
            bidDetails && bidDetails.company ? bidDetails.company.name : ''
          } (${auctionId})`}
          description=''
        />
        <Row>
          <Col span={24} xs={24} lg={24} xl={24}>
            <CardWithTitle title={`Quote Details (${auctionId})`}>
              <Row>
                <Col xs={24} md={20}>
                  <p className='title-heading primary-color mb-0'>
                    {company && company.name}
                  </p>
                </Col>
                <Col className='mb-15 mt-15' span={24} xs={24} lg={24} xl={24}>
                  <div className='rscb-info'>
                    {/* <div className='rscb-img'>
                      <img src={ImageConstants.SHRIMP_ICON} alt='Shrimp' />
                    </div> */}
                    <div
                      className='rscb-desc'
                      style={{
                        padding: 0,
                      }}
                    >
                      <p className='rscb-desc-cin'>
                        Item Number:{' '}
                        <span>{specification.companyItemNumber}</span>
                      </p>
                      <p className='rscb-desc-company'>
                        <span>{specification.customer}</span> |{' '}
                        <span>{specification.itemDescription}</span>
                      </p>
                    </div>
                  </div>
                </Col>
                <Col
                  className='rscb-details mb-15 mt-15'
                  span={24}
                  xs={24}
                  lg={24}
                  xl={24}
                >
                  <ul className='rscb-items bid-detail-list-1 mb-15'>
                    <li>
                      <span className='left-item'>Additional Item Number:</span>
                      <span className='right-item'>
                        {specification.additionalItemNumber}
                      </span>
                    </li>
                    <li>
                      <span className='left-item'>Product Form:</span>
                      <span className='right-item'>
                        {specification.productForm}
                      </span>
                    </li>
                    <li>
                      <span className='left-item'>Customer</span>
                      <span className='right-item'>
                        {specification.customer}
                      </span>
                    </li>
                    <li>
                      <span className='left-item'>Brand</span>
                      <span className='right-item'>{specification.brand}</span>
                    </li>
                    <li>
                      <span className='left-item'>Species:</span>
                      <span className='right-item species-details-item'>
                        {uniq(map(specification.seaFoodList, 'commonName')).map(
                          (seafoodItem, index) => {
                            return <Tag key={index}>{seafoodItem}</Tag>;
                          }
                        )}
                      </span>
                    </li>
                  </ul>
                  <ul className='rscb-items mt-15'>
                    <li>
                      <span className='left-item'>Seller Name :</span>
                      <span className='right-item'>
                        {company && company.name}
                      </span>
                    </li>
                    <li>
                      <span className='left-item'>Current Quote :</span>
                      <span className='right-item'>
                        {isUndefined(bids) || bids.length === 0
                          ? 'N/A'
                          : getAmountDisplayText(
                              getDisplayIncoterm(bids[0].bidIncoterms).amount
                            )}
                      </span>
                    </li>
                    <li>
                      <span className='left-item '>Quantity :</span>
                      <span className='right-item '>
                        <span className='list-sub-items'>
                          Total Cases:
                          <br />
                          <span className='color-blue'>
                            <NumberFormat
                              value={auctionDetails.totalCases}
                              thousandSeparator={true}
                              displayType='text'
                            />
                          </span>
                        </span>
                        <span className='list-sub-items'>
                          Total LBS:
                          <br />
                          <span className='color-blue'>
                            <NumberFormat
                              value={auctionDetails.totalWeight}
                              suffix={` ${auctionDetails.weightUom}`}
                              thousandSeparator={true}
                              displayType='text'
                            />
                          </span>
                        </span>
                        <span className='list-sub-items'>
                          Total FCL
                          <br />
                          <span className='color-blue'>
                            <NumberFormat
                              value={auctionDetails.totalFCL}
                              thousandSeparator={true}
                              displayType='text'
                            />
                          </span>
                        </span>
                      </span>
                    </li>
                    <li>
                      <span className='left-item'>Change (in $) :</span>
                      <span className='right-item color-green'>
                        {isUndefined(bids) ? '-' : findChange(bids)}
                      </span>
                    </li>
                    <li>
                      <span className='left-item'>Change (in %) :</span>
                      <span className='right-item color-green'>
                        {!isUndefined(bids) &&
                        findChange(bids) !== '-' &&
                        bids.length > 1
                          ? getPercentageDisplayText(
                              (findChange(bids) /
                                getDisplayIncoterm(bids[1].bidIncoterms)
                                  .amount) *
                                100
                            )
                          : '-'}
                      </span>
                    </li>
                    <li>
                      <span className='left-item'>Quote Submitted On :</span>
                      <span className='right-item'>
                        {isUndefined(bids) || bids.length === 0
                          ? '-'
                          : getFormattedFullDate(bids[0].createdAt)}
                      </span>
                    </li>
                    <li>
                      <span className='left-item '>Incoterms :</span>
                      <span className='right-item '>
                        <span className='list-sub-items'>
                          CNF/CFR (Cost & Freight)
                          <br />
                          <span className='color-blue'>
                            {isUndefined(bids) ||
                            bids.length === 0 ||
                            isUndefined(
                              findBidIncotermByCode(bids[0].bidIncoterms, 'CNF')
                                .amount
                            )
                              ? '-'
                              : getAmountDisplayText(
                                  findBidIncotermByCode(
                                    bids[0].bidIncoterms,
                                    'CNF'
                                  ).amount
                                )}{' '}
                            {findBidIncotermByCode(bids[0].bidIncoterms, 'CNF')
                              .isCalculated ? (
                              <sup>
                                <Tooltip title='This is a computed value'>
                                  <InfoCircleOutlined />
                                </Tooltip>
                              </sup>
                            ) : null}
                          </span>
                        </span>
                        <span className='list-sub-items'>
                          DDP CY (Delivered Duty Paid Container Yard)
                          <br />
                          <span className='color-blue'>
                            {isUndefined(bids) ||
                            bids.length === 0 ||
                            isUndefined(
                              findBidIncotermByCode(
                                bids[0].bidIncoterms,
                                'DDPCY'
                              ).amount
                            )
                              ? '-'
                              : getAmountDisplayText(
                                  findBidIncotermByCode(
                                    bids[0].bidIncoterms,
                                    'DDPCY'
                                  ).amount
                                )}{' '}
                            {findBidIncotermByCode(
                              bids[0].bidIncoterms,
                              'DDPCY'
                            ).isCalculated ? (
                              <sup>
                                <Tooltip title='This is a computed value'>
                                  <InfoCircleOutlined />
                                </Tooltip>
                              </sup>
                            ) : null}
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                </Col>
              </Row>
            </CardWithTitle>
          </Col>
          <Col span={24} xs={24} lg={24} xl={24}>
            <SellerBidHistory
              bidDetails={{
                ...bidDetails,
                auctionIncoterms: auctionDetails.auctionIncoterms,
                auctionTargetPrice: auctionDetails.targetPrice,
                lowestBid: auctionDetails.lowestBid,
              }}
            />
          </Col>
        </Row>
      </>
    );
  }
}
export default SecureComponent(BidDetails);
