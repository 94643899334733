import '../Modals.css';

import { Form, Input, Modal } from 'antd';
import React, { Component } from 'react';

import CompanyService from 'src/services/CompanyService';
import PropTypes from 'prop-types';
import Title from 'src/components/common/typography/Title';
import { toast } from 'react-toastify';

class InvitePlantsModal extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.inviteForm = React.createRef();
  }

  onSubmit = (values) => {
    this.setState({ loading: true });
    //Submit invite plant request
    CompanyService.inviteProcessingPlant(values)
      .then(() => {
        toast.success('Plant invited successfully');
        this.setState({ loading: false });
        this.props.onClose(true);
      })
      .catch((err) => {
        toast.error('Plant invite failed, try again later');
        this.setState({ loading: false });
      });
  };

  render() {
    const { visible } = this.props;
    const { loading } = this.state;

    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };

    return (
      <Modal
        className='confirmation-modal'
        centered
        visible={visible}
        okText='Send Invite'
        confirmLoading={loading}
        closable={false}
        maskClosable={false}
        onOk={() => this.inviteForm.submit()}
        onCancel={this.props.onClose}
        cancelButtonProps={{
          disabled: loading,
          className: 'cancel-outlined-button',
        }}
        width={900}
        destroyOnClose
      >
        <Title title='Invite Plant' />
        <Form
          {...layout}
          onFinish={this.onSubmit}
          preserve={false}
          ref={(ref) => (this.inviteForm = ref)}
          className='mt-30'
        >
          <Form.Item
            name='email'
            label='Plant e-mail'
            labelAlign='left'
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please enter a valid email',
              },
            ]}
          >
            <Input type='email' className='auction-edit-field' />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default InvitePlantsModal;
