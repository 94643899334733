import './Specification.css';

import { Button, Col, Collapse, Row, Space } from 'antd';
import {
  CaretDownOutlined,
  CopyOutlined,
  DownloadOutlined,
  EditFilled,
  FileOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { isEmpty, map, uniq } from 'lodash';
import {
  isUserImporter,
  isUserManagerOfTeam,
  isUserPlant,
} from 'src/helpers/user';

import ApiUtils from 'src/utils/ApiUtils';
import CardWithTitle from '../common/cards/CardWithTitle';
import ConfirmationModal from 'src/modals/common/ConfirmationModal';
import PropTypes from 'prop-types';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import SpecificationService from 'src/services/SpecificationService';
import SpecificationUtils from 'src/utils/SpecificationUtils';
import StringConstants from 'src/constants/StringConstants';
import UploadFilesComponent from '../common/UploadFilesComponent';
import UrlGenerator from 'src/api/UrlGenerator';
import VersionHistoryDrawer from 'src/drawers/versionHistory/VersionHistoryDrawer';
import { getDisplayString } from 'src/utils/DisplayUtils';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';

const { Panel } = Collapse;

class Specification extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    openAllPanels: PropTypes.bool,
    openBasicPanel: PropTypes.bool,
    showHeading: PropTypes.bool,
    showVersionHistory: PropTypes.bool,
    showOverview: PropTypes.bool,
    entity: PropTypes.object,
    showActions: PropTypes.bool,
    showUnarchiveAction: PropTypes.bool,
    showUploadedFiles: PropTypes.bool,
  };

  static defaultProps = {
    openAllPanels: false,
    openBasicPanel: true,
    showHeading: false,
    showVersionHistory: false,
    showOverview: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      archiving: false,
      unarchiving: false,
      downloadLoading: false,
      versionDrawerVisible: false,
      confirmArchiveModalVisible: false,
      confirmUnarchiveModalVisible: false,
    };
  }

  archiveSpecification = () => {
    const { data } = this.props;
    this.setState({ archiving: true });
    SpecificationService.archiveSpecification(data.id)
      .then((response) => {
        toast.success('Archived specification successfully');
        this.setState({ archiving: false }, () =>
          this.props.history.push(
            UrlGenerator.getUiUrlWithQueryParams(
              RouteConstants.SPECIFICATIONS,
              {
                status: StringConstants.SPECIFICATION_STATUS.ARCHIVED,
              }
            )
          )
        );
      })
      .catch((error) => {
        let errorMessage = ApiUtils.getErrorMessage(error);
        toast.error('Failed to archive specification - ' + errorMessage);
        this.setState({ archiving: false });
      });
  };

  unarchiveSpecification = () => {
    const { data } = this.props;
    this.setState({ unarchiving: true });
    SpecificationService.unarchiveSpecification(data.id)
      .then((response) => {
        toast.success('Unarchived specification successfully');
        this.setState({ unarchiving: false }, () =>
          this.props.history.push(
            UrlGenerator.getUiUrlWithQueryParams(
              RouteConstants.SPECIFICATIONS,
              {
                status: StringConstants.SPECIFICATION_STATUS.ACTIVE,
              }
            )
          )
        );
      })
      .catch((error) => {
        let errorMessage = ApiUtils.getErrorMessage(error);
        toast.error('Failed to unarchive specification - ' + errorMessage);
        this.setState({ archiving: false });
      });
  };

  downloadSpecification = () => {
    const { data } = this.props;
    this.setState({ downloadLoading: true });

    if (isUserImporter()) {
      SpecificationService.downloadSpecificationPDF(data.id)
        .then((blob) => {
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(new Blob([blob.data]));
          link.href = url;
          link.setAttribute('download', `${data.id}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          this.setState({ downloadLoading: false });
        })
        .catch((err) => {
          toast.error('Cannot download specifications at the moment');
          this.setState({ downloadLoading: false });
        });
    } else if (isUserPlant()) {
      SpecificationService.downloadSpecificationPDF(data.id, {
        entityType: this.props.entity.type,
        entityId: this.props.entity.id,
      })
        .then((blob) => {
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(new Blob([blob.data]));
          link.href = url;
          link.setAttribute('download', `${data.id}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          this.setState({ downloadLoading: false });
        })
        .catch((err) => {
          toast.error('Cannot download specifications at the moment');
          this.setState({ downloadLoading: false });
        });
    } else {
      toast.error('Cannot download specifications at the moment');
      this.setState({ downloadLoading: false });
    }
  };

  closeVersionDrawer = () => {
    this.setState({ versionDrawerVisible: false });
  };

  navigateToOverviewPage = () => {
    const { data } = this.props;
    this.props.history.push(
      UrlGenerator.getUiUrlWithPathParams(
        RouteConstants.SPECIFICATION_HOMEPAGE,
        { id: data.id }
      )
    );
  };

  render() {
    const {
      data,
      openAllPanels,
      openBasicPanel,
      showHeading,
      showVersionHistory,
      showOverview,
      showActions,
      showUnarchiveAction,
      showUploadedFiles,
    } = this.props;
    const {
      archiving,
      downloadLoading,
      versionDrawerVisible,
      unarchiving,
      confirmArchiveModalVisible,
      confirmUnarchiveModalVisible,
    } = this.state;
    if (isEmpty(data)) {
      return <span>Not Found</span>;
    }
    return (
      <CardWithTitle
        title='Specification'
        action={
          <Space>
            {isUserImporter() &&
              isUserManagerOfTeam(StringConstants.TEAMS.ACCOUNT_MANAGERS) &&
              showActions && (
                <>
                  <Button
                    type='link'
                    className='head-box-download'
                    onClick={() =>
                      this.props.history.push(
                        UrlGenerator.getUiUrlWithPathParams(
                          RouteConstants.EDIT_SPECIFICATION,
                          { id: data.id }
                        )
                      )
                    }
                    disabled={archiving || downloadLoading}
                  >
                    <EditFilled className='mr-0' />
                    Edit
                  </Button>
                  <Button
                    type='link'
                    className='head-box-download'
                    onClick={() =>
                      this.props.history.push(
                        UrlGenerator.getUiUrlWithPathParams(
                          RouteConstants.CLONE_SPECIFICATION,
                          { id: data.id }
                        )
                      )
                    }
                    disabled={archiving || downloadLoading}
                  >
                    <CopyOutlined className='mr-0' />
                    Clone
                  </Button>
                  {data.status ===
                    StringConstants.SPECIFICATION_STATUS.ACTIVE && (
                    <Button
                      type='link'
                      className='head-box-download'
                      onClick={() =>
                        this.setState({ confirmArchiveModalVisible: true })
                      }
                      loading={archiving}
                      disabled={downloadLoading}
                    >
                      <FileTextOutlined className='mr-0' />
                      Archive
                    </Button>
                  )}
                </>
              )}
            {isUserImporter() &&
              isUserManagerOfTeam(StringConstants.TEAMS.ACCOUNT_MANAGERS) &&
              showUnarchiveAction && (
                <Button
                  type='link'
                  className='head-box-download'
                  onClick={() =>
                    this.setState({ confirmUnarchiveModalVisible: true })
                  }
                  loading={unarchiving}
                  disabled={downloadLoading}
                >
                  <FileOutlined className='mr-0' />
                  Unarchive
                </Button>
              )}
            <Button
              type='link'
              className='head-box-download'
              onClick={this.downloadSpecification}
              loading={downloadLoading}
              disabled={archiving}
            >
              <DownloadOutlined className='mr-0' />
              Download PDF
            </Button>
          </Space>
        }
      >
        <Row>
          {showHeading && (
            <Col xs={24} md={20}>
              <p className='title-heading primary-color mb-0'>
                {data.itemDescription}
              </p>
            </Col>
          )}
          {(showVersionHistory || showOverview) && (
            <Col xs={24} md={showHeading ? 4 : 24} className='text-right'>
              <Space>
                {showOverview && (
                  <Button
                    className='primary-outlined-button mr-10'
                    onClick={this.navigateToOverviewPage}
                    disabled={archiving || downloadLoading}
                  >
                    Overview
                  </Button>
                )}
                {showVersionHistory && (
                  <Button
                    className='primary-button mr-10'
                    type='primary'
                    onClick={() =>
                      this.setState({ versionDrawerVisible: true })
                    }
                    disabled={archiving || downloadLoading}
                  >
                    View History
                  </Button>
                )}
              </Space>
            </Col>
          )}
        </Row>

        <Collapse
          expandIconPosition='right'
          bordered={false}
          defaultActiveKey={
            openAllPanels
              ? [
                  'basic-info',
                  'species-info',
                  'physical-attributes',
                  'corporate-assurance-req',
                  'uploaded-files',
                ]
              : openBasicPanel
              ? ['basic-info']
              : []
          }
          destroyInactivePanel
          expandIcon={({ isActive }) => (
            <CaretDownOutlined rotate={isActive ? 0 : -90} />
          )}
          className='site-collapse-custom-collapse specification-collapse'
        >
          <Panel
            header='Basic Item Information'
            key='basic-info'
            className='site-collapse-custom-panel'
          >
            <ul className='specification-items'>
              <li>
                <span className='left-item'>Company</span>
                <span className='right-item'>
                  {data.company && data.company.name}
                </span>
              </li>
              <li>
                <span className='left-item'>Item Number</span>
                <span className='right-item'>{data.id}</span>
              </li>
              <li>
                <span className='left-item'>Company Item Number</span>
                <span className='right-item'>{data.companyItemNumber}</span>
              </li>
              <li>
                <span className='left-item'>Additional Item Number</span>
                <span className='right-item'>{data.additionalItemNumber}</span>
              </li>
              <li>
                <span className='left-item'>Description</span>
                <span className='right-item'>{data.itemDescription}</span>
              </li>
              <li>
                <span className='left-item'>Customer</span>
                <span className='right-item'>{data.customer}</span>
              </li>
              <li>
                <span className='left-item'>Brand</span>
                <span className='right-item'>{data.brand}</span>
              </li>
              <li>
                <span className='left-item'>Net Unit Size</span>
                <span className='right-item'>
                  {data.productType === 'FINFISH'
                    ? `${data.netUnitSize} ${data.baseUom}`
                    : `${data.netUnitSize}`}
                </span>
              </li>
              <li>
                <span className='left-item'>No. of units in case</span>
                <span className='right-item'>{data.noOfUnitsInCase}</span>
              </li>
              <li>
                <span className='left-item'>Pack combined</span>
                <span className='right-item'>{data.packCombined}</span>
              </li>
              <li>
                <span className='left-item'>Shelf life from Production</span>
                <span className='right-item'>
                  {`${data.shelfLifeFromProductionInMonths} months`}
                </span>
              </li>
              <li>
                <span className='left-item'>
                  Shelf life requirement for delivery to customer
                </span>
                <span className='right-item'>
                  {`${data.shelfLifeReqForCustDeliveryInMonths} months`}
                </span>
              </li>
            </ul>
          </Panel>
          <Panel
            header='Species Information'
            key='species-info'
            className='site-collapse-custom-panel'
          >
            <ul className='specification-items'>
              <li>
                <span className='left-item'>Acceptable Market Name</span>
                <span className='right-item'>
                  {data &&
                    uniq(
                      map(data.seaFoodList, 'acceptableMarketName')
                    ).toString()}
                </span>
              </li>
              <li>
                <span className='left-item'>Common Names</span>
                <span className='right-item'>
                  {data && map(data.seaFoodList, 'commonName').toString()}
                </span>
              </li>
              <li>
                <span className='left-item'>Latin Names</span>
                <span className='right-item'>
                  {data && map(data.seaFoodList, 'scientificName').toString()}
                </span>
              </li>
              <li>
                <span className='left-item'>Product Type</span>
                <span className='right-item'>
                  {getDisplayString(data.productType)}
                </span>
              </li>
              <li>
                <span className='left-item'>Wild Caught or Farm Raised</span>
                <span className='right-item'>
                  {getDisplayString(data.wildOrFarm)}
                </span>
              </li>
              <li>
                <span className='left-item'>
                  Catch or Farmed Country of Origin
                </span>
                <span className='right-item'>
                  {SpecificationUtils.getAssociatedCountry(
                    data.associatedCountries,
                    'FARM_OR_CATCH'
                  )}
                </span>
              </li>
              <li>
                <span className='left-item'>Processing Country of Origin</span>
                <span className='right-item'>
                  {SpecificationUtils.getAssociatedCountry(
                    data.associatedCountries,
                    'PROCESSING'
                  )}
                </span>
              </li>
              <li>
                <span className='left-item'>Labelled County of Origin</span>
                <span className='right-item'>
                  {SpecificationUtils.getAssociatedCountry(
                    data.associatedCountries,
                    'LABELLED'
                  )}
                </span>
              </li>
              <li>
                <span className='left-item'>Country of Origin Comments</span>
                <span className='right-item'>
                  {SpecificationUtils.getComment(
                    data.additionalComments,
                    'COUNTRY_OF_ORIGIN'
                  )}
                </span>
              </li>
              <li>
                <span className='left-item'>
                  Additional Species Information Comments
                </span>
                <span className='right-item'>
                  {SpecificationUtils.getComment(
                    data.additionalComments,
                    'ADDITIONAL_SPECIES_INFO'
                  )}
                </span>
              </li>
            </ul>
          </Panel>
          <Panel
            header='Physical Attributes'
            key='physical-attributes'
            className='site-collapse-custom-panel'
          >
            <ul className='specification-items'>
              <li>
                <span className='left-item'>Product Form</span>
                <span className='right-item'>{data.productForm}</span>
              </li>
              {data.productType === 'FINFISH' && (
                <>
                  <li>
                    <span className='left-item'>Scaled or Unscaled</span>
                    <span className='right-item'>
                      {getDisplayString(data.scaledState)}
                    </span>
                  </li>
                  <li>
                    <span className='left-item'>Skin on or Skin off</span>
                    <span className='right-item'>
                      {getDisplayString(data.skinState)}
                    </span>
                  </li>
                  <li>
                    <span className='left-item'>Skinning Notes</span>
                    <span className='right-item'>
                      {getDisplayString(data.skinningNotes)}
                    </span>
                  </li>
                  <li>
                    <span className='left-item'>Boneless or Bone In</span>
                    <span className='right-item'>
                      {getDisplayString(data.boneState)}
                    </span>
                  </li>
                </>
              )}
              <li>
                <span className='left-item'>
                  Single Frozen or Double Frozen
                </span>
                <span className='right-item'>
                  {getDisplayString(data.frozenState)}
                </span>
              </li>
              <li>
                <span className='left-item'>Cooked, Raw, Parfried</span>
                <span className='right-item'>
                  {getDisplayString(data.cookedState)}
                </span>
              </li>
              <li>
                <span className='left-item'>Treatment</span>
                <span className='right-item'>
                  {getDisplayString(data.treatment)}
                </span>
              </li>
              <li>
                <span className='left-item'>Treatment Comments</span>
                <span className='right-item'>
                  {SpecificationUtils.getComment(
                    data.additionalComments,
                    'TREATMENT'
                  )}
                </span>
              </li>
              <li>
                <span className='left-item'>Moisture Level</span>
                <span className='right-item'>
                  {getDisplayString(data.moistureSymbol)}
                  {data.moisturePercent
                    ? ' ' + data.moisturePercent + ' %'
                    : null}
                </span>
              </li>
              <li>
                <span className='left-item'>Moisture Notes</span>
                <span className='right-item'>
                  {SpecificationUtils.getComment(
                    data.additionalComments,
                    'MOISTURE'
                  )}
                </span>
              </li>
              <li>
                <span className='left-item'>Glaze Comments</span>
                <span className='right-item'>
                  {SpecificationUtils.getComment(
                    data.additionalComments,
                    'GLAZE'
                  )}
                </span>
              </li>
              <li>
                <span className='left-item'>IQF or IVP</span>
                <span className='right-item'>{data.iqfIvp}</span>
              </li>
              {/* Size Range Property Display */}
              <li>
                <span className='left-item'>Size Range</span>
                <span className='right-item'>
                  {SpecificationUtils.getSizeRangeProperties(
                    data.productType,
                    data.sizeRange
                  )}
                </span>
              </li>
              <li>
                <span className='left-item'>
                  Additional Physical Attribute Comments
                </span>
                <span className='right-item'>
                  {SpecificationUtils.getComment(
                    data.additionalComments,
                    'ADDITIONAL_PHYSICAL_ATTRIBUTE'
                  )}
                </span>
              </li>
            </ul>
          </Panel>
          <Panel
            header='Corporate Assurance Requirements'
            key='corporate-assurance-req'
            className='site-collapse-custom-panel'
          >
            <ul className='specification-items'>
              <li>
                <span className='left-item'>Ingredient Statement</span>
                <span className='right-item'>
                  {SpecificationUtils.getComment(
                    data.additionalComments,
                    'INGREDIENT_STATEMENT'
                  )}
                </span>
              </li>
              <li>
                <span className='left-item'>Allergen Statement</span>
                <span className='right-item'>
                  {SpecificationUtils.getComment(
                    data.additionalComments,
                    'ALLERGEN_STATEMENT'
                  )}
                </span>
              </li>
              <li>
                <span className='left-item'>
                  Sustainability Minimum Requirements
                </span>
                <span className='right-item'>
                  {SpecificationUtils.getCorporateAssuranceValue(
                    data.corporateAssurance,
                    'SUSTAINABILITY_MIN_REQ'
                  )}
                </span>
              </li>
              <li>
                <span className='left-item'>Sustainability Comments</span>
                <span className='right-item'>
                  {SpecificationUtils.getCorporateAssuranceComments(
                    data.corporateAssurance,
                    'SUSTAINABILITY_MIN_REQ'
                  )}
                </span>
              </li>
              <li>
                <span className='left-item'>
                  Processing Facility Food Safety Minimum Requirements
                </span>
                <span className='right-item'>
                  {SpecificationUtils.getCorporateAssuranceValue(
                    data.corporateAssurance,
                    'FACILITY_FOOD_SAFETY'
                  )}
                </span>
              </li>
              <li>
                <span className='left-item'>Food Safety Comments</span>
                <span className='right-item'>
                  {SpecificationUtils.getCorporateAssuranceComments(
                    data.corporateAssurance,
                    'FACILITY_FOOD_SAFETY'
                  )}
                </span>
              </li>
              <li>
                <span className='left-item'>
                  Social Compliance Requirements
                </span>
                <span className='right-item'>
                  {SpecificationUtils.getCorporateAssuranceValue(
                    data.corporateAssurance,
                    'SOCIAL_COMPLIANCE'
                  )}
                </span>
              </li>
              <li>
                <span className='left-item'>Social Compliance Comments</span>
                <span className='right-item'>
                  {SpecificationUtils.getCorporateAssuranceComments(
                    data.corporateAssurance,
                    'SOCIAL_COMPLIANCE'
                  )}
                </span>
              </li>
              <li>
                <span className='left-item'>
                  3rd Party Quality Required Testing Partner
                </span>
                <span className='right-item'>
                  {SpecificationUtils.getCorporateAssuranceValue(
                    data.corporateAssurance,
                    'THIRD_PARTY_QA_PARTNER'
                  )}
                </span>
              </li>
              <li>
                <span className='left-item'>
                  Additional Corporate Assurance Comments
                </span>
                <span className='right-item'>
                  {SpecificationUtils.getComment(
                    data.additionalComments,
                    'CORPORATE_ASSURANCE'
                  )}
                </span>
              </li>
            </ul>
          </Panel>
          {showUploadedFiles && (
            <Panel
              header='Uploaded Files'
              key='uploaded-files'
              className='site-collapse-custom-panel'
            >
              <ul className='specification-items'>
                <UploadFilesComponent
                  ref={(ref) => (this.uploadFilesRef = ref)}
                  entity='SPECIFICATION'
                  entityId={data.id}
                  readOnly={true}
                />
              </ul>
            </Panel>
          )}
        </Collapse>
        {showVersionHistory && (
          <VersionHistoryDrawer
            currentVersion={data.version}
            visible={versionDrawerVisible}
            id={data.id}
            entity='SPECIFICATION'
            onClose={this.closeVersionDrawer}
          />
        )}
        {confirmArchiveModalVisible && (
          <ConfirmationModal
            visible={confirmArchiveModalVisible}
            title='Confirm archiving of specification'
            description={
              'Are you sure you want to archive the specification ' +
              data.id +
              ' ?'
            }
            okText='Yes, Archive'
            onSubmit={() => this.archiveSpecification()}
            onCancel={() =>
              this.setState({ confirmArchiveModalVisible: false })
            }
            actionLoading={archiving}
          />
        )}
        {confirmUnarchiveModalVisible && (
          <ConfirmationModal
            visible={confirmUnarchiveModalVisible}
            title='Confirm unarchiving of specification'
            description={
              'Are you sure you want to unarchive the specification ' +
              data.id +
              ' ?'
            }
            okText='Yes, Unarchive'
            onSubmit={() => this.unarchiveSpecification()}
            onCancel={() =>
              this.setState({ confirmUnarchiveModalVisible: false })
            }
            actionLoading={unarchiving}
          />
        )}
      </CardWithTitle>
    );
  }
}
export default withRouter(Specification);
