import { Button, Col, Row, Tabs } from 'antd';
import React, { Component } from 'react';
import { isUserImporter, isUserManagerOfTeam } from 'src/helpers/user';

import ApiUtils from 'src/utils/ApiUtils';
import Card from 'src/components/common/cards/Card';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import HelpText from 'src/components/common/typography/HelpText';
import LoadingComponent from 'src/components/common/LoadingComponent';
import RouteConstants from 'src/constants/RouteConstants';
import SecureComponent from 'src/components/common/SecureComponent';
import SpecificationService from 'src/services/SpecificationService';
import SpecificationTable from '../../components/specification/SpecificationTable';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { getReadableDisplayName } from 'src/utils/DisplayUtils';
import { isUndefined } from 'lodash';
import { toast } from 'react-toastify';

const helpTextByStatus = {
  [[StringConstants.SPECIFICATION_STATUS.ACTIVE]]: 'Active specifications',
  [[StringConstants.SPECIFICATION_STATUS.ARCHIVED]]: 'Archived specifications',
};

class Specifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      specifications: {
        loading: true,
        ACTIVE: [],
        ARCHIVED: [],
      },
      activeKey: undefined,
    };
  }

  componentDidMount() {
    let status = UrlGenerator.getParamsFromUrl(
      this.props.location.search,
      'status'
    );
    if (!status) {
      status = StringConstants.SPECIFICATION_STATUS.ACTIVE;
    }
    this.setState({ activeKey: status.toUpperCase() }, () =>
      this.fetchSpecifications()
    );
  }

  fetchSpecifications = () => {
    const { activeKey } = this.state;
    this.setState({ loading: true });
    SpecificationService.getAllSpecifications({ status: activeKey })
      .then((response) => {
        this.setState((prev) => ({
          specifications: {
            ...prev.specifications,
            [[activeKey]]: response.data,
          },
          loading: false,
        }));
      })
      .catch((err) => {
        let errorMsg = ApiUtils.getErrorMessage(
          err,
          'Failed to fetch specifications at the moment'
        );
        toast.error(errorMsg);
        this.setState({ loading: false });
      });
  };

  onTabChange = (value) => {
    const url = UrlGenerator.getUiUrlWithQueryParams(
      RouteConstants.SPECIFICATIONS,
      {
        status: value,
      }
    );
    window.history.replaceState(null, null, url);
    this.setState({ activeKey: value }, () => this.fetchSpecifications());
  };

  render() {
    const { loading, specifications, activeKey } = this.state;
    if (isUndefined(activeKey))
      return <LoadingComponent title='Loading Specifications' />;
    return (
      <>
        <HeaderMetadata
          title={`${getReadableDisplayName(activeKey)} Specification`}
          description={helpTextByStatus[activeKey]}
        />

        <Row>
          <Col xs={24} lg={24} xl={24}>
            <Card>
              <Tabs
                activeKey={activeKey}
                onChange={this.onTabChange}
                tabBarExtraContent={
                  isUserImporter() &&
                  isUserManagerOfTeam(StringConstants.TEAMS.ACCOUNT_MANAGERS) &&
                  activeKey === StringConstants.SPECIFICATION_STATUS.ACTIVE && (
                    <Button
                      className='heading-cta'
                      type='primary'
                      onClick={() =>
                        this.props.history.push(
                          RouteConstants.CREATE_SPECIFICATION
                        )
                      }
                    >
                      Create Specification
                    </Button>
                  )
                }
              >
                <Tabs.TabPane
                  tab={
                    <>
                      <span>Active Specifications</span>
                      {!loading &&
                      activeKey ===
                        StringConstants.SPECIFICATION_STATUS.ACTIVE ? (
                        <span className='heading-badge'>
                          {specifications[activeKey].length}
                        </span>
                      ) : null}
                    </>
                  }
                  key={StringConstants.SPECIFICATION_STATUS.ACTIVE}
                ></Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <>
                      <span>Archived Specifications</span>
                      {!loading &&
                      activeKey ===
                        StringConstants.SPECIFICATION_STATUS.ARCHIVED ? (
                        <span className='heading-badge'>
                          {specifications[activeKey].length}
                        </span>
                      ) : null}
                    </>
                  }
                  key={StringConstants.SPECIFICATION_STATUS.ARCHIVED}
                ></Tabs.TabPane>
              </Tabs>
              {activeKey && helpTextByStatus[activeKey] && (
                <HelpText
                  message={helpTextByStatus[activeKey]}
                  showIcon={true}
                />
              )}
              <SpecificationTable
                data={specifications[activeKey]}
                loading={loading}
                activeKey={activeKey}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default SecureComponent(
  Specifications,
  [StringConstants.COMPANY_TYPES.IMPORTER],
  [
    ...StringConstants.DEFAULT_TEAM_PAGE_ACCESS,
    {
      name: StringConstants.TEAMS.ACCOUNT_MANAGERS,
    },
  ]
);
