import { Col, Form, Input, Row, Select, Space } from 'antd';
import { find, isEmpty, isEqual, isUndefined } from 'lodash';

import { CaretDownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import SpecificationUtils from 'src/utils/SpecificationUtils';
import StringConstants from 'src/constants/StringConstants';

const { Option } = Select;

class CorporateAssuranceRequirements extends React.Component {
  static propTypes = {
    selectedSustainabilityRequirements: PropTypes.array,
    selectedProcessingRequirement: PropTypes.string,
    selectedComplianceRequirement: PropTypes.string,
    selectedThirdPartyTesting: PropTypes.string,
    wildOrFarm: PropTypes.string,
  };

  static defaultProps = {
    selectedProcessingRequirement:
      StringConstants.SPECIFICATION_FIELDS
        .PROCESSING_FACILITY_FOOD_SAFETY_MIN_REQUIREMENTS[0],
    selectedComplianceRequirement:
      StringConstants.SPECIFICATION_FIELDS.SOCIAL_COMPLIANCE_REQUIREMENTS[0],
    selectedThirdPartyTesting:
      StringConstants.SPECIFICATION_FIELDS
        .THIRD_PARTY_QUALITY_REQUIRED_TESTING_PARTNER[0],
    wildOrFarm: StringConstants.SPECIFICATION_FIELDS.FARM_RAISED,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedSustainabilityRequirements:
        this.props.selectedSustainabilityRequirements,
      selectedProcessingRequirement: this.props.selectedProcessingRequirement,
      selectedComplianceRequirement: this.props.selectedComplianceRequirement,
      selectedThirdPartyTesting: this.props.selectedThirdPartyTesting,
      showProcessingRequirementInput: false,
      showComplianceRequirementInput: false,
      showThirdPartyTestingInput: false,
    };
  }

  selectSustainabilityRequirements = (value) => {
    const { wildOrFarm } = this.props;
    let noneRequirementSelected = find(
      StringConstants.SPECIFICATION_FIELDS.SUSTAINABILITY_MIN_REQUIREMENTS[
        wildOrFarm
      ],
      function (o) {
        return value.includes(o.name) && o.type === 'None';
      }
    );
    if (!isUndefined(noneRequirementSelected))
      this.setState({
        selectedSustainabilityRequirements: [noneRequirementSelected.name],
      });
    else this.setState({ selectedSustainabilityRequirements: value });
  };

  checkRequirementSelectable = (requirement) => {
    const { wildOrFarm } = this.props;
    const { selectedSustainabilityRequirements } = this.state;
    if (isEmpty(selectedSustainabilityRequirements)) return true;

    return !StringConstants.SPECIFICATION_FIELDS.SUSTAINABILITY_MIN_REQUIREMENTS[
      wildOrFarm
    ].some((req) => {
      if (
        selectedSustainabilityRequirements.includes(req.name) &&
        req.type === 'None'
      )
        return requirement.name !== req.name;

      return (
        selectedSustainabilityRequirements.includes(req.name) &&
        requirement.type === req.type &&
        requirement.name !== req.name &&
        req.type !== 'Other'
      );
    });
  };

  selectProcessingRequirement = (value) => {
    if (value === 'Other')
      this.setState({ showProcessingRequirementInput: true });
    else
      this.setState({
        selectedProcessingRequirement: value,
        showProcessingRequirementInput: false,
      });
  };

  setProcessingRequirement = (value) => {
    this.setState({ selectedProcessingRequirement: value });
  };

  selectComplianceRequirement = (value) => {
    if (value === 'Other')
      this.setState({ showComplianceRequirementInput: true });
    else
      this.setState({
        selectedComplianceRequirement: value,
        showComplianceRequirementInput: false,
      });
  };

  setComplianceRequirement = (value) => {
    this.setState({ selectedComplianceRequirement: value });
  };

  selectThirdPartyTesting = (value) => {
    if (value === 'Other') this.setState({ showThirdPartyTestingInput: true });
    else
      this.setState({
        selectedThirdPartyTesting: value,
        showThirdPartyTestingInput: false,
      });
  };

  setThirdPartyTesting = (value) => {
    this.setState({ selectedThirdPartyTesting: value });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(
        this.props.selectedSustainabilityRequirements,
        prevProps.selectedSustainabilityRequirements
      )
    )
      this.setState({
        selectedSustainabilityRequirements:
          this.props.selectedSustainabilityRequirements,
      });
  }

  getData = () => {
    const {
      selectedSustainabilityRequirements,
      selectedProcessingRequirement,
      selectedComplianceRequirement,
      selectedThirdPartyTesting,
    } = this.state;
    return {
      sustainabilityMinReq: selectedSustainabilityRequirements,
      facilityFoodSafety: selectedProcessingRequirement,
      socialCompliance: selectedComplianceRequirement,
      thirdPartyQaPartner: selectedThirdPartyTesting,
    };
  };

  render() {
    const { wildOrFarm } = this.props;
    const {
      selectedSustainabilityRequirements,
      selectedProcessingRequirement,
      selectedComplianceRequirement,
      selectedThirdPartyTesting,
      showProcessingRequirementInput,
      showComplianceRequirementInput,
      showThirdPartyTestingInput,
    } = this.state;
    return (
      <Row gutter={30}>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            label='Sustainability Min Requirements'
          >
            <Select
              suffixIcon={<CaretDownOutlined />}
              className='specification-tag-input'
              onChange={this.selectSustainabilityRequirements}
              mode='multiple'
              value={selectedSustainabilityRequirements}
              placeholder='Please choose sustainability min requirements'
            >
              {wildOrFarm &&
                StringConstants.SPECIFICATION_FIELDS.SUSTAINABILITY_MIN_REQUIREMENTS[
                  wildOrFarm
                ].map((requirement) => (
                  <Option
                    key={requirement.name}
                    value={requirement.name}
                    disabled={!this.checkRequirementSelectable(requirement)}
                  >
                    {requirement.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            label='Sustainability Comments'
            name='sustainabilityMinReqComments'
          >
            <Input
              className='specification-input'
              placeholder='Please enter sustainability comments'
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            label='Processing Facility Food Safety Minimum Requirements'
          >
            <Space>
              <Select
                suffixIcon={<CaretDownOutlined />}
                className='specification-tag-input'
                onChange={this.selectProcessingRequirement}
                value={selectedProcessingRequirement}
              >
                {StringConstants.SPECIFICATION_FIELDS.PROCESSING_FACILITY_FOOD_SAFETY_MIN_REQUIREMENTS.map(
                  (requirement) => (
                    <Option key={requirement} value={requirement}>
                      {requirement}
                    </Option>
                  )
                )}
              </Select>
              {showProcessingRequirementInput && (
                <Input
                  className='specification-input'
                  placeholder='Enter processing facility food safety minimum requirements'
                  onChange={(event) =>
                    this.setProcessingRequirement(event.target.value)
                  }
                />
              )}
            </Space>
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            name='facilityFoodSafetyComments'
            label='Food Safety Comments'
          >
            <Input
              className='specification-input'
              placeholder='Please enter food safety comments'
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            label='Social Compliance Requirements'
          >
            <Space>
              <Select
                suffixIcon={<CaretDownOutlined />}
                className='specification-tag-input'
                onChange={this.selectComplianceRequirement}
                value={selectedComplianceRequirement}
              >
                {StringConstants.SPECIFICATION_FIELDS.SOCIAL_COMPLIANCE_REQUIREMENTS.map(
                  (requirement) => (
                    <Option key={requirement} value={requirement}>
                      {requirement}
                    </Option>
                  )
                )}
              </Select>
              {showComplianceRequirementInput && (
                <Input
                  className='specification-input'
                  placeholder='Enter social compliance requirements'
                  onChange={(event) =>
                    this.setComplianceRequirement(event.target.value)
                  }
                />
              )}
            </Space>
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            label='Social Compliance Comments'
            name='socialComplianceComments'
          >
            <Input
              className='specification-input'
              placeholder='Please enter social compliance comments'
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            label='3rd Party Quality Required Testing Partner'
          >
            <Space>
              <Select
                suffixIcon={<CaretDownOutlined />}
                className='specification-tag-input'
                onChange={this.selectThirdPartyTesting}
                value={selectedThirdPartyTesting}
              >
                {StringConstants.SPECIFICATION_FIELDS.THIRD_PARTY_QUALITY_REQUIRED_TESTING_PARTNER.map(
                  (requirement) => (
                    <Option key={requirement} value={requirement}>
                      {requirement}
                    </Option>
                  )
                )}
              </Select>
              {showThirdPartyTestingInput && (
                <Input
                  className='specification-input'
                  placeholder='Enter 3rd party quality required testing partner'
                  onChange={(event) =>
                    this.setThirdPartyTesting(event.target.value)
                  }
                />
              )}
            </Space>
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            label='Additional Corporate Assurance Comments'
            name='corporateAssuranceComments'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Input
              className='specification-input'
              placeholder='Write Comment...'
            />
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

export default CorporateAssuranceRequirements;
