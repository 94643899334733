import {
  findBidIncotermByCode,
  findChange,
  findPreviousBidForCompany,
  getDisplayIncoterm,
} from './BidUtils';

import { isUndefined } from 'lodash';

const checkBidsLength = (a, b) => {
  return a.bids.length > 0 && b.bids.length > 0;
};

const checkChange = (a, b) => {
  return (
    findChange(a.bids) !== '-' &&
    a.bids.length > 1 &&
    findChange(b.bids) !== '-' &&
    b.bids.length > 1
  );
};

const checkPreviousBid = (a, b, bids) => {
  return (
    !isUndefined(findPreviousBidForCompany(bids, a)) &&
    !isUndefined(findPreviousBidForCompany(bids, b))
  );
};

const checkIncotermByCode = (a, b, incotermCode) => {
  return (
    !isUndefined(
      findBidIncotermByCode(a.bids[0].bidIncoterms, incotermCode).amount
    ) &&
    !isUndefined(
      findBidIncotermByCode(b.bids[0].bidIncoterms, incotermCode).amount
    )
  );
};

const getPreviousBidAmount = (allBids, bid) => {
  return getDisplayIncoterm(
    findPreviousBidForCompany(allBids, bid).bidIncoterms
  ).amount;
};

const getChange = (allBids, bid) => {
  return (
    getDisplayIncoterm(bid.bidIncoterms).amount -
    getPreviousBidAmount(allBids, bid)
  );
};

const getSavings = (targetPrice, bidValue) => {
  return ((targetPrice - bidValue) / targetPrice) * 100;
};

const AuctionTableUtils = {
  checkBidsLength,
  checkChange,
  checkPreviousBid,
  checkIncotermByCode,
  getChange,
  getPreviousBidAmount,
  getSavings,
};

export default AuctionTableUtils;
