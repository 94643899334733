import React, { Component } from 'react';

import { Button } from 'antd';

class ReminderRow extends Component {
  render() {
    return (
      <div className='reminder-list-card'>
        <div className='rmdr-img-bg'>
          <img
            alt='Bid Reminder Icon'
            src={
              require('../../../assets/images/Icon-Bid-Reminder.png').default
            }
          />
        </div>
        <div className='reminder-list-desc'>
          {this.props.id === 0 ? (
            <>
              <p className='desc'>
                <strong>Draft RFQ, action to open for bidding</strong>
              </p>
              <p className='date'>AUC-TFC-000002</p>
            </>
          ) : (
            <>
              <p className='desc'>
                <strong>
                  RFQ ended, action to allocate planned orders to processing
                  plants
                </strong>
              </p>
              <p className='date'>AUC-TFC-000001</p>
            </>
          )}
        </div>
        <div className='view-cta'>
          <Button type='primary'>View</Button>
        </div>
      </div>
    );
  }
}

export default ReminderRow;
