import { Col, Row } from 'antd';
import React, { Component } from 'react';

import ApiUtils from 'src/utils/ApiUtils';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import LoadingComponent from 'src/components/common/LoadingComponent';
import PlannedOrderService from 'src/services/PlannedOrderService';
import PlannedOrderTable from '../../components/plannedOrder/PlannedOrderTable';
import SecureComponent from 'src/components/common/SecureComponent';
import StringConstants from 'src/constants/StringConstants';
import { toast } from 'react-toastify';

class PlannedOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      plannedOrders: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    PlannedOrderService.getAllPlannedOrders({
      status: StringConstants.PLANNED_ORDER_STATUS.OPEN,
    })
      .then((response) => {
        this.setState({ loading: false, plannedOrders: response.data });
      })
      .catch((err) => {
        let errorMsg = ApiUtils.getErrorMessage(
          err,
          'Failed to fetch planned orders'
        );
        toast.error(errorMsg);
        this.setState({ loading: false, plannedOrders: [] });
      });
  }

  render() {
    const { loading, plannedOrders } = this.state;

    if (loading) return <LoadingComponent />;
    else
      return (
        <>
          <HeaderMetadata
            title='Planned Orders'
            description='View planned orders and create RFQ for processing plants to submit their quotes'
          />
          <Row>
            <Col span={10} xs={24} lg={24} xl={24}>
              <PlannedOrderTable data={plannedOrders} loading={loading} />
            </Col>
          </Row>
        </>
      );
  }
}

export default SecureComponent(
  PlannedOrders,
  [StringConstants.COMPANY_TYPES.IMPORTER],
  [
    ...StringConstants.DEFAULT_TEAM_PAGE_ACCESS,
    {
      name: StringConstants.TEAMS.ACCOUNT_MANAGERS,
    },
  ]
);
