import './RouteMap.css';

import { Marker, Popup } from 'react-leaflet';

import React from 'react';
import { getFormattedDate } from 'src/utils/DisplayUtils';
import { isUndefined } from 'lodash';

class PointMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
    };
  }

  updateComponents() {
    if (this.props.hideAnimation) this.setState({ hidden: false });
    else {
      setTimeout(() => {
        this.setState({ hidden: false });

        this.props.resetCenter(
          this.props.position,
          this.props.isDestinationPoint
        );
      }, this.props.renderAfter);
    }
  }

  componentDidMount() {
    this.updateComponents();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.hideAnimation !== this.props.hideAnimation)
      this.updateComponents();
  }

  openPopup = (markerRef) => {
    if (markerRef) {
      markerRef.leafletElement.openPopup();
      if (this.props.closePopup) {
        setTimeout(function () {
          markerRef.leafletElement.closePopup();
        }, 1000);
      }
    }
  };

  render() {
    const {
      position,
      icon,
      pointEvents,
      isPointArrived,
      isSourcePoint,
      isDestinationPoint,
      name,
    } = this.props;
    const { hidden } = this.state;
    if (hidden) return null;
    return (
      <Marker ref={this.openPopup} position={position} icon={icon}>
        <Popup>
          <p>{isSourcePoint ? 'Source' : ''}</p>
          <p>{isDestinationPoint ? 'Destination' : ''}</p>
          <p>{name}</p>
          <p>
            {!isUndefined(pointEvents) && pointEvents.length > 0
              ? (isPointArrived ? 'Arrived on ' : 'Arriving on ') +
                getFormattedDate(pointEvents[0].date)
              : ''}
          </p>
        </Popup>
      </Marker>
    );
  }
}

export default PointMarker;
