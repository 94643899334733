import { AutoComplete, Input } from 'antd';
import React, { useEffect, useState } from 'react';

import throttle from 'lodash.throttle';
import { useChatContext } from 'stream-chat-react';

const UnMemoizedChannelSearch = (props) => {
  const { client, setActiveChannel } = useChatContext();
  const [searching, setSearching] = useState(false);
  const [originalChannels, setOriginalChannels] = useState([]);
  const [channels, setChannels] = useState([]);

  const selectResult = async (value, originalChannels) => {
    const channel = originalChannels.filter((channel) => {
      return channel.data.name === value;
    });

    if (channel && channel.length > 0) setActiveChannel(channel[0]);
  };

  const getChannels = (text, originalChannels) => {
    if (!text || searching) {
      setChannels(originalChannels);
      return;
    }
    setSearching(true);

    let filteredChannels = originalChannels.filter((channel) => {
      return channel.data.name.toLowerCase().includes(text.toLowerCase());
    });
    setChannels(filteredChannels);
    setSearching(false);
  };

  const getChannelsThrottled = throttle(getChannels, 200);

  const onSearch = (value, originalChannels) => {
    getChannelsThrottled(value, originalChannels);
  };

  useEffect(() => {
    getAllChannels(props.user);
  }, []);

  const getAllChannels = async (user) => {
    let channels = await client.queryChannels(
      {
        type: 'messaging',
        members: { $in: [user.id] },
      },
      [{ last_message_at: -1 }],
      { limit: 100 }
    );

    setOriginalChannels(channels);
    setChannels(channels);
  };

  return (
    <div className='str-chat__channel-search'>
      <AutoComplete
        dataSource={
          channels && channels.length > 0
            ? channels.map((channel) => channel.data.name)
            : []
        }
        onSearch={(value) => onSearch(value, originalChannels)}
        onSelect={(value) => selectResult(value, originalChannels)}
      >
        <Input placeholder={'Search by channel name '}></Input>
      </AutoComplete>
    </div>
  );
};

export const ChannelSearch = React.memo(UnMemoizedChannelSearch);
