import * as Sentry from '@sentry/react';

import { call, put, select, takeEvery } from 'redux-saga/effects';

import UserService from 'src/services/UserService';
import { actionTypes } from 'src/actions/types';
import { deleteToken } from 'src/utils/auth/auth';
import { getUser } from 'src/selectors';
import { saveState } from 'src/utils/misc/SessionStorageUtils';

export default function* watchFetchUser() {
  yield takeEvery(actionTypes.FETCH_USER_REQUEST, fetchUser);
}

function* fetchUser(action) {
  const user = yield select(getUser);

  // Make API call only if user state doesn't exist or failed before
  if (Object.keys(user).length === 0 || ('isError' in user && user.isError)) {
    const response = yield call(UserService.getUserProfile);

    if (response.status === 200) {
      let userProfile = response.data;
      Sentry.setUser({
        email: userProfile.email,
        username: userProfile.lastName + ',' + userProfile.firstName,
      });
      // dispatch success action
      yield put({ type: actionTypes.FETCH_USER_SUCCESS, user: userProfile });
      yield put({
        type: actionTypes.SET_REDIRECT_PATH,
        redirectPath: action.redirectPath,
      });
    }
    // dispatch failed action
    else {
      yield put({
        type: actionTypes.FETCH_USER_FAILURE,
        payload: 'Fetch user profile error ',
      });
      yield put({
        type: actionTypes.SET_REDIRECT_PATH,
        redirectPath: action.redirectPath,
      });
      deleteToken();
    }
  }

  saveState(user);

  yield put({ type: actionTypes.FETCH_USER_SUCCESS, user });
  yield put({
    type: actionTypes.SET_REDIRECT_PATH,
    redirectPath: action.redirectPath,
  });
}
