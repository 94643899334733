import '../../../pages/auction/plants/PlaceBid.css';

import { Button, InputNumber, Select, Space, Table } from 'antd';
import React, { Component } from 'react';
import { find, isNull, isUndefined, orderBy } from 'lodash';

import ApiUtils from 'src/utils/ApiUtils';
import AuctionService from 'src/services/AuctionService';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import { CaretDownOutlined } from '@ant-design/icons';
import LoadingComponent from 'src/components/common/LoadingComponent';
import PropTypes from 'prop-types';
import RouteConstants from 'src/constants/RouteConstants';
import SecureComponent from 'src/components/common/SecureComponent';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { findActualBiddedIncoterm } from 'src/utils/BidUtils';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

const { Option } = Select;

class SimulateBid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      auctionDetails: {},
      existingBids: {},
      rowAddDisabled: false,
      rowIndex: 0,
      simulatingBids: false,
      canSubmit: false,
    };
  }

  static propTypes = {
    auctionDetails: PropTypes.object,
    auctionBids: PropTypes.object,
  };

  componentDidMount = () => {
    this.setState({ loading: true });
    const { auctionDetails, auctionBids } = this.props;

    if (isUndefined(auctionDetails) && isUndefined(auctionBids)) {
      let auctionId = this.props.match.params.id;

      let auctionResponsePromise = AuctionService.getAuctionDetails(auctionId);
      let auctionBidsResponsePromise = AuctionService.getAuctionBids(auctionId);

      Promise.all([auctionResponsePromise, auctionBidsResponsePromise])
        .then((responses) => {
          let auctionDetails = responses[0].data;
          let auctionBids = responses[1].data;

          auctionBids.bids = orderBy(auctionBids.bids, ['createdAt'], ['desc']);

          this.setState({
            auctionDetails,
            auctionBids,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
        });
    } else
      this.setState({
        auctionDetails,
        auctionBids,
        loading: false,
      });
  };

  isIncotermAvailable = (incotermCode) => {
    const { auctionDetails } = this.state;
    return (
      auctionDetails &&
      find(auctionDetails.auctionIncoterms, function (o) {
        return o.incoterm.code === incotermCode;
      })
    );
  };

  isIncotermEnabled = (biddedIncoterm, incoterm) => {
    if (biddedIncoterm) return biddedIncoterm === incoterm;
    else return this.isIncotermAvailable(incoterm);
  };

  findExistingBidIncoterm = (rowIndex) => {
    const { data, auctionBids } = this.state;
    const company = this.state['company_' + rowIndex];
    let biddedIncoterm;
    let existingBid =
      auctionBids &&
      find(auctionBids.bids, function (o) {
        return o.companyId === company;
      });

    if (existingBid) {
      biddedIncoterm = findActualBiddedIncoterm(existingBid.bidIncoterms);
      return biddedIncoterm && biddedIncoterm.code;
    } else {
      existingBid = find(data, function (o) {
        return o.company === company;
      });
      return existingBid && existingBid.incoterm;
    }
  };

  simulateBids = async () => {
    const auctionId = this.props.match.params.id;
    const { rowIndex } = this.state;
    this.setState({ simulatingBids: true });

    let bids = [];

    for (var index = 0; index < rowIndex; index++) {
      let bidIncoterms = [
        {
          incotermCode: this.state['incoterm_' + index],
          amount: this.state['amount_' + index],
        },
      ];
      bids.push({
        plantCompanyId: this.state['company_' + index],
        fclAvailable: this.state['fclAvailable_' + index],
        incoterms: bidIncoterms,
      });
    }

    AuctionService.simulateBid(auctionId, { bids })
      .then(() => {
        toast.success('Simulated quote successfully');
        this.setState(
          {
            simulatingBids: false,
          },
          () =>
            this.props.history.push(
              UrlGenerator.getUiUrlWithPathParams(RouteConstants.AUCTION, {
                id: auctionId,
              })
            )
        );
      })
      .catch((error) => {
        let errorMessage = ApiUtils.getErrorMessage(error);
        toast.error('Failed to simulate quote - ' + errorMessage);
        this.setState({ simulateBidModalVisible: false, loading: false });
      });
  };

  addNewRow = () => {
    const { data } = this.state;
    let dataSource = [...data];

    dataSource.push({
      company: undefined,
      fclAvailable: undefined,
      incoterm: undefined,
      amount: 0.0,
      rowIndex: dataSource.length,
    });

    this.setState({
      data: dataSource,
      rowAddDisabled: !this.state.rowAddDisabled,
      rowIndex: dataSource.length - 1,
      canSubmit: false,
    });
  };

  saveRow = (rowIndex) => {
    const { data } = this.state;
    const company = this.state['company_' + rowIndex];
    const fclAvailable = this.state['fclAvailable_' + rowIndex];
    const incoterm = this.state['incoterm_' + rowIndex];
    const amount = this.state['amount_' + rowIndex];

    let dataSource = [...data];

    let newRowIndex = this.state.rowIndex;

    if (!dataSource[rowIndex].editable) newRowIndex = this.state.rowIndex + 1;

    dataSource[rowIndex] = {
      ...dataSource[rowIndex],
      company,
      fclAvailable,
      incoterm,
      amount,
      editable: true,
    };

    this.setState({
      rowAddDisabled: false,
      data: dataSource,
      rowIndex: newRowIndex,
      canSubmit: true,
    });
  };

  resetState = (rowIndex) => {
    const { data } = this.state;
    let dataSource = [...data];

    dataSource.splice(rowIndex, 1);

    dataSource = dataSource.map((elt, index) => {
      return {
        ...elt,
        rowIndex: index,
      };
    });

    delete this.state[['company_' + rowIndex]];
    delete this.state[['fclAvailable_' + rowIndex]];
    delete this.state[['incoterm_' + rowIndex]];
    delete this.state[['amount_' + rowIndex]];

    this.setState({
      data: dataSource,
      rowAddDisabled: false,
      rowIndex: this.state.rowIndex - 1,
      canSubmit: true,
    });
  };

  isRowUpdated = (rowIndex) => {
    const { data } = this.state;
    const company = this.state['company_' + rowIndex];
    const fclAvailable = this.state['fclAvailable_' + rowIndex];
    const incoterm = this.state['incoterm_' + rowIndex];
    const amount = this.state['amount_' + rowIndex];

    if (!data[rowIndex]) return false;

    return (
      company !== data[rowIndex]['company'] ||
      fclAvailable !== data[rowIndex]['fclAvailable'] ||
      incoterm !== data[rowIndex]['incoterm'] ||
      amount !== data[rowIndex]['amount']
    );
  };

  validateInput = (rowIndex) => {
    const company = this.state['company_' + rowIndex];
    const fclAvailable = this.state['fclAvailable_' + rowIndex];
    const incoterm = this.state['incoterm_' + rowIndex];
    const amount = this.state['amount_' + rowIndex];

    if (
      isUndefined(company) ||
      isUndefined(fclAvailable) ||
      isUndefined(incoterm) ||
      amount === 0.0 ||
      !this.isRowUpdated(rowIndex)
    )
      return false;
    else return true;
  };

  getColumnsForTable = () => {
    const { auctionDetails } = this.state;
    const columns = [
      {
        title: 'Processing Plant',
        render: (record) => {
          return (
            <Select
              suffixIcon={<CaretDownOutlined />}
              onChange={(value) =>
                this.setState({ ['company_' + record.rowIndex]: value })
              }
              placeholder='Choose a plant'
              className='planned-vi-sel-btn'
              value={this.state['company_' + record.rowIndex]}
            >
              {auctionDetails.biddingCompanies.map((company) => {
                if (isNull(company.mainCompany))
                  return (
                    <Option key={company.id} value={company.id}>
                      {company.name}
                    </Option>
                  );
              })}
            </Select>
          );
        },
      },
      {
        title: 'FCL Available',
        render: (record) => (
          <InputNumber
            className='decimalval input-field'
            step={1}
            min={1}
            placeholder='Enter your FCL to commit for quote'
            onChange={(value) =>
              this.setState({
                ['fclAvailable_' + record.rowIndex]: value,
              })
            }
            precision={0}
            value={this.state['fclAvailable_' + record.rowIndex]}
          />
        ),
      },
      {
        title: 'Incoterm',
        ellipsis: {
          showTitle: false,
        },
        render: (record) => (
          <Select
            suffixIcon={<CaretDownOutlined />}
            placeholder='Choose an incoterm'
            onChange={(value) =>
              this.setState({ ['incoterm_' + record.rowIndex]: value })
            }
            className='planned-vi-sel-btn'
            value={this.state['incoterm_' + record.rowIndex]}
          >
            {StringConstants.INCOTERMS.map((incoterm) => {
              return (
                this.isIncotermEnabled(
                  this.findExistingBidIncoterm(record.rowIndex),
                  incoterm.code
                ) && (
                  <Option key={incoterm.code} value={incoterm.code}>
                    {incoterm.code}
                  </Option>
                )
              );
            })}
          </Select>
        ),
      },
      {
        title: 'Quote price per lb',

        render: (record) => (
          <InputNumber
            className='input-field decimalval'
            step={0.01}
            min={0}
            onChange={(value) =>
              this.setState({ ['amount_' + record.rowIndex]: value })
            }
            formatter={(value) => '$ ' + value}
            placeholder='Enter quote amount'
            value={this.state['amount_' + record.rowIndex]}
          />
        ),
      },
      {
        title: '',
        key: 'action',
        render: (record) => {
          return (
            <Space className='text-center'>
              <Button
                className='primary-button'
                type='primary'
                onClick={() => this.saveRow(record.rowIndex)}
                disabled={!this.validateInput(record.rowIndex)}
              >
                Save
              </Button>
              {record.rowIndex === this.state.rowIndex && !record.editable && (
                <Button
                  className='cancel-button'
                  type='primary'
                  onClick={() => this.resetState(record.rowIndex)}
                >
                  Cancel
                </Button>
              )}
            </Space>
          );
        },
      },
    ];

    return columns;
  };

  render() {
    const { loading, data, rowAddDisabled, simulatingBids, canSubmit } =
      this.state;

    if (loading) return <LoadingComponent />;

    return (
      <>
        <CardWithTitle
          title='Simulate quotes'
          action={
            <Button
              type='primary'
              className='primary-button'
              onClick={() => this.addNewRow()}
              disabled={rowAddDisabled}
            >
              Add quote
            </Button>
          }
        >
          <Table
            className='plan-order-over-tble'
            locale={{ filterConfirm: 'Apply' }}
            rowKey={(row) => (row && row.company ? row.company.id : 'new row')}
            columns={this.getColumnsForTable()}
            dataSource={data}
            pagination={data && data.length >= 10}
          />
        </CardWithTitle>

        <div className='editprofile-btn-area mt-15 mb-15'>
          <Button
            className='primary-full'
            type='primary'
            onClick={() => this.simulateBids()}
            disabled={simulatingBids || data.length === 0 || !canSubmit}
            loading={simulatingBids}
          >
            Submit Bids
          </Button>
        </div>
      </>
    );
  }
}

export default withRouter(
  SecureComponent(
    SimulateBid,
    [StringConstants.COMPANY_TYPES.IMPORTER],
    [
      {
        name: StringConstants.TEAMS.SOURCING,
        role: StringConstants.TEAM_ROLES.MANAGER,
      },
    ]
  )
);
