import ApiManager from 'src/api/ApiManager';
import HttpConstants from 'src/constants/HttpConstants';
import UrlConstants from 'src/constants/UrlConstants';

const MetadataService = {
  getAllWarehouses: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_WAREHOUSES.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  getSpecificationCustomers: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_SPECIFICATION_CUSTOMERS.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  getSpecificationBrands: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_SPECIFICATION_BRANDS.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  getSeaFoodList: () => {
    return ApiManager.makeApiCall(
      UrlConstants.GET_SEAFOOD_LIST.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  getCountries: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_COUNTRIES.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  getDictionaryDropdowns: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_DICTIONARY_DROPDOWNS.USECASE,
      HttpConstants.GET_METHOD,
      data
    );
  },
};

export default MetadataService;
