/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';

import LoadingComponent from 'src/components/common/LoadingComponent';
import UrlGenerator from 'src/api/UrlGenerator';
import cookie from 'react-cookies';
import { getUserDataFromCookie } from 'src/utils/auth/AuthUtils';
import { isUndefined } from 'lodash';
import { login } from '../../../utils/auth/auth0';
import { withRouter } from 'react-router';

class Auth0Login extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const redirect = UrlGenerator.getParamsFromUrl(
      this.props.location.search,
      'redirect'
    );
    const loggedInUserData = getUserDataFromCookie();
    if (!isUndefined(loggedInUserData)) {
      cookie.remove('token');
      sessionStorage.removeItem('state');
    }
    login(redirect);
  }

  render() {
    return (
      <div style={{ height: '90vh' }}>
        <LoadingComponent title={'Logging you in...'} />
      </div>
    );
  }
}

export default withRouter(Auth0Login);
