import { actionTypes } from './types';

/**
 * @param {number} payload
 */
export const setUnreadCount = (payload) => {
  return {
    type: actionTypes.SET_UNREAD_COUNT,
    payload: payload,
  };
};
