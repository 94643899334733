import './Incoterm.css';

import React, { Component } from 'react';

import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

class AuctionIncoterm extends Component {
  static propTypes = {
    selectedData: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      cnf: this.props.selectedData ? this.getIncotermValue('CNF') : false,
      ddpcy: this.props.selectedData ? this.getIncotermValue('DDPCY') : false,
      incotermCharge: this.props.selectedData
        ? this.getIncotermCharge('CNF')
        : 0,
    };
  }

  getIncotermValue = (incotermCode) => {
    let selected = false;
    this.props.selectedData.forEach((d) => {
      if (d.incoterm.code === incotermCode) selected = true;
    });
    return selected;
  };

  getIncotermCharge = (incotermCode) => {
    let incotermCharge = 0;
    this.props.selectedData.forEach((d) => {
      if (d.incoterm.code === incotermCode) incotermCharge = d.charges;
    });
    return incotermCharge;
  };

  getSelectedIncoterms = () => {
    const { cnf, ddpcy, incotermCharge } = this.state;
    const obj = {
      charges: 0,
      incoterm: {
        code: 0,
      },
    };
    const data = [];
    if (cnf) {
      let cnf_ = cloneDeep(obj);
      cnf_.charges = incotermCharge;
      cnf_.incoterm.code = 'CNF';
      data.push(cnf_);
    }
    if (ddpcy) {
      let ddpcy_ = cloneDeep(obj);
      ddpcy_.charges = 0;
      ddpcy_.incoterm.code = 'DDPCY';
      data.push(ddpcy_);
    }
    return data;
  };

  render() {
    const { cnf, ddpcy } = this.state;

    return (
      <div className='auc-detail-inco mt-15'>
        <Checkbox
          className={
            cnf
              ? 'incoterms incoterms-dark incoterm-cnf-dark'
              : 'incoterms incoterm-cnf-light'
          }
          checked={cnf}
          disabled={true}
        >
          Cost and
          <br /> Freight
        </Checkbox>

        <Checkbox
          className={
            ddpcy
              ? 'incoterms incoterms-dark incoterm-cy-dark'
              : 'incoterms incoterm-cy-light'
          }
          checked={ddpcy}
          disabled={true}
        >
          Delivered Duty Paid Container Yard
        </Checkbox>
      </div>
    );
  }
}

export default AuctionIncoterm;
