import ApiManager from 'src/api/ApiManager';
import HttpConstants from 'src/constants/HttpConstants';
import UrlConstants from 'src/constants/UrlConstants';

const TeamService = {
  getAllTeams: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_TEAMS.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  getTeamUsers: (teamId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_TEAM_USERS.USECASE,
      HttpConstants.GET_METHOD,
      { ':teamId': teamId }
    );
  },
  updateUserRole: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_USER_ROLE.USECASE,
      HttpConstants.PATCH_METHOD,
      data
    );
  },
  removeUser: (teamId, userId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.REMOVE_USER.USECASE,
      HttpConstants.DELETE_METHOD,
      {
        ':teamId': teamId,
        ':userId': userId,
      }
    );
  },
  inviteUser: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.INVITE_USER_TO_TEAM.USECASE,
      HttpConstants.POST_METHOD,
      data
    );
  },
  resendInvite: (id) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.RESEND_INVITE_USER_TO_TEAM.USECASE,
      HttpConstants.POST_METHOD,
      { ':id': id }
    );
  },
  revokeInvite: (id) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.REVOKE_USER_TEAM_INVITE.USECASE,
      HttpConstants.DELETE_METHOD,
      { ':id': id }
    );
  },
  getPendingInvites: (teamId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PENDING_INVITES.USECASE,
      HttpConstants.GET_METHOD,
      { ':teamId': teamId }
    );
  },
};

export default TeamService;
