import { Button, Checkbox, Input, Select } from 'antd';
import { CaretDownOutlined, LoadingOutlined } from '@ant-design/icons';
import { isNull, isUndefined } from 'lodash';
import { isUserImporter, isUserPartOfTeam } from 'src/helpers/user';

import ApiUtils from 'src/utils/ApiUtils';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import ChooseTermsAndConditions from 'src/components/termsAndConditions/ChooseTermsAndConditions';
import CompanyService from 'src/services/CompanyService';
import ErrorComponent from 'src/components/common/ErrorComponent';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import LoadingComponent from 'src/components/common/LoadingComponent';
import PaymentTerms from 'src/constants/PaymentTermConstants';
import PlannedOrderReview from 'src/components/plannedOrder/PlannedOrderReview';
import ProformaService from 'src/services/ProformaService';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import SecureComponent from 'src/components/common/SecureComponent';
import StringConstants from 'src/constants/StringConstants';
import TermsAndConditionsService from 'src/services/TermsAndConditionsService';
import UrlGenerator from 'src/api/UrlGenerator';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

const { Option } = Select;

class CreateDirectProforma extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentTerms: PaymentTerms[0],
      bidValue: 0,
      incoterm: undefined,
      buyFrom: undefined,
      payTo: undefined,
      termsAndConditions: [],
      fetchingTAndCs: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.fetchTermsAndConditions();
  }

  fetchTermsAndConditions = () => {
    this.setState({ fetchingTAndCs: true });
    TermsAndConditionsService.getAllTermsAndConditions()
      .then((response) => {
        this.setState({
          termsAndConditions: response.data,
          fetchingTAndCs: false,
        });
      })
      .catch((error) => {
        if (!isUndefined(error)) {
          let errorMessage = ApiUtils.getErrorMessage(error);
          toast.error(errorMessage);
        } else toast.error('Failed to fetch T&Cs');
        this.setState({ termsAndConditions: [], fetchingTAndCs: false });
      });
  };

  fetchPlantOverview = (buyFrom) => {
    CompanyService.getProcessingPlantOverview(buyFrom)
      .then((response) => {
        let payToList = [];
        if (!isNull(response.data.company.mainCompany)) {
          payToList.push(response.data.company.mainCompany);
        } else payToList.push(response.data.company);
        this.setState({
          payToList,
          fetchingPayTos: false,
        });
      })
      .catch((err) => {
        let errorMessage = ApiUtils.getErrorMessage(err);
        toast.error(errorMessage);
        this.setState({ fetchingPayTos: false });
      });
  };

  handleOk = () => {
    const { data } = this.props.location;
    const { paymentTerms, bidValue, incoterm, buyFrom, payTo } = this.state;

    let proformaRequest = {
      plannedOrders: data.selectedPlannedOrders.map((pl) => pl.id),
      bidIncoterm: {
        incotermCode: incoterm,
        amount: bidValue,
      },
      buyFromCompanyId: buyFrom ? buyFrom : undefined,
      fetchingPayTos: false,
      payToList: [],
      payToCompanyId: payTo ? payTo : undefined,
      paymentTerms: paymentTerms,
      termsAndConditions: this.chooseTAndCs.getUpdatedData(),
    };

    this.onProformaCreate(proformaRequest);
  };

  onProformaCreate = (data) => {
    try {
      if (data.bidIncoterm.amount <= 0.0) {
        toast.warning('Quote amount cannot be empty or negative');
        return;
      }

      if (
        isUndefined(data.bidIncoterm.incotermCode) ||
        isUndefined(data.buyFromCompanyId) ||
        isUndefined(data.payToCompanyId)
      ) {
        toast.warning('Please fill all mandatory fields');
        return;
      }

      this.setState({ loading: true });
      ProformaService.createDirectProforma(data)
        .then((response) => {
          toast.success('Created Proforma successfully');
          this.setState({ loading: false });
          this.props.history.replace(
            UrlGenerator.getUiUrlWithPathParams(
              RouteConstants.PROFORMA_DETAIL,
              {
                id: response.data.id,
              }
            )
          );
        })
        .catch((error) => {
          let errorMessage = ApiUtils.getErrorMessage(
            error,
            'Failed to create proforma'
          );
          toast.error(errorMessage);
          this.setState({ loading: false });
        });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { data } = this.props.location;
    const {
      paymentTerms,
      bidValue,
      incoterm,
      buyFrom,
      payToList,
      fetchingPayTos,
      payTo,
      termsAndConditions,
      fetchingTAndCs,
      loading,
    } = this.state;

    if (isUndefined(data))
      return (
        <ErrorComponent
          title='Cannot create direct proforma'
          tip='No planned orders selected'
        />
      );

    if (fetchingTAndCs) return <LoadingComponent />;

    return (
      <>
        <HeaderMetadata
          title={'Create Direct Proforma from Planned Order(s)'}
        />
        <CardWithTitle title='Create Proforma'>
          {data && data.selectedPlannedOrders && (
            <PlannedOrderReview data={data.selectedPlannedOrders} />
          )}

          <div
            className='rscb-details   select-quantity-input'
            span={24}
            xs={24}
            lg={24}
            xl={24}
          >
            <ul className='rscb-items mt-10'>
              <li className='details-section'>
                <span className='left-item'>Buy from: </span>
                <span className='right-item'>
                  <Select
                    className='planned-vi-sel-btn'
                    showSearch
                    style={{ width: '90%' }}
                    placeholder='Select a plant to buy planned orders from'
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) =>
                      this.setState(
                        {
                          buyFrom: value,
                          payTo: undefined,
                          fetchingPayTos: true,
                        },
                        () => this.fetchPlantOverview(value)
                      )
                    }
                    suffixIcon={
                      fetchingPayTos ? (
                        <LoadingOutlined />
                      ) : (
                        <CaretDownOutlined />
                      )
                    }
                  >
                    {data.plants.map((plant) => {
                      return (
                        <Select.Option
                          value={plant.company.id}
                          key={plant.company.id}
                        >
                          {plant.company.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </span>
              </li>
              {!isUndefined(buyFrom) && !fetchingPayTos && (
                <li className='details-section'>
                  <span className='left-item'>Pay to: </span>
                  <span className='right-item'>
                    <Select
                      className='planned-vi-sel-btn'
                      showSearch
                      style={{ width: '90%' }}
                      placeholder='Select a plant to pay to for planned orders'
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => this.setState({ payTo: value })}
                      suffixIcon={
                        fetchingPayTos ? (
                          <LoadingOutlined />
                        ) : (
                          <CaretDownOutlined />
                        )
                      }
                      disabled={isUndefined(buyFrom)}
                      value={payTo}
                    >
                      {payToList.map((plant) => (
                        <Option value={plant.id} key={plant.id}>
                          {plant.name}
                        </Option>
                      ))}
                    </Select>
                  </span>
                </li>
              )}
            </ul>
            <ul className='rscb-items'>
              <li className='details-section'>
                <span className='left-item'>Payment Terms:</span>
                <span className='right-item'>
                  <Select
                    suffixIcon={<CaretDownOutlined />}
                    className='planned-vi-sel-btn'
                    defaultValue={paymentTerms}
                    style={{ width: 200 }}
                    value={paymentTerms}
                    onChange={(value) => this.setState({ paymentTerms: value })}
                  >
                    {PaymentTerms.map((value, index) => {
                      return (
                        <Select.Option value={value} key={index}>
                          {value}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </span>
              </li>
            </ul>
            <p className='modal-title color-blue mt-15'>Award Price</p>
            <ul className='rscb-items'>
              <li className='details-section'>
                <span className='left-item'>Price/Lb ($)</span>
                <span className='right-item'>
                  <Input
                    className='input-field'
                    style={{ width: 200 }}
                    value={bidValue}
                    onChange={(event) =>
                      this.setState({ bidValue: event.target.value })
                    }
                  />
                </span>
              </li>
              <li className='details-section'>
                <span className='left-item'>Incoterm:</span>
                <span className='right-item'>
                  <Checkbox
                    className={
                      incoterm === 'CNF'
                        ? 'incoterms incoterms-dark incoterm-cnf-dark'
                        : 'incoterms incoterm-cnf-light'
                    }
                    onChange={(e) => {
                      this.setState({ incoterm: 'CNF' });
                    }}
                    checked={incoterm === 'CNF'}
                  >
                    Cost and
                    <br /> Freight
                  </Checkbox>
                  <Checkbox
                    className={
                      incoterm === 'DDPCY'
                        ? 'incoterms incoterms-dark incoterm-cy-dark'
                        : 'incoterms incoterm-cy-light'
                    }
                    onChange={(event) => {
                      this.setState({ incoterm: 'DDPCY' });
                    }}
                    checked={incoterm === 'DDPCY'}
                  >
                    Delivered Duty Paid Container Yard
                  </Checkbox>
                </span>
              </li>
            </ul>
            <ChooseTermsAndConditions
              data={termsAndConditions}
              originalSelection={[]}
              hideCard={true}
              allowSelection={
                isUserImporter() &&
                isUserPartOfTeam(StringConstants.TEAMS.SOURCING)
              }
              toEdit={true}
              ref={(ref) => (this.chooseTAndCs = ref)}
            />
          </div>
        </CardWithTitle>
        <div className='editprofile-btn-area mt-15 mb-15'>
          <Button
            className='primary-full'
            type='primary'
            onClick={() => this.handleOk()}
            loading={loading}
          >
            Create Proforma
          </Button>
        </div>
      </>
    );
  }
}

export default SecureComponent(
  withRouter(CreateDirectProforma),
  [StringConstants.COMPANY_TYPES.IMPORTER],
  [{ name: StringConstants.TEAMS.SOURCING }]
);
