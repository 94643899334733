import { Col, Form, Input, Row, Select, Space } from 'antd';
import { capitalize, find, isEmpty, isUndefined } from 'lodash';
import {
  convertToCode,
  getSizeCombinedDisplayText,
} from 'src/utils/DisplayUtils';

import { CaretDownOutlined } from '@ant-design/icons';
import MetadataService from 'src/services/MetadataService';
import PropTypes from 'prop-types';
import React from 'react';
import SpecificationUtils from 'src/utils/SpecificationUtils';
import StringConstants from 'src/constants/StringConstants';

const { Option } = Select;

class PhysicalAttributes extends React.Component {
  static propTypes = {
    productForms: PropTypes.array.isRequired,
    selectedProductForm: PropTypes.string,
    otherDropdowns: PropTypes.array,
    selectedTreatment: PropTypes.string,
    selectedProductType: PropTypes.string,
    additionalComments: PropTypes.array,
    minSizeCount: PropTypes.number,
    minSizeCountUom: PropTypes.string,
    maxSizeCount: PropTypes.number,
    maxSizeCountUom: PropTypes.string,
    variance: PropTypes.number,
    varianceSymbol: PropTypes.string,
    varianceUom: PropTypes.string,
    scaledState: PropTypes.string,
    skinState: PropTypes.string,
    boneState: PropTypes.string,
    frozenState: PropTypes.string,
    cookedState: PropTypes.string,
    iqfIvp: PropTypes.string,
  };

  static defaultProps = {
    varianceSymbol: StringConstants.SPECIFICATION_FIELDS.VARIANCE_SYMBOLS[0],
    varianceUom: StringConstants.UNITS.POUNDS,
    frozenState: StringConstants.SPECIFICATION_FIELDS.FROZEN_TYPE[0],
    cookedState: StringConstants.SPECIFICATION_FIELDS.COOKED_TYPE[0],
    iqfIvp: StringConstants.SPECIFICATION_FIELDS.IQF_OR_IVP[0],
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedProductForm: this.props.selectedProductForm,
      otherDropdowns: this.props.otherDropdowns,
      selectedTreatment: this.props.selectedTreatment,
      showTreatmentInput: false,
      selectedTreatmentValue: undefined,
      minSizeCount: this.props.minSizeCount,
      minSizeCountUom: this.props.minSizeCountUom,
      maxSizeCount: this.props.maxSizeCount,
      maxSizeCountUom: this.props.maxSizeCountUom,
      variance: this.props.variance,
      varianceSymbol: this.props.varianceSymbol,
      varianceUom: this.props.varianceUom,
      scaledState: this.props.scaledState
        ? this.props.scaledState
        : this.props.otherDropdowns &&
          Object.keys(this.props.otherDropdowns).includes(
            StringConstants.SPECIFICATION_FIELDS.SCALED_OR_UNSCALED
          )
        ? this.props.otherDropdowns[
            StringConstants.SPECIFICATION_FIELDS.SCALED_OR_UNSCALED
          ][0].code
        : undefined,
      skinState: this.props.skinState
        ? this.props.skinState
        : this.props.otherDropdowns &&
          Object.keys(this.props.otherDropdowns).includes(
            StringConstants.SPECIFICATION_FIELDS.SKIN_ON_OR_SKIN_OFF
          )
        ? this.props.otherDropdowns[
            StringConstants.SPECIFICATION_FIELDS.SKIN_ON_OR_SKIN_OFF
          ][0].code
        : undefined,
      boneState: this.props.boneState
        ? this.props.boneState
        : this.props.otherDropdowns &&
          Object.keys(this.props.otherDropdowns).includes(
            StringConstants.SPECIFICATION_FIELDS.BONELESS_OR_BONE_IN
          )
        ? this.props.otherDropdowns[
            StringConstants.SPECIFICATION_FIELDS.BONELESS_OR_BONE_IN
          ][0].code
        : undefined,
      frozenState: this.props.frozenState,
      cookedState: this.props.cookedState,
      iqfIvp: this.props.iqfIvp,
    };
  }

  fetchOtherDropdowns = async (selectedProductForm, selectedProductType) => {
    const { productForms } = this.props;
    let actualSelectedProductType = selectedProductType;

    if (isUndefined(actualSelectedProductType))
      actualSelectedProductType = this.props.selectedProductType;

    let productForm = find(productForms, function (o) {
      return o.code === selectedProductForm;
    });
    let request = {
      productType: actualSelectedProductType,
      fields: [
        {
          field: productForm && productForm.field,
          code: productForm && productForm.code,
        },
      ],
    };

    let dictionaryDropdownsRes = await MetadataService.getDictionaryDropdowns(
      request
    );

    return dictionaryDropdownsRes;
  };

  selectProductForm = async (value) => {
    let dictionaryDropdownsRes = await this.fetchOtherDropdowns(value);

    this.setState({
      selectedProductForm: value,
      otherDropdowns: dictionaryDropdownsRes.data,
      scaledState: Object.keys(dictionaryDropdownsRes.data).includes(
        StringConstants.SPECIFICATION_FIELDS.SCALED_OR_UNSCALED
      )
        ? dictionaryDropdownsRes.data[
            StringConstants.SPECIFICATION_FIELDS.SCALED_OR_UNSCALED
          ][0].code
        : undefined,
      skinState: Object.keys(dictionaryDropdownsRes.data).includes(
        StringConstants.SPECIFICATION_FIELDS.SKIN_ON_OR_SKIN_OFF
      )
        ? dictionaryDropdownsRes.data[
            StringConstants.SPECIFICATION_FIELDS.SKIN_ON_OR_SKIN_OFF
          ][0].code
        : undefined,
      boneState: Object.keys(dictionaryDropdownsRes.data).includes(
        StringConstants.SPECIFICATION_FIELDS.BONELESS_OR_BONE_IN
      )
        ? dictionaryDropdownsRes.data[
            StringConstants.SPECIFICATION_FIELDS.BONELESS_OR_BONE_IN
          ][0].code
        : undefined,
    });
  };

  setMinSizeCount = (value) => this.setState({ minSizeCount: value });

  setMaxSizeCount = (value) => this.setState({ maxSizeCount: value });

  setMinSizeCountUom = (value) => this.setState({ minSizeCountUom: value });

  setMaxSizeCountUom = (value) => this.setState({ maxSizeCountUom: value });

  setVarianceSymbol = (value) => this.setState({ varianceSymbol: value });

  setVariance = (value) => this.setState({ variance: value });

  setVarianceUom = (value) => this.setState({ varianceUom: value });

  selectTreatment = (value) => {
    if (value.includes('Other'))
      this.setState({
        selectedTreatment: ['Other'],
        showTreatmentInput: true,
      });
    else if (value.includes('None, All Natural'))
      this.setState({
        selectedTreatment: ['None, All Natural'],
        showTreatmentInput: false,
      });
    else
      this.setState({
        selectedTreatment: value,
        showTreatmentInput: false,
      });
  };

  setTreatment = (value) => {
    this.setState({ selectedTreatmentValue: value });
  };

  checkTreatmentSelectable = (value) => {
    const { selectedTreatment } = this.state;

    if (isEmpty(selectedTreatment)) return true;

    let noneOrOtherTreatmentSelected =
      StringConstants.SPECIFICATION_FIELDS.TREATMENT_TYPE.filter(
        (treatment) =>
          selectedTreatment.includes(treatment) &&
          (treatment.includes('None') || treatment.includes('Other'))
      );

    if (!isEmpty(noneOrOtherTreatmentSelected))
      return noneOrOtherTreatmentSelected.includes(value);
    else return true;
  };

  selectScaledState = (value) => {
    this.setState({ scaledState: value });
  };

  selectSkinState = (value) => {
    this.setState({ skinState: value });
  };

  selectBoneState = (value) => {
    this.setState({ boneState: value });
  };

  selectFrozenType = (value) => {
    this.setState({ frozenState: value });
  };

  selectCookedType = (value) => {
    this.setState({ cookedState: value });
  };

  setIqfOrIvp = (value) => {
    this.setState({ iqfIvp: value });
  };

  componentDidUpdate(prevProps) {
    const { selectedProductType, otherDropdowns } = this.props;
    if (prevProps.selectedProductType !== selectedProductType)
      this.setState({
        otherDropdowns,
        minSizeCount: undefined,
        minSizeCountUom:
          selectedProductType === 'SHRIMP'
            ? StringConstants.SPECIFICATION_FIELDS.COUNT_PER_LB
            : undefined,
        maxSizeCount: undefined,
        maxSizeCountUom:
          selectedProductType === 'SHRIMP'
            ? StringConstants.SPECIFICATION_FIELDS.COUNT_PER_LB
            : undefined,
        variance:
          selectedProductType === 'SHRIMP' ? undefined : this.props.variance,
      });
  }

  getData = () => {
    const { selectedProductType } = this.props;
    const {
      selectedProductForm,
      minSizeCount,
      minSizeCountUom,
      maxSizeCount,
      maxSizeCountUom,
      variance,
      varianceSymbol,
      varianceUom,
      scaledState,
      skinState,
      boneState,
      frozenState,
      cookedState,
      selectedTreatment,
      selectedTreatmentValue,
      iqfIvp,
    } = this.state;
    let data = {
      productForm: selectedProductForm,
      productType: selectedProductType,
      scaledState: scaledState && convertToCode(scaledState),
      skinState: skinState && convertToCode(skinState),
      boneState: boneState && convertToCode(boneState),
      frozenState: frozenState && convertToCode(frozenState),
      cookedState: cookedState && convertToCode(cookedState),
      treatment:
        selectedTreatment &&
        (typeof selectedTreatment === 'object'
          ? selectedTreatment[0] === 'Other'
            ? selectedTreatmentValue
            : selectedTreatment.join(', ')
          : selectedTreatment),
      iqfIvp: iqfIvp,
    };

    let sizeRange = {
      minPieceSize: parseFloat(minSizeCount),
      minUom: minSizeCountUom,
      maxPieceSize: parseFloat(maxSizeCount),
      maxUom: maxSizeCountUom,
    };

    if (selectedProductType === 'FINFISH') {
      sizeRange.variance = parseFloat(variance);
      sizeRange.varianceSymbol = varianceSymbol;
      sizeRange.varianceUom = varianceUom;
    }

    data.sizeRange = [sizeRange];

    return data;
  };

  render() {
    const { productForms, selectedProductType } = this.props;

    const {
      otherDropdowns,
      selectedTreatment,
      showTreatmentInput,
      minSizeCount,
      minSizeCountUom,
      maxSizeCount,
      maxSizeCountUom,
      variance,
      varianceSymbol,
      varianceUom,
      scaledState,
      skinState,
      boneState,
      frozenState,
      cookedState,
      iqfIvp,
    } = this.state;
    return (
      <Row gutter={30}>
        <Col span={24} xs={24} lg={16} xl={16}>
          <Form.Item
            className='specification-input-item'
            label='Product Form'
            name='productForm'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Select
              suffixIcon={<CaretDownOutlined />}
              className='specification-tag-input'
              placeholder='Please select a product form'
              onChange={this.selectProductForm}
            >
              {productForms &&
                productForms.map((productForm) => (
                  <Option key={productForm.code} value={productForm.code}>
                    {productForm.code}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        {!isUndefined(otherDropdowns) &&
          Object.keys(otherDropdowns).includes(
            StringConstants.SPECIFICATION_FIELDS.SCALED_OR_UNSCALED
          ) && (
            <Col
              span={24}
              xs={24}
              lg={
                Object.keys(otherDropdowns).length === 1
                  ? 16
                  : 24 / Object.keys(otherDropdowns).length
              }
              xl={
                Object.keys(otherDropdowns).length === 1
                  ? 16
                  : 24 / Object.keys(otherDropdowns).length
              }
            >
              <Form.Item
                className='specification-input-item'
                label='Scaled or Unscaled'
              >
                <Select
                  className='specification-tag-input'
                  placeholder={'Please choose scaled state'}
                  onChange={this.selectScaledState}
                  value={scaledState}
                >
                  {otherDropdowns[
                    StringConstants.SPECIFICATION_FIELDS.SCALED_OR_UNSCALED
                  ].map((value) => (
                    <Option key={value.code} value={value.code}>
                      {value.code}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        {!isUndefined(otherDropdowns) &&
          Object.keys(otherDropdowns).includes(
            StringConstants.SPECIFICATION_FIELDS.SKIN_ON_OR_SKIN_OFF
          ) && (
            <>
              <Col
                span={24}
                xs={24}
                lg={
                  Object.keys(otherDropdowns).length === 1
                    ? 16
                    : 24 / Object.keys(otherDropdowns).length
                }
                xl={
                  Object.keys(otherDropdowns).length === 1
                    ? 16
                    : 24 / Object.keys(otherDropdowns).length
                }
              >
                <Form.Item
                  className='specification-input-item'
                  label='Skin on or Skin Off'
                >
                  <Select
                    className='specification-tag-input'
                    onChange={this.selectSkinState}
                    placeholder={'Please choose skin state'}
                    value={skinState}
                  >
                    {otherDropdowns[
                      StringConstants.SPECIFICATION_FIELDS.SKIN_ON_OR_SKIN_OFF
                    ].map((value) => (
                      <Option key={value.code} value={value.code}>
                        {value.code}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}

        {!isUndefined(otherDropdowns) &&
          Object.keys(otherDropdowns).includes(
            StringConstants.SPECIFICATION_FIELDS.BONELESS_OR_BONE_IN
          ) && (
            <Col
              span={24}
              xs={24}
              lg={
                Object.keys(otherDropdowns).length === 1
                  ? 16
                  : 24 / Object.keys(otherDropdowns).length
              }
              xl={
                Object.keys(otherDropdowns).length === 1
                  ? 16
                  : 24 / Object.keys(otherDropdowns).length
              }
            >
              <Form.Item
                className='specification-input-item'
                label='Boneless or Bone in'
              >
                <Select
                  className='specification-tag-input'
                  onChange={this.selectBoneState}
                  placeholder={'Please choose bone state'}
                  value={boneState}
                >
                  {otherDropdowns[
                    StringConstants.SPECIFICATION_FIELDS.BONELESS_OR_BONE_IN
                  ].map((value) => (
                    <Option key={value.code} value={value.code}>
                      {value.code}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        {selectedProductType === 'FINFISH' && (
          <Col span={24} xs={24} lg={16} xl={16}>
            <Form.Item
              className='specification-input-item'
              label='Skinning Notes'
              name='skinningNotes'
              rules={[
                SpecificationUtils.getValidationRule(
                  SpecificationUtils.ruleTypes.REQUIRED
                ),
              ]}
            >
              <Input
                className='specification-input'
                placeholder='Please enter skinning notes'
              />
            </Form.Item>
          </Col>
        )}
        <Col
          span={24}
          xs={24}
          lg={
            otherDropdowns && Object.keys(otherDropdowns).length === 1 ? 12 : 9
          }
          xl={
            otherDropdowns && Object.keys(otherDropdowns).length === 1 ? 12 : 9
          }
        >
          <Form.Item
            className='specification-input-item'
            label='Single Frozen or Double Frozen'
          >
            <Select
              suffixIcon={<CaretDownOutlined />}
              className='specification-tag-input'
              placeholder={'Please choose frozen type'}
              onChange={this.selectFrozenType}
              value={frozenState}
            >
              {StringConstants.SPECIFICATION_FIELDS.FROZEN_TYPE.map(
                (frozenType) => (
                  <Option key={frozenType} value={frozenType}>
                    {frozenType}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col
          span={24}
          xs={24}
          lg={
            otherDropdowns && Object.keys(otherDropdowns).length === 1 ? 12 : 9
          }
          xl={
            otherDropdowns && Object.keys(otherDropdowns).length === 1 ? 12 : 9
          }
        >
          <Form.Item
            className='specification-input-item'
            label='Cooked, Raw, Parfried'
          >
            <Select
              suffixIcon={<CaretDownOutlined />}
              className='specification-tag-input'
              placeholder={'Please choose cooked type'}
              onChange={this.selectCookedType}
              value={cookedState}
            >
              {StringConstants.SPECIFICATION_FIELDS.COOKED_TYPE.map(
                (cookedType) => (
                  <Option key={cookedType} value={cookedType}>
                    {cookedType}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24} xs={24} lg={8} xl={8}>
          <Form.Item
            className='specification-input-item'
            label='Moisture Symbol'
            name='moistureSymbol'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Select
              suffixIcon={<CaretDownOutlined />}
              className='specification-tag-input'
              placeholder='Please choose moisture symbol'
            >
              {StringConstants.SPECIFICATION_FIELDS.MOISTURE_SYMBOL.map(
                (moistureSymbol) => (
                  <Option key={moistureSymbol} value={moistureSymbol}>
                    {moistureSymbol}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={8} xl={8}>
          <Form.Item
            className='specification-input-item'
            label='Moisture Percent'
            name='moisturePercent'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Input
              className='specification-input'
              placeholder='Please enter moisture percent'
              type='number'
              step={0.1}
              min={0}
              max={100}
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={8} xl={8}>
          <Form.Item
            className='specification-input-item'
            label='Moisture Notes'
            name='moistureComments'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Input
              className='specification-input'
              placeholder='Please enter moisture notes'
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item className='specification-input-item' label='Treatment'>
            <Space>
              <Select
                className='specification-tag-input'
                mode='multiple'
                placeholder='Please choose treatment(s)'
                onChange={this.selectTreatment}
                value={selectedTreatment}
              >
                {StringConstants.SPECIFICATION_FIELDS.TREATMENT_TYPE.map(
                  (treatment) => (
                    <Option
                      key={treatment}
                      value={treatment}
                      disabled={!this.checkTreatmentSelectable(treatment)}
                    >
                      {treatment}
                    </Option>
                  )
                )}
              </Select>
              {showTreatmentInput && (
                <Input
                  className='specification-input'
                  placeholder='Enter treatment'
                  onChange={(event) => this.setTreatment(event.target.value)}
                />
              )}
            </Space>
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            label='Treatment Comments'
            name='treatmentComments'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Input
              className='specification-input'
              placeholder='Please enter treatment comments'
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            label='Glaze Comments'
            name='glazeComments'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Input
              className='specification-input'
              placeholder='Please enter glaze comments'
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item className='specification-input-item' label='IQF or IVP'>
            <Select
              suffixIcon={<CaretDownOutlined />}
              className='specification-tag-input'
              value={iqfIvp}
              onChange={this.setIqfOrIvp}
            >
              {StringConstants.SPECIFICATION_FIELDS.IQF_OR_IVP.map((value) => (
                <Option key={value} value={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            name='minSizeCount'
            label='Minimum Size Count'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Input
              className='specification-input'
              placeholder='Please enter minimum size count'
              type='number'
              step={selectedProductType === 'SHRIMP' ? 1 : 0.1}
              min={selectedProductType === 'SHRIMP' ? 1 : 0.1}
              onChange={(event) => this.setMinSizeCount(event.target.value)}
              value={minSizeCount}
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            name='minSizeCountUom'
            label='Minimum Size Count Unit of Measure'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Select
              className='specification-tag-input'
              suffixIcon={<CaretDownOutlined />}
              onChange={this.setMinSizeCountUom}
            >
              <Option
                key={StringConstants.SPECIFICATION_FIELDS.COUNT_PER_LB}
                value={StringConstants.SPECIFICATION_FIELDS.COUNT_PER_LB}
                style={{
                  display: selectedProductType === 'SHRIMP' ? 'block' : 'none',
                }}
              >
                {StringConstants.SPECIFICATION_FIELDS.COUNT_PER_LB}
              </Option>
              {Object.keys(StringConstants.UNITS).map((unit) => (
                <Option
                  key={unit}
                  value={StringConstants.UNITS[unit]}
                  style={{
                    display:
                      selectedProductType === 'SHRIMP' ? 'none' : 'block',
                  }}
                >
                  {capitalize(unit) + ' (' + StringConstants.UNITS[unit] + ')'}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            name='maxSizeCount'
            label='Maximum Size Count'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Input
              className='specification-input'
              placeholder='Please enter maximum size count'
              type='number'
              step={selectedProductType === 'SHRIMP' ? 1 : 0.1}
              min={selectedProductType === 'SHRIMP' ? 1 : 0.1}
              onChange={(event) => this.setMaxSizeCount(event.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            name='maxSizeCountUom'
            label='Maximum Size Count Unit of Measure'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Select
              className='specification-tag-input'
              suffixIcon={<CaretDownOutlined />}
              onChange={this.setMaxSizeCountUom}
            >
              <Option
                key={StringConstants.SPECIFICATION_FIELDS.COUNT_PER_LB}
                value={StringConstants.SPECIFICATION_FIELDS.COUNT_PER_LB}
                style={{
                  display: selectedProductType === 'SHRIMP' ? 'block' : 'none',
                }}
              >
                {StringConstants.SPECIFICATION_FIELDS.COUNT_PER_LB}
              </Option>
              {Object.keys(StringConstants.UNITS).map((unit) => (
                <Option
                  key={unit}
                  value={StringConstants.UNITS[unit]}
                  style={{
                    display:
                      selectedProductType === 'SHRIMP' ? 'none' : 'block',
                  }}
                >
                  {capitalize(unit) + ' (' + StringConstants.UNITS[unit] + ')'}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {selectedProductType === 'FINFISH' && (
          <>
            <Col span={24} xs={24} lg={8} xl={8}>
              <Form.Item
                className='specification-input-item'
                suffixIcon={<CaretDownOutlined />}
                label='Variance Symbol'
              >
                <Select
                  className='specification-tag-input'
                  onChange={this.setVarianceSymbol}
                  value={varianceSymbol}
                >
                  {StringConstants.SPECIFICATION_FIELDS.VARIANCE_SYMBOLS.map(
                    (varianceSymbol) => (
                      <Option key={varianceSymbol} value={varianceSymbol}>
                        {varianceSymbol}
                      </Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} xs={24} lg={8} xl={8}>
              <Form.Item
                className='specification-input-item'
                label='Variance'
                name='variance'
                rules={[
                  SpecificationUtils.getValidationRule(
                    SpecificationUtils.ruleTypes.REQUIRED
                  ),
                ]}
              >
                <Input
                  className='specification-input'
                  placeholder='Please enter variance'
                  type='number'
                  step={0.1}
                  min={0.1}
                  onChange={(event) => this.setVariance(event.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={24} xs={24} lg={8} xl={8}>
              <Form.Item
                className='specification-input-item'
                label='Variance Unit of Measure'
              >
                <Select
                  className='specification-tag-input'
                  suffixIcon={<CaretDownOutlined />}
                  onChange={this.setVarianceUom}
                  value={varianceUom}
                >
                  {Object.keys(StringConstants.UNITS).map((unit) => (
                    <Option key={unit} value={StringConstants.UNITS[unit]}>
                      {capitalize(unit) +
                        ' (' +
                        StringConstants.UNITS[unit] +
                        ')'}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item className='specification-input-item' label='Size Combined'>
            <Input
              className='specification-input'
              value={getSizeCombinedDisplayText(
                minSizeCount,
                minSizeCountUom,
                maxSizeCount,
                maxSizeCountUom,
                varianceSymbol,
                variance,
                varianceUom
              )}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} lg={9} xl={9}>
          <Form.Item
            className='specification-input-item'
            name='additionalPhysicalAttributeComments'
            label='Additional Physical Attributes Comments'
            rules={[
              SpecificationUtils.getValidationRule(
                SpecificationUtils.ruleTypes.REQUIRED
              ),
            ]}
          >
            <Input
              className='specification-input'
              placeholder='Write Comment...'
            />
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

export default PhysicalAttributes;
