import EmptySection from 'src/components/common/emptySection/EmptySection';
import ErrorComponent from 'src/components/common/ErrorComponent';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import HorizontalTimeline from 'src/components/common/horizontalTimeline/HorizontalTimeline';
import LoadingComponent from 'src/components/common/LoadingComponent';
import PurchaseOrderDetail from 'src/components/purchaseOrder/PurchaseOrderDetail';
import PurchaseOrderService from 'src/services/PurchaseOrderService';
import PurchaseOrderTimeline from 'src/components/purchaseOrder/PurchaseOrderTimeline';
import React from 'react';
import SecureComponent from 'src/components/common/SecureComponent';
import { Tabs } from 'antd';
import { isEmpty } from 'lodash';
import { isUserImporter } from 'src/helpers/user';

const { TabPane } = Tabs;

class PurchaseOrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      purchaseOrderId: this.props.match.params.id,
      purchaseOrderDetails: {},
      errorFor: undefined,
    };
  }

  componentDidMount() {
    this.fetchPageData();
  }

  fetchPageData = () => {
    this.setState({ loading: true });
    const { purchaseOrderId } = this.state;
    const { fromNav } = this.props;
    if (fromNav)
      PurchaseOrderService.getNavPODetail(purchaseOrderId)
        .then((response) => {
          this.setState({
            purchaseOrderDetails: response.data,
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            purchaseOrderDetails: {},
            errorFor: 'NAV',
            loading: false,
          });
        });
    else
      PurchaseOrderService.getPODetail(purchaseOrderId)
        .then((response) => {
          this.setState({
            purchaseOrderDetails: response.data,
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            purchaseOrderDetails: {},
            errorFor: 'Carava',
            loading: false,
          });
        });
  };

  render() {
    const { loading, purchaseOrderId, purchaseOrderDetails, errorFor } =
      this.state;
    const { fromNav } = this.props;
    if (loading) return <LoadingComponent />;
    if (isEmpty(purchaseOrderDetails))
      return (
        <ErrorComponent
          imageSrc={
            require('../../assets/images/no-data-images/nd-planned-order.png')
              .default
          }
          title='No Purchase Order found'
          tip={'Purchase order with given ID does not exist in ' + errorFor}
          showButton={false}
        />
      );
    return (
      <>
        <HeaderMetadata
          title={`${purchaseOrderId} | Purchase Order`}
          description={`Purchase Order ${purchaseOrderId} for item ${
            purchaseOrderDetails &&
            purchaseOrderDetails.specification &&
            purchaseOrderDetails.specification.itemDescription
          }`}
        />
        {isUserImporter() && (
          <HorizontalTimeline
            data={purchaseOrderDetails}
            currentEntity='Purchase Order'
          />
        )}
        <div className='ca-card-light mb-15 pai-section'>
          <Tabs
            className='notification-alerts auction-tabs '
            defaultActiveKey='1'
            onChange={(activeKey) => {
              if (activeKey === 'po-detail') this.fetchPageData();
            }}
          >
            <TabPane
              className='participant-tab'
              tab={<span>PO Details</span>}
              key='po-detail'
            >
              <PurchaseOrderDetail
                loading={loading}
                purchaseOrderId={this.props.match.params.id}
                purchaseOrderDetails={purchaseOrderDetails}
                fetchPageData={this.fetchPageData}
                fromNav={fromNav}
              />
            </TabPane>
            <TabPane tab={<span>PO Tracking</span>} key='po-timeline'>
              <PurchaseOrderTimeline
                fromNav={fromNav}
                purchaseOrderId={this.props.match.params.id}
              />
            </TabPane>
          </Tabs>
        </div>
        {/* <Comments
          ref={(ref) => (this.commentsSection = ref)}
          allowAdd={true}
          entity={StringConstants.COMMNENT_ENTITY.PURCHASE_ORDER}
          id={purchaseOrderId}
        /> */}
      </>
    );
  }
}
export default SecureComponent(PurchaseOrderDetails);
