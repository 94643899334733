import React, { Component } from 'react';

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import Title from 'src/components/common/typography/Title';

class ConfirmationModal extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    negativeAction: PropTypes.bool,
    heading: PropTypes.string.isRequired,
    title: PropTypes.any.isRequired,
    description: PropTypes.any.isRequired,
    okText: PropTypes.string.isRequired,
    cancelText: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    actionLoading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
    negativeAction: false,
    heading: 'Confirm your action',
    okText: 'Ok',
    cancelText: 'Cancel',
  };

  render() {
    const {
      visible,
      loading,
      negativeAction,
      okText,
      cancelText,
      heading,
      title,
      description,
      actionLoading,
    } = this.props;
    return (
      <Modal
        className={`${
          negativeAction ? 'confirmation-modal-negative' : 'confirmation-modal'
        }`}
        centered
        visible={visible}
        onOk={this.props.onSubmit}
        onCancel={this.props.onCancel}
        okText={okText}
        cancelText={cancelText}
        width={900}
        confirmLoading={loading}
        closable={false}
        maskClosable={false}
        okButtonProps={{ loading: actionLoading }}
        cancelButtonProps={{
          disabled: actionLoading,
          className: negativeAction ? '' : 'cancel-outlined-button',
        }}
      >
        <Title title={heading} />

        <p className='modal-title mt-15'>{title}</p>
        <p className='modal-description'>{description}</p>
      </Modal>
    );
  }
}

export default ConfirmationModal;
