import '../Modals.css';

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { find } from 'lodash';

class BidAwardRejectedModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    refreshPageFn: PropTypes.func.isRequired,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    auctionWinnerId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    visible: false,
  };

  render() {
    const { visible, auctionWinnerId, data } = this.props;

    const auctionWinner = find(data, function (o) {
      return o.id === auctionWinnerId;
    });
    return (
      <Modal
        className='single-btn-center-footer'
        centered
        visible={visible}
        onOk={this.props.refreshPageFn}
        onCancel={this.props.refreshPageFn}
        cancelText='Close'
        width={700}
        closable={false}
        maskClosable={false}
      >
        <div className='bid-accepted-content'>
          <div className='bid-cont-img'>
            <img
              alt='Rejected'
              style={{ width: '130px' }}
              className=''
              src={require('../../assets/images/rejected.png').default}
            />
          </div>
          <p className='bid-modal-content'>
            You have rejected the quote from{' '}
            <strong>
              {auctionWinner &&
                auctionWinner.company &&
                auctionWinner.company.name}
            </strong>
            <br />
            Their quote has been moved back to submitted
          </p>
        </div>
      </Modal>
    );
  }
}
export default BidAwardRejectedModal;
