import './RevenueTabs.css';

import AuctionTableManager from 'src/utils/tables/AuctionTableManager';
import Card from 'src/components/common/cards/Card';
import React from 'react';
import RevenueGraph from './RevenueGraph';
import { Table } from 'antd';
import { Tabs } from 'antd';
import { withRouter } from 'react-router-dom';

const { TabPane } = Tabs;

class RevenueTabs extends React.Component {
  render() {
    return (
      <>
        <Card className='mb-0 h-100 revenue-tabs-card'>
          <Tabs className='revenue-tabs ' defaultActiveKey='1'>
            {/*Revenue Graph tab */}
            <TabPane
              className='revenue-graph-tab'
              tab={<span>Revenue Graph</span>}
              key='1'
            >
              <RevenueGraph />
            </TabPane>
            {/*Revenue Graph tab ends */}
            {/* Finished auctions revenue tab */}
            <TabPane tab={<span>Finished Auctions Revenue</span>} key='2'>
              <Table columns={AuctionTableManager.getAuctionRevenueColumns()} />
            </TabPane>
            {/* Finished auctions revenue tab ends */}
          </Tabs>
        </Card>
      </>
    );
  }
}
export default withRouter(RevenueTabs);
