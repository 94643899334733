import cookie from 'react-cookies';
import jwt from 'jsonwebtoken';

const isAuthenticatedUser = () => {
  const token = cookie.load('token');
  if (typeof token !== typeof undefined) {
    const decoded = jwt.decode(token);
    var currentTime = Date.now() / 1000;
    if (decoded.exp > currentTime) return true;
  }
  return false;
};

const getUserDataFromCookie = () => {
  const token = cookie.load('token');
  if (token) {
    const decoded = jwt.decode(token);
    return decoded;
  }
  return {};
};

const getLoginToken = () => {
  return cookie.load('token');
};

const isUserSubCompany = () => {
  let userData = getUserDataFromCookie();
  return userData.sub_company === true;
};

export {
  isAuthenticatedUser,
  getUserDataFromCookie,
  getLoginToken,
  isUserSubCompany,
};
