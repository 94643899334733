import React, { Component } from 'react';

import ApiUtils from 'src/utils/ApiUtils';
import ChoosePlantsTable from 'src/pages/auction/buyer/create/chooseCompliantPlants/ChoosePlantsTable';
import CompanyService from 'src/services/CompanyService';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import Title from 'src/components/common/typography/Title';
import { find } from 'lodash';
import { toast } from 'react-toastify';

class AddPlantsToAuctionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      allPlants: [],
      selectedPlants: this.props.existingPlants,
      addedPlants: [],
    };
  }

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    existingPlants: PropTypes.array.isRequired,
  };

  static defaultProps = {
    heading: 'Confirm your action',
    okText: 'Submit',
    cancelText: 'Cancel',
  };

  componentDidMount = async () => {
    try {
      let response = await CompanyService.getAllProcessingPlants();
      this.setState({ loading: false, allPlants: response.data });
    } catch (err) {
      let errorMessage = ApiUtils.getErrorMessage(err);
      toast.error('Failed to fetch plants - ' + errorMessage);
      this.setState({ loading: false });
    }
  };

  onSelectChange = (selectedPlants) => {
    const { existingPlants } = this.props;
    let addedPlants = selectedPlants.filter((plantId) => {
      return !find(existingPlants, function (o) {
        return o.id === plantId;
      });
    });

    addedPlants = addedPlants.map((plantId) => {
      return {
        id: plantId,
      };
    });
    this.setState({ selectedPlants, addedPlants });
  };

  render() {
    const { visible, existingPlants } = this.props;
    const { allPlants, addedPlants } = this.state;

    return (
      <Modal
        className='confirmation-modal'
        centered
        visible={visible}
        okText='Submit'
        onOk={() => this.props.onSubmit(addedPlants)}
        onCancel={() => this.props.onCancel()}
        okButtonProps={{
          disabled: addedPlants.length === 0,
        }}
        cancelButtonProps={{ className: 'cancel-outlined-button' }}
        width={900}
        closable={false}
        maskClosable={false}
      >
        <Title title='Add plants to RFQ' />
        <ChoosePlantsTable
          data={allPlants}
          selectedData={existingPlants}
          selectMultiple={true}
          hideCard={true}
          addOnly={true}
          onSelectChange={this.onSelectChange}
        />
      </Modal>
    );
  }
}

export default AddPlantsToAuctionModal;
