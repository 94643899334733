import { isEmpty, pickBy } from 'lodash';

const getAssociatedCountry = (associatedCountries, category) => {
  const country = pickBy(associatedCountries, {
    category: category,
  });
  if (isEmpty(country)) return '-';
  return country[Object.keys(country)[0]].country;
};

const getCommentId = (additionalComments, commentType) => {
  const comment = pickBy(additionalComments, {
    commentType: commentType,
  });
  if (isEmpty(comment)) return '-';
  return comment[Object.keys(comment)[0]].id;
};

const getComment = (additionalComments, commentType) => {
  const comment = pickBy(additionalComments, {
    commentType: commentType,
  });
  if (isEmpty(comment)) return '-';
  return comment[Object.keys(comment)[0]].comments;
};

const getCorporateAssuranceComments = (corporateAssurances, assuranceType) => {
  const assurance = pickBy(corporateAssurances, {
    assuranceType: assuranceType,
  });
  if (isEmpty(assurance)) return '-';
  return assurance[Object.keys(assurance)[0]].comments;
};

const getCorporateAssuranceValue = (corporateAssurances, assuranceType) => {
  const assurance = pickBy(corporateAssurances, {
    assuranceType: assuranceType,
  });
  if (isEmpty(assurance)) return '-';
  return assurance[Object.keys(assurance)[0]].value;
};

const getSizeRangeProperties = (productType, sizeRangeProperties) => {
  if (!isEmpty(sizeRangeProperties)) {
    const sizeRanges = [];
    sizeRangeProperties.forEach((sr) => {
      let sizeRangeStr = '';
      if (productType === 'FINFISH') {
        sizeRangeStr =
          sr.minPieceSize +
          '-' +
          sr.maxPieceSize +
          sr.maxUom +
          ' ' +
          sr.varianceSymbol +
          sr.variance +
          sr.varianceUom;
      } else if (productType === 'SHRIMP') {
        sizeRangeStr = sr.minPieceSize + '-' + sr.maxPieceSize;
      }
      if (!isEmpty(sizeRangeStr)) sizeRanges.push(sizeRangeStr);
    });
    return sizeRanges.toString();
  }
  return '-';
};

const getSizeRangeProperty = (sizeRange, property) => {
  if (!isEmpty(sizeRange)) return sizeRange[property];
  else return '-';
};

const ruleTypes = {
  REQUIRED: 'REQUIRED',
  MIN_VALUE: 'MIN_VALUE',
};

const getValidationRule = (ruleType, dataType, value) => {
  let rule = {};
  switch (ruleType) {
    case ruleTypes.REQUIRED:
      rule = {
        required: true,
        message: 'This field is mandatory',
      };
      break;
    case ruleTypes.MIN_VALUE:
      rule = {
        min: value,
        type: dataType,
        message: 'Should have minimum value of ' + value,
      };
      break;
    default:
      rule = {};
  }

  return rule;
};

const SpecificationUtils = {
  getAssociatedCountry,
  getCommentId,
  getComment,
  getCorporateAssuranceValue,
  getCorporateAssuranceComments,
  getSizeRangeProperties,
  getSizeRangeProperty,
  getValidationRule,
  ruleTypes,
};

export default SpecificationUtils;
