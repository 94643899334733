import '../common/Auth.css';

import { Button, Form, Input } from 'antd';

import React from 'react';
import UserService from 'src/services/UserService';
import { toast } from 'react-toastify';
import ApiUtils from 'src/utils/ApiUtils';
import RouteConstants from 'src/constants/RouteConstants';
import { withRouter } from 'react-router';

class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  onSubmit = (values) => {
    this.setState({ loading: true });
    UserService.forgotPassword(values.email)
      .then(() => {
        toast.success('Password reset link has been sent to your email');
        this.forgotPasswordForm.resetFields();
        this.props.history.replace(RouteConstants.LOGIN);
      })
      .catch((err) => {
        const errorMessage = ApiUtils.getErrorMessage(
          err,
          'No account found, please enter your registered e-mail'
        );
        toast.error(errorMessage);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading } = this.state;
    return (
      <div className='login-left-details'>
        <img
          className='log-logo'
          src={require('../../../assets/images/logo.png').default}
          alt='Carava Logo'
        />
        <h2 className='all-form-heading'>Forgot Password?</h2>
        <p className='log-sign-form-desc'>
          Enter your registered email below to receive password reset link.
        </p>
        {/*forget password form */}
        <Form
          className='all-form forgetpass-form'
          layout='vertical'
          onFinish={this.onSubmit}
          ref={(ref) => (this.forgotPasswordForm = ref)}
        >
          <Form.Item
            label='Email Address'
            name='email'
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid e-mail!',
              },
              {
                required: true,
                message: 'Please enter your e-mail!',
              },
            ]}
          >
            <Input disabled={loading} />
          </Form.Item>

          <Form.Item>
            <Button
              className='mt-10 submit'
              type='primary'
              htmlType='submit'
              loading={loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>

        {/* forget password form end */}
      </div>
    );
  }
}
export default withRouter(ForgotPasswordForm);
