import './SourcingTrends.css';

import { Button, DatePicker, Select, Space } from 'antd';
import {
  clone,
  cloneDeep,
  findIndex,
  forEach,
  isEmpty,
  maxBy,
  minBy,
  uniq,
} from 'lodash';
import { displayDecimal, getAmountDisplayText } from 'src/utils/DisplayUtils';

import ApiUtils from 'src/utils/ApiUtils';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import { CaretDownOutlined } from '@ant-design/icons';
import Chart from 'react-apexcharts';
import DisplayConstants from 'src/constants/DisplayConstants';
import EmptySection from 'src/components/common/emptySection/EmptySection';
import LoadingComponent from 'src/components/common/LoadingComponent';
import React from 'react';
import ReportService from 'src/services/ReportService';
import moment from 'moment';
import { toast } from 'react-toastify';

const { RangePicker } = DatePicker;

const { Option } = Select;

const options_global = {
  chart: {
    height: 350,
    type: 'line',
    stacked: false,
    stackType: 'normal',
    toolbar: {
      show: true,
      position: 'topRight',
      tools: {
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true | '<img src="/static/icons/reset.png" width="20">',
      },
    },
  },

  stroke: {
    curve: 'straight',
    width: [0, 2, 0, 2],
  },
  legend: {
    show: false,
    position: 'top',
    horizontalAlign: 'right',
    fontSize: '10px',
    fontFamily: 'Roboto Condensed, sans-serif',
    fontWeight: 700,
    labels: {
      useSeriesColors: true,
    },
  },
  colors: ['#0EBAB3', '#CE2D4F', '#2170B8', '#478978'],
  markers: {
    size: 6,
    strokeColors: ['#0EBAB3', '#CE2D4F', '#2170B8', '#478978'],
    hover: {
      size: 6,
    },
  },

  plotOptions: {
    bar: {
      borderRadius: 5,
      columnWidth: '60%',
    },
  },
  fill: {
    opacity: 1,
  },

  dataLabels: {
    enabled: false,
    enabledOnSeries: [0, 1, 3, 4],
  },
  grid: {
    show: true,
    borderColor: '#E0E2E3',

    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },

  xaxis: {
    type: 'datetime',
    axisBorder: {
      show: false,
      color: 'red',
    },
    labels: {
      showDuplicates: false,
      style: {
        colors: '#231F20',
        fontSize: '12px',
        fontFamily: 'Roboto Condensed, sans-serif',
      },
    },

    tickPlacement: 'between',
  },

  yaxis: [],
  tooltip: {
    shared: true,
  },
};

const yAxisForBar = {
  forceNiceScale: true,
  axisBorder: {
    show: true,
    color: '#E0E2E3',
  },
  axisTicks: {
    show: true,
    borderType: 'solid',
    color: '#2170B8',
    height: 6,
    offsetX: 0,
    offsetY: 0,
  },
  labels: {
    formatter: function (val, index) {
      if (val) {
        let number = new Intl.NumberFormat().format(val) + ' lbs';
        return number;
      } else return '-';
    },
    style: {
      colors: '#2170B8',
      fontSize: '12px',
      fontFamily: 'Roboto Condensed, sans-serif',
    },
  },
  title: {
    text: 'Volume (in Pounds)',
    style: {
      color: '#231F20',
      fontSize: '12px',
      fontFamily: 'Roboto Condensed, sans-serif',
      fontWeight: 700,
    },
  },
};

const yAxisForLine = {
  opposite: true,
  forceNiceScale: true,

  title: {
    text: 'Average Price (in $)',
    rotate: 90,
    style: {
      color: '#231F20',
      fontSize: '12px',
      fontFamily: 'Roboto Condensed, sans-serif',
      fontWeight: 700,
    },
  },
  labels: {
    formatter: function (val, index) {
      if (val) return getAmountDisplayText(val);
      else return '-';
    },
    style: {
      colors: '#838B99',
      fontSize: '12px',
      fontFamily: 'Roboto Condensed, sans-serif',
    },
  },
  axisBorder: {
    show: true,
    color: '#E0E2E3',
  },
};

class SourcingTrends extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      specifications: [],
      specificationTrend: {},
      selectedSpecificationId: undefined,
      series: [],
      options: {},
      originalStartDate: undefined,
      originalEndDate: undefined,
      startDate: undefined,
      endDate: undefined,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    ReportService.getAllSpecificationsForDropdown()
      .then((response) => {
        let specifications = response.data;
        specifications = specifications.sort((a, b) => {
          return b.id.localeCompare(a.id);
        });

        this.setState({ specifications: specifications, loading: false }, () =>
          this.fetchSourcingTrend(specifications[0].id)
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        const errorMessage = ApiUtils.getErrorMessage(
          err,
          'Unable to get specifications at the moment'
        );
        toast.error(errorMessage);
      });
  }

  getMinYAxisValueForLine = (data) => {
    let minTrend = minBy(data, 'finalPrice');
    return minTrend.finalPrice - 0.1;
  };

  getMaxYAxisValueForLine = (data) => {
    let maxTrend = maxBy(data, 'finalPrice');
    return maxTrend.finalPrice + 0.1;
  };

  getMinYAxisValueForBar = (data) => {
    let minTrend = minBy(data, 'totalWeight');
    return minTrend.totalWeight - 1000;
  };

  getMaxYAxisValueForBar = (data) => {
    let maxTrend = maxBy(data, 'totalWeight');
    return maxTrend.totalWeight + 1000;
  };

  fetchSourcingTrend = (specificationId) => {
    if (isEmpty(specificationId)) return;
    const { specificationTrend, startDate, endDate } = this.state;
    const specTrend = specificationTrend[specificationId];
    this.setState({ loading: true });
    if (
      specTrend &&
      moment(startDate).isSame(moment(specTrend.startDate), 'date') &&
      moment(endDate).isSame(moment(specTrend.endDate), 'date')
    ) {
      const { series, options } = this.transformDataForGraph(specTrend.trends);
      this.setState({
        selectedSpecificationId: specificationId,
        series,
        options,
        loading: false,
      });
      return;
    }
    ReportService.getSourcingTrendForSpec(specificationId, startDate, endDate)
      .then((response) => {
        const { series, options } = this.transformDataForGraph(
          response.data.trends
        );
        this.setState((prevState) => ({
          specificationTrend: {
            ...prevState.specificationTrend,
            [[specificationId]]: response.data,
          },
          selectedSpecificationId: specificationId,
          series,
          options,
          originalStartDate: moment(response.data.startDate),
          originalEndDate: moment(response.data.endDate),
          startDate: moment(response.data.startDate),
          endDate: moment(response.data.endDate),
          loading: false,
        }));
      })
      .catch((err) => {
        this.setState({ loading: false });
        const errorMessage = ApiUtils.getErrorMessage(
          err,
          'Unable to get trend at the moment'
        );
        toast.error(errorMessage);
      });
  };

  transformDataForGraph = (sourcingTrend) => {
    //Get All Unique dates and countries
    let dates = [],
      countries = [];
    const avgSourcingTrend = [];

    forEach(sourcingTrend, (s) => {
      dates.push(new Date(s.postedAt).getTime());
      countries.push(s.source?.name);

      let index = findIndex(avgSourcingTrend, {
        postedAt: new Date(s.postedAt).getTime(),
        source: s.source?.name,
      });
      if (index >= 0) {
        //Avg the values
        let existingData = avgSourcingTrend[index];
        let totalWeight = existingData.totalWeight + s.totalWeight;
        let pricePaid =
          existingData.totalWeight * existingData.finalPrice +
          s.finalPrice * s.totalWeight;
        let finalPrice = displayDecimal(pricePaid / totalWeight);
        avgSourcingTrend[index].finalPrice = finalPrice;
        avgSourcingTrend[index].totalWeight = totalWeight;
      } else {
        avgSourcingTrend.push({
          finalPrice: s.finalPrice,
          postedAt: new Date(s.postedAt).getTime(),
          totalWeight: s.totalWeight,
          source: s.source?.name,
        });
      }
    });
    // Sort Dates
    dates = uniq(dates).sort();
    countries = uniq(countries).sort();

    //Create series for each country
    const generated_series_quantity = {},
      generated_series_price = {};
    forEach(countries, (country) => {
      generated_series_quantity[country] = [];
      generated_series_price[country] = [];
    });
    forEach(dates, (date) => {
      forEach(countries, (country) => {
        let index = findIndex(avgSourcingTrend, {
          postedAt: date,
          source: country,
        });
        if (index >= 0) {
          generated_series_quantity[country].push(
            parseFloat(avgSourcingTrend[index].totalWeight)
          );
          generated_series_price[country].push(
            parseFloat(avgSourcingTrend[index].finalPrice)
          );
        } else {
          generated_series_quantity[country].push(null);
          generated_series_price[country].push(null);
        }
      });
    });
    const options_ = cloneDeep(options_global);
    options_.xaxis.categories = dates;

    forEach(countries, (country) => {
      if (options_.yaxis.length === 0) {
        const bar_ = clone(yAxisForBar);
        bar_.seriesName = country + '(lbs)';
        bar_.min = this.getMinYAxisValueForBar(avgSourcingTrend);
        bar_.max = this.getMaxYAxisValueForBar(avgSourcingTrend);
        options_.yaxis.push(bar_);
        const line_ = clone(yAxisForLine);
        line_.seriesName = country;
        line_.min = this.getMinYAxisValueForLine(sourcingTrend);
        line_.max = this.getMaxYAxisValueForLine(sourcingTrend);
        options_.yaxis.push(line_);
      } else {
        const bar_ = clone(yAxisForBar);
        bar_.show = false;
        bar_.seriesName = country + '(lbs)';
        delete bar_.axisTicks;
        options_.yaxis.push(bar_);
        const line_ = clone(yAxisForLine);
        line_.show = false;
        line_.seriesName = country;
        options_.yaxis.push(line_);
      }
    });

    const series = [];
    forEach(countries, (country) => {
      series.push({
        data: generated_series_quantity[country],
        name: country + '(lbs)',
        type: 'bar',
      });
      series.push({
        data: generated_series_price[country],
        name: country,
        type: 'line',
      });
    });
    return { series, options: options_ };
  };

  render() {
    const {
      loading,
      specifications,
      selectedSpecificationId,
      series,
      startDate,
      endDate,
      originalStartDate,
      originalEndDate,
      options,
    } = this.state;

    return (
      <CardWithTitle
        className='sourcing-trend historical-trend-chart'
        title='Sourcing Trends'
      >
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            <div className='historicaltrend-header '>
              <div className='select-drop ht-header-select'>
                Name Of Products:
                <Select
                  suffixIcon={<CaretDownOutlined />}
                  className='planned-vi-sel-btn'
                  defaultValue={selectedSpecificationId}
                  style={{ width: 300 }}
                  onChange={(value) => this.fetchSourcingTrend(value)}
                >
                  {specifications.map((spec, index) => {
                    return (
                      <Option value={spec.id} key={index}>
                        {spec.itemDescription}{' '}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div className='sourcing-trend-header-right'>
                <div className='sourcing-trend-date-range'>
                  <Space>
                    <RangePicker
                      defaultValue={[startDate, endDate]}
                      format={DisplayConstants.DATE_FORMAT}
                      suffixIcon={
                        <img
                          src={
                            require('../../../assets/images/Icon-Calendar-Black.svg')
                              .default
                          }
                          alt='Calendar'
                        />
                      }
                      onChange={(values) => {
                        this.setState({
                          startDate: values[0],
                          endDate: values[1],
                        });
                      }}
                      allowClear={false}
                    />
                    <Button
                      type='primary'
                      className='primary-button'
                      disabled={
                        false &&
                        startDate.isSame(originalStartDate, 'date') &&
                        endDate.isSame(originalEndDate, 'date')
                      }
                      onClick={() =>
                        this.fetchSourcingTrend(selectedSpecificationId)
                      }
                    >
                      Apply
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
            <div className='chart-area'>
              {isEmpty(series) ? (
                <div>
                  <EmptySection
                    title='No Trends to analyze'
                    imageSrc={
                      require('../../../assets/images/no-data-images/nd-graphs.png')
                        .default
                    }
                  />
                </div>
              ) : (
                <div id='chart'>
                  <Chart
                    options={options}
                    series={series}
                    type='line'
                    height={350}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </CardWithTitle>
    );
  }
}

export default SourcingTrends;
