import { Col, Row, Table, Tabs } from 'antd';
import React, { Component } from 'react';
import { capitalize, isUndefined } from 'lodash';

import ApiUtils from 'src/utils/ApiUtils';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import HelpText from 'src/components/common/typography/HelpText';
import LoadingComponent from 'src/components/common/LoadingComponent';
import ProformaService from 'src/services/ProformaService';
import ProformaTableManager from 'src/utils/tables/ProformaTableManager';
import RouteConstants from 'src/constants/RouteConstants';
import SecureComponent from 'src/components/common/SecureComponent';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { getReadableDisplayName } from 'src/utils/DisplayUtils';
import { toast } from 'react-toastify';

const helpTextByStatus = {
  [[StringConstants.APPROVER_PROFORMA_STATUS.PENDING]]:
    'Proformas yet to be approved ',
  [[StringConstants.APPROVER_PROFORMA_STATUS.HISTORY]]:
    'Approved Proformas and contracts',
};

class Proformas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pendingProformas: [],
      historicalProformas: [],
      activeKey: undefined,
    };
  }

  componentDidMount() {
    let status = UrlGenerator.getParamsFromUrl(
      this.props.location.search,
      'status'
    );
    if (!status) {
      status = StringConstants.APPROVER_PROFORMA_STATUS.PENDING;
    }
    this.setState({ activeKey: status.toUpperCase() }, () =>
      this.fetchProformas()
    );
  }

  fetchProformas() {
    const { activeKey } = this.state;
    this.setState({ loading: true });

    if (activeKey === StringConstants.APPROVER_PROFORMA_STATUS.PENDING) {
      ProformaService.getPendingProformas()
        .then((response) => {
          this.setState({
            pendingProformas: response.data,
            loading: false,
          });
        })
        .catch((err) => {
          let errorMsg = ApiUtils.getErrorMessage(
            err,
            'Failed to fetch proformas at the moment'
          );
          toast.error(errorMsg);
          this.setState({ loading: false });
        });
    } else if (activeKey === StringConstants.APPROVER_PROFORMA_STATUS.HISTORY) {
      ProformaService.getHistoricalProformas()
        .then((response) => {
          this.setState({
            historicalProformas: response.data,
            loading: false,
          });
        })
        .catch((err) => {
          let errorMsg = ApiUtils.getErrorMessage(
            err,
            'Failed to fetch proformas at the moment'
          );
          toast.error(errorMsg);
          this.setState({ loading: false });
        });
    }
  }

  getColumnsForTable = () => {
    const { pendingProformas, historicalProformas, activeKey } = this.state;
    const data =
      activeKey === StringConstants.APPROVER_PROFORMA_STATUS.PENDING
        ? pendingProformas
        : historicalProformas;
    return ProformaTableManager.getColumnsForImporter(data);
  };

  onTabChange = (value) => {
    const url = UrlGenerator.getUiUrlWithQueryParams(RouteConstants.PROFORMAS, {
      status: value,
    });
    window.history.replaceState(null, null, url);
    this.setState({ activeKey: value }, () => this.fetchProformas());
  };

  render() {
    const { loading, pendingProformas, historicalProformas, activeKey } =
      this.state;
    if (isUndefined(activeKey))
      return <LoadingComponent title='Loading Pro-formas' />;
    return (
      <>
        <HeaderMetadata
          title={`${capitalize(getReadableDisplayName(activeKey))} Pro-forma`}
          description={helpTextByStatus[activeKey]}
        />
        <div className='ca-card-light h-100'>
          <Row>
            <Col xs={24} lg={24} xl={24}>
              <Tabs activeKey={activeKey} onChange={this.onTabChange}>
                <Tabs.TabPane
                  tab='Pending Pro-formas'
                  key={StringConstants.APPROVER_PROFORMA_STATUS.PENDING}
                >
                  {activeKey && helpTextByStatus[activeKey] && (
                    <HelpText
                      message={helpTextByStatus[activeKey]}
                      showIcon={true}
                    />
                  )}
                  <Table
                    className='mt-10'
                    columns={this.getColumnsForTable()}
                    loading={loading}
                    dataSource={pendingProformas}
                    rowKey={(row) => row.id}
                    pagination={
                      pendingProformas && pendingProformas.length >= 10
                    }
                  />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab='Pro-formas History'
                  key={StringConstants.APPROVER_PROFORMA_STATUS.HISTORY}
                >
                  {activeKey && helpTextByStatus[activeKey] && (
                    <HelpText
                      message={helpTextByStatus[activeKey]}
                      showIcon={true}
                    />
                  )}
                  <Table
                    className='mt-10'
                    columns={this.getColumnsForTable()}
                    loading={loading}
                    dataSource={historicalProformas}
                    rowKey={(row) => row.id}
                    pagination={
                      historicalProformas && historicalProformas.length >= 10
                    }
                  />
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default SecureComponent(
  Proformas,
  [StringConstants.COMPANY_TYPES.IMPORTER],
  [
    {
      name: StringConstants.TEAMS.ACCOUNT_MANAGERS,
    },
    {
      name: StringConstants.TEAMS.COMPLIANCE,
    },
    {
      name: StringConstants.TEAMS.SUSTAINABILITY,
    },
  ]
);
