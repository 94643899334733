import ApiManager from 'src/api/ApiManager';
import HttpConstants from 'src/constants/HttpConstants';
import UrlConstants from 'src/constants/UrlConstants';

const CompanyService = {
  getCompanyProfile: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_COMPANY_PROFILE.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  updateCompanyProfile: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_COMPANY_PROFILE.USECASE,
      HttpConstants.PATCH_METHOD,
      data
    );
  },
  getAllProcessingPlants: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PROCESSING_PLANTS.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  getInvitedProcessingPlants: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_INVITED_PROCESSING_PLANTS.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  getProcessingPlantOverview: (id) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PROCESSING_PLANT_OVERVIEW.USECASE,
      HttpConstants.GET_METHOD,
      { ':id': id }
    );
  },
  inviteProcessingPlant: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.INVITE_PROCESSING_PLANT.USECASE,
      HttpConstants.POST_METHOD,
      data
    );
  },
  resendInvite: (id) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.RESEND_INVITE.USECASE,
      HttpConstants.POST_METHOD,
      { ':id': id }
    );
  },
  revokeInvite: (id) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.REVOKE_INVITE.USECASE,
      HttpConstants.DELETE_METHOD,
      { ':id': id }
    );
  },
  subPlantLinkingInvite: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.SUBPLANT_LINKING_INVITE.USECASE,
      HttpConstants.POST_METHOD,
      data
    );
  },
  subPlantDelink: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.SUBPLANT_DELINK.USECASE,
      HttpConstants.POST_METHOD
    );
  },
  getSubPlantPendingInvites: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_SUBPLANT_PENDING_INVITES.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  saveCompanyNote: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.SAVE_COMPANY_NOTE.USECASE,
      HttpConstants.POST_METHOD,
      data
    );
  },
  removeCompanyNote: (noteId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.REMOVE_COMPANY_NOTE.USECASE,
      HttpConstants.DELETE_METHOD,
      { ':id': noteId }
    );
  },
  editCompanyNote: (noteId, data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.EDIT_COMPANY_NOTE.USECASE,
      HttpConstants.PATCH_METHOD,
      { ':id': noteId, ...data }
    );
  },
  getPlantQuestionnaire: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PLANT_QUESTIONNAIRE.USECASE,
      HttpConstants.GET_METHOD
    );
  },
};

export default CompanyService;
