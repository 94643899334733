import Card from './Card';
import PropTypes from 'prop-types';
import React from 'react';

const CardWithTitle = (props) => {
  return (
    <Card className={props.className}>
      <div className='heading-divider'>
        <div className='head-divi-box'>
          <p className='head-divi-title'>{props.title}</p>
          <div className='head-divi-line'></div>
        </div>
        {props.badge != null && (
          <span className='heading-badge'>{props.badge}</span>
        )}
        <div className='heading-right-area'>{props.action}</div>
      </div>
      {props.children}
    </Card>
  );
};

CardWithTitle.propTypes = {
  action: PropTypes.element,
  badge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default CardWithTitle;
