const StringConstants = {
  PROCESSING_PLANT_TYPES: {
    ALL: 'ALL',
    INVITED: 'INVITED',
  },
  AUTH_TYPES: {
    PASSWORD: 'PASSWORD',
    SSO: 'SSO',
  },
  AUCTION_STATUS: {
    DRAFT: 'DRAFT',
    ACTIVE: 'ACTIVE',
    PENDING: 'PENDING',
    HOLD: 'HOLD',
    PROFORMA_REVIEW: 'PROFORMA_REVIEW',
    INVITED: 'INVITED',
    HISTORY: 'HISTORY',
    SCHEDULED_ACTIVE: 'SCHEDULED_ACTIVE',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
  },
  AUCTION_STATUS_COLORS: {
    ACTIVE: 'blue',
    HOLD: 'gold',
    PROFORMA_REVIEW: 'purple',
    COMPLETED: 'green',
    CANCELLED: 'red',
  },
  PLANT_AUCTION_API_STATUS: {
    INVITED: 'invited',
    PENDING: 'pending',
    ACTIVE: 'ongoing',
    HISTORY: 'history',
  },
  SPECIFICATION_STATUS: {
    ACTIVE: 'ACTIVE',
    ARCHIVED: 'ARCHIVED',
  },
  PLANNED_ORDER_STATUS: {
    AUCTION_DRAFT: 'DRAFT_AUCTION',
    AUCTION_PROGRESS: 'AUCTION_PROGRESS',
    OPEN: 'OPEN',
    BIDDING: 'BIDDING',
    ASSIGNED: 'ASSIGNED',
  },
  BID_STATUS: {
    ACCEPTED: 'ACCEPTED',
    SUBMITTED: 'SUBMITTED',
  },
  PROFORMA_STATUS: {
    OPEN: 'OPEN',
    REVIEW_APPROVED: 'REVIEW_APPROVED',
    CONTRACT_GENERATED: 'CONTRACT_GENERATED',
    REJECTED: 'REJECTED',
  },
  PURCHASE_ORDER_STATUS: {
    PENDING: 'PENDING',
    ORDERED: 'ORDERED',
    IN_TRANSIT: 'IN_TRANSIT',
    DELIVERED: 'DELIVERED',
  },
  PROFORMA_REVIEW_STATUS: {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
  },
  INTERNAL_REVIEW_ACTIONS: {
    APPROVE: 'APPROVE',
    REJECT: 'REJECT',
  },
  PLANNED_VIEW_TYPES: {
    FCL: 'FCL',
    CASES: 'CASES',
    WEIGHT: 'WEIGHT',
  },
  MONTHS: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  UNITS: {
    POUNDS: 'lb',
    OUNCES: 'oz',
    KILOGRAMS: 'kg',
    GRAMS: 'g',
  },
  CONVERSIONS: {
    OUNCES_TO_POUNDS: 0.0625,
    KG_TO_POUNDS: 2.20462,
    G_TO_POUNDS: 0.00220462,
  },
  MEASUREMENT_UNITS: {
    INCHES: 'in',
    CENTIMETRES: 'cm',
    MILLIMETRES: 'mm',
    FEET: 'ft',
  },
  AUCTION_ACTIONS: {
    ADD_PLANTS_TO_AUCTION: {
      key: 'addPlantsToAuction',
      title: 'Invite more plants',
      description:
        'Do you want to invite the following processing plants to this RFQ',
    },
    EDIT_AUCTION: {
      key: 'editAuction',
      title: 'Edit RFQ',
      description: '',
    },
    END_AUCTION: {
      key: 'endAuction',
      title: 'End RFQ',
      description:
        'RFQ will be marked pending. You can award quotes and create proformas',
    },
    CANCEL_AUCTION: {
      key: 'cancelAuction',
      title: 'Cancel RFQ',
      description:
        "RFQ will be cancelled and all it's planned orders will be available for re-assignment",
    },
    SIMULATE_BID: {
      key: 'simulateBid',
      title: 'Simulate quote on behalf of plant',
    },
  },
  BID_ACTIONS: {
    ACCEPT: 'ACCEPT',
    EDIT: 'EDIT',
  },
  COMMNENT_ENTITY: {
    PROFORMA: 'PROFORMA',
  },
  COMPANY_TYPES: {
    IMPORTER: 'IMPORTER',
    PROCESSING_PLANT: 'PROCESSING_PLANT',
  },
  INVITATION_TYPES: {
    PROCESSING_PLANT: 'PROCESSING_PLANT',
    CARAVA_USER: 'CARAVA_USER',
    SUB_PROCESSING_PLANT: 'SUB_PROCESSING_PLANT',
  },
  TEAMS: {
    EXECUTIVE: 'Executive',
    SUSTAINABILITY: 'Sustainability',
    COMPLIANCE: 'Compliance',
    ACCOUNT_MANAGERS: 'Account Managers',
    SOURCING: 'Sourcing',
    SALES: 'Sales',
  },
  APPROVAL_TEAMS: ['ACCOUNT_MANAGER', 'SUSTAINABILITY', 'COMPLIANCE'],
  APPROVER_ACTIONS: {
    APPROVE: 'APPROVE',
    REJECT: 'REJECT',
  },
  TEAM_ROLES: {
    MANAGER: 'MANAGER',
    MEMBER: 'MEMBER',
  },
  PRODUCT_TYPES: {
    SHRIMP: 'SHRIMP',
    FINFISH: 'FINFISH',
  },
  INCOTERMS: [
    {
      code: 'CNF',
      text: 'Cost & ',
      subText: 'Freight',
    },
    {
      code: 'DDPCY',
      text: 'Delivered Duty Paid',
      subText: 'Container Yard',
    },
  ],
  APPROVER_PROFORMA_STATUS: {
    PENDING: 'PENDING',
    HISTORY: 'HISTORY',
  },
  DEFAULT_COMPANY_PAGE_ACCESS: ['IMPORTER', 'PROCESSING_PLANT'],
  DEFAULT_TEAM_PAGE_ACCESS: [
    {
      name: 'Executive',
    },
    {
      name: 'Sourcing',
    },
  ],
  ACCESS_TO_ALL_TEAMS_AND_ROLES: [
    {
      name: 'Executive',
    },
    {
      name: 'Sustainability',
    },
    {
      name: 'Compliance',
    },
    {
      name: 'Account Managers',
    },
    {
      name: 'Sourcing',
    },
  ],
  SPECIFICATION_FIELDS: {
    WILD_CAUGHT: 'WILD_CAUGHT',
    FARM_RAISED: 'FARM_RAISED',
    SUSTAINABILITY_MIN_REQUIREMENTS: {
      WILD_CAUGHT: [
        {
          name: 'Any GSSI Recognized Certification',
          type: 'Other',
        },
        {
          name: 'MSC',
          type: 'Other',
        },
        {
          name: 'Fishery Improvement Progress (FIP) - Active Only',
          type: 'Other',
        },
        {
          name: 'Fishery Improvement Progress (FIP) - In Progress',
          type: 'Other',
        },
        {
          name: 'Responsible Fisheries Management (RFM)',
          type: 'Other',
        },
        {
          name: 'No Sustainability Required',
          type: 'None',
        },
      ],
      FARM_RAISED: [
        {
          name: 'BAP 1 Star',
          type: 'BAP',
        },
        {
          name: 'BAP 2 Star',
          type: 'BAP',
        },
        {
          name: 'BAP 3 Star',
          type: 'BAP',
        },
        {
          name: 'BAP 4 Star',
          type: 'BAP',
        },
        {
          name: 'ASC',
          type: 'Other',
        },
        {
          name: 'GlobalGAAP',
          type: 'Other',
        },
        {
          name: 'Any GSSI Recognized Certification',
          type: 'Other',
        },
      ],
    },
    FROZEN_TYPE: ['Single frozen', 'Double frozen'],
    COOKED_TYPE: ['Cooked', 'Raw', 'Parfried'],
    TREATMENT_TYPE: [
      'Sodium Tripolyphosphate (STPP)',
      'Salt',
      'Carbon Monoxide',
      'Other',
      'None, All Natural',
    ],
    MOISTURE_SYMBOL: ['<', '<=', '>', '>=', '='],
    IQF_OR_IVP: ['IQF', 'IVP'],
    PROCESSING_FACILITY_FOOD_SAFETY_MIN_REQUIREMENTS: [
      'Any GFSI Recognized Certification',
      'BRC Grade A',
      'BRC Grade B',
      'Other',
    ],
    SOCIAL_COMPLIANCE_REQUIREMENTS: ['BSCI', 'SA8000', 'SMETA', 'Other'],
    THIRD_PARTY_QUALITY_REQUIRED_TESTING_PARTNER: [
      'SGS',
      'Silliker',
      'TUV',
      'Eurofins',
      'Other',
    ],
    SCALED_OR_UNSCALED: 'scaled_or_unscaled',
    BONELESS_OR_BONE_IN: 'boneless_or_bone_in',
    SKIN_ON_OR_SKIN_OFF: 'skin_on_or_skin_off',
    COUNT_PER_LB: 'Count per lb',
    VARIANCE_SYMBOLS: ['+', '-', '+/-'],
    COMMENT_TYPES: [
      'COUNTRY_OF_ORIGIN',
      'ADDITIONAL_SPECIES_INFO',
      'TREATMENT',
      'GLAZE',
      'ADDITIONAL_PHYSICAL_ATTRIBUTE',
      'CORPORATE_ASSURANCE',
      'MOISTURE',
    ],
    ASSURANCE_TYPES: [
      'SOCIAL_COMPLIANCE',
      'SUSTAINABILITY_MIN_REQ',
      'FACILITY_FOOD_SAFETY',
      'THIRD_PARTY_QA_PARTNER',
    ],
    ASSOCIATED_COUNTRY_TYPES: ['FARM_OR_CATCH', 'PROCESSING', 'LABELLED'],
    UPDATABLE_STRING_FIELDS: [
      { name: 'additionalItemNumber', referFrom: 'form' },
      { name: 'baseUom', referFrom: 'panel' },
      { name: 'brand', referFrom: 'panel' },
      { name: 'companyItemNumber', referFrom: 'form' },
      { name: 'cookedState', referFrom: 'panel' },
      { name: 'customer', referFrom: 'panel' },
      { name: 'frozenState', referFrom: 'panel' },
      { name: 'iqfIvp', referFrom: 'panel' },
      { name: 'itemDescription', referFrom: 'form' },
      { name: 'moisturePercent', referFrom: 'form' },
      { name: 'moistureSymbol', referFrom: 'form' },
      { name: 'netUnitSize', referFrom: 'panel' },
      { name: 'noOfUnitsInCase', referFrom: 'panel' },
      { name: 'shelfLifeFromProductionInMonths', referFrom: 'form' },
      { name: 'shelfLifeReqForCustDeliveryInMonths', referFrom: 'form' },
      { name: 'treatment', referFrom: 'panel' },
      { name: 'wildOrFarm', referFrom: 'panel' },
      { name: 'productForm', referFrom: 'form' },
      { name: 'productType', referFrom: 'panel' },
      { name: 'frozenState', referFrom: 'panel' },
      { name: 'cookedState', referFrom: 'panel' },
      { name: 'scaledState', referFrom: 'panel' },
      { name: 'skinState', referFrom: 'panel' },
      { name: 'boneState', referFrom: 'panel' },
      { name: 'skinningNotes', referFrom: 'form' },
      { name: 'sizeRange', referFrom: 'panel' },
    ],
  },
  TIMELINE_ENTITIES: ['RFQ', 'Proforma', 'Purchase Order'],
  FILE_TYPE_TO_MIME_TYPE: {
    pdf: 'application/pdf',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    htm: 'text/html',
    html: 'text/html',
    csv: 'text/plain',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    json: 'application/json',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    txt: 'text/plain',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    zip: 'application/zip',
  },
  QUESTIONNAIRE_GROUPS: [
    { name: 'FACTORY_INFO', title: 'Factory Information' },
    { name: 'EMPLOYEE_INFO', title: 'Employee Information' },
    { name: 'OTHER_INFO' },
  ],
  COMMON_CONTACT_INFORMATION_FIELDS: [
    {
      title: 'Phone',
      name: 'phoneNumber',
      inputType: 'INTEGER',
      validateBasedOnField: 'contactName',
      validateDynamically: true,
    },
    {
      title: 'Email Address',
      name: 'emailAddress',
      inputType: 'EMAIL',
      validateBasedOnField: 'contactName',
      validateDynamically: true,
    },
  ],
  FACTORY_CONTACT_INFORMATION_TYPES: {
    title: 'Factory Contact Information',
    contactTypes: [
      {
        title: 'Factory Main Contact Information',
        name: 'FACTORY_MAIN',
        fields: [
          {
            title: 'Primary Contact',
          },
        ],
      },
      {
        title: 'QC Contact Information',
        name: 'QC',
        fields: [
          {
            title: 'QC Contact',
          },
        ],
      },
      {
        title: 'Food Safety Contact Information',
        name: 'FOOD_SAFETY',
        fields: [
          {
            title: 'FS Contact',
          },
        ],
      },
      {
        title: 'Plant Manager Contact Information',
        name: 'PLANT_MANAGER',
        fields: [
          {
            title: 'Mgr Contact',
          },
        ],
      },
      {
        title: 'Chairman Contact Information',
        name: 'CHAIRMAN',
        fields: [
          {
            title: 'Contact',
          },
        ],
      },
    ],
  },
  COMMON_SUBCONTRACTOR_FACTORY_CONTACT_INFORMATION_FIELDS: [
    {
      title: 'Factory Name',
      name: 'factoryName',
      inputType: 'STRING',
      validatesOtherFields: true,
    },
    {
      title: 'Address 1',
      name: 'line1',
      inputType: 'STRING',
      validateBasedOnField: 'factoryName',
      validateDynamically: true,
    },
    {
      title: 'Address 2',
      name: 'line2',
      inputType: 'STRING',
    },
    {
      title: 'Address 3',
      name: 'line3',
      inputType: 'STRING',
    },
    {
      title: 'Country',
      name: 'country',
      inputType: 'STRING',
      validateBasedOnField: 'factoryName',
      validateDynamically: true,
    },
    {
      title: 'State/Province',
      name: 'state',
      inputType: 'STRING',
      validateBasedOnField: 'factoryName',
      validateDynamically: true,
    },
    {
      title: 'Zip Code',
      name: 'zipcode',
      inputType: 'STRING',
      validateBasedOnField: 'factoryName',
      validateDynamically: true,
    },
    {
      title: 'City',
      name: 'city',
      inputType: 'STRING',
      validateBasedOnField: 'factoryName',
      validateDynamically: true,
    },
    {
      title: 'Contact Name',
      name: 'contactName',
      inputType: 'STRING',
      validateBasedOnField: 'factoryName',
      validateDynamically: true,
    },
    {
      title: 'Contact Email Address',
      name: 'emailAddress',
      inputType: 'EMAIL',
      validateBasedOnField: 'factoryName',
      validateDynamically: true,
    },
    {
      title: 'Phone',
      name: 'phoneNumber',
      inputType: 'INTEGER',
      validateBasedOnField: 'factoryName',
      validateDynamically: true,
    },
    {
      title: 'Customers',
      name: 'customers',
      inputType: 'STRING',
    },
    {
      title: 'Is logo used',
      name: 'isLogoUsed',
      inputType: 'BOOLEAN',
      hasBooleanValue: true,
    },
  ],
  SUBCONTRACTOR_FACTORY_CONTACT_INFORMATION_TYPES: {
    title: 'Subcontractor Factory Contact Information',
    contactTypes: [
      {
        title: 'Bag Printer',
        name: 'BAG_PRINTER',
      },
      {
        title: 'Master',
        name: 'MASTER',
      },
      {
        title: 'Other',
        name: 'OTHER',
      },
    ],
  },
  FACTORY_INFORMATION_REQUEST_MAPPINGS: {
    plantAdditionalInformation: ['FACTORY_INFO', 'EMPLOYEE_INFO', 'OTHER_INFO'],
    plantContacts: [
      'FACTORY_MAIN',
      'QC',
      'FOOD_SAFETY',
      'PLANT_MANAGER',
      'CHAIRMAN',
    ],
    subContractors: ['BAG_PRINTER', 'MASTER', 'OTHER'],
  },
};

export default StringConstants;
