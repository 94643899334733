import { Col, Row } from 'antd';

import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import PendingProformaOverview from 'src/components/proforma/buyer/PendingProformaOverview';
import React from 'react';
import ReminderOverview from 'src/components/reminder/ReminderOverview';
import TotalProformaCard from 'src/components/proforma/buyer/TotalProformasCard';
import { connect } from 'react-redux';
import { getReadableDisplayName } from 'src/utils/DisplayUtils';

class ApproversDashboard extends React.Component {
  render() {
    let team =
      this.props.user &&
      this.props.user.teams &&
      this.props.user.teams.length > 0 &&
      this.props.user.teams[0].name;
    return (
      <>
        <HeaderMetadata
          title='Approvers Dashboard | Carava'
          description='Manage RFQs,  Proformas easily from this dashbaord'
        />
        <Row type='flex' gutter={15}>
          <Col className='' span={24} xs={24} lg={24} xl={24}>
            <p className='pagetitle'>{team && getReadableDisplayName(team)}</p>
          </Col>
          <Col className='' span={10} xs={10} lg={6} xl={6}>
            <TotalProformaCard />
          </Col>

          <Col className='' span={10} xs={10} lg={18} xl={18}>
            <ReminderOverview />
          </Col>
        </Row>
        <Row gutter={15}>
          <Col className='mt-15' span={10} xs={10} lg={24} xl={24}>
            <PendingProformaOverview />
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(ApproversDashboard);
