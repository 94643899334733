import ApiManager from 'src/api/ApiManager';
import HttpConstants from 'src/constants/HttpConstants';
import UrlConstants from 'src/constants/UrlConstants';

const ProformaService = {
  getAllProformas: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_PROFORMAS.USECASE,
      HttpConstants.GET_METHOD,
      data
    );
  },
  getAuctionProforma: (auctionId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_AUCTION_PROFORMA.USECASE,
      HttpConstants.GET_METHOD,
      { ':auctionId': auctionId }
    );
  },
  downloadProforma: (proformaId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.DOWNLOAD_PROFORMA.USECASE,
      HttpConstants.GET_BLOB_METHOD,
      { ':proformaId': proformaId }
    );
  },
  downloadContract: (proformaId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.DOWNLOAD_CONTRACT.USECASE,
      HttpConstants.GET_BLOB_METHOD,
      { ':proformaId': proformaId }
    );
  },
  getCalculatedProformaPlannedOrders: (proformaId, data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_CALCULATED_PROFORMA_PLANNED_ORDERS.USECASE,
      HttpConstants.POST_METHOD,
      { ...data, ':proformaId': proformaId }
    );
  },
  getProformaDetails: (proformaId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PROFORMA_DETAILS.USECASE,
      HttpConstants.GET_METHOD,
      { ':proformaId': proformaId }
    );
  },
  updateProforma: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_PROFORMA.USECASE,
      HttpConstants.PATCH_METHOD,
      data
    );
  },
  getDiff: (proformaId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_AUDIT_DIFF.USECASE,
      HttpConstants.GET_METHOD,
      { ':proformaId': proformaId }
    );
  },
  approveProforma: (proformaId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.APPROVE_PROFORMA.USECASE,
      HttpConstants.POST_METHOD,
      { ':proformaId': proformaId }
    );
  },
  createDirectProforma: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.CREATE_DIRECT_PROFORMA.USECASE,
      HttpConstants.POST_METHOD,
      data
    );
  },
  getProformaCountForDashboard: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PROFORMA_COUNT_FOR_DASHBOARD.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  getPendingProformas: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PENDING_PROFORMAS.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  getHistoricalProformas: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_HISTORICAL_PROFORMAS.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  internalReviewAction: (proformaId, data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.INTERNAL_REVIEW_ACTION.USECASE,
      HttpConstants.POST_METHOD,
      { ...data, ':proformaId': proformaId }
    );
  },
  getProformaVersions: (proformaId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PROFORMA_VERSIONS.USECASE,
      HttpConstants.GET_METHOD,
      { ':proformaId': proformaId }
    );
  },
  getProformaDetailForVersion: (proformaId, version) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PROFORMA_DETAIL_FOR_VERSION.USECASE,
      HttpConstants.GET_METHOD,
      { ':proformaId': proformaId, ':version': version }
    );
  },
  downloadProformaPDFForVersion: (proformaId, version) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.DOWNLOAD_PROFORMA_FOR_VERSION.USECASE,
      HttpConstants.GET_BLOB_METHOD,
      {
        ':version': version,
        ':proformaId': proformaId,
      }
    );
  },
  splitProforma: (proformaId, data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.SPLIT_PROFORMA.USECASE,
      HttpConstants.POST_METHOD,
      {
        ':proformaId': proformaId,
        ...data,
      }
    );
  },
};

export default ProformaService;
