import './ParticipantsAuctionIncoterms.css';

import { Button, Table, Tabs } from 'antd';
import { find, forEach, isEmpty, sortBy } from 'lodash';
import { findBidIncotermByCode, getDisplayIncoterm } from 'src/utils/BidUtils';
import {
  getAmountDisplayText,
  getFormattedFullDate,
} from 'src/utils/DisplayUtils';

import AuctionService from 'src/services/AuctionService';
import AuctionTableManager from 'src/utils/tables/AuctionTableManager';
import Card from 'src/components/common/cards/Card';
import ChatPopup from 'src/components/common/chatDrawer/ChatPopup';
import ExcelUtils from 'src/utils/exports/ExcelUtils';
import React from 'react';
import SellerBidHistoryDrawer from './SellerBidHistoryDrawer';
import StringConstants from 'src/constants/StringConstants';
import { withRouter } from 'react-router-dom';

const { TabPane } = Tabs;

class ParticipantsAuctionIncoterms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bidHistoryVisible: false,
      selectedSeller: undefined,
      allParticipants: [],
      tabLoading: false,
      downloadLoading: false,
      activeTab: 'participants',
      chatPopupVisible: false,
      plantId: undefined,
      auctionId: undefined,
    };
  }

  onClose = () => {
    this.setState({ bidHistoryVisible: false });
  };

  areAllPlannedOrdersAssigned = () => {
    const { auctionDetails } = this.props;
    return (
      auctionDetails &&
      auctionDetails.auctionPlannedOrders &&
      auctionDetails.auctionPlannedOrders.every(
        (plannedOrder) =>
          plannedOrder.status === StringConstants.PLANNED_ORDER_STATUS.ASSIGNED
      )
    );
  };

  findCompany = (biddingCompanies, companyId) => {
    let biddingCompany = find(biddingCompanies, function (o) {
      return o.id === companyId;
    });

    return biddingCompany;
  };

  getParticipantColumns = () => {
    return AuctionTableManager.getAuctionParticipantColumnsForImporters.call(
      this
    );
  };

  getAuctionHistoryColumns = () => {
    return AuctionTableManager.getAuctionHistoryColumnsForImporters.call(this);
  };

  getIncotermComparisonColumns = () => {
    return AuctionTableManager.getIncotermComparisonColumnsForImporters.call(
      this
    );
  };

  fetchAllParticipants = async () => {
    try {
      const auctionId = this.props.match.params.id;
      this.setState({ tabLoading: true });
      let response = await AuctionService.getAuctionParticipants({
        ':auctionId': auctionId,
        unassigned: false,
      });

      let allParticipants = response.data;
      allParticipants = sortBy(allParticipants, function (o) {
        return o.bids[0].rank;
      });

      this.setState({
        tabLoading: false,
        allParticipants,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        tabLoading: false,
      });
    }
  };

  exportParticipants = () => {
    const { participants, auctionDetails } = this.props;
    this.setState({ downloadLoading: true });
    const exportData = [];
    forEach(participants, (participant) => {
      const { bids, company } = participant;
      const obj = {
        'Rank No': bids.length > 0 ? bids[0].rank : '-',
        'Seller Name': company.name,
      };
      const displayIncotermObj = getDisplayIncoterm(bids[0].bidIncoterms);
      obj['Current Quote'] =
        bids.length > 0 ? getAmountDisplayText(displayIncotermObj.amount) : '';
      obj['Current Incoterm'] = bids.length > 0 ? displayIncotermObj.code : '';
      obj['Calculated Value ?'] =
        bids.length > 0 ? (displayIncotermObj.isCalculated ? 'Yes' : 'No') : '';
      obj['FCL Available'] = bids.length > 0 ? bids[0].fclAvailable : '';
      obj['Date/Time of quote'] =
        bids.length > 0 ? getFormattedFullDate(bids[0].createdAt) : '-';
      exportData.push(obj);
    });
    ExcelUtils.downloadAsExcel(exportData, auctionDetails.id + '_Participants');
    this.setState({ downloadLoading: false });
  };

  exportIncotermComparison = () => {
    const { allParticipants } = this.state;
    const { auctionDetails } = this.props;

    this.setState({ downloadLoading: true });

    const incotermComparisonData = [];
    forEach(allParticipants, (participant) => {
      const bid = participant.bids.length > 0 ? participant.bids[0] : {};
      const cnf = findBidIncotermByCode(bid.bidIncoterms, 'CNF');
      const ddpcy = findBidIncotermByCode(bid.bidIncoterms, 'DDPCY');

      const object = {
        'Rank No.': bid.rank ? bid.rank : '-',
        Seller: participant.company.name,
        'CNF/CFR': cnf ? getAmountDisplayText(cnf.amount) : '-',
        'Estimated CNF/CFR ?': cnf ? (cnf.isCalculated ? 'Yes' : 'No') : '-',
        DDPCY: ddpcy ? getAmountDisplayText(ddpcy.amount) : '-',
        'Estimated DDPCY ?': ddpcy ? (ddpcy.isCalculated ? 'Yes' : 'No') : '-',
      };
      incotermComparisonData.push(object);
    });
    ExcelUtils.downloadAsExcel(
      incotermComparisonData,
      auctionDetails.id + '_Incoterm_Comparison'
    );
    this.setState({ downloadLoading: false });
  };

  exportData = () => {
    const { activeTab } = this.state;
    if (activeTab === 'participants') this.exportParticipants();
    else if (activeTab === 'incoterm-comparison')
      this.exportIncotermComparison();
  };

  showChatPopup = (plantId, auctionId) => {
    this.setState({ chatPopupVisible: true, plantId, auctionId });
  };

  hideChatPopup = () => {
    this.setState({ chatPopupVisible: false });
  };

  render() {
    const {
      activeTab,
      allParticipants,
      bidHistoryVisible,
      downloadLoading,
      selectedSeller,
      tabLoading,
      chatPopupVisible,
      plantId,
      auctionId,
    } = this.state;

    return (
      <>
        <Card>
          <Tabs
            className='notification-alerts auction-tabs '
            defaultActiveKey={activeTab}
            onTabClick={async (value) => {
              if (value === 'incoterm-comparison') {
                await this.fetchAllParticipants();
              }
              this.setState({ activeTab: value });
            }}
            tabBarExtraContent={{
              right:
                activeTab === 'participants' ||
                activeTab === 'incoterm-comparison' ? (
                  <Button
                    className='head-box-va mb-10'
                    type='link'
                    onClick={this.exportData}
                    loading={downloadLoading || tabLoading}
                    disabled={
                      activeTab === 'participants'
                        ? isEmpty(this.props.participants)
                        : activeTab === 'incoterm-comparison'
                        ? isEmpty(allParticipants)
                        : false
                    }
                  >
                    Download as CSV
                  </Button>
                ) : (
                  <></>
                ),
            }}
          >
            {/*Participants tab */}
            <TabPane
              className='participant-tab'
              tab={<span>Participants</span>}
              key='participants'
            >
              <Table
                className='auction-tab-table'
                locale={{ filterConfirm: 'Apply' }}
                columns={this.getParticipantColumns()}
                dataSource={this.props.participants}
                rowKey={(row) => row.company.id}
                pagination={
                  this.props.participants &&
                  this.props.participants.length >= 10
                }
                footer={() => (
                  <div className='lb-denote'>
                    <span className='seller-bid-sub'>LB</span> Lowest Quote
                  </div>
                )}
              />
            </TabPane>
            {/* Participants tab ends */}
            {/* Auction History tab */}
            <TabPane tab={<span>RFQ History</span>} key='history'>
              <Table
                pagination={false}
                className='auction-tab-table2'
                locale={{ filterConfirm: 'Apply' }}
                columns={this.getAuctionHistoryColumns()}
                dataSource={this.props.allBids.bids}
                rowKey={(row) => row.id}
              />
            </TabPane>
            {/* Auction History tab end */}
            {/*Incoterm Comparison tab */}
            <TabPane
              tab={<span>Incoterm Comparison</span>}
              key='incoterm-comparison'
            >
              <Table
                pagination={false}
                className='auction-tab-table3'
                locale={{ filterConfirm: 'Apply' }}
                columns={this.getIncotermComparisonColumns()}
                rowKey={(row) => row.company.id}
                dataSource={allParticipants}
                loading={tabLoading}
              />
            </TabPane>
            {/* Incoterm Comparison tab end */}
          </Tabs>
        </Card>

        <SellerBidHistoryDrawer
          onClose={() => this.onClose()}
          visible={bidHistoryVisible}
          data={{
            ...this.props.allBids,
            bids: this.props.allBids.bids.filter((bid) => {
              return selectedSeller && bid.companyId === selectedSeller.id;
            }),
            auctionIncoterms: this.props.auctionDetails.auctionIncoterms,
            auctionTargetPrice: this.props.auctionDetails.targetPrice,
            lowestBid: this.props.auctionDetails.lowestBid,
          }}
          selectedSeller={selectedSeller}
        />

        {chatPopupVisible && (
          <ChatPopup
            visible={chatPopupVisible}
            plantId={plantId}
            auctionId={auctionId}
            hideChatPopup={() => this.hideChatPopup()}
          />
        )}
      </>
    );
  }
}
export default withRouter(ParticipantsAuctionIncoterms);
