import '../common/Auth.css';

import { Button, Checkbox, Form, Input } from 'antd';
import { Link, withRouter } from 'react-router-dom';

import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';

const SignupForm = (props) => {
  return (
    <>
      <div className='login-left-details'>
        <img
          className='log-logo'
          src={require('../../../assets/images/logo.png').default}
          alt='Carava Logo'
        />
        <h2 className='all-form-heading'>Get Started</h2>
        <p className='log-sign-form-desc'>
          Already have an account?<Link to={RouteConstants.LOGIN}>Log In</Link>
        </p>
        {/* Signup form */}
        <Form className='all-form signup-form' layout='vertical'>
          <Form.Item
            name={['user', 'name']}
            label='Your Name'
            rules={[{ required: true, message: 'Please input your name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Email Address'
            name='email'
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Create Password'
            name='password'
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item name='agreement' valuePropName='checked'>
            <Checkbox className='tnc-check'>
              By signing up you agree to our{' '}
              <a href='/' target='_blank' rel='noopener noreferrer'>
                Privacy Policy
              </a>{' '}
              and{' '}
              <a href='/' target='_blank' rel='noopener noreferrer'>
                Terms of Service
              </a>
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button className='submit' type='primary' htmlType='submit'>
              Sign Up
            </Button>
          </Form.Item>
        </Form>

        {/* signup form end */}
      </div>
    </>
  );
};

export default withRouter(SignupForm);
