import './auctionSummary/AuctionSummary.css';

import { Button, Col, Row, Space, Tag } from 'antd';
import { isEmpty, sumBy } from 'lodash';
import { isUserImporter, isUserManagerOfTeam } from 'src/helpers/user';

import AcceptOrEditBidModal from 'src/modals/auction/AcceptOrEditBidModal';
import ApiUtils from 'src/utils/ApiUtils';
import AuctionService from 'src/services/AuctionService';
import AuctionStats from 'src/components/auction/bid/AuctionStats';
import AuctionSummaryTopCard from 'src/components/auction/bid/AuctionSummaryTopCard';
import AuctionUtils from 'src/utils/AuctionUtils';
import AwardedBids from 'src/components/auction/bid/AwardedBids';
import BidAwardRejectedModal from 'src/modals/auction/BidAwardRejectedModal';
import BidAwardSuccessfulModal from 'src/modals/auction/BidAwardSuccessfulModal';
import BidService from 'src/services/BidService';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import ConfirmationModal from 'src/modals/common/ConfirmationModal';
import HorizontalTimeline from 'src/components/common/horizontalTimeline/HorizontalTimeline';
import { Link } from 'react-router-dom';
import ParticipantsAuctionIncoterms from 'src/components/auction/bid/ParticipantsAuctionIncoterms';
import PlannedOrder from 'src/components/plannedOrder/PlannedOrder';
import PlannedOrderDataTable from 'src/components/plannedOrder/auction/PlannedOrderDataTable';
import React from 'react';
import RejectBidModal from 'src/modals/auction/RejectBidModal';
import ReviewMultiplePlannedOrders from '../../create/reviewAuctionPlannedOrders/ReviewMultiplePlannedOrders';
import RouteConstants from 'src/constants/RouteConstants';
import StringConstants from 'src/constants/StringConstants';
import TimeTicker from 'src/components/common/time/TimeTicker';
import UrlGenerator from 'src/api/UrlGenerator';
import { findActualBiddedIncoterm } from 'src/utils/BidUtils';
import { getReadableDisplayName } from 'src/utils/DisplayUtils';
import moment from 'moment';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';

class AuctionSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auctionId: this.props.auctionId,
      auctionDetails: this.props.auctionDetails,
      auctionParticipants: this.props.auctionParticipants,
      auctionBids: this.props.auctionBids,
      assignPlannedOrdersModalVisible: false,
      selectedRowKeys: [],
      selectedBid: undefined,
      selectedWeight: 0,
      finalBidValue: 0,
      finalBidIncoterm: '',
      bidAction: undefined,
      auctionWinners: this.props.auctionWinners,
      auctionProforma: this.props.auctionProforma,
      selectedAuctionWinner: undefined,
      cancelAuctionModalVisible: false,
      rejectBidModalVisible: false,
      paymentTerms: this.props.auctionDetails.paymentTerms,
      bidAcceptedModalVisible: false,
      bidRejectedModalVisible: false,
      awardedBid: {},
      actionLoading: false,
      bidEditable: true,
    };
  }

  showPlannedOrdersAssignmentModal = (bid, action) => {
    let finalBid = findActualBiddedIncoterm(bid.bidIncoterms);

    this.setState({
      assignPlannedOrdersModalVisible: true,
      selectedBid: bid,
      finalBidValue: finalBid.amount,
      finalBidIncoterm: finalBid.code,
      bidAction: action,
      selectedRowKeys: [],
      selectedWeight: 0,
    });
  };

  showEditPlannedOrdersAssignmentModal = (auctionWinner, action) => {
    const { auctionDetails } = this.state;

    let bidEditable = true;
    if (
      auctionDetails.status === StringConstants.AUCTION_STATUS.PROFORMA_REVIEW
    )
      bidEditable = false;
    let selectedAuctionWinnerPlannedOrders =
      auctionWinner.auctionPlannedOrders.map((plannedOrder) => plannedOrder.id);

    this.setState({
      assignPlannedOrdersModalVisible: true,
      selectedAuctionWinner: auctionWinner,
      selectedRowKeys: selectedAuctionWinnerPlannedOrders,
      selectedWeight: sumBy(auctionWinner.auctionPlannedOrders, 'totalWeight'),
      bidAction: action,
      finalBidValue: auctionWinner.bidValue,
      finalBidIncoterm: auctionWinner.incoterm.code,
      bidEditable,
      paymentTerms: auctionWinner.paymentTerms,
    });
  };

  getDataForModal = () => {
    const {
      bidAction,
      selectedBid,
      selectedAuctionWinner,
      auctionDetails,
      selectedRowKeys,
      selectedWeight,
      finalBidValue,
      finalBidIncoterm,
      paymentTerms,
    } = this.state;

    return {
      companyName:
        bidAction === StringConstants.BID_ACTIONS.ACCEPT
          ? selectedBid && selectedBid.company && selectedBid.company.name
          : selectedAuctionWinner &&
            selectedAuctionWinner.company &&
            selectedAuctionWinner.company.name,
      fclPromised:
        bidAction === StringConstants.BID_ACTIONS.ACCEPT
          ? selectedBid && selectedBid.fclAvailable
          : selectedAuctionWinner && selectedAuctionWinner.totalFCL,
      totalFCL: auctionDetails.totalFCL,
      item:
        auctionDetails.specification.companyItemNumber +
        (' (' + auctionDetails.specification.itemDescription + ')'),
      additionalItemNumber: auctionDetails.specification.additionalItemNumber,
      productForm: auctionDetails.specification.productForm,
      customer: auctionDetails.specification.customer,
      auctionPlannedOrders: auctionDetails.auctionPlannedOrders,
      selectedPlannedOrders: selectedRowKeys,
      selectedWeight,
      auctionIncoterms: auctionDetails.auctionIncoterms,
      finalBidValue,
      finalBidIncoterm,
      paymentTerms,
    };
  };

  getPlannedOrdersForAssignmentModal = () => {
    const { auctionDetails, selectedAuctionWinner } = this.state;
    const { auctionPlannedOrders } = auctionDetails;

    let selectedAuctionWinnerPlannedOrders = selectedAuctionWinner
      ? selectedAuctionWinner.auctionPlannedOrders.map(
          (plannedOrder) => plannedOrder.id
        )
      : [];

    return this.state.bidAction === StringConstants.BID_ACTIONS.ACCEPT
      ? auctionPlannedOrders &&
          auctionPlannedOrders.filter(
            (plannedOrder) =>
              plannedOrder.status ===
              StringConstants.PLANNED_ORDER_STATUS.BIDDING
          )
      : auctionPlannedOrders &&
          auctionPlannedOrders.filter(
            (plannedOrder) =>
              plannedOrder.status ===
                StringConstants.PLANNED_ORDER_STATUS.BIDDING ||
              (plannedOrder.status ===
                StringConstants.PLANNED_ORDER_STATUS.ASSIGNED &&
                selectedAuctionWinnerPlannedOrders.includes(plannedOrder.id))
          );
  };

  acceptBid = () => {
    let data = {};
    let bidId = this.state.selectedBid.id;
    data[':bidId'] = bidId;
    if (isEmpty(this.state.selectedRowKeys)) {
      toast.error('Please assign atleast one planned order');
      this.setState({ actionLoading: false });
      return;
    }
    data.auctionPlannedOrders = this.state.selectedRowKeys.map((id) => {
      return {
        id: id,
      };
    });
    data.bidIncoterm = {
      incotermCode: this.state.finalBidIncoterm,
      amount: this.state.finalBidValue,
    };
    data.paymentTerms = this.state.paymentTerms;
    BidService.acceptBid(data)
      .then((response) => {
        toast.success('Accepted quote successfully');
        this.setState({
          actionLoading: false,
          bidAcceptedModalVisible: true,
          assignPlannedOrdersModalVisible: false,
          selectedRowKeys: [],
          selectedWeight: 0,
          awardedBid: {
            totalFCL: response.data.auctionPlannedOrders.length,
            company: response.data.company,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        let errorMessage = ApiUtils.getErrorMessage(error);
        toast.error('Failed to accept quote - ' + errorMessage);
        this.setState({
          actionLoading: false,
          assignPlannedOrdersModalVisible: false,
        });
      });
  };

  editTerms = () => {
    let data = {};
    let auctionWinnerId = this.state.selectedAuctionWinner.id;
    data[':auctionWinnerId'] = auctionWinnerId;
    if (isEmpty(this.state.selectedRowKeys)) {
      toast.error('Please assign atleast one planned order');
      this.setState({ actionLoading: false });
      return;
    }
    data.auctionPlannedOrders = this.state.selectedRowKeys.map((id) => {
      return {
        id: id,
      };
    });
    data.bidIncoterm = {
      incotermCode: this.state.finalBidIncoterm,
      amount: this.state.finalBidValue,
    };
    data.paymentTerms = this.state.paymentTerms;
    BidService.editAuctionWinner(data)
      .then((response) => {
        toast.success('Edited terms successfully');
        this.setState(
          {
            actionLoading: false,
            assignPlannedOrdersModalVisible: false,
            selectedRowKeys: [],
            selectedWeight: 0,
          },
          () => {
            this.props.refreshPageData();
          }
        );
      })
      .catch((error) => {
        console.log(error);
        let errorMessage = ApiUtils.getErrorMessage(error);
        toast.error('Failed to edit terms - ' + errorMessage);
        this.setState({
          actionLoading: false,
          assignPlannedOrdersModalVisible: false,
        });
        this.setState({
          actionLoading: false,
          assignPlannedOrdersModalVisible: false,
        });
      });
  };

  rejectBid = () => {
    const { auctionWinnerId } = this.state;
    BidService.rejectBid(auctionWinnerId)
      .then((response) => {
        toast.success('Rejected quote successfully');
        this.setState({
          actionLoading: false,
          bidRejectedModalVisible: true,
          assignPlannedOrdersModalVisible: false,
          selectedRowKeys: [],
          selectedWeight: 0,
          rejectBidModalVisible: false,
        });
      })
      .catch((error) => {
        console.log(error);
        let errorMessage = ApiUtils.getErrorMessage(error);
        toast.error('Failed to reject quote - ' + errorMessage);
        this.setState({
          actionLoading: false,
          assignPlannedOrdersModalVisible: false,
        });
      });
  };

  showRejectBidModal = (auctionWinnerId) => {
    this.setState({ rejectBidModalVisible: true, auctionWinnerId });
  };

  performBidAction = () => {
    const { bidAction } = this.state;
    if (bidAction === StringConstants.BID_ACTIONS.ACCEPT) this.acceptBid();
    else this.editTerms();
  };

  onSelectChange = (selectedRowKeys) => {
    const { auctionPlannedOrders } = this.state.auctionDetails;
    let selectedPlannedOrders = auctionPlannedOrders.filter((plannedOrder) => {
      return selectedRowKeys.includes(plannedOrder.id);
    });
    this.setState({
      selectedRowKeys,
      selectedWeight: sumBy(selectedPlannedOrders, 'totalWeight'),
    });
  };

  cancelAuction = async () => {
    const { auctionId } = this.state;
    AuctionService.cancelAuction(auctionId)
      .then((response) => {
        toast.success('RFQ cancelled successfully');
        this.setState({ visible: false, actionLoading: false });
        this.props.history.push(
          UrlGenerator.addQueryParamsToUrl(RouteConstants.AUCTIONS, {
            status: StringConstants.AUCTION_STATUS.HISTORY,
          })
        );
      })
      .catch((error) => {
        console.log(error);
        toast.error('Failed to cancel RFQ');
        this.setState({ visible: false, actionLoading: false });
      });
  };

  cancelAction = (action) => {
    this.setState({ [action + 'ModalVisible']: false });
  };

  completeAuction = async () => {
    const { auctionId, auctionDetails } = this.state;
    try {
      if (!AuctionUtils.isCompletedAuction(auctionDetails.status))
        await AuctionService.reviewProforma(auctionId);
      this.props.history.push(
        UrlGenerator.getUiUrlWithPathParams(RouteConstants.PROFORMA_REVIEW, {
          id: auctionId,
        })
      );
    } catch (error) {
      toast.error(error.data.message);
      console.log(error);
    }
  };

  hasAnyContractBeenGenerated = () => {
    const { auctionProforma } = this.state;

    return auctionProforma.some(
      (proforma) =>
        proforma.status === StringConstants.PROFORMA_STATUS.CONTRACT_GENERATED
    );
  };

  render() {
    const {
      auctionId,
      auctionDetails,
      auctionParticipants,
      auctionBids,
      auctionWinners,
      auctionProforma,
      cancelAuctionModalVisible,
      rejectBidModalVisible,
      auctionWinnerId,
      bidAcceptedModalVisible,
      bidRejectedModalVisible,
      awardedBid,
      actionLoading,
      bidEditable,
      bidAction,
    } = this.state;

    const { refreshedTime } = this.props;

    return (
      <>
        {isUserImporter() && (
          <HorizontalTimeline
            data={auctionDetails}
            nextEntityData={auctionProforma}
            currentEntity={'RFQ'}
          />
        )}
        <Row type='flex' gutter={15}>
          <Col className='' span={24} xs={24} lg={12} xl={12}>
            <Space>
              <span className='pagetitle'>RFQ Summary</span>
              <Tag
                color={
                  StringConstants.AUCTION_STATUS_COLORS[auctionDetails.status]
                }
              >
                {getReadableDisplayName(auctionDetails.status).toUpperCase()}
              </Tag>
            </Space>
          </Col>
          <Col className='' span={24} xs={24} lg={12} xl={12}>
            <Space className='fl-right'>
              <TimeTicker
                prefix='Last refreshed'
                time={moment(refreshedTime)}
              />
              <Link
                to={UrlGenerator.getUiUrlWithPathParams(
                  RouteConstants.AUCTION_DETAILS,
                  { id: auctionId }
                )}
              >
                <Button className='view-details-cta' type='primary'>
                  View Details
                </Button>
              </Link>
              {isUserImporter() &&
                isUserManagerOfTeam(StringConstants.TEAMS.SOURCING) &&
                (AuctionUtils.isPendingAuction(auctionDetails.status) ||
                  AuctionUtils.isCompletedAuction(auctionDetails.status)) && (
                  <>
                    <Button
                      className='heading-cta '
                      type='primary'
                      onClick={() => this.completeAuction()}
                      disabled={auctionWinners.length === 0}
                    >
                      Review Proformas
                    </Button>
                    {!AuctionUtils.isCompletedAuction(
                      auctionDetails.status
                    ) && (
                      <Button
                        className='heading-cta-danger'
                        onClick={() =>
                          this.setState({
                            cancelAuctionModalVisible: true,
                          })
                        }
                        disabled={this.hasAnyContractBeenGenerated()}
                      >
                        Cancel RFQ
                      </Button>
                    )}
                  </>
                )}
            </Space>

            <ConfirmationModal
              visible={cancelAuctionModalVisible}
              negativeAction={true}
              heading='Cancel RFQ'
              title={StringConstants.AUCTION_ACTIONS.CANCEL_AUCTION.title}
              description={
                StringConstants.AUCTION_ACTIONS.CANCEL_AUCTION.description
              }
              okText='Yes, Cancel the RFQ'
              cancelText='No'
              onSubmit={() => {
                this.setState({ actionLoading: true }, () =>
                  this.cancelAuction()
                );
              }}
              onCancel={() =>
                this.cancelAction(
                  StringConstants.AUCTION_ACTIONS.CANCEL_AUCTION.key
                )
              }
              actionLoading={actionLoading}
            />
            {rejectBidModalVisible && (
              <RejectBidModal
                visible={rejectBidModalVisible}
                onSubmit={() => {
                  this.setState({ actionLoading: true }, () =>
                    this.rejectBid(auctionWinnerId)
                  );
                }}
                onCancel={() => this.setState({ rejectBidModalVisible: false })}
                actionLoading={actionLoading}
                data={auctionWinners}
                auctionWinnerId={auctionWinnerId}
              />
            )}
            <BidAwardSuccessfulModal
              visible={bidAcceptedModalVisible}
              data={awardedBid}
              refreshPageFn={() => this.props.refreshPageData()}
            />
            {bidRejectedModalVisible && (
              <BidAwardRejectedModal
                visible={bidRejectedModalVisible}
                data={auctionWinners}
                auctionWinnerId={auctionWinnerId}
                refreshPageFn={() => this.props.refreshPageData()}
              />
            )}
          </Col>
        </Row>

        <Row className='mb-15'>
          <Col
            className=''
            span={24}
            xs={24}
            lg={24}
            xl={24}
            style={{ textAlign: 'center' }}
          >
            <AuctionSummaryTopCard
              auctionDetails={auctionDetails}
              auctionParticipants={auctionParticipants}
              auctionBids={auctionBids}
              isAuctionSummary={true}
              auctionWinners={auctionWinners}
            />
          </Col>
        </Row>
        <Row>
          <AcceptOrEditBidModal
            visible={this.state.assignPlannedOrdersModalVisible}
            data={this.getDataForModal()}
            tableData={this.getPlannedOrdersForAssignmentModal()}
            onSelectPlannedOrder={this.onSelectChange}
            onOk={() => {
              this.setState({ actionLoading: true }, () => {
                this.performBidAction();
              });
            }}
            onCancel={() =>
              this.setState({ assignPlannedOrdersModalVisible: false })
            }
            onBidValueChange={(value) =>
              this.setState({ finalBidValue: value })
            }
            onIncotermChange={(value) =>
              this.setState({ finalBidIncoterm: value })
            }
            onPaymentTermChange={(value) =>
              this.setState({ paymentTerms: value })
            }
            actionLoading={actionLoading}
            bidEditable={bidEditable}
            bidAction={bidAction}
          />
          <Col span={24} xs={24} lg={24} xl={24}>
            <AwardedBids
              auctionWinners={auctionWinners}
              targetPrice={auctionDetails.targetPrice}
              showEditPlannedOrdersAssignmentModal={(auctionWinner, action) =>
                this.showEditPlannedOrdersAssignmentModal(auctionWinner, action)
              }
              rejectBid={(auctionWinnerId) =>
                this.showRejectBidModal(auctionWinnerId)
              }
              allBids={auctionBids}
              auctionDetails={auctionDetails}
              auctionProforma={auctionProforma}
            />
          </Col>
          <Col span={24} xs={24} lg={24} xl={24}>
            <CardWithTitle title='Planned Order Details'>
              <Row>
                {isEmpty(auctionDetails) ? null : (
                  <Col span={24} xs={24} lg={24} xl={24}>
                    {auctionDetails.auctionPlannedOrders.length === 1 ? (
                      <PlannedOrder
                        data={{
                          ...auctionDetails.auctionPlannedOrders[0],
                          auction: {
                            targetPrice: auctionDetails.targetPrice,
                            endAt: auctionDetails.endAt,
                            currencyCode: auctionDetails.currencyCode,
                          },
                        }}
                        entity='Auction'
                        showImage={false}
                      ></PlannedOrder>
                    ) : (
                      <>
                        <ReviewMultiplePlannedOrders
                          data={auctionDetails}
                          readOnly={true}
                          entity='Auction'
                        />
                        <PlannedOrderDataTable
                          data={{
                            auctionPlannedOrders:
                              auctionDetails.auctionPlannedOrders,
                          }}
                          auctionId={auctionId}
                          readOnly={true}
                          entity='Auction'
                        />
                      </>
                    )}
                  </Col>
                )}
              </Row>
            </CardWithTitle>
          </Col>
          <Col span={24} xs={24} lg={24} xl={24}>
            <ParticipantsAuctionIncoterms
              participants={auctionParticipants}
              auctionDetails={auctionDetails}
              allBids={auctionBids}
              isAuctionSummary={true}
              showPlannedOrdersAssignmentModal={(bid, action) =>
                this.showPlannedOrdersAssignmentModal(bid, action)
              }
            />
            <AuctionStats
              allBids={auctionBids}
              auctionTargetPrice={auctionDetails.targetPrice}
              incoterms={auctionDetails.auctionIncoterms}
              lowestBid={auctionDetails.lowestBid}
              specification={auctionDetails.specification}
            />
          </Col>
        </Row>
      </>
    );
  }
}
export default withRouter(AuctionSummary);
