import './BuyerAuctionDetails.css';

import { Col, Row } from 'antd';

import AuctionIncoterm from 'src/components/incoterm/AuctionIncoterm';
import AuctionService from 'src/services/AuctionService';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import LoadingComponent from 'src/components/common/LoadingComponent';
import NoDataComponent from 'src/components/common/NoDataComponent';
import PlannedOrder from 'src/components/plannedOrder/PlannedOrder';
import PlannedOrderDataTable from 'src/components/plannedOrder/auction/PlannedOrderDataTable';
import React from 'react';
import ReviewMultiplePlannedOrders from './create/reviewAuctionPlannedOrders/ReviewMultiplePlannedOrders';
import SelectedPlantsTable from './create/chooseCompliantPlants/SelectedPlantsTable';
import StringConstants from 'src/constants/StringConstants';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';

class BuyerAuctionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auctionId: null,
      auctionDetails: {},
      loading: true,
    };
  }

  init() {
    const auctionId = this.props.match.params.id;
    this.setState({ loading: true, auctionId });

    AuctionService.getAuctionDetails(auctionId)
      .then((response) => {
        if (response.data.status !== StringConstants.AUCTION_STATUS.DRAFT) {
          const auctionDetails = response.data;
          auctionDetails.auctionPlannedOrders.forEach(
            (pl) => (pl.specification = auctionDetails.specification)
          );

          this.setState({ auctionDetails, loading: false });
        } else throw response;
      })
      .catch(() => {
        toast.error('No RFQ Found');
        this.setState({ loading: false, auctionDetails: {} });
      });
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined) {
      return false;
    }
    if (this.state.auctionId !== this.props.match.params.id) {
      this.init();
    }
  }

  render() {
    const { loading, auctionDetails, auctionId } = this.state;

    return loading ? (
      <LoadingComponent />
    ) : (
      <>
        <HeaderMetadata title={`${auctionId} (RFQ)`} description='' />
        <CardWithTitle title='RFQ Details'>
          {isEmpty(auctionDetails) ? (
            <NoDataComponent
              title='RFQ not found'
              tip={`RFQ (${auctionId}) does not exist`}
              fullScreen={true}
              headerMetadata={true}
            />
          ) : (
            <>
              <Row>
                <Col className='mb-15 mt-15' span={24} xs={24} lg={24} xl={24}>
                  {auctionDetails.auctionPlannedOrders.length === 1 ? (
                    <PlannedOrder
                      data={{
                        ...auctionDetails.auctionPlannedOrders[0],
                        auction: {
                          targetPrice: auctionDetails.targetPrice,
                          endAt: auctionDetails.endAt,
                          currencyCode: auctionDetails.currencyCode,
                        },
                      }}
                      showAuctionDetails={true}
                      entity='Auction'
                      showImage={false}
                    />
                  ) : (
                    <>
                      <ReviewMultiplePlannedOrders
                        data={auctionDetails}
                        readOnly={true}
                        showAuctionDetails={true}
                        entity='Auction'
                      />
                      <PlannedOrderDataTable
                        data={{
                          auctionPlannedOrders:
                            auctionDetails.auctionPlannedOrders,
                        }}
                        auctionId={auctionId}
                        readOnly={true}
                        entity='Auction'
                      />
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className='' span={24} xs={24} lg={24} xl={24}>
                  <div className='plain-divide'></div>

                  <div>
                    <p className='sub-heading'>Invited Processing Plants</p>
                  </div>
                </Col>
                <Col className='mb-15 mt-15' span={24} xs={24} lg={24} xl={24}>
                  <SelectedPlantsTable
                    data={auctionDetails.biddingCompanies}
                    readOnly={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col className='' span={24} xs={24} lg={24} xl={24}>
                  <div className='plain-divide'></div>

                  <div>
                    <p className='sub-heading'>Eligible Incoterms</p>
                  </div>
                </Col>
                <Col className='mb-15 mt-15' span={24} xs={24} lg={24} xl={24}>
                  <AuctionIncoterm
                    selectedData={auctionDetails.auctionIncoterms}
                  />
                </Col>
              </Row>
            </>
          )}
        </CardWithTitle>
      </>
    );
  }
}
export default withRouter(BuyerAuctionDetails);
