import { Col, Row, Table } from 'antd';
import React, { Component } from 'react';

import ApiUtils from 'src/utils/ApiUtils';
import AuctionTableManager from 'src/utils/tables/AuctionTableManager';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import EmptySection from 'src/components/common/emptySection/EmptySection';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import LoadingComponent from 'src/components/common/LoadingComponent';
import NoDataComponent from 'src/components/common/NoDataComponent';
import PlannedOrderTable from 'src/components/plannedOrder/PlannedOrderTable';
import ProformaTableManager from 'src/utils/tables/ProformaTableManager';
import ReportsTableManager from 'src/utils/tables/ReportsTableManager';
import SecureComponent from 'src/components/common/SecureComponent';
import Specification from 'src/components/specification/Specification';
import SpecificationService from 'src/services/SpecificationService';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';

class SpecificationOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      specificationId: undefined,
      finDetails: {},
      specifications: [],
    };
  }

  componentDidMount() {
    const specificationId = this.props.match.params.id;
    this.fetchFinDetails(specificationId);
  }

  fetchFinDetails = (specificationId) => {
    this.setState({ loading: true, specificationId });
    SpecificationService.getSpecificationOverviewDetails(specificationId)
      .then((response) => {
        const finDetails = response.data;
        finDetails.plannedOrders.forEach(
          (po) => (po.specification = finDetails.specification)
        );
        this.setState({
          loading: false,
          finDetails,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        const errorMessage = ApiUtils.getErrorMessage(
          err,
          'Unable to get Sppecification Details at the moment'
        );
        toast.error(errorMessage);
      });
  };

  getColumnsForProformaTable = () => {
    const { finDetails } = this.state;
    return ProformaTableManager.getColumnsForSpecificationInventory.call(
      this,
      finDetails.proformas
    );
  };

  getColumnsForAuctionTable = () => {
    const { finDetails } = this.state;
    return AuctionTableManager.getColumnsForSpecificationInventory.call(
      this,
      finDetails.auctions
    );
  };

  render() {
    const { loading, finDetails, specificationId } = this.state;
    return (
      <>
        <HeaderMetadata
          title={`${specificationId} Overview | Specification`}
          description='Detailed Analysis of Specifications'
        />
        {loading ? (
          <LoadingComponent />
        ) : isEmpty(finDetails) ? (
          <NoDataComponent
            fullScreen={true}
            title={`Specification (${specificationId}) Not Found`}
            tip={'Please check the specification selected'}
          />
        ) : (
          <>
            <Row type='flex' gutter={15}>
              <Col span={24} xs={24} lg={12} xl={12}>
                <p className='pagetitle'>
                  {finDetails.specification.itemDescription}
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {/* Specification */}
                <Specification
                  data={finDetails.specification}
                  openBasicPanel={false}
                  showHeading={false}
                  showVersionHistory={false}
                />
              </Col>
              <Col span={24}>
                {/* Planned Orders */}
                <PlannedOrderTable data={finDetails.plannedOrders} />
              </Col>
              <Col span={24}>
                {/* Auctions */}
                <CardWithTitle
                  title='Auctions'
                  badge={finDetails.auctions.length}
                >
                  {finDetails.auctions.length === 0 ? (
                    <EmptySection
                      imageSrc={
                        require('../../assets/images/no-data-images/nd-all-auctions.png')
                          .default
                      }
                      title='No Auctions'
                      showButton={false}
                      size='small'
                    />
                  ) : (
                    <>
                      <Table
                        locale={{ filterConfirm: 'Apply' }}
                        columns={this.getColumnsForAuctionTable()}
                        dataSource={finDetails.auctions}
                        rowKey={(row) => row.id}
                        scroll={{ x: 900 }}
                      />
                    </>
                  )}
                </CardWithTitle>
              </Col>
              <Col span={24}>
                {/* Proformas */}
                <CardWithTitle
                  title='Proformas'
                  badge={finDetails.proformas.length}
                >
                  {finDetails.proformas.length === 0 ? (
                    <EmptySection
                      imageSrc={
                        require('../../assets/images/no-data-images/nd-proforma.png')
                          .default
                      }
                      title='No Proformas'
                      showButton={false}
                      size='small'
                    />
                  ) : (
                    <>
                      <Table
                        locale={{ filterConfirm: 'Apply' }}
                        columns={this.getColumnsForProformaTable()}
                        dataSource={finDetails.proformas}
                        rowKey={(row) => row.id}
                      />
                    </>
                  )}
                </CardWithTitle>
              </Col>
              <Col span={24}>{/* Purchase Orders */}</Col>
              <Col span={24}>
                {/* Inventory */}
                <CardWithTitle
                  title='Inventory'
                  badge={finDetails.inventoryLots.length}
                >
                  {finDetails.inventoryLots.length === 0 ? (
                    <EmptySection
                      imageSrc={
                        require('../../assets/images/no-data-images/nd-proforma.png')
                          .default
                      }
                      title='No Inventory'
                      showButton={false}
                      size='small'
                    />
                  ) : (
                    <Table
                      locale={{ filterConfirm: 'Apply' }}
                      columns={ReportsTableManager.getColumnsForSpecificationInventory(
                        finDetails.inventoryLots
                      )}
                      dataSource={finDetails.inventoryLots}
                      rowKey={(row) => row.id}
                    />
                  )}
                </CardWithTitle>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}

export default SecureComponent(SpecificationOverview);
