import '../Modals.css';

import { Modal, Table } from 'antd';
import { cloneDeep, findIndex, isEqual, sortBy } from 'lodash';

import ApiUtils from 'src/utils/ApiUtils';
import PlannedOrderTableManager from 'src/utils/tables/PlannedOrderTableManager';
import ProformaService from 'src/services/ProformaService';
import React from 'react';
import Title from 'src/components/common/typography/Title';
import { toast } from 'react-toastify';

class SplitProformaModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      originalProformaPlannedOrders: [
        {
          buyFromCompanyId: this.props.currentProforma.buyFrom.id,
          id: this.props.assignedPlannedOrders.map(
            (plannedOrder) => plannedOrder.id
          ),
        },
      ],
      updatedProformaPlannedOrders: [
        {
          buyFromCompanyId: this.props.currentProforma.buyFrom.id,
          id: this.props.assignedPlannedOrders.map(
            (plannedOrder) => plannedOrder.id
          ),
        },
      ],
      actionLoading: false,
    };
  }

  componentDidMount() {
    const { assignedPlannedOrders } = this.props;
    let buyFromForPLs = [];
    assignedPlannedOrders.forEach((pl) => {
      buyFromForPLs['buyFromForPL_' + pl.id] =
        this.props.currentProforma.buyFrom.id;
    });
    this.setState({ ...buyFromForPLs });
  }

  getColumnsForTable = () => {
    return PlannedOrderTableManager.getPlannedOrdersSplitColumns.call(this);
  };

  handleCancel = () => {
    const { assignedPlannedOrders, currentProforma } = this.props;
    const { originalProformaPlannedOrders } = this.state;
    let buyFromForPLs = {};
    assignedPlannedOrders.forEach((pl) => {
      buyFromForPLs['buyFromForPL_' + pl.id] = currentProforma.buyFrom.id;
    });
    this.setState(
      {
        ...buyFromForPLs,
        updatedProformaPlannedOrders: cloneDeep(originalProformaPlannedOrders),
      },
      () => this.props.onCancel()
    );
  };

  handleChange = (buyFrom, plannedOrder) => {
    const { assignedPlannedOrders } = this.props;

    let newUpdatedProformaPlannedOrders = [];

    assignedPlannedOrders.forEach((pl) => {
      let newBuyFrom =
        pl.id === plannedOrder.id
          ? buyFrom
          : this.state['buyFromForPL_' + pl.id];
      let buyFromIndex = findIndex(
        newUpdatedProformaPlannedOrders,
        function (o) {
          return o.buyFromCompanyId === newBuyFrom;
        }
      );
      if (buyFromIndex === -1)
        newUpdatedProformaPlannedOrders.push({
          buyFromCompanyId: newBuyFrom,
          id: [pl.id],
        });
      else newUpdatedProformaPlannedOrders[buyFromIndex].id.push(pl.id);
    });

    this.setState({
      updatedProformaPlannedOrders: newUpdatedProformaPlannedOrders,
      ['buyFromForPL_' + plannedOrder.id]: buyFrom,
    });
  };

  isAssignmentUpdated = () => {
    return isEqual(
      this.state.originalProformaPlannedOrders,
      this.state.updatedProformaPlannedOrders.map((updatedPL) => {
        return { ...updatedPL, id: sortBy(updatedPL.id) };
      })
    );
  };

  splitProforma = () => {
    this.setState({ actionLoading: true });
    const { currentProforma } = this.props;
    const { updatedProformaPlannedOrders } = this.state;
    let data = {
      proformaPlannedOrders: updatedProformaPlannedOrders,
    };
    ProformaService.splitProforma(currentProforma.id, data)
      .then(() => {
        toast.success('Split proforma successfully');
        this.setState({ actionLoading: false }, () => this.props.refreshPage());
      })
      .catch((error) => {
        let errorMessage = ApiUtils.getErrorMessage(error);
        toast.error('Failed to split proforma - ' + errorMessage);
        this.setState({ actionLoading: false }, () => this.props.refreshPage());
      });
  };

  render() {
    const { visible, assignedPlannedOrders, loading } = this.props;
    const { actionLoading } = this.state;

    return (
      <Modal
        className='split-proforma-modal'
        centered
        closable={false}
        maskClosable={false}
        visible={visible}
        onOk={this.splitProforma}
        onCancel={this.handleCancel}
        okButtonProps={{
          disabled: this.isAssignmentUpdated() || actionLoading,
        }}
        cancelButtonProps={{
          className: 'cancel-outlined-button',
          disabled: actionLoading,
        }}
        okText='Confirm split'
        width={1000}
        confirmLoading={loading}
      >
        <Title title='Split Proforma' />
        {assignedPlannedOrders && (
          <Table
            className='plan-order-over-tble'
            pagination={false}
            locale={{ filterConfirm: 'Apply' }}
            columns={this.getColumnsForTable()}
            dataSource={assignedPlannedOrders}
            rowKey={(row) => row.id}
            scroll={{ x: 800 }}
          />
        )}
      </Modal>
    );
  }
}

export default SplitProformaModal;
