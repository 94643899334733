import './index.css';

import * as Sentry from '@sentry/react';

import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import reportWebVitals from './reportWebVitals';

if (process.env.REACT_APP_ENV !== 'localhost') {
  console.log('Sentry Initialized');
  Sentry.init({
    dsn: 'https://b83032b53bf3440b89f242927e1a68b9@o564817.ingest.sentry.io/5737523',
    environment: process.env.REACT_APP_ENV,
    release: 'carava-frontend@' + process.env.REACT_APP_VERSION,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.2,
  });
}
ReactDOM.render(
  // <React.StrictMode>
  <Sentry.ErrorBoundary>
    <App />
  </Sentry.ErrorBoundary>,
  // </React.StrictMode >
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
