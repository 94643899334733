export const actionTypes = {
  // user
  FETCH_USER_REQUEST: 'Request to fetch user profile',
  FETCH_USER_SUCCESS: 'Fetched user profile and saved to state',
  FETCH_USER_FAILURE: 'Failure in fetching user profile',
  UPDATE_USER: 'Update user profile',

  // route
  SET_UNSAVED_CHANGES: 'Set flag if any unsaved changes',
  SET_INCOMPLETE_AUCTION_DRAFT: 'Set flag of incomplete auction draft',
  SET_REDIRECT_PATH: 'Set redirect path',

  // chat
  SET_UNREAD_COUNT: 'Set unread messages count',

  RESET_STATE: 'Reset state',
};
