import './ReviewAndCreateBid.css';

import { Col, Row, Select, Space, Table, Tag } from 'antd';
import React, { Component } from 'react';
import {
  getAmountDisplayText,
  getPoundConversion,
} from 'src/utils/DisplayUtils';
import { groupBy, isUndefined, map, sumBy, uniq } from 'lodash';

import { CaretDownOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import StringConstants from 'src/constants/StringConstants';
import Title from 'src/components/common/typography/Title';
import moment from 'moment';

const columns = [
  {
    dataIndex: 'first_col',
    render: (textField) => (
      <>
        <strong>{textField.maintext}</strong>
        <br />
        <span className='spantext'>{textField.subtext}</span>
      </>
    ),
  },
  ...StringConstants.MONTHS.map((month) => {
    return {
      dataIndex: month,
      render: (text) => {
        if (text === month) return <span> {text} </span>;
        if (text === 0) return '-';
        return (
          <strong>
            <NumberFormat
              value={text}
              thousandSeparator={true}
              displayType='text'
            />
          </strong>
        );
      },
    };
  }),
];

class ReviewMultiplePlannedOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plannedView: StringConstants.PLANNED_VIEW_TYPES.FCL,
      yearSelectorEnabled: false,
      etdYears: [],
      selectedYear: undefined,
    };
  }

  getPlannedOrders = () => {
    const { entity } = this.props;
    if (entity === 'Auction') {
      const { auctionPlannedOrders } = this.props.revisedData
        ? this.props.revisedData
        : this.props.data;

      return auctionPlannedOrders ? auctionPlannedOrders : [];
    } else {
      const { proformaPlannedOrders } = this.props.revisedData
        ? this.props.revisedData
        : this.props.data;

      return proformaPlannedOrders ? proformaPlannedOrders : [];
    }
  };

  componentDidMount() {
    this.checkYearSelectorEnabling(this.getPlannedOrders());
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.checkYearSelectorEnabling(this.getPlannedOrders());
    }
  }

  checkYearSelectorEnabling = (plannedOrders) => {
    let etdYears = uniq(
      plannedOrders.map((plannedOrder) =>
        moment(plannedOrder.requestedEtd).year()
      )
    );

    this.setState({
      etdYears: etdYears.sort((a, b) => a - b),
      selectedYear: etdYears[0],
      yearSelectorEnabled: etdYears.length !== 1,
    });
  };

  getPlannedViewData = () => {
    const { plannedView, selectedYear } = this.state;
    let plannedOrders = this.getPlannedOrders();

    const plannedOrdersInSelectedYear = plannedOrders.filter((plannedOrder) => {
      return moment(plannedOrder.requestedEtd).year() === selectedYear;
    });

    const plannedOrdersByLocationCode = groupBy(
      plannedOrdersInSelectedYear,
      function (o) {
        return o.warehouse.locationCode;
      }
    );

    let plannedViewDataObj = {};

    let monthSummaryObj = {};
    StringConstants.MONTHS.forEach((month) => {
      monthSummaryObj[month] = 0;
    });

    Object.keys(plannedOrdersByLocationCode).forEach((locationCode) => {
      plannedViewDataObj[locationCode] = {};

      plannedViewDataObj[locationCode].first_col = {
        maintext:
          plannedOrdersByLocationCode[locationCode][0].destinationPort.name,
        subtext: locationCode,
      };

      let monthWiseData = groupBy(
        plannedOrdersByLocationCode[locationCode],
        function (o) {
          return StringConstants.MONTHS[moment(o.requestedEtd).month()];
        }
      );

      StringConstants.MONTHS.forEach((month) => {
        if (!(month in monthWiseData)) {
          plannedViewDataObj[locationCode][month] = 0;
        } else {
          let monthTotal = 0;
          if (plannedView === StringConstants.PLANNED_VIEW_TYPES.FCL)
            monthTotal = monthWiseData[month].length;
          else if (plannedView === StringConstants.PLANNED_VIEW_TYPES.CASES)
            monthTotal = sumBy(monthWiseData[month], 'plannedCases');
          else if (plannedView === StringConstants.PLANNED_VIEW_TYPES.WEIGHT)
            monthTotal = sumBy(monthWiseData[month], function (o) {
              return getPoundConversion(o.totalWeight, o.weightUom);
            });

          plannedViewDataObj[locationCode][month] = monthTotal;

          monthSummaryObj[month] = monthSummaryObj[month] + monthTotal;
        }
      });
    });

    let monthHeaderObj = {};
    StringConstants.MONTHS.forEach((month) => {
      monthHeaderObj[[month]] = month;
    });

    let headerRow = {
      rowIndex: 0,
      first_col: {
        maintext: 'Month (' + selectedYear + ')',
        subtext: 'ETD',
      },
      ...monthHeaderObj,
    };

    let plannedViewDataList = [headerRow];

    Object.keys(plannedViewDataObj).forEach((locationCode, index) => {
      plannedViewDataList.push({
        rowIndex: index + 1,
        ...plannedViewDataObj[locationCode],
      });
    });

    let summaryRow = {
      rowIndex: plannedViewDataList.length,
      first_col: {
        maintext: 'Total',
      },
      ...monthSummaryObj,
    };

    plannedViewDataList.push(summaryRow);

    return plannedViewDataList;
  };

  handlePlannedViewChange = (value) => {
    this.setState({ plannedView: value });
  };

  handleYearChange = (value) => {
    this.setState({ selectedYear: value });
  };

  render() {
    const { yearSelectorEnabled, etdYears, selectedYear } = this.state;
    const { data, readOnly, showAuctionDetails, revisedData } = this.props;

    const { totalFCL, totalCases, totalWeight, weightUom } = revisedData
      ? revisedData
      : data;

    let specification = {};

    if (this.getPlannedOrders().length > 0)
      specification = this.getPlannedOrders()[0].specification;

    return (
      <div className={readOnly ? '' : 'ca-card-light mb-15 rscb'}>
        {!readOnly && <Title title='Review Planned Order ' />}
        <Row>
          <Col className='mb-15 mt-15' span={24} xs={24} lg={24} xl={24}>
            <div className='rscb-info'>
              {/* <div className='rscb-img'>
                <img alt='Shrimp' src={ImageConstants.SHRIMP_ICON} />
              </div> */}
              <div
                className='rscb-desc'
                style={{
                  padding: 0,
                }}
              >
                <p className='rscb-desc-cin'>
                  Customer Item: <span>{specification.companyItemNumber}</span>
                </p>
                <p className='rscb-desc-company'>
                  <span>{specification.customer}</span> |{' '}
                  <span>{specification.itemDescription}</span>
                </p>
              </div>
            </div>
          </Col>
          <Col
            className='rscb-details mb-15 mt-15'
            span={24}
            xs={24}
            lg={24}
            xl={24}
          >
            <ul className='rscb-items'>
              {showAuctionDetails && (
                <>
                  <li>
                    <span className='left-item'>Target Price (per/LB):</span>
                    <span className='right-item'>
                      {getAmountDisplayText(data.targetPrice)}
                    </span>
                  </li>
                  <li>
                    <span className='left-item'>
                      Last Date & Time For Quote:
                    </span>
                    <span className='right-item'>
                      {moment.utc(data.endAt).format('DD MMM YYYY / hh:mm A')}
                    </span>
                  </li>
                </>
              )}
              <li>
                <span className='left-item'>Addtional Item Number</span>
                <span className='right-item'>
                  {specification.additionalItemNumber}
                </span>
              </li>
              <li>
                <span className='left-item'>Product Form</span>
                <span className='right-item'>{specification.productForm}</span>
              </li>
              <li>
                <span className='left-item'>Customer</span>
                <span className='right-item'>{specification.customer}</span>
              </li>
              <li>
                <span className='left-item'>Brand</span>
                <span className='right-item'>{specification.brand}</span>
              </li>
              <li>
                <span className='left-item'>Species</span>
                <span className='right-item species-details-item'>
                  <Space>
                    {uniq(map(specification.seaFoodList, 'commonName')).map(
                      (seafoodItem, index) => {
                        return <Tag key={index}>{seafoodItem}</Tag>;
                      }
                    )}
                  </Space>
                </span>
              </li>
            </ul>
          </Col>
          <Col
            className='rscb-bid-summary mb-15'
            span={24}
            xs={24}
            lg={24}
            xl={24}
          >
            <p className='bid-sum-title'>Quote Summary</p>

            <ul className='rscb-bid-summary-items'>
              <li>
                <span className='bid-summary-left-item'>Total FCL :</span>
                <span className='bid-summary-right-item'>{totalFCL}</span>
              </li>
              <li>
                <span className='bid-summary-left-item'>
                  Total Est. Cases :{' '}
                </span>
                <span className='bid-summary-right-item'>
                  <NumberFormat
                    value={totalCases}
                    thousandSeparator={true}
                    displayType='text'
                  />
                </span>
              </li>
              <li>
                <span className='bid-summary-left-item'>
                  Total Est. Weight :{' '}
                </span>
                <span className='bid-summary-right-item'>
                  <NumberFormat
                    value={totalWeight}
                    suffix={` ${weightUom}`}
                    thousandSeparator={true}
                    displayType='text'
                    decimalScale={2}
                  />
                </span>
              </li>
            </ul>
          </Col>
          <Col className='planned-view-sel' span={12} xs={24} lg={12} xl={6}>
            <span className='planned-sel-title'>Planned View In: </span>
            <Select
              suffixIcon={<CaretDownOutlined />}
              className='planned-vi-sel-btn'
              defaultValue={StringConstants.PLANNED_VIEW_TYPES.FCL}
              style={{ width: 200 }}
              onChange={this.handlePlannedViewChange}
              value={this.state.plannedView}
            >
              {Object.keys(StringConstants.PLANNED_VIEW_TYPES).map(
                (viewType) => {
                  return (
                    <Select.Option value={viewType} key={viewType}>
                      {viewType}
                    </Select.Option>
                  );
                }
              )}
            </Select>
          </Col>

          {yearSelectorEnabled && (
            <Col className='planned-view-sel' span={12} xs={24} lg={12} xl={6}>
              <span className='planned-sel-title'>Year: </span>
              <Select
                suffixIcon={<CaretDownOutlined />}
                className='planned-vi-sel-btn'
                defaultValue={
                  isUndefined(selectedYear) ? 'No year found' : selectedYear
                }
                style={{ width: 200 }}
                onChange={this.handleYearChange}
                value={selectedYear}
              >
                {etdYears.map((year) => {
                  return (
                    <Select.Option value={year} key={year}>
                      {year}
                    </Select.Option>
                  );
                })}
              </Select>
            </Col>
          )}

          <Col className='mt-15 mb-15' span={24} xs={24} lg={24} xl={24}>
            <Table
              className='rscb-table'
              columns={columns}
              dataSource={this.getPlannedViewData()}
              pagination={false}
              rowKey='rowIndex'
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default ReviewMultiplePlannedOrders;
