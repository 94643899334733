import InternalRouter from './mainContent/InternalRouter';
import { Layout } from 'antd';
import NavHeader from 'src/components/common/navbar/NavHeader';
import React from 'react';
import Sidebar from '../components/common/sidebar/Sidebar';

function MainContent() {
  const { Content } = Layout;
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar />
      <Layout className='site-layout'>
        <NavHeader />
        <Content
          className='site-layout-background'
          style={{
            padding: 24,
            minHeight: 280,
          }}
        >
          <InternalRouter />
        </Content>
      </Layout>
    </Layout>
  );
}
export default MainContent;
