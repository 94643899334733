import { Button, Rate, Space, Tag } from 'antd';
import { capitalize, map, uniq } from 'lodash';

import NumberFormat from 'react-number-format';
import TableFilterUtils from '../TableFilterUtils';
import { getFormattedDate } from '../DisplayUtils';
import moment from 'moment';

function getColumnsForAllPlants(data) {
  const columns = [
    {
      title: 'Processing Plant',
      dataIndex: 'company',
      filters: TableFilterUtils.getFilterDropdownValues(data, 'company.name'),
      filterMultiple: true,
      onFilter: (value, record) => record.company.name.indexOf(value) >= 0,
      render: (company) => company.name,
    },
    {
      title: 'Country',
      dataIndex: 'company',
      filters: TableFilterUtils.getFilterDropdownValues(
        data,
        'company.country'
      ),
      filterMultiple: true,
      onFilter: (value, record) => record.company.country.indexOf(value) >= 0,
      render: (company) => capitalize(company.country),
    },
    {
      title: 'Species Capabilities',
      dataIndex: 'company',
      ellipsis: {
        showTitle: false,
      },
      width: 200,
      render: ({ seaFoodList }) =>
        uniq(map(seaFoodList, 'commonName')).map((seafoodItem, index) => {
          return <Tag key={index}>{seafoodItem}</Tag>;
        }),
    },
    {
      title: 'User Notes',
      dataIndex: 'companyNote',
      render: (companyNote) => (companyNote ? companyNote : '-'),
    },
    {
      title: 'Total LBS Sold',
      dataIndex: 'company',
      render: (company) => {
        const size = Math.floor(Math.random() * (95000 - 35000 + 1)) + 35000;
        return (
          <NumberFormat
            value={size}
            suffix='lbs'
            thousandSeparator={true}
            displayType='text'
          />
        );
      },
    },
    {
      title: 'Total $ Sold',
      dataIndex: 'company',
      render: (company) => {
        const size = Math.floor(Math.random() * (95000 - 35000 + 1)) + 35000;
        return (
          <NumberFormat
            value={size * 4}
            prefix='$'
            thousandSeparator={true}
            displayType='text'
          />
        );
      },
    },
    {
      title: 'Delivery Score',
      dataIndex: 'deliveryscore',
      sorter: (a, b) => a.caravaid - b.caravaid,
      render: () => (
        <Space
          className='deliver-star'
          direction='vertical'
          align='start'
          size='small'
        >
          <Rate
            disabled
            allowHalf
            defaultValue={Math.floor(Math.random() * 5) + 1}
          />
        </Space>
      ),
    },
    {
      title: '',
      render: (plant) => {
        return (
          <div className='text-center'>
            <Space>
              <Button
                className='primary-button'
                type='primary'
                onClick={() => this.navigateToPlantHomepage(plant.company.id)}
              >
                View
              </Button>
            </Space>
          </div>
        );
      },
    },
  ];
  return columns;
}

function getColumnsForInvitedPlants(data) {
  const columns = [
    {
      title: 'Invited E-mail',
      dataIndex: 'email',
      filters: TableFilterUtils.getFilterDropdownValues(data, 'email'),
      filterMultiple: true,
      onFilter: (value, record) => record.email.indexOf(value) >= 0,
    },
    {
      title: 'Invite Sent On',
      dataIndex: 'createdAt',
      sorter: (a, b) => moment(a.createdAt).diff(b.createdAt),
      render: (createdAt) => getFormattedDate(createdAt),
    },
    {
      title: 'Expires On',
      dataIndex: 'expiresAt',
      sorter: (a, b) => moment(a.expiresAt).diff(b.expiresAt),
      render: (expiresAt) => getFormattedDate(expiresAt),
    },
    {
      title: 'Invited by',
      dataIndex: 'createdBy',
      filters: TableFilterUtils.getFilterDropdownValues(data, 'createdBy'),
      filterMultiple: true,
      onFilter: (value, record) => record.createdBy.indexOf(value) >= 0,
    },
    {
      title: '',
      render: (plant) => {
        return (
          <div className='text-center'>
            <Space>
              <Button
                className='cancel-button'
                type='primary'
                onClick={() => {
                  this.setState({
                    selectedPlant: plant,
                    revokeInviteModalVisible: true,
                  });
                }}
              >
                Revoke Invite
              </Button>
              <Button
                className='primary-button'
                type='primary'
                onClick={() => {
                  this.setState({
                    selectedPlant: plant,
                    resendInviteModalVisible: true,
                  });
                }}
              >
                Resend Invite
              </Button>
            </Space>
          </div>
        );
      },
    },
  ];
  return columns;
}

const ProcessingPlantTableManager = {
  getColumnsForAllPlants,
  getColumnsForInvitedPlants,
};

export default ProcessingPlantTableManager;
