import 'antd/dist/antd.css';
import 'react-toastify/dist/ReactToastify.css';
import 'axios-progress-bar/dist/nprogress.css';
import './App.css';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import AcceptInvite from './pages/auth/invite/AcceptInvite';
import Auth0Login from './pages/auth/ssoLogin/Auth0Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import Login from './pages/auth/Login';
import MainContent from './pages/MainContent';
import { Provider } from 'react-redux';
import React from 'react';
import RedirectComponent from './pages/auth/RedirectComponent';
import ResetPassword from './pages/auth/ResetPassword';
import RouteConstants from './constants/RouteConstants';
import SignUp from './pages/auth/SignUp';
import { ToastContainer } from 'react-toastify';
import { loadProgressBar } from 'axios-progress-bar';
import store from './createStore';

function App() {
  loadProgressBar();
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path={RouteConstants.LOGIN} exact component={Login} />
          <Route
            path={RouteConstants.AUTH0_LOGIN}
            exact
            component={Auth0Login}
          />
          <Route
            exact
            path={RouteConstants.FORGOT_PASSWORD}
            component={ForgotPassword}
          />
          <Route exact path={RouteConstants.SIGNUP} component={SignUp} />
          <Route
            exact
            path={RouteConstants.RESET_PASSWORD}
            component={ResetPassword}
          />

          <Route
            exact
            path={RouteConstants.REDIRECT}
            component={RedirectComponent}
          />
          <Route
            path={RouteConstants.USER_ACCEPT_INVITE}
            component={AcceptInvite}
          />
          <Route exact path='*' component={MainContent} />
        </Switch>
      </BrowserRouter>
      <ToastContainer
        position='bottom-left'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </Provider>
  );
}

export default App;
