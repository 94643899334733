import { Link, withRouter } from 'react-router-dom';
import { find, isEmpty, isUndefined } from 'lodash';
import {
  getUserDataFromCookie,
  isAuthenticatedUser,
} from '../../utils/auth/AuthUtils';

import Breadcrumbs from './breadcrumbs/Breadcrumbs';
import ChatDrawerTrigger from './chatDrawer/ChatDrawerTrigger';
import LoadingComponent from './LoadingComponent';
import NoAccessComponent from './NoAccessComponent';
import React from 'react';
import RouteConstants from '../../constants/RouteConstants';
import StringConstants from 'src/constants/StringConstants';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { deleteToken } from 'src/utils/auth/auth';
import { isUserDetailAvailable } from 'src/helpers/user';
import moment from 'moment';

const SecureComponent = (
  Page,
  accessToCompany = StringConstants.DEFAULT_COMPANY_PAGE_ACCESS,
  accessToTeamAndRole = StringConstants.DEFAULT_TEAM_PAGE_ACCESS,
  showNoAuth = true
) => {
  class SecureComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoggedIn: null,
      };
    }

    componentDidMount() {
      const isUserLoggedIn = isAuthenticatedUser();

      this.setState({
        isLoggedIn: isUserLoggedIn,
      });

      const loggedInUserData = getUserDataFromCookie();
      let route = RouteConstants.LOGIN;
      if (!isUserLoggedIn) {
        if (!isEmpty(loggedInUserData)) {
          // verify expiration of login token
          if (moment(new Date(loggedInUserData.exp)).isBefore(moment())) {
            deleteToken();
            sessionStorage.removeItem('state');
          }
        } else {
          if (this.props.location && this.props.location.pathname)
            route += 'redirect?redirect=' + this.props.location.pathname;
        }

        this.props.history.replace(route);
      } else {
        if (
          !isUserDetailAvailable() &&
          this.props.location &&
          this.props.location.pathname
        ) {
          route += 'redirect?redirect=' + this.props.location.pathname;
          this.props.history.replace(route);
        }
      }
    }

    checkCompanyAccess = () => {
      if (this.props.user && this.props.user.company)
        return accessToCompany.includes(this.props.user.company.type);
    };

    checkTeamAndRoleAccess = () => {
      if (this.props.user && this.props.user.teams) {
        let accessMatch = this.props.user.teams.filter((team) => {
          let userPartOfTeam = find(accessToTeamAndRole, function (o) {
            return o.name.toLowerCase() === team.name.toLowerCase();
          });

          if (userPartOfTeam && userPartOfTeam.role)
            return (
              team.role.toLowerCase() === userPartOfTeam.role.toLowerCase()
            );

          return !isUndefined(userPartOfTeam);
        });

        return accessMatch.length > 0;
      }
    };

    render() {
      const { hideBreadCrumbs } = this.props;

      const { isLoggedIn } = this.state;
      if (isLoggedIn === null)
        return (
          <div style={{ height: '95vh' }}>
            <LoadingComponent />
          </div>
        );
      if (!isLoggedIn) {
        return (
          <div>
            <p>
              You're not logged in to the system. Try to{' '}
              <Link to={RouteConstants.LOGIN}> Login</Link>
            </p>
          </div>
        );
      }

      if (
        (!this.checkCompanyAccess() || !this.checkTeamAndRoleAccess()) &&
        showNoAuth
      )
        return <NoAccessComponent fullScreen={false} showButton={false} />;

      return (
        <>
          {!hideBreadCrumbs && <Breadcrumbs />}
          <Page {...this.state} {...this.props} />
          <ChatDrawerTrigger {...this.props} />
        </>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      user: state.user,
    };
  };

  return compose(withRouter, connect(mapStateToProps, null))(SecureComponent);
};

export default SecureComponent;
