import './AuctionSteps.css';

import { Col, Steps } from 'antd';
import { EyeFilled, PlusCircleFilled, ProfileFilled } from '@ant-design/icons';

import React from 'react';

const { Step } = Steps;

const steps = [
  {
    step: 1,
    title: 'Review Selection',
    icon: <PlusCircleFilled />,
  },
  {
    step: 2,
    title: 'Choose Compliant Plants',
    icon: <ProfileFilled />,
  },
  {
    step: 3,
    title: 'Launch RFQ',
    icon: <EyeFilled />,
  },
];

const AuctionSteps = (props) => {
  const currentStep = props.step - 1;
  return (
    <Col className='mb-15 mt-15 carva-step-form' xs={0} md={24}>
      <Steps current={currentStep}>
        {steps.map((item) => (
          <Step
            key={item.step}
            title={item.title}
            icon={item.icon}
            status={
              props.step === item.step
                ? 'process'
                : props.step > item.step
                ? 'finish'
                : ''
            }
          />
        ))}
      </Steps>
    </Col>
  );
};

export default AuctionSteps;
