import '../Auction.css';

import { Link, withRouter } from 'react-router-dom';

import AuctionService from 'src/services/AuctionService';
import AuctionTableManager from 'src/utils/tables/AuctionTableManager';
import EmptySection from 'src/components/common/emptySection/EmptySection';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import StringConstants from 'src/constants/StringConstants';
import { Table } from 'antd';
import Title from '../../common/typography/Title';
import UrlGenerator from 'src/api/UrlGenerator';

class ActiveAuctionOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      auctions: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    AuctionService.getAllAuctionsForPlants({
      ':status': StringConstants.PLANT_AUCTION_API_STATUS.ACTIVE,
      limit: 5,
    })
      .then((response) => {
        this.setState({ loading: false, auctions: response.data });
      })
      .catch((err) => {
        this.setState({ loading: false, auctions: [] });
      });
  }

  getColumnsForTable = () => {
    const { auctions } = this.state;
    const columns =
      AuctionTableManager.getActiveAuctionColumnsForPlants(auctions);
    return columns;
  };

  render() {
    const { loading, auctions } = this.state;
    return (
      <div className='auctionoverview ca-card-light h-100'>
        <Title
          title='Ongoing RFQs'
          action={
            auctions.length > 0 ? (
              <Link
                className='head-box-va'
                to={UrlGenerator.getUiUrlWithQueryParams(
                  RouteConstants.AUCTIONS,
                  {
                    status: StringConstants.AUCTION_STATUS.ACTIVE,
                  }
                )}
              >
                View All
              </Link>
            ) : null
          }
        />
        {auctions.length > 0 ? (
          <Table
            className='auctionoverview'
            columns={this.getColumnsForTable()}
            loading={loading}
            dataSource={auctions}
            pagination={false}
            scroll={{ y: 200 }}
            rowKey={(row) => row.id}
          />
        ) : (
          <EmptySection
            imageSrc={
              require('../../../assets/images/no-data-images/nd-bid.png')
                .default
            }
            title='There are no active RFQs at the moment'
            showButton={false}
          />
        )}
      </div>
    );
  }
}
export default withRouter(ActiveAuctionOverview);
