import { Card, Col, Collapse, Row } from 'antd';

import ChatInfoConstants from 'src/constants/ChatInfoConstants';
import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import TableFilterUtils from 'src/utils/TableFilterUtils';
import { getChatItemDisplayText } from 'src/utils/DisplayUtils';
import { withChannelStateContext } from 'stream-chat-react';

const { Panel } = Collapse;

const ChatInfoComponent = withChannelStateContext(
  class ChatInfoComponent extends React.PureComponent {
    render() {
      return (
        <Card className='h-100 info-card'>
          <div className='info-header'>
            <span className='info-header-title'>Details </span>

            <span
              className='info-header-action'
              onClick={() => this.props.closeInfoDrawer()}
            >
              <CloseOutlined />
            </span>
          </div>
          <Collapse defaultActiveKey={['0', '1']}>
            {Object.keys(ChatInfoConstants).map((chatInfo, index) => {
              return (
                <Panel header={chatInfo} key={index}>
                  <ul className='info-items'>
                    {ChatInfoConstants[chatInfo].map((chatInfoItem) => {
                      return (
                        <li className='info-item' key={chatInfoItem.key}>
                          <Row align='center' justify='space-between'>
                            <Col xs={24} xl={8} className='info-left-item'>
                              <span>{chatInfoItem.title}</span>
                            </Col>
                            <Col xs={24} xl={16} className='info-right-item'>
                              <span>
                                {getChatItemDisplayText(
                                  chatInfoItem.type,
                                  TableFilterUtils.getProperty(
                                    this.props.channel.data,
                                    chatInfoItem.field
                                  ),
                                  chatInfoItem.linkTo
                                )}
                              </span>
                            </Col>
                          </Row>
                        </li>
                      );
                    })}
                  </ul>
                </Panel>
              );
            })}
          </Collapse>
        </Card>
      );
    }
  }
);

export default ChatInfoComponent;
