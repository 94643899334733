import ApiManager from 'src/api/ApiManager';
import HttpConstants from 'src/constants/HttpConstants';
import UrlConstants from 'src/constants/UrlConstants';

const BidService = {
  getBidDetails: (auctionId, plantId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_BID_DETAIL.USECASE,
      HttpConstants.GET_METHOD,
      {
        ':auctionId': auctionId,
        ':plantId': plantId,
      }
    );
  },
  acceptBid: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.ACCEPT_BID.USECASE,
      HttpConstants.POST_METHOD,
      data
    );
  },
  editAuctionWinner: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.EDIT_AUCTION_WINNER.USECASE,
      HttpConstants.PATCH_METHOD,
      data
    );
  },
  rejectBid: (auctionWinnerId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.REJECT_BID.USECASE,
      HttpConstants.DELETE_METHOD,
      {
        ':auctionWinnerId': auctionWinnerId,
      }
    );
  },
  updateBid: (bidId, data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_BID.USECASE,
      HttpConstants.PATCH_METHOD,
      { ...data, ':bidId': bidId }
    );
  },
};

export default BidService;
