import './SellerBidHistoryDrawer.css';

import { Button, Drawer } from 'antd';

import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import SellerBidHistoryData from './SellerBidHistoryData';
import UrlGenerator from 'src/api/UrlGenerator';
import { isUndefined } from 'lodash';
import { withRouter } from 'react-router-dom';

class SellerBidHistoryDrawer extends React.Component {
  navigateToBidDetails = () => {
    const { selectedSeller } = this.props;
    const auctionId = this.props.match.params.id;
    this.props.history.push(
      UrlGenerator.getUiUrlWithPathParams(RouteConstants.BID_DETAILS, {
        id: auctionId,
        plantId: selectedSeller && selectedSeller.id,
      })
    );
  };

  render() {
    const { visible, selectedSeller, data } = this.props;
    return (
      <Drawer
        width={620}
        onClose={this.props.onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <div className='right-side-drawer mb-15'>
          <p className='pagetitle'>Seller Quote History</p>
          <p className='sec-heading-subtitle'>Seller Name</p>
          <div className='heading-with-action'>
            <p className='sec-heading'>
              {isUndefined(selectedSeller) ? '-' : selectedSeller.name}
            </p>
            <Button
              className='view-details-btn mb-15'
              onClick={() => this.navigateToBidDetails()}
            >
              View Details
            </Button>
          </div>
        </div>
        <SellerBidHistoryData
          bidDetails={data}
          incoterms={data.auctionIncoterms}
        />
      </Drawer>
    );
  }
}
export default withRouter(SellerBidHistoryDrawer);
