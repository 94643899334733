import { Button, Drawer, Tooltip } from 'antd';
import React, { useState } from 'react';

import ChatDrawer from './ChatDrawer';

const ChatDrawerTrigger = (props) => {
  const [chatDrawerVisible, setChatDrawerVisible] = useState(false);

  return (
    <>
      <Tooltip title='View chats'>
        <Button
          style={{ position: 'fixed', top: '85%', right: 0, zIndex: 100 }}
          onClick={() => setChatDrawerVisible(true)}
        >
          <img
            src={require('../../../assets/images/chat-icon.png').default}
            alt='Chat'
            className='chat-icon'
          />
        </Button>
      </Tooltip>
      {chatDrawerVisible && (
        <Drawer
          visible={chatDrawerVisible}
          placement='right'
          onClose={() => setChatDrawerVisible(false)}
          width={400}
          className='channel-list-drawer'
        >
          <ChatDrawer {...props} />
        </Drawer>
      )}
    </>
  );
};

export default ChatDrawerTrigger;
