import { Col, Row, Typography } from 'antd';
import React, { Component } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

class HelpText extends Component {
  static propTypes = {
    showIcon: PropTypes.bool,
    message: PropTypes.string.isRequired,
  };

  static defaultProps = {
    showIcon: false,
  };

  render() {
    const { message, showIcon } = this.props;
    return (
      <Row className='mb-10'>
        <Col xs={24} md={16}>
          {showIcon && <InfoCircleOutlined style={{ marginRight: 5 }} />}
          <Typography.Text type='secondary'>{message}</Typography.Text>
        </Col>
      </Row>
    );
  }
}

export default HelpText;
