import { Button, Col, Row, Space, Table, Tabs } from 'antd';
import React, { Component } from 'react';
import { capitalize, isEmpty } from 'lodash';
import { isUserImporter, isUserManagerOfTeam } from 'src/helpers/user';

import AuctionService from 'src/services/AuctionService';
import AuctionTableManager from 'src/utils/tables/AuctionTableManager';
import AuctionUtils from 'src/utils/AuctionUtils';
import ExcelUtils from 'src/utils/exports/ExcelUtils';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import HelpText from 'src/components/common/typography/HelpText';
import RouteConstants from 'src/constants/RouteConstants';
import StatusValidator from 'src/validators/StatusValidator';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

const helpTextByStatus = {
  [[StringConstants.AUCTION_STATUS.ACTIVE]]:
    'RFQs open for processing plants to submit quotes',
  [[StringConstants.AUCTION_STATUS.PENDING]]:
    'RFQs closed for quote submission. Pending actions - Review and Awards Quotes, Proformas Review.',
  [[StringConstants.AUCTION_STATUS.DRAFT]]:
    'RFQ not open yet for processing plants to submit quotes.',
  [[StringConstants.AUCTION_STATUS.HISTORY]]:
    'RFQs that have been completed or cancelled',
};

class BuyerAuctions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auctions: {
        DRAFT: [],
        ACTIVE: [],
        PENDING: [],
        HISTORY: [],
      },
      loading: true,
      activeKey: undefined,
      analysisLoading: false,
    };
  }

  componentDidMount() {
    let status = StatusValidator.getValidStatusForParam(
      StringConstants.AUCTION_STATUS,
      this.props.location.search
    );

    if (!status) {
      status = StringConstants.AUCTION_STATUS.ACTIVE;
    }
    this.setState({ activeKey: status.toUpperCase() }, () =>
      this.fetchAuctions()
    );
  }

  fetchAuctions = () => {
    const { auctions, activeKey } = this.state;
    if (!isEmpty(auctions[activeKey])) return;

    this.setState({ loading: true });
    let status = activeKey;
    if (status === StringConstants.AUCTION_STATUS.PENDING) {
      status = [
        StringConstants.AUCTION_STATUS.HOLD,
        StringConstants.AUCTION_STATUS.PROFORMA_REVIEW,
      ];
    }
    if (status === StringConstants.AUCTION_STATUS.HISTORY) {
      status = [
        StringConstants.AUCTION_STATUS.COMPLETED,
        StringConstants.AUCTION_STATUS.CANCELLED,
      ];
    }
    AuctionService.getAllAuctions({ status })
      .then((response) => {
        this.setState((prev) => ({
          auctions: {
            ...prev.auctions,
            [[activeKey]]: response.data,
          },
          loading: false,
        }));
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  onTabChange = (value) => {
    const url = UrlGenerator.getUiUrlWithQueryParams(RouteConstants.AUCTIONS, {
      status: value,
    });
    window.history.replaceState(null, null, url);
    this.setState({ activeKey: value }, () => this.fetchAuctions());
  };

  getColumnsForTable = () => {
    const { auctions, activeKey } = this.state;
    const data = auctions[activeKey];
    if (activeKey === StringConstants.AUCTION_STATUS.ACTIVE) {
      return AuctionTableManager.getActiveAuctionColumnsForImporter(data);
    } else if (activeKey === StringConstants.AUCTION_STATUS.PENDING) {
      return AuctionTableManager.getPendingAuctionColumnsForImporters(data);
    } else if (activeKey === StringConstants.AUCTION_STATUS.DRAFT) {
      return AuctionTableManager.getDraftAuctionColumnsForImporters(data);
    } else if (activeKey === StringConstants.AUCTION_STATUS.HISTORY) {
      return AuctionTableManager.getHistoryAuctionColumnsForImporters(data);
    }
  };

  downloadAnalysis = () => {
    this.setState({ analysisLoading: true });
    AuctionService.getAuctionAnalysisOverview()
      .then((response) => {
        const downloadata = AuctionUtils.getAnalysisOverviewJSON(response.data);
        ExcelUtils.downloadAsExcel(downloadata, 'Auction Analysis');
        this.setState({ analysisLoading: false });
      })
      .catch(() => {
        toast.error('Unable to download analysis at the moment');
        this.setState({ analysisLoading: false });
      });
  };

  render() {
    const { loading, activeKey, auctions, analysisLoading } = this.state;
    return (
      <>
        <HeaderMetadata
          title={`${capitalize(activeKey)} RFQ`}
          description={helpTextByStatus[activeKey]}
        />
        <div className='ca-card-light h-95'>
          <Row>
            <Col span={24}>
              <Tabs
                activeKey={activeKey}
                onChange={this.onTabChange}
                tabBarExtraContent={
                  isUserImporter() &&
                  isUserManagerOfTeam(StringConstants.TEAMS.SOURCING)
                    ? {
                        right: (
                          <Space>
                            {activeKey ===
                              StringConstants.AUCTION_STATUS.PENDING && (
                              <Button
                                type='ghost'
                                className='btn-sm btn-secondary mb-10'
                                onClick={this.downloadAnalysis}
                                loading={analysisLoading}
                              >
                                Analyze
                              </Button>
                            )}
                            <Button
                              type='primary'
                              className='btn-sm btn-primary mb-10'
                              onClick={() =>
                                this.props.history.push(
                                  RouteConstants.PLANNED_ORDERS
                                )
                              }
                            >
                              Create RFQ
                            </Button>
                          </Space>
                        ),
                      }
                    : null
                }
              >
                {isUserImporter() &&
                  isUserManagerOfTeam(StringConstants.TEAMS.SOURCING) && (
                    <Tabs.TabPane
                      tab='Draft RFQs'
                      key={StringConstants.AUCTION_STATUS.DRAFT}
                    />
                  )}
                <Tabs.TabPane
                  tab='Active RFQs'
                  key={StringConstants.AUCTION_STATUS.ACTIVE}
                />
                <Tabs.TabPane
                  tab='Pending RFQs'
                  key={StringConstants.AUCTION_STATUS.PENDING}
                />
                <Tabs.TabPane
                  tab='RFQ History'
                  key={StringConstants.AUCTION_STATUS.HISTORY}
                />
              </Tabs>
              {activeKey && helpTextByStatus[activeKey] && (
                <HelpText
                  message={helpTextByStatus[activeKey]}
                  showIcon={true}
                />
              )}
              <Table
                columns={this.getColumnsForTable()}
                loading={loading}
                dataSource={auctions[activeKey]}
                rowKey={(row) => row.id}
                pagination={
                  auctions[activeKey] && auctions[activeKey].length >= 10
                }
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(BuyerAuctions);
