import 'stream-chat-react/dist/css/index.css';
import './ChatDrawer.css';

import {
  Channel,
  ChannelList,
  Chat,
  InfiniteScrollPaginator,
  MessageInput,
  MessageInputFlat,
  MessageList,
  Thread,
  Window,
  withChannelStateContext,
} from 'stream-chat-react';
import { Col, Drawer, Row } from 'antd';

import ApiUtils from 'src/utils/ApiUtils';
import { ChannelPreviewMessenger } from 'src/components/chat/ChannelPreviewMessenger';
import { ChannelSearch } from 'src/components/chat/ChannelSearch';
import ChatService from 'src/services/ChatService';
import HeaderComponent from 'src/components/chat/HeaderComponent';
import React from 'react';
import { StreamChat } from 'stream-chat';
import { connect } from 'react-redux';
import { setUnreadCount } from 'src/actions/chat';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

const ChannelWatcher = withChannelStateContext(
  class ChannelWatcher extends React.PureComponent {
    componentDidMount() {
      this.props.updateActiveChannel(this.props.channel);
    }

    render() {
      return null;
    }
  }
);

class ChatDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeChannel: undefined,
      chatPopupVisible: false,
    };
    this.filters = {
      type: 'messaging',
      members: { $in: [props.user.id] },
    };

    this.apiKey = process.env.REACT_APP_STREAM_KEY;
    this.sort = { last_message_at: -1 };
    this.theme = 'messaging light';
    this.Paginator = (props) => (
      <InfiniteScrollPaginator threshold={300} {...props} />
    );

    this.chatClient = StreamChat.getInstance(this.apiKey);
  }

  componentDidMount() {
    this.chatClient
      .connectUser(
        { id: this.props.user.id, name: this.props.user.name },
        async () => {
          try {
            const response = await ChatService.getChatToken();
            return response.data.token;
          } catch (error) {
            let errorMessage = ApiUtils.getErrorMessage(error);
            toast.error(errorMessage);
          }
        }
      )
      .then((response) => {
        this.props.setUnreadCount(response.me.unread_count);
      })
      .catch((error) => {
        this.props.setUnreadCount(0);
      });

    this.chatClient.on((event) => {
      if (event.total_unread_count !== undefined) {
        this.props.setUnreadCount(event.total_unread_count);
      }
    });
  }

  updateActiveChannel = (channel) => {
    this.setState({ activeChannel: channel, chatPopupVisible: true });
  };

  render() {
    const { activeChannel, chatPopupVisible } = this.state;

    const channel = (
      <Channel>
        <Window>
          <HeaderComponent />
          <MessageList />
          <MessageInput Input={MessageInputFlat} />
        </Window>
        <Thread />
        <ChannelWatcher
          updateActiveChannel={(channel) => this.updateActiveChannel(channel)}
        />
      </Channel>
    );

    return (
      <Chat client={this.chatClient} theme={this.theme}>
        <Row align='center' justify='space-around'>
          <Col xs={24}>
            <ChannelList
              filters={this.filters}
              sort={this.sort}
              Paginator={this.Paginator}
              showChannelSearch={true}
              ChannelSearch={() => <ChannelSearch user={this.props.user} />}
              Preview={(props) => (
                <ChannelPreviewMessenger {...props}></ChannelPreviewMessenger>
              )}
              setActiveChannelOnMount={false}
            />
            {activeChannel ? null : channel}
          </Col>
        </Row>
        <Drawer
          placement='bottom'
          visible={chatPopupVisible}
          height='auto'
          className='chat-popup'
          onClose={() => this.setState({ chatPopupVisible: false })}
        >
          {channel}
        </Drawer>
      </Chat>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUnreadCount: (unreadCount) => dispatch(setUnreadCount(unreadCount)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChatDrawer));
