import { Button, Col, Row, Space } from 'antd';
import { Prompt, withRouter } from 'react-router-dom';
import React, { Component } from 'react';

import AuctionService from 'src/services/AuctionService';
import AuctionSteps from './steps/AuctionSteps';
import ConfirmationModal from 'src/modals/common/ConfirmationModal';
import LoadingComponent from 'src/components/common/LoadingComponent';
import MetadataService from 'src/services/MetadataService';
import NoDataComponent from 'src/components/common/NoDataComponent';
import PlannedOrderDataTable from 'src/components/plannedOrder/auction/PlannedOrderDataTable';
import ReviewMultiplePlannedOrders from './reviewAuctionPlannedOrders/ReviewMultiplePlannedOrders';
import ReviewSinglePlannedOrder from './reviewAuctionPlannedOrders/ReviewSinglePlannedOrder';
import RouteConstants from 'src/constants/RouteConstants';
import Specification from 'src/components/specification/Specification';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { setIncompleteAuctionDraft } from 'src/actions/route';
import { toast } from 'react-toastify';
import withSecureComponent from 'src/components/common/WithSecureComponent';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';

class ReviewAuctionPlannedOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auctionId: null,
      auctionDetails: {},
      loading: true,
      updateLoading: false,
      warehouses: [],
      unsavedChangesPromptEnabled: true,
      draftAuctionPromptEnabled: true,
      revisedAuctionDetails: undefined,
      discardAuctionModalVisible: false,
    };
  }

  componentDidMount() {
    const auctionId = this.props.match.params.id;
    this.setState({ loading: true, auctionId });

    if (isEmpty(this.props.route)) this.props.setIncompleteAuctionDraft(true);

    AuctionService.getAuctionDetails(auctionId)
      .then((response) => {
        if (response.data.status === StringConstants.AUCTION_STATUS.DRAFT) {
          const auctionDetails = response.data;
          auctionDetails.auctionPlannedOrders.forEach((pl) => {
            pl.specification = auctionDetails.specification;
            pl.requestedEtd = moment(pl.requestedEtd);
            pl.plannedOrder.requestedEtd = moment(pl.plannedOrder.requestedEtd);
          });

          this.setState({ auctionDetails }, () => {
            MetadataService.getAllWarehouses()
              .then((response) => {
                this.setState({ warehouses: response.data, loading: false });
              })
              .catch((error) => {
                this.setState({ loading: false });
              });
          });
        } else throw response;
      })
      .catch((error) => {
        toast.error(error.data.message);
        this.setState({ loading: false, auctionDetails: {} });
      });
  }

  updateAuctionPlannedOrder = () => {
    let data = {};
    const { auctionDetails, auctionId } = this.state;
    if (auctionDetails.auctionPlannedOrders.length === 1) {
      data = this.reviewSinglePlannedOrder.getUpdatedData();
    } else {
      data = this.plannedOrderDataTable.getUpdatedData();
    }
    if (isEmpty(data)) {
      this.setState({ draftAuctionPromptEnabled: false }, () => {
        this.props.history.push(
          UrlGenerator.getUiUrlWithPathParams(RouteConstants.AUCTION_STEP_2, {
            id: auctionId,
          })
        );
      });
    } else {
      data[':auctionId'] = auctionId;
      this.setState({
        updateLoading: true,
      });
      AuctionService.updateAuctionPlannedOrders(data)
        .then((response) => {
          this.setState(
            {
              updateLoading: false,
              unsavedChangesPromptEnabled: false,
              draftAuctionPromptEnabled: false,
            },
            () => {
              this.props.history.replace(
                UrlGenerator.getUiUrlWithPathParams(
                  RouteConstants.AUCTION_STEP_2,
                  {
                    id: auctionId,
                  }
                )
              );
            }
          );
        })
        .catch((err) => {
          this.setState({ updateLoading: false });
          toast.error('RFQ Update Failed');
        });
    }
  };

  confirmDiscardAuction = () => {
    this.setState({ discardAuctionModalVisible: true });
  };

  cancelDiscardAuction = () => {
    this.setState({ discardAuctionModalVisible: false });
  };

  discardAuction = () => {
    const { auctionId } = this.state;
    this.setState({
      loading: true,
    });
    AuctionService.discardAuction(auctionId)
      .then((response) => {
        toast.success('RFQ Discarded');
        this.setState(
          {
            loading: false,
            unsavedChangesPromptEnabled: false,
            draftAuctionPromptEnabled: false,
          },
          () => {
            this.props.history.replace(RouteConstants.PLANNED_ORDERS);
          }
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error('Failed to discard RFQ, try again later');
      });
  };

  updatePlannedView = (data) => {
    let revisedAuctionDetails = {
      ...this.state.auctionDetails,
      auctionPlannedOrders: data.auctionPlannedOrders,
      totalFCL: data.totalFCL,
      totalCases: data.totalCases,
      totalWeight: data.totalWeight,
    };
    this.setState({ revisedAuctionDetails });
  };

  render() {
    const {
      loading,
      updateLoading,
      auctionId,
      auctionDetails,
      warehouses,
      unsavedChangesPromptEnabled,
      draftAuctionPromptEnabled,
      revisedAuctionDetails,
      discardAuctionModalVisible,
    } = this.state;

    return loading ? (
      <LoadingComponent />
    ) : isEmpty(auctionDetails) ? (
      <NoDataComponent
        title='RFQ not found'
        tip={`RFQ (${auctionId}) does not exist`}
        fullScreen={true}
        headerMetadata={true}
      />
    ) : (
      <>
        <HeaderMetadata
          title={`Review Planned Orders | Create RFQ`}
          description=''
        />
        <Row>
          <AuctionSteps step={1} />

          <Col span={24} xs={24} lg={24} xl={24} className='mt-10'>
            {auctionDetails.auctionPlannedOrders.length === 1 ? (
              <ReviewSinglePlannedOrder
                data={{
                  details: auctionDetails.auctionPlannedOrders[0],
                  warehouses: warehouses,
                }}
                auctionId={auctionId}
                ref={(ref) => (this.reviewSinglePlannedOrder = ref)}
                entity='Auction'
              />
            ) : (
              <>
                <ReviewMultiplePlannedOrders
                  data={auctionDetails}
                  revisedData={revisedAuctionDetails}
                  ref={(ref) => (this.reviewMultiplePlannedOrder = ref)}
                  entity='Auction'
                />
                <PlannedOrderDataTable
                  data={{
                    auctionPlannedOrders: auctionDetails.auctionPlannedOrders,
                    warehouses: warehouses,
                  }}
                  auctionId={auctionId}
                  ref={(ref) => (this.plannedOrderDataTable = ref)}
                  updatePlannedView={(data) => this.updatePlannedView(data)}
                  entity='Auction'
                />
              </>
            )}
          </Col>
          <Col span={24} xs={24} lg={24} xl={24}>
            <Specification data={auctionDetails.specification} />
          </Col>
          <Col span={24} xs={24} lg={24} xl={24}>
            <div className='mv-40 steps-action'>
              <Space size={24}>
                <Button
                  className='btn-secondary-danger'
                  loading={loading}
                  onClick={this.confirmDiscardAuction}
                >
                  Discard RFQ
                </Button>
                <Button
                  type='primary'
                  className='step-nav-btn'
                  loading={updateLoading}
                  onClick={this.updateAuctionPlannedOrder}
                >
                  Proceed
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
        <ConfirmationModal
          visible={discardAuctionModalVisible}
          negativeAction={true}
          heading='Discard RFQ'
          title='Are you sure you want to discard this RFQ?'
          description='This action is permanent and cannot be undone. This would cancel the RFQ and mark the planned orders available for other RFQs.'
          okText='Yes, Discard'
          onSubmit={this.discardAuction}
          onCancel={this.cancelDiscardAuction}
        />
        {draftAuctionPromptEnabled ? (
          <Prompt
            when={
              !isEmpty(this.props.route) &&
              this.props.route.isDraftAuctionIncomplete === true
            }
            message='Are you sure you want to leave, this RFQ will be in draft'
          />
        ) : null}
        {unsavedChangesPromptEnabled ? (
          <Prompt
            when={
              !isEmpty(this.props.route) &&
              this.props.route.hasUnsavedChanges === true
            }
            message='You have unsaved changes, do you want to continue?'
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    route: state.route,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIncompleteAuctionDraft: (payload) =>
      dispatch(setIncompleteAuctionDraft(payload)),
  };
};

export default compose(
  withSecureComponent(
    [StringConstants.COMPANY_TYPES.IMPORTER],
    [
      {
        name: StringConstants.TEAMS.SOURCING,
        role: StringConstants.TEAM_ROLES.MANAGER,
      },
    ]
  ),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ReviewAuctionPlannedOrders);
