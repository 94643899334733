import { find, isUndefined, orderBy } from 'lodash';

import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';
import React from 'react';
import moment from 'moment';

class RevenueGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          data: [],
        },
      ],
      options: {
        chart: {
          animations: {
            enabled: true,
            easing: 'linear',
            speed: 400,
          },
          id: 'area-datetime',
          type: 'area',
          zoom: {
            show: false,
          },
          toolbar: {
            show: false,
          },
        },
        yaxis: [
          {
            show: true,
            labels: {
              style: {
                colors: '#838B99',
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-yaxis-label',
              },
            },
            axisTicks: {
              show: false,
            },

            // min: this.getMinYAxisValue(),
            // max: this.getMaxYAxisValue(),
          },
        ],
        stroke: {
          show: false,
          curve: 'straight',
        },
        annotations: {
          position: 'front',
          // yaxis: [
          //   {
          //     y: this.props.auctionTargetPrice,
          //     strokeDashArray: 5,
          //     borderColor: '#ED474A',
          //     width: '100%',
          //     label: {
          //       show: true,
          //       text: this.props.auctionTargetPrice.toString(),
          //       position: 'left',
          //       offsetY: 5,
          //       borderWidth: 0,
          //       style: {
          //         fontSize: 12,
          //         color: '#ED474A',
          //       },
          //     },
          //   },
          // ],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: 'hollow',
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 6,
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy',
          },
          marker: {
            show: false,
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.1,
            opacityFrom: 0.1,
            opacityTo: 0.5,
            stops: [0, 100],
            gradientToColors: ['#fff'],
            colorStops: [
              [
                {
                  offset: 0,
                  color: '#eee',
                  opacity: 1,
                },
                {
                  offset: 0.6,
                  color: '#2170B866',
                  opacity: 50,
                },
                {
                  offset: 100,
                  color: '#fff',
                  opacity: 1,
                },
              ],
              [
                {
                  offset: 0,
                  color: '#fff',
                  opacity: 1,
                },
                {
                  offset: 50,
                  color: 'blue',
                  opacity: 0.75,
                },
                {
                  offset: 100,
                  color: '#ddd',
                  opacity: 1,
                },
              ],
            ],
          },
        },
        grid: {
          position: 'front',
          borderColor: '#e3e3e3',
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      selection: 'one_year',
    };
  }

  updateData(timeline) {
    this.setState({
      selection: timeline,
    });

    switch (timeline) {
      case 'one_month':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date(moment().subtract(1, 'month')).getTime(),
          new Date().getTime()
        );
        break;
      case 'six_months':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date(moment().subtract(6, 'month')).getTime(),
          new Date().getTime()
        );
        break;
      case 'one_year':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date(moment().subtract(1, 'year')).getTime(),
          new Date().getTime()
        );
        break;
      case 'ytd':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          new Date(moment().startOf('year')).getTime(),
          new Date().getTime()
        );
        break;
      case 'all':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          this.props.historicalPrice && this.props.historicalPrice.length > 0
            ? [0][0]
            : new Date(moment().startOf('year')).getTime(),
          this.props.historicalPrice && this.props.historicalPrice.length > 0
            ? this.props.historicalPrice[
                this.props.historicalPrice.length - 1
              ][0]
            : new Date().getTime()
        );
        break;
      default:
    }
  }

  getMaxYAxisValue = () => {
    let auctionTargetPrice = this.props.auctionTargetPrice;
    let sortedBids = orderBy(
      this.props.historicalPrice,
      function (o) {
        return o.finalPrice;
      },
      'desc'
    );
    let anyBidAboveTargetPrice = find(sortedBids, function (o) {
      return o.finalPrice > auctionTargetPrice;
    });

    if (sortedBids.length === 0) return undefined;

    if (isUndefined(anyBidAboveTargetPrice))
      return this.props.auctionTargetPrice + 0.5;
    else
      return anyBidAboveTargetPrice
        ? anyBidAboveTargetPrice.finalPrice + 0.1
        : this.props.auctionTargetPrice + 0.5;
  };

  getMinYAxisValue = () => {
    let sortedBids = orderBy(this.props.historicalPrice, function (o) {
      return o.finalPrice;
    });

    return sortedBids.length > 0
      ? sortedBids[0].finalPrice > this.props.auctionTargetPrice
        ? this.props.auctionTargetPrice - 0.5
        : sortedBids[0].finalPrice - 0.1
      : undefined;
  };

  render() {
    return (
      <>
        <div id='chart' className='historical-trend-chart'>
          <div className='historicaltrend-header'>
            <div className='toolbar'>
              Change View:
              <button
                id='one_month'
                onClick={() => this.updateData('one_month')}
                className={this.state.selection === 'one_month' ? 'active' : ''}
              >
                1M
              </button>
              &nbsp;
              <button
                id='six_months'
                onClick={() => this.updateData('six_months')}
                className={
                  this.state.selection === 'six_months' ? 'active' : ''
                }
              >
                6M
              </button>
              &nbsp;
              <button
                id='one_year'
                onClick={() => this.updateData('one_year')}
                className={this.state.selection === 'one_year' ? 'active' : ''}
              >
                1Y
              </button>
              &nbsp;
              <button
                id='ytd'
                onClick={() => this.updateData('ytd')}
                className={this.state.selection === 'ytd' ? 'active' : ''}
              >
                YTD
              </button>
              &nbsp;
              <button
                id='all'
                onClick={() => this.updateData('all')}
                className={this.state.selection === 'all' ? 'active' : ''}
              >
                ALL
              </button>
            </div>
            <div className='target-price-anno'>- - - Target Price</div>
          </div>

          <div id='chart-timeline'>
            <Chart
              options={this.state.options}
              series={this.state.series}
              type='area'
              height={300}
            />
          </div>
        </div>
      </>
    );
  }
}
export default RevenueGraph;
