import './PlannedOrder.css';

import { Button, Input, Space, Table } from 'antd';
import {
  filter,
  findIndex,
  forEach,
  isEmpty,
  isUndefined,
  orderBy,
} from 'lodash';
import { isUserImporter, isUserManagerOfTeam } from 'src/helpers/user';

import ApiUtils from 'src/utils/ApiUtils';
import AuctionService from 'src/services/AuctionService';
import CardWithTitle from '../common/cards/CardWithTitle';
import CompanyService from 'src/services/CompanyService';
import PlannedOrderReviewModal from '../../modals/plannedOrder/PlannedOrderReviewModal';
import PlannedOrderTableManager from 'src/utils/tables/PlannedOrderTableManager';
import ProformaService from 'src/services/ProformaService';
import PropTypes from 'prop-types';
import React from 'react';
import RouteConstants from '../../constants/RouteConstants';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setIncompleteAuctionDraft } from 'src/actions/route';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

const { Search } = Input;

function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}

class PlannedOrderTable extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      reviewModalVisible: false,
      selectedRowKeys: [], // Check here to configure the default column
      selectedPlannedOrders: [],
      modalLoading: false,
      plannedOrders: this.props.data,
      plants: [],
    };
  }

  getColumnsForTable = () => {
    const { plannedOrders } = this.state;
    return PlannedOrderTableManager.getColumnsForImporter(plannedOrders);
  };

  setReviewModalVisible(reviewModalVisible) {
    this.setState({ reviewModalVisible });
  }

  onSelectChange = (selectedRowKeys) => {
    const { plannedOrders } = this.state;
    const selectedPlannedOrders = filter(plannedOrders, (pl) => {
      return selectedRowKeys.includes(pl.id);
    });
    this.setState({ selectedPlannedOrders, selectedRowKeys });
  };

  handleSortChange = ({ value }) => {
    const { plannedOrders } = this.state;
    let data = [];
    if (value === 'newest') {
      data = orderBy(plannedOrders, 'plannedOrderCreatedOn', 'desc');
    } else {
      data = orderBy(plannedOrders, 'plannedOrderCreatedOn', 'asc');
    }
    this.setState({ plannedOrders: data });
  };

  handleSearch = (value) => {
    let filteredData = [];
    if (isEmpty(value)) this.setState({ plannedOrders: this.props.data });
    else {
      forEach(this.props.data, (po) => {
        if (JSON.stringify(po).toLowerCase().includes(value.toLowerCase()))
          filteredData.push(po);
      });
      this.setState({ plannedOrders: filteredData });
    }
  };

  onReviewSubmit = () => {
    // this.setReviewModalVisible(false);
    this.setState({ modalLoading: true });
    const { selectedRowKeys } = this.state;
    const data = { plannedOrders: [] };
    data.plannedOrders = selectedRowKeys;
    AuctionService.createAuction(data)
      .then((response) => {
        this.setState({ modalLoading: false });
        this.props.setIncompleteAuctionDraft(true);
        this.props.history.replace(
          UrlGenerator.getUiUrlWithPathParams(RouteConstants.AUCTION_STEP_1, {
            id: response.data.id,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({ modalLoading: false });
        toast.error('RFQ Creation Failed');
      });
  };

  onProformaCreate = (data) => {
    try {
      if (data.bidIncoterm.amount <= 0.0) {
        toast.warning('Quote amount cannot be empty or negative');
        return;
      }

      if (
        isUndefined(data.bidIncoterm.incotermCode) ||
        isUndefined(data.buyFromCompanyId) ||
        isUndefined(data.payToCompanyId)
      ) {
        toast.warning('Please fill all mandatory fields');
        return;
      }

      this.setState({ modalLoading: true });
      ProformaService.createDirectProforma(data)
        .then((response) => {
          this.setState({ modalLoading: false });
          this.props.history.replace(
            UrlGenerator.getUiUrlWithPathParams(
              RouteConstants.PROFORMA_DETAIL,
              {
                id: response.data.id,
              }
            )
          );
        })
        .catch((error) => {
          let errorMessage = ApiUtils.getErrorMessage(
            error,
            'Failed to create proforma'
          );
          toast.error(errorMessage);
          this.setState({ modalLoading: false });
        });
    } catch (error) {
      this.setState({ modalLoading: false });
    }
  };

  handleReviewModalCancel = () => {
    this.setState({ reviewModalVisible: false });
  };

  handleCreateAuction = () => {
    this.setState({ reviewModalVisible: true });
  };

  handleCreateProforma = () => {
    const { selectedPlannedOrders } = this.state;
    CompanyService.getAllProcessingPlants()
      .then((response) => {
        this.setState(
          {
            loading: false,
            plants: response.data,
          },
          () =>
            this.props.history.push({
              pathname: RouteConstants.CREATE_DIRECT_PROFORMA,
              data: { selectedPlannedOrders, plants: response.data },
            })
        );
      })
      .catch((err) => {
        this.setState({
          loading: false,
          plants: [],
        });
      });
  };

  isPlannedOrderDisabled = (record) => {
    const { selectedPlannedOrders } = this.state;
    if (isEmpty(selectedPlannedOrders)) return false;
    return (
      findIndex(
        selectedPlannedOrders,
        (plannedOrder) =>
          plannedOrder.specification.companyItemNumber ===
          record.specification.companyItemNumber
      ) === -1
    );
  };

  render() {
    const {
      selectedRowKeys,
      reviewModalVisible,
      selectedPlannedOrders,
      plannedOrders,
      modalLoading,
    } = this.state;

    const rowSelection = {
      hideSelectAll: true,
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: (record) => ({
        disabled: this.isPlannedOrderDisabled(record),
      }),
    };
    const { loading } = this.props;

    return (
      <CardWithTitle
        title='Planned Orders'
        action={
          <div style={{ display: 'flex' }}>
            <Search
              className='planned-order-table-search'
              placeholder='Search...'
              style={{ width: 400 }}
              enterButton='Search'
              allowClear={true}
              onSearch={this.handleSearch}
            />
            {/* RB : Commenting it out for now as per logan's comments */}
            {/* <div className='sortby-selection'>
              Sort By:
              <Select
                labelInValue
                defaultValue={{ value: 'Newest' }}
                title='Sort'
                style={{ width: 70 }}
                onChange={this.handleSortChange}
              >
                <Option value='newest'>Newest</Option>
                <Option value='oldest'>Oldest</Option>
              </Select>
            </div> */}
            {isUserImporter() &&
              isUserManagerOfTeam(StringConstants.TEAMS.SOURCING) && (
                <Space size='middle' align='center'>
                  <Button
                    className='create-proforma-cta'
                    type='primary'
                    onClick={this.handleCreateProforma}
                    disabled={selectedRowKeys.length === 0}
                  >
                    Create Proforma
                  </Button>
                  <Button
                    className='heading-cta'
                    type='primary'
                    onClick={this.handleCreateAuction}
                    disabled={selectedRowKeys.length === 0}
                  >
                    Create RFQ
                  </Button>
                </Space>
              )}
          </div>
        }
        badge={plannedOrders.length}
      >
        <Table
          locale={{ filterConfirm: 'Apply' }}
          rowSelection={rowSelection}
          columns={this.getColumnsForTable()}
          dataSource={plannedOrders}
          loading={loading}
          onChange={onChange}
          rowKey={(row) => row.id}
        />

        <PlannedOrderReviewModal
          visible={reviewModalVisible}
          data={selectedPlannedOrders}
          loading={modalLoading}
          onSubmit={this.onReviewSubmit}
          onCancel={this.handleReviewModalCancel}
        />
      </CardWithTitle>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    route: state.route,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIncompleteAuctionDraft: (payload) =>
      dispatch(setIncompleteAuctionDraft(payload)),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PlannedOrderTable);
