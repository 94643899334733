import { Button, Col, Row, Space, Switch } from 'antd';
import React, { Component } from 'react';
import { cloneDeep, find, isEqual } from 'lodash';

import ApiUtils from 'src/utils/ApiUtils';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import DisplayConstants from 'src/constants/DisplayConstants';
import { EditFilled } from '@ant-design/icons';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import LoadingComponent from 'src/components/common/LoadingComponent';
import ProfileSection from './userProfile/ProfileSection';
import SecureComponent from 'src/components/common/SecureComponent';
import UserService from 'src/services/UserService';
import { toast } from 'react-toastify';

class UserPreference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      loading: false,
      submitLoading: false,
      currentPreferences: [],
      preferences: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    UserService.getUserPreference()
      .then((response) => {
        this.setState({
          currentPreferences: cloneDeep(response.data),
          preferences: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error('Unable to get preferences at the moment');
        this.setState({ loading: false });
      });
  }

  handleCancel = () => {
    this.setState({
      preferences: this.state.currentPreferences,
      isEdit: false,
    });
  };

  updatePreferenceValue = (preference, value) => {
    const { preferences } = this.state;
    const preference_ = find(preferences, { key: preference.key });
    preference_.value = [value];
    this.setState({ preferences });
  };

  onSubmit = () => {
    const { preferences } = this.state;
    this.setState({ submitLoading: true });
    const data = {};
    preferences.forEach((preference) => {
      const preferenceSettings = DisplayConstants.PREFERENCE[preference.key];
      switch (preferenceSettings.TYPE) {
        case 'boolean':
          data[preference.key] = [preference.value[0]];
          break;
        default:
          break;
      }
    });
    UserService.updateUserPreference(data)
      .then((response) => {
        toast.success('Preferences Updated Successfully');
        this.setState({
          currentPreferences: cloneDeep(response.data),
          preferences: response.data,
          submitLoading: false,
          isEdit: false,
        });
      })
      .catch((err) => {
        const errorMessage = ApiUtils.getErrorMessage(
          err,
          'Unable to save preferences at the moment'
        );
        toast.error(errorMessage);
        this.setState({ submitLoading: false });
      });
  };

  renderPreferenceValue = (preference) => {
    const { isEdit } = this.state;
    const preferenceSettings = DisplayConstants.PREFERENCE[preference.key];
    switch (preferenceSettings.TYPE) {
      case 'boolean':
        const value = preference.value[0];
        return (
          <Switch
            checked={value}
            disabled={!isEdit}
            onChange={(value) => this.updatePreferenceValue(preference, value)}
          />
        );
      default:
        return <></>;
    }
  };

  render() {
    const { isEdit, loading, submitLoading, currentPreferences, preferences } =
      this.state;
    return (
      <>
        <HeaderMetadata title={`My Preference`} description='' />
        <CardWithTitle
          title='My Preference'
          className={'userprofile'}
          action={
            !isEdit ? (
              <Space>
                <Button
                  className='heading-cta'
                  type='primary'
                  onClick={() => this.setState({ isEdit: true })}
                  icon={<EditFilled />}
                  disabled={loading}
                >
                  Edit
                </Button>
              </Space>
            ) : (
              <></>
            )
          }
        >
          <Row>
            <ProfileSection />
            {!isEdit && loading ? (
              <Col span={24}>
                <LoadingComponent title='Loading preferences' />
              </Col>
            ) : (
              <Col className='rscb-details ' span={24} xs={24} lg={24} xl={24}>
                <p className='title-heading'>Notification Preferences</p>
                <ul className='rscb-items'>
                  {preferences.map((preference, index) => {
                    return (
                      <li key={index}>
                        <span className='left-item'>
                          {
                            DisplayConstants.PREFERENCE[preference.key]
                              .DISPLAY_NAME
                          }
                        </span>
                        <span className='right-item'>
                          {this.renderPreferenceValue(preference)}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </Col>
            )}
          </Row>
        </CardWithTitle>
        <div className='editprofile-btn-area mt-15 mb-15'>
          {isEdit && (
            <Space>
              <Button
                className='primary-full mr-15'
                type='primary'
                onClick={this.onSubmit}
                loading={submitLoading}
                disabled={isEqual(currentPreferences, preferences)}
              >
                Save Changes
              </Button>
              <Button
                onClick={this.handleCancel}
                className='cancel-outlined-full'
                disabled={submitLoading}
              >
                Cancel
              </Button>
            </Space>
          )}
        </div>
      </>
    );
  }
}

export default SecureComponent(UserPreference);
