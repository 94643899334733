import { isUserImporter, isUserPlant } from 'src/helpers/user';
import { orderBy, sortBy } from 'lodash';

import AuctionAnalysis from './buyer/view/auction/AuctionAnalysis';
import AuctionService from 'src/services/AuctionService';
import AuctionSummary from './buyer/view/auction/AuctionSummary';
import AuctionUtils from 'src/utils/AuctionUtils';
import BidService from 'src/services/BidService';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import LoadingComponent from 'src/components/common/LoadingComponent';
import NoAccessComponent from 'src/components/common/NoAccessComponent';
import NoDataComponent from 'src/components/common/NoDataComponent';
import PlantAuctionSummary from './plants/view/auction/PlantAuctionSummary';
import ProformaService from 'src/services/ProformaService';
import React from 'react';
import SecureComponent from 'src/components/common/SecureComponent';
import StringConstants from 'src/constants/StringConstants';
import { connect } from 'react-redux';
import { getFormattedFullDate } from 'src/utils/DisplayUtils';
import moment from 'moment';

class Auction extends React.Component {
  state = {
    loading: true,
    auctionId: null,
    auctionDetails: {},
    auctionParticipants: [],
    auctionBids: { biddingCompanies: [], bids: [] },
    auctionWinners: [],
    auctionProforma: [],
    plantBids: {},
    lastRefreshedTime: undefined,
  };

  init() {
    const auctionId = this.props.match.params.id;
    this.setState({ auctionId }, () => {
      if (isUserImporter()) {
        this.fetchAuctionDataInterval = setInterval(
          this.fetchBuyerPageData,
          5 * 60 * 1000
        );
        this.fetchBuyerPageData();
      } else if (isUserPlant()) {
        this.fetchAuctionDataInterval = setInterval(
          this.fetchPlantPageData,
          5 * 60 * 1000
        );
        this.fetchPlantPageData();
      }
    });
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined) {
      return false;
    }
    if (this.state.auctionId !== this.props.match.params.id) {
      clearInterval(this.fetchAuctionDataInterval);
      this.init();
    }
  }

  componentWillUnmount() {
    clearInterval(this.fetchAuctionDataInterval);
  }

  fetchAuctionDetails = async () => {
    try {
      let response = await AuctionService.getAuctionDetails(
        this.state.auctionId
      );

      let auctionDetails = response.data;
      auctionDetails.auctionPlannedOrders.forEach(
        (pl) => (pl.specification = auctionDetails.specification)
      );

      this.setState({
        auctionDetails,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  fetchAuctionParticipants = async () => {
    try {
      let response = await AuctionService.getAuctionParticipants({
        ':auctionId': this.state.auctionId,
        unassigned: true,
      });

      let auctionParticipants = response.data;
      auctionParticipants = sortBy(auctionParticipants, function (o) {
        return o.bids[0].rank;
      });

      this.setState({
        auctionParticipants,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  fetchAuctionBids = async () => {
    try {
      let response = await AuctionService.getAuctionBids(this.state.auctionId);

      let auctionBids = response.data;
      auctionBids.bids = orderBy(auctionBids.bids, ['createdAt'], ['desc']);

      this.setState({
        auctionBids,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  fetchAuctionWinners = async () => {
    try {
      let response = await AuctionService.getAuctionWinners(
        this.state.auctionId
      );

      let auctionWinners = response.data;

      this.setState({
        auctionWinners,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  fetchAuctionProforma = async () => {
    try {
      let response = await ProformaService.getAuctionProforma(
        this.state.auctionId
      );

      let auctionProforma = response.data;

      this.setState({
        auctionProforma,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  fetchPlantBid = async (plantId) => {
    try {
      let response = await BidService.getBidDetails(
        this.state.auctionId,
        plantId
      );

      let plantBids = response.data;

      this.setState({
        plantBids,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  fetchBuyerPageData = async () => {
    this.setState({ loading: true, lastRefreshedTime: moment() });
    await this.fetchAuctionDetails();
    await this.fetchAuctionParticipants();
    await this.fetchAuctionBids();
    if (
      AuctionUtils.isPendingAuction(this.state.auctionDetails.status) ||
      AuctionUtils.isCompletedAuction(this.state.auctionDetails.status)
    ) {
      await this.fetchAuctionWinners();
      await this.fetchAuctionProforma();
    }
    this.setState({ loading: false });
  };

  fetchPlantPageData = async () => {
    this.setState({ loading: true, lastRefreshedTime: moment() });
    await this.fetchAuctionDetails();
    await this.fetchAuctionParticipants();

    const plantId =
      this.props.user && this.props.user.company && this.props.user.company.id;

    await this.fetchPlantBid(plantId);

    this.setState({ loading: false });
  };

  render() {
    const {
      loading,
      auctionId,
      auctionDetails,
      auctionParticipants,
      auctionBids,
      auctionWinners,
      auctionProforma,
      plantBids,
      lastRefreshedTime,
    } = this.state;

    if (loading) return <LoadingComponent />;

    if (auctionDetails.status === StringConstants.AUCTION_STATUS.ACTIVE)
      return (
        <>
          <HeaderMetadata title={`${auctionId} (Active RFQ)`} description='' />
          {isUserImporter() ? (
            <AuctionAnalysis
              auctionId={auctionId}
              auctionDetails={auctionDetails}
              auctionParticipants={auctionParticipants}
              auctionBids={auctionBids}
              refreshPageData={this.fetchBuyerPageData}
              refreshedTime={lastRefreshedTime}
            />
          ) : isUserPlant() ? (
            <PlantAuctionSummary
              auctionDetails={auctionDetails}
              auctionParticipants={auctionParticipants}
              plantBids={plantBids}
              refreshedTime={lastRefreshedTime}
            />
          ) : (
            <NoAccessComponent />
          )}
        </>
      );
    else if (
      AuctionUtils.isPendingAuction(auctionDetails.status) ||
      AuctionUtils.isCompletedAuction(auctionDetails.status) ||
      AuctionUtils.isCancelledAuction(auctionDetails.status)
    )
      return (
        <>
          <HeaderMetadata title={`${auctionId} (Pending RFQ)`} description='' />
          {isUserImporter() ? (
            <AuctionSummary
              auctionId={auctionId}
              auctionDetails={auctionDetails}
              auctionParticipants={auctionParticipants}
              auctionBids={auctionBids}
              auctionWinners={auctionWinners}
              auctionProforma={auctionProforma}
              refreshPageData={this.fetchBuyerPageData}
              refreshedTime={lastRefreshedTime}
            />
          ) : isUserPlant() ? (
            <PlantAuctionSummary
              auctionDetails={auctionDetails}
              auctionParticipants={auctionParticipants}
              plantBids={plantBids}
              refreshedTime={lastRefreshedTime}
            />
          ) : (
            <NoAccessComponent />
          )}
        </>
      );
    else if (
      auctionDetails.status === StringConstants.AUCTION_STATUS.SCHEDULED_ACTIVE
    )
      return (
        <NoDataComponent
          title='RFQ not yet open'
          tip={`RFQ (${auctionId}) is scheduled to open at ${getFormattedFullDate(
            auctionDetails.openScheduledAt
          )}`}
          fullScreen={true}
          headerMetadata={true}
        />
      );
    else
      return (
        <NoDataComponent
          title='RFQ not found'
          tip={`RFQ (${auctionId}) does not exist`}
          fullScreen={true}
          headerMetadata={true}
        />
      );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(SecureComponent(Auction));
