import './CompanyProfile.css';

import { Button, Col, Form, Input, Row, Space } from 'antd';
import { findIndex, isEmpty, isUndefined } from 'lodash';
import {
  getFactoryContactInformationFields,
  getSubcontractorFactoryContactInformationFields,
} from 'src/utils/CompanyProfileUtils';
import {
  getUserDataFromCookie,
  isUserSubCompany,
} from 'src/utils/auth/AuthUtils';
import { isUserManagerOfTeam, isUserPlant } from 'src/helpers/user';

import ApiUtils from 'src/utils/ApiUtils';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import ChooseMainCompanyModal from 'src/modals/processingPlants/ChooseMainCompanyModal';
import CompanyService from 'src/services/CompanyService';
import ConfirmationModal from 'src/modals/common/ConfirmationModal';
import { EditFilled } from '@ant-design/icons';
import FactoryContactInformationSection from 'src/components/companyProfile/FactoryContactInformationSection';
import HeaderMetaData from 'src/components/common/helmet/HeaderMetadata';
import LoadingComponent from 'src/components/common/LoadingComponent';
import { PlusOutlined } from '@ant-design/icons';
import ProfileSection from '../company/companyProfile/ProfileSection';
import QuestionnaireSection from 'src/components/companyProfile/QuestionnaireSection';
import React from 'react';
import SeafoodListSection from 'src/components/companyProfile/SeafoodListSection';
import SecureComponent from 'src/components/common/SecureComponent';
import StringConstants from 'src/constants/StringConstants';
import TeamService from 'src/services/TeamService';
import UploadFilesComponent from '../../../components/common/UploadFilesComponent';
import { toast } from 'react-toastify';

class CompanyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      loading: true,
      companyProfile: {},
      pendingInvites: [],
      chooseMainCompanyModalVisible: false,
      resendActionLoading: false,
      resendInviteConfirmationModalVisible: false,
      revokeActionLoading: false,
      revokeInviteConfirmationModalVisible: false,
      delinkActionLoading: false,
      delinkSubplantConfirmationModalVisible: false,
      inviteId: undefined,
      questionnaire: [],
      uploadedDocuments: {},
      seafoodList: [],
      updatingProfile: false,
    };
  }

  fetchPageData = async () => {
    this.setState({ loading: true, chooseMainCompanyModalVisible: false });
    let companyProfile = await this.fetchCompanyProfile();

    if (isUserPlant()) {
      let pendingInvites = await this.getSubPlantPendingInvites();
      let { questionnaire } = await this.fetchQuestionnaire();
      this.setState({
        companyProfile,
        pendingInvites,
        questionnaire,
        seafoodList: companyProfile.seaFoodList,
        loading: false,
      });
    } else this.setState({ companyProfile, loading: false });
  };

  fetchQuestionnaire = async () => {
    try {
      let questionnaireRes = await CompanyService.getPlantQuestionnaire();
      return { questionnaire: questionnaireRes.data };
    } catch (err) {
      return { questionnaire: [] };
    }
  };

  componentDidMount() {
    this.fetchPageData();
  }

  handleCancel = () => {
    this.setState({ isEdit: false });
  };

  fetchCompanyProfile = async () => {
    try {
      let companyProfileRes = await CompanyService.getCompanyProfile();
      return companyProfileRes.data;
    } catch (error) {
      return {};
    }
  };

  getSubPlantPendingInvites = async () => {
    try {
      let pendingInvitesRes = await CompanyService.getSubPlantPendingInvites();
      return pendingInvitesRes.data ? pendingInvitesRes.data : [];
    } catch (error) {
      return [];
    }
  };

  refreshPage = async () => {
    await this.fetchPageData();
  };

  formFactoryInformationRequest = (values) => {
    let request = {};
    Object.keys(StringConstants.FACTORY_INFORMATION_REQUEST_MAPPINGS).forEach(
      (requestKey) => {
        request[requestKey] = [];
        let valueKeysForGroup = Object.keys(values).filter(
          (valueKey) =>
            valueKey.includes('-') &&
            StringConstants.FACTORY_INFORMATION_REQUEST_MAPPINGS[
              requestKey
            ].includes(valueKey.split('-')[0])
        );

        valueKeysForGroup.forEach((valueKey) => {
          let isValidValue =
            typeof values[valueKey] === 'boolean'
              ? !isUndefined(values[valueKey])
              : !isUndefined(values[valueKey]) && !isEmpty(values[valueKey]);
          if (isValidValue) {
            let group = valueKey.split('-')[0];
            let field = valueKey.split('-')[1];
            if (requestKey === 'plantAdditionalInformation') {
              request['plantAdditionalInformation'].push({
                questionId: field,
                value: values[valueKey],
              });
            } else if (requestKey === 'plantContacts') {
              let groupIndex = findIndex(
                request['plantContacts'],
                function (o) {
                  return o.type === group;
                }
              );
              if (groupIndex === -1) {
                request['plantContacts'].push({
                  type: group,
                  [field]: values[valueKey],
                });
              } else
                request['plantContacts'][groupIndex] = {
                  ...request['plantContacts'][groupIndex],
                  [field]: values[valueKey],
                };
            } else if (requestKey === 'subContractors') {
              let groupIndex = findIndex(
                request['subContractors'],
                function (o) {
                  return o.type === group;
                }
              );
              if (groupIndex === -1) {
                if (
                  field === 'line1' ||
                  field === 'line2' ||
                  field === 'line3' ||
                  field === 'city' ||
                  field === 'state' ||
                  field === 'zipcode' ||
                  field === 'country'
                )
                  request['subContractors'].push({
                    type: group,
                    address: {
                      [field]: values[valueKey],
                    },
                  });
                else
                  request['subContractors'].push({
                    type: group,
                    [field]: values[valueKey],
                  });
              } else {
                if (
                  field === 'line1' ||
                  field === 'line2' ||
                  field === 'line3' ||
                  field === 'city' ||
                  field === 'state' ||
                  field === 'zipcode' ||
                  field === 'country'
                )
                  request['subContractors'][groupIndex] = {
                    ...request['subContractors'][groupIndex],
                    address: {
                      ...request['subContractors'][groupIndex]['address'],
                      [field]: values[valueKey],
                    },
                  };
                else
                  request['subContractors'][groupIndex] = {
                    ...request['subContractors'][groupIndex],
                    [field]: values[valueKey],
                  };
              }
            }
          }
        });
      }
    );
    return request;
  };

  getFactoryInformationFieldValuesFromResponse = () => {
    const { companyProfile } = this.state;
    let values = {};
    companyProfile.plantAdditionalInformations.forEach((info) => {
      values[info.question.groupName + '-' + info.question.id] = info.value;
    });
    companyProfile.plantContacts.forEach((info) => {
      Object.keys(info).forEach((infoKey) => {
        if (infoKey !== 'type')
          values[info.type + '-' + infoKey] = info[infoKey];
      });
    });
    companyProfile.subContractors.forEach((info) => {
      Object.keys(info).forEach((infoKey) => {
        if (infoKey !== 'type' && infoKey !== 'address')
          values[info.type + '-' + infoKey] = info[infoKey];
        if (infoKey === 'address') {
          Object.keys(info.address).forEach((addressKey) => {
            if (addressKey !== 'id')
              values[info.type + '-' + addressKey] = info.address[addressKey];
          });
        }
      });
    });
    return values;
  };

  onSubmit = () => {
    let seafoodListRequest = {};
    if (isUserPlant()) seafoodListRequest = this.seafoodListSection.getData();

    this.companyProfileForm
      .validateFields()
      .then((values) => {
        this.setState({ updatingProfile: true });

        let data = {};
        if (values.line1) data.address = { line1: values.line1 };
        if (values.line2) data.address.line2 = values.line2;
        if (values.line3) data.address.line3 = values.line3;
        if (values.city) data.address.city = values.city;
        if (values.state) data.address.state = values.state;
        if (values.zipcode) data.address.zipcode = values.zipcode;
        if (values.country) {
          data.address.country = values.country;
          data.country = values.country;
        }
        if (values.registrationNumber)
          data.registrationNumber = values.registrationNumber;

        let additionalDataRequest = this.formFactoryInformationRequest(values);

        data = { ...data, ...additionalDataRequest, ...seafoodListRequest };

        CompanyService.updateCompanyProfile(data)
          .then(() => {
            this.uploadFilesRef.uploadDocuments();
          })
          .catch((err) => {
            const errorMessage = ApiUtils.getErrorMessage(
              err,
              'Unable to update details at the moment'
            );
            toast.error(errorMessage);
            this.setState({ updatingProfile: false });
          });
      })
      .catch((error) => {
        if (!isEmpty(error.errorFields)) {
          this.companyProfileForm.scrollToField(error.errorFields[0].name[0]);
        } else {
          console.log(error);
          toast.warning('Unable to submit changes at the moment');
        }
        this.setState({ updatingProfile: false });
      });
  };

  resendInvite = async () => {
    this.setState({ resendActionLoading: true });
    const { inviteId } = this.state;
    TeamService.resendInvite(inviteId)
      .then((response) => {
        toast.success('Resent invite successfully');
        this.setState(
          {
            resendActionLoading: false,
            resendInviteConfirmationModalVisible: false,
          },
          () => this.refreshPage()
        );
      })
      .catch((error) => {
        let errorMessage = ApiUtils.getErrorMessage(error);
        toast.error('Failed to resend invite - ' + errorMessage);
        this.setState(
          {
            resendActionLoading: false,
            resendInviteConfirmationModalVisible: false,
          },
          () => this.refreshPage()
        );
      });
  };

  revokeInvite = async (id) => {
    this.setState({ revokeActionLoading: true });
    const { inviteId } = this.state;
    TeamService.revokeInvite(inviteId)
      .then((response) => {
        toast.success('Revoked invite successfully');
        this.setState(
          {
            revokeActionLoading: false,
            revokeInviteConfirmationModalVisible: false,
          },
          () => this.refreshPage()
        );
      })
      .catch((error) => {
        let errorMessage = ApiUtils.getErrorMessage(error);
        toast.error('Failed to revoke invite - ' + errorMessage);
        this.setState(
          {
            revokeActionLoading: false,
            revokeInviteConfirmationModalVisible: false,
          },
          () => this.refreshPage()
        );
      });
  };

  delinkSubplant = async () => {
    this.setState({ delinkActionLoading: true });
    CompanyService.subPlantDelink()
      .then((response) => {
        toast.success('Delinked subplant successfully');
        this.setState(
          {
            delinkActionLoading: false,
            delinkSubplantConfirmationModalVisible: false,
          },
          () => this.refreshPage()
        );
      })
      .catch((error) => {
        let errorMessage = ApiUtils.getErrorMessage(error);
        toast.error('Failed to delink subplant - ' + errorMessage);
        this.setState(
          {
            delinkActionLoading: false,
            delinkSubplantConfirmationModalVisible: false,
          },
          () => this.refreshPage()
        );
      });
  };

  canAddMainCompany = () => {
    let userData = getUserDataFromCookie();
    const { companyProfile } = this.state;
    return (
      userData.sub_company === true ||
      (userData.sub_company === false &&
        companyProfile.subCompanies.length === 0)
    );
  };

  render() {
    const {
      loading,
      companyProfile,
      pendingInvites,
      isEdit,
      chooseMainCompanyModalVisible,
      resendActionLoading,
      revokeActionLoading,
      delinkActionLoading,
      resendInviteConfirmationModalVisible,
      revokeInviteConfirmationModalVisible,
      delinkSubplantConfirmationModalVisible,
      questionnaire,
      seafoodList,
      updatingProfile,
    } = this.state;

    if (loading) return <LoadingComponent />;

    return (
      <>
        <HeaderMetaData title={`My Company Profile`} description='' />
        <CardWithTitle
          className={
            isEdit
              ? 'userprofile userprofile-edit rscb-editable'
              : 'userprofile'
          }
          title='My Company Profile'
          action={
            !isEdit && isUserManagerOfTeam(StringConstants.TEAMS.EXECUTIVE) ? (
              <Button
                className='heading-cta'
                type='primary'
                onClick={() => this.setState({ isEdit: true })}
                icon={<EditFilled />}
              >
                Edit
              </Button>
            ) : null
          }
        >
          <Row gutter={30} className='profile-row'>
            <ProfileSection
              isEdit={isEdit}
              company={companyProfile}
              refreshPage={() => this.refreshPage()}
            />
            {!isEdit ? (
              <>
                <Col
                  className='rscb-details '
                  span={24}
                  xs={24}
                  lg={24}
                  xl={24}
                >
                  <p className='title-heading'>General Details</p>
                  <ul className='rscb-items'>
                    <li>
                      <span className='left-item'>Company Name:</span>
                      <span className='right-item'>
                        {companyProfile && companyProfile.name
                          ? companyProfile.name
                          : '-'}
                      </span>
                    </li>
                    <li>
                      <span className='left-item'>Address:</span>
                      <span className='right-item'>
                        {companyProfile &&
                        companyProfile &&
                        companyProfile.address ? (
                          <>
                            {!isUndefined(companyProfile.address.line1) && (
                              <span> {companyProfile.address.line1} </span>
                            )}
                            {!isUndefined(companyProfile.address.line2) && (
                              <span>
                                {' '}
                                {', ' + companyProfile.address.line2}{' '}
                              </span>
                            )}
                            {!isUndefined(companyProfile.address.line3) && (
                              <span>
                                {' '}
                                {', ' + companyProfile.address.line3}{' '}
                              </span>
                            )}
                          </>
                        ) : (
                          '-'
                        )}
                      </span>
                    </li>
                    <li>
                      <span className='left-item'>City:</span>
                      <span className='right-item'>
                        {companyProfile && companyProfile.address
                          ? companyProfile.address.city
                          : '-'}
                      </span>
                    </li>
                    <li>
                      <span className='left-item'>State:</span>
                      <span className='right-item'>
                        {companyProfile && companyProfile.address
                          ? companyProfile.address.state
                          : '-'}
                      </span>
                    </li>
                    <li>
                      <span className='left-item'>Zip Code:</span>
                      <span className='right-item'>
                        {companyProfile && companyProfile.address
                          ? companyProfile.address.zipcode
                          : '-'}
                      </span>
                    </li>
                    <li>
                      <span className='left-item'>Country:</span>
                      <span className='right-item'>
                        {companyProfile && companyProfile.country
                          ? companyProfile.country
                          : '-'}
                      </span>
                    </li>
                    <li>
                      <span className='left-item'>Registration Number:</span>
                      <span className='right-item'>
                        {companyProfile && companyProfile.registrationNumber
                          ? companyProfile.registrationNumber
                          : '-'}
                      </span>
                    </li>
                    {isUserPlant() && isEmpty(companyProfile.subCompanies) && (
                      <li>
                        <span className='left-item'>Represented by: </span>
                        <span className='right-item'>
                          {companyProfile && companyProfile.mainCompany
                            ? companyProfile.mainCompany.name
                            : pendingInvites.length > 0 &&
                              pendingInvites[0].status === 'INVITED'
                            ? 'Sent representation request to ' +
                              pendingInvites[0].email
                            : this.canAddMainCompany()
                            ? 'None'
                            : 'N/A'}
                        </span>
                      </li>
                    )}
                    {isUserPlant() &&
                      !isUserSubCompany() &&
                      !isEmpty(companyProfile.subCompanies) &&
                      pendingInvites.length === 0 && (
                        <li>
                          <span className='left-item'>Represents: </span>
                          <span className='right-item'>
                            {companyProfile &&
                            companyProfile.subCompanies &&
                            companyProfile.subCompanies.length > 0
                              ? companyProfile.subCompanies
                                  .map((company) => company.name)
                                  .join(', ')
                              : 'N/A'}
                          </span>
                        </li>
                      )}
                    {isUserPlant() && (
                      <>
                        <SeafoodListSection
                          data={seafoodList}
                          ref={(ref) => (this.seafoodListSection = ref)}
                        />
                        <QuestionnaireSection
                          data={questionnaire}
                          filledData={this.getFactoryInformationFieldValuesFromResponse()}
                        />
                        <FactoryContactInformationSection
                          data={getFactoryContactInformationFields()}
                          filledData={this.getFactoryInformationFieldValuesFromResponse()}
                        />
                        <FactoryContactInformationSection
                          data={getSubcontractorFactoryContactInformationFields()}
                          filledData={this.getFactoryInformationFieldValuesFromResponse()}
                        />
                        <p className='title-heading'> Uploaded Files </p>
                        <UploadFilesComponent
                          ref={(ref) => (this.uploadFilesRef = ref)}
                          entity='COMPANY'
                          entityId={companyProfile.id}
                          readOnly={true}
                        />
                      </>
                    )}
                  </ul>
                </Col>
              </>
            ) : (
              <>
                <Col className='rscb-details' span={24} xs={24} lg={24} xl={24}>
                  <p className='title-heading'>General Details</p>
                  <ul className='rscb-items'>
                    <Form
                      ref={(ref) => (this.companyProfileForm = ref)}
                      initialValues={{
                        ...companyProfile,
                        line1:
                          companyProfile.address &&
                          companyProfile.address.line1,
                        line2:
                          companyProfile.address &&
                          companyProfile.address.line2,
                        line3:
                          companyProfile.address &&
                          companyProfile.address.line3,
                        city:
                          companyProfile.address && companyProfile.address.city,
                        state:
                          companyProfile.address &&
                          companyProfile.address.state,
                        zipcode:
                          companyProfile.address &&
                          companyProfile.address.zipcode,
                        ...this.getFactoryInformationFieldValuesFromResponse(),
                      }}
                    >
                      <li>
                        <span className='left-item'>Company Name:</span>
                        <span className='right-item'>
                          <Form.Item name='name' className='mb-0'>
                            <Input className='input-field' disabled />
                          </Form.Item>
                        </span>
                      </li>
                      <li>
                        <span className='left-item'>Address Line 1:</span>
                        <span className='right-item'>
                          <Form.Item
                            name='line1'
                            className='mb-0'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter an address',
                              },
                            ]}
                          >
                            <Input className='input-field' />
                          </Form.Item>
                        </span>
                      </li>
                      <li>
                        <span className='left-item'>Address Line 2:</span>
                        <span className='right-item'>
                          <Form.Item name='line2' className='mb-0'>
                            <Input className='input-field' />
                          </Form.Item>
                        </span>
                      </li>
                      <li>
                        <span className='left-item'>Address Line 3:</span>
                        <span className='right-item'>
                          <Form.Item name='line3' className='mb-0'>
                            <Input className='input-field' />
                          </Form.Item>
                        </span>
                      </li>
                      <li>
                        <span className='left-item'>City:</span>
                        <span className='right-item'>
                          <Form.Item
                            name='city'
                            className='mb-0'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a city',
                              },
                            ]}
                          >
                            <Input className='input-field' />
                          </Form.Item>
                        </span>
                      </li>
                      <li>
                        <span className='left-item'>State:</span>
                        <span className='right-item'>
                          <Form.Item
                            name='state'
                            className='mb-0'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a state',
                              },
                            ]}
                          >
                            <Input className='input-field' />
                          </Form.Item>
                        </span>
                      </li>
                      <li>
                        <span className='left-item'>Zip Code:</span>
                        <span className='right-item'>
                          <Form.Item
                            name='zipcode'
                            className='mb-0'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a zipcode',
                              },
                            ]}
                          >
                            <Input className='input-field' />
                          </Form.Item>
                        </span>
                      </li>
                      <li>
                        <span className='left-item'>Country:</span>
                        <span className='right-item'>
                          <Form.Item
                            name='country'
                            className='mb-0'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a country',
                              },
                            ]}
                          >
                            <Input className='input-field' />
                          </Form.Item>
                        </span>
                      </li>
                      <li>
                        <span className='left-item'>Registration Number:</span>
                        <span className='right-item'>
                          <Form.Item
                            name='registrationNumber'
                            className='mb-0'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a registration number',
                              },
                            ]}
                          >
                            <Input className='input-field' />
                          </Form.Item>
                        </span>
                      </li>
                      {isUserPlant() && isEmpty(companyProfile.subCompanies) && (
                        <li>
                          <span className='left-item'>Represented by: </span>
                          <span className='right-item'>
                            {companyProfile && companyProfile.mainCompany ? (
                              <Space>
                                {companyProfile.mainCompany.name}
                                <Button
                                  className='cancel-button'
                                  onClick={() =>
                                    this.setState({
                                      delinkSubplantConfirmationModalVisible: true,
                                    })
                                  }
                                  loading={delinkActionLoading}
                                >
                                  Delink
                                </Button>
                              </Space>
                            ) : pendingInvites.length > 0 &&
                              pendingInvites[0].status === 'INVITED' ? (
                              <Space>
                                <span>
                                  {'Sent representation request to ' +
                                    pendingInvites[0].email}
                                </span>
                                <Button
                                  className='primary-button'
                                  onClick={() =>
                                    this.setState({
                                      resendInviteConfirmationModalVisible: true,
                                      inviteId: pendingInvites[0].id,
                                    })
                                  }
                                  loading={resendActionLoading}
                                >
                                  Resend
                                </Button>
                                <Button
                                  className='cancel-button'
                                  onClick={() =>
                                    this.setState({
                                      revokeInviteConfirmationModalVisible: true,
                                      inviteId: pendingInvites[0].id,
                                    })
                                  }
                                  loading={revokeActionLoading}
                                >
                                  Revoke
                                </Button>
                              </Space>
                            ) : this.canAddMainCompany() ? (
                              <Space>
                                <span> None </span>
                                <Button
                                  className='primary-button '
                                  onClick={() => {
                                    this.setState({
                                      chooseMainCompanyModalVisible: true,
                                    });
                                  }}
                                >
                                  <PlusOutlined />
                                  Add Representative
                                </Button>
                              </Space>
                            ) : (
                              'N/A'
                            )}
                          </span>
                        </li>
                      )}
                      {isUserPlant() &&
                        !isUserSubCompany() &&
                        !isEmpty(companyProfile.subCompanies) &&
                        pendingInvites.length === 0 && (
                          <li>
                            <span className='left-item'>Represents: </span>
                            <span className='right-item'>
                              {companyProfile &&
                              companyProfile.subCompanies &&
                              companyProfile.subCompanies.length > 0
                                ? companyProfile.subCompanies
                                    .map((company) => company.name)
                                    .join(', ')
                                : 'N/A'}
                            </span>
                          </li>
                        )}
                      {isUserPlant() && (
                        <>
                          <SeafoodListSection
                            data={seafoodList}
                            isEdit={true}
                            ref={(ref) => (this.seafoodListSection = ref)}
                          />
                          <QuestionnaireSection
                            data={questionnaire}
                            isEdit={true}
                          />
                          <FactoryContactInformationSection
                            data={getFactoryContactInformationFields()}
                            isEdit={true}
                          />
                          <FactoryContactInformationSection
                            data={getSubcontractorFactoryContactInformationFields()}
                            isEdit={true}
                          />
                          <p className='title-heading'> Uploaded Files </p>
                          <UploadFilesComponent
                            ref={(ref) => (this.uploadFilesRef = ref)}
                            entity='COMPANY'
                            entityId={companyProfile.id}
                            refreshData={this.refreshPage}
                            resetLoadingButton={() =>
                              this.setState({ updatingProfile: false })
                            }
                            successMessage={'Profile updated successfully'}
                          />
                        </>
                      )}
                    </Form>
                  </ul>
                </Col>
              </>
            )}
          </Row>
        </CardWithTitle>

        <div className='editprofile-btn-area mt-15 mb-15'>
          {!isEdit ? null : (
            <Space>
              <Button
                className='primary-full mr-15'
                type='primary'
                onClick={this.onSubmit}
                loading={updatingProfile}
              >
                Save Changes
              </Button>
              <Button
                onClick={this.handleCancel}
                className='cancel-outlined-full'
                disabled={updatingProfile}
              >
                Cancel
              </Button>
            </Space>
          )}
        </div>
        {chooseMainCompanyModalVisible && (
          <ChooseMainCompanyModal
            visible={chooseMainCompanyModalVisible}
            onCancel={() =>
              this.setState({ chooseMainCompanyModalVisible: false })
            }
            refreshPage={() => this.refreshPage()}
          />
        )}
        {resendInviteConfirmationModalVisible && (
          <ConfirmationModal
            visible={resendInviteConfirmationModalVisible}
            heading='Resend representation invite'
            title='Confirm resending of invite'
            description='Are you sure you want to resend this invite ?'
            okText='Yes, Resend'
            onSubmit={this.resendInvite}
            onCancel={() =>
              this.setState({
                resendInviteConfirmationModalVisible: false,
                inviteId: undefined,
              })
            }
          />
        )}
        {revokeInviteConfirmationModalVisible && (
          <ConfirmationModal
            visible={revokeInviteConfirmationModalVisible}
            negativeAction={true}
            heading='Revoke representation invite'
            title='Confirm revoking of invite'
            description='Are you sure you want to revoke this invite ?'
            okText='Yes, Revoke'
            onSubmit={this.revokeInvite}
            onCancel={() =>
              this.setState({
                revokeInviteConfirmationModalVisible: false,
                inviteId: undefined,
              })
            }
          />
        )}
        {delinkSubplantConfirmationModalVisible && (
          <ConfirmationModal
            visible={delinkSubplantConfirmationModalVisible}
            heading='Delink subplant'
            title='Confirm delinking of subplant'
            description={
              'Are you sure you want to delink from ' +
              (companyProfile &&
                companyProfile.mainCompany &&
                companyProfile.mainCompany.name) +
              '?'
            }
            okText='Yes, Delink'
            onSubmit={this.delinkSubplant}
            onCancel={() =>
              this.setState({
                delinkSubplantConfirmationModalVisible: false,
              })
            }
          />
        )}
      </>
    );
  }
}
export default SecureComponent(
  CompanyProfile,
  [
    StringConstants.COMPANY_TYPES.IMPORTER,
    StringConstants.COMPANY_TYPES.PROCESSING_PLANT,
  ],
  [{ name: StringConstants.TEAMS.EXECUTIVE }]
);
