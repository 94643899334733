import './AgreementTerm.css';

import { Col, InputNumber, Row, Select } from 'antd';

import CardWithTitle from '../common/cards/CardWithTitle';
import { CaretDownOutlined } from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Link } from 'react-router-dom';
import PaymentTerms from 'src/constants/PaymentTermConstants';
import React from 'react';
import RevisedDataView from '../plannedOrder/revisedDataView/RevisedDataView';
import RouteConstants from 'src/constants/RouteConstants';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import VersionHistoryDrawer from 'src/drawers/versionHistory/VersionHistoryDrawer';
import { find } from 'lodash';
import { getFormattedDate } from 'src/utils/DisplayUtils';

class AgreementTerm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      versionDrawerVisible: false,
      viewChanges: true,
      paymentTerms: this.props.data && this.props.data.paymentTerms,
      bidValue: this.props.data && this.props.data.bidValue,
      bidIncoterm:
        this.props.data &&
        this.props.data.bidIncoterm &&
        this.props.data.bidIncoterm.code,
      currentVersion: this.props.data.version,
      data: this.props.data,
    };
  }

  getUpdatedData = () => {
    const { bidValue, bidIncoterm, paymentTerms } = this.state;
    return {
      amount: bidValue,
      incotermCode: bidIncoterm,
      paymentTerms,
    };
  };

  closeVersionDrawer = () => {
    this.setState({ versionDrawerVisible: false });
  };

  isIncotermAvailable = (incotermCode) => {
    const { data } = this.props;
    const { bidIncoterm } = this.state;

    if (!data.auctionIncoterms) return bidIncoterm === incotermCode;

    return (
      data &&
      find(data.auctionIncoterms, function (o) {
        return o.incoterm.code === incotermCode;
      })
    );
  };

  render() {
    const { toEdit, prevData, readOnly } = this.props;

    const {
      viewChanges,
      paymentTerms,
      bidValue,
      bidIncoterm,
      versionDrawerVisible,
      currentVersion,
      data,
    } = this.state;

    return (
      <div className='mt-15'>
        <CardWithTitle title='Agreement Terms'>
          <Row>
            <Col
              className='rscb-details mb-15 mt-15'
              span={24}
              xs={24}
              lg={18}
              xl={18}
            >
              <ul className='rscb-items mt-15'>
                <li>
                  <span className='left-item'>Proforma :</span>
                  <span className='right-item'>{data && data.id}</span>
                </li>
                {data && data.type === 'AUCTION' && (
                  <li>
                    <span className='left-item'>RFQ :</span>
                    <Link
                      to={UrlGenerator.getUiUrlWithPathParams(
                        RouteConstants.AUCTION,
                        {
                          id: data.auctionId,
                        }
                      )}
                    >
                      <span className='auction-link'>
                        {data && data.auctionId}
                      </span>
                    </Link>
                  </li>
                )}
                <li>
                  <span className='left-item'>Buy From :</span>
                  <span className='right-item'>
                    {data && data.buyFrom && data.buyFrom.name}
                  </span>
                </li>
                <li>
                  <span className='left-item'>Pay To :</span>
                  <span className='right-item'>
                    {data && data.payTo && data.payTo.name}
                  </span>
                </li>
                <li>
                  <span className='left-item'>Version :</span>
                  <span className='right-item'>
                    v {data && data.version}{' '}
                    {!readOnly && data.version !== 0 && (
                      <span
                        className='color-blue-link'
                        onClick={() => {
                          this.setState({
                            versionDrawerVisible:
                              !this.state.versionDrawerVisible,
                          });
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {this.state.viewHistory ? 'Hide' : 'View'} History
                      </span>
                    )}
                  </span>
                </li>
                <li>
                  <span className='left-item'>Current Quote :</span>
                  {' $ '}
                  <RevisedDataView
                    data={prevData ? prevData : data}
                    updatedValue={data.bidValue}
                    attribute='bidValue'
                    type='Number'
                  />
                  {' / '}
                  <RevisedDataView
                    data={prevData ? prevData : data}
                    updatedValue={data.bidIncoterm.code}
                    attribute='bidIncoterm.code'
                  />
                </li>
                <li>
                  <span className='left-item '>Quantity :</span>
                  <span className='right-item '>
                    <span className='list-sub-items'>
                      Total Cases:
                      <br />
                      <span className='color-blue'>
                        <RevisedDataView
                          data={prevData ? prevData : data}
                          updatedValue={data.totalCases}
                          attribute='totalCases'
                          type='Number'
                        />
                      </span>
                    </span>
                    <span className='list-sub-items'>
                      Total Est LBS:
                      <br />
                      <span className='color-blue'>
                        <RevisedDataView
                          data={prevData ? prevData : data}
                          updatedValue={data.totalWeight}
                          attribute='totalWeight'
                          type='Weight'
                        />
                      </span>
                    </span>
                    <span className='list-sub-items'>
                      Total FCL:
                      <br />
                      <span className='color-blue'>
                        <RevisedDataView
                          data={prevData ? prevData : data}
                          updatedValue={data.totalFCL}
                          attribute='totalFCL'
                          type='Number'
                        />
                      </span>
                    </span>
                  </span>
                </li>
                <li>
                  <span className='left-item'>Quote Submitted On :</span>
                  <span className='right-item'>
                    {data && getFormattedDate(data.bidCreatedAt)}
                  </span>
                </li>
                <li>
                  <span className='left-item'>Quote Value:</span>
                  <span className='right-item'>
                    <InputNumber
                      className='input-field decimalval'
                      style={{ width: 200 }}
                      step={0.01}
                      min={0}
                      value={bidValue}
                      onChange={(value) => this.setState({ bidValue: value })}
                      disabled={!toEdit}
                      formatter={(value) => '$ ' + value}
                    />
                  </span>
                </li>
                <li>
                  <span className='left-item'>Quote Incoterm:</span>
                  <span className='right-item'>
                    {StringConstants.INCOTERMS.map((incoterm, index) => {
                      return (
                        this.isIncotermAvailable(incoterm.code) && (
                          <Checkbox
                            key={index}
                            className={
                              bidIncoterm === incoterm.code
                                ? 'incoterms incoterms-dark incoterm-cnf-dark'
                                : 'incoterms incoterm-cnf-light'
                            }
                            onChange={() => {
                              this.setState({ bidIncoterm: incoterm.code });
                            }}
                            checked={bidIncoterm === incoterm.code}
                            disabled={!toEdit}
                          >
                            {incoterm.text}
                            <br />
                            {incoterm.subText}
                          </Checkbox>
                        )
                      );
                    })}
                  </span>
                </li>
                <li>
                  <span className='left-item'>Payment Terms:</span>
                  <span className='right-item'>
                    {toEdit ? (
                      <Select
                        suffixIcon={<CaretDownOutlined />}
                        className='planned-vi-sel-btn'
                        style={{ width: '100%' }}
                        onChange={(value) =>
                          this.setState({ paymentTerms: value })
                        }
                        defaultValue={paymentTerms}
                        value={paymentTerms}
                        disabled={!toEdit}
                      >
                        {PaymentTerms.map((value, index) => {
                          return (
                            <Select.Option value={value} key={index}>
                              {value}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    ) : viewChanges ? (
                      <RevisedDataView
                        data={prevData ? prevData : data}
                        updatedValue={data.paymentTerms}
                        attribute='paymentTerms'
                      />
                    ) : (
                      data.paymentTerms
                    )}
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
          {data && (
            <VersionHistoryDrawer
              currentVersion={currentVersion}
              visible={versionDrawerVisible}
              id={data.id}
              entity='PROFORMA'
              onClose={this.closeVersionDrawer}
              ref={(ref) => (this.versionHistoryDrawer = ref)}
            />
          )}
        </CardWithTitle>
      </div>
    );
  }
}
export default AgreementTerm;
