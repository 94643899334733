import { CalendarFilled, CaretDownOutlined } from '@ant-design/icons';
import { Col, DatePicker, InputNumber, Row, Select, Space, Tag } from 'antd';
import React, { Component } from 'react';
import {
  capitalize,
  find,
  isEmpty,
  isUndefined,
  map,
  uniq,
  upperCase,
} from 'lodash';
import { getAmountDisplayText, getFormattedDate } from 'src/utils/DisplayUtils';

import AuctionService from 'src/services/AuctionService';
import DisplayConstants from 'src/constants/DisplayConstants';
import NumberFormat from 'react-number-format';
import ProformaService from 'src/services/ProformaService';
import PropTypes from 'prop-types';
import RevisedDataView from './revisedDataView/RevisedDataView';
import StringConstants from '../../constants/StringConstants';
import { isUserPlant } from 'src/helpers/user';
import moment from 'moment';
import { toast } from 'react-toastify';

const { Option } = Select;

export default class PlannedOrder extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    isEdit: PropTypes.bool,
    showImage: PropTypes.bool,
    showAuctionStatus: PropTypes.bool,
    prevData: PropTypes.object,
  };

  static defaultProps = {
    isEdit: false,
    showImage: true,
    showAuctionStatus: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      plannedCases: undefined,
      requestedEtd: undefined,
      requestedEta: undefined,
      totalWeight: undefined,
      weightUom: undefined,
      locationCode: undefined,
      destinationPort: undefined,
    };
  }

  getUpdatedData = () => {
    const { data, warehouses, entity } = this.props;
    const { totalWeight, requestedEtd, locationCode } = this.state;

    if (
      (isUndefined(totalWeight) ||
        totalWeight === data.totalWeight.toString()) &&
      (isUndefined(requestedEtd) ||
        moment(data.requestedEtd).isSame(requestedEtd, 'd')) &&
      (isUndefined(locationCode) ||
        locationCode === data.warehouse.locationCode.toString())
    ) {
      // reset computed fields since no update
      this.setState({
        plannedCases: data.plannedCases,
        weightUom: data.weightUom,
        requestedEta: data.requestedEta,
        destinationPort: data.destinationPort,
      });
      return {};
    }

    const updatedData = {
      totalWeight: totalWeight ? totalWeight : data.totalWeight,
      requestedEtd: requestedEtd
        ? moment(requestedEtd).format()
        : moment(data.requestedEtd).format(),
      warehouseId: locationCode
        ? find(warehouses, function (o) {
            return o.locationCode === locationCode;
          }).id
        : data.warehouse.id,
    };

    if (entity === 'Auction')
      return {
        auctionPlannedOrders: [
          {
            id: data.id,
            ...updatedData,
          },
        ],
      };
    else
      return {
        proformaPlannedOrders: [
          {
            id: data.id,
            ...updatedData,
          },
        ],
      };
  };

  getCalculatedData = () => {
    const { auctionId, proformaId, entity } = this.props;

    if (isEmpty(this.getUpdatedData())) return;
    //TODO Check if something was changed

    if (entity === 'Auction') {
      AuctionService.getCalculatedAuctionPlannedOrders(
        auctionId,
        this.getUpdatedData()
      )
        .then((response) => {
          this.setState({
            plannedCases: response.data[0].plannedCases,
            weightUom: response.data[0].weightUom,
            destinationPort: response.data[0].destinationPort,
            requestedEta: response.data[0].requestedEta,
          });
        })
        .catch((error) => {
          console.log(error);
          toast.error('Computation failed, invalid values in edited field(s)');
          // reset state
          this.resetEditedFields();
        });
    } else {
      ProformaService.getCalculatedProformaPlannedOrders(
        proformaId,
        this.getUpdatedData()
      )
        .then((response) => {
          this.setState({
            plannedCases: response.data[0].plannedCases,
            weightUom: response.data[0].weightUom,
            destinationPort: response.data[0].destinationPort,
            requestedEta: response.data[0].requestedEta,
          });
        })
        .catch((error) => {
          console.log(error);
          toast.error('Computation failed, invalid values in edited field(s)');
          // reset state
          this.resetEditedFields();
        });
    }
  };

  resetEditedFields = () => {
    this.setState({
      totalWeight: undefined,
      weightUom: undefined,
      destinationPort: undefined,
      requestedEta: undefined,
      requestedEtd: undefined,
      plannedCases: undefined,
      locationCode: undefined,
    });
  };

  render() {
    const {
      data,
      isEdit,
      showImage,
      showAuctionStatus,
      warehouses,
      showAuctionDetails,
      toEdit,
      prevData,
    } = this.props;
    const {
      plannedCases,
      requestedEtd,
      totalWeight,
      locationCode,
      requestedEta,
      destinationPort,
    } = this.state;

    return !isEdit && !toEdit ? (
      <Row>
        <Col className='mb-15 mt-15' span={24} xs={24} lg={24} xl={24}>
          <div className='rscb-info'>
            {showImage && (
              <div className='rscb-img'>
                <img
                  src={require('../../assets/images/walmart.jpg').default}
                  className='rounded-corners-image'
                  alt='Species'
                />
              </div>
            )}
            <div className='rscb-desc' style={showImage ? {} : { padding: 0 }}>
              <p className='rscb-desc-cin'>
                <Space>
                  Planned Order : <span>{data.plannedOrderId}</span>
                  {showAuctionStatus &&
                    StringConstants.PLANNED_ORDER_STATUS.AUCTION_DRAFT ===
                      data.status && <Tag color='orange'>RFQ Draft</Tag>}
                  {showAuctionStatus &&
                    StringConstants.PLANNED_ORDER_STATUS.AUCTION_PROGRESS ===
                      data.status && <Tag color='blue'>RFQ In-progress</Tag>}
                </Space>
              </p>
              <p className='rscb-desc-company'>
                <span>{upperCase(data.specification.customer)}</span> |{' '}
                <span>{data.specification.itemDescription}</span>
              </p>
            </div>
          </div>
        </Col>
        <Col
          className='rscb-details mb-15 mt-15'
          span={24}
          xs={24}
          lg={24}
          xl={24}
        >
          <ul className='rscb-items'>
            {showAuctionDetails && (
              <>
                <li>
                  <span className='left-item'>Target Price (per/LB):</span>
                  <span className='right-item'>
                    {getAmountDisplayText(data.auction.targetPrice)}
                  </span>
                </li>
                <li>
                  <span className='left-item'>Last Date & Time For Quote:</span>
                  <span className='right-item'>
                    {moment
                      .utc(data.auction.endAt)
                      .format('DD MMM YYYY / hh:mm A')}
                  </span>
                </li>
              </>
            )}
            <li>
              <span className='left-item'>Company Planned Order</span>
              <span className='right-item'>
                {data.companyPlannedOrderNumber}
              </span>
            </li>
            <li>
              <span className='left-item'>Item</span>
              <span className='right-item'>
                {data.specification.companyItemNumber} (
                {data.specification.itemDescription})
              </span>
            </li>
            <li>
              <span className='left-item'>Addtional Item Number</span>
              <span className='right-item'>
                {data.specification.additionalItemNumber}
              </span>
            </li>
            <li>
              <span className='left-item'>Product Form</span>
              <span className='right-item'>
                {data.specification.productForm}
              </span>
            </li>
            <li>
              <span className='left-item'>Customer</span>
              <span className='right-item'>{data.specification.customer}</span>
            </li>
            <li>
              <span className='left-item'>Brand</span>
              <span className='right-item'>{data.specification.brand}</span>
            </li>
            <li>
              <span className='left-item'>Requested ETD</span>
              <span className='right-item'>
                <RevisedDataView
                  data={prevData ? prevData : data}
                  updatedValue={prevData ? data.requestedEtd : requestedEtd}
                  attribute='requestedEtd'
                  type='Date'
                />
              </span>
            </li>
            <li>
              <span className='left-item'>Requested ETA</span>
              <span className='right-item'>
                <RevisedDataView
                  data={prevData ? prevData : data}
                  updatedValue={prevData ? data.requestedEta : requestedEta}
                  attribute='requestedEta'
                  type='Date'
                />
              </span>
            </li>
            <li>
              <span className='left-item'>Location Code</span>
              <span className='right-item'>
                <RevisedDataView
                  data={prevData ? prevData : data}
                  updatedValue={
                    prevData ? data.warehouse.locationCode : locationCode
                  }
                  attribute='warehouse.locationCode'
                />
              </span>
            </li>
            <li>
              <span className='left-item'>Destination Port</span>
              <span className='right-item'>
                <RevisedDataView
                  data={prevData ? prevData : data}
                  updatedValue={
                    prevData ? data.destinationPort : destinationPort
                  }
                  attribute='destinationPort'
                  type='Port'
                />
              </span>
            </li>
            <li>
              <span className='left-item'>Total FCL</span>
              <span className='right-item'>
                {data.totalFCL ? data.totalFCL : 1}
              </span>
            </li>
            <li>
              <span className='left-item'>Estimated Weight</span>
              <span className='right-item'>
                <RevisedDataView
                  data={prevData ? prevData : data}
                  updatedValue={prevData ? data.totalWeight : totalWeight}
                  attribute='totalWeight'
                  type='Weight'
                />
              </span>
            </li>
            <li>
              <span className='left-item'>Planned Cases</span>
              <span className='right-item'>
                <RevisedDataView
                  data={prevData ? prevData : data}
                  updatedValue={prevData ? data.plannedCases : plannedCases}
                  attribute='plannedCases'
                  type='Number'
                />
              </span>
            </li>
            <li>
              <span className='left-item'>Species</span>
              <span className='right-item species-details-item'>
                <Space>
                  {uniq(map(data.specification.seaFoodList, 'commonName')).map(
                    (seafoodItem, index) => {
                      return <Tag key={index}>{seafoodItem}</Tag>;
                    }
                  )}
                </Space>
              </span>
            </li>
          </ul>
        </Col>
      </Row>
    ) : (
      <div className='rscb-editable'>
        <Row>
          <Col className='mb-15 mt-15' span={24} xs={24} lg={24} xl={24}>
            <div className='rscb-info'>
              {showImage && (
                <div className='rscb-img'>
                  <img
                    src={require('../../assets/images/walmart.jpg').default}
                    className='rounded-corners-image'
                    alt='Species'
                  />
                </div>
              )}
              <div
                className='rscb-desc'
                style={
                  showImage
                    ? {}
                    : {
                        padding: 0,
                      }
                }
              >
                <p className='rscb-desc-cin'>
                  <Space>
                    Planned Order : <span>{data.plannedOrderId}</span>
                    {showAuctionStatus &&
                      StringConstants.PLANNED_ORDER_STATUS.AUCTION_DRAFT ===
                        data.status && <Tag color='orange'>RFQ Draft</Tag>}
                    {showAuctionStatus &&
                      StringConstants.PLANNED_ORDER_STATUS.AUCTION_PROGRESS ===
                        data.status && <Tag color='blue'>RFQ In-progress</Tag>}
                  </Space>
                </p>
                <p className='rscb-desc-company'>
                  <span>{upperCase(data.specification.customer)}</span> |{' '}
                  <span>{data.specification.itemDescription}</span>
                </p>
              </div>
            </div>
          </Col>
          <Col
            className='rscb-details mb-15 mt-15'
            span={24}
            xs={24}
            lg={24}
            xl={24}
          >
            <ul className='rscb-items'>
              <li>
                <span className='left-item'>Company Planned Order</span>
                <span className='right-item'>
                  {data.companyPlannedOrderNumber}
                </span>
              </li>
              <li>
                <span className='left-item'>Item</span>
                <span className='right-item'>
                  {data.specification.companyItemNumber} (
                  {data.specification.itemDescription})
                </span>
              </li>
              <li>
                <span className='left-item'>Addtional Item Number</span>
                <span className='right-item'>
                  {data.specification.additionalItemNumber}
                </span>
              </li>
              <li>
                <span className='left-item'>Product Form</span>
                <span className='right-item'>
                  {data.specification.productForm}
                </span>
              </li>

              <li>
                <span className='left-item'>Customer</span>
                <span className='right-item'>
                  {data.specification.customer}
                </span>
              </li>
              <li>
                <span className='left-item'>Brand</span>
                <span className='right-item'>{data.specification.brand}</span>
              </li>
              <li>
                <span className='left-item'>Requested ETD</span>
                <span className='right-item'>
                  <DatePicker
                    className='input-field'
                    placeholder=''
                    suffixIcon={<CalendarFilled />}
                    defaultValue={moment(data.requestedEtd)}
                    value={requestedEtd}
                    format={DisplayConstants.DATE_FORMAT}
                    onChange={(value) => this.setState({ requestedEtd: value })}
                  />
                </span>
              </li>
              <li>
                <span className='left-item'>Requested ETA</span>
                <span className='right-item'>
                  {requestedEta
                    ? getFormattedDate(requestedEta)
                    : getFormattedDate(data.requestedEta)}
                </span>
              </li>
              <li>
                <span className='left-item'>Location Code</span>
                <span className='right-item'>
                  {isUserPlant() ? (
                    data.warehouse.locationCode
                  ) : (
                    <Select
                      suffixIcon={<CaretDownOutlined />}
                      className='planned-vi-sel-btn'
                      defaultValue={data.warehouse.locationCode}
                      value={locationCode}
                      onChange={(value) =>
                        this.setState({
                          locationCode: value,
                        })
                      }
                    >
                      {warehouses.map((warehouse) => {
                        return (
                          <Option
                            key={warehouse.locationCode}
                            value={warehouse.locationCode}
                          >
                            {warehouse.locationCode}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </span>
              </li>
              <li>
                <span className='left-item'>Destination Port</span>
                <span className='right-item'>
                  {destinationPort
                    ? destinationPort &&
                      `${capitalize(destinationPort.name)}, ${
                        destinationPort.country
                      }`
                    : data.destinationPort &&
                      `${capitalize(data.destinationPort.name)}, ${
                        data.destinationPort.country
                      }`}
                </span>
              </li>
              <li>
                <span className='left-item'>Total FCL</span>
                <span className='right-item'>
                  {data.totalFCL ? data.totalFCL : 1}
                </span>
              </li>
              <li>
                <span className='left-item'>Estimated Weight</span>
                <span className='right-item'>
                  <InputNumber
                    className='input-field'
                    step={1}
                    defaultValue={data.totalWeight}
                    value={totalWeight}
                    min={1}
                    onChange={(value) => this.setState({ totalWeight: value })}
                  />
                </span>
              </li>
              <li>
                <span className='left-item'>Planned Cases</span>
                <span className='right-item'>
                  <NumberFormat
                    value={plannedCases ? plannedCases : data.plannedCases}
                    thousandSeparator={true}
                    displayType='text'
                  />
                </span>
              </li>

              <li>
                <span className='left-item'>Species</span>
                <span className='right-item species-details-item'>
                  <Space>
                    {uniq(
                      map(data.specification.seaFoodList, 'commonName')
                    ).map((seafoodItem, index) => {
                      return <Tag key={index}>{seafoodItem}</Tag>;
                    })}
                  </Space>
                </span>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    );
  }
}
