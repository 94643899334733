import moment from 'moment';

export const getDuration = (date1, date2) => {
  if (date1 === null || date2 === null) return null;
  return moment(date1).diff(moment(date2), 'days');
};

export const getDaysRemaining = (eta) => {
  if (!eta) return null;
  else return moment(eta).diff(moment(new Date()).startOf('day'), 'days');
};
