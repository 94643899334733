import NumberFormat from 'react-number-format';
import ReportConstants from 'src/constants/ReportConstants';
import TableFilterUtils from '../TableFilterUtils';
import { Tooltip } from 'antd';
import { getDisplayString } from '../DisplayUtils';

const ReportsTableManager = {
  getColumnsForPipeline: (data) => {
    return [
      {
        title: 'Item Number',
        dataIndex: 'itemNumber',
        key: 'itemNumber',
        filters: TableFilterUtils.getFilterDropdownValues(data, 'itemNumber'),
        filterMultiple: true,
        onFilter: (value, record) => record.itemNumber.indexOf(value) >= 0,
        width: 100,
      },
      {
        title: 'Item Description',
        dataIndex: 'itemDescription',
        filters: TableFilterUtils.getFilterDropdownValues(
          data,
          'itemDescription'
        ),
        ellipsis: {
          showTitle: false,
        },
        filterMultiple: true,
        onFilter: (value, record) => record.itemDescription.indexOf(value) >= 0,
        render: (itemDescription) => (
          <Tooltip placement='topLeft' title={itemDescription}>
            {itemDescription}
          </Tooltip>
        ),
      },
      {
        title: 'Product Type',
        key: 'productType',
        dataIndex: 'productType',
        width: 120,
        render: (productType) => getDisplayString(productType),
        filters: TableFilterUtils.getFilterDropdownValues(data, 'productType'),
        filterMultiple: true,
        onFilter: (value, record) => record.productType.indexOf(value) >= 0,
      },
      {
        title: 'Product Form',
        key: 'productForm',
        dataIndex: 'productForm',
        filters: TableFilterUtils.getFilterDropdownValues(
          data,
          'specification.productForm'
        ),
        filterMultiple: true,
        onFilter: (value, record) => record.productForm.indexOf(value) >= 0,
      },
      {
        title: 'In US Cold Storage',
        dataIndex: ReportConstants.PIPELINE_STATUS.US_COLD_STORAGE,
        key: ReportConstants.PIPELINE_STATUS.US_COLD_STORAGE,
        sorter: (a, b) =>
          a[ReportConstants.PIPELINE_STATUS.US_COLD_STORAGE] -
          b[ReportConstants.PIPELINE_STATUS.US_COLD_STORAGE],
        render: (data) => (
          <NumberFormat
            value={data}
            thousandSeparator={true}
            displayType='text'
          />
        ),
      },
      {
        title: 'On the Water',
        dataIndex: ReportConstants.PIPELINE_STATUS.ON_WATER,
        key: ReportConstants.PIPELINE_STATUS.ON_WATER,
        sorter: (a, b) =>
          a[ReportConstants.PIPELINE_STATUS.ON_WATER] -
          b[ReportConstants.PIPELINE_STATUS.ON_WATER],
        render: (data) => (
          <NumberFormat
            value={data}
            thousandSeparator={true}
            displayType='text'
          />
        ),
      },
      {
        title: 'On Order or In Production',
        dataIndex: ReportConstants.PIPELINE_STATUS.IN_PRODUCTION,
        key: ReportConstants.PIPELINE_STATUS.IN_PRODUCTION,
        sorter: (a, b) =>
          a[ReportConstants.PIPELINE_STATUS.IN_PRODUCTION] -
          b[ReportConstants.PIPELINE_STATUS.IN_PRODUCTION],
        render: (data) => (
          <NumberFormat
            value={data}
            thousandSeparator={true}
            displayType='text'
          />
        ),
      },
    ];
  },
  getColumnsForSpecificationInventory: (data) => {
    return [
      {
        title: 'Location',
        dataIndex: 'locationCode',
        key: 'locationCode',
        filters: TableFilterUtils.getFilterDropdownValues(data, 'locationCode'),
        filterMultiple: true,
        onFilter: (value, record) => record.locationCode.indexOf(value) >= 0,
      },
      {
        title: 'FIN',
        dataIndex: 'companyItemNumber',
        key: 'companyItemNumber',
        filters: TableFilterUtils.getFilterDropdownValues(
          data,
          'companyItemNumber'
        ),
        filterMultiple: true,
        onFilter: (value, record) =>
          record.companyItemNumber.indexOf(value) >= 0,
      },
      {
        title: 'Purchase Order',
        dataIndex: 'poNumber',
        key: 'poNumber',
        filters: TableFilterUtils.getFilterDropdownValues(data, 'poNumber'),
        filterMultiple: true,
        onFilter: (value, record) => record.poNumber.indexOf(value) >= 0,
      },
      {
        title: 'Total Weight',
        dataIndex: 'totalWeight',
        key: 'totalWeight',
        sorter: (a, b) => a['totalWeight'] - b['totalWeight'],
        render: (data) => (
          <NumberFormat
            value={data}
            thousandSeparator={true}
            displayType='text'
          />
        ),
      },
      {
        title: 'Cases Available',
        dataIndex: 'cases',
        key: 'cases',
        sorter: (a, b) => a['cases'] - b['cases'],
        render: (data) => (
          <NumberFormat
            value={data}
            thousandSeparator={true}
            displayType='text'
          />
        ),
      },
      {
        title: 'Cost/Unit',
        dataIndex: 'costPerUnit',
        key: 'costPerUnit',
        sorter: (a, b) => a['costPerUnit'] - b['costPerUnit'],
        render: (data) => (
          <NumberFormat
            prefix='$ '
            value={data}
            thousandSeparator={true}
            displayType='text'
          />
        ),
      },
      {
        title: 'Total Cost',
        dataIndex: 'totalCost',
        key: 'totalCost',
        sorter: (a, b) => a['totalCost'] - b['totalCost'],
        render: (data) => (
          <NumberFormat
            prefix='$'
            value={data}
            thousandSeparator={true}
            displayType='text'
          />
        ),
      },
    ];
  },
};
export default ReportsTableManager;
