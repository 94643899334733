import './common/Auth.css';

import { Col, Layout, Row } from 'antd';

import AuthSideCarousel from './common/AuthSideCarousel';
import ForgotPasswordForm from './forgotPassword/ForgotPasswordForm';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import React from 'react';
import UnsecureComponent from 'src/components/common/UnsecureComponent';

class ForgetPassowrd extends React.Component {
  render() {
    return (
      <>
        <HeaderMetadata
          title='Forgot Password | Carava'
          description='Forgot your password? No worries, we got you! Get recovery link sent to your registered email.'
        />
        <Layout style={{ minHeight: '100vh' }}>
          <Row>
            <Col
              style={{ minHeight: '100vh' }}
              className='login-left'
              span={12}
              xs={24}
              lg={12}
              xl={12}
            >
              <ForgotPasswordForm />
            </Col>
            <Col
              style={{ minHeight: '100vh' }}
              className='login-right'
              span={12}
              xs={24}
              sm={24}
              lg={12}
              xl={12}
            >
              <AuthSideCarousel />
            </Col>
          </Row>
        </Layout>
      </>
    );
  }
}
export default UnsecureComponent(ForgetPassowrd);
