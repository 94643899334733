import './Auth.css';

import { Carousel } from 'antd';
import React from 'react';

class AuthSideCarousel extends React.Component {
  render() {
    return (
      <>
        <div className='login-right-details'>
          <Carousel className='loginpage-caro' autoplay>
            <div className='caro-div'>
              <h1>Supply Chain Transparency</h1>
              <div className='ca-divider'></div>
              <p>
                Comprehensive visibility to supply chain status from origin
                facilities to destination warehouse
              </p>
            </div>
            <div className='caro-div'>
              <h1>RFQ Management</h1>
              <div className='ca-divider'></div>
              <p>
                Efficient execution of bid consolidation, negotitation and
                finalization of awards
              </p>
            </div>
            <div className='caro-div'>
              <h1>End-to-End Traceability</h1>
              <div className='ca-divider'></div>
              <p>
                Product traceability to processing facilities and raw material
                sources
              </p>
            </div>
            <div className='caro-div'>
              <h1>Document Management</h1>
              <div className='ca-divider'></div>
              <p>Centrally housed documentation for orders and facilities</p>
            </div>
            <div className='caro-div'>
              <h1>Purchase Order Tracking</h1>
              <div className='ca-divider'></div>
              <p>
                Live container and production tracking to support forecasting
                accuracy and ongoing execution
              </p>
            </div>
          </Carousel>
        </div>
      </>
    );
  }
}
export default AuthSideCarousel;
