import { Column, Row } from 'simple-flexbox';
import React, { Component } from 'react';

import { Button } from 'antd';
import HeaderMetadata from './helmet/HeaderMetadata';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

class NoAccessComponent extends Component {
  static propTypes = {
    fullScreen: PropTypes.bool,
    headerMetadata: PropTypes.bool,
    title: PropTypes.string,
    tip: PropTypes.string,
    showButton: PropTypes.bool,
  };

  static defaultProps = {
    fullScreen: false,
    headerMetadata: false,
    title: 'Not Authorized',
    tip: 'Looks like you are trying to access something thats restricted. Please contact support for details.',
    showButton: true,
  };

  render() {
    const { fullScreen, headerMetadata, title, tip, showButton } = this.props;
    return (
      <>
        {headerMetadata && <HeaderMetadata title={title} description={tip} />}
        <Row horizontal='center' style={fullScreen ? { height: '75vh' } : ''}>
          <Column horizontal='center'>
            <p className='page-heading primary-color'>{title}</p>
            <p className='text-normal font-18'>{tip}</p>
            {showButton && (
              <Button
                type='primary'
                className='step-nav-btn mv-20'
                onClick={() => this.props.history.goBack()}
              >
                Go Back
              </Button>
            )}
            <img
              src={require('../../assets/images/no-auth.png').default}
              alt='No Data'
              className='mt-40'
            />
          </Column>
        </Row>
      </>
    );
  }
}

export default withRouter(NoAccessComponent);
