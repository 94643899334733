import { Button, Col, DatePicker, InputNumber, Row, Select, Space } from 'antd';
import { CaretDownOutlined, EditFilled } from '@ant-design/icons';
import { Prompt, withRouter } from 'react-router-dom';
import React, { Component } from 'react';

import ApiUtils from 'src/utils/ApiUtils';
import AuctionService from 'src/services/AuctionService';
import AuctionSteps from './steps/AuctionSteps';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import ConfirmationModal from 'src/modals/common/ConfirmationModal';
import LoadingComponent from 'src/components/common/LoadingComponent';
import NoDataComponent from 'src/components/common/NoDataComponent';
import PaymentTerms from 'src/constants/PaymentTermConstants';
import PlannedOrderDataTable from 'src/components/plannedOrder/auction/PlannedOrderDataTable';
import ReviewMultiplePlannedOrders from './reviewAuctionPlannedOrders/ReviewMultiplePlannedOrders';
import ReviewSinglePlannedOrder from './reviewAuctionPlannedOrders/ReviewSinglePlannedOrder';
import RouteConstants from 'src/constants/RouteConstants';
import SelectIncoterm from 'src/components/incoterm/SelectIncoterm';
import SelectedPlantsTable from './chooseCompliantPlants/SelectedPlantsTable';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { setIncompleteAuctionDraft } from 'src/actions/route';
import { toast } from 'react-toastify';
import withSecureComponent from 'src/components/common/WithSecureComponent';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';

class ReviewAndOpenAuction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      auctionDetails: {},
      auctionId: null,
      targetPrice: 0,
      paymentTerms: PaymentTerms[0],
      auctionStartNow: true,
      auctionStartBy: moment().add(15, 'minutes'),
      auctionEndBy: moment().add(10, 'd'),
      updateLoading: false,
      draftAuctionPromptEnabled: true,
      discardAuctionModalVisible: false,
    };
  }

  componentDidMount() {
    const auctionId = this.props.match.params.id;
    this.setState({ loading: true, auctionId });

    if (isEmpty(this.props.route)) this.props.setIncompleteAuctionDraft(true);

    AuctionService.getAuctionDetails(auctionId)
      .then((response) => {
        if (response.data.status === 'DRAFT') {
          const auctionDetails = response.data;
          auctionDetails.auctionPlannedOrders.forEach(
            (pl) => (pl.specification = auctionDetails.specification)
          );
          this.setState({ auctionDetails, loading: false });
        } else throw response;
      })
      .catch((error) => {
        toast.error(error.data.message);
        this.setState({ loading: false, auctionDetails: {} });
      });
  }

  navigateTo = (route) => {
    const { auctionId } = this.state;
    this.props.history.replace(
      UrlGenerator.getUiUrlWithPathParams(route, { id: auctionId })
    );
  };

  confirmDiscardAuction = () => {
    this.setState({ discardAuctionModalVisible: true });
  };

  cancelDiscardAuction = () => {
    this.setState({ discardAuctionModalVisible: false });
  };

  discardAuction = () => {
    const { auctionId } = this.state;
    this.setState({ loading: true });
    AuctionService.discardAuction(auctionId)
      .then(() => {
        toast.success('RFQ Discarded');
        this.setState(
          { loading: false, draftAuctionPromptEnabled: false },
          () => {
            this.props.history.replace(RouteConstants.PLANNED_ORDERS);
          }
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error('Failed to discard RFQ, try again later');
      });
  };

  openAuction = () => {
    const {
      targetPrice,
      auctionEndBy,
      auctionId,
      paymentTerms,
      auctionStartBy,
      auctionStartNow,
    } = this.state;

    if (targetPrice === 0) {
      toast.warning('Target price cannot be 0');
      window.scrollTo(0, 0);
      return;
    }

    const data = {
      ':auctionId': auctionId,
      endAt: moment(auctionEndBy).format(),
      targetPrice,
      paymentTerms,
    };
    if (!auctionStartNow) {
      data.openAt = moment(auctionStartBy).format();
    }
    this.setState({ updateLoading: true });
    AuctionService.openAuction(data)
      .then((response) => {
        if (
          response.data.status !==
          StringConstants.AUCTION_STATUS.SCHEDULED_ACTIVE
        )
          toast.success('RFQ Open for plants to submit quotes');
        this.setState(
          { updateLoading: false, draftAuctionPromptEnabled: false },
          () => {
            this.props.history.replace(
              UrlGenerator.getUiUrlWithPathParams(RouteConstants.AUCTION, {
                id: response.data.id,
              })
            );
          }
        );
      })
      .catch((err) => {
        let errorMessage = ApiUtils.getErrorMessage(
          err,
          'Cannot open the RFQ at the moment'
        );
        toast.error(errorMessage);
        this.setState({ updateLoading: false });
      });
  };

  render() {
    const {
      loading,
      updateLoading,
      auctionId,
      auctionDetails,
      auctionStartNow,
      auctionStartBy,
      auctionEndBy,
      draftAuctionPromptEnabled,
      discardAuctionModalVisible,
      targetPrice,
    } = this.state;

    return loading ? (
      <LoadingComponent />
    ) : isEmpty(auctionDetails) ? (
      <NoDataComponent
        title='RFQ not found'
        tip={`RFQ (${auctionId}) does not exist`}
        fullScreen={true}
        headerMetadata={true}
      />
    ) : (
      <>
        <HeaderMetadata
          title={`Review and Submit RFQ | Create RFQ`}
          description=''
        />
        <Row>
          <AuctionSteps step={3} />
          <Col span={24} xs={24} lg={24} xl={24}>
            <CardWithTitle title='Review and Open RFQ' className='rscb'>
              <div className='rscb-editable'>
                <Row>
                  <Col
                    className='rscb-details mb-15 mt-15'
                    span={24}
                    xs={24}
                    lg={24}
                    xl={24}
                  >
                    <ul className='rscb-items'>
                      <li>
                        <span className='left-item'>
                          Target Price (per LB):
                        </span>
                        <span className='right-item'>
                          <InputNumber
                            className='input-field'
                            step={0.01}
                            min={0}
                            placeholder='Enter your expected price'
                            onChange={(value) =>
                              this.setState({ targetPrice: value })
                            }
                            formatter={(value) => `$ ${value}`}
                            value={targetPrice}
                          />
                        </span>
                      </li>

                      <li>
                        <span className='left-item'>Payment Terms:</span>
                        <span className='right-item'>
                          <Select
                            suffixIcon={<CaretDownOutlined />}
                            className='planned-vi-sel-btn'
                            defaultValue={PaymentTerms[0]}
                            style={{ width: '100%' }}
                            onChange={(value) =>
                              this.setState({ paymentTerms: value })
                            }
                          >
                            {PaymentTerms.map((value, index) => {
                              return (
                                <Select.Option value={value} key={index}>
                                  {value}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </span>
                      </li>

                      <li>
                        <span className='left-item'>Start RFQ By:</span>
                        <span className='right-item'>
                          <Row style={{ width: '100%' }}>
                            <Col flex='auto' className='mr-10'>
                              <Select
                                suffixIcon={<CaretDownOutlined />}
                                className='planned-vi-sel-btn'
                                defaultValue={auctionStartNow}
                                style={{ width: '100%' }}
                                onChange={(value) =>
                                  this.setState({ auctionStartNow: value })
                                }
                              >
                                <Select.Option value={true}>
                                  Immediately
                                </Select.Option>
                                <Select.Option value={false}>
                                  Later
                                </Select.Option>
                              </Select>
                            </Col>
                            <Col flex='auto'>
                              {!auctionStartNow && (
                                <DatePicker
                                  suffixIcon={
                                    <img
                                      src={
                                        require('src/assets/images/Icon-Calendar-Black.svg')
                                          .default
                                      }
                                      alt='Calendar'
                                    />
                                  }
                                  className='place-bid-date-time'
                                  showTime={{ format: 'hh:mm A' }}
                                  format='DD MMM, YYYY hh:mm A'
                                  defaultValue={auctionStartBy}
                                  onChange={(value) =>
                                    this.setState({
                                      auctionStartBy: value,
                                      auctionEndBy: auctionStartBy.add(10, 'd'),
                                    })
                                  }
                                />
                              )}
                            </Col>
                          </Row>
                        </span>
                      </li>

                      <li>
                        <span className='left-item'>End RFQ By:</span>
                        <span className='right-item'>
                          <DatePicker
                            suffixIcon={
                              <img
                                className=''
                                src={
                                  require('src/assets/images/Icon-Calendar-Black.svg')
                                    .default
                                }
                                alt='species'
                              />
                            }
                            className='place-bid-date-time'
                            showTime={{ format: 'hh:mm A' }}
                            format='DD MMM, YYYY hh:mm A'
                            defaultValue={auctionEndBy}
                            onChange={(value) =>
                              this.setState({ auctionEndBy: value })
                            }
                          />
                        </span>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col className='' span={24} xs={24} lg={24} xl={24}>
                  <div className='plain-divide'></div>

                  <div>
                    <p className='sub-heading'>Review Selection</p>
                    <Button
                      className='heading-cta edit-cta'
                      icon={<EditFilled />}
                      onClick={() => {
                        this.setState(
                          { draftAuctionPromptEnabled: false },
                          () => this.navigateTo(RouteConstants.AUCTION_STEP_1)
                        );
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                </Col>
                <Col className='mb-15 mt-15' span={24} xs={24} lg={24} xl={24}>
                  {auctionDetails.auctionPlannedOrders.length === 1 ? (
                    <ReviewSinglePlannedOrder
                      data={{
                        details: auctionDetails.auctionPlannedOrders[0],
                      }}
                      readOnly={true}
                      entity='Auction'
                    />
                  ) : (
                    <>
                      <ReviewMultiplePlannedOrders
                        data={auctionDetails}
                        readOnly={true}
                        entity='Auction'
                      />
                      <div className='plain-divide'></div>
                      <PlannedOrderDataTable
                        data={{
                          auctionPlannedOrders:
                            auctionDetails.auctionPlannedOrders,
                        }}
                        readOnly={true}
                        entity='Auction'
                      />
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <div className='plain-divide'></div>
                <Col className='mt-15' span={24} xs={24} lg={24} xl={24}>
                  <div>
                    <p className='sub-heading'>Compliant Plants</p>
                    <Button
                      className='heading-cta edit-cta'
                      icon={<EditFilled />}
                      onClick={() => {
                        this.setState(
                          { draftAuctionPromptEnabled: false },
                          () => this.navigateTo(RouteConstants.AUCTION_STEP_2)
                        );
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                </Col>

                <Col
                  className='rscb-details mb-15 mt-15'
                  span={24}
                  xs={24}
                  lg={24}
                  xl={24}
                >
                  <SelectedPlantsTable data={auctionDetails.biddingCompanies} />
                </Col>
              </Row>
              <Row>
                <Col className='' span={24} xs={24} lg={24} xl={24}>
                  <div className='plain-divide'></div>
                  <div>
                    <p className='sub-heading'>Eligible Incoterms</p>
                    <Button
                      className='heading-cta edit-cta'
                      icon={<EditFilled />}
                      onClick={() => {
                        this.setState(
                          { draftAuctionPromptEnabled: false },
                          () => this.navigateTo(RouteConstants.AUCTION_STEP_2)
                        );
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                  <SelectIncoterm
                    selectedData={auctionDetails.auctionIncoterms}
                    readOnly={true}
                  />
                </Col>
              </Row>
            </CardWithTitle>
            <Row>
              <Col span={24} xs={24} lg={24} xl={24}>
                <div className='mv-40 steps-action'>
                  <Space size={24}>
                    <Button
                      className='btn-secondary-danger'
                      loading={loading}
                      onClick={this.confirmDiscardAuction}
                    >
                      Discard RFQ
                    </Button>
                    <Button
                      type='primary'
                      className='step-nav-btn'
                      loading={updateLoading}
                      onClick={this.openAuction}
                    >
                      Open RFQ
                    </Button>
                  </Space>
                </div>
              </Col>
            </Row>
          </Col>
          <ConfirmationModal
            visible={discardAuctionModalVisible}
            negativeAction={true}
            heading='Discard RFQ'
            title='Are you sure you want to discard this RFQ?'
            description='This action is permanent and cannot be undone. This would cancel the RFQ and mark the planned orders available for other RFQs.'
            okText='Yes, Discard'
            onSubmit={this.discardAuction}
            onCancel={this.cancelDiscardAuction}
          />
          {draftAuctionPromptEnabled ? (
            <Prompt
              when={
                !isEmpty(this.props.route) &&
                this.props.route.isDraftAuctionIncomplete === true
              }
              message='Are you sure you want to leave, this RFQ will be in draft'
            />
          ) : null}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    route: state.route,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIncompleteAuctionDraft: (payload) =>
      dispatch(setIncompleteAuctionDraft(payload)),
  };
};

export default compose(
  withSecureComponent(
    [StringConstants.COMPANY_TYPES.IMPORTER],
    [
      {
        name: StringConstants.TEAMS.SOURCING,
        role: StringConstants.TEAM_ROLES.MANAGER,
      },
    ]
  ),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ReviewAndOpenAuction);
