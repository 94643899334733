import './ReviewAndCreateBid.css';

import React, { Component } from 'react';

import { Button } from 'antd';
import PlannedOrder from 'src/components/plannedOrder/PlannedOrder';
import Title from 'src/components/common/typography/Title';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { setUnsavedChanges } from 'src/actions/route';

class ReviewSinglePlannedOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      updatedFields: undefined,
    };
  }

  handlEditClick = () => {
    this.setState({ isEdit: !this.state.isEdit }, () => {
      this.props.setUnsavedChanges(
        !isEmpty(this.plannedOrder.getUpdatedData())
      );
      if (!this.state.isEdit) this.plannedOrder.getCalculatedData();
    });
  };

  getUpdatedData = () => {
    return this.plannedOrder.getUpdatedData();
  };

  resetEditedFields = () => {
    this.plannedOrder.resetEditedFields();
  };

  render() {
    const { isEdit } = this.state;
    const {
      data,
      readOnly,
      hideTitle,
      auctionId,
      proformaId,
      entity,
      toEdit,
      prevData,
    } = this.props;

    return (
      <div className={readOnly || hideTitle ? '' : 'ca-card-light mb-15 rscb'}>
        {hideTitle
          ? null
          : !readOnly && (
              <Title
                title='Review Planned Order'
                action={
                  <Button
                    className='heading-cta'
                    type='primary'
                    onClick={this.handlEditClick}
                  >
                    {isEdit ? 'Done' : 'Revise Planned Order'}
                  </Button>
                }
              />
            )}
        <PlannedOrder
          isEdit={isEdit}
          data={data.details}
          warehouses={data.warehouses}
          showAuctionStatus={false}
          ref={(ref) => (this.plannedOrder = ref)}
          auctionId={auctionId}
          proformaId={proformaId}
          entity={entity}
          toEdit={toEdit}
          prevData={prevData && prevData.details}
          showImage={false}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUnsavedChanges: (payload) => dispatch(setUnsavedChanges(payload)),
  };
};

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(
  ReviewSinglePlannedOrder
);
