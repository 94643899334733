import './common/Auth.css';

import { Col, Layout, Row } from 'antd';

import AuthSideCarousel from './common/AuthSideCarousel';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import React from 'react';
import ResetPasswordForm from './resetPassword/ResetPasswordForm';
import UnsecureComponent from 'src/components/common/UnsecureComponent';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: undefined,
      loading: false,
    };
  }

  componentDidMount() {
    const token = this.props.match.params.token;
    this.setState({ loading: false, token });
    //TODO Make API Call with the token  to verify if token is valid
  }

  render() {
    const { token, loading } = this.state;
    return (
      <>
        <HeaderMetadata
          title='Reset your password | Carava'
          description='Reset your password and recover your account !'
        />
        <Layout style={{ minHeight: '100vh' }}>
          <Row>
            <Col
              style={{ minHeight: '100vh' }}
              className='login-left'
              span={12}
              xs={24}
              lg={12}
              xl={12}
            >
              <ResetPasswordForm token={token} disabled={loading} />
            </Col>
            <Col
              style={{ minHeight: '100vh' }}
              className='login-right'
              span={12}
              xs={24}
              sm={24}
              lg={12}
              xl={12}
            >
              <AuthSideCarousel />
            </Col>
          </Row>
        </Layout>
      </>
    );
  }
}
export default UnsecureComponent(ResetPassword);
