import { capitalize, isEqual, isUndefined, lowerCase, uniqWith } from 'lodash';
import { getBidDisplayText, getReadableDisplayName } from '../DisplayUtils';

import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import RouteConstants from 'src/constants/RouteConstants';
import { Space } from 'antd';
import TableFilterUtils from '../TableFilterUtils';
import UrlGenerator from 'src/api/UrlGenerator';

const getColumns = (data) => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Container Number',
      dataIndex: 'containerNumber',
      render: (value) => (value ? value : '-'),
    },
    {
      title: 'Company PO Number',
      dataIndex: 'companyPoNumber',
      render: (value) => (value ? value : '-'),
    },
    {
      title: 'Buy From',
      filters: uniqWith(
        [
          ...TableFilterUtils.getFilterDropdownValues(data, 'buyFrom.name'),
          ...TableFilterUtils.getFilterDropdownValues(data, 'buyFromVendor'),
        ],
        isEqual
      ),
      onFilter: (value, record) => {
        if (record.poSource === 'CARAVA') return record.buyFrom.name === value;
        else if (record.poSource === 'NAV')
          return record.buyFromVendor === value;
        else return false;
      },
      render: (record) => {
        if (record.poSource === 'CARAVA')
          return record.buyFrom ? record.buyFrom.name : '-';
        else if (record.poSource === 'NAV')
          return record.buyFromVendor ? record.buyFromVendor : '-';
        else return '-';
      },
      width: 200,
    },
    {
      title: 'Pay To',
      filters: uniqWith(
        [
          ...TableFilterUtils.getFilterDropdownValues(data, 'payTo.name'),
          ...TableFilterUtils.getFilterDropdownValues(data, 'payToVendor'),
        ],
        isEqual
      ),
      onFilter: (value, record) => {
        if (record.poSource === 'CARAVA') return record.payTo.name === value;
        else if (record.poSource === 'NAV') return record.payToVendor === value;
        else return false;
      },
      render: (record) => {
        if (record.poSource === 'CARAVA')
          return record.payTo ? record.payTo.name : '-';
        else if (record.poSource === 'NAV')
          return record.payToVendor ? record.payToVendor : '-';
        else return '-';
      },
      width: 200,
    },
    {
      title: 'Payment Terms',
      dataIndex: 'paymentTerms',
      filters: TableFilterUtils.getFilterDropdownValues(data, 'paymentTerms'),
      filterMultiple: true,
      onFilter: (value, record) => record.paymentTerms.indexOf(value) >= 0,
      render: (value) => <span>{value}</span>,
      width: 150,
    },
    {
      title: 'Total Cases',
      dataIndex: 'totalCases',
      render: (value) => (
        <NumberFormat
          value={value}
          thousandSeparator={true}
          displayType='text'
        />
      ),
      sorter: (a, b) => {
        return a.totalCases - b.totalCases;
      },
    },
    {
      title: 'Total Weight',
      render: (record) => (
        <NumberFormat
          value={record.totalWeight}
          thousandSeparator={true}
          displayType='text'
          // suffix={}
        />
      ),
      sorter: (a, b) => {
        return a.totalWeight - b.totalWeight;
      },
    },
    {
      title: 'Origin Country',
      dataIndex: 'fromCountry',
      filters: TableFilterUtils.getFilterDropdownValues(data, 'fromCountry'),
      filterMultiple: true,
      onFilter: (value, record) => record.fromCountry.indexOf(value) >= 0,
    },
    {
      title: 'Value per case',
      render: (record) => {
        return getBidDisplayText(
          record.finalBidValue,
          record.incoterm && record.incoterm.code
        );
      },
    },
    {
      title: 'Source',
      filters:
        TableFilterUtils.getFilterDropdownValues(data, 'poSource').length === 0
          ? [{ text: 'Carava', value: 'Carava' }]
          : TableFilterUtils.getFilterDropdownValues(data, 'poSource'),
      onFilter: (value, record) => record.poSource === value,
      render: (record) => {
        return isUndefined(record.poSource) ? (
          <span className='carava-source-text'> Carava </span>
        ) : (
          <span className={lowerCase(record.poSource) + '-source-text'}>
            {capitalize(record.poSource)}
          </span>
        );
      },
    },
    {
      title: '',
      render: (purchaseOrder) => {
        return (
          <div className='text-center'>
            <Space>
              <Link
                className='primary-button ant-btn'
                to={
                  purchaseOrder.poSource === 'NAV'
                    ? UrlGenerator.getUiUrlWithPathParams(
                        RouteConstants.NAV_PURCHASE_ORDER_DETAIL,
                        {
                          id: purchaseOrder.id,
                        }
                      )
                    : UrlGenerator.getUiUrlWithPathParams(
                        RouteConstants.PURCHASE_ORDER_DETAIL,
                        {
                          id: purchaseOrder.id,
                        }
                      )
                }
              >
                View
              </Link>
            </Space>
          </div>
        );
      },
    },
  ];
};

function getColumnsForOverview(data) {
  const columns = getColumns(data);
  columns.splice(5, 0, {
    title: 'Status',
    dataIndex: 'status',
    filters: TableFilterUtils.getFilterDropdownValues(data, 'status'),
    filterMultiple: true,
    onFilter: (value, record) => record.status.indexOf(value) >= 0,
    render: (value) => capitalize(getReadableDisplayName(value)),
  });

  return columns;
}

const PurchaseOrderTableManager = {
  getColumns,
  getColumnsForOverview,
};

export default PurchaseOrderTableManager;
