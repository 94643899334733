import { isUserImporter, isUserManagerOfTeam } from 'src/helpers/user';

import CardWithTitle from '../common/cards/CardWithTitle';
import EmptySection from '../common/emptySection/EmptySection';
import React from 'react';
import StringConstants from 'src/constants/StringConstants';
import { Table } from 'antd';
import TermsAndConditionsTableManager from 'src/utils/tables/TermsAndConditionsTableManager';

class ChooseTermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      termsAndConditions: this.props.data,
      selectedRowKeys: [],
      selectedTandCs: this.props.originalSelection,
    };
  }

  async componentDidMount() {
    const { originalSelection } = this.props;
    this.setState({
      selectedRowKeys:
        originalSelection && originalSelection.map((tAndC) => tAndC.id),
    });
  }

  resetSelection = () => {
    const { originalSelection } = this.props;
    this.setState({
      selectedRowKeys: originalSelection.map((tAndC) => tAndC.id),
    });
  };

  getColumns = () => {
    const { termsAndConditions } = this.state;
    let showTextColumnOnly = true;
    return TermsAndConditionsTableManager.getBasicColumnsForTable.call(
      this,
      termsAndConditions,
      showTextColumnOnly
    );
  };

  onSelectChange = (selectedRowKeys) => {
    const { termsAndConditions } = this.state;
    const selectedTandCs = termsAndConditions.filter((tAndC) => {
      return selectedRowKeys.includes(tAndC.id);
    });
    this.setState({ selectedTandCs, selectedRowKeys });
  };

  isUserAuthorized = () => {
    return (
      isUserImporter() &&
      (isUserManagerOfTeam(StringConstants.TEAMS.EXECUTIVE) ||
        isUserManagerOfTeam(StringConstants.TEAMS.SOURCING))
    );
  };

  getUpdatedData = () => {
    return this.state.selectedRowKeys;
  };

  render() {
    const { termsAndConditions, selectedRowKeys, selectedTandCs } = this.state;

    const { toEdit, allowSelection, hideCard } = this.props;

    let rowSelection = null;

    if (allowSelection)
      rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: (record) => ({
          disabled: !this.isUserAuthorized() || !toEdit,
        }),
      };

    if (hideCard)
      return (
        <>
          <p className='modal-title color-blue mt-15'>Terms and Conditions</p>
          {!toEdit && selectedTandCs.length === 0 ? (
            <EmptySection
              imageSrc={
                require('../../assets/images/no-data-images/nd-old-versions.png')
                  .default
              }
              title='No T&Cs'
              showButton={false}
              size='small'
            />
          ) : (
            <Table
              columns={this.getColumns()}
              rowSelection={rowSelection}
              dataSource={
                toEdit && allowSelection ? termsAndConditions : selectedTandCs
              }
              rowKey='id'
              scroll={{ x: 900 }}
              pagination={
                termsAndConditions && termsAndConditions.length >= 10
                  ? true
                  : false
              }
            />
          )}
        </>
      );
    return (
      <CardWithTitle title='Terms and Conditions'>
        {!toEdit && selectedTandCs.length === 0 ? (
          <EmptySection
            imageSrc={
              require('../../assets/images/no-data-images/nd-old-versions.png')
                .default
            }
            title='No T&Cs'
            showButton={false}
            size='small'
          />
        ) : (
          <Table
            columns={this.getColumns()}
            rowSelection={rowSelection}
            dataSource={
              toEdit && allowSelection ? termsAndConditions : selectedTandCs
            }
            rowKey='id'
            scroll={{ x: 900 }}
            pagination={
              termsAndConditions && termsAndConditions.length >= 10
                ? true
                : false
            }
          />
        )}
      </CardWithTitle>
    );
  }
}

export default ChooseTermsAndConditions;
