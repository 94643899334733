import './AuctionSummaryTopCard.css';

import {
  getAmountDisplayText,
  getBidDisplayWithHelperText,
  getFormattedFullDate,
  getTimeRemaining,
} from 'src/utils/DisplayUtils';

import NumberFormat from 'react-number-format';
import React from 'react';
import { isEmpty } from 'lodash';

class AuctionSummaryTopCard extends React.Component {
  render() {
    const { auctionDetails, auctionBids, isAuctionSummary } = this.props;

    return (
      <div className='auction-info-card'>
        <ul className='auction-info-items-list'>
          <li className='auction-info-items'>
            {isEmpty(auctionDetails)
              ? 0
              : auctionDetails.biddingCompanies.length}{' '}
            <span>Invited Plants</span>
          </li>
          <li className='auction-info-items'>
            {isEmpty(auctionBids) ? 0 : auctionBids.bids.length}{' '}
            <span>Quotes</span>
          </li>
          <li className='auction-info-items'>
            {auctionDetails.lowestBid
              ? getBidDisplayWithHelperText(
                  auctionDetails.lowestBid.amount,
                  auctionDetails.lowestBid.incoterm.code
                )
              : 'N/A'}

            <span>Lowest Quote</span>
          </li>
          <li className='auction-info-items'>
            {isEmpty(auctionDetails)
              ? 'N/A'
              : getAmountDisplayText(auctionDetails.targetPrice)}
            <span>Target Price</span>
          </li>
          <li className='auction-info-items'>
            {isEmpty(auctionDetails) ? (
              ''
            ) : isAuctionSummary ? (
              <>
                <span className='date-time'>
                  RFQ Started on {getFormattedFullDate(auctionDetails.openedAt)}
                </span>
                <span className='date-time'>
                  RFQ Ended on {getFormattedFullDate(auctionDetails.endAt)}
                </span>
              </>
            ) : (
              <>
                <span className='date-time'>
                  RFQ Ends on{' '}
                  {auctionDetails && getFormattedFullDate(auctionDetails.endAt)}
                </span>
                <span className='time-left'>
                  {getTimeRemaining(auctionDetails.endAt)}
                </span>
              </>
            )}
          </li>
        </ul>
        <div className='auction-info-card-inside'>
          <div className='auction-info-card-cell'>
            <p className='auction-info-card-inside-detail'>
              <NumberFormat
                value={auctionDetails.totalCases}
                thousandSeparator={true}
                displayType='text'
                suffix={' Cases'}
              />
            </p>
          </div>
          <div className='auction-info-card-cell'>
            <p className='auction-info-card-inside-detail'>
              <NumberFormat
                value={auctionDetails.totalWeight}
                suffix={` ${auctionDetails.weightUom}`}
                thousandSeparator={true}
                displayType='text'
              />
            </p>
          </div>
          <div className='auction-info-card-cell'>
            <p className='auction-info-card-inside-detail'>
              <NumberFormat
                value={auctionDetails.totalFCL}
                thousandSeparator={true}
                displayType='text'
                suffix={' FCL'}
              />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default AuctionSummaryTopCard;
