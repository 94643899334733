import { Col, Upload } from 'antd';
import React, { Component } from 'react';
import { getProfilePicture, getUsername } from 'src/utils/DisplayUtils';

import PropTypes from 'prop-types';
import RouteConstants from 'src/constants/RouteConstants';
import UrlConstants from 'src/constants/UrlConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { capitalize } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import { updateUser } from 'src/actions/user';
import { withRouter } from 'react-router-dom';

class ProfileSection extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    isEdit: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      profilePicUploading: false,
    };
  }

  getProfilePictureForUser = (user) => {
    if (this.state.profilePicUploading)
      return (
        <img
          src={require('../../../../assets/images/Loader.gif').default}
          alt='loading...'
        />
      );
    return getProfilePicture(user);
  };

  uploadSuccess = (data) => {
    toast.success('Profile Picture Uploaded Successfully');
    this.props.updateUser(data);
    this.setState({ profilePicUploading: false });
  };

  uploadFailed = () => {
    toast.error('Failed to upload profile picture');
    this.setState({ profilePicUploading: false });
  };

  render() {
    const { user, isEdit } = this.props;
    return (
      <Col className='mt-15' span={24} xs={24} lg={24} xl={24}>
        <div className='profile-img-info'>
          <div className='profile-img'>
            {this.getProfilePictureForUser(user)}
            {isEdit && (
              <Upload
                accept='image/png, image/jpeg'
                showUploadList={false}
                method='PATCH'
                action={UrlGenerator.generateUrl(
                  UrlConstants.UPLOAD_PROFILE_PICTURE.USECASE
                )}
                headers={{
                  Authorization: 'Bearer ' + cookie.load('token'),
                }}
                onStart={() => this.setState({ profilePicUploading: true })}
                onError={this.uploadFailed}
                onSuccess={this.uploadSuccess}
              >
                Change Photo
              </Upload>
            )}
          </div>
          <div className='profile-info'>
            <p className='profile-username'>{getUsername(user)}</p>
            <p className='pro-designation-mailid mb-10'>{user.email}</p>
            <p className='pro-designation-mailid'>
              {user.teams.map((team, index) => {
                let team_string =
                  capitalize(team.name) + ' ' + capitalize(team.role);
                if (index < user.teams.length - 1) {
                  team_string += ' | ';
                }
                return team_string;
              })}
            </p>
            <div
              className='profile-company-name'
              onClick={() =>
                this.props.history.push(RouteConstants.COMPANY_PROFILE)
              }
            >
              {user.company.name}
            </div>
          </div>
        </div>
        <div className='heading-divider'></div>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => dispatch(updateUser(data)),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProfileSection);
