import './AuctionStats.css';

import BidHistoryChart from 'src/components/auction/bid/BidHistoryChart';
import Card from 'src/components/common/cards/Card';
import HistoricalTrend from './HistoricalTrend';
import LoadingComponent from 'src/components/common/LoadingComponent';
import NoDataComponent from 'src/components/common/NoDataComponent';
import React from 'react';
import SpecificationService from 'src/services/SpecificationService';
import { Tabs } from 'antd';
import { getDisplayIncotermForLegend } from 'src/utils/BidUtils';
import { isEmpty } from 'lodash';

const { TabPane } = Tabs;

class AuctionStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIncoterm:
        props.incoterms && props.incoterms.length > 1
          ? 'DDPCY'
          : props.allBids && props.allBids.bids && props.allBids.bids.length > 0
          ? getDisplayIncotermForLegend(this.props.allBids.bids)
          : props.incoterms && props.incoterms.length > 0
          ? props.incoterms[0].incoterm.code
          : undefined,
      historicalPriceLoading: true,
      historicalPrice: undefined,
    };
  }

  handleChange = (value) => {
    this.setState({ selectedIncoterm: value });
  };

  getHistoricalPrice = async () => {
    const { specification } = this.props;
    this.setState({ historicalPriceLoading: true });
    try {
      let response = await SpecificationService.getItemHistoricalPrice(
        specification.id
      );

      this.setState({
        historicalPrice: response.data,
        historicalPriceLoading: false,
      });
    } catch (error) {
      this.setState({ historicalPriceLoading: false });
    }
  };

  render() {
    const { selectedIncoterm, historicalPrice, historicalPriceLoading } =
      this.state;

    return (
      <>
        <Card>
          <Tabs
            className='notification-alerts auction-tabs bid-charts-tabs '
            defaultActiveKey='1'
            onChange={(key) => {
              if (key === 'Historical Price') this.getHistoricalPrice();
            }}
          >
            <TabPane
              className='participant-tab'
              tab={<span>Quote Stats</span>}
              key='Quote Stats'
            >
              {isEmpty(this.props.allBids.bids) ? (
                <NoDataComponent
                  title='No bids found'
                  tip={'No bids found for given RFQ'}
                  fullScreen={false}
                  headerMetadata={false}
                  showButton={false}
                />
              ) : (
                <BidHistoryChart
                  allBids={this.props.allBids}
                  auctionTargetPrice={this.props.auctionTargetPrice}
                  selectedIncoterm={selectedIncoterm}
                  incoterms={this.props.incoterms}
                  handleChange={this.handleChange}
                  lowestBid={this.props.lowestBid}
                  chartId='quote-stats-chart'
                />
              )}
            </TabPane>
            {/* Participants tab ends */}
            {/* Auction History tab */}
            <TabPane
              tab={
                <span>
                  {this.props.specification &&
                    this.props.specification.itemDescription +
                      ' - Historical Trend'}
                </span>
              }
              key='Historical Price'
            >
              {historicalPriceLoading ? (
                <LoadingComponent title='Fetching historical quotes ...' />
              ) : (
                <HistoricalTrend
                  historicalPrice={historicalPrice}
                  auctionTargetPrice={this.props.auctionTargetPrice}
                  item={this.props.specification.itemDescription}
                  allBids={this.props.allBids}
                  lowestBid={this.props.lowestBid}
                  selectedIncoterm={selectedIncoterm}
                />
              )}
            </TabPane>
            {/* Auction History tab end */}
          </Tabs>
        </Card>
      </>
    );
  }
}
export default AuctionStats;
