import '../../constants/RouteConstants';
import './PlannedOrder.css';

import CardWithTitle from '../common/cards/CardWithTitle';
import { Link } from 'react-router-dom';
import PlannedOrderService from 'src/services/PlannedOrderService';
import PlannedOrderTableManager from 'src/utils/tables/PlannedOrderTableManager';
import React from 'react';
import RouteConstants from '../../constants/RouteConstants';
import StringConstants from 'src/constants/StringConstants';
import { Table } from 'antd';

function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}

class PlannedOrderOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPlannedOrders: 0,
      loading: false,
      plannedOrdersData: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    PlannedOrderService.getAllPlannedOrders({
      limit: 10,
      status: StringConstants.PLANNED_ORDER_STATUS.OPEN,
    })
      .then((response) => {
        this.setState({
          loading: false,
          plannedOrdersData: response.data,
          totalPlannedOrders: response.headers['x-total-count'],
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          plannedOrdersData: [],
          totalPlannedOrders: 0,
        });
      });
  }

  getColumnsForTable = () => {
    const { plannedOrdersData } = this.state;
    return PlannedOrderTableManager.getColumnsForImporter(plannedOrdersData);
  };

  render() {
    const { plannedOrdersData, totalPlannedOrders, loading } = this.state;
    return (
      <CardWithTitle
        title='Planned Orders'
        badge={totalPlannedOrders}
        action={
          <Link className='head-box-va' to={RouteConstants.PLANNED_ORDERS}>
            View All
          </Link>
        }
      >
        <Table
          loading={loading}
          locale={{ filterConfirm: 'Apply' }}
          columns={this.getColumnsForTable()}
          dataSource={plannedOrdersData}
          onChange={onChange}
          pagination={false}
          rowKey={(row) => row.id}
          footer={() => (
            <div className='text-right'>
              Showing {plannedOrdersData.length} of {totalPlannedOrders} planned
              orders
            </div>
          )}
        />
      </CardWithTitle>
    );
  }
}
export default PlannedOrderOverview;
