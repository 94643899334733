import './CreateSpecifications.css';

import { Button, Col, Row } from 'antd';
import { camelCase, find, isEmpty, isUndefined, uniq } from 'lodash';

import ApiUtils from 'src/utils/ApiUtils';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import { Form } from 'antd';
import LoadingComponent from 'src/components/common/LoadingComponent';
import MetadataService from 'src/services/MetadataService';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import SecureComponent from 'src/components/common/SecureComponent';
import SpecificationPanels from 'src/components/specification/specificationPanels/SpecificationPanels';
import SpecificationService from 'src/services/SpecificationService';
import SpecificationUtils from 'src/utils/SpecificationUtils';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { getReadableDisplayName } from 'src/utils/DisplayUtils';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

class CloneSpecification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      specification: {},
      customers: [],
      brands: [],
      seafoodList: [],
      acceptableMarketNames: [],
      countryNames: [],
      productForms: [],
      otherDropdowns: {},
      creatingSpec: false,
    };
    this.createSpecForm = React.createRef();
  }

  async componentDidMount() {
    this.fetchPageData();
  }

  fetchPageData = async () => {
    this.setState({ loading: true });
    let specificationRes = await SpecificationService.getSpecificationDetail(
      this.props.match.params.id
    );
    let specification = specificationRes.data;
    let { customers } = await this.fetchSpecificationCustomers();
    let { brands } = await this.fetchSpecificationBrands();
    let { seafoodList, acceptableMarketNames } = await this.fetchSeafoodList();
    let { countryNames } = await this.fetchCountries();
    await this.fetchProductForms(specification.productType);
    let { otherDropdowns } = await this.fetchOtherDropdowns(
      specification.productForm,
      specification.productType
    );

    this.setState({
      customers,
      brands,
      seafoodList,
      acceptableMarketNames,
      countryNames,
      specification,
      otherDropdowns,
      loading: false,
    });
  };

  fetchSpecificationCustomers = async () => {
    try {
      let customersRes = await MetadataService.getSpecificationCustomers();
      return {
        customers: customersRes.data,
      };
    } catch (err) {
      return { customers: [] };
    }
  };

  fetchSpecificationBrands = async () => {
    try {
      let brandsRes = await MetadataService.getSpecificationBrands();
      return {
        brands: brandsRes.data,
      };
    } catch (err) {
      return { brands: [] };
    }
  };

  fetchSeafoodList = async () => {
    try {
      let seafoodListRes = await MetadataService.getSeaFoodList();
      return {
        seafoodList: seafoodListRes.data,
        acceptableMarketNames: uniq(
          seafoodListRes.data.map((item) => item.acceptableMarketName)
        ).sort(),
      };
    } catch (err) {
      return { seaFoodList: [], acceptableMarketNames: [] };
    }
  };

  fetchCountries = async () => {
    try {
      let countriesRes = await MetadataService.getCountries();
      return {
        countryNames: countriesRes.data.map((country) => country.name).sort(),
      };
    } catch (err) {
      return { countryNames: [] };
    }
  };

  fetchProductForms = async (selectedProductType) => {
    let actualSelectedProductType = selectedProductType;
    if (isUndefined(actualSelectedProductType))
      actualSelectedProductType = this.state.selectedProductType;
    let request = {
      productType: actualSelectedProductType,
    };
    let dictionaryDropdownsRes = await MetadataService.getDictionaryDropdowns(
      request
    );
    this.setState({ productForms: dictionaryDropdownsRes.data.product_form });
  };

  fetchOtherDropdowns = async (selectedProductForm, selectedProductType) => {
    const { productForms } = this.state;
    let actualSelectedProductType = selectedProductType;

    if (isUndefined(actualSelectedProductType))
      actualSelectedProductType = this.state.selectedProductType;

    let productForm = find(productForms, function (o) {
      return o.code === selectedProductForm;
    });
    let request = {
      productType: actualSelectedProductType,
      fields: [
        {
          field: productForm && productForm.field,
          code: productForm && productForm.code,
        },
      ],
    };

    let dictionaryDropdownsRes = await MetadataService.getDictionaryDropdowns(
      request
    );

    return { otherDropdowns: dictionaryDropdownsRes.data };
  };

  getOtherPanelData = () => {
    return this.specificationPanels.getData();
  };

  getAdditionalComments = (formValues) => {
    let additionalComments = [];
    StringConstants.SPECIFICATION_FIELDS.COMMENT_TYPES.forEach(
      (commentType) => {
        let updatedComment =
          formValues[
            camelCase(getReadableDisplayName(commentType)) + 'Comments'
          ];

        additionalComments.push({
          commentType,
          comments: updatedComment,
        });
      }
    );
    return additionalComments;
  };

  getCorporateAssurances = (formValues, panelData) => {
    let corporateAssurances = [];
    StringConstants.SPECIFICATION_FIELDS.ASSURANCE_TYPES.forEach(
      (assuranceType) => {
        let updatedCorporateAssuranceValue =
          panelData[camelCase(getReadableDisplayName(assuranceType))];
        let updatedCorporateAssuranceComments =
          formValues[
            camelCase(getReadableDisplayName(assuranceType)) + 'Comments'
          ];

        if (typeof updatedCorporateAssuranceValue === 'object')
          updatedCorporateAssuranceValue.forEach((value) =>
            corporateAssurances.push({
              assuranceType,
              comments: updatedCorporateAssuranceComments,
              value: value,
            })
          );
        else
          corporateAssurances.push({
            assuranceType,
            comments: updatedCorporateAssuranceComments,
            value: updatedCorporateAssuranceValue,
          });
      }
    );
    return corporateAssurances;
  };

  getCountriesAssociated = (formValues) => {
    let countriesAssociated = [];
    StringConstants.SPECIFICATION_FIELDS.ASSOCIATED_COUNTRY_TYPES.forEach(
      (countryType) => {
        let updatedValue =
          formValues[
            camelCase(getReadableDisplayName(countryType)) + 'CountryOfOrigin'
          ];

        updatedValue.forEach((value) =>
          countriesAssociated.push({
            category: countryType,
            country: value,
          })
        );
      }
    );
    return countriesAssociated;
  };

  getSeaFoodList = (selectedAcceptedMarketNames) => {
    const { seafoodList } = this.state;
    let updatedSeafoodList = seafoodList
      .filter((seafoodItem) => {
        return selectedAcceptedMarketNames.includes(
          seafoodItem.acceptableMarketName
        );
      })
      .map((item) => item.id);

    return updatedSeafoodList;
  };

  getStringFields = (formValues, otherFormData) => {
    let updatedStringFields = {};
    StringConstants.SPECIFICATION_FIELDS.UPDATABLE_STRING_FIELDS.forEach(
      (field) => {
        let updatedValue;
        if (field.referFrom === 'form') updatedValue = formValues[field.name];
        else updatedValue = otherFormData[field.name];

        if (field.name === 'moisturePercent' || field.name === 'netUnitSize')
          updatedValue = parseFloat(updatedValue);
        if (field.name === 'noOfUnitsInCase')
          updatedValue = parseInt(updatedValue);

        updatedStringFields[field.name] = updatedValue;
      }
    );

    return updatedStringFields;
  };

  onFormSubmit = async (formValues) => {
    this.setState({ creatingSpec: true });
    const { acceptableMarketName } = formValues;

    let otherFormData = this.getOtherPanelData();

    let additionalComments = this.getAdditionalComments(formValues);

    let corporateAssurances = this.getCorporateAssurances(
      formValues,
      otherFormData
    );

    let countriesAssociated = this.getCountriesAssociated(formValues);

    let seaFoodList = this.getSeaFoodList(acceptableMarketName);

    let createDataRequest = this.getStringFields(formValues, otherFormData);

    createDataRequest.additionalComments = additionalComments;

    createDataRequest.corporateAssurances = corporateAssurances;

    createDataRequest.countriesAssociated = countriesAssociated;
    createDataRequest.seaFoodList = seaFoodList;

    createDataRequest.purchaseBlocked = false;
    createDataRequest.salesBlocked = false;

    if (isEmpty(createDataRequest.treatment)) {
      toast.warning('Please choose a treatment type');
      this.setState({ creatingSpec: false });
      return;
    }

    try {
      SpecificationService.createSpecification(createDataRequest)
        .then((response) => {
          console.log(response);
          this.specificationPanels.uploadDocumentsPanel
            .getUploadComponentRef()
            .uploadDocuments(response.data.id);
        })
        .catch((error) => {
          let errorMessage = ApiUtils.getErrorMessage(error);
          toast.error('Failed to create spec - ' + errorMessage);
          this.setState({ creatingSpec: false });
        });
    } catch (error) {
      console.log(error);
      let errorMessage = ApiUtils.getErrorMessage(error);
      toast.error('Failed to create spec - ' + errorMessage);
      this.setState({ creatingSpec: false });
    }
  };

  onFormFinishFailed = () => {
    toast.warning('Some fields have errors, please verify and re-submit');
  };

  updateFormFields = (updatedFormFields) => {
    this.specForm.setFieldsValue(updatedFormFields);
  };

  navigateToSpecDetail = () => {
    let specId = this.props.match.params.id;
    this.props.history.push(
      UrlGenerator.getUiUrlWithPathParams(RouteConstants.SPECIFICATION_DETAIL, {
        id: specId,
      })
    );
  };

  render() {
    const {
      loading,
      specification,
      customers,
      brands,
      seafoodList,
      acceptableMarketNames,
      countryNames,
      productForms,
      otherDropdowns,
      creatingSpec,
    } = this.state;

    if (loading) return <LoadingComponent />;

    return (
      <>
        <Form
          layout='vertical'
          className='spec-form'
          onFinish={this.onFormSubmit}
          onFinishFailed={this.onFormFinishFailed}
          scrollToFirstError={true}
          ref={(ref) => (this.specForm = ref)}
          initialValues={{
            ...specification,
            companyItemNumber: undefined,
            acceptableMarketName:
              specification.seaFoodList &&
              uniq(
                specification.seaFoodList.map(
                  (item) => item.acceptableMarketName
                )
              ),
            farmOrCatchCountryOfOrigin:
              specification.associatedCountries &&
              specification.associatedCountries
                .filter((o) => o.category === 'FARM_OR_CATCH')
                .map((value) => value.country),
            processingCountryOfOrigin:
              specification.associatedCountries &&
              specification.associatedCountries
                .filter((o) => o.category === 'PROCESSING')
                .map((value) => value.country),
            labelledCountryOfOrigin:
              specification.associatedCountries &&
              specification.associatedCountries
                .filter((o) => o.category === 'LABELLED')
                .map((value) => value.country),
            countryOfOriginComments:
              specification.additionalComments &&
              SpecificationUtils.getComment(
                specification.additionalComments,
                'COUNTRY_OF_ORIGIN'
              ),
            additionalSpeciesInfoComments:
              specification.additionalComments &&
              SpecificationUtils.getComment(
                specification.additionalComments,
                'ADDITIONAL_SPECIES_INFO'
              ),
            treatmentComments:
              specification.additionalComments &&
              SpecificationUtils.getComment(
                specification.additionalComments,
                'TREATMENT'
              ),
            moistureComments:
              specification.additionalComments &&
              SpecificationUtils.getComment(
                specification.additionalComments,
                'MOISTURE'
              ),
            glazeComments:
              specification.additionalComments &&
              SpecificationUtils.getComment(
                specification.additionalComments,
                'GLAZE'
              ),
            minSizeCount: SpecificationUtils.getSizeRangeProperty(
              specification &&
                specification.sizeRange &&
                specification.sizeRange[0],
              'minPieceSize'
            ),
            minSizeCountUom: SpecificationUtils.getSizeRangeProperty(
              specification &&
                specification.sizeRange &&
                specification.sizeRange[0],
              'minUom'
            ),
            maxSizeCount: SpecificationUtils.getSizeRangeProperty(
              specification &&
                specification.sizeRange &&
                specification.sizeRange[0],
              'maxPieceSize'
            ),
            maxSizeCountUom: SpecificationUtils.getSizeRangeProperty(
              specification &&
                specification.sizeRange &&
                specification.sizeRange[0],
              'maxUom'
            ),
            variance: SpecificationUtils.getSizeRangeProperty(
              specification &&
                specification.sizeRange &&
                specification.sizeRange[0],
              'variance'
            ),
            varianceSymbol: SpecificationUtils.getSizeRangeProperty(
              specification &&
                specification.sizeRange &&
                specification.sizeRange[0],
              'varianceSymbol'
            ),
            varianceUom: SpecificationUtils.getSizeRangeProperty(
              specification &&
                specification.sizeRange &&
                specification.sizeRange[0],
              'varianceUom'
            ),
            additionalPhysicalAttributeComments:
              specification.additionalComments &&
              SpecificationUtils.getComment(
                specification.additionalComments,
                'ADDITIONAL_PHYSICAL_ATTRIBUTE'
              ),
            sustainabilityMinReqComments:
              specification.corporateAssurance &&
              SpecificationUtils.getCorporateAssuranceComments(
                specification.corporateAssurance,
                'SUSTAINABILITY_MIN_REQ'
              ),
            facilityFoodSafetyComments:
              specification.corporateAssurance &&
              SpecificationUtils.getCorporateAssuranceComments(
                specification.corporateAssurance,
                'FACILITY_FOOD_SAFETY'
              ),
            socialComplianceComments:
              specification.corporateAssurance &&
              SpecificationUtils.getCorporateAssuranceComments(
                specification.corporateAssurance,
                'SOCIAL_COMPLIANCE'
              ),
            corporateAssuranceComments:
              specification.additionalComments &&
              SpecificationUtils.getComment(
                specification.additionalComments,
                'CORPORATE_ASSURANCE'
              ),
          }}
        >
          <CardWithTitle title='Clone Specification'>
            <SpecificationPanels
              ref={(ref) => (this.specificationPanels = ref)}
              basicItemData={{
                customers,
                brands,
                selectedCustomer: specification.customer,
                selectedBrand: specification.brand,
              }}
              speciesData={{
                acceptableMarketNames,
                countryNames,
                seafoodList,
                selectedSeafoodItems: specification.seaFoodList,
                selectedProductType: specification.productType,
                wildOrFarm: specification.wildOrFarm,
                additionalComments: specification.additionalComments,
                associatedCountries: specification.associatedCountries,
                farmOrCatchCountryOfOrigin:
                  specification.associatedCountries &&
                  SpecificationUtils.getAssociatedCountry(
                    specification.associatedCountries,
                    'FARM_OR_CATCH'
                  ),
                processingCountryOfOrigin:
                  specification.associatedCountries &&
                  SpecificationUtils.getAssociatedCountry(
                    specification.associatedCountries,
                    'PROCESSING'
                  ),
                labelledCountryOfOrigin:
                  specification.associatedCountries &&
                  SpecificationUtils.getAssociatedCountry(
                    specification.associatedCountries,
                    'LABELLED'
                  ),
                selectedProductForm: specification.productForm,
                minSizeCount: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'minPieceSize'
                ),
                minSizeCountUom: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'minUom'
                ),
                maxSizeCount: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'maxPieceSize'
                ),
                maxSizeCountUom: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'maxUom'
                ),
                variance: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'variance'
                ),
                varianceSymbol: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'varianceSymbol'
                ),
                varianceUom: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'varianceUom'
                ),
              }}
              basicItemSizeData={{
                netUnitSize: specification.netUnitSize,
                noOfUnits: specification.noOfUnitsInCase,
                baseUom: specification.baseUom,
              }}
              physicalAttributesData={{
                productForms,
                otherDropdowns,
                selectedTreatment:
                  specification.treatment === '-' ||
                  specification.treatment === 'None, All Natural'
                    ? 'None, All Natural'
                    : specification.treatment &&
                      specification.treatment
                        .split(',')
                        .map((treatment) => treatment.trim()),
                selectedProductType: specification.productType,
                selectedProductForm: specification.productForm,
                additionalComments: specification.additionalComments,
                scaledState:
                  specification &&
                  specification.scaledState &&
                  getReadableDisplayName(specification.scaledState),
                skinState:
                  specification &&
                  specification.skinState &&
                  getReadableDisplayName(specification.skinState),
                boneState:
                  specification &&
                  specification.boneState &&
                  getReadableDisplayName(specification.boneState),
                frozenType:
                  specification &&
                  specification.frozenState &&
                  getReadableDisplayName(specification.frozenState),
                cookedType:
                  specification &&
                  specification.cookedState &&
                  getReadableDisplayName(specification.cookedState),
                minSizeCount: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'minPieceSize'
                ),
                minSizeCountUom: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'minUom'
                ),
                maxSizeCount: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'maxPieceSize'
                ),
                maxSizeCountUom: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'maxUom'
                ),
                variance: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'variance'
                ),
                varianceSymbol: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'varianceSymbol'
                ),
                varianceUom: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'varianceUom'
                ),
                iqfIvp: specification && specification.iqfIvp,
              }}
              corporateAssuranceRequirementsData={{
                selectedSustainabilityRequirements:
                  specification &&
                  specification.corporateAssurance &&
                  specification.corporateAssurance
                    .filter(
                      (assurance) =>
                        assurance.assuranceType === 'SUSTAINABILITY_MIN_REQ'
                    )
                    .map((elt) => elt.value),
                selectedProcessingRequirement:
                  specification &&
                  SpecificationUtils.getCorporateAssuranceValue(
                    specification.corporateAssurance,
                    'FACILITY_FOOD_SAFETY'
                  ),
                selectedComplianceRequirement:
                  specification &&
                  SpecificationUtils.getCorporateAssuranceValue(
                    specification.corporateAssurance,
                    'SOCIAL_COMPLIANCE'
                  ),
                selectedThirdPartyTesting:
                  specification &&
                  SpecificationUtils.getCorporateAssuranceValue(
                    specification.corporateAssurance,
                    'THIRD_PARTY_QA_PARTNER'
                  ),
                wildOrFarm: specification.wildOrFarm,
                corporateAssurance: specification.corporateAssurance,
                additionalComments: specification.additionalComments,
              }}
              uploadDocumentsData={{
                resetLoadingButton: () =>
                  this.setState({ creatingSpec: false }),
                fetchPageData: () =>
                  this.props.history.push(RouteConstants.SPECIFICATIONS),
                successMessage:
                  'Created specification and uploaded templates successfully',
                doNotFetchUploadedDocuments: true,
              }}
              updateFormFields={this.updateFormFields}
            />
          </CardWithTitle>
        </Form>
        <Row>
          <Col span={24} xs={24} lg={24} xl={24}>
            <Form.Item className='specification-input-item'>
              <div className='editprofile-btn-area mt-15 mb-15'>
                <Button
                  className='primary-full mr-15'
                  type='primary'
                  onClick={() => this.specForm.submit()}
                  loading={creatingSpec}
                >
                  Create Specification
                </Button>
                <Button
                  className='cancel-full'
                  onClick={() => this.navigateToSpecDetail()}
                >
                  Cancel
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
}
export default withRouter(
  SecureComponent(
    CloneSpecification,
    [StringConstants.COMPANY_TYPES.IMPORTER],
    [
      {
        name: StringConstants.TEAMS.ACCOUNT_MANAGERS,
        role: StringConstants.TEAM_ROLES.MANAGER,
      },
    ]
  )
);
