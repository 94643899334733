import { Col, Row, Table } from 'antd';
import { isEmpty, isUndefined } from 'lodash';

import ApiUtils from 'src/utils/ApiUtils';
import AuctionTableManager from 'src/utils/tables/AuctionTableManager';
import Card from 'src/components/common/cards/Card';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import CompanyService from 'src/services/CompanyService';
import EmptySection from 'src/components/common/emptySection/EmptySection';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import LoadingComponent from 'src/components/common/LoadingComponent';
import ProcessingPlantAdditionalInformation from 'src/components/plantDetails/ProcessingPlantAdditionalInformation';
import ProcessingPlantDetail from 'src/components/plantDetails/ProcessingPlantDetail';
import ProformaTableManager from 'src/utils/tables/ProformaTableManager';
import PurchaseOrderTableManager from 'src/utils/tables/PurchaseOrderTableManager';
import React from 'react';
import SecureComponent from 'src/components/common/SecureComponent';
import StringConstants from 'src/constants/StringConstants';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';

class PlantHomepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      plantId: null,
      data: {},
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    this.init();
  };

  init = () => {
    const plantId = this.props.match.params.id;
    CompanyService.getProcessingPlantOverview(plantId)
      .then((response) => {
        this.setState({ loading: false, data: response.data, plantId });
      })
      .catch((err) => {
        let errorMessage = 'Failed to fetch plant overview details';
        if (!isUndefined(err)) errorMessage = ApiUtils.getErrorMessage(err);
        toast.error(errorMessage);
        this.setState({ loading: false, data: [], plantId });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined) {
      return false;
    }
    if (this.state.plantId !== this.props.match.params.id) {
      if (!prevState.loading) this.setState({ loading: true });
      this.init();
    }
  }

  getColumnsForPurchaseOrderTable = () => {
    const { data } = this.state;
    if (isEmpty(data) || isUndefined(data.purchaseOrders)) return [];
    return PurchaseOrderTableManager.getColumnsForOverview.call(
      this,
      data.purchaseOrders
    );
  };

  getColumnsForProformaTable = () => {
    const { data } = this.state;
    if (isEmpty(data)) return [];
    return ProformaTableManager.getColumnsForImporter.call(
      this,
      data.proformas
    );
  };

  getColumnsForAuctionTable = () => {
    const { data } = this.state;
    if (isEmpty(data)) return [];
    return AuctionTableManager.getColumnsForPlantOverview.call(
      this,
      data.auctions
    );
  };

  render() {
    const { loading, plantId, data } = this.state;

    if (loading) return <LoadingComponent />;

    return (
      <>
        <HeaderMetadata
          title={`${data && data.company && data.company.name} (${plantId})`}
          description={`Processing plant ${
            data && data.company && data.company.name
          } (${plantId}) `}
        />
        <Row>
          <Col span={24}>
            <Card>
              <ProcessingPlantDetail
                data={data}
                showAdditionalDetails
                refreshData={() => this.init()}
                noteActionsEnabled
              />
              <div
                className='plain-divide'
                style={{ marginTop: 15, marginBottom: 15 }}
              ></div>
              <ProcessingPlantAdditionalInformation
                data={data}
                plantId={plantId}
              />
            </Card>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            {/* Auctions */}
            <CardWithTitle
              title='RFQs'
              badge={!isEmpty(data.auctions) ? data.auctions.length : 0}
            >
              {!isEmpty(data.auctions) && data.auctions.length === 0 ? (
                <EmptySection
                  imageSrc={
                    require('../assets/images/no-data-images/nd-all-auctions.png')
                      .default
                  }
                  title='No RFQs'
                  showButton={false}
                  size='small'
                />
              ) : (
                <>
                  <Table
                    locale={{ filterConfirm: 'Apply' }}
                    columns={this.getColumnsForAuctionTable()}
                    dataSource={isEmpty(data) ? [] : data.auctions}
                    rowKey={(row) => row.id}
                    pagination={
                      data && data.auctions && data.auctions.length >= 10
                    }
                    scroll={{ x: 900 }}
                  />
                </>
              )}
            </CardWithTitle>
          </Col>
          <Col span={24}>
            {/* Proformas */}
            <CardWithTitle
              title='Proformas'
              badge={!isEmpty(data.proformas) ? data.proformas.length : 0}
            >
              {!isEmpty(data.proformas) && data.proformas.length === 0 ? (
                <EmptySection
                  imageSrc={
                    require('../assets/images/no-data-images/nd-proforma.png')
                      .default
                  }
                  title='No Proformas'
                  showButton={false}
                  size='small'
                />
              ) : (
                <>
                  <Table
                    locale={{ filterConfirm: 'Apply' }}
                    columns={this.getColumnsForProformaTable()}
                    dataSource={isEmpty(data) ? [] : data.proformas}
                    rowKey={(row) => row.id}
                    pagination={
                      data && data.proformas && data.proformas.length >= 10
                    }
                  />
                </>
              )}
            </CardWithTitle>
          </Col>
          <Col span={24}>
            {/* Purchase Order */}
            <CardWithTitle
              title='Purchase Orders'
              badge={
                !isEmpty(data.purchaseOrders) ? data.purchaseOrders.length : 0
              }
            >
              {!isEmpty(data.purchaseOrders) &&
              data.purchaseOrders.length === 0 ? (
                <EmptySection
                  imageSrc={
                    require('../assets/images/no-data-images/nd-planned-order.png')
                      .default
                  }
                  title='No Purhcase Orders'
                  showButton={false}
                  size='small'
                />
              ) : (
                <>
                  <Table
                    locale={{ filterConfirm: 'Apply' }}
                    columns={this.getColumnsForPurchaseOrderTable()}
                    dataSource={isEmpty(data) ? [] : data.purchaseOrders}
                    rowKey={(row) => row.id}
                    pagination={
                      data &&
                      data.purchaseOrders &&
                      data.purchaseOrders.length >= 10
                    }
                  />
                </>
              )}
            </CardWithTitle>
          </Col>
        </Row>
        {/* <Row>
          <Col span={24}>
            <Card>
              <Tabs
              >
                <TabPane
                  tab={<span>Quote Analysis</span>}
                  key='Quote Analysis'
                ></TabPane>
                <TabPane tab={<span>RFQ Stats</span>} key='RFQ Stats'></TabPane>
              </Tabs>
            </Card>
            <CardWithTitle title='Quote and RFQ Analysis'>
              <Row>
                <Col span={24}>
                  <p className='sub-heading'> Quote Analysis </p>
                </Col>
                <Col span={24}>
                  <div className='plain-divide'> </div>
                  <p className='sub-heading'> RFQ Analysis </p>
                </Col>
              </Row>
            </CardWithTitle>
          </Col>
        </Row> */}
      </>
    );
  }
}

export default SecureComponent(withRouter(PlantHomepage), [
  StringConstants.COMPANY_TYPES.IMPORTER,
]);
