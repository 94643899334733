import { Button, Col, Row, Space } from 'antd';
import { Prompt, withRouter } from 'react-router-dom';
import React, { Component } from 'react';

import AuctionService from 'src/services/AuctionService';
import AuctionSteps from './steps/AuctionSteps';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import ChoosePlantsTable from './chooseCompliantPlants/ChoosePlantsTable';
import CompanyService from 'src/services/CompanyService';
import ConfirmationModal from 'src/modals/common/ConfirmationModal';
import LoadingComponent from 'src/components/common/LoadingComponent';
import NoDataComponent from 'src/components/common/NoDataComponent';
import RouteConstants from 'src/constants/RouteConstants';
import SelectIncoterm from 'src/components/incoterm/SelectIncoterm';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { setIncompleteAuctionDraft } from 'src/actions/route';
import { toast } from 'react-toastify';
import withSecureComponent from 'src/components/common/WithSecureComponent';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';

class ChooseCompliantPlants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      updateLoading: false,
      plants: [],
      auctionDetails: {},
      auctionId: null,
      draftAuctionPromptEnabled: true,
      discardAuctionModalVisible: false,
    };
  }

  componentDidMount() {
    const auctionId = this.props.match.params.id;
    this.setState({ loading: true, auctionId });

    if (isEmpty(this.props.route)) this.props.setIncompleteAuctionDraft(true);

    AuctionService.getAuctionDetails(auctionId)
      .then((response) => {
        if (response.data.status === 'DRAFT') {
          const auctionDetails = response.data;
          this.setState({ auctionDetails });
        } else {
          throw response;
        }
        CompanyService.getAllProcessingPlants()
          .then((response) => {
            this.setState({ loading: false, plants: response.data });
          })
          .catch((err) => {
            this.setState({ loading: false, plants: [] });
          });
      })
      .catch((error) => {
        toast.error(error.data.message);
        this.setState({ loading: false, auctionDetails: {} });
      });
  }

  updateCompliantPlantIncoterm = () => {
    let selectedPlants = this.choosePlants.getSelectedPlants();
    selectedPlants = selectedPlants.map((value) => {
      return { id: value };
    });

    let selectedIncoterms = this.selectIncoterm.getSelectedIncoterms();
    let enteredIncotermCharge = this.selectIncoterm.getEnteredIncotermCharge();

    if (selectedIncoterms.length > 1 && enteredIncotermCharge === 0) {
      toast.warning('Estimate Customs Brokerage Costs cannot be 0');
      return;
    }

    if (isEmpty(selectedPlants) || isEmpty(this.selectIncoterm)) {
      toast.warning('Please select all mandatory fields');
      return;
    } else {
      this.setState({ updateLoading: true });
      const data = {
        ':auctionId': this.state.auctionId,
        auctionIncoterms: selectedIncoterms,
        biddingCompanies: selectedPlants,
      };
      AuctionService.updateCompliantPlantsAndIncoterms(data)
        .then((response) => {
          this.setState(
            { updateLoading: false, draftAuctionPromptEnabled: false },
            () => {
              this.props.history.push(
                UrlGenerator.getUiUrlWithPathParams(
                  RouteConstants.AUCTION_STEP_3,
                  {
                    id: this.state.auctionId,
                  }
                )
              );
            }
          );
        })
        .catch((err) => {
          this.setState({ updateLoading: false });
          toast.error('Cannot save the details at the moment');
        });
    }
  };

  confirmDiscardAuction = () => {
    this.setState({ discardAuctionModalVisible: true });
  };

  cancelDiscardAuction = () => {
    this.setState({ discardAuctionModalVisible: false });
  };

  discardAuction = () => {
    const { auctionId } = this.state;
    this.setState({ loading: true });
    AuctionService.discardAuction(auctionId)
      .then(() => {
        toast.success('RFQ Discarded');
        this.setState(
          { loading: false, draftAuctionPromptEnabled: false },
          () => {
            this.props.history.replace(RouteConstants.PLANNED_ORDERS);
          }
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error('Failed to discard RFQ, try again later');
      });
  };

  render() {
    const {
      loading,
      updateLoading,
      auctionId,
      auctionDetails,
      plants,
      draftAuctionPromptEnabled,
      discardAuctionModalVisible,
    } = this.state;

    return loading ? (
      <LoadingComponent />
    ) : isEmpty(auctionDetails) ? (
      <NoDataComponent
        title='RFQ not found'
        tip={`RFQ (${auctionId}) does not exist`}
        fullScreen={true}
        headerMetadata={true}
      />
    ) : (
      <>
        <HeaderMetadata
          title={`Choose Compliant Plants | Create RFQ`}
          description=''
        />
        <Row>
          <AuctionSteps step={2} />
          <Col span={24} xs={24} lg={24} xl={24}>
            <ChoosePlantsTable
              data={plants}
              ref={(ref) => (this.choosePlants = ref)}
              selectedData={auctionDetails.biddingCompanies}
              selectMultiple={true}
            />
          </Col>
          <Col span={24} xs={24} lg={24} xl={24}>
            <CardWithTitle title='Select Eligible Incoterms'>
              <SelectIncoterm
                ref={(ref) => (this.selectIncoterm = ref)}
                selectedData={auctionDetails.auctionIncoterms}
              />
            </CardWithTitle>
          </Col>
          <Col span={24} xs={24} lg={24} xl={24}>
            <div className='mv-40 steps-action'>
              <Space size={24}>
                <Button
                  className='btn-secondary-danger'
                  loading={loading}
                  onClick={this.confirmDiscardAuction}
                >
                  Discard RFQ
                </Button>
                <Button
                  type='primary'
                  className='step-nav-btn'
                  loading={updateLoading}
                  onClick={this.updateCompliantPlantIncoterm}
                >
                  Proceed
                </Button>
              </Space>
            </div>
          </Col>
          <ConfirmationModal
            visible={discardAuctionModalVisible}
            negativeAction={true}
            heading='Discard RFQ'
            title='Are you sure you want to discard this RFQ?'
            description='This action is permanent and cannot be undone. This would cancel the RFQ and mark the planned orders available for other RFQs.'
            okText='Yes, Discard'
            onSubmit={this.discardAuction}
            onCancel={this.cancelDiscardAuction}
          />
          {draftAuctionPromptEnabled ? (
            <Prompt
              when={
                !isEmpty(this.props.route) &&
                this.props.route.isDraftAuctionIncomplete === true
              }
              message='Are you sure you want to leave, this RFQ will be in draft'
            />
          ) : null}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    route: state.route,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIncompleteAuctionDraft: (payload) =>
      dispatch(setIncompleteAuctionDraft(payload)),
  };
};

export default compose(
  withSecureComponent(
    [StringConstants.COMPANY_TYPES.IMPORTER],
    [
      {
        name: StringConstants.TEAMS.SOURCING,
        role: StringConstants.TEAM_ROLES.MANAGER,
      },
    ]
  ),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ChooseCompliantPlants);
