import ApiManager from 'src/api/ApiManager';
import HttpConstants from 'src/constants/HttpConstants';
import UrlConstants from 'src/constants/UrlConstants';
import moment from 'moment';

const ReportService = {
  getAllSpecificationsForDropdown: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_SPECIFICATIONS_DROPDOWN.USECASE,
      HttpConstants.GET_METHOD
    );
  },
  getSourcingTrendForSpec: (specificationId, startDate, endDate) => {
    let data = {
      ':id': specificationId,
    };
    if (startDate && endDate) {
      data.startDate = moment(startDate).startOf('day').toISOString();
      data.endDate = moment(endDate).endOf('day').toISOString();
    }

    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_SOURCING_TREND_FOR_SPEC.USECASE,
      HttpConstants.GET_METHOD,
      data
    );
  },
  getInventoryPipeline: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_INVENTORY_PIPELINE_FOR_SPECS.USECASE,
      HttpConstants.GET_METHOD
    );
  },
};

export default ReportService;
