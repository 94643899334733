import './RouteMap.css';

import { Map, Marker, Polyline, TileLayer, ZoomControl } from 'react-leaflet';
import React, { useEffect, useState } from 'react';

import ContainerTrackingConstants from 'src/constants/ContainerTrackingConstants';
import MapConstants from 'src/constants/MapConstants';
import PointMarker from './PointMarker';
import { findUpcomingEvent } from 'src/utils/PurchaseOrderUtils';

const RouteMap = (props) => {
  const { attribution, url } = MapConstants.tileLayer;

  const { overlayAttribution, overlayUrl } = MapConstants.overlayLayer;

  const [L, setL] = useState(null);

  const [mapCenterPoint, setMapCenter] = useState(null);

  const [mapZoomLevel, setMapZoom] = useState(null);

  const { pathData, events, hideAnimation } = props;

  const eventsLength = events ? events.length : 0;

  let newEvents = events ? [...events] : [];

  for (var i = 0; i < eventsLength; i++) {
    newEvents.splice(i * 2 + 1, 0, 'Transit Event');
  }

  let { upcomingStep } = findUpcomingEvent(newEvents, new Date());

  const resetMapCenter = (position, lastPoint) => {
    setMapCenter(position);
    setMapZoom(3);

    if (lastPoint) {
      setTimeout(function () {
        setMapCenter(null);
        setMapZoom(null);
      }, 1000);
    }
  };

  useEffect(() => {
    const L = require('leaflet');
    setL(L);
  }, []);

  if (L) {
    let mapCenter =
      mapCenterPoint === null
        ? pathData
          ? pathData.mapCenter
          : [0, -90]
        : mapCenterPoint;

    let mapZoom = mapZoomLevel === null ? 2 : mapZoomLevel;

    const mapZone = pathData ? pathData.mapZone : 'default';

    const greenIcon = L.icon(MapConstants.markerIcons.greenIcon);

    const sourceIcon = L.icon(MapConstants.markerIcons.sourceIcon);

    const destinationIcon = L.icon(MapConstants.markerIcons.destinationIcon);

    const currentLocationIcon = L.icon(
      MapConstants.markerIcons.currentLocationIcon
    );

    const mapBounds =
      mapZone === 'wrap'
        ? L.latLngBounds([-90, 0], [90, 360])
        : L.latLngBounds([-90, -180], [90, 180]);
    return (
      <Map
        style={{ height: '100%', width: '100%' }}
        zoom={mapZoom}
        center={mapCenter}
        maxBounds={mapBounds}
        attributionControl={false}
        zoomControl={false}
        minZoom={L.Browser.mobile ? 1 : 2}
        maxZoom={L.Browser.mobile ? 4 : 12}
      >
        <TileLayer attribution={attribution} url={url}></TileLayer>
        <TileLayer
          attribution={overlayAttribution}
          url={overlayUrl}
        ></TileLayer>
        {pathData
          ? Object.keys(pathData.path).map((pathType, index) => {
              /* Render route in map for currently selected container */

              return Object.keys(pathData.path[pathType].path).map(
                (meridianPath, index) => {
                  return (
                    <Polyline
                      positions={pathData.path[pathType].path[meridianPath]}
                      color={pathData.path[pathType].color}
                      key={meridianPath + '_' + pathType + '_' + index}
                    />
                  );
                }
              );
            })
          : null}
        {pathData /* Render appropriate marker icon for each major point in route */
          ? pathData.points.map((point, index) => {
              let icon = greenIcon;
              if (index === 0) icon = sourceIcon;
              if (index === pathData.points.length - 1) icon = destinationIcon;
              if (point.lat && point.lng)
                return (
                  <PointMarker
                    key={index}
                    hideAnimation={hideAnimation}
                    position={{ lat: point.lat, lng: point.lng }}
                    name={point.name}
                    icon={icon}
                    pointEvents={
                      newEvents.length > 0 ? newEvents[index * 2] : undefined
                    }
                    isPointArrived={index < upcomingStep / 2}
                    isSourcePoint={index === 0}
                    isDestinationPoint={index === pathData.points.length - 1}
                    renderAfter={index === 0 ? 500 : index * 1500}
                    resetCenter={(position, lastPoint) =>
                      resetMapCenter(position, lastPoint)
                    }
                    closePopup={index === pathData.points.length - 1}
                  />
                );
              else return null;
            })
          : null}

        {pathData ? (
          pathData[ContainerTrackingConstants.TrackingStatus] !==
          ContainerTrackingConstants.TrackingStatusTypes.Arrived ? (
            pathData.lastContainerCoord.lat &&
            pathData.lastContainerCoord.lng ? (
              <Marker
                position={pathData.lastContainerCoord}
                icon={currentLocationIcon}
              />
            ) : null
          ) : null
        ) : null}
        <ZoomControl position='bottomleft' />
      </Map>
    );
  }
  return null;
};

export default RouteMap;
