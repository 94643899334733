import './SearchResult.css';

import { Button } from 'antd';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { lowerCase } from 'lodash';
import { withRouter } from 'react-router-dom';

class SearchResultList extends React.Component {
  renderHighlightedText = (text, searchText, index) => {
    return (
      <>
        {text.substring(0, index)}
        <span className='color-blue' style={{ fontWeight: 'bold' }}>
          {text.substring(index, index + searchText.length)}
        </span>
        {text.substring(index + searchText.length)}
      </>
    );
  };

  renderRow = (index, title, subTitle, onClickFn) => {
    const { searchText } = this.props;
    var titleIndex = lowerCase(title).indexOf(lowerCase(searchText));
    var subTitleIndex = subTitle
      .toLowerCase()
      .indexOf(searchText.toLowerCase());

    return (
      <div className='search-result-row' key={index}>
        <div className='search-result-row-left'>
          <p className='search-result-name'>
            {titleIndex >= 0
              ? this.renderHighlightedText(title, searchText, titleIndex)
              : title}
            <br />
            <span className='search-result-name-sub'>
              {subTitleIndex >= 0
                ? this.renderHighlightedText(
                    subTitle,
                    searchText,
                    subTitleIndex
                  )
                : subTitle}
            </span>
          </p>
        </div>
        <div className='search-result-row-right'>
          <Button
            className='heading-cta head-cta-dis'
            type='primary'
            onClick={onClickFn}
          >
            View
          </Button>
        </div>
      </div>
    );
  };

  renderSpecificationRow = (row, index) => {
    return this.renderRow(
      index,
      row.description,
      `${row.id} | ${row.companyItemNumber}`,
      () => {
        this.props.history.push(
          UrlGenerator.getUiUrlWithPathParams(
            RouteConstants.SPECIFICATION_DETAIL,
            { id: row.id }
          )
        );
        this.props.onClose();
      }
    );
  };

  renderCompanyRow = (row, index) => {
    return this.renderRow(index, row.name, `${row.id}`, () => {
      this.props.history.push(
        UrlGenerator.getUiUrlWithPathParams(RouteConstants.PLANT_HOMEPAGE, {
          id: row.id,
        })
      );
      this.props.onClose();
    });
  };

  renderPlannedOrderRow = (row, index) => {
    return this.renderRow(
      index,
      row.specDescription,
      `${row.id} | ${row.customer}`,
      () => {
        this.props.history.push(
          UrlGenerator.getUiUrlWithPathParams(
            RouteConstants.PLANNED_ORDER_DETAIL,
            {
              id: row.id,
            }
          )
        );
        this.props.onClose();
      }
    );
  };

  renderAuctionRow = (row, index) => {
    return this.renderRow(
      index,
      row.specDescription,
      `${row.id} | ${row.ownerId}`,
      () => {
        this.props.history.push(
          UrlGenerator.getUiUrlWithPathParams(RouteConstants.AUCTION, {
            id: row.id,
          })
        );
        this.props.onClose();
      }
    );
  };

  renderProformaRow = (row, index) => {
    return this.renderRow(
      index,
      row.id,
      `${row.specDescription} ${row.auctionId ? '|' + row.auctionId : ''}`,
      () => {
        this.props.history.push(
          UrlGenerator.getUiUrlWithPathParams(RouteConstants.PROFORMA_DETAIL, {
            id: row.id,
          })
        );
        this.props.onClose();
      }
    );
  };

  render() {
    const { title, results } = this.props;
    return (
      <>
        <p className='nav-result-heading'>
          {title === 'Auction' ? 'RFQ' : title}
        </p>
        <div>
          {results.map((result, index) => {
            if (title === 'specification') {
              return this.renderSpecificationRow(result, index);
            } else if (title === 'company') {
              return this.renderCompanyRow(result, index);
            } else if (title === 'plannedOrder') {
              return this.renderPlannedOrderRow(result, index);
            } else if (title === 'auction') {
              return this.renderAuctionRow(result, index);
            } else if (title === 'proforma') {
              return this.renderProformaRow(result, index);
            }
            return (
              <div className='search-result-row' key={index}>
                <div className='search-result-row-left'>
                  <p className='search-result-name'>
                    {/* SC <span className='color-blue'>Shrimp</span> Ring 8/20oz IQF */}
                    {result.description}
                    <br />
                    <span className='search-result-name-sub'>
                      {result.id} | {result.companyItemNumber}
                    </span>
                  </p>
                </div>
                <div className='search-result-row-right'>
                  <Button className='heading-cta head-cta-dis' type='primary'>
                    View
                  </Button>
                </div>
              </div>
            );
          })}
        </div>

        {/* <p className='see-result-cta'> {this.props.all_result_cta}</p> */}
      </>
    );
  }
}
export default withRouter(SearchResultList);
