import { Badge, Dropdown, Menu } from 'antd';

import NotificationOverlay from './navNotification/NotificationOverlay';
import NotificationService from 'src/services/NotificationService';
import React from 'react';
import { findIndex } from 'lodash';

// import {
//   CheckCircleOutlined,
//   MailOutlined,
//   WarningOutlined,
// } from '@ant-design/icons';

// const getIcon = (icon) => {
//   switch (icon) {
//     case 'mail':
//       return <MailOutlined />;
//     case 'alert':
//       return <WarningOutlined />;
//     case 'check':
//       return <CheckCircleOutlined />;
//     default:
//       return <MailOutlined />;
//   }
// };

// const getNotificationBody = (list) => {
//   return list.length > 0 ? (
//     <List
//       size='small'
//       itemLayout='horizontal'
//       dataSource={list}
//       renderItem={(item) => (
//         <List.Item className='list-clickable'>
//           <div className='pr-3'>
//             {item.img ? (
//               <Avatar src={`/img/avatars/${item.img}`} />
//             ) : (
//               <Avatar
//                 className={`ant-avatar-${item.type}`}
//                 icon={getIcon(item.icon)}
//               />
//             )}
//           </div>
//           <div className='mr-3'>
//             <span className='font-weight-bold text-dark'>{item.name} </span>
//             <span className='text-gray-light'>{item.desc}</span>
//           </div>
//           <small className='ml-auto'>{item.time}</small>
//         </List.Item>
//       )}
//     />
//   ) : (
//     <div className='empty-notification'>
//       <img src='' alt='empty' />
//       <p className='mt-3'>You have viewed all notifications</p>
//     </div>
//   );
// };

class NavNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      notifications: [],
      visible: false,
    };
  }

  componentDidMount() {
    this.fetchNotifications();
    this.notificationInterval = setInterval(
      this.fetchNotifications,
      5 * 60 * 1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.notificationInterval);
  }

  fetchNotifications = () => {
    this.setState({ loading: true });
    NotificationService.getAllNotifications()
      .then((response) => {
        this.setState({ notifications: response.data, loading: false });
      })
      .catch(() => this.setState({ loading: false }));
  };

  markNotificationRead = (id) => {
    const { notifications } = this.state;
    const index = findIndex(notifications, { id });
    if (index >= 0) {
      notifications.splice(index, 1);
      this.setState({ notifications, visible: false });
    }
  };

  markAllNotificationsRead = () => {
    this.setState({ loading: true, visible: false });
    NotificationService.markAllNotificationsRead()
      .then(() => {
        this.setState({ notifications: [], loading: false });
      })
      .catch(() => this.setState({ loading: false }));
  };

  render() {
    const { loading, notifications, visible } = this.state;
    return (
      <Dropdown
        placement='bottomRight'
        overlay={
          <NotificationOverlay
            loading={loading}
            notifications={notifications}
            markNotificationRead={this.markNotificationRead}
            markAllNotificationsRead={this.markAllNotificationsRead}
          />
        }
        trigger={['click']}
        onVisibleChange={(value) => this.setState({ visible: value })}
        visible={visible}
      >
        <Menu className='notification-menu' mode='horizontal'>
          <Menu.Item>
            <Badge count={notifications.length}>
              <img
                src={
                  require('../../../../assets/images/notification-icon.svg')
                    .default
                }
                alt='Notifications'
              />
            </Badge>
          </Menu.Item>
        </Menu>
      </Dropdown>
    );
  }
}

export default NavNotification;
