import PropTypes from 'prop-types';
import React from 'react';
import UploadFilesComponent from 'src/components/common/UploadFilesComponent';

class UploadDocuments extends React.Component {
  static propTypes = {
    specId: PropTypes.string.isRequired,
    resetLoadingButton: PropTypes.func,
    fetchPageData: PropTypes.func,
    successMessage: PropTypes.string,
    doNotFetchUploadedDocuments: PropTypes.bool,
  };

  getData = () => {
    return this.uploadFilesRef.getData();
  };

  getUploadComponentRef = () => {
    return this.uploadFilesRef;
  };

  render() {
    const { specId, successMessage, doNotFetchUploadedDocuments } = this.props;

    return (
      <UploadFilesComponent
        ref={(ref) => (this.uploadFilesRef = ref)}
        entity='SPECIFICATION'
        entityId={specId}
        refreshData={this.props.fetchPageData}
        resetLoadingButton={() => this.props.resetLoadingButton()}
        isPartOfForm={true}
        successMessage={successMessage}
        doNotFetchUploadedDocuments={doNotFetchUploadedDocuments}
      />
    );
  }
}

export default UploadDocuments;
