import React, { Component } from 'react';
import { isUserImporter, isUserPlant } from 'src/helpers/user';

import BuyerAuctions from './buyer/BuyerAuctions';
import NoAccessComponent from 'src/components/common/NoAccessComponent';
import ProcessingPlantAuctions from './plants/ProcessingPlantAuctions';
import SecureComponent from 'src/components/common/SecureComponent';

class Auctions extends Component {
  render() {
    if (isUserImporter()) return <BuyerAuctions />;
    else if (isUserPlant()) return <ProcessingPlantAuctions />;
    else return <NoAccessComponent />;
  }
}

export default SecureComponent(Auctions);
