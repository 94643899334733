import ApiManager from 'src/api/ApiManager';
import HttpConstants from 'src/constants/HttpConstants';
import UrlConstants from 'src/constants/UrlConstants';

const SearchService = {
  getSearchResults: (text) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_SEARCH_RESULTS.USECASE,
      HttpConstants.GET_METHOD,
      {
        searchText: text,
      }
    );
  },
};

export default SearchService;
