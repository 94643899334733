import { Link, withRouter } from 'react-router-dom';

import EmptySection from 'src/components/common/emptySection/EmptySection';
import ProformaService from 'src/services/ProformaService';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import StringConstants from 'src/constants/StringConstants';
import { Table } from 'antd';
import Title from '../../common/typography/Title';
import UrlGenerator from 'src/api/UrlGenerator';
import { getBidDisplayText } from 'src/utils/DisplayUtils';

class OpenProformaOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      proformas: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    ProformaService.getAllProformas({
      limit: 5,
      status: StringConstants.PROFORMA_STATUS.OPEN,
    })
      .then((response) => {
        this.setState({ loading: false, proformas: response.data });
      })
      .catch((err) => {
        this.setState({ loading: false, proformas: [] });
      });
  }

  getColumnsForTable = () => {
    const columns = [
      {
        title: 'Item',
        key: 'id',
        render: ({ specification, id }) => (
          <>
            <strong>{specification.itemDescription}</strong>
            <br />
            <span className='spantext'>{id}</span>
          </>
        ),
      },
      {
        title: 'Total FCLs',
        dataIndex: 'totalFCL',
        key: 'totalFCL',
        render: (totalFCL) => <div className='ml-15'>{totalFCL}</div>,
      },
      {
        title: 'My Quote',
        key: 'bidValue',
        render: ({ bidValue, bidIncoterm }) => {
          return (
            <strong>{getBidDisplayText(bidValue, bidIncoterm.code)}</strong>
          );
        },
      },
      {
        title: 'Invited By',
        dataIndex: 'importer',
        key: 'importer',
        render: (importer) => importer.name,
      },
      {
        title: '',
        key: 'action',
        render: (record) => (
          <div className='text-center'>
            <Link
              className='auctionoverview-cta ant-btn-primary'
              to={UrlGenerator.getUiUrlWithPathParams(
                RouteConstants.PROFORMA_DETAIL,
                {
                  id: record.id,
                }
              )}
            >
              View
            </Link>
          </div>
        ),
      },
    ];
    return columns;
  };

  render() {
    const { loading, proformas } = this.state;
    return (
      <div className='auctionoverview ca-card-light h-100'>
        <Title
          title='Open Proformas'
          action={
            proformas.length > 0 ? (
              <Link
                className='head-box-va'
                to={UrlGenerator.getUiUrlWithQueryParams(
                  RouteConstants.PROFORMAS,
                  {
                    status: StringConstants.PROFORMA_STATUS.OPEN,
                  }
                )}
              >
                View All
              </Link>
            ) : null
          }
        />
        {proformas.length > 0 ? (
          <Table
            className='auctionoverview'
            columns={this.getColumnsForTable()}
            loading={loading}
            dataSource={proformas}
            pagination={false}
            scroll={{ y: 200 }}
            rowKey={(row) => row.id}
          />
        ) : (
          <EmptySection
            imageSrc={
              require('../../../assets/images/no-data-images/nd-bid.png')
                .default
            }
            title='There are no proformas at the moment'
            showButton={false}
          />
        )}
      </div>
    );
  }
}
export default withRouter(OpenProformaOverview);
