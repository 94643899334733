import './ProcessingPlantAuctionDetails.css';

import { Button, Col, Row, Space } from 'antd';
import { isEmpty, isUndefined } from 'lodash';
import { isUserManagerOfTeam, isUserPlant } from 'src/helpers/user';

import ApiUtils from 'src/utils/ApiUtils';
import AuctionService from 'src/services/AuctionService';
import AuctionUtils from 'src/utils/AuctionUtils';
import BidService from 'src/services/BidService';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import ConfirmationModal from 'src/modals/common/ConfirmationModal';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import { Link } from 'react-router-dom';
import LoadingComponent from 'src/components/common/LoadingComponent';
import NoAccessComponent from 'src/components/common/NoAccessComponent';
import NoDataComponent from 'src/components/common/NoDataComponent';
import PlannedOrder from 'src/components/plannedOrder/PlannedOrder';
import React from 'react';
import ReviewMultiplePlannedOrders from '../buyer/create/reviewAuctionPlannedOrders/ReviewMultiplePlannedOrders';
import RouteConstants from 'src/constants/RouteConstants';
import Specification from 'src/components/specification/Specification';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { connect } from 'react-redux';
import { isUserSubCompany } from 'src/utils/auth/AuthUtils';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';

class ProcessingPlantAuctionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      auctionId: this.props.match.params.id,
      auctionDetails: this.props.auctionDetails,
      withdrawFromAuctionModalVisible: false,
      readOnly: this.props.readOnly,
      bidDetails: this.props.bidDetails,
    };
  }

  init() {
    const auctionId = this.props.match.params.id;
    const plantId = this.props.user.company.id;

    this.setState({ loading: true, auctionId });

    const { auctionDetails } = this.state;

    if (isUndefined(auctionDetails) || auctionDetails.id !== auctionId) {
      AuctionService.getAuctionDetails(auctionId)
        .then((response) => {
          if (response.data.status !== StringConstants.AUCTION_STATUS.DRAFT) {
            const auctionDetails = response.data;
            auctionDetails.auctionPlannedOrders.forEach(
              (pl) => (pl.specification = auctionDetails.specification)
            );

            this.setState({ auctionDetails }, () => {
              BidService.getBidDetails(auctionId, plantId).then((response) => {
                let bidDetails = response.data;
                this.setState({ bidDetails, loading: false });
              });
            });
          } else throw response;
        })
        .catch(() => {
          toast.error('No RFQ Found');
          this.setState({ loading: false, auctionDetails: {} });
        });
    } else this.setState({ loading: false });
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined) {
      return false;
    }
    if (this.state.auctionId !== this.props.match.params.id) {
      this.init();
    }
  }

  withdrawFromAuction = () => {
    const { auctionId } = this.state;
    AuctionService.withdrawFromAuction(auctionId)
      .then(() => {
        toast.success('Withdrew from RFQ successfully');
        this.setState({ withdrawFromAuctionModalVisible: false }, () => {
          this.props.history.push(RouteConstants.AUCTIONS);
        });
      })
      .catch((error) => {
        let errorMessage = ApiUtils.getErrorMessage(
          error,
          'Failed to withdraw from RFQ'
        );
        toast.error(errorMessage);
        this.setState({ withdrawFromAuctionModalVisible: false });
      });
  };

  cancelWithdrawFromAuction = () => {
    this.setState({ withdrawFromAuctionModalVisible: false });
  };

  render() {
    const {
      loading,
      auctionDetails,
      bidDetails,
      auctionId,
      withdrawFromAuctionModalVisible,
      readOnly,
    } = this.state;

    return loading ? (
      <LoadingComponent />
    ) : !readOnly &&
      bidDetails &&
      bidDetails.bids &&
      bidDetails.bids.length > 0 ? (
      <NoAccessComponent
        title='RFQ already entered'
        tip={`You have already entered RFQ (${auctionId}) and placed a quote`}
        fullScreen={true}
        headerMetadata={true}
      />
    ) : (
      <>
        <HeaderMetadata title={`${auctionId} (RFQ)`} description='' />

        {isEmpty(auctionDetails) ? (
          <NoDataComponent
            title='RFQ not found'
            tip={`RFQ (${auctionId}) does not exist`}
            fullScreen={true}
            headerMetadata={true}
          />
        ) : (
          <>
            {!readOnly &&
              AuctionUtils.isActiveAuction(auctionDetails.status) &&
              isUserPlant() &&
              !isUserSubCompany() &&
              isUserManagerOfTeam(StringConstants.TEAMS.SOURCING) && (
                <Row align='start'>
                  <Col className='mb-15'>
                    <Space>
                      <Link
                        to={UrlGenerator.getUiUrlWithPathParams(
                          RouteConstants.PLACE_BID,
                          { id: auctionId }
                        )}
                      >
                        <Button className='primary-button enter-auction-button'>
                          Enter RFQ
                        </Button>
                      </Link>
                      <Button
                        className='cancel-outlined-button not-interested-button'
                        onClick={() =>
                          this.setState({
                            withdrawFromAuctionModalVisible: true,
                          })
                        }
                      >
                        Not Interested
                      </Button>
                    </Space>
                  </Col>
                </Row>
              )}
            <Row>
              <Col className='mb-10' span={24} xs={24} lg={24} xl={24}>
                <CardWithTitle title='RFQ Details'>
                  {auctionDetails.auctionPlannedOrders.length === 1 ? (
                    <PlannedOrder
                      data={{
                        ...auctionDetails.auctionPlannedOrders[0],
                        auction: {
                          targetPrice: auctionDetails.targetPrice,
                          endAt: auctionDetails.endAt,
                          currencyCode: auctionDetails.currencyCode,
                        },
                      }}
                      showAuctionDetails={false}
                      entity='Auction'
                      showImage={false}
                    />
                  ) : (
                    <>
                      <ReviewMultiplePlannedOrders
                        data={auctionDetails}
                        readOnly={true}
                        showAuctionDetails={false}
                        entity='Auction'
                      />
                    </>
                  )}
                </CardWithTitle>
              </Col>
            </Row>
            <Row>
              <Col span={24} xs={24} lg={24} xl={24}>
                <Specification
                  data={auctionDetails.specification}
                  entity={{
                    type: 'AUCTION',
                    id: auctionDetails.id,
                  }}
                />
              </Col>
            </Row>
            <ConfirmationModal
              visible={withdrawFromAuctionModalVisible}
              negativeAction={true}
              heading='Withdraw from RFQ'
              title='Are you sure you want to withdraw from this RFQ?'
              description='This action is permanent and cannot be undone. This would remove you permanently from the RFQ and you cannot be added back'
              okText='Yes, Withdraw'
              onSubmit={this.withdrawFromAuction}
              onCancel={this.cancelWithdrawFromAuction}
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(ProcessingPlantAuctionDetails));
