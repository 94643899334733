import React, { Component } from 'react';

import PropTypes from 'prop-types';
import ReportsTableManager from 'src/utils/tables/ReportsTableManager';
import { Table } from 'antd';
import { forEach } from 'lodash';

class PipelineReportTable extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    pipelineData: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      pipelineReport: [],
    };
  }

  componentDidMount() {
    this.transformData();
  }

  transformData = () => {
    const { pipelineData } = this.props;
    const processedData = [];
    forEach(pipelineData, (record) => {
      let obj = {
        itemNumber: record.specification.companyItemNumber,
        itemDescription: record.specification.itemDescription,
        productType: record.specification.productType,
        productForm: record.specification.productForm,
      };
      forEach(record.pipelineItems, (item) => {
        obj[item.tag] = item.units;
      });
      processedData.push(obj);
    });
    this.setState({ pipelineReport: processedData });
  };

  getColumnsForTable = () => {
    const { pipelineReport } = this.state;
    return ReportsTableManager.getColumnsForPipeline(pipelineReport);
  };

  render() {
    const { loading } = this.props;
    const { pipelineReport } = this.state;
    return (
      <div>
        <Table
          locale={{ filterConfirm: 'Apply' }}
          columns={this.getColumnsForTable()}
          dataSource={pipelineReport}
          loading={loading}
          rowKey={(row) => row.itemNumber}
        />
      </div>
    );
  }
}

export default PipelineReportTable;
