import { Button, Tabs } from 'antd';
import React, { Component } from 'react';
import { capitalize, isEmpty } from 'lodash';

import EmptySection from 'src/components/common/emptySection/EmptySection';
import LoadingComponent from 'src/components/common/LoadingComponent';
import NotificationService from 'src/services/NotificationService';
import NotificationUtils from 'src/utils/NotificationUtils';
import { getFormattedFullDate } from 'src/utils/DisplayUtils';
import { withRouter } from 'react-router';

const { TabPane } = Tabs;
class NotificationOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleNotification = (notification) => {
    const { id, entityId, type } = notification;
    NotificationService.markNotificationRead(id);
    //Find Route to Redirect User
    const url = NotificationUtils.getRedirectionUrl(entityId, type);
    if (!isEmpty(url)) {
      this.props.history.push(url);
      this.props.markNotificationRead(id);
    }
  };

  render() {
    const { loading, notifications } = this.props;
    return (
      <div className='nav-dropdown nav-notification'>
        <div className='notification-contain'>
          <Tabs
            className='notification-alerts'
            defaultActiveKey='notification'
            tabBarExtraContent={{
              right:
                notifications.length > 0 ? (
                  <Button
                    className='head-box-va mb-10'
                    type='link'
                    disabled={loading}
                    onClick={this.props.markAllNotificationsRead}
                  >
                    Mark All Read
                  </Button>
                ) : (
                  <></>
                ),
            }}
          >
            <TabPane
              tab={
                <span>
                  Notifications
                  {notifications.length > 0 && (
                    <span className='heading-badge'>
                      {notifications.length}
                    </span>
                  )}
                </span>
              }
              key='notification'
            >
              {!isEmpty(notifications) ? (
                notifications.map((notification) => (
                  <div
                    className='reminder-list-card tab-notification-alert notification-item'
                    key={notification.id}
                    onClick={() => this.handleNotification(notification)}
                  >
                    <div className='reminder-list-desc'>
                      <p className='desc'>
                        <strong>{`${notification.entityId} - ${capitalize(
                          notification.title
                        )}`}</strong>
                      </p>
                      <p className='bid-value'>{notification.text}</p>
                    </div>
                    <div className='view-cta'>
                      <p>{getFormattedFullDate(notification.createdAt)}</p>
                    </div>
                  </div>
                ))
              ) : loading ? (
                <LoadingComponent />
              ) : (
                <EmptySection
                  imageSrc={
                    require('../../../../../assets/images/no-data-images/nd-notifications.png')
                      .default
                  }
                  title='No Notifications'
                  size='small'
                />
              )}
            </TabPane>

            {/* Reminder tab */}
            {/* <TabPane
              tab={
                <span>
                  Reminders
                  <span className='heading-badge'>06</span>
                </span>
              }
              key='2'
            >
              <div className='reminder-list-card tab-reminder-alert'>
                <div className='rmdr-img-bg'>
                  <img
                    alt='reminder'
                    src={
                      require('../../../../../assets/images/Icon-Bid-Reminder.png')
                        .default
                    }
                  />
                </div>
                <div className='reminder-list-desc'>
                  <p className='desc'>
                    <strong>
                      Quote for SC Shrimp Ring 8/20oz IQF Quote for SC Shrimp
                      Ring 8/20oz IQF Quote for SC Shrimp Ring 8/20oz IQFQuote
                      for SC Shrimp Ring 8/20oz IQF
                    </strong>
                  </p>
                  <p className='date'>21 Apr 2021</p>
                </div>
                <div className='view-cta'>
                  <Button>View</Button>
                </div>
              </div>
            </TabPane> */}
            {/* reminder tab end */}
          </Tabs>
        </div>
      </div>
    );
  }
}

export default withRouter(NotificationOverlay);
