import { cloneDeep, isEqual, orderBy } from 'lodash';
import { displayDecimal, getFormattedFullDate } from 'src/utils/DisplayUtils';
import {
  findBidIncotermByCode,
  formBidChartData,
  getDisplayIncotermForLegend,
} from 'src/utils/BidUtils';

import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';
import Highcharts from 'highcharts/highstock';
import React from 'react';
import moment from 'moment';
import noData from 'highcharts/modules/no-data-to-display';

noData(Highcharts);

class BidHistoryChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allBids: cloneDeep(this.props.allBids),
      // options: {
      //   chart: {
      //     type: 'line',
      //   },
      //   lang: {
      //     noData: 'No bids found',
      //   },
      //   noData: {
      //     style: {
      //       fontWeight: 'bold',
      //       fontSize: '15px',
      //       color: '#303030',
      //     },
      //   },
      //   yAxis: {
      //     opposite: false,
      //     plotLines: [
      //       {
      //         color: '#ed474a',
      //         width: 2,
      //         value: this.props.auctionTargetPrice,
      //         dashStyle: 'Dash',
      //       },
      //     ],
      //     min: this.getMinYAxisValue(),
      //     max: this.getMaxYAxisValue(),
      //   },
      //   series: formBidChartData(this.props.allBids),
      //   plotOptions: {
      //     series: {
      //       marker: {
      //         enabled: true,
      //         radius: 5,
      //         symbol: 'circle',
      //       },
      //     },
      //   },
      //   rangeSelector: {
      //     inputEnabled: false,
      //     buttons: [
      //       {
      //         type: 'day',
      //         count: 1,
      //         text: '1d',
      //         title: 'View 1 day',
      //       },
      //       {
      //         type: 'day',
      //         count: 5,
      //         text: '5d',
      //         title: 'View 5 days',
      //       },
      //       {
      //         type: 'month',
      //         count: 1,
      //         text: '1m',
      //         title: 'View 1 month',
      //       },
      //       {
      //         type: 'month',
      //         count: 3,
      //         text: '3m',
      //         title: 'View 3 months',
      //       },
      //       {
      //         type: 'all',
      //         text: 'All',
      //         title: 'View all',
      //       },
      //     ],
      //   },
      //   legend: {
      //     enabled: true,
      //     align: 'right',
      //     verticalAlign: 'top',
      //     layout: 'horizontal',
      //     x: 0,
      //     y: 25,
      //     symbolWidth: 10,
      //     symbolRadius: 5,
      //   },
      //   credits: {
      //     enabled: false,
      //   },
      //   time: {
      //     useUTC: false,
      //   },
      // },
      options: {
        chart: {
          animations: {
            enabled: true,
            easing: 'linear',
            speed: 700,
          },
          id: props.chartId,
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        noData: {
          text: 'No bids found',
          style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#303030',
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            show: true,
            datetimeUTC: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: [
          formBidChartData(this.props.allBids, this.props.incoterms).map(
            (series) => {
              return series.color;
            }
          ),
        ],
        markers: {
          size: 4,
          discrete: this.getDiscreteMarkers(),
        },
        stroke: {
          width: 3,
          curve: 'straight',
          dashArray: 0,
        },
        annotations: {
          position: 'front',
          yaxis: [
            {
              y: this.props.auctionTargetPrice,
              strokeDashArray: 5,
              borderColor: '#ED474A',
              width: this.props.auctionTargetPrice ? '100%' : '0%',
              label: {
                show: true,
                text:
                  this.props.auctionTargetPrice &&
                  displayDecimal(this.props.auctionTargetPrice),
                position: 'right',
                offsetY: -10,
                borderWidth: 0,
                style: {
                  fontSize: 12,
                  color: '#ED474A',
                },
              },
            },
            {
              y: this.props.lowestBid && this.props.lowestBid.amount,
              strokeDashArray: 5,
              borderColor: '#0EBAB3',
              width: this.props.lowestBid ? '100%' : '0%',
              label: {
                show: true,
                text:
                  this.props.lowestBid &&
                  displayDecimal(this.props.lowestBid.amount) +
                    ' / ' +
                    (this.props.lowestBid.incoterm &&
                      this.props.lowestBid.incoterm.code),
                position: 'left',
                offsetX: 60,
                borderWidth: 0,
                style: {
                  fontSize: 12,
                  color: '#0EBAB3',
                },
              },
            },
          ],
        },
        yaxis: [
          {
            min: this.props.auctionTargetPrice
              ? this.getMinYAxisValue()
              : undefined,
            max: this.props.auctionTargetPrice
              ? this.getMaxYAxisValue()
              : undefined,
          },
        ],
        series: formBidChartData(this.props.allBids, this.props.incoterms),
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'right',
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            radius: 7,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 2,
          },
        },
        grid: {
          borderColor: '#e3e3e3',
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          row: {
            padding: {
              right: 100,
            },
          },
        },
        tooltip: {
          shared: false,
          intersect: true,
          x: {
            formatter: function (
              value,
              { series, seriesIndex, dataPointIndex, w }
            ) {
              return getFormattedFullDate(value);
            },
          },
          y: formBidChartData(this.props.allBids, this.props.incoterms).map(
            (series) => {
              return {
                formatter: function (value) {
                  return value + ' / ' + series.finalIncoterm;
                },
              };
            }
          ),
          marker: {
            show: false,
          },
        },
      },
      selection: 'one_week',
    };
  }

  getLowerReferenceValue = () => {
    let lowerReferenceValue = this.props.auctionTargetPrice;

    if (this.props.lowestBid) {
      lowerReferenceValue =
        lowerReferenceValue < this.props.lowestBid.amount
          ? lowerReferenceValue
          : this.props.lowestBid.amount;
    }

    return lowerReferenceValue;
  };

  getHigherReferenceValue = () => {
    let higherReferenceValue = this.props.auctionTargetPrice;

    let selectedIncoterm = this.props.selectedIncoterm;
    let sortedBids = orderBy(
      this.props.allBids.bids,
      function (o) {
        return findBidIncotermByCode(o.bidIncoterms, selectedIncoterm)
          ? findBidIncotermByCode(o.bidIncoterms, selectedIncoterm).amount
          : 0;
      },
      'desc'
    );

    if (sortedBids.length > 0) {
      higherReferenceValue =
        higherReferenceValue >
        findBidIncotermByCode(sortedBids[0].bidIncoterms, selectedIncoterm)
          .amount
          ? higherReferenceValue
          : findBidIncotermByCode(sortedBids[0].bidIncoterms, selectedIncoterm)
              .amount;
    }

    return higherReferenceValue;
  };

  getMaxYAxisValue = () => {
    return this.getHigherReferenceValue() + 0.1;
  };

  getMinYAxisValue = () => {
    let selectedIncoterm = this.props.selectedIncoterm;
    let sortedBids = orderBy(this.props.allBids.bids, function (o) {
      return findBidIncotermByCode(o.bidIncoterms, selectedIncoterm)
        ? findBidIncotermByCode(o.bidIncoterms, selectedIncoterm).amount
        : 0;
    });

    let lowerReferenceValue = this.getLowerReferenceValue();

    return sortedBids.length > 0
      ? findBidIncotermByCode(sortedBids[0].bidIncoterms, selectedIncoterm)
          .amount > lowerReferenceValue
        ? lowerReferenceValue - 0.1
        : findBidIncotermByCode(sortedBids[0].bidIncoterms, selectedIncoterm)
            .amount - 0.1
      : undefined;
  };

  getDiscreteMarkers = () => {
    let discreteArr = formBidChartData(
      this.props.allBids,
      this.props.incoterms
    ).map((series, seriesIndex) =>
      series.data.map((dataPoint, dataPointIndex) => {
        return {
          seriesIndex,
          dataPointIndex,
          shape: dataPoint.marker,
          fillColor: series.color,
          strokeColor: '#fff',
          size: 7,
        };
      })
    );

    let finalDiscreteArr = [];
    discreteArr.forEach((discreteArrForSeries) => {
      discreteArrForSeries.forEach((discreteObjForDataPoint) => {
        finalDiscreteArr.push(discreteObjForDataPoint);
      });
    });

    return finalDiscreteArr;
  };

  componentDidUpdate() {
    if (!isEqual(this.props.allBids, this.state.allBids)) {
      let options = { ...this.state.options };

      options.series = formBidChartData(
        this.props.allBids,
        this.props.incoterms
      );
      // let yAxis = [...options.yAxis];
      // yAxis[0].min = this.getMinYAxisValue();
      // yAxis[0].max = this.getMaxYAxisValue();

      let yaxis = [...options.yaxis];
      yaxis[0].min = this.props.auctionTargetPrice
        ? this.getMinYAxisValue()
        : undefined;
      yaxis[0].max = this.props.auctionTargetPrice
        ? this.getMaxYAxisValue()
        : undefined;

      let tooltip = { ...options.tooltip };

      tooltip.y = formBidChartData(
        this.props.allBids,
        this.props.incoterms
      ).map((series) => {
        return {
          formatter: function (value) {
            return value + ' / ' + series.finalIncoterm;
          },
        };
      });

      options.tooltip = { ...tooltip };

      let markers = { ...options.markers };

      options.markers = {
        ...markers,
        discrete: this.getDiscreteMarkers(),
      };

      this.setState({ allBids: this.props.allBids, options });
    }
  }

  updateData(timeline) {
    this.setState({
      selection: timeline,
    });

    switch (timeline) {
      case 'one_day':
        ApexCharts.exec(
          this.props.chartId,
          'zoomX',
          new Date(moment().subtract(1, 'day')).getTime(),
          new Date().getTime()
        );
        break;
      case 'three_days':
        ApexCharts.exec(
          this.props.chartId,
          'zoomX',
          new Date(moment().subtract(3, 'days')).getTime(),
          new Date().getTime()
        );
        break;
      case 'one_week':
        ApexCharts.exec(
          this.props.chartId,
          'zoomX',
          new Date(moment().subtract(1, 'week')).getTime(),
          new Date().getTime()
        );
        break;
      case 'one_month':
        ApexCharts.exec(
          this.props.chartId,
          'zoomX',
          new Date(moment().subtract(1, 'month')).getTime(),
          new Date().getTime()
        );
        break;
      case 'all':
        ApexCharts.exec(
          this.props.chartId,
          'zoomX',
          new Date(moment().startOf('year')).getTime(),
          new Date().getTime()
        );
        break;
      default:
    }
  }

  render() {
    const { incoterms } = this.props;
    let displayIncoterm = getDisplayIncotermForLegend(this.props.allBids.bids);

    return (
      // <HighchartsReact
      //   options={this.state.options}
      //   highcharts={Highcharts}
      //   constructorType={'stockChart'}
      // />
      <div className='bid-chart'>
        {incoterms.length > 1 ? (
          <>
            <span className='circle-marker-legend'></span>
            <span className='helper-text'>
              {incoterms.length > 1 ? 'DDPCY Value' : ''}
            </span>
            <span className='square-marker-legend'></span>
            <span className='helper-text'>
              {incoterms.length > 1 ? 'Est DDPCY Value' : ''}
            </span>
          </>
        ) : (
          <span className='helper-text'>
            {'All quotes in ' + displayIncoterm}
          </span>
        )}

        <div className='header'>
          <div className='toolbar'>
            Change View:
            <button
              id='one_day'
              onClick={() => this.updateData('one_day')}
              className={this.state.selection === 'one_day' ? 'active' : ''}
            >
              1D
            </button>
            &nbsp;
            <button
              id='three_days'
              onClick={() => this.updateData('three_days')}
              className={this.state.selection === 'three_days' ? 'active' : ''}
            >
              3D
            </button>
            &nbsp;
            <button
              id='one_week'
              onClick={() => this.updateData('one_week')}
              className={this.state.selection === 'one_week' ? 'active' : ''}
            >
              1W
            </button>
            &nbsp;
            <button
              id='one_month'
              onClick={() => this.updateData('one_month')}
              className={this.state.selection === 'one_month' ? 'active' : ''}
            >
              1M
            </button>
            &nbsp;
            <button
              id='all'
              onClick={() => this.updateData('all')}
              className={this.state.selection === 'all' ? 'active' : ''}
            >
              ALL
            </button>
          </div>
          <div className='header-elements'>
            {this.props.auctionTargetPrice && (
              <div className='target-price-anno'>- - - Target Price</div>
            )}
            {this.props.lowestBid && (
              <div className='lowest-bid-anno'>- - - Lowest Quote</div>
            )}
          </div>
        </div>
        <div id='chart'>
          <Chart
            options={this.state.options}
            series={this.state.options.series}
            type='line'
            height={450}
          />
        </div>
      </div>
    );
  }
}
export default BidHistoryChart;
