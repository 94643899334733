import { Button } from 'antd';
import { ChannelHeader } from 'stream-chat-react';
import { InfoCircleFilled } from '@ant-design/icons';

const HeaderComponent = (props) => {
  return (
    <div className='chat-header'>
      <ChannelHeader />
      {props.showInfoIcon && (
        <Button
          onClick={() => props.showInfoDrawer()}
          id='chat-info'
          icon={<InfoCircleFilled />}
        />
      )}
    </div>
  );
};

export default HeaderComponent;
