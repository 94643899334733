import { Form, Input, Modal, Select } from 'antd';
import React, { Component } from 'react';

import ApiUtils from 'src/utils/ApiUtils';
import { CaretDownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import StringConstants from 'src/constants/StringConstants';
import TeamService from 'src/services/TeamService';
import Title from 'src/components/common/typography/Title';
import { toast } from 'react-toastify';

const { Option } = Select;

class InviteMemberModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isError: false,
      errorMsg: '',
    };
  }

  onSubmit = (values) => {
    this.setState({ loading: true });
    values.teamId = this.props.teamId;
    TeamService.inviteUser(values)
      .then((response) => {
        toast.success('Invited Successfully');
        this.setState({ loading: false }, () => this.props.refreshPage());
      })
      .catch((err) => {
        let errorMessage = ApiUtils.getErrorMessage(err);
        toast.error('Failed to invite member - ' + errorMessage);
        this.setState({ loading: false, isError: true, errorMsg: err });
      });
  };

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    teamId: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  render() {
    const { visible } = this.props;
    const { loading } = this.state;

    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };

    return (
      <Modal
        className='confirmation-modal'
        centered
        visible={visible}
        okText='Send Invite'
        onOk={() => this.inviteForm.submit()}
        onCancel={this.props.onCancel}
        closable={false}
        maskClosable={false}
        cancelButtonProps={{
          disabled: loading,
          className: 'cancel-outlined-button',
        }}
        width={900}
      >
        <Title title='Invite Member' />

        <Form
          {...layout}
          onFinish={this.onSubmit}
          preserve={false}
          ref={(ref) => (this.inviteForm = ref)}
          className='mt-30'
        >
          <Form.Item
            label='Email Address'
            name='email'
            labelAlign='left'
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid E-mail!',
              },
              {
                required: true,
                message: 'Please enter your E-mail!',
              },
            ]}
            hasFeedback
          >
            <Input className='invite-member-field' />
          </Form.Item>
          <Form.Item
            label='Role'
            name='role'
            labelAlign='left'
            rules={[
              {
                required: true,
                message: 'Please select a role',
              },
            ]}
            hasFeedback
          >
            <Select
              className='planned-vi-sel-btn'
              suffixIcon={<CaretDownOutlined />}
            >
              {Object.keys(StringConstants.TEAM_ROLES).map((role) => {
                return (
                  <Option value={role} key={role}>
                    {role}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default InviteMemberModal;
