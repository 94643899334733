import './Comment.css';

import { Button, Form, Input, Upload } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';

import React from 'react';

const { TextArea } = Input;

class Comment extends React.Component {
  state = {
    isActive: false,
    isActive1: true,
  };
  handleShow = () => {
    this.setState({
      isActive: true,
      isActive1: false,
    });
  };

  handleHide = () => {
    this.setState({
      isActive: false,
      isActive1: true,
    });
  };
  render() {
    return (
      <>
        <div className='ca-card-light mb-15  '>
          <div className='heading-divider'>
            <div className='head-divi-box'>
              <p className='head-divi-title'>Comments</p>
              <div className='head-divi-line'></div>
            </div>
          </div>

          <ul className='comment-sec mt-15'>
            <li className='comment-box'>
              <div className='comment-user-img'>
                <img
                  className=''
                  src={require('../../assets/images/avtar-profile.png').default}
                  alt='species'
                />
              </div>
              <div className='comment-vline'></div>

              <div className='comnt-details'>
                <p className='cmnt-username'>
                  <strong>Ramanuja Naidu</strong>
                  <span className='cmnt-dot'>&bull;</span>
                  <span className='cmnt-date'>14 Jan 2021, 00:35 PM</span>
                </p>
                <p className='cmnt-src'>
                  <img
                    className=''
                    src={require('../../assets/images/user-gen.png').default}
                    alt='species'
                  />
                  User Generated Comment
                </p>
                {this.state.isActive1 ? (
                  <>
                    {/* user comment */}
                    <p className='user-cmnt'>
                      Can you let us know the Name/Title for these links and
                      Subtitle that you want us to place on Central. In case,
                      you need a specific logo for the link as well do let us
                      know.Once you send it across we will ensure it is placed
                      on Central and shared with you for approval.
                    </p>
                    <div className='cmnt-btn-area mt-15'>
                      <Button
                        onClick={this.handleShow}
                        className='primary-button mr-15'
                        type='primary'
                        icon={<EditFilled />}
                      >
                        Edit
                      </Button>
                      <Button
                        className='cancel-button'
                        type='primary'
                        icon={<DeleteFilled />}
                      >
                        Delete
                      </Button>
                    </div>
                    {/* user comment end */}
                  </>
                ) : null}
                {this.state.isActive ? (
                  <>
                    {/* user comment edited */}
                    <Form>
                      <div className='edited-cmnt-box'>
                        <Form.Item>
                          <TextArea
                            className='edited-cmnt'
                            autoSize={{ maxRows: 10 }}
                            placeholder='Can you let us know the Name/Title for these links and Subtitle that you want us to place on Central. In case, you need a specific logo for the link as well do let us know.Once you send it across we will ensure it is placed on Central and shared with you for approval.'
                          />
                        </Form.Item>
                        <div className='cmnt-attacment'>
                          <Upload>
                            <img
                              className='attch-icon'
                              src={
                                require('../../assets/images/cmnt-attach.png')
                                  .default
                              }
                              alt='species'
                            />
                            Add Attachment
                          </Upload>
                        </div>
                      </div>
                      <Button
                        onClick={this.handleHide}
                        className='primary-button mt-15'
                        type='primary'
                        htmlType='submit'
                      >
                        Save Changes
                      </Button>
                    </Form>
                    {/* user comment edited */}
                  </>
                ) : null}
              </div>
            </li>
            <li className='comment-box'>
              <div className='comment-user-img'>
                <img
                  className=''
                  src={require('../../assets/images/tim.jpg').default}
                  alt='species'
                />
              </div>
              <div className='comment-vline'></div>

              <div className='comnt-details'>
                <p className='cmnt-username'>
                  <strong>Michael Collyar</strong>
                  <span className='cmnt-dot'>&bull;</span>
                  <span className='cmnt-date'>15 Jan 2021, 09:07 AM</span>
                </p>
                <p className='cmnt-src'>
                  <img
                    className=''
                    src={require('../../assets/images/user-gen.png').default}
                    alt='species'
                  />
                  User Generated Comment
                </p>
                <p className='user-cmnt'>
                  Hey! Apologies, I've been working non-stop this week. I think
                  it looks good, although I think we should also consider (and
                  this is harder because it's part of the applications menu at
                  the top left) adding the other project tracker, available
                  here: https://airtable.com/shrRyq3ENE1E7jsjW. We have the
                  systems separate because he runs just the bag artwork and I
                  take care of the marketing/all other requests for assets or
                  materials. Is there a way to incorporate both without throwing
                  off the layout we already have? Thanks.
                </p>
              </div>
            </li>
            <li className='comment-box'>
              <div className='comment-user-img'>
                <img
                  className=''
                  src={require('../../assets/images/avtar-profile.png').default}
                  alt='species'
                />
              </div>
              <div className='comment-vline'></div>

              <div className='comnt-details'>
                <p className='cmnt-username'>
                  <strong>Carava Pvt. Ltd.</strong>
                  <span className='cmnt-dot'>&bull;</span>
                  <span className='cmnt-date'>15 Jan 2021, 09:10 AM</span>
                </p>
                <p className='cmnt-src'>
                  <img
                    className=''
                    src={require('../../assets/images/sytem-gen.png').default}
                    alt='species'
                  />
                  System Generated Comment
                </p>
                <p className='user-cmnt'>
                  This is the example for system generated comment.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </>
    );
  }
}
export default Comment;
