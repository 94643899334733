import ApiManager from 'src/api/ApiManager';
import HttpConstants from 'src/constants/HttpConstants';
import UrlConstants from 'src/constants/UrlConstants';

const CommentService = {
  fetchComments: (entityId, entityType, page, limit) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_COMMENTS.USECASE,
      HttpConstants.GET_METHOD,
      { entityId, entityType, page, limit }
    );
  },
  addComment: (entityId, entityType, message) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.ADD_COMMENT.USECASE,
      HttpConstants.POST_METHOD,
      { entityId, entityType, message }
    );
  },
};

export default CommentService;
