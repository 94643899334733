import ApiManager from 'src/api/ApiManager';
import HttpConstants from 'src/constants/HttpConstants';
import UrlConstants from 'src/constants/UrlConstants';

const NotificationService = {
  getAllNotifications: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_NOTIFICATIONS.USECASE,
      HttpConstants.GET_METHOD,
      {
        status: 'UNREAD',
      }
    );
  },
  markAllNotificationsRead: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.MARK_ALL_NOTIFICATIONS_READ.USECASE,
      HttpConstants.POST_METHOD
    );
  },
  markNotificationRead: (notificationId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.MARK_NOTIFICATION_READ.USECASE,
      HttpConstants.PATCH_METHOD,
      {
        ':id': notificationId,
      }
    );
  },
};

export default NotificationService;
