export function hashCode(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 3) - hash - 4);
  }
  return hash;
}

export function intToRGB(hash) {
  let hex = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    hex += ('00' + value.toString(16)).substr(-2);
  }
  return hex;
}
