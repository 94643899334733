import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';

const StatusValidator = {
  getValidStatusForParam: (type, location, statusKey = 'status') => {
    const statusParam = UrlGenerator.getParamsFromUrl(location, statusKey);
    if (statusParam) {
      if (
        (type === StringConstants.AUCTION_STATUS &&
          [
            StringConstants.AUCTION_STATUS.ACTIVE,
            StringConstants.AUCTION_STATUS.DRAFT,
            StringConstants.AUCTION_STATUS.PENDING,
            StringConstants.AUCTION_STATUS.INVITED,
            StringConstants.AUCTION_STATUS.HISTORY,
          ].includes(statusParam)) ||
        (type === StringConstants.PROCESSING_PLANT_TYPES &&
          [
            StringConstants.PROCESSING_PLANT_TYPES.ALL,
            StringConstants.PROCESSING_PLANT_TYPES.INVITED,
          ].includes(statusParam))
      ) {
        return statusParam;
      }
    }
    return null;
  },
};

export default StatusValidator;
