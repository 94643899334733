import { Link, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { cloneDeep, isEmpty } from 'lodash';

import { Breadcrumb } from 'antd';
import BreadcrumbConstants from 'src/constants/BreadcrumbConstants';
import RouteConstants from 'src/constants/RouteConstants';
import eventBus from 'src/utils/eventBus/EventBus';

class Breadcrumbs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: [],
      url: undefined,
    };
  }

  componentDidMount() {
    eventBus.on('breadcrumb_update', (data) => this.updateStep(data));
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps === undefined) {
      return false;
    }
    if (this.state.url !== this.props.match.url) {
      this.init();
    }
  }

  init() {
    const route = this.props.match.path;
    const params = this.props.match.params;
    let steps = BreadcrumbConstants[route]
      ? cloneDeep(BreadcrumbConstants[route])
      : [];
    if (!isEmpty(steps)) {
      steps.forEach((step) => {
        Object.keys(params).forEach((paramKey) => {
          if (step.text.includes(':' + paramKey)) {
            step.text = step.text.replace(':' + paramKey, params[paramKey]);
          }
          if (step.route && step.route.includes(':' + paramKey))
            step.route = step.route.replace(':' + paramKey, params[paramKey]);
        });
      });
    }
    this.setState({ steps, url: this.props.match.url });
  }

  updateStep = (data) => {
    const { steps } = this.state;
    steps.forEach((step) => {
      if (data[step.text]) {
        step.text = data[step.text];
      }
    });
    this.setState({ steps });
  };

  componentWillUnmount() {
    eventBus.remove('breadcrumb_update');
  }

  render() {
    const { steps } = this.state;
    if (isEmpty(steps)) return null;
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={RouteConstants.DASHBOARD}>Home</Link>
        </Breadcrumb.Item>
        {steps.map((step, index) => (
          <Breadcrumb.Item key={index}>
            {step.route ? <Link to={step.route}>{step.text}</Link> : step.text}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  }
}

export default withRouter(Breadcrumbs);
