import './common/Auth.css';

import { Col, Layout, Row } from 'antd';

import AuthSideCarousel from './common/AuthSideCarousel';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import LoginForm from './login/LoginForm';
import React from 'react';
import UnsecureComponent from 'src/components/common/UnsecureComponent';

class Login extends React.Component {
  render() {
    return (
      <>
        <HeaderMetadata
          title='Sourcing Platform for the Seafood industry | Carava'
          description='Procurement Platform for the Seafood Industry'
        />
        <Layout style={{ minHeight: '100vh' }}>
          <Row>
            <Col
              style={{ minHeight: '100vh' }}
              className='login-left'
              span={12}
              xs={24}
              lg={12}
              xl={12}
            >
              <LoginForm />
            </Col>
            <Col
              style={{ minHeight: '100vh' }}
              className='login-right'
              span={12}
              xs={24}
              sm={24}
              lg={12}
              xl={12}
            >
              <AuthSideCarousel />
            </Col>
          </Row>
        </Layout>
      </>
    );
  }
}
export default UnsecureComponent(Login);
