import './HorizontalTimeline.css';

import { CheckOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Col, Row, Steps } from 'antd';
import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import RouteConstants from 'src/constants/RouteConstants';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { isEmpty } from 'lodash';

const { Step } = Steps;

class HorizontalTimeline extends Component {
  getTimelineEntities = () => {
    let timelineEntities = StringConstants.TIMELINE_ENTITIES;
    const { currentEntity, data } = this.props;
    if (currentEntity === 'Proforma') {
      if (data.type === 'DIRECT') return timelineEntities.slice(1);
      else return timelineEntities;
    } else if (currentEntity === 'Purchase Order') {
      if (!('auctionId' in data) && !('proformaId' in data)) return [];
      else if (!('auctionId' in data)) return timelineEntities.slice(1);
      else return timelineEntities;
    } else return timelineEntities;
  };

  findCurrentStep = () => {
    const { currentEntity, data, nextEntityData } = this.props;
    let timelineEntities = this.getTimelineEntities();
    if (currentEntity === 'RFQ') {
      if (
        data.status === StringConstants.AUCTION_STATUS.PROFORMA_REVIEW ||
        data.status === StringConstants.AUCTION_STATUS.COMPLETED
      ) {
        if (isEmpty(nextEntityData)) return 1;
        else {
          if (
            nextEntityData.some(
              (nextEntity) => nextEntity.status === 'CONTRACT_GENERATED'
            )
          )
            return timelineEntities.length;
          else return timelineEntities.length - 1;
        }
      } else return 1;
    } else if (currentEntity === 'Proforma') {
      if (data.status === StringConstants.PROFORMA_STATUS.CONTRACT_GENERATED)
        return timelineEntities.length;
      else return timelineEntities.length - 1;
    } else return timelineEntities.length;
  };

  getTitle = (redirectEntity, redirectEntityIndex, currentEntityIndex) => {
    const { currentEntity, data } = this.props;
    if (redirectEntityIndex === currentEntityIndex) return currentEntity;
    if (
      currentEntity === 'RFQ' &&
      redirectEntity === 'Proforma' &&
      (data.status === StringConstants.AUCTION_STATUS.PROFORMA_REVIEW ||
        data.status === StringConstants.AUCTION_STATUS.COMPLETED)
    )
      return (
        <Link
          to={UrlGenerator.getUiUrlWithPathParams(
            RouteConstants.PROFORMA_REVIEW,
            {
              id: data.id,
            }
          )}
        >
          {redirectEntity}
        </Link>
      );
    else if (currentEntity === 'Proforma' && redirectEntity === 'RFQ')
      return (
        <Link
          to={UrlGenerator.getUiUrlWithPathParams(RouteConstants.AUCTION, {
            id: data.auctionId,
          })}
        >
          {redirectEntity}
        </Link>
      );
    else if (currentEntity === 'Purchase Order') {
      if (redirectEntity === 'Proforma')
        return (
          <Link
            to={UrlGenerator.getUiUrlWithPathParams(
              RouteConstants.PROFORMA_DETAIL,
              {
                id: data.proformaId,
              }
            )}
          >
            {redirectEntity}
          </Link>
        );
      else if (redirectEntity === 'RFQ')
        return (
          <Link
            to={UrlGenerator.getUiUrlWithPathParams(RouteConstants.AUCTION, {
              id: data.auctionId,
            })}
          >
            {redirectEntity}
          </Link>
        );
    } else return redirectEntity;
  };

  constructor(props) {
    super(props);
    this.state = {
      currentStep: this.findCurrentStep(),
    };
  }

  render() {
    const { currentStep } = this.state;
    const { currentEntity } = this.props;
    let currentEntityIndex = this.getTimelineEntities().indexOf(currentEntity);
    return (
      <Row className='horizontal-timeline'>
        <Col className=' mb-15 ' span={24} xs={24} lg={24} xl={24}>
          <Steps current={currentStep} status='process'>
            {this.getTimelineEntities().map((entity, index) => {
              return (
                <Step
                  key={entity}
                  title={this.getTitle(entity, index, currentEntityIndex)}
                  icon={
                    index < currentStep ? (
                      <CheckOutlined />
                    ) : (
                      <ClockCircleOutlined />
                    )
                  }
                ></Step>
              );
            })}
          </Steps>
        </Col>
      </Row>
    );
  }
}

export default HorizontalTimeline;
