import ApiUtils from 'src/utils/ApiUtils';
import Card from 'src/components/common/cards/Card';
import PipelineReportGraph from './pipelineOverviewReport/PipelineReportGraph';
import PipelineReportTable from './pipelineOverviewReport/PipelineReportTable';
import React from 'react';
import ReportService from 'src/services/ReportService';
import StringConstants from 'src/constants/StringConstants';
import { Tabs } from 'antd';
import { toast } from 'react-toastify';

const { TabPane } = Tabs;

class PipelineOverviewReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pipelineData: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    ReportService.getInventoryPipeline()
      .then((response) => {
        this.setState({ pipelineData: response.data, loading: false }, () => {
          this.graphRef.fetchReport(StringConstants.PRODUCT_TYPES.SHRIMP);
        });
      })
      .catch((err) => {
        let errorMessage = ApiUtils.getErrorMessage(
          err,
          'Unable to fetch details at the moment'
        );
        toast.error(errorMessage);
        this.setState({ loading: false });
      });
  }

  render() {
    const { loading, pipelineData } = this.state;

    return (
      <Card>
        <Tabs
          className='notification-alerts auction-tabs vw-part-mb-hi-tab'
          defaultActiveKey='pipeline-graph'
          destroyInactiveTabPane
        >
          <TabPane
            tab={<span>Pipeline (By Quantity)</span>}
            key='pipeline-graph'
          >
            <PipelineReportGraph
              ref={(ref) => (this.graphRef = ref)}
              loading={loading}
              pipelineData={pipelineData}
            />
          </TabPane>

          <TabPane tab={<span>Pipeline Overview</span>} key='pipeline-table'>
            <PipelineReportTable
              ref={(ref) => (this.tableRef = ref)}
              loading={loading}
              pipelineData={pipelineData}
            />
          </TabPane>
        </Tabs>
      </Card>
    );
  }
}

export default PipelineOverviewReport;
