import './PlaceBid.css';

import { Col, Row } from 'antd';

import AuctionService from 'src/services/AuctionService';
import AuctionUtils from 'src/utils/AuctionUtils';
import BidService from 'src/services/BidService';
import LoadingComponent from 'src/components/common/LoadingComponent';
import NoAccessComponent from 'src/components/common/NoAccessComponent';
import NoDataComponent from 'src/components/common/NoDataComponent';
import PlaceBidCard from 'src/components/auction/plants/PlaceBidCard';
import ProcessingPlantAuctionDetails from './ProcessingPlantAuctionDetails';
import React from 'react';
import SecureComponent from 'src/components/common/SecureComponent';
import StringConstants from 'src/constants/StringConstants';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';

class PlaceBid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auctionId: null,
      auctionDetails: {},
      loading: true,
      bidDetails: undefined,
    };
  }

  componentDidMount() {
    const auctionId = this.props.match.params.id;
    const plantId = this.props.user.company.id;
    this.setState({ loading: true, auctionId });

    AuctionService.getAuctionDetails(auctionId)
      .then((response) => {
        if (response.data.status !== StringConstants.AUCTION_STATUS.DRAFT) {
          const auctionDetails = response.data;
          auctionDetails.auctionPlannedOrders.forEach(
            (pl) => (pl.specification = auctionDetails.specification)
          );

          this.setState({ auctionDetails }, () => {
            BidService.getBidDetails(auctionId, plantId).then((response) => {
              let bidDetails = response.data;
              this.setState({ bidDetails, loading: false });
            });
          });
        } else throw response;
      })
      .catch((error) => {
        toast.error('No RFQ Found');
        this.setState({ loading: false, auctionDetails: {} });
      });
  }

  render() {
    const { loading, auctionDetails, auctionId, bidDetails } = this.state;

    return loading ? (
      <LoadingComponent />
    ) : !AuctionUtils.isActiveAuction(auctionDetails.status) ? (
      <NoDataComponent
        title='RFQ not active for bidding'
        tip={`RFQ (${auctionId}) is not active for bidding`}
        fullScreen={true}
        headerMetadata={true}
      />
    ) : bidDetails && bidDetails.bids && bidDetails.bids.length > 0 ? (
      <NoAccessComponent
        title='RFQ already entered'
        tip={`You have already entered RFQ (${auctionId}) and placed a quote`}
        fullScreen={true}
        headerMetadata={true}
      />
    ) : (
      <>
        <PlaceBidCard auctionId={auctionId} auctionDetails={auctionDetails} />
        <Row>
          <Col span={24} xs={24} lg={24} xl={24}>
            <ProcessingPlantAuctionDetails
              auctionDetails={auctionDetails}
              bidDetails={bidDetails}
              readOnly={true}
            />
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
  null
)(
  withRouter(
    SecureComponent(
      PlaceBid,
      [StringConstants.COMPANY_TYPES.PROCESSING_PLANT],
      [
        {
          name: StringConstants.TEAMS.SOURCING,
          role: StringConstants.TEAM_ROLES.MANAGER,
        },
      ]
    )
  )
);
