import { Button } from 'antd';
import React from 'react';
import StringConstants from 'src/constants/StringConstants';
import { isEmpty } from 'lodash';

class ProformaActionCard extends React.Component {
  render() {
    const {
      proformaStatus,
      toEdit,
      updateLoading,
      isBuyerOrSeller,
      isPartOfApprovalTeam,
      currentTeam,
      currentActioningTeam,
    } = this.props;

    return (
      <div className='auction-info-card mb-15'>
        {isEmpty(proformaStatus) ? (
          <>
            <div className='proforma-approve-heading'>
              {toEdit && currentActioningTeam === currentTeam
                ? '[' + currentTeam + '] Would you like to make changes? '
                : '[' + currentTeam + '] Do you approve this pro-forma?'}
            </div>
            <div className='proforma-approve-btns'>
              {(!toEdit || currentActioningTeam !== currentTeam) && (
                <Button
                  className='primary-button mr-15'
                  type='primary'
                  onClick={() => {
                    if (isBuyerOrSeller)
                      this.props.approveProforma(currentTeam);
                    else if (isPartOfApprovalTeam)
                      this.props.showApproverCommentBox(
                        StringConstants.APPROVER_ACTIONS.APPROVE,
                        currentTeam
                      );
                  }}
                  disabled={!isBuyerOrSeller && !isPartOfApprovalTeam}
                >
                  Approve
                </Button>
              )}
              {isBuyerOrSeller ||
              currentTeam === StringConstants.TEAMS.EXECUTIVE ? (
                <>
                  <Button
                    className='success-button mr-15'
                    type='primary'
                    onClick={() => {
                      if (toEdit && currentActioningTeam === currentTeam)
                        this.props.saveDetails();
                      this.props.toggleEdit(currentTeam);
                    }}
                    loading={updateLoading}
                    disabled={!isBuyerOrSeller && !isPartOfApprovalTeam}
                  >
                    {toEdit && currentActioningTeam === currentTeam
                      ? 'Save '
                      : 'Request Changes '}
                  </Button>
                  {toEdit && currentActioningTeam === currentTeam && (
                    <Button
                      className='cancel-button'
                      onClick={() => this.props.hideEdit(currentTeam)}
                      disabled={!isBuyerOrSeller && !isPartOfApprovalTeam}
                    >
                      Cancel
                    </Button>
                  )}
                </>
              ) : isPartOfApprovalTeam ? (
                <Button
                  className='cancel-button mr-15'
                  onClick={() => {
                    this.props.showApproverCommentBox(
                      StringConstants.APPROVER_ACTIONS.REJECT
                    );
                  }}
                  disabled={!isBuyerOrSeller && !isPartOfApprovalTeam}
                >
                  Reject
                </Button>
              ) : null}
            </div>
          </>
        ) : (
          <>
            <div className='proforma-approve-heading'>
              {proformaStatus.mainText}
            </div>
            <div className='proforma-approve-btns'>
              {proformaStatus.subText}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ProformaActionCard;
