import ApiManager from 'src/api/ApiManager';
import HttpConstants from 'src/constants/HttpConstants';
import UrlConstants from 'src/constants/UrlConstants';

const InviteService = {
  getInviteDetails: (token) => {
    return ApiManager.makeApiCall(
      UrlConstants.GET_INVITE_TOKEN_DETAILS.USECASE,
      HttpConstants.GET_METHOD,
      { ':token': token }
    );
  },
  submitPlantInvite: (token, data) => {
    return ApiManager.makeApiCall(
      UrlConstants.ACCEPT_PLANT_INVITES.USECASE,
      HttpConstants.POST_METHOD,
      {
        ':token': token,
        ...data,
      }
    );
  },
  submitUserInvite: (token, data) => {
    return ApiManager.makeApiCall(
      UrlConstants.ACCEPT_USER_INVITES.USECASE,
      HttpConstants.POST_METHOD,
      {
        ':token': token,
        ...data,
      }
    );
  },
  submitSubPlantInvite: (token, data) => {
    return ApiManager.makeApiCall(
      UrlConstants.ACCEPT_SUB_PLANT_INVITES.USECASE,
      HttpConstants.POST_METHOD,
      {
        ':token': token,
        ...data,
      }
    );
  },
};

export default InviteService;
