import './PendingInvites.css';

import CardWithTitle from '../common/cards/CardWithTitle';
import ConfirmationModal from 'src/modals/common/ConfirmationModal';
import EmptySection from '../common/emptySection/EmptySection';
import React from 'react';
import { Table } from 'antd';
import TeamTableManager from 'src/utils/tables/TeamTableManager';

class PendingInvites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resendInviteConfirmationModalVisible: false,
      revokeInviteConfirmationModalVisible: false,
      inviteId: undefined,
    };
  }

  getPendingInvitesColumns = () => {
    return TeamTableManager.getPendingInvitesColumns.call(this);
  };

  render() {
    const { invites, error, resendActionLoading, revokeActionLoading } =
      this.props;

    const {
      resendInviteConfirmationModalVisible,
      revokeInviteConfirmationModalVisible,
      inviteId,
    } = this.state;

    return (
      <>
        <CardWithTitle title='Pending Invites'>
          {invites && invites.length === 0 ? (
            <EmptySection
              imageSrc={
                require('../../assets/images/no-data-images/nd-rcvd-invites.png')
                  .default
              }
              title={
                error
                  ? 'No access'
                  : 'There are no pending invites at the moment'
              }
            />
          ) : (
            <Table
              className='pending-invites-table'
              columns={this.getPendingInvitesColumns()}
              dataSource={invites}
              pagination={false}
              scroll={{ y: 370 }}
              rowKey='id'
            />
          )}
          <ConfirmationModal
            visible={resendInviteConfirmationModalVisible}
            onSubmit={() => this.props.resendInvite(inviteId)}
            onCancel={() =>
              this.setState({ resendInviteConfirmationModalVisible: false })
            }
            title='Confirm resending of invite'
            description={'Are you sure you want to resend this invite ?'}
            okText='Yes, Resend'
            actionLoading={resendActionLoading}
          />
          <ConfirmationModal
            visible={revokeInviteConfirmationModalVisible}
            onSubmit={() => this.props.revokeInvite(inviteId)}
            onCancel={() =>
              this.setState({ revokeInviteConfirmationModalVisible: false })
            }
            title='Confirm revoking of invite'
            description={'Are you sure you want to revoke this invite ?'}
            negativeAction={true}
            okText='Yes, Revoke'
            actionLoading={revokeActionLoading}
          />
        </CardWithTitle>
      </>
    );
  }
}
export default PendingInvites;
