import './CreateSpecifications.css';

import { Button, Col, Row } from 'antd';
import { camelCase, find, isEmpty, isEqual, isUndefined, uniq } from 'lodash';

import ApiUtils from 'src/utils/ApiUtils';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import { Form } from 'antd';
import LoadingComponent from 'src/components/common/LoadingComponent';
import MetadataService from 'src/services/MetadataService';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import SecureComponent from 'src/components/common/SecureComponent';
import SpecificationPanels from 'src/components/specification/specificationPanels/SpecificationPanels';
import SpecificationService from 'src/services/SpecificationService';
import SpecificationUtils from 'src/utils/SpecificationUtils';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { getReadableDisplayName } from 'src/utils/DisplayUtils';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

class EditSpecification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      specification: {},
      customers: [],
      brands: [],
      seafoodList: [],
      acceptableMarketNames: [],
      countryNames: [],
      productForms: [],
      otherDropdowns: {},
      uploadedDocuments: {},
      updatingSpec: false,
    };
    this.createSpecForm = React.createRef();
  }

  async componentDidMount() {
    this.fetchPageData();
  }

  fetchPageData = async () => {
    this.setState({ loading: true });
    let specificationRes = await SpecificationService.getSpecificationDetail(
      this.props.match.params.id
    );
    let specification = specificationRes.data;
    let { customers } = await this.fetchSpecificationCustomers();
    let { brands } = await this.fetchSpecificationBrands();
    let { seafoodList, acceptableMarketNames } = await this.fetchSeafoodList();
    let { countryNames } = await this.fetchCountries();
    await this.fetchProductForms(specification.productType);
    let { otherDropdowns } = await this.fetchOtherDropdowns(
      specification.productForm,
      specification.productType
    );
    this.setState({
      customers,
      brands,
      seafoodList,
      acceptableMarketNames,
      countryNames,
      otherDropdowns,
      specification,
      loading: false,
    });
  };

  fetchSpecificationCustomers = async () => {
    try {
      let customersRes = await MetadataService.getSpecificationCustomers();
      return {
        customers: customersRes.data,
      };
    } catch (err) {
      return { customers: [] };
    }
  };

  fetchSpecificationBrands = async () => {
    try {
      let brandsRes = await MetadataService.getSpecificationBrands();
      return {
        brands: brandsRes.data,
      };
    } catch (err) {
      return { brands: [] };
    }
  };

  fetchSeafoodList = async () => {
    try {
      let seafoodListRes = await MetadataService.getSeaFoodList();
      return {
        seafoodList: seafoodListRes.data,
        acceptableMarketNames: uniq(
          seafoodListRes.data.map((item) => item.acceptableMarketName)
        ).sort(),
      };
    } catch (err) {
      return { seaFoodList: [], acceptableMarketNames: [] };
    }
  };

  fetchCountries = async () => {
    try {
      let countriesRes = await MetadataService.getCountries();
      return {
        countryNames: countriesRes.data.map((country) => country.name).sort(),
      };
    } catch (err) {
      return { countryNames: [] };
    }
  };

  fetchProductForms = async (selectedProductType) => {
    let actualSelectedProductType = selectedProductType;
    if (isUndefined(actualSelectedProductType))
      actualSelectedProductType = this.state.selectedProductType;
    let request = {
      productType: actualSelectedProductType,
    };
    let dictionaryDropdownsRes = await MetadataService.getDictionaryDropdowns(
      request
    );
    this.setState({ productForms: dictionaryDropdownsRes.data.product_form });
  };

  fetchOtherDropdowns = async (selectedProductForm, selectedProductType) => {
    const { productForms } = this.state;
    let actualSelectedProductType = selectedProductType;

    if (isUndefined(actualSelectedProductType))
      actualSelectedProductType = this.state.selectedProductType;

    let productForm = find(productForms, function (o) {
      return o.code === selectedProductForm;
    });
    let request = {
      productType: actualSelectedProductType,
      fields: [
        {
          field: productForm && productForm.field,
          code: productForm && productForm.code,
        },
      ],
    };

    let dictionaryDropdownsRes = await MetadataService.getDictionaryDropdowns(
      request
    );

    return { otherDropdowns: dictionaryDropdownsRes.data };
  };

  getOtherPanelData = () => {
    return this.specificationPanels.getData();
  };

  getAdditionalComments = (formValues) => {
    const { specification } = this.state;
    let additionalComments = [];
    let updatedComments = [];
    StringConstants.SPECIFICATION_FIELDS.COMMENT_TYPES.forEach(
      (commentType) => {
        let existingCommentValue = SpecificationUtils.getComment(
          specification.additionalComments,
          commentType
        );

        let existingCommentId = SpecificationUtils.getCommentId(
          specification.additionalComments,
          commentType
        );
        let updatedComment =
          formValues[
            camelCase(getReadableDisplayName(commentType)) + 'Comments'
          ];

        if (
          existingCommentValue !== updatedComment &&
          existingCommentId !== '-'
        ) {
          additionalComments.push({
            commentType,
            comments: updatedComment,
            id: existingCommentId,
          });
          updatedComments.push(commentType);
        }
      }
    );

    if (updatedComments.length > 0) {
      StringConstants.SPECIFICATION_FIELDS.COMMENT_TYPES.forEach(
        (commentType) => {
          if (!updatedComments.includes(commentType)) {
            let existingCommentValue = SpecificationUtils.getComment(
              specification.additionalComments,
              commentType
            );

            let existingCommentId = SpecificationUtils.getCommentId(
              specification.additionalComments,
              commentType
            );

            if (existingCommentId !== '-')
              additionalComments.push({
                commentType,
                comments: existingCommentValue,
                id: existingCommentId,
              });
          }
        }
      );
    }

    return additionalComments;
  };

  getCorporateAssurances = (formValues, panelData) => {
    const { specification } = this.state;
    let corporateAssurances = [];
    let updatedAssuranceTypes = [];
    StringConstants.SPECIFICATION_FIELDS.ASSURANCE_TYPES.forEach(
      (assuranceType) => {
        let existingCorporateAssuranceValue = specification.corporateAssurance
          .filter((assurance) => assurance.assuranceType === assuranceType)
          .map((elt) => elt.value);
        let existingCorporateAssuranceComments =
          SpecificationUtils.getCorporateAssuranceComments(
            specification.corporateAssurance,
            assuranceType
          );
        let updatedCorporateAssuranceValue =
          panelData[camelCase(getReadableDisplayName(assuranceType))];
        let updatedCorporateAssuranceComments =
          formValues[
            camelCase(getReadableDisplayName(assuranceType)) + 'Comments'
          ];

        if (typeof updatedCorporateAssuranceValue === 'string')
          updatedCorporateAssuranceValue = [updatedCorporateAssuranceValue];

        if (
          assuranceType === 'THIRD_PARTY_QA_PARTNER' &&
          !isEqual(
            existingCorporateAssuranceValue,
            updatedCorporateAssuranceValue
          )
        ) {
          updatedCorporateAssuranceValue.forEach((value) => {
            corporateAssurances.push({
              assuranceType,
              comments: null,
              value: value,
            });
          });
          updatedAssuranceTypes.push(assuranceType);
        } else if (
          assuranceType !== 'THIRD_PARTY_QA_PARTNER' &&
          (!isEqual(
            existingCorporateAssuranceValue,
            updatedCorporateAssuranceValue
          ) ||
            !isEqual(
              existingCorporateAssuranceComments,
              updatedCorporateAssuranceComments
            ))
        ) {
          updatedCorporateAssuranceValue.forEach((value) => {
            corporateAssurances.push({
              assuranceType,
              comments: updatedCorporateAssuranceComments,
              value: value,
            });
          });
          updatedAssuranceTypes.push(assuranceType);
        }
      }
    );

    if (updatedAssuranceTypes.length > 0)
      StringConstants.SPECIFICATION_FIELDS.ASSURANCE_TYPES.forEach(
        (assuranceType) => {
          if (!updatedAssuranceTypes.includes(assuranceType)) {
            let existingCorporateAssuranceValue =
              specification.corporateAssurance
                .filter(
                  (assurance) => assurance.assuranceType === assuranceType
                )
                .map((elt) => elt.value);
            let existingCorporateAssuranceComments =
              SpecificationUtils.getCorporateAssuranceComments(
                specification.corporateAssurance,
                assuranceType
              );

            existingCorporateAssuranceValue.forEach((value) => {
              corporateAssurances.push({
                assuranceType,
                comments: existingCorporateAssuranceComments,
                value: value,
              });
            });
          }
        }
      );

    return corporateAssurances;
  };

  getCountriesAssociated = (formValues) => {
    const { specification } = this.state;
    let countriesAssociated = [];
    let updatedCountryTypes = [];
    let isUpdatePresent = false;
    StringConstants.SPECIFICATION_FIELDS.ASSOCIATED_COUNTRY_TYPES.forEach(
      (countryType) => {
        let existingValue = specification.associatedCountries.filter(
          (o) => o.category === countryType
        );

        existingValue = existingValue.map((value) => value.country);

        let updatedValue =
          formValues[
            camelCase(getReadableDisplayName(countryType)) + 'CountryOfOrigin'
          ];

        if (typeof updatedValue === 'string') updatedValue = [updatedValue];

        if (!isEqual(existingValue, updatedValue)) {
          updatedValue.forEach((value) =>
            countriesAssociated.push({
              category: countryType,
              country: value,
            })
          );
          updatedCountryTypes.push(countryType);
          isUpdatePresent = true;
        }
      }
    );

    StringConstants.SPECIFICATION_FIELDS.ASSOCIATED_COUNTRY_TYPES.forEach(
      (countryType) => {
        if (!updatedCountryTypes.includes(countryType) && isUpdatePresent) {
          let existingValue = specification.associatedCountries.filter(
            (o) => o.category === countryType
          );

          existingValue = existingValue.map((value) => value.country);

          existingValue.forEach((value) => {
            countriesAssociated.push({
              category: countryType,
              country: value,
            });
          });
        }
      }
    );
    return countriesAssociated;
  };

  getUpdatedSeaFoodList = (selectedAcceptedMarketNames) => {
    const { specification, seafoodList } = this.state;
    let updatedSeafoodList = seafoodList.filter((seafoodItem) => {
      return selectedAcceptedMarketNames.includes(
        seafoodItem.acceptableMarketName
      );
    });

    let specSeafoodListIds = specification.seaFoodList
      .map((item) => item.id)
      .sort();
    let updatedSeafoodListIds = updatedSeafoodList.map((item) => item.id);

    if (isEqual(specSeafoodListIds, updatedSeafoodListIds)) return [];
    else return updatedSeafoodListIds;
  };

  getUpdatedStringFields = (formValues, otherFormData) => {
    const { specification } = this.state;
    let updatedStringFields = {};
    StringConstants.SPECIFICATION_FIELDS.UPDATABLE_STRING_FIELDS.forEach(
      (field) => {
        let existingValue = specification[field.name];
        let updatedValue;
        if (field.referFrom === 'form') updatedValue = formValues[field.name];
        else updatedValue = otherFormData[field.name];

        if (field.name === 'sizeRange') {
          existingValue = existingValue.map((value) => {
            delete value.id;
            return value;
          });
        }

        if (!isEqual(existingValue, updatedValue))
          updatedStringFields[field.name] = updatedValue;
      }
    );

    return updatedStringFields;
  };

  validateSizeRange = (sizeRange) => {
    if (isUndefined(sizeRange)) return true;
    return sizeRange.every((sizeRangeItem) => {
      return Object.keys(sizeRangeItem).every((field) => {
        if (field === 'varianceSymbol' && sizeRangeItem[field] === '-')
          return true;
        if (isEmpty(sizeRangeItem[field]) || isUndefined(sizeRangeItem[field]))
          return false;

        return true;
      });
    });
  };

  onFormSubmit = async (formValues) => {
    this.setState({ updatingSpec: true });
    const { acceptableMarketName } = formValues;

    let otherFormData = this.getOtherPanelData();

    let additionalComments = this.getAdditionalComments(formValues);

    let corporateAssurances = this.getCorporateAssurances(
      formValues,
      otherFormData
    );

    let countriesAssociated = this.getCountriesAssociated(formValues);

    let seaFoodList = this.getUpdatedSeaFoodList(acceptableMarketName);

    let updateDataRequest = this.getUpdatedStringFields(
      formValues,
      otherFormData
    );

    // if (!this.validateSizeRange(updateDataRequest.sizeRange)) {
    //   toast.warning('Size range is invalid ');
    //   return;
    // }

    if (!isEmpty(additionalComments))
      updateDataRequest.additionalComments = additionalComments;

    if (!isEmpty(corporateAssurances))
      updateDataRequest.corporateAssurances = corporateAssurances;

    if (!isEmpty(countriesAssociated))
      updateDataRequest.countriesAssociated = countriesAssociated;

    if (!isEmpty(seaFoodList)) updateDataRequest.seaFoodList = seaFoodList;

    let isAnyTemplateUploaded = otherFormData.documentTemplates.some(
      (template) => !isUndefined(otherFormData[template.type + '_file'])
    );

    if (isEmpty(updateDataRequest) && !isAnyTemplateUploaded) {
      toast.warning('Nothing to update !');
      this.setState({ updatingSpec: false });
      return;
    }

    if (isEmpty(updateDataRequest.treatment)) {
      toast.warning('Please choose a treatment type');
      this.setState({ updatingSpec: false });
      return;
    }

    try {
      let specificationId = this.props.match.params.id;
      updateDataRequest[':specId'] = specificationId;
      updateDataRequest['productType'] = otherFormData['productType'];
      SpecificationService.updateSpecification(updateDataRequest)
        .then((response) => {
          this.specificationPanels.uploadDocumentsPanel
            .getUploadComponentRef()
            .uploadDocuments();
        })
        .catch((error) => {
          let errorMessage = ApiUtils.getErrorMessage(error);
          toast.error('Failed to update spec - ' + errorMessage);
          this.setState({ updatingSpec: false });
        });
    } catch (error) {
      console.log(error);
      let errorMessage = ApiUtils.getErrorMessage(error);
      toast.error('Failed to update spec - ' + errorMessage);
      this.setState({ updatingSpec: false });
    }
  };

  onFormFinishFailed = () => {
    toast.warning('Some fields have errors, please verify and re-submit');
  };

  navigateToSpecDetail = () => {
    let specId = this.props.match.params.id;
    this.props.history.push(
      UrlGenerator.getUiUrlWithPathParams(RouteConstants.SPECIFICATION_DETAIL, {
        id: specId,
      })
    );
  };

  updateFormFields = (updatedFormFields) => {
    this.specForm.setFieldsValue(updatedFormFields);
  };

  render() {
    const {
      loading,
      specification,
      customers,
      brands,
      seafoodList,
      acceptableMarketNames,
      countryNames,
      productForms,
      otherDropdowns,
      uploadedDocuments,
      updatingSpec,
    } = this.state;

    if (loading) return <LoadingComponent />;

    return (
      <>
        <Form
          layout='vertical'
          className='spec-form'
          onFinish={this.onFormSubmit}
          onFinishFailed={this.onFormFinishFailed}
          scrollToFirstError={true}
          ref={(ref) => (this.specForm = ref)}
          initialValues={{
            ...specification,
            acceptableMarketName:
              specification.seaFoodList &&
              uniq(
                specification.seaFoodList.map(
                  (item) => item.acceptableMarketName
                )
              ),
            farmOrCatchCountryOfOrigin:
              specification.associatedCountries &&
              specification.associatedCountries
                .filter((o) => o.category === 'FARM_OR_CATCH')
                .map((value) => value.country),
            processingCountryOfOrigin:
              specification.associatedCountries &&
              specification.associatedCountries
                .filter((o) => o.category === 'PROCESSING')
                .map((value) => value.country),
            labelledCountryOfOrigin:
              specification.associatedCountries &&
              specification.associatedCountries
                .filter((o) => o.category === 'LABELLED')
                .map((value) => value.country),
            countryOfOriginComments:
              specification.additionalComments &&
              SpecificationUtils.getComment(
                specification.additionalComments,
                'COUNTRY_OF_ORIGIN'
              ),
            additionalSpeciesInfoComments:
              specification.additionalComments &&
              SpecificationUtils.getComment(
                specification.additionalComments,
                'ADDITIONAL_SPECIES_INFO'
              ),
            treatmentComments:
              specification.additionalComments &&
              SpecificationUtils.getComment(
                specification.additionalComments,
                'TREATMENT'
              ),
            moistureComments:
              specification.additionalComments &&
              SpecificationUtils.getComment(
                specification.additionalComments,
                'MOISTURE'
              ),
            glazeComments:
              specification.additionalComments &&
              SpecificationUtils.getComment(
                specification.additionalComments,
                'GLAZE'
              ),
            minSizeCount: SpecificationUtils.getSizeRangeProperty(
              specification &&
                specification.sizeRange &&
                specification.sizeRange[0],
              'minPieceSize'
            ),
            minSizeCountUom: SpecificationUtils.getSizeRangeProperty(
              specification &&
                specification.sizeRange &&
                specification.sizeRange[0],
              'minUom'
            ),
            maxSizeCount: SpecificationUtils.getSizeRangeProperty(
              specification &&
                specification.sizeRange &&
                specification.sizeRange[0],
              'maxPieceSize'
            ),
            maxSizeCountUom: SpecificationUtils.getSizeRangeProperty(
              specification &&
                specification.sizeRange &&
                specification.sizeRange[0],
              'maxUom'
            ),
            variance: SpecificationUtils.getSizeRangeProperty(
              specification &&
                specification.sizeRange &&
                specification.sizeRange[0],
              'variance'
            ),
            varianceSymbol: SpecificationUtils.getSizeRangeProperty(
              specification &&
                specification.sizeRange &&
                specification.sizeRange[0],
              'varianceSymbol'
            ),
            varianceUom: SpecificationUtils.getSizeRangeProperty(
              specification &&
                specification.sizeRange &&
                specification.sizeRange[0],
              'varianceUom'
            ),
            additionalPhysicalAttributeComments:
              specification.additionalComments &&
              SpecificationUtils.getComment(
                specification.additionalComments,
                'ADDITIONAL_PHYSICAL_ATTRIBUTE'
              ),
            sustainabilityMinReqComments:
              specification.corporateAssurance &&
              SpecificationUtils.getCorporateAssuranceComments(
                specification.corporateAssurance,
                'SUSTAINABILITY_MIN_REQ'
              ),
            facilityFoodSafetyComments:
              specification.corporateAssurance &&
              SpecificationUtils.getCorporateAssuranceComments(
                specification.corporateAssurance,
                'FACILITY_FOOD_SAFETY'
              ),
            socialComplianceComments:
              specification.corporateAssurance &&
              SpecificationUtils.getCorporateAssuranceComments(
                specification.corporateAssurance,
                'SOCIAL_COMPLIANCE'
              ),
            corporateAssuranceComments:
              specification.additionalComments &&
              SpecificationUtils.getComment(
                specification.additionalComments,
                'CORPORATE_ASSURANCE'
              ),
          }}
        >
          <CardWithTitle title='Edit Specification'>
            <SpecificationPanels
              ref={(ref) => (this.specificationPanels = ref)}
              basicItemData={{
                customers,
                brands,
                selectedCustomer: specification.customer,
                selectedBrand: specification.brand,
              }}
              speciesData={{
                acceptableMarketNames,
                countryNames,
                seafoodList,
                selectedSeafoodItems: specification.seaFoodList,
                selectedProductType: specification.productType,
                wildOrFarm: specification.wildOrFarm,
                additionalComments: specification.additionalComments,
                associatedCountries: specification.associatedCountries,
                farmOrCatchCountryOfOrigin:
                  specification.associatedCountries &&
                  SpecificationUtils.getAssociatedCountry(
                    specification.associatedCountries,
                    'FARM_OR_CATCH'
                  ),
                processingCountryOfOrigin:
                  specification.associatedCountries &&
                  SpecificationUtils.getAssociatedCountry(
                    specification.associatedCountries,
                    'PROCESSING'
                  ),
                labelledCountryOfOrigin:
                  specification.associatedCountries &&
                  SpecificationUtils.getAssociatedCountry(
                    specification.associatedCountries,
                    'LABELLED'
                  ),
                selectedProductForm: specification.productForm,
                minSizeCount: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'minPieceSize'
                ),
                minSizeCountUom: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'minUom'
                ),
                maxSizeCount: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'maxPieceSize'
                ),
                maxSizeCountUom: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'maxUom'
                ),
                variance: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'variance'
                ),
                varianceSymbol: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'varianceSymbol'
                ),
                varianceUom: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'varianceUom'
                ),
              }}
              basicItemSizeData={{
                netUnitSize: specification.netUnitSize,
                noOfUnits: specification.noOfUnitsInCase,
                baseUom: specification.baseUom,
              }}
              physicalAttributesData={{
                productForms,
                otherDropdowns,
                selectedTreatment:
                  specification.treatment === '-' ||
                  specification.treatment === 'None, All Natural'
                    ? 'None, All Natural'
                    : specification.treatment &&
                      specification.treatment
                        .split(',')
                        .map((treatment) => treatment.trim()),
                selectedProductType: specification.productType,
                selectedProductForm: specification.productForm,
                additionalComments: specification.additionalComments,
                scaledState:
                  specification &&
                  specification.scaledState &&
                  getReadableDisplayName(specification.scaledState),
                skinState:
                  specification &&
                  specification.skinState &&
                  getReadableDisplayName(specification.skinState),
                boneState:
                  specification &&
                  specification.boneState &&
                  getReadableDisplayName(specification.boneState),
                frozenType:
                  specification &&
                  specification.frozenState &&
                  getReadableDisplayName(specification.frozenState),
                cookedType:
                  specification &&
                  specification.cookedState &&
                  getReadableDisplayName(specification.cookedState),
                minSizeCount: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'minPieceSize'
                ),
                minSizeCountUom: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'minUom'
                ),
                maxSizeCount: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'maxPieceSize'
                ),
                maxSizeCountUom: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'maxUom'
                ),
                variance: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'variance'
                ),
                varianceSymbol: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'varianceSymbol'
                ),
                varianceUom: SpecificationUtils.getSizeRangeProperty(
                  specification &&
                    specification.sizeRange &&
                    specification.sizeRange[0],
                  'varianceUom'
                ),
                iqfIvp: specification && specification.iqfIvp,
              }}
              corporateAssuranceRequirementsData={{
                selectedSustainabilityRequirements:
                  specification &&
                  specification.corporateAssurance &&
                  specification.corporateAssurance
                    .filter(
                      (assurance) =>
                        assurance.assuranceType === 'SUSTAINABILITY_MIN_REQ'
                    )
                    .map((elt) => elt.value),
                selectedProcessingRequirement:
                  specification &&
                  SpecificationUtils.getCorporateAssuranceValue(
                    specification.corporateAssurance,
                    'FACILITY_FOOD_SAFETY'
                  ),
                selectedComplianceRequirement:
                  specification &&
                  SpecificationUtils.getCorporateAssuranceValue(
                    specification.corporateAssurance,
                    'SOCIAL_COMPLIANCE'
                  ),
                selectedThirdPartyTesting:
                  specification &&
                  SpecificationUtils.getCorporateAssuranceValue(
                    specification.corporateAssurance,
                    'THIRD_PARTY_QA_PARTNER'
                  ),
                wildOrFarm: specification.wildOrFarm,
                corporateAssurance: specification.corporateAssurance,
                additionalComments: specification.additionalComments,
              }}
              uploadDocumentsData={{
                uploadedDocuments,
                specId: specification.id,
                resetLoadingButton: () =>
                  this.setState({ updatingSpec: false }),
                fetchPageData: () => this.fetchPageData(),
                successMessage:
                  'Updated specification and uploaded templates successfully',
              }}
              updateFormFields={this.updateFormFields}
              specId={this.props.match.params.id}
              isEdit={true}
            />
          </CardWithTitle>
        </Form>
        <Row>
          <Col span={24} xs={24} lg={24} xl={24}>
            <Form.Item className='specification-input-item'>
              <div className='editprofile-btn-area mt-15 mb-15'>
                <Button
                  className='primary-full mr-15'
                  type='primary'
                  onClick={() => this.specForm.submit()}
                  loading={updatingSpec}
                >
                  Update Specification
                </Button>
                <Button
                  className='cancel-full'
                  onClick={() => this.navigateToSpecDetail()}
                >
                  Cancel
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
}
export default withRouter(
  SecureComponent(
    EditSpecification,
    [StringConstants.COMPANY_TYPES.IMPORTER],
    [
      {
        name: StringConstants.TEAMS.ACCOUNT_MANAGERS,
        role: StringConstants.TEAM_ROLES.MANAGER,
      },
    ]
  )
);
