import 'antd/dist/antd.css';

import { Layout, Menu, Popover } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { find, orderBy } from 'lodash';
import { isUserImporter, isUserPartOfTeam } from 'src/helpers/user';

import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import StringConstants from 'src/constants/StringConstants';
import { compose } from 'redux';
import { connect } from 'react-redux';
import getSidebarMenuItems from './SidebarOptions';
import { setUnsavedChanges } from 'src/actions/route';

const { Sider } = Layout;
const { SubMenu } = Menu;

class Sidebar extends React.Component {
  state = {
    collapsed: true,
    menuItems: orderBy(getSidebarMenuItems(), 'index'),
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentDidMount() {
    this.unregisterHistoryListener = this.props.history.listen(() => {
      // reset unsaved changes property during navigation
      if (this.props.route.hasUnsavedChanges)
        this.props.setUnsavedChanges(false);
    });
  }

  componentWillUnmount() {
    this.unregisterHistoryListener();
  }

  getCurrentRouteLevel = () => {
    let splitIndex = 1;
    let hasSettingsInPathName =
      this.props.location.pathname.includes('settings');
    if (hasSettingsInPathName) splitIndex = 2;
    let currentPath = '/' + this.props.location.pathname.split('/')[splitIndex];
    let route = find(Object.keys(RouteConstants), function (o) {
      return hasSettingsInPathName
        ? RouteConstants[o] === '/settings' + currentPath
        : RouteConstants[o] === currentPath;
    });
    return route;
  };

  onMenuItemChange = (event) => {
    this.props.history.push(RouteConstants[event.key]);
  };

  isSettingsActive = () => {
    const settingsRoute = [
      'TEAM_MEMBERS',
      'REPORT',
      'TERMS_AND_CONDITIONS',
      'COMPANY_PROFILE',
      'PURCHASE_ORDER_ANALYSIS',
    ];
    const currentRoute = this.getCurrentRouteLevel();
    return settingsRoute.includes(currentRoute);
  };

  isCompanyProfileActive = () => {
    return this.props.location.pathname === RouteConstants.COMPANY_PROFILE;
  };

  render() {
    const { collapsed, menuItems } = this.state;
    return (
      <Sider
        className='carava-menu'
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className='toggleicon'>
          <img
            className='trigger'
            onClick={this.toggle}
            src={require('../../../assets/images/Icon-Expand.png').default}
            alt='Menu toggle'
          />
        </div>
        <div className='menus'>
          <Menu
            theme='dark'
            mode='inline'
            defaultSelectedKeys={['DASHBOARD']}
            onClick={this.onMenuItemChange}
            selectedKeys={[this.getCurrentRouteLevel()]}
          >
            {menuItems.map((menuItem) => {
              if (menuItem.hasSubMenu)
                return (
                  <SubMenu
                    className='settings-submenu'
                    key={menuItem.key}
                    icon={<img alt={menuItem.title} src={menuItem.imgSrc} />}
                  >
                    {menuItem.items.map((item) => {
                      return <Menu.Item key={item.key}>{item.title}</Menu.Item>;
                    })}
                  </SubMenu>
                );
              return (
                <Menu.Item
                  key={menuItem.key}
                  icon={<img alt={menuItem.title} src={menuItem.imgSrc} />}
                >
                  {menuItem.title}
                </Menu.Item>
              );
            })}
          </Menu>

          <div className='menu-setting'>
            <Menu theme='dark' mode='inline'>
              <Menu.Item
                title={null}
                className='setting-menu-item'
                key={'SETTINGS'}
              >
                <Popover
                  className='setting-pop'
                  placement='rightBottom'
                  content={
                    <div className='setting-popover-content'>
                      <Link to={RouteConstants.TEAM_MEMBERS}>Team Members</Link>
                      {isUserImporter() &&
                        (isUserPartOfTeam(StringConstants.TEAMS.EXECUTIVE) ||
                          isUserPartOfTeam(StringConstants.TEAMS.SOURCING)) && (
                          <>
                            <Link to={RouteConstants.REPORT}>Reports</Link>
                            <Link to={RouteConstants.TERMS_AND_CONDITIONS}>
                              Terms and Conditions
                            </Link>
                            <Link to={RouteConstants.PURCHASE_ORDER_ANALYSIS}>
                              PO Analysis
                            </Link>
                          </>
                        )}
                      {isUserPartOfTeam(StringConstants.TEAMS.EXECUTIVE) && (
                        <Link to={RouteConstants.COMPANY_PROFILE}>
                          Company Profile
                        </Link>
                      )}
                    </div>
                  }
                  trigger='hover'
                >
                  <img
                    style={
                      this.isSettingsActive()
                        ? { opacity: '1' }
                        : { opacity: '0.4' }
                    }
                    src={
                      require('../../../assets/images/sidebar/Icon-Settings-Filled.svg')
                        .default
                    }
                    alt='settings'
                  />
                  <span className='setting-text'>Settings</span>
                </Popover>
              </Menu.Item>
            </Menu>
          </div>
        </div>
      </Sider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    route: state.route,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUnsavedChanges: () => dispatch(setUnsavedChanges()),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Sidebar);
