import LoadingComponent from './LoadingComponent';
import React from 'react';
import RouteConstants from '../../constants/RouteConstants';
import { isAuthenticatedUser } from '../../utils/auth/AuthUtils';
import { withRouter } from 'react-router-dom';

const UnsecureComponent = (Page) => {
  class UnsecureComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoggedIn: null,
      };
    }

    componentDidMount() {
      const isLoggedIn = isAuthenticatedUser();

      let pathSplit =
        this.props.location &&
        this.props.location.pathname &&
        this.props.location.pathname.split('/');
      let mainPath = pathSplit[1];

      if (isLoggedIn && mainPath !== 'invite' && mainPath !== 'signup') {
        let route = RouteConstants.REDIRECT;
        this.props.history.replace(route);
      }
      this.setState({ isLoggedIn });
    }

    render() {
      const { isLoggedIn } = this.state;
      if (isLoggedIn === null)
        return (
          <div style={{ height: '95vh' }}>
            <LoadingComponent />
          </div>
        );

      return <Page {...this.state} {...this.props} />;
    }
  }

  return withRouter(UnsecureComponent);
};

export default UnsecureComponent;
