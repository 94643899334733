import './TeamDetails.css';

import { Col, Row, Select } from 'antd';
import { find, isEmpty, isUndefined } from 'lodash';

import AddMember from 'src/components/team/AddMember';
import ApiUtils from 'src/utils/ApiUtils';
import { CaretDownOutlined } from '@ant-design/icons';
import ErrorComponent from 'src/components/common/ErrorComponent';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import LoadingComponent from 'src/components/common/LoadingComponent';
import MemberRole from 'src/components/team/MemberRole';
import PendingInvites from 'src/components/team/PendingInvites';
import React from 'react';
import StringConstants from 'src/constants/StringConstants';
import TeamService from 'src/services/TeamService';
import { connect } from 'react-redux';
import { getReadableDisplayName } from 'src/utils/DisplayUtils';
import { isUserImporter } from 'src/helpers/user';
import { toast } from 'react-toastify';

const { Option } = Select;

class TeamDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      teams: [],
      userTeams:
        props &&
        props.user &&
        props.user.teams &&
        props.user.teams.map((team) => team.name),
      selectedTeam:
        props &&
        props.user &&
        props.user.teams &&
        props.user.teams.length > 0 &&
        props.user.teams[0].id,
      pendingInvites: [],
      actionLoading: false,
      users: [],
      pendingInvitesError: false,
    };
  }

  componentDidMount = () => {
    if (!isEmpty(this.props.user)) this.fetchPageData();
  };

  refreshPage = () => {
    this.fetchPageData();
  };

  fetchPageData = async () => {
    const { selectedTeam } = this.state;
    this.setState({ loading: true });
    try {
      let teams = await this.fetchAllTeams();
      let pendingInvites = await this.fetchPendingInvites(selectedTeam);
      let users = await this.fetchUsersInTeam(selectedTeam);

      this.setState({
        loading: false,
        teams,
        pendingInvites: 'err' in pendingInvites ? [] : pendingInvites,
        users,
        pendingInvitesError: 'err' in pendingInvites,
      });
    } catch (err) {
      let errorMessage = ApiUtils.getErrorMessage(err);
      toast.error('Error - ' + errorMessage);
      this.setState({ loading: false });
    }
  };

  fetchAllTeams = async () => {
    try {
      let response = await TeamService.getAllTeams();
      if (isUserImporter()) return response.data;
      else {
        const teams = [];
        response.data.forEach((team) => {
          if (team.name === StringConstants.TEAMS.EXECUTIVE) {
            teams.push(team);
          } else if (team.name === StringConstants.TEAMS.SOURCING) {
            teams.push({
              id: team.id,
              name: StringConstants.TEAMS.SALES,
            });
          }
        });
        return teams;
      }
    } catch (err) {
      let errorMessage = ApiUtils.getErrorMessage(err);
      toast.error('Failed to fetch teams - ' + errorMessage);
      return [];
    }
  };

  fetchPendingInvites = async (teamId) => {
    try {
      if (this.isUserAuthorized()) {
        let response = await TeamService.getPendingInvites(teamId);
        return response.data;
      } else return [];
    } catch (err) {
      return { err: true };
    }
  };

  fetchUsersInTeam = async (teamId) => {
    try {
      let response = await TeamService.getTeamUsers(teamId);
      return response.data;
    } catch (err) {
      let errorMessage = ApiUtils.getErrorMessage(err);
      toast.error('Failed to fetch users for this team - ' + errorMessage);
      return [];
    }
  };

  selectTeam = (value) => {
    this.setState({ loading: true, selectedTeam: value }, async () => {
      let pendingInvites = await this.fetchPendingInvites(value);
      let users = await this.fetchUsersInTeam(value);
      this.setState({
        loading: false,
        pendingInvites: 'err' in pendingInvites ? [] : pendingInvites,
        pendingInvitesError: 'err' in pendingInvites,
        users,
      });
    });
  };

  resendInvite = async (id) => {
    this.setState({ resendActionLoading: true });
    TeamService.resendInvite(id)
      .then((response) => {
        toast.success('Resent invite successfully');
        this.setState({ resendActionLoading: false }, () => this.refreshPage());
      })
      .catch((error) => {
        let errorMessage = ApiUtils.getErrorMessage(error);
        toast.error('Failed to resend invite - ' + errorMessage);
        this.setState({ resendActionLoading: false }, () => this.refreshPage());
      });
  };

  revokeInvite = async (id) => {
    this.setState({ revokeActionLoading: true });
    TeamService.revokeInvite(id)
      .then((response) => {
        toast.success('Revoked invite successfully');
        this.setState({ revokeActionLoading: false }, () => this.refreshPage());
      })
      .catch((error) => {
        let errorMessage = ApiUtils.getErrorMessage(error);
        toast.error('Failed to revoke invite - ' + errorMessage);
        this.setState({ revokeActionLoading: false }, () => this.refreshPage());
      });
  };

  removeUser = async (teamId, userId) => {
    this.setState({ removeActionLoading: true });
    TeamService.removeUser(teamId, userId)
      .then(() => {
        toast.success('Removed user successfully');
        this.setState({ removeActionLoading: false }, () => this.refreshPage());
      })
      .catch((error) => {
        let errorMessage = ApiUtils.getErrorMessage(error);
        toast.error('Failed to remove user - ' + errorMessage);
        this.setState({ removeActionLoading: false }, () => this.refreshPage());
      });
  };

  isUserAuthorized = () => {
    const { userTeams, selectedTeam } = this.state;

    let userTeam = find(this.props.user.teams, function (o) {
      return o.id === selectedTeam;
    });

    if (
      !isUndefined(userTeam) &&
      userTeam.name === StringConstants.TEAMS.EXECUTIVE
    )
      return userTeam.role === StringConstants.TEAM_ROLES.MANAGER;
    else
      return (
        userTeams.includes(StringConstants.TEAMS.EXECUTIVE) ||
        (!isUndefined(userTeam) &&
          userTeam.role === StringConstants.TEAM_ROLES.MANAGER)
      );
  };

  render() {
    const {
      teams,
      loading,
      userTeams,
      selectedTeam,
      pendingInvites,
      resendActionLoading,
      revokeActionLoading,
      removeActionLoading,
      users,
      pendingInvitesError,
    } = this.state;

    return loading ? (
      <div style={{ height: 250 }}>
        <LoadingComponent />
      </div>
    ) : isEmpty(teams) ? (
      <ErrorComponent
        title='No teams found'
        tip='Please contact support for details'
      />
    ) : (
      <>
        <HeaderMetadata
          title={`Teams | Carava`}
          description='View, Manage and Invite Members for teams'
        />
        <Row type='flex' gutter={15}>
          <Col span={24} xs={24} lg={16} xl={16}>
            <p className='pagetitle'>Teams</p>
          </Col>
          <Col span={24} xs={24} lg={8} xl={8}>
            <Select
              className='team-selection'
              defaultValue={selectedTeam}
              style={{ width: 240 }}
              onChange={this.selectTeam}
              suffixIcon={<CaretDownOutlined />}
            >
              {teams.map((team) => {
                return (
                  <Option value={team.id} key={team.id}>
                    {getReadableDisplayName(team.name) +
                      (userTeams.includes(team.name) ? ' (My Team)' : '')}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>

        <Row type='flex' gutter={15}>
          <Col
            span={24}
            xs={24}
            lg={this.isUserAuthorized() ? 14 : 24}
            xl={this.isUserAuthorized() ? 14 : 24}
          >
            <MemberRole
              users={users}
              removeUser={this.removeUser}
              removeActionLoading={removeActionLoading}
              refreshPage={this.refreshPage}
              isUserAuthorized={this.isUserAuthorized()}
            />
          </Col>
          {this.isUserAuthorized() && (
            <Col span={24} xs={24} lg={10} xl={10}>
              <Row>
                <Col className='mb-15' span={24} xs={24} lg={24} xl={24}>
                  <AddMember
                    teamId={selectedTeam}
                    refreshPage={this.refreshPage}
                  />
                </Col>
                <Col span={24} xs={24} lg={24} xl={24}>
                  <PendingInvites
                    invites={pendingInvites}
                    resendInvite={this.resendInvite}
                    revokeInvite={this.revokeInvite}
                    resendActionLoading={resendActionLoading}
                    revokeActionLoading={revokeActionLoading}
                    error={pendingInvitesError}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(TeamDetails);
