import '../Modals.css';

import React, { Component } from 'react';

import { Modal } from 'antd';
import PlannedOrderReview from 'src/components/plannedOrder/PlannedOrderReview';
import Title from 'src/components/common/typography/Title';

class PlannedOrderReviewModal extends Component {
  handleOk = () => {
    this.props.onSubmit();
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  render() {
    const { visible, data, loading } = this.props;
    return (
      <Modal
        centered
        closable={false}
        maskClosable={false}
        visible={visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        cancelButtonProps={{ className: 'cancel-outlined-button' }}
        okText='Proceed to RFQ'
        width={900}
        confirmLoading={loading}
      >
        <Title title='Review Selection' />
        <PlannedOrderReview data={data} />
      </Modal>
    );
  }
}

export default PlannedOrderReviewModal;
