import { cloneDeep, forEach, isEmpty, sum } from 'lodash';
import { findActualBiddedIncoterm, findBidIncotermByCode } from './BidUtils';

import StringConstants from 'src/constants/StringConstants';

const AuctionUtils = {
  getAuctionCountForCard: (status, auctionCountData, auctionData) => {
    let auctionCount = auctionCountData[status],
      totalAuctions = !isEmpty(auctionCountData)
        ? sum(Object.values(auctionCountData))
        : 0;

    if (auctionCount > 0) {
      let percentage = ((auctionCount / totalAuctions) * 100).toFixed(2);
      auctionData[status] = {
        count: auctionCount,
        percentage,
      };
    } else {
      auctionData[status] = {
        count: 0,
        percentage: 0,
      };
    }
  },
  isPendingAuction: (status) => {
    return (
      status === StringConstants.AUCTION_STATUS.HOLD ||
      status === StringConstants.AUCTION_STATUS.PROFORMA_REVIEW
    );
  },
  isActiveAuction: (status) => {
    return status === StringConstants.AUCTION_STATUS.ACTIVE;
  },
  isCompletedAuction: (status) => {
    return status === StringConstants.AUCTION_STATUS.COMPLETED;
  },
  isCancelledAuction: (status) => {
    return status === StringConstants.AUCTION_STATUS.CANCELLED;
  },
  getAnalysisOverviewJSON: (data) => {
    const jsonData = [];
    const analysisRow = {
      'Auction ID': '',
      'Auction w/ multiple incoterms': '',
      Packer: '',
      Item: '',
      'Current $/LB': '',
      'Port of Delivery': '',
      'Quoted $/LB': '',
      'Quoted Incoterm': '',
      'Quoted $/LB DDPCY': '',
      '$/LB Difference': '',
      '$/LB % Variance': '',
      'Required Supply (LB)': '',
      'Required Supply (FCL)': '',
      'Quoted Supply (LB)': '',
      'Quoted Supply (FCL)': '',
      'Difference (LB)': '',
      'Difference (FCL)': '',
      'Supply %': '',
    };
    forEach(data, (row) => {
      const obj_ = cloneDeep(analysisRow);
      obj_['Auction ID'] = row.auctionId;
      obj_['Auction w/ multiple incoterms'] =
        row.bid.bidIncoterms && row.bid.bidIncoterms.length === 2
          ? 'Yes'
          : 'No';
      obj_['Packer'] = row.bid?.company?.name;
      obj_['Item'] = row.itemDescription;
      obj_['Current $/LB'] = row.targetPrice;
      if (row.portOfDelivery)
        obj_['Port of Delivery'] = row.portOfDelivery.toString();

      const actualBid = findActualBiddedIncoterm(row.bid.bidIncoterms);
      const ddpcyBid = findBidIncotermByCode(row.bid.bidIncoterms, 'DDPCY');
      obj_['Quoted $/LB'] = actualBid.amount;
      obj_['Quoted Incoterm'] = actualBid.code;
      obj_['Quoted $/LB DDPCY'] = ddpcyBid.amount;

      if (isEmpty(ddpcyBid))
        obj_['$/LB Difference'] = parseFloat(
          actualBid.amount - row.targetPrice
        ).toFixed(2);
      else
        obj_['$/LB Difference'] = parseFloat(
          ddpcyBid.amount - row.targetPrice
        ).toFixed(2);

      obj_['$/LB % Variance'] = parseFloat(
        (obj_['$/LB Difference'] / row.targetPrice) * 100
      ).toFixed(2);
      obj_['Required Supply (LB)'] = row.totalWeightRequired;
      obj_['Required Supply (FCL)'] = row.totalFCLRequired;
      obj_['Quoted Supply (LB)'] = row.totalWeightQuoted
        ? row.totalWeightQuoted
        : row.bid.fclAvailable * 33000;
      obj_['Quoted Supply (FCL)'] = row.bid.fclAvailable;
      obj_['Difference (LB)'] =
        obj_['Quoted Supply (LB)'] - row.totalWeightRequired;
      obj_['Difference (FCL)'] = row.bid.fclAvailable - row.totalFCLRequired;
      obj_['Supply %'] = parseFloat(
        (row.bid.fclAvailable / row.totalFCLRequired) * 100
      ).toFixed(2);
      jsonData.push(obj_);
    });
    return jsonData;
  },
};

export default AuctionUtils;
