import { Col, Row, Table, Tabs } from 'antd';
import React, { Component } from 'react';
import { capitalize, isEmpty } from 'lodash';

import AuctionService from 'src/services/AuctionService';
import AuctionTableManager from 'src/utils/tables/AuctionTableManager';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import HelpText from 'src/components/common/typography/HelpText';
import RouteConstants from 'src/constants/RouteConstants';
import StatusValidator from 'src/validators/StatusValidator';
import StringConstants from 'src/constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { withRouter } from 'react-router-dom';

const helpTextByStatus = {
  [[StringConstants.AUCTION_STATUS.ACTIVE]]: 'Active RFQs for submit quotes',
  [[StringConstants.AUCTION_STATUS.PENDING]]:
    'RFQs closed for quote submission. Pending actions - Review and Awards Quotes, Proformas Review.',
  [[StringConstants.AUCTION_STATUS.INVITED]]:
    'Invited RFQs to participate and submit quotes.',
  [[StringConstants.AUCTION_STATUS.HISTORY]]:
    'RFQs that have been completed or cancelled',
};

const ApiStatus = StringConstants.PLANT_AUCTION_API_STATUS;

class ProcessingPlantAuctions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auctions: {
        INVITED: [],
        ACTIVE: [],
        PENDING: [],
        HISTORY: [],
      },
      loading: true,
      activeKey: undefined,
    };
  }

  componentDidMount() {
    let status = StatusValidator.getValidStatusForParam(
      StringConstants.AUCTION_STATUS,
      this.props.location.search
    );
    if (!status) {
      status = StringConstants.AUCTION_STATUS.ACTIVE;
    }
    this.setState({ activeKey: status.toUpperCase() }, () =>
      this.fetchAuctions()
    );
  }

  fetchAuctions = () => {
    const { auctions, activeKey } = this.state;
    if (!isEmpty(auctions[activeKey])) return;

    this.setState({ loading: true });
    let status = ApiStatus[activeKey];
    AuctionService.getAllAuctionsForPlants({ ':status': status })
      .then((response) => {
        this.setState((prev) => ({
          auctions: {
            ...prev.auctions,
            [[activeKey]]: response.data,
          },
          loading: false,
        }));
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  onTabChange = (value) => {
    const url = UrlGenerator.getUiUrlWithQueryParams(RouteConstants.AUCTIONS, {
      status: value,
    });
    window.history.replaceState(null, null, url);
    this.setState({ activeKey: value }, () => this.fetchAuctions());
  };

  getColumnsForTable = () => {
    const { auctions, activeKey } = this.state;
    const data = auctions[activeKey];
    if (activeKey === StringConstants.AUCTION_STATUS.ACTIVE) {
      return AuctionTableManager.getActiveAuctionColumnsForPlants(data);
    } else if (activeKey === StringConstants.AUCTION_STATUS.PENDING) {
      return AuctionTableManager.getPendingAuctionColumnsForPlants(data);
    } else if (activeKey === StringConstants.AUCTION_STATUS.INVITED) {
      return AuctionTableManager.getInvitedAuctionColumnsForPlants(data);
    } else if (activeKey === StringConstants.AUCTION_STATUS.HISTORY) {
      return AuctionTableManager.getHistoryAuctionColumnsForPlants(data);
    }
  };

  render() {
    const { loading, activeKey, auctions } = this.state;
    return (
      <>
        <HeaderMetadata
          title={`${capitalize(activeKey)} RFQ`}
          description={helpTextByStatus[activeKey]}
        />
        <div className='ca-card-light h-95'>
          <Row>
            <Col span={24}>
              <Tabs activeKey={activeKey} onChange={this.onTabChange}>
                <Tabs.TabPane
                  tab='Invited RFQs'
                  key={StringConstants.AUCTION_STATUS.INVITED}
                />
                <Tabs.TabPane
                  tab='Active RFQs'
                  key={StringConstants.AUCTION_STATUS.ACTIVE}
                />
                <Tabs.TabPane
                  tab='Pending RFQs'
                  key={StringConstants.AUCTION_STATUS.PENDING}
                />
                <Tabs.TabPane
                  tab='RFQ History'
                  key={StringConstants.AUCTION_STATUS.HISTORY}
                />
              </Tabs>
              {activeKey && helpTextByStatus[activeKey] && (
                <HelpText
                  message={helpTextByStatus[activeKey]}
                  showIcon={true}
                />
              )}
              <Table
                columns={this.getColumnsForTable()}
                loading={loading}
                dataSource={auctions[activeKey]}
                rowKey={(row) => row.id}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(ProcessingPlantAuctions);
