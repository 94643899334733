import ApiManager from 'src/api/ApiManager';
import HttpConstants from 'src/constants/HttpConstants';
import UrlConstants from 'src/constants/UrlConstants';

const PurchaseOrderService = {
  getAllPOs: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_PURCHASE_ORDERS.USECASE,
      HttpConstants.GET_METHOD,
      data
    );
  },
  getPODetail: (id) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PURCHASE_ORDER_DETAIL.USECASE,
      HttpConstants.GET_METHOD,
      { ':id': id }
    );
  },
  getPOTimeline: (id) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_PURCHASE_ORDER_TIMELINE.USECASE,
      HttpConstants.GET_METHOD,
      { ':id': id }
    );
  },
  getNavPODetail: (id) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_NAV_PURCHASE_ORDER_DETAIL.USECASE,
      HttpConstants.GET_METHOD,
      { ':id': id }
    );
  },
  getNavPOTimeline: (id) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_NAV_PURCHASE_ORDER_TIMELINE.USECASE,
      HttpConstants.GET_METHOD,
      { ':id': id }
    );
  },
};

export default PurchaseOrderService;
