import './AddMember.css';

import { Button } from 'antd';
import Card from '../common/cards/Card';
import InviteMemberModal from 'src/modals/team/InviteMemberModal';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';

class AddMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inviteMemberModalVisible: false,
    };
  }

  render() {
    const { inviteMemberModalVisible } = this.state;
    return (
      <Card>
        <div className='addmem-box'>
          <div className='addmember-left'>
            <img
              className=''
              src={require('../../assets/images/invitemem.png').default}
              alt='Invite Members'
            />
          </div>
          <div className='addmember-right'>
            <p className='addmember-title section-heading mb-0'>
              Add Members to Your Team
            </p>
            <p className='addmember-desc'>
              Get your tasks run faster by directly inviting your team members
              to carava team
            </p>
            <Button
              className='primary-button invite-mem-btn'
              onClick={() => this.setState({ inviteMemberModalVisible: true })}
            >
              <PlusOutlined />
              Invite Member
            </Button>
          </div>
        </div>
        <InviteMemberModal
          visible={inviteMemberModalVisible}
          onCancel={() => this.setState({ inviteMemberModalVisible: false })}
          teamId={this.props.teamId}
          refreshPage={this.props.refreshPage}
        />
      </Card>
    );
  }
}
export default AddMember;
