import {
  getInputElement,
  getValidationRules,
} from 'src/utils/CompanyProfileUtils';
import { groupBy, isEmpty, orderBy } from 'lodash';

import { Form } from 'antd';
import React from 'react';
import StringConstants from 'src/constants/StringConstants';

class QuestionnaireSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
    };
  }

  componentDidMount() {
    const { data } = this.props;
    let groupedData = groupBy(data, 'groupName');
    let sortedData = {};
    Object.keys(groupedData).forEach((group) => {
      let sortedDataInGroup = orderBy(groupedData[group], 'displayRank');
      sortedData[group] = sortedDataInGroup;
    });
    this.setState({ formData: sortedData });
  }

  render() {
    const { formData } = this.state;
    const { isEdit, filledData } = this.props;
    return (
      <>
        <p className='title-heading'>
          {isEdit ? 'Factory Information Form' : 'Factory Information'}
        </p>
        <ul className='rscb-items'>
          {StringConstants.QUESTIONNAIRE_GROUPS.map((questionGroup) => {
            return (
              <div key={questionGroup.name}>
                <span
                  className='title-small-heading'
                  style={{ marginBottom: 15 }}
                >
                  {questionGroup.title}
                </span>
                <ul className='rscb-items'>
                  {!isEmpty(formData) &&
                    formData[questionGroup.name].map((question) => {
                      return (
                        <li key={question.groupName + '_' + question.id}>
                          <span className='left-item'>
                            {question.questionText}
                          </span>
                          <span className='right-item'>
                            {isEdit ? (
                              <Form.Item
                                name={question.groupName + '-' + question.id}
                                className='mb-0'
                                rules={getValidationRules(question)}
                              >
                                {getInputElement(question.groupName, question)}
                              </Form.Item>
                            ) : (
                              filledData[question.groupName + '-' + question.id]
                            )}
                          </span>
                        </li>
                      );
                    })}
                </ul>
              </div>
            );
          })}
        </ul>
      </>
    );
  }
}

export default QuestionnaireSection;
