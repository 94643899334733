import { getField, getReducer } from '.';

import StringConstants from 'src/constants/StringConstants';
import { isEmpty } from 'lodash';

export const isUserDetailAvailable = () => {
  return !isEmpty(getReducer('user'));
};

export const isUserImporter = () => {
  return (
    getField('user', 'company.type') === StringConstants.COMPANY_TYPES.IMPORTER
  );
};

export const isUserPlant = () => {
  return (
    getField('user', 'company.type') ===
    StringConstants.COMPANY_TYPES.PROCESSING_PLANT
  );
};

export const isUserManagerOfTeam = (teamName) => {
  return (
    getField('user', 'teams') &&
    getField('user', 'teams').filter(
      (team) =>
        team.name.toLowerCase() === teamName.toLowerCase() &&
        team.role === StringConstants.TEAM_ROLES.MANAGER
    ).length > 0
  );
};

export const isUserPartOfTeam = (teamName) => {
  return (
    getField('user', 'teams') &&
    getField('user', 'teams').filter(
      (team) => team.name.toLowerCase() === teamName.toLowerCase()
    ).length > 0
  );
};

export const isUserBuyerOrSeller = (currentTeam) => {
  let isUserBuyerOrSeller =
    (isUserImporter() || isUserPlant()) &&
    isUserManagerOfTeam(StringConstants.TEAMS.SOURCING);

  if (currentTeam) {
    return (
      isUserBuyerOrSeller && currentTeam === StringConstants.TEAMS.SOURCING
    );
  }
  return isUserBuyerOrSeller;
};

export const isUserPartOfApprovalTeam = (currentTeam) => {
  let isUserPartOfApprovalTeam =
    isUserPartOfTeam(StringConstants.TEAMS.ACCOUNT_MANAGERS) ||
    isUserPartOfTeam(StringConstants.TEAMS.COMPLIANCE) ||
    isUserPartOfTeam(StringConstants.TEAMS.SUSTAINABILITY);

  if (currentTeam)
    return (
      isUserPartOfApprovalTeam &&
      [
        StringConstants.TEAMS.ACCOUNT_MANAGERS,
        StringConstants.TEAMS.COMPLIANCE,
        StringConstants.TEAMS.SUSTAINABILITY,
      ].includes(currentTeam)
    );

  return isUserPartOfApprovalTeam;
};
