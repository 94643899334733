import { CalendarFilled } from '@ant-design/icons';
import { DatePicker } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import Title from 'src/components/common/typography/Title';
import { getReadableDisplayName } from 'src/utils/DisplayUtils';
import moment from 'moment';

class ExpiryDateModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    documentType: PropTypes.string.isRequired,
    handleOk: PropTypes.func,
    handleClose: PropTypes.func,
  };

  static defaultProps = {
    visible: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      expiryDate: moment()
        .add('1', 'year')
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss'),
    };
  }

  render() {
    const { visible, documentType, handleOk, handleClose } = this.props;

    const { expiryDate } = this.state;

    return (
      <Modal
        className='confirmation-modal'
        centered
        visible={visible}
        width={500}
        onOk={() => handleOk(expiryDate)}
        onCancel={handleClose}
        cancelText='Close'
        closable={false}
        maskClosable={false}
      >
        <Title
          title={
            'Enter expiry date for ' + getReadableDisplayName(documentType)
          }
        />
        <ul className='auction-edit-items'>
          <li className='auction-edit-row'>
            <span className='left-item'>Expiry Date: </span>
            <span className='right-item'>
              <DatePicker
                className='input-field'
                placeholder=''
                suffixIcon={<CalendarFilled />}
                defaultValue={moment().add('1', 'year')}
                format='DD MMM, YYYY'
                onChange={(value) => {
                  this.setState({
                    expiryDate: moment(value)
                      .startOf('day')
                      .format('YYYY-MM-DDTHH:mm:ss'),
                  });
                }}
                disabledDate={(date) =>
                  moment()
                    .startOf('day')
                    .diff(moment(date).startOf('day'), 'days') >= 1 ||
                  moment()
                    .startOf('day')
                    .diff(moment(date).startOf('day'), 'days') === 0
                }
                allowClear={false}
              />
            </span>
          </li>
        </ul>
      </Modal>
    );
  }
}

export default ExpiryDateModal;
