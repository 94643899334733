import '../../Auction.css';

import { Divider, Tooltip } from 'antd';
import { isEmpty, sum } from 'lodash';
import { isUserImporter, isUserPartOfTeam } from 'src/helpers/user';

import AuctionUtils from 'src/utils/AuctionUtils';
import { Link } from 'react-router-dom';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import StringConstants from '../../../../constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';

const { AUCTION_STATUS } = StringConstants;

class TotalAuctionOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      auctionCount: {},
      totalAuctions: 0,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    try {
      const auctionCounts = {
          ACTIVE: 5,
          HISTORY: 2,
        },
        totalAuctions = !isEmpty(auctionCounts)
          ? sum(Object.values(auctionCounts))
          : 0,
        auctionData = {};

      AuctionUtils.getAuctionCountForCard(
        AUCTION_STATUS.ACTIVE,
        auctionCounts,
        auctionData
      );
      AuctionUtils.getAuctionCountForCard(
        AUCTION_STATUS.HISTORY,
        auctionCounts,
        auctionData
      );

      this.setState({
        loading: false,
        auctionCount: auctionData,
        totalAuctions,
      });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { auctionCount, totalAuctions, loading } = this.state;

    return (
      <div className='ca-card-dark h-100'>
        <div className='total-auction-count'>
          <p className='total-auction-count-no'>{totalAuctions}</p>
          <p className='total-auction-count-text'>Total RFQs</p>
        </div>
        <div className='auction-progress-bar'>
          {!loading &&
          isUserImporter() &&
          isUserPartOfTeam(StringConstants.TEAMS.EXECUTIVE) ? (
            <>
              <div className='auction-progress-bar-title'>
                {auctionCount[AUCTION_STATUS.ACTIVE].count > 0 && (
                  <Link
                    to={UrlGenerator.addQueryParamsToUrl(
                      RouteConstants.AUCTIONS,
                      { status: AUCTION_STATUS.ACTIVE }
                    )}
                    style={{
                      width: `${
                        auctionCount[AUCTION_STATUS.ACTIVE].percentage
                      }%`,
                    }}
                  >
                    <span>Active</span>
                  </Link>
                )}

                {auctionCount[AUCTION_STATUS.HISTORY].count > 0 && (
                  <Link
                    to={UrlGenerator.addQueryParamsToUrl(
                      RouteConstants.AUCTIONS,
                      { status: AUCTION_STATUS.HISTORY }
                    )}
                    style={{
                      width: `${
                        auctionCount[AUCTION_STATUS.HISTORY].percentage
                      }%`,
                      textAlign: 'right',
                    }}
                  >
                    <span>Finished</span>
                  </Link>
                )}
              </div>
              <div className='total-auction-progress-bar'>
                {auctionCount[AUCTION_STATUS.ACTIVE].count > 0 && (
                  <Link
                    to={UrlGenerator.addQueryParamsToUrl(
                      RouteConstants.AUCTIONS,
                      { status: AUCTION_STATUS.ACTIVE }
                    )}
                    className='active-auction-bar'
                    style={{
                      width: `${
                        auctionCount[AUCTION_STATUS.ACTIVE].percentage
                      }%`,
                    }}
                  >
                    <div className='active-auction-bar-h'>
                      <p className='bar-number'>
                        {auctionCount[AUCTION_STATUS.ACTIVE].count}
                      </p>
                    </div>
                  </Link>
                )}
                {auctionCount[AUCTION_STATUS.HISTORY].count > 0 && (
                  <Link
                    to={UrlGenerator.addQueryParamsToUrl(
                      RouteConstants.AUCTIONS,
                      { status: AUCTION_STATUS.HISTORY }
                    )}
                    className='open-auction-bar'
                    style={{
                      width: `${
                        auctionCount[AUCTION_STATUS.HISTORY].percentage
                      }%`,
                    }}
                  >
                    <div className='open-auction-bar-h'>
                      <p className='bar-number'>
                        {auctionCount[AUCTION_STATUS.HISTORY].count}
                      </p>
                    </div>
                  </Link>
                )}
              </div>
            </>
          ) : null}
        </div>
        {isUserImporter() && isUserPartOfTeam(StringConstants.TEAMS.EXECUTIVE) && (
          <>
            <Divider />
            <Tooltip title={'$5000.50'}>
              <div className='total-auction-revenue'>
                <p className='total-auction-revenue-value'>{'$5000.50'}</p>
                <p className='total-auction-revenue-text'>Revenue this month</p>
              </div>
            </Tooltip>
          </>
        )}
      </div>
    );
  }
}
export default TotalAuctionOverview;
