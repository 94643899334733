import { actionTypes } from 'src/actions/types';

export default function chat(state = {}, action) {
  switch (action.type) {
    case actionTypes.SET_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: action.payload,
      };
    default:
      return state;
  }
}
