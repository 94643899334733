import PropTypes from 'prop-types';
import React from 'react';

const Title = (props) => {
  return (
    <div className='heading-divider'>
      <div className='head-divi-box'>
        <p className='head-divi-title'>{props.title}</p>
        <div className='head-divi-line'></div>
      </div>
      <div className='heading-right-area'>{props.action}</div>
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.element,
  badge: PropTypes.string,
};

export default Title;
