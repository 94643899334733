import './PoTracking.css';

import { Col, Row } from 'antd';

import { Checkbox } from 'antd';
import GoogleMapReact from 'google-map-react';
import MapMarker from './MapMarker';
import React from 'react';

class PoTracking extends React.Component {
  static defaultProps = {
    center: {
      lat: 38.120966,
      lng: -122.951813,
    },
    zoom: 5,
  };

  render() {
    return (
      <>
        <Row>
          <Col className='mb-15 mt-15' span={24} xs={24} lg={24} xl={24}>
            <div className='rscb-info'>
              <div className='rscb-img'>
                <img
                  className=''
                  src={require('../../assets/images/Img-Shrimp.png').default}
                  alt='species'
                />
              </div>
              <div className='rscb-desc'>
                <p className='rscb-desc-cin'>
                  Customer Item Number : <span>001-00218</span>
                </p>
                <p className='rscb-desc-company'>
                  <span>WALMART</span> |{' '}
                  <span>GV Shrimp 30/12oz Bag 41-60 Ckd</span>
                </p>
              </div>
            </div>
          </Col>
          <Col className='mb-15 mt-15' span={24} xs={24} lg={24} xl={24}>
            <ul className='po-track-del-status'>
              <li>
                <span className='left-item'>
                  <span className='delivery-status-title'>
                    Estimated Delivery:
                    <br />
                    <span className='delivery-status'>
                      Friday, 15 January 2021
                    </span>
                  </span>
                </span>
                <span className='right-item'>
                  <span className='delivery-status-title'>
                    Status:
                    <br />
                    <span className='delivery-status'>Out For Delivery</span>
                  </span>
                </span>
              </li>
            </ul>
          </Col>
          <Col
            className='rscb-details mb-15 mt-15'
            span={24}
            xs={24}
            lg={24}
            xl={24}
          >
            <p style={{ fontWeight: 700 }} className='text-normal'>
              Shipment Information:
            </p>
            <ul className='rscb-items bid-detail-list-1 mb-15'>
              <li>
                <span className='left-item'>Maersk:</span>
                <span className='right-item'>
                  Carava-800-GO-FISHX (463 3339)
                </span>
              </li>
              <li>
                <span className='left-item'>Shipping To:</span>
                <span className='right-item'>
                  John Newman 12/26,
                  <br />
                  558 Plymouth Street Middleborough, MA 02346
                </span>
              </li>
            </ul>
            <p
              style={{ fontWeight: 700, marginTop: '20px' }}
              className='text-normal'
            >
              PO Status
            </p>
            <ul className='rscb-items mt-15'>
              <li>
                <span className='left-item'>Tracking ID:</span>
                <span className='right-item'>1411112326589756</span>
              </li>
              <li>
                <span className='left-item'>From:</span>
                <span className='right-item'>BALTIMORE, MA US</span>
              </li>
              <li>
                <span className='left-item'>To:</span>
                <span className='right-item'>MIDDLEBORO, MA US</span>
              </li>
            </ul>
          </Col>
          <Col className=' ' span={24} xs={24} lg={24} xl={24}>
            <p
              style={{ color: '#918F8F', marginBottom: '0' }}
              className='text-normal'
            >
              Tracking Staus:
            </p>
          </Col>
          <Col className=' mb-15 ' span={24} xs={24} lg={20} xl={20}>
            <ul className='ca-delivery-timeline'>
              <li className='timeline-sections '>
                <div className='left-content '>
                  <p className='date-time'>
                    <strong>16 Jan</strong>
                  </p>
                  <p className='date-time'>11:35 PM</p>
                </div>
                <Checkbox className='tim-ch' checked={false}></Checkbox>
                <div className='center-content-upline'></div>
                <div className='center-content-downline'></div>
                <div className='right-content'>
                  <p className='tracking-desc '>
                    <strong>Activity :</strong> Out for delivery: 17478-Simon
                    Cooper RSC (63768) (17478)-PDS212209232717478-FromMob
                  </p>
                  <p className='tracking-desc'>
                    <strong>Location :</strong> MIDDLEBORO, MA US
                  </p>
                </div>
              </li>
              <li className='timeline-sections active'>
                <div className='left-content '>
                  <p className='date-time'>
                    <strong>15 Jan</strong>
                  </p>
                  <p className='date-time'>11:35 PM</p>
                </div>
                <Checkbox className='tim-ch' checked={true}></Checkbox>
                <div className='center-content-upline'></div>
                <div className='center-content-downline'></div>
                <div className='right-content'>
                  <p className='tracking-desc '>
                    <strong>Activity :</strong> Out for delivery: 17478-Simon
                    Cooper RSC (63768) (17478)-PDS212209232717478-FromMob
                  </p>
                  <p className='tracking-desc'>
                    <strong>Location :</strong> MIDDLEBORO, MA US
                  </p>
                </div>
              </li>
              <li className='timeline-sections active'>
                <div className='left-content '>
                  <p className='date-time'>
                    <strong>14 Jan</strong>
                  </p>
                  <p className='date-time'>11:35 PM</p>
                </div>
                <Checkbox className='tim-ch' checked={true}></Checkbox>
                <div className='center-content-upline'></div>
                <div className='center-content-downline'></div>
                <div className='right-content'>
                  <p className='tracking-desc '>
                    <strong>Activity :</strong> Out for delivery: 17478-Simon
                    Cooper RSC (63768) (17478)-PDS212209232717478-FromMob
                  </p>
                  <p className='tracking-desc'>
                    <strong>Location :</strong> MIDDLEBORO, MA US
                  </p>
                </div>
              </li>
              <li className='timeline-sections active'>
                <div className='left-content '>
                  <p className='date-time'>
                    <strong>13 Jan</strong>
                  </p>
                  <p className='date-time'>11:35 PM</p>
                </div>
                <Checkbox className='tim-ch' checked={true}></Checkbox>
                <div className='center-content-upline'></div>
                <div className='center-content-downline'></div>
                <div className='right-content'>
                  <p className='tracking-desc '>
                    <strong>Activity :</strong> Out for delivery: 17478-Simon
                    Cooper RSC (63768) (17478)-PDS212209232717478-FromMob
                  </p>
                  <p className='tracking-desc'>
                    <strong>Location :</strong> MIDDLEBORO, MA US
                  </p>
                </div>
              </li>
              <li className='timeline-sections active'>
                <div className='left-content '>
                  <p className='date-time'>
                    <strong>12 Jan</strong>
                  </p>
                  <p className='date-time'>11:35 PM</p>
                </div>
                <Checkbox className='tim-ch' checked={true}></Checkbox>
                <div className='center-content-upline'></div>
                <div className='center-content-downline'></div>
                <div className='right-content'>
                  <p className='tracking-desc '>
                    <strong>Activity :</strong> Out for delivery: 17478-Simon
                    Cooper RSC (63768) (17478)-PDS212209232717478-FromMob
                  </p>
                  <p className='tracking-desc'>
                    <strong>Location :</strong> MIDDLEBORO, MA US
                  </p>
                </div>
              </li>
              <li className='timeline-sections multiactivity active'>
                <div className='left-content '>
                  <p className='date-time'>
                    <strong>11 Jan</strong>
                  </p>
                  <p className='date-time'>11:35 PM</p>
                </div>
                <Checkbox className='tim-ch' checked={true}></Checkbox>
                <div className='center-content-upline'></div>
                <div className='center-content-downline'></div>
                <div className='right-content'>
                  <div className='multiactivity-divider'></div>
                  <p className='tracking-desc '>
                    <Checkbox
                      className='tim-multi-chk'
                      checked={true}
                    ></Checkbox>
                    <strong>Activity :</strong> Out for delivery: 17478-Simon
                    Cooper RSC (63768) (17478)-PDS212209232717478-FromMob
                  </p>
                  <p className='tracking-desc '>
                    <Checkbox
                      className='tim-multi-chk'
                      checked={true}
                    ></Checkbox>
                    <strong>Activity :</strong> Out for delivery: 17478-Simon
                    Cooper RSC (63768) (17478)-PDS212209232717478-FromMob
                  </p>
                  <p className='tracking-desc '>
                    <Checkbox
                      className='tim-multi-chk'
                      checked={true}
                    ></Checkbox>
                    <strong>Activity :</strong> Out for delivery: 17478-Simon
                    Cooper RSC (63768) (17478)-PDS212209232717478-FromMob
                  </p>
                </div>
              </li>
              <li className='timeline-sections active'>
                <div className='left-content '>
                  <p className='date-time'>
                    <strong>10 Jan</strong>
                  </p>
                  <p className='date-time'>11:35 PM</p>
                </div>
                <Checkbox className='tim-ch' checked={true}></Checkbox>
                <div className='center-content-upline'></div>
                <div className='center-content-downline'></div>
                <div className='right-content'>
                  <p className='tracking-desc '>
                    <strong>Activity :</strong> Out for delivery: 17478-Simon
                    Cooper RSC (63768) (17478)-PDS212209232717478-FromMob
                  </p>
                  <p className='tracking-desc'>
                    <strong>Location :</strong> MIDDLEBORO, MA US
                  </p>
                </div>
              </li>
              <li className='timeline-sections active'>
                <div className='left-content '>
                  <p className='date-time'>
                    <strong>09 Jan</strong>
                  </p>
                  <p className='date-time'>11:35 PM</p>
                </div>
                <Checkbox className='tim-ch' checked={true}></Checkbox>
                <div className='center-content-upline'></div>
                <div className='center-content-downline'></div>
                <div className='right-content'>
                  <p className='tracking-desc '>
                    <strong>Activity :</strong> Out for delivery: 17478-Simon
                    Cooper RSC (63768) (17478)-PDS212209232717478-FromMob
                  </p>
                  <p className='tracking-desc'>
                    <strong>Location :</strong> MIDDLEBORO, MA US
                  </p>
                </div>
              </li>
              <li className='timeline-sections active'>
                <div className='left-content '>
                  <p className='date-time'>
                    <strong>08 Jan</strong>
                  </p>
                  <p className='date-time'>11:35 PM</p>
                </div>
                <Checkbox className='tim-ch' checked={true}></Checkbox>
                <div className='center-content-upline'></div>
                <div className='center-content-downline'></div>
                <div className='right-content'>
                  <p className='tracking-desc '>
                    <strong>Activity :</strong> Created By M. Ferren
                  </p>
                  <p className='tracking-desc'>
                    <strong>Location :</strong> MIDDLEBORO, MA US
                  </p>
                </div>
              </li>
            </ul>
          </Col>
          <Col className=' ' span={24} xs={24} lg={24} xl={24}>
            <p
              style={{ color: '#918F8F', marginBottom: '15px' }}
              className='text-normal'
            >
              Live Location:
            </p>
            <div
              style={{
                height: '300px',
                width: '100%',
                borderRadius: '12px',
                overflow: 'hidden',
              }}
            >
              <GoogleMapReact
                defaultCenter={this.props.center}
                defaultZoom={this.props.zoom}
              >
                <MapMarker lat={40.204622} lng={-141.9572} />
              </GoogleMapReact>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
export default PoTracking;
