import { Col, Upload } from 'antd';
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import UrlConstants from 'src/constants/UrlConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import cookie from 'react-cookies';
import { getCompanyLogo } from 'src/utils/DisplayUtils';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';

class ProfileSection extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    isEdit: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      logoUploading: false,
    };
  }

  getLogoForCompany = (company) => {
    if (this.state.logoUploading)
      return (
        <img
          src={require('../../../../assets/images/Loader.gif').default}
          alt='loading...'
        />
      );
    return getCompanyLogo(company);
  };

  uploadSuccess = (data) => {
    toast.success('Logo Uploaded Successfully');
    this.props.refreshPage();
    this.setState({ logoUploading: false });
  };

  uploadFailed = () => {
    toast.error('Failed to upload logo');
    this.setState({ logoUploading: false });
  };

  render() {
    const { company, isEdit } = this.props;

    return (
      <Col className='mt-15' span={24} xs={24} lg={24} xl={24}>
        <div className='profile-img-info'>
          <div className='profile-img'>
            {this.getLogoForCompany(company)}
            {isEdit && (
              <Upload
                accept='image/png, image/jpeg'
                showUploadList={false}
                method='PATCH'
                action={UrlGenerator.generateUrl(
                  UrlConstants.UPLOAD_COMPANY_LOGO.USECASE
                )}
                headers={{
                  Authorization: 'Bearer ' + cookie.load('token'),
                }}
                onStart={() => this.setState({ logoUploading: true })}
                onError={this.uploadFailed}
                onSuccess={this.uploadSuccess}
              >
                Change Logo
              </Upload>
            )}
          </div>
          <div className='profile-info'>
            <p className='profile-username'>{company && company.name}</p>
            <p className='pro-designation-mailid'>
              {(company && company.address && !isNull(company.address)
                ? company.address.city + ', '
                : '') +
                (company && !isNull(company.country) ? company.country : '')}
            </p>
          </div>
        </div>
        <div className='heading-divider'></div>
      </Col>
    );
  }
}

export default ProfileSection;
