import ApiManager from 'src/api/ApiManager';
import HttpConstants from 'src/constants/HttpConstants';
import UrlConstants from 'src/constants/UrlConstants';

const AuctionService = {
  getAuctionCountForDashboard: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.DASHBOARD_GET_AUCTION_COUNT.USECASE,
      HttpConstants.GET_METHOD
    );
  },

  getAuctionDetails: (auctionId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_AUCTION_DETAIL.USECASE,
      HttpConstants.GET_METHOD,
      { ':auctionId': auctionId }
    );
  },

  createAuction: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.CREATE_AUCTION.USECASE,
      HttpConstants.POST_METHOD,
      data
    );
  },

  updateAuctionPlannedOrders: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_AUCTION_PLANNED_ORDERS.USECASE,
      HttpConstants.POST_METHOD,
      data
    );
  },

  updateCompliantPlantsAndIncoterms: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.UPDATE_COMPLIANT_PLANT_INCOTERMS.USECASE,
      HttpConstants.POST_METHOD,
      data
    );
  },

  openAuction: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.OPEN_AUCTION.USECASE,
      HttpConstants.POST_METHOD,
      data
    );
  },

  discardAuction: (auctionId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.DELETE_AUCTION.USECASE,
      HttpConstants.DELETE_METHOD,
      { ':auctionId': auctionId }
    );
  },

  getAllAuctions: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_AUCTIONS.USECASE,
      HttpConstants.GET_METHOD,
      data
    );
  },

  getAuctionBids: (auctionId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_AUCTION_BIDS.USECASE,
      HttpConstants.GET_METHOD,
      { ':auctionId': auctionId }
    );
  },

  getAuctionParticipants: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_AUCTION_PARTICIPANTS.USECASE,
      HttpConstants.GET_METHOD,
      data
    );
  },

  moveAuctionToHold: (auctionId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.MOVE_AUCTION_TO_HOLD.USECASE,
      HttpConstants.POST_METHOD,
      { ':auctionId': auctionId }
    );
  },

  cancelAuction: (auctionId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.CANCEL_AUCTION.USECASE,
      HttpConstants.POST_METHOD,
      { ':auctionId': auctionId }
    );
  },

  editAuction: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.EDIT_AUCTION.USECASE,
      HttpConstants.PATCH_METHOD,
      data
    );
  },

  addPlantsToAuction: (auctionId, data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.ADD_PLANTS_TO_AUCTION.USECASE,
      HttpConstants.POST_METHOD,
      { ':auctionId': auctionId, ...data }
    );
  },

  getAuctionWinners: (auctionId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_AUCTION_WINNERS.USECASE,
      HttpConstants.GET_METHOD,
      { ':auctionId': auctionId }
    );
  },

  getCalculatedAuctionPlannedOrders: (auctionId, data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_CALCULATED_AUCTION_PLANNED_ORDERS.USECASE,
      HttpConstants.POST_METHOD,
      { ...data, ':auctionId': auctionId }
    );
  },

  placeBid: (auctionId, data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.PLACE_BID.USECASE,
      HttpConstants.POST_METHOD,
      { ...data, ':auctionId': auctionId }
    );
  },
  simulateBid: (auctionId, data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.SIMULATE_BID.USECASE,
      HttpConstants.POST_METHOD,
      { ...data, ':auctionId': auctionId }
    );
  },

  reviewProforma: (auctionId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.REVIEW_PROFORMA.USECASE,
      HttpConstants.POST_METHOD,
      { ':auctionId': auctionId }
    );
  },

  // Processing Plant Side APIs
  getAllAuctionsForPlants: (data) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_AUCTIONS_FOR_PLANTS.USECASE,
      HttpConstants.GET_METHOD,
      data
    );
  },

  withdrawFromAuction: (auctionId) => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.WITHDRAW_FROM_AUCTION.USECASE,
      HttpConstants.DELETE_METHOD,
      { ':auctionId': auctionId }
    );
  },

  getAuctionAnalysisOverview: () => {
    return ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_AUCTION_ANALYSIS.USECASE,
      HttpConstants.GET_METHOD
    );
  },
};
export default AuctionService;
