import '../Proforma.css';

import { isEmpty, sum } from 'lodash';

import { Link } from 'react-router-dom';
import ProformaService from 'src/services/ProformaService';
import ProformaUtils from 'src/utils/ProformaUtils';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import StringConstants from '../../../constants/StringConstants';
import UrlGenerator from 'src/api/UrlGenerator';
import { getFormattedCurrentDate } from 'src/utils/DisplayUtils';

const { PROFORMA_REVIEW_STATUS, APPROVER_PROFORMA_STATUS } = StringConstants;

class TotalProformaOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      proformaCounts: {},
      totalProformas: 0,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    ProformaService.getProformaCountForDashboard()
      .then((response) => {
        const proformaCounts = response.data,
          totalProformas = !isEmpty(proformaCounts)
            ? sum(Object.values(proformaCounts))
            : 0,
          proformaData = {};

        ProformaUtils.getProformaCountForCard(
          PROFORMA_REVIEW_STATUS.APPROVED,
          proformaCounts,
          proformaData
        );
        ProformaUtils.getProformaCountForCard(
          PROFORMA_REVIEW_STATUS.PENDING,
          proformaCounts,
          proformaData
        );

        this.setState({
          loading: false,
          proformaCount: proformaData,
          totalProformas,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          proformaCount: {},
          totalProformas: 0,
        });
      });
  }

  render() {
    const { proformaCount, totalProformas, loading } = this.state;

    return (
      <div className='ca-card-dark h-100'>
        <div className='total-proforma-count'>
          <p className='total-proforma-count-no'>{totalProformas}</p>
          <p className='total-proforma-count-text'>Total Proformas</p>
        </div>
        <div className='proforma-progress-bar'>
          {!loading && (
            <>
              <div className='proforma-progress-bar-title'>
                {!isEmpty(proformaCount) &&
                  proformaCount[PROFORMA_REVIEW_STATUS.APPROVED].count > 0 && (
                    <Link
                      to={UrlGenerator.addQueryParamsToUrl(
                        RouteConstants.PROFORMAS,
                        { status: APPROVER_PROFORMA_STATUS.HISTORY }
                      )}
                      style={{
                        width: `${
                          proformaCount[PROFORMA_REVIEW_STATUS.APPROVED]
                            .percentage
                        }%`,
                      }}
                    >
                      <span>Approved</span>
                    </Link>
                  )}

                {!isEmpty(proformaCount) &&
                  proformaCount[PROFORMA_REVIEW_STATUS.PENDING].count > 0 && (
                    <Link
                      to={UrlGenerator.addQueryParamsToUrl(
                        RouteConstants.PROFORMAS,
                        { status: APPROVER_PROFORMA_STATUS.PENDING }
                      )}
                      style={{
                        width: `${
                          proformaCount[PROFORMA_REVIEW_STATUS.PENDING]
                            .percentage
                        }%`,
                        textAlign: 'center',
                      }}
                    >
                      <span>Pending </span>
                    </Link>
                  )}
              </div>
              <div className='total-proforma-progress-bar'>
                {proformaCount[PROFORMA_REVIEW_STATUS.APPROVED].count > 0 && (
                  <Link
                    to={UrlGenerator.addQueryParamsToUrl(
                      RouteConstants.PROFORMAS,
                      { status: APPROVER_PROFORMA_STATUS.HISTORY }
                    )}
                    className='approved-proforma-bar'
                    style={{
                      width: `${
                        proformaCount[PROFORMA_REVIEW_STATUS.APPROVED]
                          .percentage
                      }%`,
                    }}
                  >
                    <div className='approved-proforma-bar-h'>
                      <p className='bar-number'>
                        {proformaCount[PROFORMA_REVIEW_STATUS.APPROVED].count}
                      </p>
                    </div>
                  </Link>
                )}
                {proformaCount[PROFORMA_REVIEW_STATUS.PENDING].count > 0 && (
                  <Link
                    to={UrlGenerator.addQueryParamsToUrl(
                      RouteConstants.PROFORMAS,
                      { status: APPROVER_PROFORMA_STATUS.PENDING }
                    )}
                    className='pending-proforma-bar'
                    style={{
                      width: `${
                        proformaCount[PROFORMA_REVIEW_STATUS.PENDING].percentage
                      }%`,
                    }}
                  >
                    <div className='pending-proforma-bar-h'>
                      <p className='bar-number'>
                        {proformaCount[PROFORMA_REVIEW_STATUS.PENDING].count}
                      </p>
                    </div>
                  </Link>
                )}
              </div>
            </>
          )}
        </div>
        <div className='total-proforma-date'>
          <p>{getFormattedCurrentDate()}</p>
        </div>
      </div>
    );
  }
}
export default TotalProformaOverview;
