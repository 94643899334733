import ApexCharts from 'apexcharts';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import { CaretDownOutlined } from '@ant-design/icons';
import Chart from 'react-apexcharts';
import React from 'react';
import { Select } from 'antd';
import moment from 'moment';

const { Option } = Select;

const options = {
  chart: {
    id: 'pending-po-chart',
    height: 350,
    type: 'bar',
    stacked: false,
    stackType: 'normal',
    toolbar: {
      show: true,
      position: 'topRight',
      tools: {
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true | '<img src="/static/icons/reset.png" width="20">',
      },
    },
  },
  stroke: {
    curve: 'straight',
    width: [0, 2, 0, 2],
  },
  legend: {
    show: false,
    position: 'top',
    horizontalAlign: 'right',
    fontSize: '10px',
    fontFamily: 'Roboto Condensed, sans-serif',
    fontWeight: 700,
    labels: {
      useSeriesColors: true,
    },
  },
  colors: ['#0EBAB3', '#CE2D4F', '#2170B8', '#478978'],
  markers: {
    size: 6,
    strokeColors: ['#0EBAB3', '#CE2D4F', '#2170B8', '#478978'],
    hover: {
      size: 6,
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 5,
      columnWidth: '60%',
    },
  },
  fill: {
    opacity: 1,
  },
  dataLabels: {
    enabled: false,
    enabledOnSeries: [0, 1, 3, 4],
  },
  grid: {
    show: true,
    borderColor: '#E0E2E3',

    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },
  xaxis: {
    type: 'datetime',
    axisBorder: {
      show: false,
      color: 'red',
    },
    labels: {
      showDuplicates: false,
      style: {
        colors: '#231F20',
        fontSize: '12px',
        fontFamily: 'Roboto Condensed, sans-serif',
      },
    },
    tickPlacement: 'between',
  },
  yaxis: [
    {
      forceNiceScale: true,
      axisBorder: {
        show: true,
        color: '#E0E2E3',
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#2170B8',
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        formatter: function (val, index) {
          if (val) {
            let number = '$ ' + new Intl.NumberFormat().format(val);
            return number;
          } else return '-';
        },
        style: {
          colors: '#2170B8',
          fontSize: '12px',
          fontFamily: 'Roboto Condensed, sans-serif',
        },
      },
      title: {
        text: 'Total Value',
        style: {
          color: '#231F20',
          fontSize: '12px',
          fontFamily: 'Roboto Condensed, sans-serif',
          fontWeight: 700,
        },
      },
    },
  ],
};

const series = {
  name: 'Pending PO Values',
  data: [
    {
      x: new Date('2021-09-29'),
      y: 20000,
    },
    {
      x: new Date('2021-09-30'),
      y: 28000,
    },
    {
      x: new Date('2021-10-01'),
      y: 24000,
    },
    {
      x: new Date('2021-10-02'),
      y: 22000,
    },
    {
      x: new Date('2021-10-03'),
      y: 19000,
    },
  ],
};

class PendingPOChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: 'one_week',
    };
  }

  updateData(timeline) {
    this.setState({
      selection: timeline,
    });

    switch (timeline) {
      case 'one_week':
        ApexCharts.exec(
          'pending-po-chart',
          'zoomX',
          new Date(moment().subtract(1, 'week')).getTime(),
          new Date().getTime()
        );
        break;
      case 'one_month':
        ApexCharts.exec(
          'pending-po-chart',
          'zoomX',
          new Date(moment().subtract(1, 'month')).getTime(),
          new Date().getTime()
        );
        break;
      case 'all':
        ApexCharts.exec(
          'pending-po-chart',
          'zoomX',
          new Date(moment().startOf('year')).getTime(),
          new Date().getTime()
        );
        break;
      default:
    }
  }

  render() {
    return (
      <CardWithTitle title='Pending POs'>
        <div className='header'>
          <div className='select-drop ht-header-select'>
            Date:
            <Select
              suffixIcon={<CaretDownOutlined />}
              className='planned-vi-sel-btn'
              defaultValue={'ETA'}
              style={{ width: 300 }}
            >
              <Option value={'ETA'}>ETA</Option>
              <Option value={'Payment due date'}>Payment due date</Option>
            </Select>
          </div>
          <div className='toolbar'>
            Change View:
            <button
              id='one_week'
              onClick={() => this.updateData('one_week')}
              className={this.state.selection === 'one_week' ? 'active' : ''}
            >
              1W
            </button>
            &nbsp;
            <button
              id='one_month'
              onClick={() => this.updateData('one_month')}
              className={this.state.selection === 'one_month' ? 'active' : ''}
            >
              1M
            </button>
            &nbsp;
            <button
              id='all'
              onClick={() => this.updateData('all')}
              className={this.state.selection === 'all' ? 'active' : ''}
            >
              ALL
            </button>
          </div>
        </div>
        <Chart
          options={options}
          series={[series]}
          type='bar'
          height={350}
          width={'95%'}
        />
      </CardWithTitle>
    );
  }
}

export default PendingPOChart;
