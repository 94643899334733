import './UserProfile.css';

import { Button, Col, DatePicker, Form, Input, Row, Space } from 'antd';
import { EditFilled, SettingOutlined } from '@ant-design/icons';
import { capitalize, isEmpty } from 'lodash';

import ApiUtils from 'src/utils/ApiUtils';
import CardWithTitle from 'src/components/common/cards/CardWithTitle';
import DisplayConstants from 'src/constants/DisplayConstants';
import HeaderMetadata from 'src/components/common/helmet/HeaderMetadata';
import { Link } from 'react-router-dom';
import ProfileSection from './userProfile/ProfileSection';
import React from 'react';
import RouteConstants from 'src/constants/RouteConstants';
import SecureComponent from 'src/components/common/SecureComponent';
import UserService from 'src/services/UserService';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getFormattedDate } from 'src/utils/DisplayUtils';
import { getUserDataFromCookie } from 'src/utils/auth/AuthUtils';
import { logout } from 'src/utils/auth/auth0';
import moment from 'moment';
import { resetState } from 'src/actions';
import { toast } from 'react-toastify';
import { updateUser } from 'src/actions/user';

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      changePasswordAllowed: false,
      loading: false,
    };
  }

  componentDidMount() {
    const jwtData = getUserDataFromCookie();
    if (jwtData && jwtData.auth_type === 'PASSWORD')
      this.setState({ changePasswordAllowed: true });
  }

  handleCancel = () => {
    this.setState({ isEdit: false });
  };

  onSubmit = () => {
    this.setState({ loading: true });
    this.userProfileForm
      .validateFields()
      .then((values) => {
        const data = {};
        if (values.firstName) data.firstName = values.firstName;
        if (values.lastName) data.lastName = values.lastName;
        if (values.mobileNumber) data.mobileNumber = values.mobileNumber;
        if (values.dob) data.dob = values.dob;

        UserService.updateProfile(data)
          .then(() => {
            toast.success('Profile Updated Successfully');
            this.props.updateUser(data);
            this.setState({ loading: false, isEdit: false });
          })
          .catch((err) => {
            const errorMessage = ApiUtils.getErrorMessage(
              err,
              'Unable to update details at the moment'
            );
            toast.error(errorMessage);
            this.setState({ loading: false });
          });
      })
      .catch((error) => {
        if (!isEmpty(error.errorFields)) {
          this.userProfileForm.scrollToField(error.errorFields[0].name[0]);
        } else {
          toast.warning('Unable to submit changes at the moment');
        }
        this.setState({ loading: false });
      });
  };

  render() {
    const { isEdit, loading, changePasswordAllowed } = this.state;
    const { user } = this.props;

    return (
      <>
        <HeaderMetadata title={`My Profile`} description='' />
        <CardWithTitle
          className={
            isEdit
              ? 'userprofile userprofile-edit rscb-editable'
              : 'userprofile'
          }
          title='My Profile'
          action={
            !isEdit ? (
              <Space>
                <Button
                  className='primary-outlined-button'
                  icon={<SettingOutlined />}
                  onClick={() =>
                    this.props.history.push(RouteConstants.USER_PREFERENCE)
                  }
                >
                  Preferences
                </Button>
                <Button
                  className='heading-cta'
                  type='primary'
                  onClick={() => this.setState({ isEdit: true })}
                  icon={<EditFilled />}
                >
                  Edit
                </Button>
              </Space>
            ) : (
              <></>
            )
          }
        >
          <Row>
            <ProfileSection isEdit={isEdit} />
            {!isEdit ? (
              <Col className='rscb-details ' span={24} xs={24} lg={24} xl={24}>
                <p className='title-heading'>General Details</p>
                <ul className='rscb-items'>
                  <li>
                    <span className='left-item'>First Name:</span>
                    <span className='right-item'>{user && user.firstName}</span>
                  </li>
                  <li>
                    <span className='left-item'>Last Name:</span>
                    <span className='right-item'>{user && user.lastName}</span>
                  </li>
                  <li>
                    <span className='left-item'>Email Address:</span>
                    <span className='right-item'>{user && user.email}</span>
                  </li>
                  <li>
                    <span className='left-item'>Password:</span>
                    <span className='right-item'>
                      <span>
                        &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
                      </span>
                      {changePasswordAllowed && (
                        <Link
                          className='change-btn ml-30'
                          to={RouteConstants.USER_CHANGE_PASSWORD}
                        >
                          Change
                        </Link>
                      )}
                    </span>
                  </li>
                  <li>
                    <span className='left-item'>Mobile Number:</span>
                    <span className='right-item'>
                      {user && user.mobileNumber}
                    </span>
                  </li>
                  <li>
                    <span className='left-item'>Date Of Birth:</span>
                    <span className='right-item'>
                      {user.dob && getFormattedDate(user.dob)}
                    </span>
                  </li>
                  <li>
                    <span className='left-item'>Role In Company:</span>
                    <span className='right-item'>
                      {user.teams.map((team, index) => {
                        let team_string =
                          capitalize(team.name) + ' ' + capitalize(team.role);
                        if (index < user.teams.length - 1) {
                          team_string += ', ';
                        }
                        return team_string;
                      })}
                    </span>
                  </li>
                </ul>
              </Col>
            ) : (
              <Col className='rscb-details' span={24} xs={24} lg={24} xl={24}>
                <p className='title-heading'>General Details</p>
                <ul className='rscb-items'>
                  <Form
                    ref={(ref) => (this.userProfileForm = ref)}
                    initialValues={{ ...user, dob: moment(user.dob) }}
                  >
                    <li>
                      <span className='left-item'>First Name:</span>
                      <span className='right-item'>
                        <Form.Item
                          name='firstName'
                          className='mb-0'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter your first name',
                            },
                          ]}
                        >
                          <Input className='input-field' />
                        </Form.Item>
                      </span>
                    </li>
                    <li>
                      <span className='left-item'>Last Name:</span>
                      <span className='right-item'>
                        <Form.Item
                          name='lastName'
                          className='mb-0'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter your last name',
                            },
                          ]}
                        >
                          <Input className='input-field' />
                        </Form.Item>
                      </span>
                    </li>
                    <li>
                      <span className='left-item'>Email Address:</span>
                      <span className='right-item'>
                        <Form.Item name='email' className='mb-0'>
                          <Input
                            type='email'
                            className='input-field'
                            disabled
                          />
                        </Form.Item>
                      </span>
                    </li>
                    <li>
                      <span className='left-item'>Mobile Number:</span>
                      <span className='right-item'>
                        <Form.Item name='mobileNumber' className='mb-0'>
                          <Input className='input-field' />
                        </Form.Item>
                      </span>
                    </li>
                    <li>
                      <span className='left-item'>Date Of Birth:</span>
                      <span className='right-item'>
                        <Form.Item name='dob' className='mb-0'>
                          <DatePicker
                            className='input-field'
                            format={DisplayConstants.DATE_FORMAT}
                            suffixIcon={
                              <img
                                src={
                                  require('../../../assets/images/Icon-Calendar-Black.svg')
                                    .default
                                }
                                alt='calendar'
                              />
                            }
                          />
                        </Form.Item>
                      </span>
                    </li>
                  </Form>
                </ul>
              </Col>
            )}
          </Row>
        </CardWithTitle>
        <div className='editprofile-btn-area mt-15 mb-15'>
          {!isEdit ? (
            <Button
              className='cancel-full'
              onClick={() => {
                this.props.resetState();
                logout();
              }}
            >
              Logout
            </Button>
          ) : (
            <Space>
              <Button
                className='primary-full mr-15'
                type='primary'
                onClick={this.onSubmit}
                loading={loading}
              >
                Save Changes
              </Button>
              <Button
                onClick={this.handleCancel}
                className='cancel-outlined-full'
                disabled={loading}
              >
                Cancel
              </Button>
            </Space>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    signOut: logout,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetState: () => dispatch(resetState()),
    updateUser: (data) => dispatch(updateUser(data)),
  };
};

export default compose(
  SecureComponent,
  connect(mapStateToProps, mapDispatchToProps)
)(UserProfile);
