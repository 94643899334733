import { Input, Modal } from 'antd';
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Title from 'src/components/common/typography/Title';
import { isEmpty } from 'lodash';

const { TextArea } = Input;

class ApproverCommentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: undefined,
    };
  }

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  static defaultProps = {
    heading: 'Confirm your action',
    okText: 'Proceed',
    cancelText: 'Cancel',
  };

  render() {
    const { visible } = this.props;
    const { comment } = this.state;
    return (
      <Modal
        className='confirmation-modal'
        centered
        visible={visible}
        okText='Submit'
        onOk={() => this.props.onSubmit(comment)}
        onCancel={() => this.props.onCancel()}
        okButtonProps={{
          disabled: isEmpty(comment),
        }}
        closable={false}
        maskClosable={false}
      >
        <Title title='Add Comment' />

        <ul className='auction-edit-items'>
          <li className='auction-edit-row'>
            <span className='left-item'>Comment: </span>
            <span className='right-item'>
              <TextArea
                className='auction-edit-field'
                onChange={(event) =>
                  this.setState({ comment: event.target.value })
                }
                placeholder='Add comments here'
                autoSize
              />
            </span>
          </li>
        </ul>
      </Modal>
    );
  }
}

export default ApproverCommentModal;
